import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ZenMatDialogsComponent} from './portfolio/_components/zen-mat-dialogs/zen-mat-dialogs.component';
import {ZenMatButtonsComponent} from './portfolio/_components/zen-mat-buttons/zen-mat-buttons.component';
import {ZenMatFormFieldsComponent} from './portfolio/_components/zen-mat-form-fields/zen-mat-form-fields.component';
import {MatIconModule} from '@angular/material/icon';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../modules/zentility/_shared/shared.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxMaskModule} from 'ngx-mask';
import {IMaskModule} from 'angular-imask';
import {ZenWidgetsComponent} from './portfolio/_components/zen-widgets/zen-widgets.component';
import {MatrixPricingHelperService} from '../modules/zentility/_modules/zen-market-pulse/_services/matrix-pricing-helper.service';
import {ZenMatTableStoryComponent} from './portfolio/_components/zen-mat-table-story/zen-mat-table-story.component';
import {ZenMatTableHelperService} from '../modules/zentility/_shared/_services/helpers/zen-mat-table-helper.service';
import {LayoutModulesModule} from '../modules/zentility/layout-modules/layout-modules.module';

import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  declarations: [ZenMatDialogsComponent, ZenMatButtonsComponent, ZenMatFormFieldsComponent, ZenWidgetsComponent, ZenMatTableStoryComponent],
  exports: [
  ],
  providers: [MatrixPricingHelperService, ZenMatTableHelperService],
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    SharedModule,
    MatDatepickerModule,
    NgxMaskModule,
    IMaskModule,
    LayoutModulesModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatAutocompleteModule
  ]
})
export class StoriesModule {
}
