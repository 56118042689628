import {Component, Inject} from '@angular/core';
import {NgxPopperjsContentComponent} from 'ngx-popperjs';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {ZenToastDataModel} from '../zen-toast/zen-toast.component';
import {ZenBaseComponent} from '../zen-base/zen-base.component';
import {JenZenImgType} from '../jen-zen-img/jen-zen-img.component';

@Component({
  selector: 'app-zen-toast-with-popper',
  templateUrl: './zen-toast-with-popper.component.html',
  styleUrls: ['./zen-toast-with-popper.component.scss']
})
export class ZenToastWithPopperComponent extends ZenBaseComponent {
  popperContent: NgxPopperjsContentComponent;
  JenZenImgType = JenZenImgType;

  constructor(public snackBarRef: MatSnackBarRef<ZenToastWithPopperComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: ZenToastDataModel) {
    super();
    this.popperContent = this.data.popperContent;
  }

}
