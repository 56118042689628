import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {environment} from '../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {LenV4FormModel, PfLensDetailsModel} from '../../../_modules/portfolio/_model/portfolio-lens.model';
import {DeleteCheck, ActivationCheck} from '../../_model/customer-v4.model';
import {ActivationChangeBulkModel} from '../../_model/activation-change-bulk.model';

@Injectable({
  providedIn: 'root'
})
export class LenV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  getLenById(customerId: number, lenId: string): Observable<LenV4FormModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/lens/${lenId}`;
    return this.http.get<LenV4FormModel>(url).pipe(take(1));
  }

  createLen(customerId: number, data: LenV4FormModel): Observable<LenV4FormModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/lens`;
    return this.http.post<LenV4FormModel>(url, data).pipe(take(1));
  }

  updateLen(customerId: number, lenId: string, data: LenV4FormModel): Observable<LenV4FormModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/lens/${lenId}`;
    return this.http.put<LenV4FormModel>(url, data).pipe(take(1));
  }

  getLenDetailsById(customerId: number, lenId: string): Observable<PfLensDetailsModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/lens/${lenId}`;
    return this.http.get<PfLensDetailsModel>(url).pipe(take(1));
  }

  bulkDeleteCheckLen(customerId: number, ids: string[]): Observable<DeleteCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/lens/bulk/delete-check`;
    return this.http.post<DeleteCheck>(url, { ids }).pipe(take(1));
  }

  deleteCustomerLenByIds(customerId: number, ids: string[]): Observable<LenV4FormModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/lens/bulk/delete`;
    return this.http.post<LenV4FormModel>(url, {ids}).pipe(take(1));
  }

  /**
   * Bulk Activation Check LENs - Check to see if provided LENs can be Reactivated
   * Payload -> {customerId: number, ids: string[]} -> where applicable (LENs)
   * @param customerId
   * @param ids
   */
  bulkActivationCheckLens(customerId: number, ids: string[]): Observable<ActivationCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/lens/bulk/activation-check`;
    return this.http.post<ActivationCheck>(url, { ids }).pipe(take(1));
  }

  /**
   * Bulk change status LENs - Deactivate or reactivate LENs using the table or row action toggles
   * Payload -> {customerId: number, ids: string[]} -> where applicable (LENs)
   * @param customerId
   * @param lenIds
   * @param active
   */
  bulkChangeStatusLens(customerId: number, activationBulkChange: ActivationChangeBulkModel) {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/lens/bulk/activation`;
    return this.http.put(url, activationBulkChange).pipe(take(1));
  }

  deleteW9(customerId: number, lenId: string, w9DocumentId: number) {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/lens/${lenId}/w9/${w9DocumentId}`;
    return this.http.delete(url).pipe(take(1));
  }
}
