import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../../environments';
import { AuthenticationService } from '../authentication.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DecisionResponse } from '../../../../../_modules/zen-contracts/_modules/sign-contract/_interfaces/envelope-response.interface';
import { EnvelopeRequest } from '../../../../../_modules/zen-contracts/_modules/sign-contract/_interfaces/envelope-request.interface';
import { map, take } from 'rxjs/operators';
import { saveBlob } from '../../../_utils/zen-utils';

export interface ContractDocs {
  id: string;
  customerId: number;
  energyPlanId: number;
  viewCallbackUrl: string;
  supplierUserId: string;
  rateCheckId: string;
  docusignTemplateId: string;
  envelopeId: string;
  created: string;
  lastUpdated: string;
  themeId: string;
  primarySignatoryId: string;
  organizationId: string;
  version: number;
  friendlyFileName: string;
  countersignedUploaded: boolean;
}

@Injectable()
export class DocusignContractsService {

  constructor(private http: HttpClient,
    private authService: AuthenticationService) {
  }


  signMatrixContract(customerId: number, energyPlanId: number, rateCheckId: string, callbackUrl: string): Observable<DecisionResponse> {
    const url = `${environment.signApiBaseUrl}/v2/docusign/create_envelope?force_new_contract=true`;
    const body: EnvelopeRequest = {
      customerId: customerId,
      energyPlanId: energyPlanId,
      viewCallbackUrl: window.location.origin + '/assets/html/redirect.html#' + callbackUrl,
      rateCheckId: rateCheckId
    };
    return this.http.post<DecisionResponse>(url, body).pipe(take(1));
  }

  getEnvelopeRequestWithCustomerId(rateCheckId: string, customerId: number): Observable<ContractDocs> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.signApiBaseUrl}/v2/docusign/customer/${customerId}/rate_checks/${rateCheckId}/envelope_request`;
    return this.http.get<ContractDocs>(url).pipe(take(1));
  }

  manageContract(envelopeId: string, callbackUrl: string): Observable<DecisionResponse> {
    const url = `${environment.signApiBaseUrl}/v2/docusign/envelopes/${envelopeId}/correct_view?redirectUrl=${callbackUrl}`;
    console.log('callbackUrl ', url);
    return this.http.get<DecisionResponse>(url).pipe(take(1));
  }

  downloadDocument(apiUrl: string, fileName: string, responseType?: string) {
    const downloadUrl = environment.signApiBaseUrl + apiUrl;
    if (!responseType) {
      responseType = 'application/pdf';
    }
    return this.http.get(downloadUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': responseType
      },
      responseType: 'blob'
    }).pipe(
      map(data => saveBlob(data, responseType, fileName))
    ).toPromise();
  }
}
