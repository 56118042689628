import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FlatTreeControl, NestedTreeControl} from '@angular/cdk/tree';
import {
  ChecklistDatabase
} from '../portfolio-filter-flyout/portfolio-filter-flyout.component';
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';

/**
 * A general purpose flyout component used for displaying trees and selections on the right side of the page.
 *
 * Not general yet - for future story :) TODO
 */
@Component({
  selector: 'app-zen-flyout',
  templateUrl: './zen-flyout.component.html',
  styleUrls: ['./zen-flyout.component.scss',
    '../portfolio-filter-flyout/portfolio-filter-flyout.component.scss'],
  providers: [ChecklistDatabase]
})
export class ZenFlyoutComponent implements OnInit {
  /**
   * The stream of data for the tree to show
   */
  treeDataSource: MatTreeFlatDataSource<ZenTreeNode, ZenTreeNode, ZenTreeNode>;
  /**
   * How to manipulate the tree
   */
  treeControl: FlatTreeControl<ZenTreeNode>;

  treeFlattener: MatTreeFlattener<ZenTreeNode, ZenTreeNode>
  /**
   * How to select things in the tree. Can retrieve all nodes that are selected with .selected
   */
  checklistSelection: SelectionModel<ZenTreeNode>;

  @Input() showFlyout: boolean;
  @Input() title: string;
  @Input() popperText: string;
  @Input() treeData: ZenTreeNode[] = [];
  @Input() dataSource: ZenTreeNode[];
  @Output() hideFlyoutFn = new EventEmitter<void>();
  @Output() callbackFn = new EventEmitter<ZenTreeNode[]>();

  constructor() {
    // literally 1 to 1 transformer... :/
    let transformer = (node: ZenTreeNode, level: number): ZenTreeNode => {
      return {
        label: node.label,
        children: node.children,
        level: level,
        parent: node.parent,
        expandable: node.expandable
      };
    };

    this.treeControl = new FlatTreeControl<ZenTreeNode>(
      node => node.level,
      node => node.expandable
    );

    this.treeFlattener = new MatTreeFlattener(
      transformer,
      node => node.level,
      node => node.expandable,
      node => node.children,
    );

    this.treeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.checklistSelection = new SelectionModel<ZenTreeNode>(true /* multiple */);
  }

  ngOnInit() {
    this.treeDataSource.data = this.treeData;

    // Populate selections based on url params
    if (this.dataSource && this.dataSource.length > 0) {
      this.updateSelectedCheckboxes();
    }
  }

  hasChild = (_: number, node: ZenTreeNode) => !!node.children && node.children.length > 0;

  clearSelections() {
    this.checklistSelection.clear();
  }

  isSelected(node: ZenTreeNode) {
    return this.checklistSelection.isSelected(node);
  }

  submitSelections() {
    // send filter string and selected nodes  to APIs and then hide filter window
    this.showFlyout = !this.showFlyout;
    let selected = this.checklistSelection.selected;
    this.hideFlyout();
    this.callbackFn.emit(selected);
  }

  handleCheckboxClicked(node: ZenTreeNode) {
    this.checklistSelection.toggle(node);
    if (node.children) { // Select all the node's children too
      this.checklistSelection.select(...node.children);
    }
  }

  onShow() {
    // When the flyout shows, pull from data source and update selected
    this.updateSelectedCheckboxes();
  }

  hideFlyout() {
    this.hideFlyoutFn.emit();
  }

  private updateSelectedCheckboxes() {
    // Clear selections
    this.clearSelections();
    this.dataSource.forEach(nodeToSelect => {
      let nodes = this.treeControl.dataNodes.filter(n => n.label == nodeToSelect.label);
      if (nodes) {
        this.checklistSelection.select(...nodes);
      }
    });
  }
}

export interface ZenTreeNode {
  label: string;
  parent: boolean; // If parent is true, chevron showed instead of checkbox.
  level: number;
  disabled?: boolean;
  expandable: boolean;
  children?: ZenTreeNode[];
}
