import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { UtilityAccountNumber } from '../_models/utility-account-number';
import {ItemListV4Model} from '../../_model/item-list-v4.model';
import {ValidPatterns} from '../_models/common/email-pattern';

export function validate(required?: boolean,
                         minLength?: number,
                         password?: boolean,
                         email?: boolean,
                         phone?: boolean): ValidatorFn[] {
  const validators: ValidatorFn[] = [];
  if (required) {
    validators.push(Validators.required);
  }
  if (minLength) {
    validators.push(Validators.minLength(minLength));
  }
  if (password) {
    validators.push(validatePassword());
  }
  if (email) {
    validators.push(Validators.pattern(ValidPatterns.EMAIL_PATTERN));
  }
  if (phone) {
    validators.push(validatePhone())
  }
  return validators;
}

export function validatePassword(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const value: string = control.value as string;
    let valid = value && value.length > 4 && value.trim() !== '';
    return !valid ? { 'password': {value: control.value}} : null;
  };
}

export function validatePhone(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const value: string = control.value as string;
    let valid = value && value.length >= 10  && value.trim() !== '';
    return !valid ? { 'phone': {value: control.value}} : null;
  };
}

export function validateUan(uan: UtilityAccountNumber): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const trimmed = control.value ? control.value.trim().replace(/_| /g, '') : control.value;
    const isValid = new RegExp(uan.validationExpression).test(trimmed);
    return isValid ? null : { utilAccountNo: { value: control.value } };
  };
}

// this validator fails if a non-empty value is provided, but it doesnt match any of the given options
export function isValidOption(options: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const value: string = control.value as string;
    let valid = !value || options.includes(value);
    return !valid ? { 'validoption': { value: control.value }} : null;
  };
}

export function isValidItemList(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const value: ItemListV4Model = control.value;
    let valid = !value || (value && value.key && value.value);
    return !valid ? { 'validoption': { value: control.value }} : null;
  };
}

export function isValidItemListV2(itemList: ItemListV4Model[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const value: (string | number) = control.value;
    let valid = !value || (itemList.some(item => item.key === value));
    return !valid ? { 'validoption': { value: control.value }} : null;
  };
}
