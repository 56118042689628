<div *ngIf="form" class="d-flex flex-wrap" [formGroup]="form">
  <div class="col-12 col-md-6 px-4">
    <div class="dialog-form-sec with-border" [hidden]="fieldsToHide.includes('customerName')">
      <div class="d-flex justify-content-center">
        <div class="w-100">

          <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-between">
              <p class="form-title mb-0">Associated with...</p>
              <button *ngIf="lockedCustomer && data.type === ZenDialogDataType.ADD_DIALOG"
                      class="filter-button d-flex align-items-center p-0" (click)="lockedCustomer=null">
                <span class="small">Show more</span>
                <i class="material-icons small">expand_more</i>
              </button>
            </div>
          </div>

          <!-- Customer form -->
          <div class="row mt-1 text-start">
            <div class="col-12 mb-2" *ngIf="!customerId || !lockedCustomer">
              <mat-form-field [appearance]="appearance" class="w-100">
                <mat-label>Customer Name*</mat-label>
                <input *ngIf="!lockedCustomerId" matInput [formControl]="customerCtrl" [matAutocomplete]="auto">
                <input *ngIf="lockedCustomerId" matInput [value]="customerCtrl.value?.value" disabled>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCustomerFn">
                  <mat-option *ngFor="let option of filteredCustomers | async" [value]="option"
                              (click)="handleCustomerSelection(option.key)">
                    {{option.value}}
                  </mat-option>
                </mat-autocomplete>
                <i class="material-symbols-rounded" matSuffix>expand_more</i>

                <mat-error *ngIf="customerCtrl.touched && customerCtrl.hasError('required')">Required Field*</mat-error>
                <mat-error *ngIf="customerCtrl.touched && customerCtrl.hasError('validoption')">Invalid
                  Customer*
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Locked Customer -->
            <div *ngIf="customerId && lockedCustomer" class="locked-input-value">
              <div class="d-flex align-items-center">
                <div class="m-0 d-flex align-items-center">
                  <i class="material-symbols-rounded ms-1">language</i>
                  <p class="text-md ms-2">{{lockedCustomer.value}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dialog-form-sec with-border mb-0">
      <div class="d-flex justify-content-center">
        <div class="w-100">

          <div class="row mb-3">
            <div class="col-12 text-start">
              <p class="form-title mb-0">Contract Details</p>
            </div>
          </div>

          <div class="row text-start">
            <div class="col-12 col-md-12 form-group" [hidden]="fieldsToHide.includes('state')">
              <mat-form-field [appearance]="appearance">
                <mat-label>{{'nomenclature.state' | translate}}*</mat-label>
                <input *ngIf="lockedState" matInput [value]="lockedState" disabled>
                <input *ngIf="!lockedState"  (ngModelChange)="onStateChange()" matInput formControlName="state" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [panelWidth]="100" [autoActiveFirstOption]="true">
                  <mat-option *ngFor="let option of filteredStateOptions | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
                <i class="material-symbols-rounded" matSuffix>expand_more</i>
                <mat-error *ngIf="hasError('state', 'required')">Required
                  Field*
                </mat-error>
                <mat-error *ngIf="hasError('state', 'validoption')">Invalid
                  {{'nomenclature.state' | translate}}*
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-12 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>{{'nomenclature.supplier' | translate}}*</mat-label>
                <input matInput [formControl]="supplierCtrl" [matAutocomplete]="supplierAuto">
                <mat-autocomplete #supplierAuto="matAutocomplete" [displayWith]="supplierDisplayFn">
                  <mat-option *ngFor="let supplier of filteredSuppliers | async" [value]="supplier">
                    {{supplier.supplierName}}
                  </mat-option>
                </mat-autocomplete>
                <i class="material-symbols-rounded" matSuffix>expand_more</i>
                <mat-error *ngIf="supplierCtrl.touched && supplierCtrl.hasError('required')">Required Field*
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-12 form-group">
              <div class="mb-2">
                <app-zen-unit-conversion-input label="Supply Rate*" [isRateField]="true"
                                               [commodity]="controls.commodityType?.value"
                                               [formCtrl]="controls.supplierRate"
                                               [selectedConversionUnit]="unit"></app-zen-unit-conversion-input>
              </div>
              <mat-error *ngIf="hasError('supplierRate', 'required')">Required Field*</mat-error>
              <mat-error *ngIf="(hasError('supplierRate', 'max')
                    || hasError('supplierRate', 'min'))">
                {{rateValidationMsg}}
              </mat-error>
            </div>
            <mat-checkbox color="accent" formControlName="draftContract" class="ms-1 mb-5">End Date and Term are currently unknown</mat-checkbox>

            <div class="col-12 col-md-12 form-group" #contractEndDiv>
              <mat-form-field [appearance]="appearance">
                <mat-label>Contract End*</mat-label>
                <input matInput name="contract-end" [matDatepicker]="dp" readonly (click)="dp.open()"
                       formControlName="planEndDate" placeholder="Choose a date">
                <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp startView="year"
                                (yearSelected)="setMonthAndYear($event, dp, true)"
                                (monthSelected)="setMonthAndYear($event, dp)"></mat-datepicker>
                <mat-error *ngIf="hasError('planEndDate', 'required')">Required Field*</mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-12 form-group">
              <div class="zen-input-group month-input">
                <!-- To fix - Field WONKY on Non-standard screen issue, I added max-width adjustment w.r.t the contractEndDiv width -->
                <mat-form-field [appearance]="appearance" class="{{!form.controls.draftContract.value ? 'left-field' : '' }}"
                                [style.max-width]="(contractEndDiv.scrollWidth - 115) + 'px'">
                  <mat-label>Term*</mat-label>
                  <input matInput formControlName="termMonths" [imask]="TermMask" [unmask]="true"/>
                  <mat-error *ngIf="hasError('termMonths', 'required')">Required Field*
                  </mat-error>
                  <mat-error *ngIf="hasError('termMonths', 'min') || hasError('termMonths', 'max')">
                    Required Field - Value must be between 1 and 72.
                  </mat-error>
                </mat-form-field>
                <mat-form-field *ngIf="!form.controls.draftContract.value" [appearance]="appearance" class="right-field">
                  <input matInput value="Months" [readonly]="true" class="pb-2"/>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-6 px-4">
    <div class="dialog-form-sec with-border mb-0">
      <div class="d-flex justify-content-center">
        <div class="w-100">

          <div class="row mb-3">
            <div class="col-12 text-start">
              <p class="form-title mb-0">Contract Strategy</p>
            </div>
          </div>

          <div class="row text-start">
            <div class="col-12 col-md-12 form-group" [hidden]="fieldsToHide.includes('service')">
              <mat-form-field [appearance]="appearance">
                <mat-label>Service*</mat-label>
                <mat-select *ngIf="!lockedCommodityType" formControlName="commodityType">
                  <mat-option *ngFor="let s of zenContractHelper.services" [value]="s.value">{{s.name}}</mat-option>
                </mat-select>
                <input matInput *ngIf="lockedCommodityType" [value]="commodityName" disabled/>

                <i class="material-symbols-rounded" matSuffix>expand_more</i>
                <mat-error *ngIf="hasError('commodityType', 'required')">Required Field*
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-12 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Contract Type*</mat-label>
                <mat-select formControlName="rateType">
                  <mat-option *ngFor="let rateType of zenContractHelper.contractTypes"
                              [value]="rateType.value">{{rateType.name}}</mat-option>
                </mat-select>
                <i class="material-symbols-rounded" matSuffix>expand_more</i>
                <mat-error *ngIf="hasError('rateType', 'required')">Required Field*
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-12 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Contract Configuration</mat-label>
                <mat-select formControlName="productModelId">
                  <mat-option *ngFor="let config of zenContractHelper.contractConfigs"
                              [value]="config.value">{{config.name}}</mat-option>
                </mat-select>
                <i class="material-symbols-rounded" matSuffix>expand_more</i>
              </mat-form-field>
            </div>

            <!-- Electricity Fields STARTS -->
            <ng-container *ngIf="controls.commodityType?.value === CommodityType.Electricity">
              <div class="col-12 col-md-12 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Source</mat-label>
                  <mat-select formControlName="source">
                    <mat-option *ngFor="let s of zenContractHelper.contractSources"
                                [value]="s.value">{{s.name}}</mat-option>
                  </mat-select>
                  <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  <mat-error *ngIf="hasError('source', 'required')">Required Field*
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
            <!-- Electricity Fields ENDS -->

            <!-- Gas Fields START -->
            <ng-container *ngIf="controls.commodityType?.value === CommodityType.Gas">
              <div class="col-12 col-md-12 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Basis Pricing Location</mat-label>
                  <mat-select formControlName="basisPricingLocation">
                    <mat-option [value]="NatGasPricingSolutionsEnum.BURNER_TIP">Burner Tip</mat-option>
                    <mat-option [value]="NatGasPricingSolutionsEnum.CITY_GATE">City Gate</mat-option>
                  </mat-select>
                  <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  <mat-error *ngIf="hasError('basisPricingLocation', 'required')">Required Field*
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 col-md-12 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Bandwidth % (Swing)</mat-label>
                  <input matInput formControlName="swing" type="number"/>
                  <mat-error *ngIf="hasError('swing', 'required')">Required Field*
                  </mat-error>
                  <mat-error *ngIf="(hasError('swing', 'max') || hasError('swing', 'min'))">
                    Required Field - Value must be between 0 and 100.
                  </mat-error>
                </mat-form-field>
              </div>

            </ng-container>

            <!-- Gas Fields ENDS -->

            <div class="col-12 col-sm-12 col-md-6 form-group">
              <!-- Renewable % field - will be in enabled state if commodity === Gas OR source !== ELECTRIC_STANDARD_MIX -->
              <!-- sourceFieldUpdate() method handles enableSource field. -->
              <mat-form-field [appearance]="appearance" [ngClass]="{'locked': !enableSource}">
                <mat-label>Renewable %</mat-label>
                <input matInput formControlName="greenPercent" type="number"/>
                <mat-error *ngIf="hasError('greenPercent', 'required')">Required Field*
                </mat-error>
                <mat-error *ngIf="(hasError('greenPercent', 'max')
                  || hasError('greenPercent', 'min'))">
                  {{controls.commodityType.value === CommodityType.Gas ? 'Required Field - Value must be between 0 and 100.'
                  : 'Required Field - Value must be between 1 and 100.'}}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-sm-12 col-md-6 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Billing Method</mat-label>
                <mat-select formControlName="billingMethod">
                  <mat-option [value]="BillingMethod.dual">Dual</mat-option>
                  <mat-option [value]="BillingMethod.consolidated">Consolidated</mat-option>
                </mat-select>
                <i class="material-symbols-rounded" matSuffix>expand_more</i>
                <mat-error *ngIf="hasError('billingMethod', 'required')">Required Field*
                </mat-error>
              </mat-form-field>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!(isProcurementSnapshot && form.controls.draftContract.value)" class="dialog-form-sec with-border mb-0 mt-5">
      <div class="d-flex justify-content-center">
        <div class="w-100">

          <div class="row mb-3">
            <div class="col-12 text-start">
              <p class="form-title mb-0">Contract Document</p>
            </div>
            <div class="col-12 col-md-12 form-group mt-3">
              <app-zen-file-upload
                [isDisabled]="form.controls.draftContract.value"
                [formCtrl]="form.controls.tempFileId" label="Contract"
                [uploading]="uploading" [documentName]="documentName" [downloadable]="contractDocumentId"
                (downloadDocument)="downloadDocument()" (onFileInput)="onFileInput($event)"
              ></app-zen-file-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Warning Section -->
    <div *ngIf="form.controls.draftContract.value" class="row mt-3">
      <div class="col-12">
        <div class="custom-warning">
          <div class="icon-container">
            <i class="icon yellow material-symbols-outlined">warning</i>
          </div>
          <div class="text-container">
            This contract will be saved as a Draft. Request a copy of the contract
            <span class="or-text">or</span> have the client e-sign the LOE/MLOA and allow our team to confirm the contract end date and term directly with the supplier.
          </div>
        </div>
      </div>
    </div>

    <!-- End of Warning Section -->
  </div>
</div>
<div mat-dialog-actions class="me-4 mt-3">
  <ng-container *ngFor="let btn of data.actionButtons">
    <button mat-button [ngClass]="btn?.btnType || 'mat-button'" [class]="btn?.styleClass" type="button"
            (click)="submit(btn)" [color]="btn.color" [disabled]="disableButton"
            [class.loading]="disableButton">{{btn.label}}

      <mat-spinner diameter="25" *ngIf="disableButton && btn?.btnType === ButtonTypes.MAT_RAISED_BUTTON"
                   [strokeWidth]="2" color="accent"></mat-spinner>
    </button>
  </ng-container>
</div>
