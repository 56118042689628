import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Organization} from '../../_shared/_zen-legacy-common/_models/organization';
import {ZenUser, ZenUserV1Model} from '../../_shared/_zen-legacy-common/_models/zen-user';
import {AuthenticationService, ROLE} from '../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {
  OrganizationManagementService
} from '../../_shared/_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {ProfileService} from '../../_shared/_zen-legacy-common/zen-common-services/_services/profile.service';
import {ZenDialogMsgService} from '../../_shared/_services/zen-dialog-msg.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ButtonActionTypes, ButtonTypes, ZenDialogDataModel} from '../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {ZenUserProfileDialogComponent} from '../../_shared/_dialogs/zen-user-profile-dialog/zen-user-profile-dialog.component';
import {ZenDialogPanelClassEnum, ZenDialogSizeEnum} from '../../_shared/_enums/zen-dialogs.enum';
import {ZenIconsEnum} from '../../_shared/_enums/zen-icons.enum';
import {ZenBreadcrumbService} from '../../_shared/_services/zen-breadcrumb.service';
import {NavigationService} from '../../_shared/_zen-legacy-common/zen-common-services/_services/navigation.service';
import {DEFAULT_LOGO_PATH} from '../../_modules/portfolio-shared/_shared/portfolio-platform-logo/portfolio-platform-logo.component';
import {PortfolioHelperService} from '../../_modules/portfolio/_services/_helpers/portfolio-helper.service';
import {MatDrawer} from '@angular/material/sidenav';
import {ZenRoutesEnum} from '../../_shared/_enums/zen-routes.enum';
import {ZenErrorMsgEnum} from '../../_shared/_enums/zen-error-msg.enum';
import {CustomerIdSessionService} from '../../_shared/_services/customer-id/customer-id-session.service';
import {CacheV4Service} from '../../_shared/_services/v4/cache-v4.service';
import {EntityTypeService} from '../../_shared/_zen-legacy-common/zen-common-services/_services/entity-type.service';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ZenBaseWithTranslateComponent} from '../../_shared/_components/zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';

const MOBILE_WIDTH = 1249;
const DESKTOP_WIDTH = 1600;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends ZenBaseWithTranslateComponent implements OnInit, OnDestroy {
  organization: Organization;
  firstLetter: string;
  user: ZenUser;
  companyLogoPath: string;
  smallDrawer: boolean;

  menuItems = [];

  isDesktop = true;
  isMobile: boolean;

  isOpened = true;

  showSettings: boolean;

  // When a user is logged in as an advisor but open a customer link - show the following message at
  // the top of the page to allow them to switch back to their advisor profile.
  advisorUserLoggedCustomerName: string;

  private readonly destroy$ = new Subject<void>();

  constructor(private orgSvc: OrganizationManagementService, private profileService: ProfileService,
              private zenDialogSvc: ZenDialogMsgService,
              public auth: AuthenticationService, private dialog: MatDialog, private router: Router,
              public navSvc: NavigationService, public zenBreadCrumbService: ZenBreadcrumbService,
              private pfHelperService: PortfolioHelperService,
              private customerIdSvc: CustomerIdSessionService,
              private entityTypeSvc: EntityTypeService,
              public translateSvc: TranslateService,
              private cacheV4Svc: CacheV4Service) {
    super(translateSvc);
    this.advisorUserLoggedCustomerName = null;

    // Checking an Advisor user logged and viewing customer pages. If, showing a blue banner on the top.
    if (auth.isAdvisor() && !router.url.includes(ZenRoutesEnum.ADVISOR) && customerIdSvc.getCustomerId()) {
      this.auth.getCustomer(customerIdSvc.getCustomerId()).subscribe({
        next: customer => {
          this.advisorUserLoggedCustomerName = customer?.companyName;
        }, error: () => {
          this.zenDialogSvc.openToast(false, ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
    }
  }

  ngOnInit(): void {
    // Loading common entities
    this.entityTypeSvc.populateEntityTypes();

    this.handleScreenChange();
    this.smallDrawer = (window.innerWidth > MOBILE_WIDTH && window.innerWidth < DESKTOP_WIDTH);

    this.orgSvc.organizationSubject
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(o => {
        this.organization = o;
        this.companyLogoPath = o.imagePath;
        this.handleScreenChange();
      });

    this.setMenuItems();
    this.auth.currentUserSub
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => this.setMenuItems());

    this.profileService.getUser()
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(user => this.handleUserData(user));

    // Customer cant access org settings page
    this.showSettings = this.auth.isAdvisor() || this.auth.isAdmin();

    this.orgSvc.onHideArrChangeEmit
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => this.setMenuItems());
  }

  ngOnDestroy() {
    this.destroy$.next(); // Trigger the observable
    this.destroy$.complete(); // Complete the observable. So, further fires triggers wont happen.
  }

  setMenuItems() {
    this.menuItems = this.getLeftNavBarItems();
  }

  handleUserData(user: ZenUser) {
    this.firstLetter = user.firstName.charAt(0).toUpperCase();
    this.user = user;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    setTimeout(() => {
      this.smallDrawer = Boolean(window.innerWidth > MOBILE_WIDTH && window.innerWidth < DESKTOP_WIDTH);
      this.handleScreenChange();
    }, 100);
  }

  handleScreenChange() {
    this.isDesktop = window.innerWidth > MOBILE_WIDTH;
    this.isMobile =  window.innerWidth < MOBILE_WIDTH;
    this.isOpened = window.innerWidth > MOBILE_WIDTH;
    if (this.organization) {
      const {imagePath, imageSmallPath} = this.organization;
      // If there is no or any one of the org logo is not uploaded and below condition miss match we show DEFAULT_LOGO_PATH.
      this.companyLogoPath = ((this.smallDrawer) ? imageSmallPath : imagePath) || DEFAULT_LOGO_PATH;
    }
  }

  toggleSmallDrawer() {
    this.smallDrawer = !this.smallDrawer;
    this.handleScreenChange();
  }

  handleEditUser() {
    let dialogData: ZenDialogDataModel = {
      // type: ZenDialogDataType.CONFIRM,
      header: {title: 'Edit User', icon: ZenIconsEnum.SETTINGS},
      bodyText: 'Enter the data below to manage your user profile.',
      onClose: () => dialogRef.close(),
      actionButtons: [
        {label: 'Cancel', command: () => dialogRef.close(), actionType: ButtonActionTypes.CANCEL},
        {
          label: 'Save', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
          actionType: ButtonActionTypes.SUBMIT,
          command: (user: ZenUserV1Model) => {
            this.zenDialogSvc.openToast(true);
            this.handleUserData(user);
            dialogRef.close();
          }
        }
      ],
    };
    const dialogRef = this.dialog.open(ZenUserProfileDialogComponent, {
      width: ZenDialogSizeEnum.MEDIUM,
      panelClass: ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT,
      data: dialogData, autoFocus: false
    });
  }

  /**
   * Creates the left hand nav for advisors or customers
   */
  getLeftNavBarItems() {
    if (this.auth.isCustomer()) {
      // customer nav
      return [
        {icon: ZenIconsEnum.DASHBOARD, title: 'Dashboard', link: this.navSvc.getCustomerDashboardPageRoute()},
        {icon: ZenIconsEnum.PORTFOLIO, title: 'Portfolio', link: this.navSvc.getPortfolioPageRoute()},
        {icon: ZenIconsEnum.RATE_CHECK, title: 'Rate Checks', link: this.navSvc.getRateCheckListPageRoute()},
        {icon: ZenIconsEnum.CONTRACTS_V2, title: 'Contracts', link: this.navSvc.getContractListPageRoute()},
        {icon: ZenIconsEnum.STACK_RANKING, title: 'Stack Ranking', link: this.navSvc.getStackRankingPageRoute()},
      ]
    } else {
      // advisor nav
      let menuItems = [
        {icon: ZenIconsEnum.PORTFOLIO, title: 'Portfolio', link: this.navSvc.getPortfolioPageRoute()}, ,
        {icon: ZenIconsEnum.RATE_CHECK, title: 'Rate Checks', link: this.navSvc.getRateCheckListPageRoute()},
        {icon: ZenIconsEnum.CONTRACTS_V2, title: 'Contracts', link: this.navSvc.getContractListPageRoute()},
        {icon: ZenIconsEnum.STACK_RANKING, title: 'Stack Ranking', link: this.navSvc.getStackRankingPageRoute()},
        {
          icon: ZenIconsEnum.MARKET_PULSE, title: 'MarketPulse', link: this.navSvc.getMarketPulsePageRoute(),
          hide: !this._translations?.features?.featureMarketPulse
        }
      ];

      if (this.organization.settings?.featureOpportunities) {
        menuItems.splice(1, 0, {icon: ZenIconsEnum.OPPORTUNITIES, title: 'Opportunities', link: this.navSvc.getOpportunitiesPageRoute()});
      }

      if (this.showPayments()) {
        menuItems.push({icon: ZenIconsEnum.PAYMENTS, title: 'Payments', link: this.navSvc.getPaymentsPageRoute()});
      }
      return menuItems;
    }
  }

  /**
   * Show payments if:
   * - this org doesn't have a parent org
   * - user is an admin OR an advisor with the correct roles
   */
  showPayments(): boolean {
    // isAdmin() method includes ROLE_ADMIN_SUPPLIERS. So, excluding that user from payments page permitted roles.
    return !this.orgSvc.organization.parentOrganizationId && !this.orgSvc.hideArr &&
      this.auth.hasAnyRole([ROLE.ROLE_ADMIN_SUPER, ROLE.ROLE_ADMIN_PAYMENTS, ROLE.ROLE_ORG_ADMIN, ROLE.ROLE_ADVISOR_FINANCE]);
  }

  gotoAdvisorDash() {
    this.router.navigate(this.navSvc.getPortfolioPageRoute());
  }


  closeSideNavAndClearFilters(sideNav: MatDrawer) {
    sideNav.close();
    this.clearFilters()
  }

  /**
   * Only clear filters if we are on the customer level for Admins
   */
  clearFilters() {
    if (this.navSvc.isCustomerLevelForAdmin()) {
      this.pfHelperService.resetAllFilters();
    } else {
    }
  }

  clearCache() {
    this.cacheV4Svc.clearOrgCache().subscribe({
      next: () => {
        this.zenDialogSvc.openToast(true);
      }, error: e => {
        console.log('Error: clear org cache ', e);
        this.zenDialogSvc.openToast(false);
      }
    });
  }
}
