<app-zen-dialog-wrapper [data]="data" [loading]="loading" (onSubmit)="submit($event)" actionStyleCls="center">

  <!-- commodityExistCheck only available for service addresses  -->
  <div class="my-4 d-flex flex-column align-items-center" *ngIf="commodityExistCheck">
    <p class="text-md text-start mb-3">Select the service type.</p>
    <div class="d-flex flex-wrap rc-req-service-address-commodity-selection-dialog mb-1">
      <div class="me-5">
        <app-zen-icon-button title="Electricity" [icon]="ZenIcons.ELECTRIC"
                             [disabled]="!commodityExistCheck?.hasElectric"
                             [selected]="selectedCommodity.value === Commodity.Electricity"
                             [unselected]="selectedCommodity.value === Commodity.Gas"
                             (click)="updateCommodity(Commodity.Electricity)"></app-zen-icon-button>
      </div>

      <div class="">
        <app-zen-icon-button title="Natural Gas" [icon]="ZenIcons.NAT_GAS"
                             [disabled]="!commodityExistCheck?.hasGas"
                             [selected]="selectedCommodity.value === Commodity.Gas"
                             [unselected]="selectedCommodity.value === Commodity.Electricity"
                             (click)="updateCommodity(Commodity.Gas)"></app-zen-icon-button>
      </div>
    </div>

    <mat-error *ngIf="selectedCommodity.touched && selectedCommodity.hasError('required')">Required Field*</mat-error>
  </div>

  <div class="mb-4">
    <p class="text-md">
      <b>Note:</b> Only procurable meters with known usage can be added to a RateCheck.

      <i class="material-icons-outlined blue-1-color pointer vertical-align-middle medium"
         [popper]="supplierInfoPopper" [popperHideOnScroll]="true" [popperHideOnClickOutside]="true"
         [popperPositionFixed]="true" popperTrigger="click" popperApplyClass="sm">info</i>
    </p>
  </div>

  <popper-content #supplierInfoPopper>
    <app-zen-popper [popperContent]="supplierInfoPopper">
      <p class="text-sm mb-3">For {{'nomenclature.suppliers' | translate}} to be able to provide basic pricing the following is required:</p>
      <p class="text-sm mb-1"><b>Deregulated Area:</b> The state and/or utility allows competition among energy {{'nomenclature.suppliers' | translate}}.</p>
      <p class="text-sm"><b>Usage:</b> Usage information is provided.</p>
      <p class="text-sm"><b>Procurement Status:</b> Indicates if a meter is under contract, and if so, includes the contract end date.</p>
    </app-zen-popper>
  </popper-content>

</app-zen-dialog-wrapper>
