import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../_services/authentication.service';
import {map, take} from 'rxjs/operators';
import {ContractSignature} from '../../../_models/contract';
import {Document} from '../../../_models/app-rate-check-v2';
import {TermsAndConditionsAcceptance} from '../models/tac';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomerTiliService {
  baseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthenticationService) { }

  signLoeMloa(customerId: number, signature: ContractSignature, signerId?: string): Observable<Document> {
    const signParam =  signerId ? `?signer=${signerId}` : '';
    const url = `${this.baseUrl}/v2/organizations/${this.authService.getOrganizationId()}/customers/${customerId}/legal/loe-mloa/sign${signParam}`;
    return this.http.post<Document>(url, signature).pipe(take(1));
  }

  loeMloaUrl(customerId: number): Observable<string> {
    const url = `${this.baseUrl}/v2/tili/customers/${customerId}/loe-mloa-url`;
    return this.http.get(url, {responseType: 'text'})
      .pipe(
        map((loeMloaUrl: string) =>  {
          if (loeMloaUrl.startsWith('https://') || loeMloaUrl.startsWith('http://')) {
            return loeMloaUrl;
          }
          if (environment.apiBaseUrl.startsWith('https')) {
            return `https://${loeMloaUrl}`;
          }
          return `http://${loeMloaUrl}`;
        }),
        take(1)
      );
  }

  acceptTermsAndConditions(customerId: number, tacId: string): Observable<TermsAndConditionsAcceptance> {
    const url = `${this.baseUrl}/v2/organization/${this.authService.getOrganizationId()}/customer/${customerId}/tac/${tacId}/sign`;
    return this.http.post<TermsAndConditionsAcceptance>(url, {});
  }

  async acceptTacFn(customerId: number, tacId: string) {
    try {
        await this.acceptTermsAndConditions(customerId, tacId).toPromise();
        this.onSuccessfulTACApproval();
        return true;
    } catch (e) {
      return false;
    }
  }

  onSuccessfulTACApproval() {
    this.authService.setUserApprovalStatus(AuthenticationService.CUSTOMER_TAC_APPROVED, true);
    this.authService.toggleTacApprovalPopup.next(true);
  }

}
