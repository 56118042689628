import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-zen-datepicker',
  templateUrl: './zen-datepicker.component.html',
  styleUrl: './zen-datepicker.component.scss'
})
export class ZenDatepickerComponent {
  @Input() formCtrl: FormControl;
  ZenIcons = ZenIconsEnum;
  @Output() dateChanged = new EventEmitter();
}
