import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ZenUnitsHelperService} from '../../_services/helpers/zen-units-helper.service';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';
import {FilterClass} from '../../_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {DecimalPipe} from '@angular/common';
import {ZenNumberFormat} from '../../_enums/zen-number-format.enum';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CommodityFeeFormatPipe} from '../../_pipes/commodity-fee-format.pipe';
import {ZenBaseWithTranslateComponent} from '../zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-zen-unit-conversion-input',
  templateUrl: './zen-unit-conversion-input.component.html',
  styleUrls: ['./zen-unit-conversion-input.component.scss']
})
export class ZenUnitConversionInputComponent extends ZenBaseWithTranslateComponent implements OnInit, OnChanges {
  @Input() formCtrl: UntypedFormControl;
  @Input() convertedValueCtrl = new UntypedFormControl();
  @Input() commodity: CommodityType;
  @Input() label: string;
  @Input() isUsageField: boolean;
  @Input() hideUnitSelection: boolean;
  @Input() selectedConversionUnit = new UntypedFormControl() // ZenUnitsEnum;
  @Input() isRateField: boolean; // Used to define its a Usage field or Rate field
  @Output() onUnitChange: EventEmitter<ZenUnitsEnum> = new EventEmitter();
  @Output() onValueChange: EventEmitter<void> = new EventEmitter();

  baseUnit: ZenUnitsEnum;
  unitOptions: FilterClass[] = [];
  isBaseUnitSelected: boolean;

  mask; // (ElectricityFeeMask | GasFeeMask);
  ZenUnitsEnum = ZenUnitsEnum;
  CommodityType = CommodityType;

  constructor(private zenUnitsSvc: ZenUnitsHelperService,
              private decimalPipe: DecimalPipe,
              private translateService: TranslateService,
              private feeFormat: CommodityFeeFormatPipe) {
    super(translateService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.hideUnitSelection) {
      this.handleUnitChange(this.baseUnit);
    }
  }

  ngOnInit(): void {
    this.mask = {
      mask: Number,
      scale: this.isUsageField ? 4 : 2,
      padFractionalZeros: !this.isUsageField,  // if true, then pads zeros at end to the length of scale
      normalizeZeros: true,  // appends or removes zeros at ends
      radix: '.',  // fractional delimiter
      thousandsSeparator: ','
    };

    this.baseUnit = this.commodity === CommodityType.Electricity ? ZenUnitsEnum.kWh : ZenUnitsEnum.Dth;
    this.isBaseUnitSelected = Boolean(this.selectedConversionUnit.value === this.baseUnit);
    this.unitOptions = this.zenUnitsSvc.getAllUnitOptions(this.commodity, Boolean(this.isRateField));
    this.updateConversionValue(false);


    if (!this.isUsageField) {
      this.updateDecimalPrecession();
    }

    if (this.hideUnitSelection) {
      this.handleUnitChange(this.baseUnit);
    }

    // Handle form ctrl changes
    this.formCtrl.valueChanges.pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(() => {
        this.onValueChange.emit();
        this.updateConversionValue(false);
      });
  }

  /** NOTE: This is just a temporary fix. The proper fix is upgrading angular & angular-imask to 14.
   * Pad zero issue fix updated with new version of angular-imask:
   * https://github.com/gabrieljablonski/imaskjs/commit/a076513f777467b612b6f3671a5d8e5ab98c2fda */
  updateDecimalPrecession() {
    setTimeout(() => {
      this.mask = {
        ...this.mask,
        scale: this.commodity === CommodityType.Gas ? 4 : 5,  // digits after point, 0 for integers -- ZenNumberFormat.ELECTRIC_FEE_IN_DOLLARS = '1.2-5',
        padFractionalZeros: false  // if true, then pads zeros at end to the length of scale
      };
    });
  }

  handleUnitChange(label: string) {
    const unit = this.unitOptions.find(u => u.label === label)?.value as ZenUnitsEnum;
    if (unit) {
      this.formCtrl.markAsUntouched();
      this.selectedConversionUnit.setValue(unit);
      this.isBaseUnitSelected = Boolean(this.selectedConversionUnit.value === this.baseUnit);
      this.updateConversionValue();
    }
  }

  updateConversionValue(emitEvent = true) {
    if (this.isRateField) {
      // For rate field the input calculation will be $/unit
      this.convertedValueCtrl.setValue(
        this.feeFormat.transform(this.zenUnitsSvc.convertRateToBaseUnitValue(this.selectedConversionUnit.value, parseFloat(this.formCtrl.value)), {commodity: this.commodity})
      );
    } else {
      // For usage field the input calculation will be direct
      this.convertedValueCtrl.setValue(
        this.decimalPipe.transform(this.zenUnitsSvc.getSelectedUnitCalculatedUsageValue(this.selectedConversionUnit.value, parseFloat(this.formCtrl.value)), ZenNumberFormat.USAGE)
      );
    }
    if (emitEvent) {
      this.onUnitChange.emit(this.selectedConversionUnit.value);
    }
  }

}
