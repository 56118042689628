import {Component, OnInit} from '@angular/core';
import {TimelineStatus} from '../../../../modules/zentility/_modules/portfolio/_model/portfolio-meters.model';
import {
  ZenTableColsModel,
  ZenTableConfig,
  ZenTableMenuOption
} from '../../../../modules/zentility/_shared/_components/zen-mat-table/zen-mat-table.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {
  PortfolioTabTopActionConfig
} from '../../../../modules/zentility/_shared/_components/zen-tab-top-action/zen-tab-top-action.component';
import {ZenRcTableEnum} from '../../../../modules/zentility/_modules/zen-rate-checks/_enum/zen-rc-table.enum';
import {ZenRcStatus, ZenRcStatusClass} from '../../../../modules/zentility/_modules/zen-rate-checks/_enum/zen-rc-v4.enum';
import {ZenIconsEnum} from '../../../../modules/zentility/_shared/_enums/zen-icons.enum';
import {MatTableSize} from '../../../../modules/zentility/_shared/_enums/zen-mat-table.enum';
import {NgxPopperjsPlacements} from 'ngx-popperjs';
import {ZenColumnInfoText} from '../../../../modules/zentility/_shared/_enums/zen-column-info-text.enum';
import {CommodityType} from '../../../../modules/zentility/_shared/_zen-legacy-common/_models/commodity';
import {ZenMatTableHelperService} from '../../../../modules/zentility/_shared/_services/helpers/zen-mat-table-helper.service';


const LOGO_URL = '/assets/img/img-thumbnail.svg';

@Component({
  selector: 'app-zen-mat-table-story',
  templateUrl: './zen-mat-table-story.component.html',
  providers: [ZenMatTableHelperService],
  styleUrls: ['./zen-mat-table-story.component.scss', '../zen-mat-form-fields/zen-mat-form-fields.component.scss',
    '../../../../modules/zentility/layout-modules/layout/layout.component.scss',
    '../../../../modules/zentility/_modules/zen-stack-ranking/_components/zen-stack-ranking-list/zen-stack-ranking-list.component.scss']
})
export class ZenMatTableStoryComponent implements OnInit {

  data = [
    {
      active: false,
      position: 1,
      lenCount: 1,
      customerLogo: LOGO_URL,
      customerStatusCls: 'active',
      usage: '6,000,000 kWh',
      usageType: 'lightbulb',
      usageTypeCls: 'electric',
      companyName: 'Hydrogen',
      documentDownloadIcon: 'download',
      customerNameSubText: 'Send LOE/MLOA',
      customerNameSubTextHyperlinked: true,
      atRisk: 'H',
      risk: '71%',
      todayPercentage: '50%',
      todaySubText: 'Contracted',
      procurement: {
        timelines: [{
          endDate: '2022-09-02',
          energyPlanId: 12274,
          monthsRemaining: 0,
          percent: 100,
          provider: null,
          providerType: null,
          startDate: '2022-09-02',
          termMonths: 0,
          timelineStatus: TimelineStatus.ON_UTILITY
        }]
      },
      annualEnergySpend: '$221,867', annualEnergyStatus: ZenRcStatusClass.EXPIRED,
    },
    {
      active: true,
      position: 2,
      lenCount: 2,
      customerLogo: LOGO_URL,
      customerStatusCls: 'inactive',
      usage: '6,000,000 Dth',
      usageType: 'whatshot',
      usageTypeCls: 'nat-gas',
      companyName: 'Helium',
      risk: '25%',
      riskIcon: 'info',
      documentDownloadIcon: 'download',
      riskIconCls: 'danger no-bg',
      customerNameSubText: 'Send LOE/MLOA',
      customerNameSubTextHyperlinked: true,
      usageKwh: 4.0026,
      atRisk: 'He',
      todayPercentage: '50%',
      todaySubText: 'Contracted',
      atRiskTextCls: 'font-weight-bold danger_1',
      procurement: {
        timelines: [
          {
            'startDate': '2021-08-13',
            endDate: '2024-09-01',
            'termMonths': 36,
            'monthsRemaining': 24,
            'percent': 66.7,
            'energyPlanId': 6047,
            'timelineStatus': TimelineStatus.CURRENT_CONTRACT,
            'provider': null,
            'providerType': null
          },
          {
            'startDate': '2022-07-01',
            'endDate': '2022-09-01',
            'termMonths': 12,
            'monthsRemaining': 0,
            'percent': 33.30,
            'energyPlanId': 0,
            'timelineStatus': TimelineStatus.EXPIRED,
            'provider': null,
            'providerType': null
          }
        ]
      },
      annualEnergySpend: '$221,867', annualEnergyStatus: ZenRcStatusClass.PROCESSING
    },
    {
      active: true,
      position: 2,
      lenCount: 2,
      customerLogo: LOGO_URL,
      customerStatusCls: 'inactive',
      usage: '6,000,000 Dth',
      usageType: 'whatshot',
      usageTypeCls: 'nat-gas',
      companyName: 'Helium',
      risk: '25%',
      riskIcon: 'info',
      documentDownloadIcon: 'download',
      riskIconCls: 'danger no-bg',
      customerNameSubText: 'LOE/MLOA Signed',
      usageKwh: 4.0026,
      atRisk: 'He',
      atRiskTextCls: 'font-weight-bold danger_1',
      todayPercentage: '50%',
      todaySubText: 'Contracted',
      procurement: {
        timelines: [{
          endDate: '2022-09-02',
          energyPlanId: 12274,
          monthsRemaining: 0,
          percent: 100,
          provider: null,
          providerType: null,
          startDate: '2022-09-02',
          termMonths: 0,
          timelineStatus: TimelineStatus.EXPIRED
        }]
      },
      annualEnergySpend: '$221,867', annualEnergyStatus: ZenRcStatusClass.FUTURE
    }
  ];

  cols: ZenTableColsModel[] = [
    {
      field: 'companyName', header: 'Customer Name', type: 'standard', enableTooltip: true, hyperlink: true,
      imageCol: 'imageSmall', statusCol: 'customerStatusCls', sticky: true,
      subTextCol: 'customerNameSubText', subTextLinkEnabledCol: 'customerNameSubTextHyperlinked',
      handleSubTextClick: (col, rowData) => console.log('handleSubTextClick'),
      headerTooltipText: ZenColumnInfoText.CUSTOMER_NAME,
    },
    {field: 'usageKwh', header: 'Usage (kWh)', headerColIcon: 'lightbulb',  type: 'standard',
      align: 'center', headerTooltipText: ZenColumnInfoText.ELEC_USAGE},
    {field: 'todayPercentage', header: 'Today', align: 'center', subTextCol: 'todaySubText', type: 'standard',},
    {field: 'usage', header: 'Usage', iconCol: 'usageType', iconClsCol: 'usageTypeCls', iconPosition: 'order-first', type: 'standard'},
    {
      field: 'risk', textClassCol: 'atRiskTextCls', header: 'At Risk', iconCol: 'riskIcon',
      iconClsCol: 'riskIconCls', iconPosition: 'order-last', type: 'standard',
      headerTooltipText: ZenColumnInfoText.AT_RISK, align: 'center'
    },
    {field: 'annualEnergySpend', header: 'Annual Energy Spend', borderLeftColor: 'annualEnergyStatus', type: 'standard'},
    {field: 'documentId', header: 'Download', iconCol: 'documentDownloadIcon', whiteSpace: true, align: 'center', type: 'standard'},
    {field: 'menu', type: 'menu', stickyEnd: true},
  ];

  tableMenuOptions: ZenTableMenuOption[] = [
    {label: 'Refresh Data', type: 'button', command: (selectedData: []) => console.log('Refresh Data ')},
    {label: 'Active', type: 'toggle', toggleDefaultVal: true, field: 'active', command: (selectedData: [], menu) => console.log('View ')},
    {label: 'Export', type: 'button', command: (selectedData: []) => console.log('Export ')},
    {label: 'Delete', type: 'button', command: (selectedData: []) => console.log('Delete ')},
  ];

  pfRowMenuOptions: ZenTableMenuOption[] = [
    {label: 'View', type: 'button', command: (rowData) => console.log('view ', rowData)},
    {label: 'Edit', type: 'button', command: (rowData) => console.log('Edit ', rowData)},
    {label: 'Send Welcome Email', type: 'button', command: (rowData) => console.log('Send Welcome Email ', rowData)},
    {label: 'Send LOE/MLOA', type: 'button', command: (rowData) => console.log('Send LOE/MLOA ', rowData)},
    {label: 'Active', type: 'toggle', field: 'active', command: (rowData) => console.log('view ', rowData)},
    {label: 'Delete', type: 'button', command: (rowData) => console.log('Delete ', rowData)},
  ];


  tableConfig = {
    styles: {'min-width': MatTableSize.MEDIUM},
    cols: this.cols,
    tableMenuOptions: this.tableMenuOptions,
    rowMenuOptions: this.pfRowMenuOptions,
    totalLength: this.data.length
  }

  /* RC Table */
  rcTableTopConfig: PortfolioTabTopActionConfig = {};
  ZenRcTableEnum = ZenRcTableEnum;

  openTableConfig: ZenTableConfig;
  closedTableConfig: ZenTableConfig;
  rcSelectedTab = ZenRcTableEnum.OPEN;

  rcRowMenuOptions: ZenTableMenuOption[] = [];

  openRcs = [
    {
      customerName: 'C1', customerLens: ['101 West LLC'],
      status: ZenRcStatus.DRAFT, statusCls: ZenRcStatusClass.DRAFT,
      serviceType: ZenIconsEnum.ELECTRIC, serviceTypeCls: 'electric',
      usage: '2,000,000 kWh', state: 'MA', dueDate: 'May 7, 2021',
      meterCount: 24, notes: null, arr: '$67,886'
    },
    {
      customerName: 'C2',
      customerLens: ['101 West LLC', '102 West LLC'],
      status: ZenRcStatus.TIMED_OUT,
      statusCls: ZenRcStatusClass.TIMED_OUT,
      serviceType: ZenIconsEnum.ELECTRIC, serviceTypeCls: 'electric', usage: '2,000,000 kWh',
      state: 'MA', dueDate: 'May 7, 2021',
      meterCount: 24, notes: 'GREEN, NOTES', arr: '$7,886'
    },
    {
      customerName: 'C3', customerLens: ['101 West LLC', '102 West LLC', '103 West LLC'],
      status: ZenRcStatus.BIDDING, statusCls: ZenRcStatusClass.BIDDING,
      serviceType: ZenIconsEnum.NAT_GAS, serviceTypeCls: 'nat-gas', usage: '100,000 Dth',
      state: 'MA', dueDate: 'May 7, 2021',
      meterCount: 24, notes: 'GRN, Cap/Trans Pass through', arr: '$86'
    },
    {
      customerName: 'C4',
      customerLens: ['101 West LLC', '102 West LLC', '103 West LLC'],
      status: ZenRcStatus.SUPPLIER_REVIEW,
      statusCls: ZenRcStatusClass.SUPPLIER_REVIEW,
      serviceType: ZenIconsEnum.NAT_GAS, serviceTypeCls: 'nat-gas', usage: '100,000 Dth',
      state: 'MA', dueDate: 'May 7, 2021',
      meterCount: 24, notes: 'Notes', arr: '$6'
    }
  ];

  closedRcs = [
    {
      customerName: 'C1', customerLens: ['101 West LLC', '102 West LLC', '103 West LLC'],
      status: ZenRcStatus.DRAFT, statusCls: ZenRcStatusClass.DRAFT,
      serviceType: ZenIconsEnum.ELECTRIC, serviceTypeCls: 'electric',
      usage: '2,000,000 kWh', state: 'MA', dateClosed: 'May 7, 2021',
      meterCount: 24, notes: null, arr: '$67,886'
    },
    {
      customerName: 'C2',
      customerLens: ['101 West LLC', '102 West LLC', '103 West LLC'],
      status: ZenRcStatus.TIMED_OUT,
      statusCls: ZenRcStatusClass.TIMED_OUT,
      serviceType: ZenIconsEnum.ELECTRIC, serviceTypeCls: 'electric', usage: '2,000,000 kWh',
      state: 'MA', dateClosed: 'May 7, 2021',
      meterCount: 24, notes: 'GREEN, NOTES', arr: '$7,886'
    },
    {
      customerName: 'C3', customerLens: ['101 West LLC', '102 West LLC', '103 West LLC'],
      status: ZenRcStatus.BIDDING, statusCls: ZenRcStatusClass.BIDDING,
      serviceType: ZenIconsEnum.NAT_GAS, serviceTypeCls: 'nat-gas', usage: '100,000 Dth',
      state: 'MA', dateClosed: 'May 7, 2021',
      meterCount: 24, notes: 'GRN, Cap/Trans Pass through', arr: '$86'
    },
    {
      customerName: 'C4',
      customerLens: ['101 West LLC', '102 West LLC', '103 West LLC'],
      status: ZenRcStatus.SUPPLIER_REVIEW,
      statusCls: ZenRcStatusClass.SUPPLIER_REVIEW,
      serviceType: ZenIconsEnum.NAT_GAS, serviceTypeCls: 'nat-gas', usage: '100,000 Dth',
      state: 'MA', dateClosed: 'May 7, 2021',
      meterCount: 24, notes: 'Notes', arr: '$6'
    }
  ];

  constructor(public zenMatTableHelperSvc: ZenMatTableHelperService) {
    this.handleTblConfig();
  }

  ngOnInit(): void {
    this.initTable();
  }

  handleTabChange(evt: MatTabChangeEvent) {
    this.handleTblConfig();
    this.initTable();
  }

  initTable() {
    this.handleRowMenuModification();
    const _openedTblCols: ZenTableColsModel[] = [
      {
        field: 'customerName', header: 'Customer Name', subTextArrayCol: 'customerLens',
        type: 'standard', sticky: true, headerTooltipText: ZenColumnInfoText.CUSTOMER_NAME
      },
      {field: 'status', header: 'Status', statusCol: 'statusCls', type: 'standard'},
      {
        field: 'commodityType', header: 'Service', iconCol: 'serviceType', iconClsCol: 'serviceTypeCls',
        whiteSpace: true, type: 'standard', align: 'center'
      },
      {field: 'usage', header: 'Usage', type: 'standard'},
      {field: 'state', header: 'State', align: 'center', type: 'standard'},
      {field: 'dueDate', header: 'Due Date', type: 'standard'},
      {field: 'meterCount', header: 'Meters', align: 'center', hyperlink: true, type: 'standard'},
      {
        field: 'notes', header: 'Notes', type: 'notes', allowAddNew: true,
        handleAddNewClick: (col, rowData) => console.log(col, rowData)
      },
      {
        field: 'arr', header: 'ARR', type: 'standard', enableUnderLinedTooltip: true,
        popperPlacement: NgxPopperjsPlacements.LEFT, popperApplyClass: 'md'
      },
      {field: 'menu', type: 'menu', stickyEnd: true}
    ];

    const _closedTblCols: ZenTableColsModel[] = [
      {
        field: 'customerName', header: 'Customer Name', subTextArrayCol: 'customerLens',
        type: 'standard', sticky: true, headerTooltipText: ZenColumnInfoText.CUSTOMER_NAME
      },
      {field: 'status', header: 'Status', statusCol: 'statusCls', type: 'standard'},
      {
        field: 'commodityType', header: 'Service', iconCol: 'serviceType', iconClsCol: 'serviceTypeCls',
        whiteSpace: true, align: 'center', type: 'standard'
      },
      {field: 'state', header: 'State', align: 'center', type: 'standard'},
      {field: 'dateClosed', header: 'Date Closed', type: 'standard'},
      {field: 'usage', header: 'Usage', type: 'standard'},
      {field: 'meterCount', header: 'Meters', align: 'center', type: 'standard'},
      {field: 'menu', type: 'menu', stickyEnd: true}
    ];

    const _commonConfig = {
      styles: {'min-width': MatTableSize.MEDIUM},
      rowMenuOptions: this.rcRowMenuOptions,
      searchPlaceholder: 'Search Rate Checks'
    }

    this.openTableConfig = {
      ..._commonConfig,
      cols: _openedTblCols,
      totalLength: this.openRcs.length
    };

    this.closedTableConfig = {
      ..._commonConfig,
      cols: _closedTblCols,
      totalLength: this.closedRcs.length
    };
  }

  handleRowMenuModification(selectedRow?: any) {
    this.rcRowMenuOptions = [
      {
        type: 'button',
        label: 'View/Modify Request',
        command: (rowData) => this.handleMenuClick(rowData)
      },
      {type: 'button', label: 'View Request', command: (rowData) => this.handleMenuClick(rowData)},
      {type: 'button', label: 'View Report', command: (rowData) => this.handleMenuClick(rowData)},
      {type: 'button', label: 'Copy Link to Report', command: (rowData) => this.handleMenuClick(rowData)},
      {type: 'button', label: 'Download Report', command: (rowData) => this.handleMenuClick(rowData)},
      {type: 'button', label: 'Send Report', command: (rowData) => this.handleMenuClick(rowData)},
      {type: 'button', label: 'View Rates', command: (rowData) => this.handleMenuClick(rowData)},
      {
        type: 'button',
        label: 'View Contract Details',
        command: (rowData) => this.handleMenuClick(rowData)
      },
      {
        type: 'button',
        label: 'Request Refresh',
        command: (rowData) => this.handleMenuClick(rowData)
      },
      {type: 'button', label: 'Delete', command: (rowData) => this.handleMenuClick(rowData)},
      {type: 'button', label: 'Abort', command: (rowData) => this.handleMenuClick(rowData)},
    ];
  }

  handleMenuClick(rowData) {
    console.log('handleMenuClick ', rowData);
  }

  handleTblConfig() {
    this.rcTableTopConfig = {
      actionBtn: {
        label: 'Rate Check',
        icon: 'add'
      },
      menuItems: [
        {label: 'Add a Rate Check', command: () => console.log('Open')}
      ]
    };
  }

  /* Stack Ranking Table */
  srSelectedIndex = 0;
  srTableEnum = CommodityType;
  srElectricityTableConfig: ZenTableConfig = {
    styles: {'min-width': MatTableSize.MEDIUM},
    searchPlaceholder: 'Search Contracts',
    cols: [
      {field: 'state', header: 'State', align: 'center', type: 'standard'},
      {field: 'utility', header: 'Utility', type: 'standard'},
      {field: 'rate', header: 'Weighted Average Rate', type: 'standard'},
      {field: 'meterCount', header: 'Meters', align: 'center', type: 'standard'},
      {
        field: 'annualUsage', header: `Annual Usage`, type: 'standard'
      },
      {
        field: 'annualCost', header: 'Annual Cost', type: 'standard',
      },
      {
        field: 'annualUsagePct', header: 'Annual Usage %', borderLeftColor: 'annualUsagePctColor', type: 'standard',
      },
      {
        field: 'annualCostPct', header: 'Annual Cost %', borderLeftColor: 'annualCostPctColor', type: 'standard'
      }
    ],
    totalLength: 4, hideMultiselect: true
  }
  srNatGasTableConfig: ZenTableConfig = this.srElectricityTableConfig;
  srStackRankingElectricityData = [
    {
      groupByInfo: [{groupByColumnField:"state",friendlyName:"State"},{groupByColumnField:"utility",friendlyName:"Utility"}],
      state: "MA",
      utility: "Eversource",
      rate: 0.1234,
      meterCount: 100,
      annualUsage: "2,261,023,423 " + (this.srSelectedIndex === 0 ? 'kWh' : 'Dth'),
      annualCost: "$1,000,000",
      annualUsagePct: "25%",
      annualCostPct: "25%",
      annualUsagePctColor: '#54E2C0',
      annualCostPctColor: '#54E2C0',
    },
    {
      groupByInfo: [{groupByColumnField:"state",friendlyName:"State"},{groupByColumnField:"utility",friendlyName:"Utility"}],
      state: "MA",
      utility: "Eversource",
      rate: 0.1234,
      meterCount: 100,
      annualUsage: "2,261,023,423 " + (this.srSelectedIndex === 0 ? 'kWh' : 'Dth'),
      annualCost: "$1,000,000",
      annualUsagePct: "25%",
      annualCostPct: "25%",
      annualUsagePctColor: '#54E2C0',
      annualCostPctColor: '#54E2C0',
    },
    {
      groupByInfo: [{groupByColumnField:"state",friendlyName:"State"},{groupByColumnField:"utility",friendlyName:"Utility"}],
      state: "MA",
      utility: "Eversource",
      rate: 0.1234,
      meterCount: 100,
      annualUsage: "2,261,023,423 " + (this.srSelectedIndex === 0 ? 'kWh' : 'Dth'),
      annualCost: "$1,000,000",
      annualUsagePct: "25%",
      annualCostPct: "25%",
      annualUsagePctColor: '#54E2C0',
      annualCostPctColor: '#54E2C0',
    },
    {
      groupByInfo: [{groupByColumnField:"state",friendlyName:"State"},{groupByColumnField:"utility",friendlyName:"Utility"}],
      state: "MA",
      utility: "Eversource",
      rate: 0.1234,
      meterCount: 100,
      annualUsage: "2,261,023,423 " + (this.srSelectedIndex === 0 ? 'kWh' : 'Dth'),
      annualCost: "$1,000,000",
      annualUsagePct: "25%",
      annualCostPct: "25%",
      annualUsagePctColor: '#54E2C0',
      annualCostPctColor: '#54E2C0',
    }
    ];
  srStackRankingNatGasData = [...this.srStackRankingElectricityData];

  srHandleTabChange(evt: MatTabChangeEvent) {
    this.srSelectedIndex = evt.index;
  }

}
