import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchTextHighlight'
})

export class SearchTextHighlightPipe implements PipeTransform {

  transform(value: string, args: string): string {
    if (args && value) {
      let re = new RegExp(args, 'gi');
      return value ? value.toString().replace(re, '<mark>$&</mark>') : value;
    } else {
      return value;
    }
  }
}
