import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {GenerateTokenRes} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/models/rate-check';
import {ZenDialogMsgService} from '../../../_shared/_services/zen-dialog-msg.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {NavigationService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/navigation.service';
import {EnergyPlanMeterWithoutUsage, ZenContractV4ListModel} from '../../../_shared/_model/contract-v4.model';
import {ButtonTypes, ZenDialogComponent, ZenDialogDataModel} from '../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {ZenDialogSizeEnum} from '../../../_shared/_enums/zen-dialogs.enum';
import {
  RateCheckMeterModificationType,
  RateCheckRequestV4FlowModel,
  RcRequestObjectType
} from '../../../_shared/_model/rate-check-request-v4.model';
import {PfCustomersTableRowModel} from '../../portfolio/_model/portfolio-customers.model';
import {PortfolioHelperService} from '../../portfolio/_services/_helpers/portfolio-helper.service';
import {ContractsV4Service} from '../../../_shared/_services/v4/contracts-v4.service';
import {MatDialog} from '@angular/material/dialog';
import {RateCheckV4Service} from '../../../_shared/_services/v4/rate-check-v4.service';
import {ZenErrorMsgEnum} from '../../../_shared/_enums/zen-error-msg.enum';
import {Router} from '@angular/router';
import {ZenColors} from '../../../_shared/_enums/zen-colors.enum';
import {
  ContractFriendlyStatus,
  ContractFriendlyStatusDescription,
  MLOAAuthorizationFriendlyStatusDescription
} from '../../../_shared/_zen-legacy-common/_utils/contract-utils';

@Injectable({
  providedIn: 'root'
})
export class ZenContractSharedHelperService {
  loading: boolean;

  mloaLegends: { color: ZenColors, description: string; }[] = [
    {description: MLOAAuthorizationFriendlyStatusDescription.eSigned, color: ZenColors.SUCCESS_2},
    {description: MLOAAuthorizationFriendlyStatusDescription.SentToday, color: ZenColors.INFO_1},
    {description: MLOAAuthorizationFriendlyStatusDescription.SentBeforeToday, color: ZenColors.DANGER_1},
    {description: MLOAAuthorizationFriendlyStatusDescription.NotSent, color: ZenColors.textColorLight}
  ];


  constructor(private zenDialogSvc: ZenDialogMsgService,
              private authSvc: AuthenticationService,
              private navSvc: NavigationService,
              private pfHelpSvc: PortfolioHelperService,
              public contractV4Svc: ContractsV4Service,
              private rcV4Svc: RateCheckV4Service,
              private router: Router,
              private dialog: MatDialog,
              private clipboard: Clipboard) {
  }

  copyContractDetailsPageLink(customerId: number, energyPlanId: number) {
    this.authSvc.generateTemporalCustomerToken(customerId).subscribe((res: GenerateTokenRes) => {
        if (res.token) {
          const contractDetRoute = this.navSvc.toRouterLink(this.navSvc.getContractCustomerDetailsPageRoute(energyPlanId));
          const url = `${window.location.origin}${contractDetRoute}?token=${res.token}`;
          this.clipboard.copy(url); // Copy link to clipboard
          this.zenDialogSvc.openSuccessGuidance('Link Copied', 'Contract link copied to clipboard!');
          this.zenDialogSvc.openToast(true);
        } else {
          this.zenDialogSvc.openErrorDialog(true, 'Unable to generate token!');
          this.zenDialogSvc.openToast(false);
        }
      }, () => {
        this.zenDialogSvc.openErrorDialog(true, 'Unable to generate token!');
        this.zenDialogSvc.openToast(false);
      });
  }

  rebookContract(contractV4: ZenContractV4ListModel) {
    if (contractV4?.energyPlanId) {
      this.loading = true;
      this.contractV4Svc.getEnergyPlanMetersWithoutUsage(contractV4.customerId, contractV4.energyPlanId)
        .subscribe({
          next: metersWithNoUsage => {
            if (metersWithNoUsage.length > 0) {
              this.loading = false;
              this.confirmRebookContract(metersWithNoUsage, contractV4);
            } else {
              this.completeRebookContract(contractV4);
            }
          },
          error: err => {
            this.loading = false;
            this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
          }
        });
    }
  }

  confirmRebookContract(metersWithNoUsage: EnergyPlanMeterWithoutUsage[], contractV4: ZenContractV4ListModel) {
    let metersWithNoUsageString = `<ul>`;
    metersWithNoUsage.forEach(meter => {
      metersWithNoUsageString = metersWithNoUsageString + `<li>${meter.utilityAccountNumber1}</li>`;
    });
    metersWithNoUsageString = metersWithNoUsageString + `</ul>`;
    let dialogData: ZenDialogDataModel = {
      header: {title: 'Confirm Rebook Contract'},
      bodyHtml: `<p>The contract you are rebooking has meters with no usage data. These meters will not be included in the new rate check.</p>` +
        metersWithNoUsageString +
        `<p>Are you sure you want to continue?</p>`,
      onClose: () => dialogRef.close(),
      actionButtons: [
        {
          label: 'Cancel', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'accent',
          command: () => {
            dialogRef.close();
          }
        },
        {
          label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'accent',
          command: () => {
            this.completeRebookContract(contractV4);
            dialogRef.close();
          }
        }
      ]
    };

    const dialogRef = this.dialog.open(ZenDialogComponent, {
      width: ZenDialogSizeEnum.SMALL,
      data: dialogData, autoFocus: false, panelClass: ''
    });
  }

  completeRebookContract(contractV4: ZenContractV4ListModel) {
    if (contractV4?.energyPlanId && contractV4?.customerId) {
      this.loading = true;
      const {customerId, energyPlanId, commodityType, state} = contractV4;
      const request = {
        customerId, commodityType, state,
        meterChanges: {
          ids: [energyPlanId],
          objectType: RcRequestObjectType.ENERGY_PLAN,
          modificationType: RateCheckMeterModificationType.ADD
        }
      } as RateCheckRequestV4FlowModel;

      this.rcV4Svc.createRcRequest(customerId, request)
        .subscribe({
          next: natGasRateCheck => {
            this.loading = false;
            this.router.navigate(this.navSvc.getRateCheckRequestV2PageRoute(natGasRateCheck.customerId, natGasRateCheck.id));
          }, error: error => {
            console.log('Failed to rebook Energy Plan ', error);
            this.loading = false;
            this.zenDialogSvc.openErrorDialog(true, 'Failed to Rebook Energy Plan');
          }
        });
    }
  }

  gotoCustomerDetailsPage(rightClick: boolean, customerId: number) {
    if (rightClick) {
      this.pfHelpSvc.handleCustomerViewInNewTab({customerId} as PfCustomersTableRowModel);
    } else {
      this.pfHelpSvc.handleCustomerView({customerId} as PfCustomersTableRowModel);
    }
  }

}
