import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {PasswordConfig} from '../../_zen-legacy-common/_models/organization';
import {zenHasError} from '../../_utils/zen-has-error.util';

@Component({
  selector: 'app-zen-password-field',
  templateUrl: './zen-password-field.component.html',
  styleUrls: ['./zen-password-field.component.scss']
})
export class ZenPasswordFieldComponent implements OnInit {
  @Input() feedback = true;
  @Input() formGroup: UntypedFormGroup;
  @Input() label: string;
  @Input() formCtrlName: string;
  @Input() passwordConfig: PasswordConfig;
  @Input() placeHolder?: string;
  hide = true;

  constructor() { }

  ngOnInit(): void {
  }

  hasError(errorName: string): Boolean {
    return zenHasError(this.formGroup, this.formCtrlName, errorName);
  }

}
