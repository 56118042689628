import {Component, OnInit} from '@angular/core';
import {ZenColors} from '../../../../modules/zentility/_shared/_enums/zen-colors.enum';

@Component({
  selector: 'app-zen-widgets',
  templateUrl: './zen-widgets.component.html',
  styleUrls: ['./zen-widgets.component.scss',
    '../zen-mat-form-fields/zen-mat-form-fields.component.scss',
    '../../../../modules/zentility/layout-modules/layout/layout.component.scss',
    '../../../../modules/zentility/_shared/_components/zen-chart/zen-chart.component.scss'
  ]
})
export class ZenWidgetsComponent implements OnInit {
  // snapshot
  snapshotData = {
    customerCount: 436,
    lenCount: 1280,
    meterCount: 42238,
    serviceAddressCount: 36932
  };

  // chart
  chartData = {
    pluginData: { title: 'Contracted', value: 25.5},
    legends: [
      {label: 'Already expired', subLabel: '8 Meters', color: ZenColors.DANGER_1, value: 69},
      {label: 'Expiring very soon', subLabel: '3 Meters', color: ZenColors.DANGER_2, value: 11},
      {label: 'Expiring soon', subLabel: '2 Meters', color: ZenColors.DANGER_3, value: 10},
      {label: 'Not expiring', subLabel: '5 Meters', color: ZenColors.SUCCESS_2, value: 10}
    ]
  };
  chartDataEmpty = {
    pluginData: { title: 'Contracted', value: 0},
    legends: [
      {label: 'Already expired', subLabel: '8 Meters', color: ZenColors.DANGER_1, value: 0},
      {label: 'Expiring very soon', subLabel: '3 Meters', color: ZenColors.DANGER_2, value: 0},
      {label: 'Expiring soon', subLabel: '2 Meters', color: ZenColors.DANGER_3, value: 0},
      {label: 'Not expiring', subLabel: '5 Meters', color: ZenColors.SUCCESS_2, value: 0}
    ]
  }

  // table
  tableConfig;
  data;

  constructor() {
  }

  ngOnInit(): void {
  }

}
