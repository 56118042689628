import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ZenImageCropperDialogComponent} from '../../../../_shared/_dialogs/zen-image-cropper-dialog/zen-image-cropper-dialog.component';
import {ZenDialogPanelClassEnum, ZenDialogSizeEnum} from '../../../../_shared/_enums/zen-dialogs.enum';
import {
  ButtonActionTypes,
  ButtonTypes,
  ZenDialogDataModel,
  ZenDialogDataType
} from '../../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-portfolio-image-uploader',
  templateUrl: './portfolio-image-uploader.component.html',
  styleUrls: ['./portfolio-image-uploader.component.scss']
})
export class PortfolioImageUploaderComponent {
  @Input() type: 'sm' | 'full';
  @Input() loading: boolean;
  @Output() onUpload: EventEmitter<File> = new EventEmitter<File>();
  @Input() imageUrl;

  // The below control used for image input resetting.
  inputCtrl = new UntypedFormControl();

  constructor(private dialog: MatDialog) {
  }

  onFileChange(filesEvt) {
    if (filesEvt.target.files.length === 0) {
      return;
    }
    let dialogData: ZenDialogDataModel = {
      type: ZenDialogDataType.CONFIRM,
      header: {title: 'Crop Image'},
      // Aspect Ratio: For small logo -> 1 / For full logo -> 5/2
      data: {imageInputFileEvent: filesEvt, aspectRatio: this.type === 'sm' ? 1 : 2.5},
      onClose: () => this.closeDialogAndResetFileInput(dialogRef),
      actionButtons: [
        {
          label: 'Close', btnType: ButtonTypes.MAT_BUTTON, actionType: ButtonActionTypes.CANCEL,
          command: () => this.closeDialogAndResetFileInput(dialogRef)
        },
        {
          label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
          actionType: ButtonActionTypes.SUBMIT,
          command: (croppedImg: File) => {
            this.onUpload.emit(croppedImg);
            this.closeDialogAndResetFileInput(dialogRef);
          }
        }
      ]
    };

    const dialogRef = this.dialog.open(ZenImageCropperDialogComponent, {
      width: ZenDialogSizeEnum.EXTRA_MEDIUM,
      panelClass: ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT,
      data: dialogData, autoFocus: false
    });
  }

  closeDialogAndResetFileInput(dialogRef) {
    dialogRef.close();
    this.inputCtrl.reset();
  }
}
