<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" actionStyleCls="center" [loading]="loading">

  <div class="d-flex justify-content-center m-4">
    <div class="col-12 col-10">
      <app-zen-autocomplete *ngIf="zoneList?.length"
                            label="Zone"
                            [listData]="zoneList" [formCtrl]="zoneControl"></app-zen-autocomplete>
    </div>
  </div>

</app-zen-dialog-wrapper>
