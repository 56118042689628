import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import {CommodityType} from '../_zen-legacy-common/_models/commodity';
import {CommodityUnit} from '../_zen-legacy-common/_enums/commodity-unit.enum';

@Pipe({
  name: 'commodityUnit'
})
export class CommodityUnitPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number, commodityType: CommodityType): string {
    return this.decimalPipe.transform(value, '1.0-0') + ' ' + (commodityType === CommodityType.Gas ? CommodityUnit.GAS : CommodityUnit.ELECTRICITY);
  }

}
