import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonActionTypes, ZenDialogActionButton, ZenDialogDataModel, ZenDialogDataType} from '../zen-dialog/zen-dialog.component';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {isValidItemList, isValidOption} from '../../_zen-legacy-common/_utils/validator-utils';
import {ValidPatterns} from '../../_zen-legacy-common/_models/common/email-pattern';
import {map, startWith, take} from 'rxjs/operators';
import {LabelledTagOption, Tag, TagType} from '../../../_modules/portfolio/_model/tag.model';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {TagService} from '../../_services/tag.service';
import {AddressAutocompleteService} from '../../_services/address-autocomplete.services';
import {LenV4FormModel, PfLenDialogRes} from '../../../_modules/portfolio/_model/portfolio-lens.model';
import {CustomerContact} from '../../_zen-legacy-common/_models/customer';
import {formatAddressType2} from '../../_zen-legacy-common/_utils/format-utils';
import {TemporaryStorageService} from '../../_zen-legacy-common/zen-common-services/_services/common/temporary-storage.service';
import {LenV4Service} from '../../_services/v4/len-v4.service';
import {InitialFiltersService} from '../../../_modules/portfolio/_services/_helpers/initial-filters.service';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {ZenDialogSizeEnum} from '../../_enums/zen-dialogs.enum';
import {ItemListV4Service} from '../../_services/v4/item-list-v4.service';
import {CustomerServiceV4} from '../../_services/v4/customer-v4.service';
import {CustomerDetails, PfCustomerDetailsModel} from '../../../_modules/portfolio/_model/portfolio-customers.model';
import {ItemListV4Model} from '../../_model/item-list-v4.model';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {ZenMessageType} from '../../_components/zen-message/zen-message.component';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import {CustomerContactService} from '../../_zen-legacy-common/zen-common-services/_services/customer-contact.service';
import {ZenFormTypesEnum} from '../../_enums/zen-form-types.enum';
import {PortfolioLensHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-lens-helper.service';
import {MatSelectChange} from '@angular/material/select';
import {ChangeStatusObjectType} from '../../../_modules/portfolio/_enums/portfolio-tabs.enum';
import {CacheService} from '../../_zen-legacy-common/zen-common-services/_services/cache.service';
import {PortfolioHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-helper.service';
import {PortfolioHierarchyLevelEnum} from '../../../_modules/portfolio/_enums/portfolio-hierarchy-level.enum';
import {ZenTableMenuOption} from '../../_components/zen-mat-table/zen-mat-table.component';
import {CustomerBillingAddressV4Service} from '../../_services/v4/customer-billing-address-v4.service';
import {CustomerBillingAddress} from '../../_model/customer-address-v4.model';
import {StateV4Service} from '../../_services/v4/state-v4.service';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';

export enum ContactTypes {
  BILLING = 'BILLING', SIGNATOR = 'SIGNATOR'
}

@Component({
  selector: 'app-zen-edit-len-dialog',
  templateUrl: './zen-edit-len-dialog.component.html',
  styleUrls: ['./zen-edit-len-dialog.component.scss']
})
export class ZenEditLenDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  form: UntypedFormGroup;
  billingAddress: UntypedFormGroup;
  activationChange: UntypedFormGroup;
  contactForm: UntypedFormGroup;
  ZenDialogDataType = ZenDialogDataType;
  errorMsg: string;

  // state
  stateOptions: string[] = [];
  filteredStateOptions: Observable<string[]>;

  // tags
  tagFilterCtrl = new UntypedFormControl('');
  tagOptions: LabelledTagOption[] = [];
  selectedTags: Partial<Tag>[] = [];
  TagType = TagType;

  isEditMode: boolean;
  lockedCustomer: ItemListV4Model;

  appearance: MatFormFieldAppearance = 'outline';

  // Billing address search
  addressCtrl = new UntypedFormControl(null);
  public filteredAddresses: ReplaySubject<CustomerBillingAddress[]> = new ReplaySubject();
  customerBillingAddresses: CustomerBillingAddress[] = [];

  addressFormType: ZenFormTypesEnum;
  FORM_TYPES = ZenFormTypesEnum;
  // this constant used to identify customer default address selected or not.
  customerDefaultAddressIdConstant = '0';

  // Used to show if an address has been updated
  billingAddressUpdated: boolean;

  actualDialogData: ZenDialogDataModel;

  // Customer search
  customerCtrl = new UntypedFormControl(null, [Validators.required, isValidItemList()]);
  public filteredCustomers: ReplaySubject<ItemListV4Model[]> = new ReplaySubject();
  searchableCustomers: ItemListV4Model[] = [];

  // EIN Mask
  einMask;

  // Tax Cert
  taxCertName: string;
  taxCertUploading: boolean;
  taxCertificateDocumentId: number; // After uploading temp file - it will be a document.
  taxCertFileInputCtrl = new UntypedFormControl();  // to clear file input data.

  // W-9
  w9Uploading: boolean;
  w9FileInputCtrl = new UntypedFormControl(); // to clear file input data for W-9.
  w9Name: string;
  w9DocumentId: number; // NOT the temp file ID, the actual file

  disableButton: boolean;
  ZenIconsEnum = ZenIconsEnum;

  lenDetails: LenV4FormModel;
  customerDet: CustomerDetails;

  ZenMessageType = ZenMessageType;
  lockedCustomerId: number;

  // Add/Edit contact form
  contactFormType: ZenFormTypesEnum;
  selectedContactType: ContactTypes;
  ContactTypes = ContactTypes;
  contactList: CustomerContact[] = [];
  billingContactList: CustomerContact[] = [];
  disableActivationStatus = false;
  activatedLen = false;
  deactivatedLen = false;
  isAdvisor = true;

  editBillingContact = true;
  editSignatorContact = true;
  defaultToCustomerAddress = false;

  isOutSidePortfolio: boolean; // Flag used to know if this edit dialog used inside/outside the portfolio flow.

  protected readonly PortfolioHierarchyLevelEnum = PortfolioHierarchyLevelEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenEditLenDialogComponent>,
              private zenDialogSvc: ZenDialogMsgService,
              private tagService: TagService,
              private formBuilder: UntypedFormBuilder,
              private itemListV4Svc: ItemListV4Service,
              private customerBillingAddressV4Svc: CustomerBillingAddressV4Service,
              private tempStorageSvc: TemporaryStorageService,
              private lenV4Svc: LenV4Service,
              private pfLenHelpSvc: PortfolioLensHelperService,
              private custV4Svc: CustomerServiceV4,
              private stateV4Svc: StateV4Service,
              private customerContactSvc: CustomerContactService,
              private pfFiltersSvc: InitialFiltersService,
              public authSvc: AuthenticationService,
              public addressAutocompleteService: AddressAutocompleteService,
              private cacheSvc: CacheService,
              public translateSvc: TranslateService,
              private pfHelpSvc: PortfolioHelperService) {
    super(translateSvc);
    // Taking copy of actual data to support billing address add/edit
    this.actualDialogData = {...this.data};
    this.isOutSidePortfolio = this.data.data.isOutSidePortfolio;
  }

  ngOnInit(): void {
    this.stateV4Svc.getAllCountryStates().subscribe({
      next: states => {
        this.stateOptions = states.map(s => s.key);
        this.init();
      },
      error: err => {
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });
  }

  init() {
    this.loadForm();
    this.loadContactForm();
    this.isAdvisor = this.authSvc.isAdvisor();
    this.isEditMode = Boolean(this.data.data && this.data.data.id);
    this.lockedCustomerId = Number(this.data.data.lockedCustomerId);
    this.addressCtrl.disable();


    if (this.isEditMode) {
      this.getLenDetails(() => this.customerListAndLockedDetInit());
    } else {
      this.loadCustomerContacts();
      this.customerListAndLockedDetInit();
    }

    this.customerCtrl.valueChanges
      .subscribe(() => {
        if (this.customerId) {
          this.addressCtrl.enable();
        }
      });

    // listen to changes in Tax Exempt checkbox
    this.controls.taxExempt.valueChanges
      .subscribe(checked => {
        // taxCertificateDocumentTempId is no longer a required field, if taxExempt checked.
        // this.controls.taxCertificateDocumentTempId.setValidators(checked ? [Validators.required] : []);
        if (!checked) {
          this.taxCertName = null;
          this.taxCertFileInputCtrl.setValue(null); // to clear file input data.
          this.controls.taxCertificateDocumentTempId.setValue(null);
        }
        this.controls.taxCertificateDocumentTempId.updateValueAndValidity();
      });

    this.addressCtrl.valueChanges.subscribe(value => this._filter(value));

    this.controls.csCustomers.valueChanges.subscribe(value => {
      this.loadCustomerContacts();
    });
    this.controls.bcCustomers.valueChanges.subscribe(value => {
      this.loadCustomerContacts();
    });
    this.controls.csAdvisors.valueChanges.subscribe(value => {
      this.loadCustomerContacts();
    });
    this.controls.bcAdvisors.valueChanges.subscribe(value => {
      this.loadCustomerContacts();
    });
  }


  customerListAndLockedDetInit() {
    if (this.authSvc.isAdvisor() && !this.lockedCustomerId) {
      this.loadCustomers();
    } else {
      // Customer item-list API wont work for customer users. So, adding locked customer into list to support UI.
      this.addLockedCustomerInSearchList();
    }
  }

  getCustomerDet(customerId: number) {
    this.lockedCustomer = this.searchableCustomers.find(c => c.key === customerId);
    this.custV4Svc.getCustomerDetails(customerId).subscribe(customerDet => {
      this.customerDet = customerDet;
      this.onCustomerChange(customerId);
    }, err => {
      this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
    });
  }

  loadCustomerContacts() {
    let getAllCustomers = this.form.controls.csCustomers.value;
    let getAllAdvisors = this.form.controls.csAdvisors.value;
    this.customerContactSvc.getCustomerContacts(this.lockedCustomerId, getAllCustomers, getAllAdvisors)
      .subscribe({
        next: contacts => this.setContactsDropdownData(contacts),
        error: err => {
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }

  displayCustomerFn(customer: ItemListV4Model): string {
    return customer && customer.value ? customer.value : '';
  }

  displayAddressFn(address: CustomerBillingAddress): string {
    return address && address.label ? address.label : '';
  }

  // To support edit mode
  getLenDetails(callback?: Function) {
    combineLatest([
      this.lenV4Svc.getLenById(this.data.data.customerId, this.data.data.id),
        this.lenV4Svc.bulkActivationCheckLens(this.data.data.customerId, [this.data.data.id]),
      this.customerContactSvc.getCustomerContacts(this.lockedCustomerId, false, false)
    ]).pipe(take(1)).subscribe({
      next: ([res, activationCheck, contacts]) => {
        this.disableActivationStatus = !activationCheck.activatable;
        this.fillLenDetails(res, contacts);
        if (this.disableActivationStatus) {
          this.controls.active.disable();
        }
        // Setting billing & signatory contacts dropdown data
        this.setContactsDropdownData(contacts);
        if (callback) {
          callback();
        }
      }, error: err => {
        this.disableDialogActions(false);
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });
  }

  /** Setting billing & signatory contacts dropdown data */
  setContactsDropdownData(contacts: CustomerContact[]) {
    this.contactList = [...contacts].map(c => this.customerContactSvc.setContactFilterClass(c));
    // To bring add new contact option in the dropdown list
    this.contactList.unshift({id: null, email: null, value: null, label: 'New Billing Contact'});
    // The below None value is omitted during the form submit.
    this.contactList.splice(1, 0, {id: null, email: null, value: 'None', label: 'None'});

    this.billingContactList = [...contacts].map(c => this.customerContactSvc.setContactFilterClass(c));
    // To bring add new contact option in the dropdown list
    this.billingContactList.unshift({id: null, email: null, value: null, label: 'New Billing Contact'});
    // The below None value is omitted during the form submit.
    this.billingContactList.splice(1, 0, {id: null, email: null, value: 'None', label: 'None'});
  }

  fillLenDetails(res: LenV4FormModel, contacts: CustomerContact[]) {
    this.lenDetails = res;
    if (res.billingAddress) {
      this.setBillingAddress(res.billingAddress);
    }
    this.defaultToCustomerAddress = res.defaultToCustomerAddress;

    let data = {...res};
    delete data.customerId;
    this.taxCertName = data.taxCertificateDocumentId ? 'tax_certificate…' : null;
    this.taxCertificateDocumentId = data.taxCertificateDocumentId;
    // the tempId (on add) will be converted into docId (on edit)
    this.controls.taxCertificateDocumentTempId.setValue(data.taxCertificateDocumentId);
    // W9
    this.w9Name = data.w9DocumentId ? `W-9__${this.lenDetails.name}…` : null;
    this.w9DocumentId = data.w9DocumentId;
    this.form.patchValue(data);
    // setting contacts
    if (res.billingContact?.id) {
      this.form.controls.billingContactId.setValue(res?.billingContact?.id);
      const contact_ = contacts.find(c => c.id === res?.billingContact?.id);
      if (contact_) {
        this.toggleContactEdits(ContactTypes.BILLING, contact_);
      }
    }
    if (res.signatorContact?.id) {
      this.form.controls.signatorContactId.setValue(res?.signatorContact?.id);
      const contact_ = contacts.find(c => c.id === res?.signatorContact?.id);
      if (contact_) {
        this.toggleContactEdits(ContactTypes.SIGNATOR, contact_);
      }
    }
    this.selectedTags = res.tags ? res.tags : [];
  }

  downloadTaxCert() {
    this.tempStorageSvc.downloadDocument(this.customerId, this.taxCertificateDocumentId, 'TaxCert__' + this.form.value.name)
      .subscribe(() => {
      }, err => {
        this.disableDialogActions(false);
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      });

  }

  downloadW9() {
    this.pfLenHelpSvc.downloadW9(this.customerId, this.w9DocumentId, this.form.value.name)
      .subscribe(() => {
      }, err => {
        this.disableDialogActions(false);
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      });
  }

  deleteW9() {
    if (this.w9DocumentId == null) {
      return;
    }

    this.zenDialogSvc.showConfirmationDialog(
      'Delete W-9',
      'Are you sure you want to delete this W-9? This action cannot be undone.',
      null,
      () => {
        this.lenV4Svc.deleteW9(this.customerId, this.lenDetails.id, this.w9DocumentId).subscribe(() => {
          this.w9Name = null;
          this.w9DocumentId = null;
          this.w9FileInputCtrl.setValue(null);
          this.zenDialogSvc.openToast(true);
        }, err => {
          this.disableDialogActions(false);
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        });
      }
    );
  }

  private _filter(value: string): void {
    if (value && typeof value === 'string') {
      const filterValue = value.toLowerCase();
      const filteredCustomers = this.customerBillingAddresses.filter(option => option.label?.toLowerCase().includes(filterValue));
      this.filteredAddresses.next(filteredCustomers)
    }
  }

  loadCustomers(search?: string): void {
    if (this.authSvc.isAdvisor() && !this.lockedCustomerId) {
      this.itemListV4Svc.getOrgCustomerItemList(search).subscribe(result => {
        this.searchableCustomers = result;
        this.filteredCustomers.next(this.searchableCustomers);
        this.handleLockedCustomer();
      }, err => {
        this.disableDialogActions(false);
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      });
    }
  }

  addLockedCustomerInSearchList() {
    this.custV4Svc.getCustomerDetails(this.lockedCustomerId).subscribe(customer => {
      this.searchableCustomers.push({key: customer.customerId, value: customer.companyName} as ItemListV4Model);
      this.filteredCustomers.next(this.searchableCustomers);
      this.lockedCustomer = this.searchableCustomers.find(c => c.key === customer.customerId);
      this.handleLockedCustomer();
      this.customerDet = customer;
      this.onCustomerChange(this.lockedCustomerId);
    }, err => {
      this.disableDialogActions(false);
      this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
    });
  }

  // Handling locked customer to support customer details page - Add LEN option
  handleLockedCustomer() {
    if (this.data.data) {
      setTimeout(() => {
        if (this.lockedCustomerId && !this.customerId) {
          const _customer = this.searchableCustomers.find(c => c.key === this.lockedCustomerId);
          this.customerCtrl.setValue(_customer);
        }
      }, 100); // adding this delay to support tags and address api calls
    }
  }

  get controls() {
    return this.form.controls;
  }

  get customerId() {
    return this.customerCtrl.value ? this.customerCtrl.value?.key : null;
  }

  loadForm(): void {
    this.billingAddress = this.formBuilder.group({
      id: new UntypedFormControl(),
      address1: new UntypedFormControl(null, [Validators.required]),
      address2: new UntypedFormControl(null, []),
      state: new UntypedFormControl(null, [Validators.required, isValidOption(this.stateOptions)]),
      city: new UntypedFormControl(null, [Validators.required]),
      zip: new UntypedFormControl(null, [Validators.required]),
    });

    // This is FormGroup is a model of Partial<ActivationChangeBulkV4Model>
    this.activationChange = this.formBuilder.group({
      reasonId: new UntypedFormControl(null, [Validators.required]),
      assumption: new UntypedFormControl(null),
      assumptionDt: new UntypedFormControl(null),
      reasonCustom: new UntypedFormControl(null, [Validators.maxLength(100)])
    });

    this.form = this.formBuilder.group({
      active: new UntypedFormControl(true, []),
      name: new UntypedFormControl(null, [Validators.required]),
      ein: new UntypedFormControl(null, [Validators.pattern(ValidPatterns.EIN_PATTERN)]),
      // if taxExempt checked -> taxCertDocumentTempId is required.
      taxExempt: new UntypedFormControl(null, []),
      csCustomers: new UntypedFormControl(null, []),
      csAdvisors: new UntypedFormControl(null, []),
      bcCustomers: new UntypedFormControl(null, []),
      bcAdvisors: new UntypedFormControl(null, []),
      taxCertificateDocumentTempId: new UntypedFormControl(null, []),
      w9DocumentTempId: new UntypedFormControl(null, []),
      billingContactId: new UntypedFormControl(),
      signatorContactId: new UntypedFormControl(),
    });

    // listen to changes in State, and filter options accordingly
    this.filteredStateOptions = this.billingAddress.controls.state.valueChanges.pipe(
      startWith(''),
      map(query => this.filterStates(query)),
    );
  }

  loadContactForm(): void {
    this.contactForm = this.formBuilder.group({
      id: new UntypedFormControl(),
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      title: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(ValidPatterns.EMAIL_PATTERN)]),
      userId: new UntypedFormControl('', ),
    });
  }

  onCustomerChange(customerId: number) {
    this.initTagOptions(customerId);
    this.getCustomerBillingAddresses(customerId);
  }

  handleAddAddress() {
    this.errorMsg = null;
    this.addressFormType = ZenFormTypesEnum.ADD;
    this.billingAddress.reset();
    this.addressCtrl.setValidators(null);
    this.addressCtrl.updateValueAndValidity();
    this.modifyDialogData();
  }

  onAddressSelect(id: string, inputElement: HTMLInputElement) {
    const selected = this.customerBillingAddresses.find(a => a.id === id);
    if (selected) {
      this.addressFormType = null;
      this.billingAddress.patchValue(selected);
      this.addressCtrl.setValidators([Validators.required]);
      this.addressCtrl.updateValueAndValidity();
      this.modifyDialogData();

      inputElement?.blur();
    }
  }

  handleEditAddress() {
    if (this.isEditable) {
      this.errorMsg = null;
      this.addressFormType = ZenFormTypesEnum.EDIT;
      this.addressCtrl.setValidators([Validators.required]);
      this.addressCtrl.updateValueAndValidity();

      const address = this.customerBillingAddresses.find(a => a.id === this.addressCtrl.value);
      this.setBillingAddress(address);
      this.modifyDialogData();
    }
  }

  modifyDialogData() {
    if (this.addressFormType) {
      this.dialogRef.updateSize(ZenDialogSizeEnum.MEDIUM);
      this.data.header = {
        title: (this.addressFormType === ZenFormTypesEnum.ADD ? 'Add' : 'Edit') + ' Billing Address',
        icon: ZenIconsEnum.SETTINGS
      };
      this.data.bodyText = this.addressFormType === ZenFormTypesEnum.EDIT
        ? `Edit the information below to maintain an accurate record of this billing address.`
        : `Create a new billing address to be leveraged across this customer.`;
      // Add/Edit billing address onClose action
      this.data.onClose = () => {
        this.addressFormType = null;
        this.modifyDialogData();
      };
    } else if (this.contactFormType) {
      const _contactType = this.selectedContactType === ContactTypes.BILLING ? 'Billing Contact' : 'Signatory Contact';
      this.dialogRef.updateSize(ZenDialogSizeEnum.MEDIUM);
      this.data.header = {
        title: (this.contactFormType === ZenFormTypesEnum.ADD ? 'Add ' : 'Edit ') + _contactType,
        icon: ZenIconsEnum.SETTINGS
      };
      this.data.bodyText = this.contactFormType === ZenFormTypesEnum.EDIT
        ? `Edit the information below to maintain an accurate record of this ${_contactType.toLowerCase()}.`
        : `Create a new ${_contactType.toLowerCase()} to be leveraged across this customer.`;
      // Add/Edit contacts onClose action
      this.data.onClose = () => {
        this.cancelContactEdit();
        this.resetContacts();
      };
    } else {
      // Reverting back to actual dialog config/data
      this.dialogRef.updateSize(ZenDialogSizeEnum.LARGE);
      this.data.header = this.actualDialogData.header;
      this.data.bodyText = this.actualDialogData.bodyText;
      this.data.onClose = this.actualDialogData.onClose;
    }
  }

  setBillingAddress(address) {
    if (address) {
      let {id, address1, address2, city, state, zip} = address;
      this.addressCtrl.setValue(this.formatAddress(address));
      this.billingAddress.setValue({id, address1, address2, city, state, zip});
    }
  }

  disableDialogActions(disable: boolean) {
    this.disableButton = disable;
  }

  handleEinEntry(showMask = false) {
    this.einMask = {mask: '00-0000000', lazy: showMask, placeholderChar: '_'};
  }

  onBlurEin() {
    if (this.form.value.ein === ValidPatterns.EIN_PLACEHOLDER) {
      this.handleEinEntry(true);
    }
  }

  handleSelectionChange(event: MatSelectChange) {
    if (this.isEditMode) {
      if (event.source.value === false) {
        this.activatedLen = false;
        if (this.lenDetails === undefined || this.lenDetails?.active === true) {
          this.deactivatedLen = true;
        }
      } else {
        this.deactivatedLen = false;
        if (this.lenDetails === undefined || this.lenDetails?.active === false) {
          this.activatedLen = true;
        }
      }
    }
  }
  onTaxCertFileUpload(files: FileList) {
    if (files == null || files.length === 0) {
      return;
    }
    this.taxCertUploading = true;
    this.tempStorageSvc.uploadTempFile(files[0]).then(res => {
      this.taxCertName = files[0].name;
      this.taxCertUploading = false;
      this.controls.taxCertificateDocumentTempId.setValue(res.id);
      this.taxCertificateDocumentId = null;
    }, err => {
      this.taxCertUploading = false;
      this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
    });
  }

  onW9FileUpload(files: FileList) {
    if (files == null || files.length === 0) {
      return;
    }
    this.w9Uploading = true;
    this.tempStorageSvc.uploadTempFile(files[0]).then(res => {
      this.w9Name = files[0].name;
      this.w9Uploading = false;
      this.controls.w9DocumentTempId.setValue(res.id);
      this.w9DocumentId = null;
    }, err => {
      this.w9Uploading = false;
      this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
    });
  }

  // On address form submit
  onAddressFormSubmit() {
    this.billingAddress.markAllAsTouched();
    this.errorMsg = null;
    if (this.billingAddress.valid) {
      let _val = this.billingAddress.value;
      _val.customerId = this.customerId;
      if (this.addressFormType === ZenFormTypesEnum.EDIT) {
        const addressId = this.addressCtrl.value.value;
        this.customerBillingAddressV4Svc.updateCustomerBillingAddress(this.customerId, addressId, _val).subscribe(updatedBillingAddress => {
          const index = this.customerBillingAddresses.findIndex(ad => ad.id === addressId);
          if (index !== -1) {
            this.customerBillingAddresses[index] = this.formatAddress(updatedBillingAddress);
            this.addressCtrl.setValue(this.formatAddress(updatedBillingAddress));
            this.zenDialogSvc.openToast(true);
            this.resetAddressSearch();
            this.addressFormType = null;
            this.billingAddressUpdated = true;
            this.modifyDialogData();
          } else {
            this.zenDialogSvc.openToast(false);
          }
        }, e => {
          if (e.error && e.error.status === 400 && e.error.message) {
            this.errorMsg = e.error.message;
            this.cancelAddressEdit();
          } else {
            this.errorMsg = ZenErrorMsgEnum.ERR_MSG_1_TEXT;
          }
          this.zenDialogSvc.openToast(false);
        });
      } else {
        this.customerBillingAddressV4Svc.addCustomerBillingAddress(this.customerId, _val).subscribe(addedBillingAddress => {
          this.customerBillingAddresses.push(this.formatAddress(addedBillingAddress));
          this.zenDialogSvc.openToast(true);
          setTimeout(() => {
            this.addressCtrl.setValue(addedBillingAddress);
            this.resetAddressSearch();
          }, 100);
          this.addressFormType = null;
          this.modifyDialogData();
        }, e => {
          if (e.error && e.error.status === 400 && e.error.message) {
            this.errorMsg = e.error.message;
          } else {
            this.cancelAddressEdit();
            this.errorMsg = ZenErrorMsgEnum.ERR_MSG_1_TEXT;
          }
          this.zenDialogSvc.openToast(false);
        });
      }
    }
  }

  resetAddressSearch() {
    this.filteredAddresses.next(this.customerBillingAddresses);
  }

  // On address edit cancel
  cancelAddressEdit() {
    this.errorMsg = null;
    this.addressFormType = null;
    this.modifyDialogData();
    if (this.lenDetails && this.lenDetails.billingAddress) {
      this.addressCtrl.setValue(this.lenDetails.billingAddress)
    }
  }

  get isEditable() {
    return (this.addressCtrl.value && this.addressCtrl.value.id !== this.customerDefaultAddressIdConstant) && !this.addressFormType;
  }

  getCustomerBillingAddresses(customerId: number) {
    this.customerBillingAddressV4Svc.getCustomerBillingAddressById(customerId).subscribe(addresses => {
      this.customerBillingAddresses = addresses.map(address => this.formatAddress(address));
      if (this.customerDet) {
        const customerBillingAddress: CustomerBillingAddress = this.formatAddress({
          id: this.customerDefaultAddressIdConstant, // setting null for customer address
          customerId: this.customerDet.customerId,
          address1: this.customerDet.address1,
          address2: this.customerDet.address2,
          state: this.customerDet.state,
          city: this.customerDet.city,
          zip: this.customerDet.zip
        });
        this.customerBillingAddresses.unshift(customerBillingAddress);

        if (this.isEditMode && (!this.lenDetails || !this.lenDetails?.billingAddress) && this.defaultToCustomerAddress) {
          this.setBillingAddress(customerBillingAddress);
        }
      }
      this.resetAddressSearch();
    }, err => {
      this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
    });
  }

  formatAddress(address: CustomerBillingAddress) {
    address.label = formatAddressType2(address);
    address.value = address.id;
    return address;
  }

  initTagOptions(customerId?: number) {
    this.tagService.getTagOptions(TagType.LEN, customerId).subscribe(options => {
      this.tagOptions = options.map(opt => {
        return {...opt, label: this.tagService.getTagOptionLabel(opt)};
      }).sort((a, b) => a.label.localeCompare(b.label));
    }, err => {
      this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
    });
  }

  handleAddTag(tag: Partial<Tag>) {
    this.selectedTags.push(tag);
    this.tagFilterCtrl.reset('');
  }

  handleRemoveTag(tag: Partial<Tag>) {
    this.selectedTags = this.selectedTags.filter(t => t.name !== tag.name || t.tagGroup?.name !== tag.tagGroup?.name);
  }

  filterStates(query: string) {
    let filteredStates = [...this.stateOptions];
    let search = this.billingAddress.controls.state.value;
    if (!search) {
      return filteredStates;
    } else {
      search = search.toLowerCase();
      return this.stateOptions.filter(s => s.toLowerCase().includes(search));
    }
  }

  submit(action: ZenDialogActionButton) {
    this.errorMsg = null;
    let _formData = {
      ...this.form.value,
      customerId: this.customerId,
      tags: this.selectedTags
    } as LenV4FormModel;

    if (this.billingAddress.valid) {
      _formData.billingAddressId = this.addressCtrl.value?.id;
      // Handle setting default customer address.
      if (this.addressCtrl.value?.id === this.customerDefaultAddressIdConstant) {
        _formData.billingAddressId = null;
        _formData.defaultToCustomerAddress = true;
      } else {
        _formData.defaultToCustomerAddress = false;
      }
    }

    if (typeof _formData.taxCertificateDocumentTempId !== 'string') {
      delete _formData.taxCertificateDocumentTempId;
    }

    if (typeof _formData.w9DocumentTempId !== 'string') {
      delete _formData.w9DocumentTempId;
    }

    if (!_formData.billingContactId || (_formData.billingContactId && _formData.billingContactId.toLowerCase().trim() === 'none')) {
      delete _formData.billingContactId;
    }

    if (!_formData.signatorContactId || (_formData.signatorContactId && _formData.signatorContactId.toLowerCase().trim() === 'none')) {
      delete _formData.signatorContactId;
    }

    if (action.actionType === ButtonActionTypes.CANCEL) {
      if (this.billingAddressUpdated) {
        action.command({lenModel: null, billingAddressUpdated: this.billingAddressUpdated} as PfLenDialogRes);
      } else {
        this.dialogRef.close();
      }
    } else {
      this.addressCtrl.markAllAsTouched();
      this.customerCtrl.markAllAsTouched();
      this.form.markAllAsTouched(); // to trigger mat-error

      _formData.activationChange = {
        active: _formData.active,
        id: this.lenDetails?.activationAuditId || null, ...this.activationChange.value
      };

      // If add/edit address initiated
      if (this.addressFormType && this.billingAddress.valid || !this.addressFormType) {
        // For already inactive LENs we are hiding this reason fields form.
        // Only for the active LENs we are showing this reason fields
        if (!this.lenDetails?.active || (_formData.active || (!_formData.active && this.activationChange.valid))) {
          if (this.form.valid && this.isEditMode) {
            // updating a LEN
            this.handleLenCreateUpdate(this.lenV4Svc.updateLen(this.customerId, this.data.data.id, _formData), action);
          } else if (this.form.valid && !this.isEditMode) {
            // creating a LEN
            this.handleLenCreateUpdate(this.lenV4Svc.createLen(this.customerId, _formData), action);
          }
        }
      }
    }
  }

  handleLenCreateUpdate(sub: Observable<LenV4FormModel>, action: ZenDialogActionButton | ZenTableMenuOption) {
    this.disableDialogActions(true);
    sub.subscribe({
      next: (lenUpdated) => {
        if (this.isOutSidePortfolio) {
          this.zenDialogSvc.openToast(true);
        } else {
          if (this.activatedLen) {
            this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getReactivateSuccessBodyText([lenUpdated], ChangeStatusObjectType.LEN));
          } else if (this.deactivatedLen) {
            this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getDeactivateSuccessBodyText([lenUpdated], ChangeStatusObjectType.LEN));
          } else {
            this.zenDialogSvc.openToast(true);
          }
        }

        this.pfHelpSvc.handleDetailsEntityStatusUpdate(this.controls.active.value);

        this.disableDialogActions(false);
        action.command({lenModel: lenUpdated, billingAddressUpdated: this.billingAddressUpdated} as PfLenDialogRes);
      }, error: e => {
        if (e.error && e.error.status === 400 && e.error.message) {
          this.errorMsg = e.error.message
        }
        this.disableDialogActions(false);
        this.zenDialogSvc.openToast(false);
      }
    });
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }

  addressHasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.billingAddress, formControlName, formErrorName);
  }

  // Contact form STARTS
  showAddNewContactForm(type: ContactTypes) {
    this.selectedContactType = type;
    this.contactFormType = ZenFormTypesEnum.ADD;
    this.contactForm.reset();
    this.modifyDialogData();
  }

  showOnOptionSelect(e: any, type: ContactTypes) {
    const contact_ = type === ContactTypes.SIGNATOR ? this.contactList.find(x => x.id == e) : this.billingContactList.find(x => x.id == e);
    this.toggleContactEdits(type, contact_);
  }

  toggleContactEdits(type: ContactTypes, contact_: CustomerContact) {
    if (contact_ && contact_.multiCustomerContact && !this.isAdvisor) {
      type === ContactTypes.SIGNATOR ? this.editSignatorContact = false : this.editBillingContact = false;
    } else {
      type === ContactTypes.SIGNATOR ? this.editSignatorContact = true : this.editBillingContact = true;
    }
  }

  showEditContactForm(type: ContactTypes) {
    this.selectedContactType = type;
    this.contactFormType = ZenFormTypesEnum.EDIT;
    let _contactId;
    let _contact;
    // Both billing and signatory contacts input field uses same contactList
    if (type === ContactTypes.BILLING) {
      _contactId = this.form.controls.billingContactId.value;
      _contact = this.billingContactList.find(c => c.id === _contactId);
    } else if (type === ContactTypes.SIGNATOR) {
      _contactId = this.form.controls.signatorContactId.value;
      _contact = this.contactList.find(c => c.id === _contactId);
    }

    if (_contact.id) {
      this.contactForm.setValue({
        id: _contact.id,
        firstName: _contact.firstName,
        lastName: _contact.lastName,
        title: _contact.title,
        phone: _contact.phone,
        email: _contact.email,
        userId: _contact.userId
      });
      this.modifyDialogData();
    }
  }

  onContactFormSubmit() {
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      const val = this.contactForm.getRawValue();
      // We need to do an add, if they edit before its added to a new customer.
      const isEditingFromOtherSourceBeforeInitialSave = val.id === val.userId;

      if (this.contactFormType === ZenFormTypesEnum.EDIT && !isEditingFromOtherSourceBeforeInitialSave) {
        this.handleContactSubscribe(this.customerContactSvc.updateContactById(val.id, val,
          (this.lockedCustomerId || this.lenDetails.customerId)));
      } else if (this.contactFormType === ZenFormTypesEnum.ADD || isEditingFromOtherSourceBeforeInitialSave) {
        this.handleContactSubscribe(this.customerContactSvc.addContactByCustomerId(val,
          (this.lockedCustomerId || this.lenDetails.customerId)), isEditingFromOtherSourceBeforeInitialSave);
      }
    }
  }

  handleContactUpdate(contact: CustomerContact, id: string) {
    let contactList = this.selectedContactType === ContactTypes.SIGNATOR ? this.contactList : this.billingContactList;
    const i = contactList.findIndex(c => c.id === id);
    if (i !== -1) {
      this.contactList[i] = {...contact};
    }
  }

  handleContactSubscribe(sub: Observable<CustomerContact>, isEditingFromOtherSourceBeforeInitialSave  = false) {
    this.errorMsg = null;
    sub.subscribe(res => {
      const _contact = this.customerContactSvc.setContactFilterClass(res);
      if (this.contactFormType === ZenFormTypesEnum.ADD || isEditingFromOtherSourceBeforeInitialSave) {
        this.contactList.splice(1, 0, _contact);
        this.billingContactList.splice(1, 0, _contact);
      } else if (this.contactFormType === ZenFormTypesEnum.EDIT && !isEditingFromOtherSourceBeforeInitialSave) {
        this.handleContactUpdate(_contact, _contact.id);
      }
      this.zenDialogSvc.openToast(true);
      if (this.selectedContactType === ContactTypes.SIGNATOR) {
        this.form.controls.signatorContactId.setValue(res?.id);
      } else if (this.selectedContactType === ContactTypes.BILLING) {
        this.form.controls.billingContactId.setValue(res?.id);
      }

      setTimeout(() => {
        this.cancelContactEdit()
      }, 25);
    }, e => {
      if (e.error && e.error.status === 400 && e.error.message) {
        this.errorMsg = e.error.message
      } else {
        this.errorMsg = ZenErrorMsgEnum.ERR_MSG_1_TEXT;
      }
      this.zenDialogSvc.openToast(false);
    });
  }

  cancelContactEdit() {
    this.errorMsg = null;
    this.contactFormType = null;
    this.selectedContactType = null;
    this.modifyDialogData();
  }

  resetContacts() {
    if (this.lenDetails && this.lenDetails.billingContact?.id) {
      this.form.controls.billingContactId.setValue(this.lenDetails.billingContact?.id);
    }
    if (this.lenDetails && this.lenDetails.signatorContact?.id) {
      this.form.controls.signatorContactId.setValue(this.lenDetails.signatorContact?.id);
    }
  }

  // Contact form ENDS
}
