import {HttpParams} from '@angular/common/http';

export function getParams(paramsObject): HttpParams {
  let httpParams = new HttpParams();
  Object.keys(paramsObject).forEach((key) => {
    if (paramsObject[key]) {
      httpParams = httpParams.append(key, paramsObject[key]);
    }
  });
  return httpParams;
}
