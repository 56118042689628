import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'absolute'})
export class AbsolutePipe implements PipeTransform {
  constructor() {}

  transform(value: number) {
    return Math.abs(value);
  }
}
