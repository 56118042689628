import {Component, Input} from '@angular/core';
import {JenZenImgType} from '../jen-zen-img/jen-zen-img.component';

export interface ZenMsgWithImgValidation {
  invalid: boolean;
  showValidation: boolean;
  title: string;
  message: string;
}

@Component({
  selector: 'app-zen-message-with-img',
  templateUrl: './zen-message-with-img.component.html',
  styleUrls: ['./zen-message-with-img.component.scss']
})
export class ZenMessageWithImgComponent {
  show = true;
  @Input() message: string;
  @Input() validations: ZenMsgWithImgValidation;
  @Input() closeable: boolean;

  JenZenImgType = JenZenImgType;

  constructor() {}

  dismiss() {
    this.show = false;
  }


  toggleValidation() {
    this.validations.showValidation = !this.validations.showValidation;
  }

}
