import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {TX_SWITCH_OPTIONS} from '../../../_modules/zen-market-pulse/_services/matrix-pricing-helper.service';
import {TxSwitchData} from '../../../_modules/zen-reports/_modules/zen-rate-check-report/_models/matrix-rate-check.interface';
import moment from 'moment';

@Component({
  selector: 'app-zen-texas-contract-options',
  templateUrl: './zen-texas-contract-options.component.html',
  styleUrls: ['./zen-texas-contract-options.component.scss']
})
export class ZenTexasContractOptionsComponent implements OnInit {
  /** TX Switch **/
  txSwitchIsOnCycle: boolean;
  txMoveIsNormal: boolean;
  selectedTxSwitchOption: TX_SWITCH_OPTIONS;
  TX_SWITCH_OPTIONS = TX_SWITCH_OPTIONS;
  txSwitchDate = new Date();
  TX_SWITCH_OPTIONS_LIST = [TX_SWITCH_OPTIONS.SWITCH, TX_SWITCH_OPTIONS.MOVE_IN];
  minDate: Date;
  maxDate: Date;
  priorityMoveInSupplierExists: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenTexasContractOptionsComponent>) {
  }

  /**
   * Parse all passed in data and set calendar min/maxes
   */
  ngOnInit() {
    // Set date/radio button options based on data
    this.minDate = new Date(Math.max.apply(null, [moment(this.data.data.startDate).startOf('month').toDate(), new Date()]));
    this.maxDate = moment(this.data.data.startDate).endOf('month').toDate();
    this.priorityMoveInSupplierExists = this.data.data.priorityMoveInSupplierExists;

    // Either restore existing data, or set defaults, if there is no existing data.
    if (this.data.data.existingData != null) {
      this.txMoveIsNormal = !this.data.data.existingData.txPriorityMoveIn;
      this.txSwitchIsOnCycle = this.data.data.existingData.txSwitchIsOnCycle != null ? this.data.data.existingData.txSwitchIsOnCycle : true;
      this.selectedTxSwitchOption = this.data.data.existingData.txSwitchOption;
      this.txSwitchDate = this.data.data.existingData.txSwitchDate != null ? moment(this.data.data.existingData.txSwitchDate, 'YYYY-MM-DD').toDate() : this.minDate;
    } else {
      this.txMoveIsNormal = true;
      this.txSwitchIsOnCycle = true;
      this.selectedTxSwitchOption = TX_SWITCH_OPTIONS.SWITCH;
      this.txSwitchDate = new Date(this.minDate);
    }
  }

  /**
   * Returns data based on selected switch option, omitting any fields that aren't needed.
   */
  getTexasSwitchData(): TxSwitchData {
      if (this.selectedTxSwitchOption === TX_SWITCH_OPTIONS.SWITCH) {
        return {
          txSwitchOption: this.selectedTxSwitchOption,
          txSwitchDate: this.txSwitchIsOnCycle ? null : this.txSwitchDate,
          txSwitchIsOnCycle: this.txSwitchIsOnCycle} as TxSwitchData;
      } else {
        return {
          txSwitchOption: this.selectedTxSwitchOption,
          txSwitchDate: this.txSwitchDate,
          txPriorityMoveIn: !this.txMoveIsNormal
        } as TxSwitchData;
      }
  }

  onSubmit() {
    // Save data to database
    this.dialogRef.close({data: this.getTexasSwitchData()});
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  dateValidator() {
    if (this.txSwitchDate < this.minDate) {
      this.txSwitchDate = this.minDate;
    }
    if (this.txSwitchDate > this.maxDate) {
      this.txSwitchDate = this.maxDate;
    }
  }
}
