export enum ZenNatGasPricingSolutionsEnum {
  BURNER_TIP = 'burner tip',
  CITY_GATE = 'city gate'
}

export enum ZenNatGasPaymentTermEnum {
  NET_15 = 'Net 15', NET_20 = 'Net 20', NET_30 = 'Net 30',
}


export enum ZenBillingMethodEnum {
  DUAL = 'dual', CONSOLIDATED = 'consolidated'
}
