export function orderBy(arr: any, fieldName?: string, type = 'asc') {
  return arr.sort((a, b) => {
    let val_1 = fieldName && a[fieldName] ? a[fieldName] : a;
    val_1 = typeof(val_1) === 'string' ? val_1.toUpperCase() : val_1;
    let val_2 = fieldName && b[fieldName] ? b[fieldName] : b;
    val_2 = typeof(val_2) === 'string' ? val_2.toUpperCase() : val_2;
    if (type === 'desc') {
      return val_1 < val_2 ? 1 : -1;
    } else {
      return val_1 > val_2 ? 1 : -1;
    }
  });
}
