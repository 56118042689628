import { ThemeName } from '../../zentility/_shared/_zen-legacy-common/_models/theme.model';

// export interface ThemeStyles {
//   primaryColor: string;
//   secondaryColor: string;
//   tertiaryColor: string;
//   backgroundColor: string;
//   bodyBackgroundColor: string;
//   textColor: string;
//   textLink: string;
//   lightTextColor: string;
//   greyDark: string;
//   greyMdark: string;
//   greyMid: string;
//   greyLmid: string;
//   greyLight: string;
//   greyMLight: string;
//   greyVlight: string;
//   navbarMobbutton: string;
//   navbarBackgroundColor: string;
//   navbarNavBorder: string;
//   navbarNavItemHoverColor: string;
//   navbarNavItemActiveColor: string;
//   navbarNavItemBorderColor: string;
//   navbarNavItemTextColor: string;
//   navbarNavItemTextColorHover: string;
//   menuTextColor: string;
//   rankHigh: string;
//   rankMedium: string;
//   rankLow: string;
//   brandLogo: string;
// }

export const dummyZentilityThemeStyles = {
  name: ThemeName.ZENTILITY,
  variant: 'default',
  rules: {
  primaryColor: '#2bdc83',
  secondaryColor: '#309bf5',
  tertiaryColor: '#384152',
  backgroundColor: '#384152',
  bodyBackgroundColor: '#f4f6f7',
  textColor: '#384152',
  textLink: '#309bf5',
  lightTextColor: '#8d96a7',
  greyDark: '#c7d0d5',
  greyMdark: '#d3dbdf',
  greyMid: '#dfe4e7',
  greyLmid: '#dbe2e1',
  greyLight: '#f4f6f7',
  greyMLight: '#f2f6f5',
  greyVlight: '#f4f7f6',
  navbarMobbutton: '#282f3b',
  navbarBackgroundColor: '#384152',
  navbarNavBorder: '#004b53',
  navbarNavItemHoverColor: '#2bdc83',
  navbarNavItemActiveColor: '#2bdc83',
  navbarNavItemBorderColor: '#8c96a9',
  navbarNavItemTextColor: '#ffffff',
  navbarNavItemTextColorHover: '#2bdc83',
  menuTextColor: '#ffffff',
  rankHigh: '#2bdc83',
  rankMedium: '#309bf5',
  rankLow: '#8c96a9',
  brandLogo: 'url(/assets/img/zentility_logo_light_text.png)',
  loginHeroImage: 'url(/assets/img/zentility-building-nature.jpg)'
}} as ThemeStyles;


// export const cbreTheme = () => ({
//   primaryColor: '#006a4d',
//   secondaryColor: '#69be28',
//   tertiaryColor: '#00b2dd',
//   backgroundColor: '#006a4d',
//   bodyBackgroundColor: '#ebebeb',
//   textColor: '#3a3a3a',
//   textLink: '#69be28',
//   lightTextColor: '#8d96a7',
//   greyDark: '#3a3a3a',
//   greyMdark: '#aaaaaa',
//   greyMid: '#c9d2d1',
//   greyLmid: '#dbe2e1',
//   greyLight: '#ecf1f0',
//   greyMLight: '#f2f6f5',
//   greyVlight: '#f4f7f6',
//   navbarMobbutton: '#004935',
//   navbarBackgroundColor: '#006a4d',
//   navbarNavBorder: '#004b53',
//   navbarNavItemHoverColor: '#69be28',
//   navbarNavItemActiveColor: '#69be28',
//   navbarNavItemBorderColor: '#004b53',
//   navbarNavItemTextColor: '#ffffff',
//   navbarNavItemTextColorHover: '#69be28',
//   menuTextColor: '#ffffff',
//   rankHigh: '#006a4d',
//   rankMedium: '#69be28',
//   rankLow: '#b2cd42',
//   brandLogo: 'url(/assets/img/cbre/cbre_logo.png)',
//   loginHeroImage: 'url("/assets/img/cbre/cbre-building-image.jpg") no-repeat 0'
// } as ThemeStyles);

// export const patriotTheme = () => ({
//   primaryColor: '#0068a6',
//   secondaryColor: '#eb2030',
//   tertiaryColor: '#70dbff',
//   backgroundColor: '#f9f9f9',
//   bodyBackgroundColor: '#f9f9f9',
//   textColor: '#101010',
//   textLink: '#36ccff',
//   lightTextColor: '#575757',
//   greyDark: '#a6a6a6',
//   greyMdark: '#bcbcbc',
//   greyMid: '#cacaca',
//   greyLmid: '#dadada',
//   greyLight: '#e9e9e9',
//   greyMLight: '#f9f9f9',
//   greyVlight: '#f4f4f4',
//   navbarMobbutton: '#004c79',
//   navbarBackgroundColor: '#0068a6',
//   navbarNavBorder: '#004c79',
//   navbarNavItemHoverColor: '#70dbff',
//   navbarNavItemActiveColor: '#70dbff',
//   navbarNavItemBorderColor: '#004c79',
//   navbarNavItemTextColor: '#ffffff',
//   navbarNavItemTextColorHover: '#70dbff',
//   menuTextColor: '#ffffff',
//   rankHigh: '#eb2030',
//   rankMedium: '#309bf5',
//   rankLow: '#8c96a9',
//   brandLogo: 'url(/assets/img/patriot/patriot-logo.png)',
//   loginHeroImage: 'url(/assets/img/patriot/patriot-landing.jpg) center no-repeat'
// } as ThemeStyles);

export const dummyTiliThemeStyles = {
  name: 'zen-tili-theme',
  variant: 'light',
  rules: {
    primaryColor: '#f16e00',
    secondaryColor: '#0a7c7c',
    tertiaryColor: '#4A3E3D',
    backgroundColor: '#ffffff',
    bodyBackgroundColor: '#ffffff',
    textColor: '#4A3E3D',
    textLink: '#0a7c7c',
    lightTextColor: '#606775',
    greyDark: '#afb3ba',
    greyMdark: '#c3c6cb',
    greyMid: '#d7d9dc',
    greyLmid: '#d7d9dc',
    greyLight: '#f5f5f6',
    greyMLight: '#f7f8f8',
    greyVlight: '#f9f9fa',
    navbarMobbutton: '#f5f5f6',
    navbarBackgroundColor: '#ffffff',
    navbarNavBorder: '#ebeced',
    navbarNavItemHoverColor: '#309bf5',
    navbarNavItemActiveColor: '#309bf5',
    navbarNavItemBorderColor: '#ebeced',
    navbarNavItemTextColor: '#384152',
    navbarNavItemTextColorHover: '#309bf5',
    menuTextColor: '#384152',
    rankHigh: '#2bdc83',
    rankMedium: '#309bf5',
    rankLow: '#8c96a9',
    brandLogo: 'url(/assets/img/tili/tili-logo-orange.png)',
    loginHeroImage: '',
    headerFontFamily: `'Open Sans', sans-serif`,
    bodyFontFamily: `'Open Sans', sans-serif`
  }
} as ThemeStyles;
export interface ThemeStyles {
  id?: string;
  name: string | ThemeName;
  public?: boolean;
  variant: string;
  rules: {[key: string]: string};
}



























