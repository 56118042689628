import {EventEmitter, Injectable} from '@angular/core';
import {
  ZenTableColsModel,
  ZenTableConfig,
  ZenTableMenuOption,
  ZenTooltipHoverModel
} from '../../../../_shared/_components/zen-mat-table/zen-mat-table.component';
import {SelectionModel} from '@angular/cdk/collections';
import {
  ButtonActionTypes,
  ButtonTypes,
  ZenDialogComponent,
  ZenDialogDataModel,
  ZenDialogDataType
} from '../../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PortfolioHelperService} from './portfolio-helper.service';
import {DownloadService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/download.service';
import {ZenPopperData} from '../../../../_shared/_components/zen-popper/zen-popper.component';
import {formatMeterAddress} from '../../../../_shared/_zen-legacy-common/_utils/format-utils';
import {MatTableSize} from '../../../../_shared/_enums/zen-mat-table.enum';
import {ZenDialogMsgService} from '../../../../_shared/_services/zen-dialog-msg.service';
import {
  EnergyPlanTimeline,
  MeterDetailsV4Model,
  PfMetersTableRowModel,
  TimelineStatus,
  UnprocurableMeterInfo
} from '../../_model/portfolio-meters.model';
import {ZenDialogPanelClassEnum, ZenDialogSizeEnum} from '../../../../_shared/_enums/zen-dialogs.enum';
import {ZenEditMeterDialogComponent} from '../../../../_shared/_dialogs/zen-edit-meter-dialog/zen-edit-meter-dialog.component';
import {Router} from '@angular/router';
import {MeterUsageProfileV4Service} from '../../../../_shared/_services/v4/meter-usage-profile-v4.service';
import {ZenErrorMsgEnum} from '../../../../_shared/_enums/zen-error-msg.enum';
import {MeterUsageProfileV4GetModel} from '../../../../_shared/_model/meter-usage-profile-v4.model';
import {ZenColors} from '../../../../_shared/_enums/zen-colors.enum';
import {ChartComponentLike, ChartData, ChartDataset, ChartOptions} from 'chart.js';
import * as moment from 'moment';
import {MetersV4Service} from '../../../../_shared/_services/v4/meters-v4.service';
import {ZenIconsEnum} from '../../../../_shared/_enums/zen-icons.enum';
import {CommodityType} from '../../../../_shared/_zen-legacy-common/_models/commodity';
import {UtilityService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/utility.service';
import {
  ActivationObjectType,
  ChangeStatusObjectType,
  DeleteObjectType,
  PortfolioSectionsEnum,
  PortfolioTabsEnum
} from '../../_enums/portfolio-tabs.enum';
import {getCssVarValue} from '../../../../_shared/_zen-legacy-common/_utils/zen-utils';
import {DecimalPipe} from '@angular/common';
import {
  ZenAddProcurementSnapshotDialogComponent
} from '../../../../_shared/_dialogs/zen-add-procurement-snapshot-dialog/zen-add-procurement-snapshot-dialog.component';
import {NgxPopperjsPlacements} from 'ngx-popperjs';
import {ZenColumnInfoText} from '../../../../_shared/_enums/zen-column-info-text.enum';
import {CustomerAndServiceAddressType, ServiceAddressSubType} from '../../../../_shared/_zen-legacy-common/_enums/entity-types.enum';
import {ZenUnitsEnum} from '../../../../_shared/_enums/zen-units.enum';
import {AuthenticationService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {StackedBarBorderRadiusPlugin} from '../../../../_shared/_components/zen-chart/zen-chart.component';
import {PortfolioFilterService} from './portfolio-filter.service';
import {formatExtractFileName} from '../../../../_shared/_utils/zen-filename.util';
import {ContractsV4Service} from '../../../../_shared/_services/v4/contracts-v4.service';
import {UndefinedMetersToUtilityDto} from '../../../../_shared/_model/meter-energy-plan.model';
import {
  ZenBulkAssignMetersEnergyPlanDialogComponent
} from '../../../../_shared/_dialogs/zen-bulk-assign-meters-energy-plan-dialog/zen-bulk-assign-meters-energy-plan-dialog.component';
import {getActivationStatusFilterValue} from '../../../../_shared/_utils/zen-menu-item.util';
import {PortfolioRateCheckService} from './portfolio-rate-check.service';
import {TranslateService} from '@ngx-translate/core';
import {ZenLocaleModel} from '../../../../_shared/_model/zen-locale.model';
import {UntypedFormControl, Validators} from '@angular/forms';
import {PfServiceAddressTableRowModel} from '../../_model/portfolio-service-addresses.model';
import {PfLensTableRowModel} from '../../_model/portfolio-lens.model';
import {combineLatest} from 'rxjs';
import {
  ZenProcurableStatusDialogComponent,
  ZenProcurementStatusDialogModel
} from '../../../../_shared/_dialogs/zen-procurable-status-dialog/zen-procurable-status-dialog.component';

export function checkDraftMeter(timelines: EnergyPlanTimeline[]) {
  return Boolean(timelines?.filter(x => x?.timelineStatus === TimelineStatus.DRAFT)?.length > 0);
}


@Injectable({
  providedIn: 'root'
})
export class PortfolioMetersHelperService {
  loading: boolean;
  selectedRows = new SelectionModel<PfMetersTableRowModel>(true, []);
  onMeterUpdated: EventEmitter<MeterDetailsV4Model> = new EventEmitter<MeterDetailsV4Model>();

  tableMenuOptions: ZenTableMenuOption[] = [];

  tableConfig: ZenTableConfig = {};

  popperData: ZenPopperData[] = [];
  // Usage profile
  meterUsage: MeterUsageProfileV4GetModel;

  // Procurement snapshot
  timelineData: EnergyPlanTimeline[] = [];
  timelineDataLoaded: EventEmitter<{ timelines: EnergyPlanTimeline[]; isDraft: boolean }> = new EventEmitter();
  isDraft: boolean;

  // Monthly usage chart
  chartOptions: ChartOptions = {};
  chartPlugins: ChartComponentLike[] = [];
  filteredData: ChartData;
  legendLabels: Array<string> = [];

  // Meter details
  meterDet: MeterDetailsV4Model;
  unit: ZenUnitsEnum.kWh | ZenUnitsEnum.Dth;

  meterListData: PfMetersTableRowModel[] = [];
  translations: ZenLocaleModel;

  constructor(private dialog: MatDialog,
              private pfHelpSvc: PortfolioHelperService, private downloadSvc: DownloadService,
              private pfHelpFilterSvc: PortfolioFilterService,
              private zenDialogSvc: ZenDialogMsgService, private router: Router,
              private utilityService: UtilityService, private decimalPipe: DecimalPipe,
              private contractsV4Svc: ContractsV4Service,
              private authSvc: AuthenticationService,
              private pfRcSvc: PortfolioRateCheckService,
              public translateSvc: TranslateService,
              private meterV4Svc: MetersV4Service, private mtrUsgProfSvc: MeterUsageProfileV4Service) {
    this.translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;

    this.tableConfig = {
      styles: {'min-width': MatTableSize.MEDIUM}, // this width is to match the overall top widget width.
      cols: [
        {
          field: 'label', header: 'Meter Name', type: 'standard', enableTooltip: true,
          colStyleCls: 'text-column',
          hyperlink: true, handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleViewMeter(rowData),
          popperPlacement: NgxPopperjsPlacements.AUTOSTART,
          imageCol: 'imageSmallPath', statusCol: 'meterStatusCls', sticky: true, subTextCol: 'serviceAddressLabel',
          headerTooltipText: ZenColumnInfoText.METER_NAME
        },
        {field: 'utilityName', header: this.translations?.nomenclature?.utilityShort, type: 'standard', headerTooltipText: ZenColumnInfoText.UTILITY},
        {
          field: 'billCount', header: 'Bills', align: 'center', type: 'standard',
          headerTooltipText: ZenColumnInfoText.BILLS, sortColumnName: 'meterMetrics.billCount', hideSort: true,
          hyperlink: true, handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleViewMeter(rowData)
        }, // Bills table is on default tab
        {
          field: 'usage', header: 'Usage', iconCol: 'usageType', iconClsCol: 'usageTypeCls', iconPosition: 'order-first', type: 'standard',
          headerTooltipText: ZenColumnInfoText.USAGE, sortColumnName: 'projectedAnnualUsage'
        },
        {
          field: 'activeRateCheckCountStr',
          header: 'Rate Checks',
          align: 'center',
          type: 'standard',
          headerTooltipText: ZenColumnInfoText.RCS,
          sortColumnName: 'meterMetrics.activeRateCheckCount',
          hyperlink: true,
          handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleViewMeter(rowData, PortfolioSectionsEnum.RATE_CHECKS, null, null)
        },
        {
          field: 'procurementSnapshot', header: 'Procurement Snapshot', type: 'procurement-snapshot',
          headerTooltipText: ZenColumnInfoText.PROCUREMENT_SNAPSHOT, hideSort: true,
          downloadFormatter: (col, rowData: PfMetersTableRowModel) => rowData.procurementSnapshot?.timelines[0]?.timelineStatus.toString()
        },
        {field: 'menu', type: 'menu', stickyEnd: true}
      ],
      searchPlaceholder: 'Search Meters',
      download: {
        enable: true,
        getFileNameFn: () => this.pfHelpSvc.getTableDownloadFileName('portfolio_meters'),
        externalDownload: () => this.handleExport()
      },
      onDoubleClick: (rowData) => this.pfHelpSvc.handleViewMeter(rowData),
    };

    this.tableMenuOptions = [
      {
        label: 'Delete', type: 'button', icon: ZenIconsEnum.DELETE, iconPosition: 'left',
        command: () => this.confirmDelete(null, this.selectedRows.selected)
      }, {
        label: 'Active',
        type: 'toggle',
        toggleDefaultVal: true,
        field: 'active',
        command: () => this.handleBulkStatusChange(getActivationStatusFilterValue(this.tableMenuOptions), false, this.selectedRows.selected)
      }, {
        label: `Set On ${this.translations?.nomenclature?.utilityShort}`,
        type: 'button', iconPosition: 'left',
        icon: ZenIconsEnum.ON_UTILITY,
        command: () => this.confirmOnUtilityStatusUpdate()
      },
      {
        label: 'Start Rate Check', type: 'button', icon: ZenIconsEnum.RATE_CHECK, iconPosition: 'left',
        command: () => this.pfRcSvc.createRateCheckByMeterIds(this.selectedRows.selected)
      },
      {
        label: 'Set Procurement Status', type: 'button', icon: ZenIconsEnum.MARK_UNPROCURABLE, iconPosition: 'left',
        command: () => this.handleProcurementStatusChange(this.selectedRows.selected)
      }
    ];

    this.tableConfig.tableMenuOptions = this.tableMenuOptions;

    this.pfHelpFilterSvc.activeStatusUpdate.subscribe(val => {
      this.tableConfig.tableMenuOptions.filter(x => x.label === 'Active').forEach(x => {
        x.toggleDefaultVal = val;
      })
    })
  }

  clear() {
    this.meterDet = null;
    this.meterUsage = null;
    this.selectedRows.clear();
    this.timelineData = [];
  }

  initUsageProfile(customerId: number, serviceAddressId: number, meterId: number) {
    this.loading = true;
    this.loadProcurementSnapshot(customerId, serviceAddressId, meterId);
    this.mtrUsgProfSvc.getUsageProfileByMeterId(customerId, serviceAddressId, meterId).subscribe(up => {
      this.meterUsage = up;
      this.buildChartOptions();
      this.loading = false;
    }, e => {
      console.log('Error: Customer contacts ', e);
      this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      this.zenDialogSvc.openToast(false);
      this.loading = false;
    });
  }

  loadProcurementSnapshot(customerId: number, serviceAddressId: number, meterId: number) {
    this.mtrUsgProfSvc.getMeterTimelines(customerId, serviceAddressId, meterId).subscribe(timelines => {
      this.timelineData = timelines;
      this.isDraft = checkDraftMeter(timelines);
      this.timelineDataLoaded.emit({timelines, isDraft: this.isDraft});
    }, e => {
      console.log('Error: Customer contacts ', e);
      this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      this.zenDialogSvc.openToast(false);
      this.loading = false;
    });
  }

  handleTooltipData({col, element}: ZenTooltipHoverModel) {
    const _data: PfMetersTableRowModel = element;
    this.meterV4Svc.getMeterDetailsById(_data.customerId, _data.serviceAddressId, _data.id).subscribe(meter => {
      this.utilityService.getUtilityAccountNumbers(meter.utilityId, meter.commodityType)
        .subscribe(uans => {
          if (col.field === 'label') {
            this.popperData = [
              {
                title: this.authSvc.customerNameLabel,
                titleIcon: ZenIconsEnum.CUSTOMER,
                value: meter.customerCompanyName,
                hideIfBlank: false,
                enableCopy: true
              },
              {
                title: 'Legal Entity Name',
                titleIcon: ZenIconsEnum.LEN,
                value: meter.lenName,
                hideIfBlank: false,
                enableCopy: true
              },
              {
                title: 'Service Address Name',
                titleIcon: ZenIconsEnum.SERVICE_ADDRESS,
                value: meter.serviceAddressLabel,
                hideIfBlank: false,
                enableCopy: true
              },
              {
                title: 'Meter Name',
                titleIcon: ZenIconsEnum.METER,
                value: (meter.label && meter.label.trim() !== '' ? meter.label : meter.utilityAccountNum1),
                hideIfBlank: false,
                status: (meter.active ? 'active' : 'inactive'),
                enableCopy: true
              },
              {
                title: 'Service Address',
                value: formatMeterAddress(meter),
                hideIfBlank: false,
                enableCopy: true,
                align: 'center'
              },
              {
                title: (uans && uans[0] ? uans[0].labelShort : `${this.translations?.nomenclature?.utilityShort} Account Number`),
                value: meter.utilityAccountNum1,
                hideIfBlank: false,
                enableCopy: true,
                align: 'center'
              }
            ];
            if (uans && uans[1]) {
              this.popperData.push({
                title: (uans && uans[1] ? uans[1].labelShort : 'UAN 2'),
                value: meter.utilityAccountNum2,
                hideIfBlank: true,
                enableCopy: true,
                align: 'center'
              });
            }
            if (meter.serviceAddressType === CustomerAndServiceAddressType.REAL_ESTATE && meter.serviceAddressSubType === ServiceAddressSubType.MULTI_FAMILY) {
              this.popperData.push({
                title: 'Meter Type',
                value: meter.type || '-',
                hideIfBlank: false,
                enableCopy: true,
                align: 'center'
              });
            }
          }
        }, e => {
          console.log('Error: UAN ', e);
          this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
          this.zenDialogSvc.openToast(false);
          this.loading = false;
        });
    }, e => {
      console.log('Error: Meter details ', e);
      this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      this.zenDialogSvc.openToast(false);
      this.loading = false;
    });
  }

  buildChartOptions() {
    this.legendLabels = [];
    let actualUsageDataValues = [];
    let zenIqDataValues = [];

    this.meterUsage.monthlyUsage.forEach(mu => {
      this.legendLabels.push(mu.yearMonth);
      actualUsageDataValues.push(mu.totalUsage * (100 - mu.zenIQPct) / 100 || 0);
      zenIqDataValues.push(mu.totalUsage * mu.zenIQPct / 100 || 0);
    });

    let moments = this.legendLabels.map(d => moment(d)),
      maxYear = moment.max(moments).format('YYYY'),
      minYear = moment.min(moments).format('YYYY');

    let _this = this;

    this.chartOptions = {
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return ' ' + _this.decimalPipe.transform(Number(tooltipItem.raw), '1.0-0') + ' ' + _this.unit;
            }
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false
          },
          title: {
            display: true,
            text: minYear !== maxYear ? (`${minYear} - ${maxYear}`) : (minYear || maxYear),
            align: 'end',
            color: ZenColors.textColorLight
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 6,
            callback: function (value, a, b) {
              return moment(_this.legendLabels[value]).format('MMM');
            }
          }
        },
        y: {
          stacked: true,
          position: 'left',
          grid: {
            display: false
          },
          title: {
            display: true,
            text: _this.unit,
            color: ZenColors.textColorLight
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 4,
            callback: function (value: number, a, b) {
              // add commas to numbers
              return Number(value).toString().split(/(?=(?:...)*$)/).join(',');
            }
          }
        }
      }
    } as ChartOptions;

    this.chartPlugins = [StackedBarBorderRadiusPlugin];

    const _secondaryColorLight20 = getCssVarValue('--secondaryColorLight20');
    const _secondaryClr = getCssVarValue('--secondaryColor');
    const _commonConfig: Partial<ChartDataset> = {
      barThickness: 'flex',
      maxBarThickness: 8,
      borderWidth: 2,
      borderSkipped: false
    };
    this.filteredData = {
      labels: this.legendLabels,
      datasets: [
        {
          data: [...actualUsageDataValues],
          backgroundColor: [_secondaryClr],
          borderColor: [_secondaryClr],
          hoverBorderColor: [_secondaryClr],
          hoverBackgroundColor: [_secondaryClr],
          ..._commonConfig
        },
        {
          data: [...zenIqDataValues],
          backgroundColor: [_secondaryColorLight20],
          borderColor: [_secondaryColorLight20],
          hoverBorderColor: [_secondaryColorLight20],
          hoverBackgroundColor: [_secondaryColorLight20],
          ..._commonConfig
        }
      ]
    };
  }

  confirmDelete(rowData?: PfMetersTableRowModel, selectedRows?: PfMetersTableRowModel[], goBack = false, callback?: Function) {
    const ids: number[] = rowData ? [rowData.id] : selectedRows.map(r => r.id);
    const _customerId = (rowData?.customerId || (selectedRows && selectedRows.length ? selectedRows[0]?.customerId : null));
    const _serviceAddressId = (rowData?.serviceAddressId || (selectedRows && selectedRows.length ? selectedRows[0]?.serviceAddressId : null));
    const _lenId = (rowData?.lenId || (selectedRows && selectedRows.length ? selectedRows[0]?.lenId : null));
    const hierarchyDeletable = rowData ? true : this.pfHelpSvc.checkSelectedRowsHierarchy(PortfolioTabsEnum.METERS, selectedRows);
    if (ids.length && hierarchyDeletable && _customerId && _serviceAddressId) {
      this.meterV4Svc.bulkDeleteCheckMeter(_customerId, _serviceAddressId, ids).subscribe(deleteCheck => {
        if (deleteCheck.deletable) {
          let dialogData: ZenDialogDataModel = {
            type: ZenDialogDataType.CONFIRM,
            bodyHtml: this.pfHelpSvc.getDeleteBodyText(rowData, selectedRows, DeleteObjectType.METER, rowData?.label, deleteCheck.impactedObjects),
            header: {title: 'Portfolio Management'},
            onClose: () => dialogRef.close(),
            actionButtons: [
              {
                label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
                command: () => {
                  dialogRef.close();
                  this.meterV4Svc.bulkDeleteMeters(_customerId, _serviceAddressId, ids).subscribe(() => {
                    this.selectedRows = new SelectionModel<PfMetersTableRowModel>(true, []);
                    if (callback) {
                      callback();
                    } else {
                      this.pfHelpSvc.handleAfterDelete(ids, PortfolioTabsEnum.METERS, goBack,
                        _customerId, _lenId, _serviceAddressId);
                    }
                  }, e => {
                    console.log('Error: bulk delete meter by id ', e);
                    this.zenDialogSvc.openToast(false);
                  });
                }
              }
            ]
          };
          const dialogRef = this.dialog.open(ZenDialogComponent, {
            width: ZenDialogSizeEnum.SMALL,
            data: dialogData, autoFocus: false
          });
        } else {
          this.zenDialogSvc.openErrorDialog(true, deleteCheck.errorMessage || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      }, e => {
        console.log('ERR: bulk delete service address check ', e);
        this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      });
    } else {
      this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.HIERARCHY_DELETE_ERROR);
    }
  }

  /**
   * handleBulkStatusChange - Called when the active toggle on the Meter table or row actions is toggled,
   * deactivates or reactivates the chosen meter(s)
   * @param active - boolean - current Active toggle status
   * @param rowAction - boolean - true for status change from row action toggle, false from table action toggle
   * @param selectedRows - PfMetersTableRowModel[] selected rows from the Meter table (null if row action)
   */
  handleBulkStatusChange(active: boolean, rowAction: boolean, selectedRows: PfMetersTableRowModel[]) {
    let ids: number[] = selectedRows.map(r => r.id);
    const _customerId = selectedRows.length ? selectedRows[0]?.customerId : null;
    const _serviceAddressId = selectedRows.length ? selectedRows[0]?.serviceAddressId : null;
    const _lenId = selectedRows.length ? selectedRows[0]?.lenId : null;
    const hierarchySupportsStatusChange = this.pfHelpSvc.checkSelectedRowsHierarchy(PortfolioTabsEnum.METERS, selectedRows);

    if (ids.length && hierarchySupportsStatusChange) {
      this.meterV4Svc.bulkActivationCheckMeters(_customerId, _serviceAddressId, ids).subscribe(activationCheck => {
        if (active || activationCheck.activatable) {
          let dialogData: ZenDialogDataModel = {
            type: ZenDialogDataType.CONFIRM,
            bodyHtml: active ? this.pfHelpSvc.getMeterDeactivateBodyText(rowAction, selectedRows) :
              this.pfHelpSvc.getMeterReactivateBodyText(rowAction, selectedRows),
            header: {title: 'Portfolio Management'},
            onClose: () => {
              dialogRef.close();
              this.resetTableActionToggle(!rowAction);
            },
            actionButtons: [
              {
                label: 'Confirm',
                btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
                command: () => {
                  dialogRef.close();
                  // !active is passed to the API since we need to send the desired active/inactive status change, not the current status
                  this.meterV4Svc.bulkChangeStatusMeters(_customerId, _serviceAddressId, ids, !active).subscribe(res => {
                    this.selectedRows = new SelectionModel<PfMetersTableRowModel>(true, []);
                    this.pfHelpSvc.handleAfterChangeStatus(ids, PortfolioTabsEnum.METERS, _customerId, _lenId, _serviceAddressId);
                    this.resetTableActionToggle(!rowAction);
                    active ? this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getDeactivateSuccessBodyText(selectedRows, ChangeStatusObjectType.METER)) :
                      this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getReactivateSuccessBodyText(selectedRows, ChangeStatusObjectType.METER));
                  }, e => {
                    console.log('ERR: bulk change status Meters ', e);
                    this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
                    this.resetTableActionToggle(!rowAction);
                  });
                }
              }
            ]
          };
          const dialogRef = this.dialog.open(ZenDialogComponent, {
            width: ZenDialogSizeEnum.SMALL,
            data: dialogData,
            autoFocus: false
          });
        } else {
          this.zenDialogSvc.openErrorDialog(true, this.pfHelpSvc.getReactivateErrorBodyText(rowAction, ChangeStatusObjectType.METER, ActivationObjectType.SERVICE_ADDRESS));
          this.resetTableActionToggle(!rowAction);
        }
      }, e => {
        console.log('ERR: bulk activation Meter check ', e);
        this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        this.resetTableActionToggle(!rowAction);
      });
    } else {
      this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.HIERARCHY_STATUS_CHANGE_ERROR);
      this.resetTableActionToggle(!rowAction);
    }
  }


  handleProcurementStatusChangeServiceAddress(selectedRows: PfServiceAddressTableRowModel[]) {
    const uniqueCustomerIds = new Set(selectedRows.map(row => row.customerId));
    const uniqueStates = [...new Set(selectedRows.map(row => row.state))];
    const uniqueCommodityTypes = [CommodityType.Gas, CommodityType.Electricity];
    let ids: number[] = selectedRows.map(r => r.id);

    if (ids.length && uniqueCustomerIds.size < 2) {
      const finalCustomerId = [...uniqueCustomerIds][0];
      let dialogData: ZenProcurementStatusDialogModel = {
        customerId: finalCustomerId,
        states: uniqueStates,
        commodityTypes: uniqueCommodityTypes,
        serviceAddressIds: ids,
        onClose: () => dialogRef.close(),
        onComplete: () => this.pfHelpSvc.handleAfterChangeStatus(),
      };
        const dialogRef = this.dialog.open(ZenProcurableStatusDialogComponent, {
          width: ZenDialogSizeEnum.SMALL,
          data: dialogData,
          autoFocus: false
        });
    } else {
      this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.CUSTOMER_HIERARCHY_STATUS_CHANGE_ERROR);
    }
  }


  handleProcurementStatusChange(selectedRows: PfMetersTableRowModel[]) {
    const uniqueCustomerIds = new Set(selectedRows.map(row => row.customerId));
    const uniqueStates = [...new Set(selectedRows.map(row => row.serviceAddressState))];
    const uniqueCommodityTypes = [...new Set(selectedRows.map(row => row.commodityType))];
    let ids: number[] = selectedRows.map(r => r.id);

    if (ids.length && uniqueCustomerIds.size < 2) {
          const finalCustomerId = [...uniqueCustomerIds][0];

          let dialogData: ZenProcurementStatusDialogModel = {
            customerId: finalCustomerId,
            states: uniqueStates,
            commodityTypes: uniqueCommodityTypes,
            meterIds: ids,
            onClose: () => dialogRef.close(),
            onComplete: () => this.pfHelpSvc.handleAfterChangeStatus(),
          };

          const dialogRef = this.dialog.open(ZenProcurableStatusDialogComponent, {
            width: ZenDialogSizeEnum.SMALL,
            data: dialogData,
            autoFocus: false
          });
    } else {
      this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.CUSTOMER_HIERARCHY_STATUS_CHANGE_ERROR);
    }
  }


  resetTableActionToggle(tableAction: boolean) {
    if (tableAction) {
      this.tableConfig.tableMenuOptions.find(x => x.label === 'Active').toggleDefaultVal =
        this.tableConfig.tableMenuOptions.find(x => x.label === 'Active').toggleDefaultVal !== true;
    }
  }

  handleAddEditMeter(rowData?: PfMetersTableRowModel, lockedCustomerId?: number,
                     lockedLenId?: string, lockedserviceAddressId?: number, lockedCommodity?: CommodityType, callback?: Function, skipActivationPopups = false) {
    this.selectedRows = new SelectionModel<PfMetersTableRowModel>(true, []);

    let dialogData: ZenDialogDataModel = {
      data: {...rowData, lockedCustomerId, lockedLenId, lockedserviceAddressId, lockedCommodity, skipActiveStatusPopUps: skipActivationPopups},
      type: rowData?.id ? ZenDialogDataType.EDIT_DIALOG : ZenDialogDataType.ADD_DIALOG,
      header: {title: rowData?.id ? 'Edit Meter' : 'Add Meter', icon: 'settings'},
      bodyText: rowData?.id ? `Edit the information below to maintain an accurate record of this meter.`
        : `Quickly create a meter by providing the required information and any other details you might know.`,
      onClose: () => dialogRef.close(),
      actionButtons: [
        {label: 'Cancel', command: () => dialogRef.close()}
      ]
    };

    /** PM Add/Edit Meter dialog save action
     * 1) Add mode -> i) Save -> Save & goto Meter details page. ii) Save & Close -> stay at current page
     * 2) Edit mode -> Save */
    if (rowData?.id) { // Edit mode
      dialogData.actionButtons.push({
        label: 'Save',
        btnType: ButtonTypes.MAT_RAISED_BUTTON,
        color: 'primary',
        command: (meter) => dialogRef.close(meter)
      });
    } else { // Add mode
      dialogData.actionButtons.push({
        label: 'Save',
        btnType: ButtonTypes.MAT_RAISED_BUTTON,
        color: 'primary',
        suffixIcon: 'expand_more',
        menuItems: [
          {
            label: 'Save & Close', command: (meter) => {
              dialogRef.close(meter);
            }
          },
          {
            label: 'Save & Continue', command: (meter: PfMetersTableRowModel) => {
              dialogRef.close();
              this.pfHelpSvc.handleViewMeter(meter);
            }
          }
        ]
      });
    }

    const dialogRef = this.dialog.open(ZenEditMeterDialogComponent, {
      width: ZenDialogSizeEnum.LARGE,
      data: dialogData,
      panelClass: [ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT]
    });

    dialogRef.afterClosed().subscribe((result: MeterDetailsV4Model) => {
      if (result) {
        this.pfHelpSvc.refreshCurrentPortfolioPage(result.customerId, result.lenId, result.serviceAddressId);
        this.onMeterUpdated.emit(result);
      }

      if (callback) {
        callback(result);
      }
    });
  }

  /** 1) The below timelineStatus to css class conversation method has been used in following places,
   * app-zen-procurement-snapshot, app-meter-portfolio
   * 2) The css classes has been mentioned in following places - zen-icon.scss and zen-procurement-snapshot.scss
   * API call -> mtrUsgProfSvc.getMeterTimelines()
   * @param status -> TimelineStatus
   */
  convertStatusToCls(status: TimelineStatus) {
    switch (status) {
      case TimelineStatus.EXPIRING_SOON:
        return 'expiring-less-than-3-months';
        break;
      case TimelineStatus.EXPIRING:
        return 'expiring-greater-than-3-months';
        break;
      default:
        return status.trim().toLowerCase().replace(/ /g, ''); // Contracted->contracted, Expired->expired, On Utility->onutility
        break;
    }
  }

  openProcurementSnapshotDialog() {
    const service = this.meterDet.commodityType === CommodityType.Gas ? 'Gas' : 'Electricity';
    let dialogData: ZenDialogDataModel = {
      data: this.meterDet,
      type: ZenDialogDataType.CONFIRM,
      bodyHtml: `Define the current status of this ${service} meter to understand when a new supply agreement can be procured.`,
      header: {title: 'Procurement Snapshot', icon: ZenIconsEnum.PROCUREMENT_SNAPSHOT},
      onClose: () => dialogRef.close(),
      actionButtons: [
        {
          label: 'Cancel', actionType: ButtonActionTypes.CANCEL, command: () => dialogRef.close()
        }, {
          label: 'Save', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
          command: () => {
            dialogRef.close();
          }
        }
      ]
    };
    const dialogRef = this.dialog.open(ZenAddProcurementSnapshotDialogComponent, {
      width: ZenDialogSizeEnum.EXTRA_LARGE,
      data: dialogData, autoFocus: false
    });
  }

  async handleExport() {
    let _columns: ZenTableColsModel[] = [...this.tableConfig.cols];
    let _data: any[] = this.meterListData; // pull from current page

    // Adding additional columns to support download requirement.
    _columns.push({header: 'Service Address Name', field: 'serviceAddressLabel', type: 'standard'});
    _columns.push({header: 'UAN 1', field: 'utilityAccountNum1', type: 'standard'});
    if (this.meterListData.some(m => m.utilityAccountNum2)) {
      _columns.push({header: 'UAN 2', field: 'utilityAccountNum2', type: 'standard'});
    }
    if (this.meterListData.some(m => m.utilityAccountNum3)) {
      _columns.push({header: 'UAN 3', field: 'utilityAccountNum3', type: 'standard'});
    }

    // build the data to be exported for each row in the table
    const exportRows = _data.map(row => {
      const exportRow = {};
      _columns.forEach(col => {
        if (col.downloadFormatter) {
          exportRow[col.header] = col.downloadFormatter(col, row);
        } else {
          exportRow[col.header] = col.formatter ? col.formatter(col, row) : row[col.field];
        }
      });
      return exportRow;
    });
    const fileName = formatExtractFileName(this.tableConfig.download.getFileNameFn());
    this.downloadSvc.downloadExcelFile(exportRows, fileName);
  }

  /**
   * 1) You cannot change the status of records that span different hierarchies
   *     (e.g. meters that span two different customer, serviceAddress state, commodity).
   * 2) All selected meters - must be undefined status.
   */
  confirmOnUtilityStatusUpdate() {
    if (this.selectedRows.selected?.length && this.selectedRows.selected[0]?.commodityType) {
      const _isAllUndefinedMeters = this.selectedRows.selected
        .some(m => m.procurementSnapshot?.timelines[0].timelineStatus !== TimelineStatus.UNDEFINED) === false;
      const _isSingleCustomer = new Set(this.selectedRows.selected.map(m => m.customerId)).size === 1;
      const _isSingleServiceAddressState = new Set(this.selectedRows.selected.map(m => m.serviceAddressState)).size === 1;
      const _isSingleCommodity = new Set(this.selectedRows.selected.map(m => m.commodityType)).size === 1;
      const _isSingleUtilityId = new Set(this.selectedRows.selected.map(m => m.utilityId)).size === 1;

      if (_isAllUndefinedMeters && _isSingleCustomer && _isSingleServiceAddressState && _isSingleCommodity && _isSingleUtilityId) {

        let dialogData: ZenDialogDataModel = {
          type: ZenDialogDataType.CONFIRM,
          header: {title: 'Portfolio Management'},
          bodyHtml: `This action will update Undefined status of the selected meters to On ${this.translations?.nomenclature?.utilityShort}.
<br/><br/> Are you sure want to proceed?`,
          onClose: () => dialogRef.close(),
          data: {metersSelected: this.selectedRows.selected},
          actionButtons: [
            {
              label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
              command: (formVal: Partial<UndefinedMetersToUtilityDto>) => {
                dialogRef.close();
                this.changeUndefinedMetersToOnUtility(formVal);
              }
            }
          ]
        };

        const dialogRef = this.dialog.open(ZenBulkAssignMetersEnergyPlanDialogComponent, {
          width: ZenDialogSizeEnum.SMALL,
          data: dialogData,
          autoFocus: false
        });

      } else {
        this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.HIERARCHY_ON_UTILITY_STATUS_CHANGE_ERROR);
      }

    }
  }

  changeUndefinedMetersToOnUtility(formVal: Partial<UndefinedMetersToUtilityDto>) {
    try {
      this.pfHelpSvc.loader.meters = true;
      const {customerId, serviceAddressId, lenId, commodityType, utilityId, serviceAddressState} = this.selectedRows.selected[0];

      const _request: UndefinedMetersToUtilityDto = {
        commodityType, serviceAddressState: serviceAddressState, utilityId,
        meterIds: this.selectedRows.selected.map(m => m.id),
        supplierRate: formVal?.supplierRate || null
      };

      this.contractsV4Svc.addUndefinedMetersToUtility(customerId, _request).subscribe({
        next: () => {
          this.selectedRows.clear();
          this.pfHelpSvc.refreshCurrentPortfolioPage(customerId, lenId, serviceAddressId);
          this.zenDialogSvc.openToast(true);
          this.pfHelpSvc.loader.meters = false;
        },
        error: err => {
          this.pfHelpSvc.loader.meters = false;
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
    } catch (e) {
      this.pfHelpSvc.loader.meters = false;
    }
  }

  initMeterDetailFromMeterListPage(customerId: number, serviceAddressId: number, meterId: number, callback: Function) {
    this.meterV4Svc.getMeterDetailsById(customerId, serviceAddressId, meterId).subscribe({
      next: _meterDet => {
        this.meterDet = _meterDet;

        if (callback) {
          callback()
        }
      },
      error: err => {
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });
  }

}
