<!-- Mouse Drag code Ref: https://stackblitz.com/edit/angular-ivy-phegzo?file=src%2Fapp%2Fapp.component.ts -->
<!-- Scroll right/left using btn code Ref: https://codesandbox.io/s/horizontal-scroll-carousel-i09yx?file=/index.html:114-486 -->
<div class="zen-magic-scroll" [ngClass]="position">
  <div #parentContainer class="parent" (mouseup)="stopDragging($event, false)"
       (mousedown)="startDragging($event, false, parentContainer)"
       (mouseleave)="stopDragging($event, false)" (mousemove)="moveEvent($event, parentContainer)">
    <div class="child">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="left-sec" *ngIf="showLeftIcon" (click)="clickLeft()">
    <i class="material-icons left-arrow">chevron_left</i>
  </div>
  <div class="right-sec" (click)="clickRight()">
    <i class="material-icons right-arrow">chevron_right</i>
  </div>
</div>
