import {Directive, Input} from '@angular/core';
import {UntypedFormControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[maxValue][formControlName],[maxValue][formControl],[maxValue][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: MaxValueDirective, multi: true}]
})
export class MaxValueDirective implements Validator {
  @Input()
  maxValue: number;

  validate(c: UntypedFormControl): {[key: string]: any} {
    let v = c.value;
    return ( v > this.maxValue) ? {'maxValue': true} : null;
  }
}
