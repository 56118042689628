
import {Injectable} from '@angular/core';
import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../zen-common-services/_services/authentication.service';

@Injectable()
export class TosCheckGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.getCurrentRoles()) {
      if (this.authService.isAdmin()) {
        return true;
      } else if (this.authService.isAdvisor()) {
        // removing this dependency for now.  Its probably not needed.
        // if (!this.authService.getUserApprovalStatus(AuthenticationService.ADVISOR_TOS_SIGNED)) {
        //    this.router.navigate([AuthenticationService.TILI_ROUTE, 'tos']);
        // }
        return true;
      } else {
        if (!this.authService.getUserApprovalStatus(AuthenticationService.CUSTOMER_TAC_APPROVED)) {
          /** IT WILL ALLOW THE USER TO STAY ON THE SAME URL, after signing TOS.
           * This will hide the app <router-outlet ></router-outlet> attribute, untill customer got signed the TOS.
           */
          this.authService.toggleTacApprovalPopup.next(false);
        }
        return true;
      }
    }
    return true;
  }
}
