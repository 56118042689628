import {Component, Inject} from '@angular/core';
import {ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenBaseComponent} from '../../_components/zen-base/zen-base.component';
import {ZenContractV4ListModel} from '../../_model/contract-v4.model';

@Component({
  selector: 'app-zen-edit-contract-dialog',
  templateUrl: './zen-edit-contract-dialog.component.html',
  styleUrls: ['./zen-edit-contract-dialog.component.scss']
})
export class ZenEditContractDialogComponent extends ZenBaseComponent {
  errorMsg: string;
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenEditContractDialogComponent>) {
    super();
  }

  onCancel() {
    this.dialogRef.close();
  }

  handleAfterSubmit(contract: ZenContractV4ListModel) {
    this.dialogRef.close(contract);
  }

  bubbleUpError(event) {
    this.errorMsg = event;
  }

}
