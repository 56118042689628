import {Pipe, PipeTransform} from '@angular/core';
import {CountryEnum} from '../_model/zen-locale.model';
import {i18nFormatPhoneNumber} from '../_components/zen-base-with-translate/zen-base-with-translate.component';

@Pipe({name: 'phoneFormatPipe'})
export class PhoneFormatPipe implements PipeTransform {
  constructor() {}

  transform(value: string, country = CountryEnum.US) {
    return i18nFormatPhoneNumber(value, country);
  }
}
