import { Injectable } from '@angular/core';
import tinycolor from 'tinycolor2';

@Injectable()
export class ColorService {

  constructor() { }

  /**
   * Tints the color, when q is 100 it always returns white
   * @param color the color
   * @param percent a number between 0 and 100
   */
  tint(color: string, percent: number): string {

    return tinycolor(color).lighten(percent).toHexString();
  }

  /**
   * Shades the color, when percent is 100 it always returns black
   * @param color the src color
   * @param percent a number between 0 and 100
   */
  shade(color: string, percent: number): string {
    return tinycolor(color).darken(percent).toHexString();
  }

  /**
   * returns an rgb object with the average color from the image
   * @param img an html image node
   */
  avgImageColor(img: HTMLImageElement) {
    const blockSize = 5;
    const rgb = {r: 0, g: 0, b: 0};
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext && canvas.getContext('2d');
    const w = canvas.width = img.naturalWidth || img.offsetWidth || img.width;
    const h = canvas.height = img.naturalHeight || img.offsetHeight || img.height;
    ctx.drawImage(img, 0, 0);

    try {
      const {data} = ctx.getImageData(0, 0, w, h);
      const length = data.length;
      let i = 0, count = 0;
      while ((i += blockSize * 4) < length) {
        ++count;
        rgb.r += data[i];
        rgb.g += data[i + 1];
        rgb.b += data[i + 2];
      }
      rgb.r = ~~(rgb.r / count);
      rgb.g = ~~(rgb.g / count);
      rgb.b = ~~(rgb.b / count);
    } catch (e) {
      console.warn('failed to calculate avg img color', e);
    }
    return tinycolor(rgb).toHexString();
  }
}
