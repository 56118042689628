import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {CustomerContact} from '../../_models/customer';
import {take, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../../../../../../environments';
import {OrganizationQuery} from '../../../../../../stores/organization/organization.query';
import {getParams} from '../../_utils/set-param-util';
import {orderBy} from '../../_utils/orderby.utils';

@Injectable({
  providedIn: 'root'
})
export class CustomerContactService {
  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient,
              private organizationQuery: OrganizationQuery,
              private authService: AuthenticationService) { }

  getCustomerContacts(customerId?: number, allCustomers = false, includeAdvisors = false): Observable<CustomerContact[]> {
    let _id = customerId ? customerId : this.authService.getCurrentCustomerId();
    const url = `${this.baseUrl}/v1/customers/${_id}/contacts`;
    let params = {};
    // Add request parameters if users checked the "All Customers" or "Include Advisors" checkbox
    if (allCustomers || includeAdvisors) {
       params = {
        allCustomers: allCustomers,
        includeAdvisors: includeAdvisors
      };
    }
    let httpParams = getParams(params);
    return this.http.get<CustomerContact[]>(url, {params: httpParams }).pipe(take(1),
      tap(contacts => orderBy(contacts, 'firstName', 'asc')));
  }

  deleteCustomerContactById(contactsId, customerId?: number): Observable<CustomerContact> {
    const url = `${this.baseUrl}/v1/customers/${customerId ? customerId : this.getCurrentCustomerId()}/contacts/${contactsId}`;
    return this.http.delete<CustomerContact>(url).pipe(take(1));
  }

  addContactByCustomerId(contact: CustomerContact, customerId?: number): Observable<CustomerContact> {
    let _id = customerId ? customerId : this.getCurrentCustomerId();
    const url = `${this.baseUrl}/v1/customers/${_id}/contacts`;
    return this.http.post<CustomerContact>(url, contact).pipe(take(1));
  }

  updateContactById(contactsId: string, contact: CustomerContact, customerId?: number): Observable<CustomerContact> {
    let _cid = customerId ? customerId : this.getCurrentCustomerId();
    const url = `${this.baseUrl}/v1/customers/${_cid}/contacts/${contactsId}`;
    return this.http.put<CustomerContact>(url, contact).pipe(take(1));
  }

  makeContactPrimary(contactsId: string, customerId?: number): Observable<CustomerContact> {
    let _cid = customerId ? customerId : this.getCurrentCustomerId();
    const url = `${this.baseUrl}/v1/customers/${_cid}/contacts/${contactsId}/primary`;
    return this.http.put<CustomerContact>(url, null).pipe(take(1));
  }

  setContactFilterClass(contact: CustomerContact) {
    contact.label = `${contact.firstName} ${contact.lastName}${contact.email ? ', ' + contact.email : ''}`;
    contact.value = contact.id;
    return contact;
  }

  private getCurrentCustomerId() {
    return this.authService.getCurrentCustomerId() || this.organizationQuery.customers.getActiveId();
  }
}
