import {Component, Inject, OnInit} from '@angular/core';
import {MatrixMessageService} from '../../../_modules/zen-market-pulse/_services/matrix-message.service';
import {ZenTableColsModel, ZenTableConfig} from '../../_components/zen-mat-table/zen-mat-table.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {ZenColors} from '../../_enums/zen-colors.enum';
import {ProgressStatus} from '../../_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {capitalizeFirstLetter} from '../../_zen-legacy-common/_utils/format-utils';
import {TranslateService} from '@ngx-translate/core';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';

export enum ProgressStatusCls {
  PENDING= 'pending',
  APPROVED = 'approved',
  INELIGIBLE = 'ineligible',
  NOT_RECEIVED = 'not-received'
}

@Component({
  selector: 'app-zen-matrix-pricing-status-report-dialog',
  templateUrl: './zen-matrix-pricing-status-report-dialog.component.html',
  styleUrls: ['./zen-matrix-pricing-status-report-dialog.component.scss']
})
export class ZenMatrixPricingStatusReportDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  ZenColors = ZenColors;
  tableConfig: ZenTableConfig = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenMatrixPricingStatusReportDialogComponent>,
              public matMsgService: MatrixMessageService,
              public translateSvc: TranslateService
              ) {
    super(translateSvc);
  }

  ngOnInit(): void {
    this.buildTable();
    this.matMsgService.onSupplierData.subscribe(() => this.buildTable());
  }

  buildTable() {
    if (this.matMsgService.dataProgressList?.length) {
      this.matMsgService.dataProgressList = this.matMsgService.dataProgressList.map(p => {
        p.pricingStatusColor = this.getProgressColor(p.progressStatus);
        // Converting NOT RECEIVED -> Not Received
        p.progressStatusText = p.progressStatus ? p.progressStatus.split(' ')
          .map(word => capitalizeFirstLetter(word.toLowerCase())).join(' ') : p.progressStatus;
        return p;
      });

      let cols: ZenTableColsModel[] = [
        {field: 'supplierName', header: this._translations?.nomenclature?.supplier, type: 'standard'},
        {field: 'supplierIneligibleMessage', header: '', whiteSpace: true, colStyleCls: 'text-column', type: 'standard'},
        {field: 'progressStatusText', header: 'Status', progressColorCol: 'pricingStatusColor', type: 'progress-status'}
      ];
      this.tableConfig = {
        ...this.tableConfig,
        cols,
        hideMultiselect: true,
        hideSearch: true,
        hidePagination: this.matMsgService.dataProgressList?.length <= 10
      };
    }
  }

  // To support new zen table column -> Type progress-status.
  getProgressColor(progressStatus: ProgressStatus): ProgressStatusCls {
    switch (progressStatus) {
      case ProgressStatus.APPROVAL_PENDING:
        return ProgressStatusCls.PENDING;
      case ProgressStatus.APPROVED:
        return ProgressStatusCls.APPROVED;
      case ProgressStatus.INELIGIBLE:
        return ProgressStatusCls.INELIGIBLE;
      case ProgressStatus.NOT_RECEIVED:
        return ProgressStatusCls.NOT_RECEIVED;
    }
  }
}
