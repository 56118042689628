import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {environment} from '../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {ActivationCheck, DeleteCheck} from '../../_model/customer-v4.model';
import {ActivationChangeBulkModel} from '../../_model/activation-change-bulk.model';
import {ServiceAddressAddUpdateDTO, ServiceAddressDetailsModel} from '../../_model/service-address.model';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {CommodityValidationV4Model} from '../../_model/commodity-validation-v4.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceAddressService {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  getServiceAddressDetails(customerId: number, serviceAddressId: number): Observable<ServiceAddressDetailsModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/details`;
    return this.http.get<ServiceAddressDetailsModel>(url).pipe(take(1));
  }

  /**
   * To fetch available commodities of the procurable utility meters with energy profile by service address ids.
   * If !commodities.length - then there is no eligible meters available for the selected serviceAddressIds.
   * @param customerId
   * @param ids
   */
  getAvailableCommodityTypesByIds(customerId: number, serviceAddressIds: number[]): Observable<CommodityValidationV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-address-procurable-commodities?serviceAddressIds=${serviceAddressIds}`;
    return this.http.get<CommodityValidationV4Model>(url).pipe(take(1));
  }

  getServiceAddressById(customerId: number, serviceAddressId: number): Observable<ServiceAddressDetailsModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}`;
    return this.http.get<ServiceAddressDetailsModel>(url).pipe(take(1));
  }

  createServiceAddress(customerId: number, data): Observable<ServiceAddressDetailsModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses`;
    return this.http.post<ServiceAddressDetailsModel>(url, data).pipe(take(1));
  }

  updateServiceAddress(customerId: number, serviceAddressId: number, data: ServiceAddressAddUpdateDTO): Observable<ServiceAddressDetailsModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}`;
    return this.http.put<ServiceAddressDetailsModel>(url, data).pipe(take(1));
  }

  deleteServiceAddress(customerId: number, serviceAddressId: number): Observable<ServiceAddressDetailsModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}`;
    return this.http.delete<ServiceAddressDetailsModel>(url).pipe(take(1));
  }

  bulkDeleteCheckServiceAddress(customerId: number, ids: number[]): Observable<DeleteCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/bulk/delete-check`;
    return this.http.post<DeleteCheck>(url, { ids }).pipe(take(1));
  }

  bulkDeleteServiceAddresses(customerId: number, ids: number[]): Observable<ServiceAddressDetailsModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/bulk/delete`;
    return this.http.post<ServiceAddressDetailsModel>(url, {ids}).pipe(take(1));
  }

  /**
   * Bulk Activation Check Service Addresses - Check to see if provided Service Addresses can be Reactivated
   * Payload -> {customerId: number, ids: string[]} -> where applicable (Service Addresses)
   * @param customerId
   * @param ids
   */
  bulkActivationCheckServiceAddresses(customerId: number, ids: number[]): Observable<ActivationCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/service-addresses/bulk/activation-check`;
    return this.http.post<ActivationCheck>(url, { ids }).pipe(take(1));
  }

  /**
   * Bulk change status Service Addresses - Deactivate or reactivate Service Addresses using the table or row action toggles
   * Payload -> {customerId: number, ids: number[]} -> where applicable (Service Addresses)
   * @param customerId
   * @param serviceAddressIds
   * @param active
   */
  bulkChangeStatusServiceAddresses(customerId: number, activationBulkChange: ActivationChangeBulkModel) {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/service-addresses/bulk/activation`;
    return this.http.put(url, activationBulkChange).pipe(take(1));
  }
}
