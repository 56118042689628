<div class="zen-detail-expansion">
  <div class="card-container-wrapper four-cards-responsive">
    <div class="card-container view-details-card" [ngClass]="{expanded}">
      <div class="details-sec">
        <i class="material-symbols-rounded mobile-close-left-icon" (click)="toggleExpansion()">arrow_back_ios_new</i>

        <ng-container *ngIf="expansionDetails.menuItems && expansionDetails.menuItems.length">
          <button class="det-menu-icon d-flex justify-content-center align-items-center sm" mat-icon-button
                  [matMenuTriggerFor]="appMenu.childMenu">
            <i class="material-symbols-rounded mb-1">more_horiz</i>
          </button>

          <app-zen-menu-item #appMenu [element]="expansionDetails?.currentHierarchyDetails"
                             [menuItems]="expansionDetails?.menuItems"></app-zen-menu-item>
        </ng-container>

        <div class="top-sec-wrapper" *ngIf="expansionDetails && expansionDetails.nameDetails">
          <input hidden type="file" [formControl]="inputCtrl" onclick="this.value=null;" (change)="onFileChange($event)" #uploadFileInput [accept]="'image/png'"/>
          <div class="thumbnail-img-container">
            <div class="overlay-edit">
              <i *ngIf="expansionDetails.imagePath && expansionDetails.imageSmallPath" class="material-symbols-rounded revert-icon" (click)="handleImageRevert()">close</i>
              <i class="material-symbols-rounded" (click)="uploadFileInput.click()">upload</i>
            </div>
            <img [src]="(!expansionDetails.imagePath && !expansionDetails.imageSmallPath) ? THUMBNAIL :
                        (expanded ? expansionDetails.imagePath : expansionDetails.imageSmallPath)" class="thumbnail-img" alt=""/>
          </div>
          <div class="w-100">

            <div class="name-details-main">
              <div class="details-top-sec">
                <i class="material-symbols-rounded me-2">{{expansionDetails.nameDetails.icon}}</i>
                <span class="sub-heading-1">{{expansionDetails.nameDetails.label}}</span>

                <!-- Mobile breadcrumb starts -->
                <i class="material-symbols-rounded breadcrumb-arrow"
                   [matMenuTriggerFor]="breadcrumbMenu">keyboard_arrow_down</i>
                <mat-menu #breadcrumbMenu="matMenu" class="zen-breadcrumb-main">
                  <ng-container *ngIf="zenBcSvc.config?.breadcrumbs?.length else portfolioOption">
                     <ng-template [ngTemplateOutlet]="bcMobileTemp"
                                  [ngTemplateOutletContext]="{breadcrumbs: zenBcSvc.config.breadcrumbs}"></ng-template>
                  </ng-container>
                  <ng-template #portfolioOption>
                    <ng-template [ngTemplateOutlet]="bcMobileTemp" [ngTemplateOutletContext]="{breadcrumbs: zenBcSvc.config?.mobileBreadcrumbs}"></ng-template>
                  </ng-template>
                </mat-menu>
                <ng-template #bcMobileTemp let-breadcrumbs='breadcrumbs'>
                  <ng-container *ngFor="let bread of breadcrumbs">
                    <div mat-menu-item class="zen-breadcrumb-item" [ngClass]="{current: bread.currentPage}"
                         (click)="zenBcSvc.handleBcClick(bread)">
                      <i class="material-icons current-icon">{{bread.currentPage ? 'done' : ''}}</i>
                      <i class="material-icons">{{bread.icon}}</i>
                      <p class="m-0">{{bread.title}}</p>
                    </div>
                  </ng-container>
                </ng-template>
              </div>
              <div class="details-middle-sec">
                <h2 class="title mb-1 {{!expanded ? 'me-5' : ''}}" (click)="toggleExpansion()">{{expansionDetails.nameDetails.name}}</h2>
                <i *ngIf="expanded" class="material-icons edit-icon" (click)="handleEdit()">edit</i>

                <mat-chip-option class="sm pointer-events-none {{expanded ? '' : 'ms-5'}}"
                  [ngClass]="expansionDetails.nameDetails.active ? 'active' : 'inactive'">{{expansionDetails.nameDetails.active ? 'Active' : 'Inactive'}}</mat-chip-option>
              </div>
            </div>

            <mat-expansion-panel [expanded]="expanded" hideToggle class="details-expansion">
              <div class="det-exp-wrapper">

                <div class="sub-details" >
                  <ng-container *ngIf="expansionDetails.details && expansionDetails.details.length">
                    <div class="sub-det-child col-12 col-sm-12 col-md-6 {{det.colLgSize || 'col-lg-6'}}" *ngFor="let det of expansionDetails.details">
                      <ng-template [ngTemplateOutlet]="detailTpl" [ngTemplateOutletContext]="{det}"></ng-template>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="expansionDetails.details2 && expansionDetails.details2.length">
                    <div class="sub-det-child col-12 col-sm-12 col-md-6 {{det.colLgSize || 'col-lg-3'}}" *ngFor="let det of expansionDetails.details2">
                      <ng-template [ngTemplateOutlet]="detailTpl" [ngTemplateOutletContext]="{det}"></ng-template>
                    </div>
                  </ng-container>
                </div>

                <div class="sub-details" >
                  <ng-container *ngIf="expansionDetails.details3 && expansionDetails.details3.length">
                    <div class="sub-det-child col-12 col-sm-12 col-md-6 col-lg-6" *ngFor="let det of expansionDetails.details3">
                      <ng-template [ngTemplateOutlet]="detailTpl" [ngTemplateOutletContext]="{det}"></ng-template>
                    </div>
                  </ng-container>
                </div>

                <ng-template #detailTpl let-det='det'>
                  <div class="d-flex align-items-center">
                    <i *ngIf="det.icon"
                       class="material-symbols-rounded color-icon p-3 me-3 pointer {{det.icon.class}}">{{det.icon.name}}</i>
                    <div>
                      <!-- If type === upload -> upload icon -->
                      <p class="p-value" *ngIf="det.type === 'download'">
                        <i *ngIf="det.value else valTpl" class="material-symbols-rounded color-icon p-1 pointer"
                        (click)="det.command()">download</i>
                        <ng-template #valTpl>{{det.value || '-'}}</ng-template>
                      </p>

                      <p class="p-value" *ngIf="det.type === 'edit'">
                        <i *ngIf="!det.value else valTpl" class="material-symbols-rounded color-icon p-1 pointer"
                           (click)="det.command()">add</i>
                        <ng-template #valTpl>{{det.value}}</ng-template>
                      </p>

                      <p class="p-value hyperlink" *ngIf="det.type === 'hyperlink'"
                        (click)="det.command()">
                        {{det.value}}
                      </p>

                      <p class="p-value" *ngIf="!det.type || det.type === 'standard'">{{det.value || '-'}}</p>

                      <p class="sub-heading-1 no-wrap">{{det.label}}</p>
                    </div>
                  </div>
                </ng-template>

                <div class="d-flex align-items-center justify-content-between ms-2 ps-2 position-relative">
                  <mat-chip-listbox>
                    <mat-chip-option *ngFor="let tag of expansionDetails.tags">
                      <div class="d-flex align-items-center justify-content-between">
                        <span>#{{tag.tagGroup ? tag.tagGroup.name + ':' : ''}}{{tag.name}}</span>
                        <i class="material-icons pointer ms-2 large" (click)="onTagRemove(tag)">close</i>
                      </div>
                    </mat-chip-option>
                  </mat-chip-listbox>

                  <ng-template *ngIf="expanded" [ngTemplateOutlet]="viewBtnTpl"></ng-template>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </div>

        <ng-template *ngIf="!expanded" [ngTemplateOutlet]="viewBtnTpl"></ng-template>

        <ng-template #viewBtnTpl>
          <button *ngIf="expansionDetails?.nameDetails" mat-stroked-button class="view-stroked view-det" (click)="toggleExpansion()">
            <span>{{expanded ? 'Show less' : 'Show more'}}</span>
            <i class="material-symbols-rounded">{{expanded ? 'expand_less' : 'expand_more'}}</i>
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
