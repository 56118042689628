import {Component, Input, OnInit} from '@angular/core';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {Clipboard} from '@angular/cdk/clipboard';

export interface ZenPopperData {
  title: string;
  titleIcon?: string;
  value: string;
  hideIfBlank?: boolean;
  align?: 'left' | 'center';
  status?: 'active' | 'inactive';
  enableCopy?: boolean;
}

@Component({
  selector: 'app-zen-popper',
  templateUrl: './zen-popper.component.html',
  styleUrls: ['./zen-popper.component.scss']
})
export class ZenPopperComponent implements OnInit {
  @Input() title: string;
  @Input() closeable: boolean;
  @Input() popperContent;
  @Input() popperData: ZenPopperData[] = [];
  @Input() small: boolean;

  constructor(private dialogMsgSvc: ZenDialogMsgService,
              private clipboard: Clipboard) {
  }

  ngOnInit(): void {
  }

  copyToClipboard(val: string) {
    this.clipboard.copy(val); // Copy link to clipboard
    this.dialogMsgSvc.openToast(true, ZenErrorMsgEnum.COPY);
  }
}
