import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonActionTypes, ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivationChangeBulkModel} from '../../_model/activation-change-bulk.model';

@Component({
  selector: 'app-zen-activation-form-dialog',
  templateUrl: './zen-activation-form-dialog.component.html',
  styleUrls: ['./zen-activation-form-dialog.component.scss']
})
export class ZenActivationFormDialogComponent implements OnInit {
  loading: boolean;
  form: FormGroup;


  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenActivationFormDialogComponent>,
              private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      reasonId: new FormControl(null, [Validators.required]),
      assumption: new FormControl(null),
      assumptionDt: new FormControl(null),
      reasonCustom: new FormControl(null, [Validators.maxLength(100)])
    });
  }

  submit(btn: ZenDialogActionButton) {
    if (btn.actionType === ButtonActionTypes.SUBMIT) {
      this.form.markAllAsTouched();
      if (this.form.valid) {
        btn.command(this.form.value as Partial<ActivationChangeBulkModel>);
      }
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
