import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments';
import {MatrixLeadSelection} from '../_models/matrix-lead-selection.model';
import {MatrixLead} from '../_models/matrix-lead.model';
import {MatrixSearchCriteria} from '../_models/matrix-search-criteria.model';
import {MatrixLeadRequest} from '../_models/matrix-lead-request.model';
import {take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FileData} from '../../../_shared/_zen-legacy-common/_models/file-data';

@Injectable({
  providedIn: 'root'
})
export class MatrixLeadService {

  private baseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) { }

  sendLeadEmail(orgId: string, searchCriteria: MatrixSearchCriteria, leadContact: MatrixLead, leadPriceSelections: MatrixLeadSelection[], files: FileData[]): Observable<any> {
    const matrixLeadRequest: MatrixLeadRequest = {
      matrixSearchCriteria: searchCriteria,
      matrixLeadContact: leadContact,
      matrixSelections: leadPriceSelections
    };
    let formData = new FormData();
    if (files.length > 0) {
      formData.append('file', files[0].fileRef, files[0].fileName);
    }
    formData.append('marketPulseLead', JSON.stringify(matrixLeadRequest));

    let url = `${this.baseUrl}/v2/organization/${orgId}/marketpulse/send_lead`;
    console.log('About to post to: ', url);
    return this.http.post(url, formData, {
      // This is required to manage post multipart updates
      headers: {}
    }).pipe(take(1));
  }

}

