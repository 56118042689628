import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonActionTypes, ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {FormControl, Validators} from '@angular/forms';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {CommodityValidationV4Model} from '../../_model/commodity-validation-v4.model';

/**
 * This component used to confirm start RC at portfolio meter/serviceAddress level.
 * commodityExistCheck object used to select commodity only for serviceAddress selection.
 */

@Component({
  selector: 'app-rc-req-portfolio-confirm-dialog',
  templateUrl: './rc-req-portfolio-confirm-dialog.component.html',
  styleUrl: './rc-req-portfolio-confirm-dialog.component.scss'
})
export class RcReqPortfolioConfirmDialogComponent {
  Commodity = CommodityType;
  commodityExistCheck: CommodityValidationV4Model;
  ZenIcons = ZenIconsEnum;
  selectedCommodity: FormControl<CommodityType> = new FormControl(null);
  loading: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<RcReqPortfolioConfirmDialogComponent>
  ) {
    this.commodityExistCheck = this.data.data?.commodityExistCheck;

    if (this.commodityExistCheck) {
      this.selectedCommodity.setValidators([Validators.required]);
      this.selectedCommodity.updateValueAndValidity();

      // If there is only one commodity exists
      if (Object.values(this.commodityExistCheck)?.filter(v => v)?.length === 1) {
        this.selectedCommodity.setValue(this.commodityExistCheck?.hasGas ? CommodityType.Gas : CommodityType.Electricity);
      }
    }
  }

  updateCommodity(c: CommodityType) {
    if ((this.commodityExistCheck?.hasGas && c === CommodityType.Gas)
    || (this.commodityExistCheck?.hasElectric && c === CommodityType.Electricity)) {
      this.selectedCommodity.setValue(c);
    }
  }

  submit(btn: ZenDialogActionButton) {
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      this.dialogRef.close(false);
    } else {
      this.selectedCommodity.markAllAsTouched();

      if (this.selectedCommodity.valid) {
        this.loading = true;
        this.dialogRef.close(this.selectedCommodity.value);
        btn.command(this.selectedCommodity.value);
      }
    }
  }

}
