import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export enum ZenTimelineSteps {
  COMPLETED = 'completed', PENDING = 'pending'
}

export interface ZenTimeline {
  index?: number;
  className: ('completed' | 'pending') | ZenTimelineSteps;
  title: string;
}

@Component({
  selector: 'app-zen-timeline',
  templateUrl: './zen-timeline.component.html',
  styleUrls: ['./zen-timeline.component.scss']
})
export class ZenTimelineComponent implements OnInit {
  @Input() zenTimeline: ZenTimeline[];
  @Input() currentStep: string;
  @Input() whiteBg: boolean;

  @Output() goBack = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

}
