import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgxPopperjsContentComponent} from 'ngx-popperjs';

@Component({
  selector: 'app-zen-side-flyout',
  templateUrl: './zen-side-flyout.component.html',
  styleUrls: ['./zen-side-flyout.component.scss']
})
export class ZenSideFlyoutComponent implements OnInit, OnDestroy, OnChanges {
  @Input() infoPopper: NgxPopperjsContentComponent;
  @Input() header: string;
  @Input() width = '50rem';

  @Output() onShow = new EventEmitter<void>();
  @Output() onHide = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => this.openNav(), 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => this.openNav(), 100);
  }

  ngOnDestroy() {
    this.closeNav();
  }

  openNav() {
    const sideNav = document.getElementById('zenSideFlyout');
    if (sideNav) {
      sideNav.style.width = this.width;
    }
    this.onShow.emit();
  }

  closeNav() {
    const sideNav = document.getElementById('zenSideFlyout');
    if (sideNav) {
      sideNav.style.width = '0';
    }
    this.onHide.emit(true);
  }

}
