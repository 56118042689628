import {Component, Input, OnInit} from '@angular/core';

export interface ZenCardNoDataConfig {
  message: string;
  actionButton: {label: string; command: () => void};
}

@Component({
  selector: 'app-zen-card-no-data',
  templateUrl: './zen-card-no-data.component.html',
  styleUrls: ['./zen-card-no-data.component.scss']
})
export class ZenCardNoDataComponent implements OnInit {
  @Input() config: ZenCardNoDataConfig;

  constructor() { }

  ngOnInit(): void {
  }

}
