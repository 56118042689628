import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {CountryEnum, ZenLocaleModel} from '../_model/zen-locale.model';
import {TranslateService} from '@ngx-translate/core';
import {findUkStateShortName} from '../_utils/zen-state.util';

export enum AddressFormType {
  ADDRESS = 'address',
  STREET = 'street'
}

@Injectable()
export class AddressAutocompleteService {

  defaultOptions;

  private previousSearchElement: HTMLInputElement;

  constructor(
    private translateSvc: TranslateService
  ) {
    const _translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;
    this.defaultOptions = {
      componentRestrictions: {country: _translations?.id}
    };
  }

  public handleAddressChange(formGroup: UntypedFormGroup, searchElement: HTMLInputElement, formType?: AddressFormType): void {
    if (this.previousSearchElement !== searchElement) {
      let autocomplete = new google.maps.places.Autocomplete(searchElement, this.defaultOptions);
      autocomplete.addListener('place_changed', () => {
        let address: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (address && address.address_components) {
          this.emptyAddressTwoField(formGroup);
          const addressObject = this.constructAddressObject(address.address_components, formType);
          formGroup.patchValue(addressObject);
          formGroup.updateValueAndValidity();
          searchElement.click();
          searchElement.blur();
        }
      });

      this.previousSearchElement = searchElement;
    }
  }

  /** This method is used to map google maps api response(address_components) with zen platforms address forms. **/
  private constructAddressObject(googleAddressComponents, formType) {
    const _country: CountryEnum = this.defaultOptions.componentRestrictions?.country;

    const stateComponent = googleAddressComponents.find(component =>
      _country === CountryEnum.GB ? component?.types?.[0] === 'administrative_area_level_2'
          : component?.types?.[0] === 'administrative_area_level_1');

    const zipComponent = googleAddressComponents.find(component => component?.types?.[0] === 'postal_code');

    // Locality is normally the city, but if not, neighborhood is often used (for mailing address)
    let cityComponent = googleAddressComponents.find(component => component?.types?.[0] === 'locality');
    if (!cityComponent) {
      cityComponent = googleAddressComponents.find(component => component?.types?.[0] === 'neighborhood');
    }
    if (!cityComponent) {
      cityComponent = googleAddressComponents.find(component => component?.types?.[0] === 'sublocality_level_1');
    }
    if (!cityComponent) {
      cityComponent = googleAddressComponents.find(component => component?.types?.[0] === 'postal_town');
    }
    const streetNumberComponent = googleAddressComponents.find(component => component?.types?.[0] === 'street_number');
    const streetNameComponent = googleAddressComponents.find(component => component?.types?.[0] === 'route');

    let address = '';
    if (streetNameComponent) {
      if (streetNumberComponent) {
        address = streetNumberComponent['short_name'] + ' ' + streetNameComponent['short_name'];
      } else {
        address = streetNameComponent['short_name'];
      }
    }

    const address1 = formType !== AddressFormType.STREET ? address : '';
    const street1 = formType === AddressFormType.STREET ? address : '';
    const city = cityComponent ? cityComponent['short_name'] : '';
    let state = stateComponent ? stateComponent['short_name'] : '';
    const zip = zipComponent ? zipComponent['short_name'] : '';

    // To fix - For UK the google-maps API is not providing abbreviated short_name for the state/county
    if (_country === CountryEnum.GB) {
      state = findUkStateShortName(state);
    }

    return {
      /** Common address fields through out the platform. **/
      address1, street1, city, state, zip,
      /** To support NatGas step 2 - ServiceAddress Len Details **/
      billingStreet1: address1,
      billingCity: city,
      billingState: state,
      billingZip: zip,
      /** To support NatGas step 2 - ServiceAddress Account Details **/
      svcStreet1: address1,
      svcCity: city,
      svcState: state,
      svcZip: zip,
      /** To support serviceAddress  edit */
      serviceAddressStreet1: address1,
      serviceAddressCity: city,
      serviceAddressState: state,
      serviceAddressZip: zip,
      /** To support len address edit */
      lenAddress1: address1,
      lenCity: city,
      lenState: state,
      lenZip: zip
    };
  }

  /** address 2 field is a user preference. So, it should be nullified on address1 change. **/
  emptyAddressTwoField(formGroup: UntypedFormGroup) {
    if (formGroup.controls.address2) {
      formGroup.controls.address2.setValue(null);
      formGroup.controls.address2.updateValueAndValidity();
    }
    if (formGroup.controls.street2) {
      formGroup.controls.street2.setValue(null);
      formGroup.controls.street2.updateValueAndValidity();
    }
  }

}
