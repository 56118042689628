import {ZenToastComponent, ZenToastDataModel} from '../_components/zen-toast/zen-toast.component';
import {ZenErrorMsgEnum} from '../_enums/zen-error-msg.enum';
import {
  ButtonActionTypes,
  ButtonTypes,
  ZenDialogComponent,
  ZenDialogDataModel,
  ZenDialogDataType
} from '../_dialogs/zen-dialog/zen-dialog.component';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ZenDialogSizeEnum} from '../_enums/zen-dialogs.enum';
import {ZenToastSnackbarComponent} from '../_components/zen-toast-snackbar/zen-toast-snackbar.component';
import {ZenIconsEnum} from '../_enums/zen-icons.enum';
import {NgxPopperjsContentComponent} from 'ngx-popperjs';
import {ZenToastWithPopperComponent} from '../_components/zen-toast-with-popper/zen-toast-with-popper.component';
import {MessageIconsEnum} from '../_zen-legacy-common/_enums/message-icons.enum';

@Injectable({
  providedIn: 'root'
})
export class ZenDialogMsgService {

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {
  }

  openToast(success: boolean, msg?: string, duration?: number) {
    const cls = success ? 'success-toast' : 'warn-toast';
    this.snackBar.openFromComponent(ZenToastComponent, {
      duration: duration || 6000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: [cls],
      data: {message: msg || (success ? 'Success' : 'Error'), icon: success ? ZenIconsEnum.DONE : ZenIconsEnum.ERROR} as ZenToastDataModel
    });
  }

  openErrorDialog(withGuidance: boolean, guideMsg?: string, headerTitle?: string, size?: ZenDialogSizeEnum) {
    let header;
    let bodyText;
    if (!headerTitle) {
      header = withGuidance ? {title: ZenErrorMsgEnum.ERR_MSG_2_TITLE} : {title: ZenErrorMsgEnum.ERR_MSG_1_TITLE};
    } else {
      header = {title: headerTitle};
    }
    bodyText = guideMsg || ZenErrorMsgEnum.ERR_MSG_1_TEXT;
    let dialogData: ZenDialogDataModel = {
      type: ZenDialogDataType.GUIDANCE_ERROR,
      header, bodyText,
      onClose: () => dialogRef.close()
    };
    const dialogRef = this.dialog.open(ZenDialogComponent, {
      width: size || ZenDialogSizeEnum.SMALL,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }


  openErrorGuidance(title?: string, guideMsg?: string) {
    let header;
    header = {title: title || 'Error Occurred'};
    let dialogData: ZenDialogDataModel = {
      type: ZenDialogDataType.GUIDANCE_ERROR,
      header, bodyHtml: guideMsg,
      onClose: () => dialogRef.close()
    };
    const dialogRef = this.dialog.open(ZenDialogComponent, {
      width: ZenDialogSizeEnum.MEDIUM,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  openSuccessGuidance(headerTitle: string, bodyHtml: string) {
    let dialogData: ZenDialogDataModel = {
      type: ZenDialogDataType.GUIDANCE_SUCCESS,
      header: {title: headerTitle},
      onClose: () => dialogRef.close(),
      bodyHtml
    };
    const dialogRef = this.dialog.open(ZenDialogComponent, {
      width: ZenDialogSizeEnum.SMALL,
      data: dialogData, autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  showConfirmationDialog(title: string, bodyText: string, onCancel: Function, onConfirm: Function, afterClosed?: Function, cancelText?: string, confirmText?: string, onClose?: Function) {
    let dialogData: ZenDialogDataModel = {
      type: ZenDialogDataType.CONFIRM,
      onClose: () => {
        popup.close();
        if (onClose) {
          onClose();
        }
      },
      header: {title: title, icon: MessageIconsEnum.WARNING, iconClass: 'warn_1'},
      actionButtons: [
        {
          label: cancelText || 'Cancel', btnType: ButtonTypes.MAT_BUTTON, command: () => {
            popup.close();
            if (onCancel) {
              onCancel();
            }
          },
          actionType: ButtonActionTypes.CANCEL
        },
        {
          label: confirmText || 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary', command: () => {
            popup.close();
            if (onConfirm) {
              onConfirm();
            }
          },
          actionType: ButtonActionTypes.SUBMIT
        }
      ],
      bodyHtml: bodyText
    };
    const popup = this.dialog.open(ZenDialogComponent, {
      width: ZenDialogSizeEnum.SMALL,
      data: dialogData,
      autoFocus: false
    });

    popup.afterClosed().subscribe(() => {
      if (afterClosed) {
        afterClosed();
      }
    });
  }


  showZenToastMessage(msg?: string) {
    this.snackBar.openFromComponent(ZenToastSnackbarComponent, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['zen-toast-help'],
      data: {message: msg} as ZenToastDataModel
    });
  }

  closeZenToastConfirmation() {
    this.snackBar.dismiss();
  }

  showZenToastWithPopper(popperContent: NgxPopperjsContentComponent) {
    this.snackBar.openFromComponent(ZenToastWithPopperComponent, {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['zen-toast-with-popper'],
      data: {popperContent} as ZenToastDataModel
    });
  }
}
