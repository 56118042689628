import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jen-zen-img-happiest',
  templateUrl: './jen-zen-img-happiest.component.html',
  styleUrls: ['./jen-zen-img-happiest.component.scss']
})
export class JenZenImgHappiestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
