import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {environment} from '../../../../../../environments';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';

@Injectable({
  providedIn: 'root'
})
export class CacheV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  clearOrgCache(): Observable<void> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/cache/clear`;
    return this.http.post<void>(url, {}).pipe(take(1));
  }

  clearRateCheckCache(): Observable<void> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/cache/clear-rate-check`;
    return this.http.post<void>(url, {}).pipe(take(1));
  }

}
