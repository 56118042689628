import {Observable, of, throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';


@Injectable()
export class ErrorService {

  constructor() { }

  public handleObsError(error: Response | any): Observable<any> {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      return of(body);
    } else {
      errMsg = error.toString();
    }
    return observableThrowError(errMsg || 'Internal Server error');
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleErrorWithEmptyResult<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
