<div id="zenSideFlyout" class="sidenav">
  <div class="filter-flyout-header" *ngIf="header else onlyCloseTpl">
    <div class="d-flex align-items-center justify-content-between">
      <div class="flyout-header d-inline-flex align-items-center">
        <p class="with-info">{{header}}</p>
        <i *ngIf="infoPopper" class="material-icons-outlined help-outline ms-2"
           [popper]="infoPopper" [popperHideOnScroll]="true" [popperHideOnClickOutside]="true"
           popperTrigger="click" popperApplyClass="xs" popperPlacement="right">info</i>
      </div>

      <i class="material-icons closebtn" (click)="closeNav()">close</i>
    </div>
  </div>

  <ng-template #onlyCloseTpl>
    <div class="d-flex justify-content-end mt-4 me-4">
      <i class="material-icons closebtn" (click)="closeNav()">close</i>
    </div>
  </ng-template>

  <div class="content-wrapper">
    <ng-content></ng-content>
  </div>

</div>
