<div class="w-100" *ngIf="config">
  <div class="card-container d-flex align-items-center justify-content-center text-center py-5">
    <div class="empty-state-content">
      <h1>{{config.title}}</h1>

      <div class="row justify-content-center text-center">
        <div class="col-7">
          <p class="text-md my-4">{{config.bodyText}}</p>
        </div>
      </div>

      <button mat-raised-button color="accent" (click)="config.action?.command()">
        <i class="material-icons large me-3" *ngIf="config.action.icon">{{config.action.icon}}</i>
        <span class="label">{{config.action.btnLabel}}</span>
      </button>

      <div class="mt-3" *ngIf="config.footerAction">
        <a class="font-weight-300 pointer" (click)="config.footerAction.command()">
          {{config.footerAction.linkLabel}}</a>
      </div>
    </div>
  </div>
</div>
