import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FilterClass, PriceEntityZenTable} from '../../_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FileData, FileStatus} from '../../_zen-legacy-common/_models/file-data';
import {MatrixPricingHelperService} from '../../../_modules/zen-market-pulse/_services/matrix-pricing-helper.service';
import {MatrixLeadService} from '../../../_modules/zen-market-pulse/_services/matrix-lead.service';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {ValidPatterns} from '../../_zen-legacy-common/_models/common/email-pattern';
import {MatrixLeadSelection} from '../../../_modules/zen-market-pulse/_models/matrix-lead-selection.model';
import {MatrixSearchCriteria} from '../../../_modules/zen-market-pulse/_models/matrix-search-criteria.model';
import {saveBlob} from '../../_zen-legacy-common/_utils/zen-utils';
import {orderBy} from '../../_zen-legacy-common/_utils/orderby.utils';
import {stateSupportsRateClass, stateSupportsZone} from '../../_zen-legacy-common/_utils/state-utils';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {ZenTableColsModel, ZenTableConfig} from '../../_components/zen-mat-table/zen-mat-table.component';
import {MatTableSize} from '../../_enums/zen-mat-table.enum';
import {CurrencyPipe} from '@angular/common';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import uuid from 'uuid';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {AddressAutocompleteService} from '../../_services/address-autocomplete.services';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {TranslateService} from '@ngx-translate/core';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';

enum LEAD_STEPS {
  STEP_1, STEP_2, STEP_3
}

enum RadioBtnValues {
  YES = 'Yes', NO = 'No'
}

@Component({
  selector: 'app-zen-market-pulse-generate-lead-dialog',
  templateUrl: './zen-market-pulse-generate-lead-dialog.component.html',
  styleUrls: ['./zen-market-pulse-generate-lead-dialog.component.scss']
})
export class ZenMarketPulseGenerateLeadDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  RadioBtnValues = RadioBtnValues;
  loading: boolean;
  supplierList: FilterClass[] = [];

  /* Default field */
  prices = [];
  utilityName: string;
  supplierNames = [];
  terms = [];
  rateClass = [];

  @Output() onLeadClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedIndex = LEAD_STEPS.STEP_1;
  LEAD_STEPS = LEAD_STEPS;
  steps = [
    {id: LEAD_STEPS.STEP_1, title: 'Step 1 - Confirm plan details', selected: true, completed: false},
    {id: LEAD_STEPS.STEP_2, title: 'Step 2 - Confirm your details', selected: false, completed: false},
    {id: LEAD_STEPS.STEP_3, title: 'Step 3 - Complete', selected: false, completed: false}
  ];

  /** Step 2 form */
  stepTwoForm: UntypedFormGroup;
  serviceAddress: UntypedFormGroup;
  billsToUpload: FileData[] = [];

  appearance: MatFormFieldAppearance = 'outline';

  tableConfig: ZenTableConfig;

  rateTblData: PriceEntityZenTable[] = [];

  fileInputCtrl = new UntypedFormControl();


  stateSupportsZone = (s) => stateSupportsZone(s);
  stateSupportsRateClass = (s) => stateSupportsRateClass(s);

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenMarketPulseGenerateLeadDialogComponent>,
              public svc: MatrixPricingHelperService, private leadService: MatrixLeadService,
              private currencyPipe: CurrencyPipe, private zenDialogSvc: ZenDialogMsgService,
              public addressAutocompleteService: AddressAutocompleteService,
              public translateSvc: TranslateService,
              private fb: UntypedFormBuilder, public orgSvc: OrganizationManagementService) {
    super(translateSvc);
    this.buildTable();
  }

  ngOnInit() {
    this.getStateSupplierList();
    this.svc.selectedPriceEntities.forEach(sp => {
      this.prices.push(sp.finalizedPrice);
      if (!this.supplierNames.includes(sp.supplierName)) {
        this.supplierNames.push(sp.supplierName);
      }
      if (sp.term && !this.terms.includes(sp.term)) { this.terms.push(sp.term); }
      if (sp.rateClass && !this.rateClass.includes(sp.rateClass)) { this.rateClass.push(sp.rateClass); }

      this.rateTblData.push({
        ...sp, renewableIcon: ZenIconsEnum.GREEN, renewableIconCls: sp.green ? 'contractStrategyCls' : ''
      });
    });
    this.newLead();
    this.utilityName = this.svc.utilityList.find(u => u.id === this.svc.searchCriteria.utilityId).label;
    this.buildForm();
  }

  buildTable() {
    this.tableConfig = {
      styles: {'min-width': MatTableSize.SMALL}, hideMultiselect: true, hideSearch: true, hidePagination: true,
      cols: [
        {field: 'supplierName', type: 'standard', header: this._translations?.nomenclature?.supplier},
        {field: 'term', type: 'standard', header: 'Term', align: 'center', formatter: (col, rowData) => this.formatTerm(col, rowData)},
        {
          field: 'finalizedPrice', type: 'standard', header: this._translations?.nomenclature?.rate,
          formatter: (col, rowData) => this.formatFinalisedRate(col, rowData)
        },
        {field: 'renewable', header: 'Renewable', iconCol: 'renewableIcon', align: 'center',
          iconClsCol: 'renewableIconCls', type: 'standard', whiteSpace: true}
      ]
    }
  }

  buildForm() {
    this.serviceAddress = this.fb.group({
      address1: [null],
      address2: [null],
      city: [null],
      zip: [null],
    })
    this.stepTwoForm = this.fb.group({
      companyName: [null, Validators.required],
      contactName: [null, Validators.required],
      contactEmail: [null, [Validators.required, Validators.pattern(ValidPatterns.EMAIL_PATTERN)]],
      contactPhone: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],

      /** Energy Contract Details */
      energyContract: [null, Validators.required], // radio-btn
      currentSupplier: [null],
      contractEndDate: [null],
      contractRateCents: [null],

      /** More than one service address or meter */
      multipleBills: [null, Validators.required],

      // if multipleBills === 'No'
      doYouHaveBill: [null],
      // if doYouHaveBill === 'No'
      serviceAddress: this.serviceAddress,
      utilityAccountNo: [null],
      // if doYouHaveBill === 'Yes' - Upload Bill

      // if multipleBills === 'Yes'
      // Do you have bills for all the accounts
      allAccountsBill: [null], // Boolean
      comments: [null],
      // existingSupplier: [null, Validators.required],
    });
    this.handleFormFieldChanges();
  }

  get sTwo() {
    return this.stepTwoForm.controls;
  }

  get svcAdd() {
    return this.serviceAddress.controls;
  }

  formatTerm(col: ZenTableColsModel, rowData: PriceEntityZenTable) {
    return rowData.term + 'm';
  }

  formatFinalisedRate(col: ZenTableColsModel, rowData: PriceEntityZenTable) {
    return this.currencyPipe.transform(rowData?.finalizedPrice, this._translations?.currency?.abbreviation, 'symbol-narrow', '1.3-3');
  }

  handleFormFieldChanges() {
    this.sTwo.energyContract.valueChanges.subscribe(() => {
      if (this.sTwo.energyContract.value === RadioBtnValues.YES) {
        this.sTwo.currentSupplier.setValidators([Validators.required]);
        this.sTwo.contractEndDate.setValidators([Validators.required]);
        this.sTwo.contractRateCents.setValidators([Validators.required]);
      } else {
        this.resetFields(this.sTwo.currentSupplier);
        this.resetFields(this.sTwo.contractEndDate);
        this.resetFields(this.sTwo.contractRateCents);
      }
    });

    this.sTwo.multipleBills.valueChanges.subscribe(() => {
      if (this.sTwo.multipleBills.value) {
        this.sTwo.allAccountsBill.setValidators([Validators.required]);
        this.resetFields(this.sTwo.doYouHaveBill);
      } else {
        this.sTwo.doYouHaveBill.setValidators([Validators.required]);
        this.sTwo.doYouHaveBill.setErrors({required: true});
        this.resetFields(this.sTwo.allAccountsBill);
      }
      this.billsToUpload = [];
      this.fileInputCtrl.setValue(null);
    });

    this.sTwo.allAccountsBill.valueChanges.subscribe(() => {
      if (this.sTwo.allAccountsBill.value === RadioBtnValues.NO) {
        this.fileInputCtrl.setValidators([]);
      } else {
        this.fileInputCtrl.setValidators([Validators.required]);
      }
      this.billsToUpload = [];
      this.fileInputCtrl?.setValue(null);
      this.fileInputCtrl.updateValueAndValidity();
    });

    this.sTwo.doYouHaveBill.valueChanges.subscribe(() => {
      if (this.sTwo.doYouHaveBill.value === RadioBtnValues.NO) {
        this.svcAdd.address1.setValidators([Validators.required]);
        this.svcAdd.city.setValidators([Validators.required]);
        this.svcAdd.zip.setValidators([Validators.required]);
        this.sTwo.utilityAccountNo.setValidators([Validators.required]);
        this.fileInputCtrl.setValidators([]);
      } else {
        this.resetFields(this.svcAdd.address1);
        this.resetFields(this.svcAdd.city);
        this.resetFields(this.svcAdd.zip);
        this.resetFields(this.sTwo.utilityAccountNo);
        this.fileInputCtrl.setValidators([Validators.required]);
      }
      this.billsToUpload = [];
      this.fileInputCtrl.setValue(null);
      this.fileInputCtrl.updateValueAndValidity();
    });
  }

  resetFields(ctrl: AbstractControl) {
    ctrl.setValidators(null);
    ctrl.setValue(null);
    ctrl.setErrors(null);
    ctrl.markAsPristine();
    ctrl.markAsUntouched();
  }

  newLead() {
    this.billsToUpload = [];
  }

  handlePagination(selectStep = LEAD_STEPS.STEP_1, compStep?: LEAD_STEPS) {
    this.selectedIndex = selectStep;
    // Changing dialog message
    switch (this.selectedIndex) {
      case LEAD_STEPS.STEP_1:
        this.data.header = {
          title: 'Thank you! Let’s get started',
          icon: ZenIconsEnum.SETTINGS,
        };
        this.data.bodyText = `Confirm the details below to secure the price you have selected. `;
        break;
      case LEAD_STEPS.STEP_2:
        this.data.header = {
          title: 'Confirm your details',
          icon: ZenIconsEnum.SETTINGS,
        };
        this.data.bodyText = `Complete the information below to tie the selected price to your business.`;
        break;
      case LEAD_STEPS.STEP_3:
        this.data.header = {
          title: 'Perfect! You’re all set.',
          icon: ZenIconsEnum.SETTINGS,
        };
        this.data.bodyText = null;
        break;
    };

    this.steps = this.steps.map(s => {
      s.selected = s.id === selectStep;
      s.completed = s.id === compStep;
      return s;
    });
  }

  handleComplete() {
    this.stepTwoForm.markAllAsTouched();
    this.serviceAddress.markAllAsTouched();
    this.fileInputCtrl.markAllAsTouched();
    if (this.stepTwoForm.valid && !(this.billsToUpload.length === 0 && (this.sTwo.allAccountsBill.value === RadioBtnValues.YES
      || this.sTwo.doYouHaveBill.value === RadioBtnValues.YES))) {
      this.sendLead();
    }
  }

  sendLead() {
    const matrixPricingSelections = this.svc.selectedPriceEntities.map(pe => {
      return {
        id: pe.id, supplierName: pe.supplierName, rateKwhCents: pe.finalizedPrice,
        renewable: pe.green, passthroughCT: pe.capAndTransPassthrough, savingsAmount: pe.savings,
        savingsPct: pe.savingsPct, termMonths: pe.term
      } as MatrixLeadSelection
    });
    let val = this.stepTwoForm.value;
    val.serviceAddressCount = this.svc.selectedServiceAddresses.length;
    delete val.energyContract;
    delete val.doYouHaveBill;
    delete val.allAccountsBill;
    this.loading = true;
    /** .filter(d => d) - is used to filter out all the null values. */
    if (Object.values(this.serviceAddress.value).filter(d => d).length === 0) {
      delete val.serviceAddress; // Remove service address data if there is no values.
    }
    /** Replacing rate class id with its label value. To populate the value in the email template.*/
    let searchCriteria: MatrixSearchCriteria = {...this.svc.searchCriteria}; // To overcome two way binding & avoid showing wrong data in the dropdown.
    if (searchCriteria.rateClass) {
      const rateCls = this.svc.rateClassList.find(rc => rc.value === searchCriteria.rateClass);
      if (rateCls) { searchCriteria.rateClass = rateCls.label; }
    }
    this.leadService.sendLeadEmail(this.orgSvc.organization.id, searchCriteria, val, matrixPricingSelections, this.billsToUpload)
      .subscribe(() => {
        this.handlePagination(LEAD_STEPS.STEP_3, LEAD_STEPS.STEP_3);
        this.steps = this.steps.map(s => {
          s.selected = false;
          s.completed = true;
          return s;
        });
        this.loading = false;
      }, () => {
        this.loading = false;
        this.zenDialogSvc.openErrorDialog(true, 'Failed to send lead. If the problem persists please contact support.');
      });
  }

  downloadBill(f: FileData) {
    saveBlob(f.fileRef, f.type, f.fileName);
  }

  getStateSupplierList() {
    this.loading = true;
    this.orgSvc.getStateSuppliers().subscribe(response => {
      let stateSuppliers = response.find(r => r.stateId === this.svc.searchCriteria.state);
      if (stateSuppliers) {
        this.supplierList = stateSuppliers.suppliers.map(s => ({label: s.supplierName, value: s.supplierName}));
        this.supplierList = orderBy(this.supplierList, 'label');
      }
      this.loading = false;
    }, () => this.loading = false);
  }

  onFileInput(files: FileList) {
    let invalidFormatFiles = false;
    const filesArray = [];

    const acceptedFormats = 'application/pdf, image/*'.split(',').map(item => {
      return item.trim()
    });

    for (let index = 0; index < files.length; index++) {
      let file = files.item(index);
      filesArray.push(file);

      // windows Excel CSV fix
      let fileType = this.getFileType(file);

      if (!acceptedFormats.includes(fileType) && !acceptedFormats.includes(fileType.split('/')[0] + '/*')) {
        invalidFormatFiles = true;
        break;
      }
    }
    if (!invalidFormatFiles && this.billsToUpload) {
      let initialLen = this.billsToUpload.length;
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        let fileToLoad: FileData = new FileData(
          file,
          uuid.v4(),
          file.name,
          this.getFileType(file),
          FileStatus.LOADING,
          false);
        this.billsToUpload.push(fileToLoad);
      }
      if (this.billsToUpload.length > initialLen) {
        this.billsToUpload.filter(file => file.fileStatus === FileStatus.LOADING)
          .forEach(fileToLoad => fileToLoad.load()
            .subscribe(fileLoaded => {
              this.billsToUpload = this.billsToUpload.map(fileData => {
                if (fileData.id !== fileLoaded.id) {
                  return fileData;
                } else {
                  return fileLoaded;
                }
              });
            }));
      }
    } else {
      this.zenDialogSvc.openErrorDialog(true, 'Invalid file format.');
    }
  }

  getFileType(file: File): string {
    let fileType = file.type.trim();
    // window fix for excel CSV
    const ext = file.name.split('.').pop().toLowerCase().trim();
    if (ext === 'csv') {
      fileType = 'text/csv';
    }
    return fileType;
  }

  svcAddressHasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.serviceAddress, formControlName, formErrorName);
  }

  sTwoHasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.stepTwoForm, formControlName, formErrorName);
  }
}
