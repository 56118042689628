import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';


export enum FileStatus {
  LOADING,
  READY_TO_UPLOAD,
  UPLOADING,
  UPLOADED,
  ERROR
}

export class FileData {
  constructor(public fileRef: any,
              public id?: string,
              public fileName?: string,
              public type?: string,
              public fileStatus?: FileStatus,
              public uploaded?: boolean,
              public result?: any,
              public metadata?: any,
              public documentId?: any,
              public fileId?: any) {}
  load(): Observable<FileData> {
    return loadFile(this).pipe(map(fileData => {
      fileData.fileStatus = FileStatus.READY_TO_UPLOAD;
      return fileData;
    }));
  }
}


function loadFile(fileData: FileData): Observable<FileData> {
  let result = new Promise<FileData>(resolve => {
    let reader = new FileReader();
    reader.onload = function () {
      fileData.result = reader.result;
      resolve(fileData);
    };
    reader.readAsBinaryString(fileData.fileRef);
  });
  return from(result);
}
