import {PortfolioFilterRequest, PortfolioFilterResourceIdRequest} from '../../portfolio/_model/portfolio-initial-filter.model';
import {
  FilterParentAttribute,
  RenewableStatuses,
  TreeItemFlatNode
} from '../../../_shared/_components/portfolio-filter-flyout/portfolio-filter-flyout.component';
import {PortfolioHierarchyLevelEnum} from '../../portfolio/_enums/portfolio-hierarchy-level.enum';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {ZenMatTableSelectOption, ZenMatTableSelectSearchConfig} from '../../../_shared/_components/zen-mat-table/zen-mat-table.component';
import {UntypedFormControl} from '@angular/forms';
import {EventEmitter} from '@angular/core';
import {PortfolioSectionsEnum, PortfolioTabsEnum} from '../../portfolio/_enums/portfolio-tabs.enum';
import _ from 'lodash';
import {ZenMatTableHelperService} from '../../../_shared/_services/helpers/zen-mat-table-helper.service';
import {PortfolioFilterSessionStore} from '../../../_shared/_stores/filter/portfolio-filter-session.store';

export abstract class ZenAbstractFilterService {
  currentFilters: PortfolioFilterRequest;
  previousFilters: PortfolioFilterRequest;
  filterNodes: TreeItemFlatNode[];
  filterChipNodes: TreeItemFlatNode[];
  hierarchyLevel: PortfolioHierarchyLevelEnum;
  hierarchyIds = {customerId: null, lenId: null, serviceAddressId: null, meterId: null};
  selectedTab: PortfolioTabsEnum; // Query param - Used to make API call w.r.t, the last selected tab on reload.
  serviceSection = PortfolioSectionsEnum.PORTFOLIO; // Used to easily identify different abtract filter implementations
  selectedSection: PortfolioSectionsEnum;
  isTopLevelComponent: boolean;
  selectedTabIndex: number; // Query param - Used to reach last mat-tab position on reload.
  hasAppliedFilters: boolean;
  protected router: Router;
  protected route: ActivatedRoute;
  protected authSvc: AuthenticationService;
  protected portfolioFilterStore: PortfolioFilterSessionStore;
  pfCustomerSelectSearchConfig: ZenMatTableSelectSearchConfig =
    {selectOptions: [], searchTypeCtrl: new UntypedFormControl(null), inputCtrl: new UntypedFormControl(null)};
  pfLenSelectSearchConfig: ZenMatTableSelectSearchConfig =
    {selectOptions: [], searchTypeCtrl: new UntypedFormControl(null), inputCtrl: new UntypedFormControl(null)};
  pfServiceAddressSelectSearchConfig: ZenMatTableSelectSearchConfig =
    {selectOptions: [], searchTypeCtrl: new UntypedFormControl(null), inputCtrl: new UntypedFormControl(null)};
  pfMeterSelectSearchConfig: ZenMatTableSelectSearchConfig =
    {selectOptions: [], searchTypeCtrl: new UntypedFormControl(null), inputCtrl: new UntypedFormControl(null)};
  activeStatusUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected zenMatTableHelperSvc: ZenMatTableHelperService;

  protected constructor (router: Router, route: ActivatedRoute, authSvc: AuthenticationService, zenMatTableHelperSvc: ZenMatTableHelperService, portfolioFilterStore: PortfolioFilterSessionStore) {
    this.router = router;
    this.route = route;
    this.authSvc = authSvc;
    this.zenMatTableHelperSvc = zenMatTableHelperSvc;
    this.portfolioFilterStore = portfolioFilterStore;
    this.hasAppliedFilters = false;
  }


  applyFiltersAndReload(nodes: TreeItemFlatNode[], hierarchyLevel: PortfolioHierarchyLevelEnum, customerId: number, lenId: string,
                        serviceAddressId: number, callbackFn: EventEmitter<void>, updateURLState = true) {
    nodes = this.removeNonFunctionalNodes(nodes);
    const filters = this.createFilterObject(nodes);

    if (JSON.stringify(this.currentFilters) !== JSON.stringify(filters) && filters !== undefined) {
      this.previousFilters = this.currentFilters;
      this.currentFilters = this.createFilterObject(nodes);
      if (updateURLState) {
        this.updateUrlState();
      } // passed in through component input. can turn off url state to manually do it
      this.emitChangeStatusEvent();
      this.filterNodes = nodes;
      this.filterChipNodes = this.mapFilterChipGroupings(nodes);
      this.hasAppliedFilters = this.filterChipNodes.length > 0;

      callbackFn.emit();
    }
  }



  updateUrlState(selectedSectionEnum: PortfolioSectionsEnum = null, selectedTab: PortfolioTabsEnum = null, selectedTabIndex: number = null) {
    let queryParams: { filters?: string; selectedSection?: PortfolioSectionsEnum, selectedTab?: string, tabIndex?: number } = {};

    // If we have hierarchy values, remove them and see if we still have a filter we should store/persist to URL
    const {customerIds, serviceAddressIds, meterIds, lenIds, ...remainingFilters} = this.currentFilters != null ? this.currentFilters : {customerIds: null, serviceAddressIds: null, meterIds: null, lenIds: null};
    // If there are no filters, set to null instead of an empty object
    const isObjectWithData =  Object.values(remainingFilters).filter(x => x != null).filter(x => !Array.isArray(x) || (Array.isArray(x) && x.length > 0)).length > 0;

    if (isObjectWithData) {
      queryParams.filters = encodeURIComponent(this.getFilterString(null, null, null, null, true));
      this.updatePortfolioFilterStore(this.portfolioFilterStore);
    } else {
      queryParams.filters = null;
      this.updatePortfolioFilterStore(this.portfolioFilterStore);
    }

    if (selectedSectionEnum != null) {
      queryParams.selectedSection = selectedSectionEnum;
    }

    if (selectedTab !== null) {
      queryParams.selectedTab = selectedTab; // PortfolioTabsEnum
    }
    if (selectedTabIndex !== null) {
      queryParams.tabIndex = selectedTabIndex;
    }

    if (this.isTopLevelComponent) {
      delete queryParams.selectedSection;
    }
    this.router.navigate([], {relativeTo: this.route, queryParams, replaceUrl: true, queryParamsHandling: 'merge'});
  }

  getFilterString(customerId?: number, lenId?: string, serviceAddressId?: number, meterId?: number, excludeSelectSearch = false) {
    // To support customer login. This will set following filter with customerIds: [] in portfolio API calls
    if (this.authSvc.isCustomer()) {
      customerId = this.authSvc.getCurrentCustomerId();
    }
    // If filters are undefined, lets return null so that 'null' filters can be passed to the backend.
    // == is intentional to catch undefined and null
    if (this.isEmptyFilter() && customerId == null && lenId == null && serviceAddressId == null && meterId == null) {
      // Based on the tab selected, we need to check if the search + search type is empty. If it is, return filter = null
      switch (this.selectedTab) {
        case PortfolioTabsEnum.CUSTOMERS:
          if (!this.pfCustomerSelectSearchConfig.inputCtrl.value && !this.pfCustomerSelectSearchConfig.searchTypeCtrl.value) {
            return null;
          }
          break;
        case PortfolioTabsEnum.LENS:
          if (!this.pfLenSelectSearchConfig.inputCtrl.value && !this.pfLenSelectSearchConfig.searchTypeCtrl.value) {
            return null;
          }
          break;
        case PortfolioTabsEnum.SERVICE_ADDRESSES:
          if (!this.pfServiceAddressSelectSearchConfig.inputCtrl.value && !this.pfServiceAddressSelectSearchConfig.searchTypeCtrl.value) {
            return null;
          }
          break;
        case PortfolioTabsEnum.METERS:
          if (!this.pfMeterSelectSearchConfig.inputCtrl.value && !this.pfMeterSelectSearchConfig.searchTypeCtrl.value) {
            return null;
          }
          break;
      }
    }
    let filters;
    if (excludeSelectSearch) { // Excluding this select search filter while updating url params.
      filters = {...this.currentFilters, ...this.getHierarchyObject(customerId, lenId, serviceAddressId, meterId)}
    } else {
      filters = {
        ...this.currentFilters, ...this.getSelectSearchFilters(),
        ...this.getHierarchyObject(customerId, lenId, serviceAddressId, meterId)
      };
    }

    return JSON.stringify(filters);
  };

  getSelectSearchFilters() {
    // Set the right search config to pull data from based on the tab
    let searchConfig: ZenMatTableSelectSearchConfig;
    switch (this.selectedTab) {
      case PortfolioTabsEnum.CUSTOMERS:
        searchConfig = this.pfCustomerSelectSearchConfig;
        break;
      case PortfolioTabsEnum.LENS:
        searchConfig = this.pfLenSelectSearchConfig;
        break;
      case PortfolioTabsEnum.SERVICE_ADDRESSES:
        searchConfig = this.pfServiceAddressSelectSearchConfig;
        break;
      case PortfolioTabsEnum.METERS:
        searchConfig = this.pfMeterSelectSearchConfig;
        break;
    }
    let _selectFilters = {} as any;
    const selectedOption = searchConfig?.searchTypeCtrl?.value as ZenMatTableSelectOption;
    if (selectedOption || searchConfig?.inputCtrl?.value) {
      const modifiedInputVal = this.zenMatTableHelperSvc.setInputDataTypeConvert(searchConfig.inputCtrl.value, selectedOption.dataType);
      // Setting customer select filters.
      if (this.selectedTab === PortfolioTabsEnum.CUSTOMERS) {
        _selectFilters.customerFilters = {};
        if (searchConfig.inputCtrl.value) {
          _selectFilters.customerFilters[selectedOption?.objectName] = modifiedInputVal;
        }
      }
      // Setting LEN select filters.
      if (this.selectedTab === PortfolioTabsEnum.LENS) {
        _selectFilters.legalEntityNameFilters = {};
        if (searchConfig.inputCtrl.value) {
          _selectFilters.legalEntityNameFilters[selectedOption?.objectName] = modifiedInputVal;
        }
      }
      // Setting serviceAddress select filters.
      if (this.selectedTab === PortfolioTabsEnum.SERVICE_ADDRESSES) {
        _selectFilters.serviceAddressFilters = {};
        if (searchConfig.inputCtrl.value) {
          _selectFilters.serviceAddressFilters[selectedOption?.objectName] = modifiedInputVal;
        }
      }
      // Setting meter select filters.
      if (this.selectedTab === PortfolioTabsEnum.METERS) {
        _selectFilters.meterFilters = {};
        if (searchConfig.inputCtrl.value) {
          _selectFilters.meterFilters[selectedOption?.objectName] = modifiedInputVal;
        }
      }
    }
    return _selectFilters;
  }

  isEmptyFilter()   {
    return this.currentFilters === null || this.currentFilters === undefined || Object.keys(this.currentFilters).length === 0;
  }

  removeNonFunctionalNodes(nodes: TreeItemFlatNode[]): TreeItemFlatNode[] {
    let timeHorizonNodes: TreeItemFlatNode[] = [], riskStatuses: TreeItemFlatNode[] = [], procurementStatuses: TreeItemFlatNode[] = [],
      commodityTypes: TreeItemFlatNode[] = [], mloaSignedStatuses: TreeItemFlatNode[] = [], meterStatuses: TreeItemFlatNode[] = [],
      activationStatuses: TreeItemFlatNode[] = [], renewableStatuses: TreeItemFlatNode[] = [];

    nodes.forEach(nd => {
      switch (nd.parentAttribute) {
        case FilterParentAttribute.commodityTypes:
          commodityTypes.push(nd);
          break;
        case FilterParentAttribute.mloaSignedStatus:
          mloaSignedStatuses.push(nd);
          break;
        case FilterParentAttribute.procurementStatuses:
          if (!nd.isCheckableParent) {
            procurementStatuses.push(nd);
          }
          break;
        case FilterParentAttribute.meterStatuses:
          meterStatuses.push(nd)
          break;
        case FilterParentAttribute.timeHorizons:
          timeHorizonNodes.push(nd);
          break;
        case FilterParentAttribute.riskStatuses:
          riskStatuses.push(nd);
          break;
        case FilterParentAttribute.activationStatuses:
          activationStatuses.push(nd);
          break;
        case FilterParentAttribute.renewableStatuses:
          renewableStatuses.push(nd);
          break;
      }
    });

    let attrToFilter: FilterParentAttribute[] = [];
    if (meterStatuses.length === 0) {
      // remove this field so it doesn't get converted to JSON
      attrToFilter.push(FilterParentAttribute.timeHorizons);
    }

    if (renewableStatuses.length === 1 && renewableStatuses[0].value === RenewableStatuses.ALL) {
      // selecting both filters is the same as selecting none
      attrToFilter.push(FilterParentAttribute.renewableStatuses);
    }

    if (commodityTypes.length === 2) {
      // selecting both filters is the same as selecting none
      attrToFilter.push(FilterParentAttribute.commodityTypes);
    }

    if (riskStatuses.length === 4) {
      // selecting 4 filters is the same as selecting none
      attrToFilter.push(FilterParentAttribute.riskStatuses);
    }

    if (mloaSignedStatuses.length === 2) {
      // selecting both filters is the same as selecting none
      attrToFilter.push(FilterParentAttribute.mloaSignedStatus);
    }

    // Remove this, because its the default value even if not included in the filter, and we don't need a filter chip
    if (activationStatuses.length === 1 && activationStatuses[0].value === true) {
      attrToFilter.push(FilterParentAttribute.activationStatuses);

    }
    return nodes.filter(n => !attrToFilter.includes(n.parentAttribute))
  }

  handleUrlFilterParams(params: Params) {
    // If we have URL filters, use them
    if (params.filters && params.filters !== 'null') {
      this.currentFilters = JSON.parse(decodeURIComponent(params.filters));
      const {customerIds, serviceAddressIds, meterIds, lenIds, ...remainingFilters} = this.currentFilters != null ? this.currentFilters : {customerIds: null, serviceAddressIds: null, meterIds: null, lenIds: null};
      // If there are no filters, set to null instead of an empty object
      this.hasAppliedFilters =  Object.values(remainingFilters).filter(x => x != null).filter(x => !Array.isArray(x) || (Array.isArray(x) && x.length > 0)).length > 0;
      this.updatePortfolioFilterStore(this.portfolioFilterStore);
      this.emitChangeStatusEvent();
    } else {
      // If filters are null, but there is a value in the store for this type of filter, restore
      // the filters from the store, and apply to URL
      // If there are no filters in the store, then we have null filters
      this.retrieveFiltersFromStoreOrSetToNull();
    }
  }

  retrieveFiltersFromStoreOrSetToNull() {
    const storeValue = this.portfolioFilterStore.getValue();
    switch (this.serviceSection) {
      case PortfolioSectionsEnum.PORTFOLIO:
        storeValue?.portfolioFilterString != null ? this.getSectionFilters(storeValue.portfolioFilterString) : this.resetFilters();
        break;
      case PortfolioSectionsEnum.RATE_CHECKS:
        storeValue?.rateCheckFilterString != null ? this.getSectionFilters(storeValue.rateCheckFilterString) : this.resetFilters();
        break;
      case PortfolioSectionsEnum.CONTRACTS:
        storeValue?.contractFilterString != null ? this.getSectionFilters(storeValue.contractFilterString) : this.resetFilters();
        break;
      case PortfolioSectionsEnum.CONTACTS:
        break;
      case PortfolioSectionsEnum.STACKRANKING:
        storeValue?.stackRankingFilterString != null ? this.getSectionFilters(storeValue.stackRankingFilterString) : this.resetFilters();
        break;
      case PortfolioSectionsEnum.DASHBOARD:
        storeValue?.dashboardFilterString != null ? this.getSectionFilters(storeValue.dashboardFilterString) : this.resetFilters();
        break;
    }

  }

  getSectionFilters(encodedFilterString: string) {
    this.currentFilters = JSON.parse(decodeURIComponent(encodedFilterString));
    this.updateUrlState();
  }


  resetFilters() {
    // Set to null so that previous filters don't hang around when navigating to/from old UI

    const queryParams = {selectedSection: this.serviceSection};
    if (this.isTopLevelComponent) {
      delete queryParams.selectedSection;
    }
    this.router.navigate([], {relativeTo: this.route, queryParams, replaceUrl: true, queryParamsHandling: 'merge'});
  }

  resetFiltersAndNodes() {
    this.currentFilters = null;
    this.filterChipNodes = null;
    this.hasAppliedFilters = false;
    this.portfolioFilterStore.reset();
  }

  updatePortfolioFilterStore(portfolioFilterStore: PortfolioFilterSessionStore) {
    // Use json destructuring to remove customerIds, serviceAddressIds, meterIds, lenIds from the filters object
    // We don't want this stored as part of the filter string
    const {customerIds, serviceAddressIds, meterIds, lenIds, ...remainingFilters} = this.currentFilters != null ? this.currentFilters : {customerIds: null, serviceAddressIds: null, meterIds: null, lenIds: null};
    // If there are no filters, set to null instead of an empty object
    const isObjectWithData =  Object.values(remainingFilters).filter(x => x != null).filter(x => !Array.isArray(x) || (Array.isArray(x) && x.length > 0)).length > 0;

    const encodedUriFilters = (isObjectWithData) ? encodeURIComponent(JSON.stringify(remainingFilters)) : null;

    switch (this.serviceSection) {
      case PortfolioSectionsEnum.PORTFOLIO:
        portfolioFilterStore.update({portfolioFilterString: encodedUriFilters});
        break;
      case PortfolioSectionsEnum.RATE_CHECKS:
        portfolioFilterStore.update({rateCheckFilterString: encodedUriFilters});
        break;
      case PortfolioSectionsEnum.CONTRACTS:
        portfolioFilterStore.update({contractFilterString: encodedUriFilters});
        break;
      case PortfolioSectionsEnum.CONTACTS:
        break;
      case PortfolioSectionsEnum.STACKRANKING:
        portfolioFilterStore.update({stackRankingFilterString: encodedUriFilters});
        break;
      case PortfolioSectionsEnum.DASHBOARD:
        portfolioFilterStore.update({dashboardFilterString: encodedUriFilters});
        break;
    }
  }

  emitChangeStatusEvent() {
    if (this.currentFilters != null && this.currentFilters.activationStatuses != null && String(this.currentFilters.activationStatuses[0]) === 'false') {
      this.activeStatusUpdate.emit(false);
    } else {
      this.activeStatusUpdate.emit(true);
    }
  }

  mapFilterChipGroupings(nodes: TreeItemFlatNode[]): TreeItemFlatNode[] {
    // Separate grouped nodes out into different arrays
    let utilityNodes: TreeItemFlatNode[] = [], meterStatuses: TreeItemFlatNode[] = [], riskStatusNodes: TreeItemFlatNode[] = [],
      customerTypeNodes: TreeItemFlatNode[] = [], serviceAddressTypeNodes: TreeItemFlatNode[] = [], meterTypeNodes: TreeItemFlatNode[] = [],
      customerTagNodes: TreeItemFlatNode[] = [], serviceAddressTagNodes: TreeItemFlatNode[] = [],
      meterTagNodes: TreeItemFlatNode[] = [], timeHorizonNodes: TreeItemFlatNode[] = [], otherNodes: TreeItemFlatNode[] = [],
      lenTagNodes: TreeItemFlatNode[] = [], activationStatusNodes: TreeItemFlatNode[] = [], contractStateNodes: TreeItemFlatNode[] = [],
      rateCheckStatusesNodes: TreeItemFlatNode[] = [], contractStatusesNodes: TreeItemFlatNode[] = [], renewableStatusNodes: TreeItemFlatNode[] = [], procurementStatuses: TreeItemFlatNode[] = [];

    nodes.forEach(nd => {
      switch (nd.parentAttribute) {
        case FilterParentAttribute.utilityIds:
          utilityNodes.push(nd);
          break;
        case FilterParentAttribute.customerTypes:
          customerTypeNodes.push(nd);
          break;
        case FilterParentAttribute.meterTypes:
          meterTypeNodes.push(nd);
          break;
        case FilterParentAttribute.serviceAddressTypes:
          serviceAddressTypeNodes.push(nd);
          break;
        case FilterParentAttribute.meterStatuses:
          meterStatuses.push(nd);
          break;
        case FilterParentAttribute.timeHorizons:
          timeHorizonNodes.push(nd);
          break;
        case FilterParentAttribute.customerTags:
          customerTagNodes.push(nd);
          break;
        case FilterParentAttribute.serviceAddressTags:
          serviceAddressTagNodes.push(nd);
          break;
        case FilterParentAttribute.meterTags:
          meterTagNodes.push(nd);
          break;
        case FilterParentAttribute.lenTags:
          lenTagNodes.push(nd);
          break;
        case FilterParentAttribute.riskStatuses:
          riskStatusNodes.push(nd);
          break;
        case FilterParentAttribute.rateCheckStatuses:
          rateCheckStatusesNodes.push(nd);
          break;
        case FilterParentAttribute.contractStatuses:
          contractStatusesNodes.push(nd);
          break;
        case FilterParentAttribute.rateCheckStates:
        case FilterParentAttribute.contractStates:
          contractStateNodes.push(nd);
          break;
        case FilterParentAttribute.activationStatuses:
          // Only show the inactive chip, hide the active chip.
          if (!nd.value) {
            activationStatusNodes.push(nd);
          }
          break;
        case FilterParentAttribute.renewableStatuses:
          // Never show the "All" chip
          if (nd.value !== RenewableStatuses.ALL) {
            otherNodes.push(nd);
          }
          break;
        case FilterParentAttribute.procurementStatuses:
          if (!nd.isCheckableParent) {
            otherNodes.push(nd);
          }
          break;
        default  :
          otherNodes.push(nd);
      }

    })
    const draftIndex = meterStatuses.findIndex(x => x.value === 'Draft');
    if (draftIndex !== -1) {
      meterStatuses.push(...meterStatuses.splice(draftIndex, 1));
    }

    const timeHorizonNode = timeHorizonNodes?.length > 0 ? timeHorizonNodes[0] : null;
    const utilityStates = new Set(utilityNodes.filter(n => n.utilityState != null).map(n => n.utilityState));

    // Utility nodes are a bit special on how they group by state, so they are done seperately
    const utilityResultNodes = Array.from(utilityStates).map(s =>
      this.mapFilterChipGroup(utilityNodes.filter(n => n.expandable === false && n.utilityState === s), false,
        FilterParentAttribute.utilityIds, utilityNodes.find(x => x.parentAttribute === FilterParentAttribute.utilityIds).icon,
        s + ' | ', s));
    // All other grouped chips handled here
    const singleGroupedNodeResults = [
      this.mapFilterChipGroup(activationStatusNodes),
      this.mapFilterChipGroup(meterStatuses, false, FilterParentAttribute.timeHorizons,
        timeHorizonNode ? timeHorizonNode.icon : '', timeHorizonNode ? timeHorizonNode.item + ' | ' : ''),
      this.mapFilterChipGroup(riskStatusNodes),
      this.mapFilterChipGroup(customerTypeNodes),
      this.mapFilterChipGroup(serviceAddressTypeNodes),
      this.mapFilterChipGroup(meterTypeNodes),
      this.mapFilterChipGroup(customerTagNodes, true),
      this.mapFilterChipGroup(serviceAddressTagNodes, true),
      this.mapFilterChipGroup(meterTagNodes, true),
      this.mapFilterChipGroup(lenTagNodes, true),
      this.mapFilterChipGroup(contractStateNodes),
      this.mapFilterChipGroup(rateCheckStatusesNodes),
      this.mapFilterChipGroup(contractStatusesNodes),
      //this.mapFilterChipGroup(procurementStatuses)
    ]

    if (utilityResultNodes != null) {
      otherNodes = otherNodes.concat(...utilityResultNodes)
    }

    // Add group chips back
    singleGroupedNodeResults.filter(x => x != null).forEach(x => otherNodes = otherNodes.concat(x))

    return otherNodes;
  }

  mapFilterChipGroup(nodes: TreeItemFlatNode[], iconStacked?: boolean, parentAttribute?: string, icon?: string, valuePrefix?: string,
                     utilityState?: string): TreeItemFlatNode {
    // This prevents the nodes from being updated during child node mutation below
    const tempNodes = _.cloneDeep(nodes);
    const childNodes = utilityState ? [...tempNodes.map(x => {
      x.item = valuePrefix + x.item;
      return x
    })] : tempNodes;
    const itemValues = nodes.map(n => n.item).slice(0, 1).join(', ');
    let uiValue = valuePrefix ? (valuePrefix + itemValues) : itemValues;
    uiValue = nodes.length > 1 ? uiValue : uiValue;
    return nodes.length > 0 ? {
      item: uiValue,
      value: uiValue,
      disabled: false,
      parentAttribute: parentAttribute ? parentAttribute : nodes[0].parentAttribute,
      isRadioButton: false,
      isCheckableParent: true,
      utilityState: utilityState ? utilityState : null,
      icon: icon ? icon : nodes[0].icon,
      showStackedIcon: iconStacked ? iconStacked : false,
      chipChildren: childNodes
    } as TreeItemFlatNode : null;
  }

  createFilterObject = (nodes: TreeItemFlatNode[]): PortfolioFilterRequest => {
    // Use the selected nodes parentAttribute, and values to write a json object that can be sent to the backend.
    const setKeys = new Set(nodes.filter(x => x.parentAttribute !== null).map(y => y.parentAttribute));
    const obj = {};
    setKeys.forEach(key => {
      obj[key] = nodes.filter(y => y.parentAttribute === key && y.expandable === false).map(x => x.value);
    });

    return obj as PortfolioFilterRequest;
  };

  setFilterNodes(nodes: TreeItemFlatNode[]) {
    this.filterNodes = nodes;
    this.currentFilters = this.createFilterObject(this.removeNonFunctionalNodes(nodes));
    this.filterChipNodes = this.mapFilterChipGroupings(nodes);
    this.hasAppliedFilters = this.filterChipNodes.length > 0;
  }


  // this will insert filters immediately before the API requests for customerIds, lenIds, serviceAddressIds, and meterIds
  // Note: currently we have no reason to ever have more than 1 value of each type, although the backend technically supports it
  getHierarchyObject(customerId?: number, lenId?: string, serviceAddressId?: number, meterId?: number) {
    let pfResourceRequest = {} as PortfolioFilterResourceIdRequest;
    // If these values exist, add them to a filter object that will be spread into the current filters before submission
    if (customerId) {
      pfResourceRequest.customerIds = [customerId];
    } else {
      delete pfResourceRequest.customerIds;
    }
    if (lenId) {
      pfResourceRequest.lenIds = [lenId];
    } else {
      delete pfResourceRequest.lenIds;
    }
    if (serviceAddressId) {
      pfResourceRequest.serviceAddressIds = [serviceAddressId];
    } else {
      delete pfResourceRequest.serviceAddressIds;
    }
    if (meterId) {
      pfResourceRequest.meterIds = [meterId];
    } else {
      delete pfResourceRequest.meterIds;
    }
    return pfResourceRequest;
  }
}
