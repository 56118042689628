import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {ReportProduct} from '../../../_modules/zen-reports/_modules/zen-rate-check-report/_models/report-product.model';
import {DownloadService} from '../../_zen-legacy-common/zen-common-services/_services/download.service';
import {ZenTableColsModel} from '../../_components/zen-mat-table/zen-mat-table.component';
import {ZenStackRanking} from '../../../_modules/zen-stack-ranking/_services/zen-stack-ranking-helper.service';
import moment from 'moment';

@Component({
  selector: 'app-zen-report-products-dialog',
  templateUrl: './zen-report-products-dialog.component.html',
  styleUrls: ['./zen-report-products-dialog.component.scss']
})
export class ZenReportProductsDialogComponent {
  products = new Array<ReportProduct>();
  tabActiveIndex = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenReportProductsDialogComponent>,
              private downloadSvc: DownloadService) {
    this.products = this.data.data?.products;
  }

  submit(btn: ZenDialogActionButton) {
    btn.command(btn);
  }

  extractExcel(): void {
    let _columns: ZenTableColsModel[] = this.products[this.tabActiveIndex]?.tableConfig?.cols;
    let _data: ZenStackRanking[] = this.products[this.tabActiveIndex].tableModel.data;
    // columns configured to be exported.
    const exportColumns = _columns.map(col => col.field);
    // build the data to be exported for each row in the table
    const exportRows = _data.map(row => {
      const exportRow = {};
      _columns.forEach(col => {
        if (exportColumns.includes(col.field)) {
          exportRow[col.header] = col.formatter ? col.formatter(col, row) : row[col.field];
        }
      });
      return exportRow;
    });
    this.downloadSvc.downloadExcelFile(exportRows, 'Rates_Offered__' + moment().format('MM_DD_YYYY'));
  }
}
