import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ZenTreeNode} from '../zen-flyout/zen-flyout.component';

@Component({
  selector: 'zen-flyout-chips',
  templateUrl: './zen-flyout-chips.component.html',
  styleUrls: ['./zen-flyout-chips.component.scss']
})
export class ZenFlyoutChipsComponent implements OnInit {
  popperChildren: ZenTreeNode[]; // need to implement, TODO
  @Input() dataSource: ZenTreeNode[];
  @Output() removeCallbackFn = new EventEmitter<any>();
  @Input() displayInline: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  // Update list when opening popper to show correct echild nodes
  updatePopperList(node: ZenTreeNode) {
    this.popperChildren = node.children;
  }

  /**
   * Remove a node from the list of currently selected/active nodes
   * @param node The node to remove
   * @emits removeCallbackFn with list of nodes excluding the node given
   */
  remove(node: ZenTreeNode) {
    // The data source has all of the nodes right now
    let currentActiveNodes = this.dataSource;
    // Remove the node from the data source
    const toRemoveIdx = currentActiveNodes.findIndex(n => {
      return n.label === node.label;
    });
    if (toRemoveIdx > -1) {
      currentActiveNodes.splice(toRemoveIdx, 1);
    }
    this.removeCallbackFn.emit(currentActiveNodes);
  }

}
