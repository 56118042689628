import {Component, Input, OnInit} from '@angular/core';
import {ButtonTypes} from '../../_dialogs/zen-dialog/zen-dialog.component';
import {UntypedFormControl} from '@angular/forms';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {ZenBaseComponent} from '../zen-base/zen-base.component';
import {NgxPopperjsContentComponent} from 'ngx-popperjs';
import {ZenColors} from '../../_enums/zen-colors.enum';
import {ZenTableViewTypeEnum} from '../../_enums/zen-table-view-type.enum';

export interface ActionBtn {
  label: string;
  icon?: string;
  btnType?: ButtonTypes;
  mobileIconOnly?: boolean;
  command?: () => void;
  styleCls?: 'with-left-border'
}

export interface DropDownActionOption {
  label?: string;
  value: (number | string);
  icon?: ZenIconsEnum;
}

export interface PortfolioTabTopActionConfig {
  actionBtn?: ActionBtn;
  menuItems?: { label: string; command: () => void; beta?: boolean; legacy?: boolean; }[];
  actionBtn2?: ActionBtn;
  menuItems2?: {label: string; command: () => void}[];

  dropdownAction?: {
    label: string;
    formCtrl: UntypedFormControl;
    width?: string;
    iconOnly?: boolean;
    onChange: () => void;
    options: DropDownActionOption[];
  };
  btnToggleGroupAction?: {
    formCtrl: UntypedFormControl; // ZenTableViewTypeEnum
    onChange?: () => void;
    options: DropDownActionOption[];
  };
  legendPopperContent?: NgxPopperjsContentComponent;
  isDialog?: boolean;
  isDefaultState?: boolean;
}

export enum TabTopActionBarPosition {
  v1 = 'v1', v2 = 'v2', v3 = 'v3'
}

@Component({
  selector: 'app-zen-tab-top-action',
  templateUrl: './zen-tab-top-action.component.html',
  styleUrls: ['./zen-tab-top-action.component.scss']
})
export class ZenTabTopActionComponent extends ZenBaseComponent implements OnInit {
  @Input() config: PortfolioTabTopActionConfig;
  @Input() designPosition: TabTopActionBarPosition = TabTopActionBarPosition.v1;

  ButtonTypes = ButtonTypes;

  matSelectTrigger: DropDownActionOption;

  staticLegendColors = [ZenColors.DANGER_1, ZenColors.DANGER_2, ZenColors.INFO_1, ZenColors.SUCCESS_2, ZenColors.SUCCESS_3];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setMatSelectTrigger();
    if (this.config?.dropdownAction?.formCtrl?.value) {
      this.config.dropdownAction.formCtrl.valueChanges.subscribe(() => this.setMatSelectTrigger());
    }
  }

  /**  <mat-select-trigger> is a template which is used to customise the view of selected value.
   * Here we are using it to show Icon + Value. */
  setMatSelectTrigger() {
    if (this.config?.dropdownAction?.formCtrl?.value) {
      this.matSelectTrigger = this.config.dropdownAction.options.find(op => op.value === this.config.dropdownAction.formCtrl.value);
    }
  }

  openLegendPopper() {
    if (this.config.legendPopperContent) {
      this.config.legendPopperContent.hide();
      setTimeout(() => this.config.legendPopperContent.show(), 50);
    }
  }

  handleToggleBtnClick(val: ZenTableViewTypeEnum) {
    if (this.config?.btnToggleGroupAction?.formCtrl) {
      this.config.btnToggleGroupAction.formCtrl.setValue(
        (val === ZenTableViewTypeEnum.COLLAPSED) ? ZenTableViewTypeEnum.DETAILED: ZenTableViewTypeEnum.COLLAPSED);
      this.config.btnToggleGroupAction?.onChange();
    }
  }
}
