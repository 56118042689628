import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {catchError, map, take, tap} from 'rxjs/operators';
import {environment} from '../../../../../../../environments';
import {Customer} from '../../_models/customer';
import {CustomerOrganization, FeeMode, InitResponse, Organization, OrganizationSettings} from '../../_models/organization';
import StateUtility, {StateSupplier, StateSupplierList} from '../../_models/service-address-settings';
import {AuthenticationService} from './authentication.service';
import {CacheService} from './cache.service';
import {ErrorService} from './error.service';
import {GenerateTokenRes, SendRcReportReq} from '../tili-services/models/rate-check';
import {ActivatedRoute} from '@angular/router';
import {BrokerFeesV2} from '../tili-services/models/broker-fees';
import {ZenDialogMsgService} from '../../../_services/zen-dialog-msg.service';
import {CountryEnum, ZenLocaleModel} from '../../../_model/zen-locale.model';
import {TranslateService} from '@ngx-translate/core';
import {getParams} from '../../_utils/set-param-util';
import {CommodityType} from '../../_models/commodity';

const HIDE_ARR_KEY = 'hideArr';

@Injectable({
  providedIn: 'root'
})
export class OrganizationManagementService {
  baseUrl = environment.apiBaseUrl;
  TILI_ORG_ID = '00000002-1111-1111-1111-111111111111';
  ZENTILITY_ORG_ID = '00000001-1111-1111-1111-111111111111';
  organization: Organization; // this doesn't work as its not always set.  Its not a singleton for some reason

  organizationSubject: ReplaySubject<Organization> = new ReplaySubject(1);
  feeMode: FeeMode = FeeMode.HIDE_ALL;
  showFeeBreakdown = false;
  showFee = false;

  /**
   * Applicable to users who are able to see ARR. Persists in local storage but not on logout.
    */
  hideArr: boolean;
  onHideArrChangeEmit: Subject<boolean> = new Subject();

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private errorService: ErrorService,
              private translateSvc: TranslateService,
              private route: ActivatedRoute,
              private cacheService: CacheService,
              private zenDialogSvc: ZenDialogMsgService) {

    this.organizationSubject.subscribe(o => {
      if (o) {
        // console.log('setting org: ', o.id, ' | theme: ', o.themeId);

        // Normalize HTML entities
        o.settings.footerCustomerPages = this.normalizeHtmlEntities(o.settings.footerCustomerPages);
        o.settings.footerAdvisorPages = this.normalizeHtmlEntities(o.settings.footerAdvisorPages);
        o.settings.footerReports = this.normalizeHtmlEntities(o.settings.footerReports);

        this.authService.setUserApprovalStatus(AuthenticationService.ADVISOR_TOS_SIGNED, o.tosSigned);
        this.authService.setCustomerLabel();

        this._setOrganizationValue(o);

        this.calcFeeModePermissions(); // initial permissions based on org
      }
    });

    this.authService.currentUserSub.subscribe(() => {
      this.calcFeeModePermissions(); // calculate based on user as well
    });
  }

  /**
   * Sets permissions fee mode permissions based on user role and org settings
   */
  calcFeeModePermissions() {
    const o = this.organization;

    // FIXME: I don't want to touch this for fear of breaking something,but when user is logged in as a customer, despite them being a customer and should not be able to see fees, this fee mode is set from org settings which can set feeMode to true
    // set fee mode the org and check the settings for the fee mode
    this.feeMode = o.settings.feeMode;

    // allow these roles to override and see all: ROLE_ORG_ADMIN, ROLE_BROKER_FINANCE, ROLE_ADMIN*
    let adminRole = this.authService.getCurrentRoles().some(role => role.includes('ROLE_ADMIN') || role.includes('ROLE_ORG_ADMIN')
      || role.includes('ROLE_BROKER_FINANCE') || role.includes('ROLE_ADMIN_SUPER') || role.includes('ROLE_ADMIN_ACCOUNT_MGR')
      || role.includes('ROLE_ADMIN_PAYMENTS') || role.includes('ROLE_ADMIN_SUPPLIERS'));

    // organization admin override -- regardless of the org setting, the org admin can see everything
    if (adminRole) {
      this.feeMode = FeeMode.SHOW_FEE_AND_DETAILS;
    }
    this.showFeeBreakdown = (this.feeMode === FeeMode.SHOW_FEE_AND_DETAILS);
    this.showFee = !(this.feeMode === FeeMode.HIDE_ALL);

    // organization admin override
    if (adminRole) {
      // console.log('Org Admin... granting fee breakdown permissions');
      this.showFee = true;
      this.showFeeBreakdown = true;
      this.feeMode = FeeMode.SHOW_FEE_AND_DETAILS;
    }
    // If hideArr exists in local storage, use that value
    if (localStorage.getItem(HIDE_ARR_KEY) !== null) {
      this.hideArr = JSON.parse(localStorage.getItem(HIDE_ARR_KEY));
    }
  }

  public getStateUtilities(electricOnly: boolean): Observable<StateUtility[]> {
    let url = `${this.baseUrl}/v1/state_utilities`;
    // url = electricOnly ? url + '?elec_provider=true' : url;
    const _translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;

    let httpParams = getParams({
      elec_provider: electricOnly,
      country: _translations?.id
    });

    return this.http.get(url, {params: httpParams})
      .pipe(
        take(1),
        map(response => response as StateUtility[]),
        catchError(this.errorService.handleObsError)
      );
  }

  public getStateSuppliers(state?: string, commodityType?: CommodityType): Observable<StateSupplier[]> {
    const _translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;

    let httpParams = getParams({country: _translations?.id, state, commodityType});

    const url = `${this.baseUrl}/v1/state_suppliers`;
    return this.http.get<StateSupplierList>(url, {params: httpParams})
      .pipe(take(1), map(response => response.stateSuppliers as StateSupplier[]),
        catchError(this.errorService.handleObsError));
  }

  public getOrganizationById(id: string): Observable<Organization> {
    const url = `${this.baseUrl}/v2/organizations/${id}?full_details=true`; // get root org info as well
    return this.http.get(url)
      .pipe(
        take(1),
        tap(response => {
          this.setOrganization(response as Organization);
        }),
        catchError(this.errorService.handleObsError)
      );
  }

  // TODO: remove force refresh and refactor everyone to use the organizationSubject
  public getOrganization(forceRefresh = false): Observable<Organization> {
    return this.getOrganizationById(this.getOrganizationId());
  }

  public async getOrganizationPromise(forceRefresh = false): Promise<Organization> {
    return await this.getOrganization(forceRefresh).toPromise();
  }

  public getOrganizationUrl(organziation: Organization): string {
    const proto = window.location.protocol;
    const subDomain = window.location.toString().split('//')[1].split('.')[0];
    let devDomain = '';
    if (subDomain.startsWith('local-')) {
      devDomain = 'local-';
    }
    if (subDomain.startsWith('dev-')) {
      devDomain = 'dev-'
    }
    const port = (window.location.port !== '80' && window.location.port !== '443') ? ':' + window.location.port : '';

    // when domainExternal is null, the api does not include it in json
    // undefined != null
    if (typeof organziation.domainExternal !== 'undefined' && organziation.domainExternal !== null) {
      return organziation.domainExternal;
    } else {
      return `${proto}//${devDomain}${organziation.domainInternal}.tili.ai${port}`;
    }
  }

  public updateOrganization(o: Organization): Observable<Organization> {
    const url = `${this.baseUrl}/v2/organizations/${o.id}`;
    return this.http.put<Organization>(url, o);
  }

  public updateOrganizationSettings(orgId: string, settings: OrganizationSettings): Observable<OrganizationSettings> {
    const url = `${environment.apiBaseUrl}/v2/organizations/${orgId}/settings`;
    return this.http.put<OrganizationSettings>(url, settings);
  }

  private _setOrganizationValue(o: Organization) {
    this.cacheService.addValue('getOrganization()', null, o, '', moment().add(1, 'day').toDate());
    this.organization = o;
  }

  public setOrganization(organization: Organization) {
    this.organizationSubject.next(organization);
  }

  public observeOrganization(): Observable<Organization> {
    return this.organizationSubject.asObservable();
  }

  public getCustomers(organizationId: string): Observable<Customer[]> {
    const url = `${this.baseUrl}/v2/organizations/${organizationId}/customers`;
    return this.http.get<Customer[]>(url)
      .pipe(take(1));
  }

  public discover(): Observable<any> {
    const url = `${environment.apiBaseUrl}/v2/app/init`;
    return this.http.get(url).pipe(
      tap((response: InitResponse) => {
        const initOrg = response.organization as Organization;
        if (this.organization?.id !== initOrg.id) {
          // only set org from init if we don't have an org already
          // you don't want to reset the org if making changes on the settings page
          // because this init call has a filtered org that is missing fields.
          console.log('Setting the organization');
          this.setOrganization(initOrg);
        }

        // Setting locale
        if (response?.locale) {
          const langCode = response?.locale?.id as CountryEnum;
          this.translateSvc.setDefaultLang(langCode);
          this.translateSvc.use(langCode);
          this.translateSvc.setTranslation(langCode, response.locale);
        } else {
          this.zenDialogSvc.openToast(false, 'No locale configs found.');
        }

        this.authService.loginIfAuthInUrl();
      })
    );
  }

  public getOrganizationId(): string {
    return this.organization.id;
  }

  /**
   * if the organization id is currently tiLi but auth came back with another organization id,
   * we need to get the updated organization and set it
   */
  public needToGetNewOrgAfterLogin(): Observable<boolean> {
    return this.getOrganization().pipe(map(o => {
      this.setOrganization(o);
      return (o.id === this.TILI_ORG_ID && this.authService.getOrganizationId() !== this.TILI_ORG_ID);
    }), take(1));
  }

  public getCustomersSetting(): Observable<Customer[]> {
    const url = `${this.baseUrl}/v2/organizations/${this.getOrganizationId()}/customers`;
    return this.http.get<Customer[]>(url);
  }

  public generateTemporalCustToken(customerId: number, organizationId: string): Observable<GenerateTokenRes> {
    let url = `${this.baseUrl}/v2/organizations/${organizationId}/customers/${customerId}/generate-token`;
    return this.http.post<GenerateTokenRes>(url, {});
  }

  public sendRcReport(customerId: number, organizationId: string, sendRcData: SendRcReportReq): Observable<SendRcReportReq> {
    let url = `${this.baseUrl}/v2/organizations/${organizationId}/customers/${customerId}/send_rcr_email`;
    return this.http.post<SendRcReportReq>(url, sendRcData);
  }

  public getUserOrganizationByCustomerId(customerId: number): Observable<CustomerOrganization> {
    const url = `${this.baseUrl}/v1/customers/${customerId}/organization`;
    return this.http.get<CustomerOrganization>(url);
  }

  public getUserOrganizationById(organizationId: string): Observable<Organization> {
    const url = `${this.baseUrl}/v2/organizations/${organizationId}`;
    return this.http.get<Organization>(url);
  }

  public getBrokerFees(organizationId): Observable<BrokerFeesV2> {
    const url = `${this.baseUrl}/v2/organizations/${organizationId}/broker-fees`;
    return this.http.get<BrokerFeesV2>(url);
  }

  public getTermsAndCondsText(orgId: string): Observable<boolean> {
    const url = `${this.baseUrl}/v2/organization/${orgId}/tac/latest`;
    return this.http.get<boolean>(url);
  }

  public normalizeHtmlEntities(html) {
    if (html) {
      return html.replace(/[\u00A0-\u2666]/g, function (c) {
        return '&#' + c.charCodeAt(0) + ';';
      });
    }
  }

  /**
   * Sets hideArr and persists to local storage
   */
  setHideArr(checked: boolean) {
    this.hideArr = checked;
    localStorage.setItem(HIDE_ARR_KEY, checked.toString());
    this.zenDialogSvc.openToast(true, `Now ${checked ? 'hiding' : 'showing'} ARRs!`);
    this.onHideArrChangeEmit.next(checked);
  }

  /**
   * Returns true if ARR is allowed to be shown
   */
  showArr() {
    return !(this.hideArr || !this.showFee || this.authService.isCustomer());
  }
}
