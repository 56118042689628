import {Injectable} from '@angular/core';
import {ZenBreadcrumb, ZenBreadcrumbConfig} from '../_components/zen-breadcrumps/zen-breadcrumb.component';
import {PfMetersTableRowModel} from '../../_modules/portfolio/_model/portfolio-meters.model';
import {PfServiceAddressTableRowModel} from '../../_modules/portfolio/_model/portfolio-service-addresses.model';
import {PfLensTableRowModel} from '../../_modules/portfolio/_model/portfolio-lens.model';
import {PfCustomersTableRowModel} from '../../_modules/portfolio/_model/portfolio-customers.model';
import {PortfolioHelperService} from '../../_modules/portfolio/_services/_helpers/portfolio-helper.service';
import {ZenIconsEnum} from '../_enums/zen-icons.enum';

@Injectable({
  providedIn: 'root'
})
export class ZenBreadcrumbService {
  config: ZenBreadcrumbConfig;

  hide: boolean;

  constructor(private pfHelpSvc: PortfolioHelperService) {
    this.hide = false;
  }

  setConfig(config: ZenBreadcrumbConfig) {
    this.config = config;
  }

  clear() {
    this.config = null;
  }

  handleTitleClick() {
    if (this.config && this.config.command) {
      this.config?.command();
    }
  }

  onHide() {
    this.hide = true;
  }

  onShow() {
    this.hide = false;
  }

  handleBcClick(bc: ZenBreadcrumb) {
    if (!bc.currentPage && !bc.disableClick) {
      if (bc.customerId && bc.meterId) {
        this.pfHelpSvc.handleViewMeter({customerId: bc.customerId, id: bc.meterId} as PfMetersTableRowModel);
      } else if (bc.customerId && bc.serviceAddressId) {
        this.pfHelpSvc.handleViewServiceAddress({customerId: bc.customerId, id: bc.serviceAddressId} as PfServiceAddressTableRowModel);
      } else if (bc.customerId && bc.lenId) {
        this.pfHelpSvc.handleViewLen({customerId: bc.customerId, id: bc.lenId} as PfLensTableRowModel);
      } else if (bc.customerId) {
        this.pfHelpSvc.handleCustomerView({customerId: bc.customerId} as PfCustomersTableRowModel);
      } else if (bc.icon === ZenIconsEnum.PORTFOLIO) {
        this.pfHelpSvc.handlePortfolioView();
      } else if (bc.command) {
        bc.command();
      }
    } else if (bc.icon === ZenIconsEnum.BACK) {
      this.handleTitleClick();
    }
  }
}
