import {ContractStatus} from '../_enums/zen-contract.enum';
import {ContractFriendlyStatus} from '../_zen-legacy-common/_utils/contract-utils';
import {ZenColors} from '../_enums/zen-colors.enum';
import {ZenRcStatusClass} from '../../_modules/zen-rate-checks/_enum/zen-rc-v4.enum';


export function getContractStatusColor(status: ContractStatus) {
  switch (status) {
    case ContractStatus.processing:
      return ZenColors.INFO_1;
    case ContractStatus.current:
      return ZenColors.SUCCESS_2;
    case ContractStatus.future:
      return ZenColors.SUCCESS_3;
    case ContractStatus.expired:
      return ZenColors.DANGER_1;
    case ContractStatus.on_utility:
      return ZenColors.WARN_1;
    case ContractStatus.undefined:
      return ZenColors.textColorLight_2;
    case ContractStatus.contracted:
      return ZenColors.SUCCESS_2;
  }
}

export function getContractStatusCls(status: ContractFriendlyStatus): ZenRcStatusClass {
  switch (status) {
    case ContractFriendlyStatus.Current:
      return ZenRcStatusClass.CURRENT;
    case ContractFriendlyStatus.Aborted:
    case ContractFriendlyStatus.Declined:
    case ContractFriendlyStatus.Rejected:
    case ContractFriendlyStatus.Expired:
    case ContractFriendlyStatus.No_Longer_In_Process:
      return ZenRcStatusClass.EXPIRED;
    case ContractFriendlyStatus.Processing:
    case ContractFriendlyStatus.Contract_Generation:
    case ContractFriendlyStatus.Customer_Review:
      return ZenRcStatusClass.PROCESSING;
    case ContractFriendlyStatus.Contract_Review:
    case ContractFriendlyStatus.Supplier_Review:
      return ZenRcStatusClass.SUPPLIER_REVIEW;
    case ContractFriendlyStatus.Future:
      return ZenRcStatusClass.FUTURE;
    case ContractFriendlyStatus.Booked_Contract:
      return ZenRcStatusClass.CURRENT;
    case ContractFriendlyStatus.Timed_Out:
      return ZenRcStatusClass.TIMED_OUT;
    case ContractFriendlyStatus.On_Utility:
      return ZenRcStatusClass.ON_UTILITY;
    case ContractFriendlyStatus.Draft:
        return ZenRcStatusClass.DRAFT;
  }
}
