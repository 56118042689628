<app-zen-side-flyout *ngIf="showFilter" (onShow)="applyUrlFilterToFlyout()"
                     (onHide)="toggleFilterFlyout($event); onHide()" header="Filters"
                     [infoPopper]="filterPopper">

  <div class="filter-flyout">

    <div class="mat-tree-wrapper">
      <mat-tree class="ff-spacer" [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node class="filter-node child-filter-node" *matTreeNodeDef="let node" (click)="this.nodeToggle(node)" matTreeNodePadding
                     [matTreeNodePaddingIndent]="node.parentAttribute === 'utilityIds' ?  34 : (node.parentAttribute === parentAttribute.meterStatuses || node.parentAttribute === parentAttribute.timeHorizons) ? 0 :  node.level == 2 ?  15 : 0">
        <div *ngIf="node.isRadioButton" class="action-element-container">
          <mat-radio-button class="flyout-radio-button child-label" [name]="node.parentAttribute"
                            [disabled]="node.disabled"
                            [checked]="checklistSelection.isSelected(node)"
                            (change)="todoLeafItemRadioButtonToggle(node)"
                            [value]="node.value">{{node.item}}</mat-radio-button>
        </div>
        <div *ngIf="!node.isRadioButton" class="action-element-container">
          <mat-checkbox class="mat-checkbox-child" *ngIf="!node.isRadioButton"
                        [disabled]="node.disabled" [checked]="checklistSelection.isSelected(node)"
                        (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
        </div>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
                     [matTreeNodePaddingIndent]="(node.parentAttribute === parentAttribute.meterStatuses && node.level === 1) ? 0 : 15"
                     class="filter-node mat-node-parent">
        <div *ngIf="!node.isRadioButton" class="action-element-container">
          <button class="custom-ripple"
                  *ngIf="!(node.parentAttribute === parentAttribute.meterStatuses && node.level === 1)" mat-icon-button
                  (click)="this.nodeToggle(node)"
                  [attr.aria-label]="'Toggle ' + node.item">
            <mat-icon fontSet="material-icons-round">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-checkbox
            [ngClass]="node.isCheckableParent ? 'mat-checkbox-parent-checkable' : (node.parentAttribute === parentAttribute.meterStatuses && node.level === 1) ? 'meter-status-sub-header' : 'mat-checkbox-parent'"
            [disabled]="node.disabled" [checked]="descendantsAllSelected(node)"
            [indeterminate]="descendantsPartiallySelected(node)"
            (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
        </div>
        <div *ngIf="node.isRadioButton" class="action-element-container">
          <mat-label
            [class]="(!node.disabled ? 'child-label-radio' : 'child-label-radio-disabled')">{{node.item}}</mat-label>
          <mat-checkbox class="mat-checkbox-parent-hidden" [disabled]="node.disabled"
                        [checked]="descendantsAllSelected(node)"
                        [indeterminate]="descendantsPartiallySelected(node)"
                        (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
          <br>
        </div>
      </mat-tree-node>

    </mat-tree>
    </div>

    <div class="filter-flyout-footer">
      <div class="d-flex justify-content-between">
        <button mat-button (click)="clearFilters(true)">Clear Filters</button>
        <button mat-raised-button color="accent" class="float-md-right" (click)="submitFilters()">
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</app-zen-side-flyout>

<popper-content class="zen-dialog-popper" #filterPopper>
  <app-zen-popper title="Filters" [closeable]="true" [popperContent]="filterPopper">
    <p>
      Use the filters below to adjust the widgets and/or narrow down your search within the table.
    </p>
  </app-zen-popper>
</popper-content>
