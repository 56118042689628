<app-zen-dialog-wrapper [data]="data" [hideButton]="true">

  <div class="mb-4">
    <p class="text-center text-md">TX requires the new service type of
      <span class="hover-span" [popper]="switchPopper" popperTrigger="click" popperApplyClass="sm" [popperPositionFixed]="true">Switch</span>
      or
      <span class="hover-span" [popper]="moveInPopper" popperTrigger="click" popperApplyClass="sm" [popperPositionFixed]="true">Move-In</span></p>
  </div>
  <!-- TX Contract Options -->
  <div>
    <mat-form-field class="left-field ps-5 pe-5" [appearance]="'outline'">
      <mat-label>Switch Type</mat-label>
      <mat-select [(ngModel)]="selectedTxSwitchOption">
        <input type="text"
               matInput>
        <mat-option *ngFor="let config of TX_SWITCH_OPTIONS_LIST"
                    [value]="config">{{config}}</mat-option>
      </mat-select>
      <i class="material-symbols-rounded" matSuffix>expand_more</i>
    </mat-form-field>
    <div *ngIf="selectedTxSwitchOption === TX_SWITCH_OPTIONS.MOVE_IN && this.priorityMoveInSupplierExists"
         class="d-flex flex-row justify-content-between mt-2">
      <div class="ps-3">
        <mat-radio-group [(ngModel)]="txMoveIsNormal">
          <mat-radio-button class="ms-5" [value]="true">Normal</mat-radio-button>
          <mat-radio-button class="ms-5" [value]="false">Priority (Fee may apply)</mat-radio-button>
          <i class="material-symbols-rounded info-icon sm ms-2"
             [popper]="priorityMoveIn" popperTrigger="click" popperApplyClass="sm" [popperPositionFixed]="true">info</i>
        </mat-radio-group>
      </div>
    </div>
    <div *ngIf="selectedTxSwitchOption === TX_SWITCH_OPTIONS.MOVE_IN"
         class="radio-sec d-flex flex-row justify-content-between align-items-center mt-3 mb-2">
      <mat-form-field class="left-field ps-5 pe-5" appearance="outline">
        <mat-label>Move In</mat-label>
        <input matInput [matDatepicker]="datePicker" (click)="datePicker.open()" (dateChange)="dateValidator()"
               [(ngModel)]="txSwitchDate" [min]="minDate" [max]="maxDate">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngIf="selectedTxSwitchOption === TX_SWITCH_OPTIONS.SWITCH"
         class="d-flex flex-row justify-content-between mt-2">
      <div class="ps-3">
        <mat-radio-group [(ngModel)]="txSwitchIsOnCycle">
          <mat-radio-button class="ms-5" [value]="true">On Cycle</mat-radio-button>
          <i class="material-symbols-rounded info-icon sm ms-2"
             [popper]="onCycle" popperTrigger="click" popperApplyClass="sm" [popperPositionFixed]="true">info</i>
          <mat-radio-button class="ms-5" [value]="false">Off Cycle</mat-radio-button>
          <i class="material-symbols-rounded info-icon sm ms-2"
             [popper]="offCycle" popperTrigger="click" popperApplyClass="sm" [popperPositionFixed]="true">info</i>
        </mat-radio-group>
      </div>
    </div>
    <div *ngIf="selectedTxSwitchOption === TX_SWITCH_OPTIONS.SWITCH && txSwitchIsOnCycle === false">
      <mat-form-field class="left-field ps-5 pe-5 mt-3" appearance="outline">
        <mat-label>Switch</mat-label>
        <input matInput [matDatepicker]="datePicker" (click)="datePicker.open()" (dateChange)="dateValidator()"
               [(ngModel)]="txSwitchDate" [min]="minDate" [max]="maxDate">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <!-- buttons -->
  <div mat-dialog-actions class="center justify-content-center mt-3">
    <button mat-button (click)="onCancel()" class="me-5">Cancel</button>
    <button mat-raised-button color="accent" (click)="onSubmit()">Confirm</button>
  </div>

  <popper-content #switchPopper>
    <app-zen-popper title="Switch" [popperContent]="switchPopper">
      <p class="medium">
        A 'Switch' refers to the action of changing your current electricity provider to a different one without moving
        locations.
      </p>
    </app-zen-popper>
  </popper-content>
  <popper-content #moveInPopper>
    <app-zen-popper title="Move-In" [popperContent]="moveInPopper">
      <p class="medium">
        A 'Move-in' is when you are relocating and need to establish electric service at that location.
        This might involve selecting a new provider or transferring your existing service to the new address.
      </p>
    </app-zen-popper>
  </popper-content>
  <popper-content #priorityMoveIn>
    <app-zen-popper title="Priority Move-in" [popperContent]="priorityMoveIn">
      <p class="medium">
        A "Priority Move-in" refers to a request to start electricity service on a date sooner than the standard
        timeframe, typically within the next day or two.
        This expedited service is especially helpful in situations when electricity needs to be turned on quickly.
      </p>
    </app-zen-popper>
  </popper-content>
  <popper-content #offCycle>
    <app-zen-popper title="Off-Cycle" [popperContent]="offCycle">
      <p class="medium">
        An 'Off-cycle' switch or change pertains to making alterations to your electricity plan outside of your current
        contract's regular billing cycle.
        Making changes off-cycle might come with different implications compared to on-cycle changes.
        As always, review your contract or consult with your provider to understand any associated costs or conditions.
      </p>
    </app-zen-popper>
  </popper-content>
  <popper-content #onCycle>
    <app-zen-popper title="On Cycle" [popperContent]="onCycle">
      <p class="medium">
        'On-cycle' refers to making a switch or change in your electricity plan during your current contract's regular
        billing cycle.
      </p>
    </app-zen-popper>
  </popper-content>
</app-zen-dialog-wrapper>
