export enum ZenNumberFormat {
  NO_DECIMAL = '1.0-0',
  USAGE = '1.0-0',
  USAGE_MWH = '1.0-1',
  ELECTRIC_FEE_IN_MILS = '1.0-3',
  ELECTRIC_FEE_IN_DOLLARS = '1.2-5',
  GAS_FEE = '1.2-4',
  COST = '1.2-2',
  PERCENTAGE = '1.0-2'
}
