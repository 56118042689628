import { EntityState, EntityStore, Store } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ReportType } from '../../modules/zentility/_modules/zen-reports/_modules/zen-rate-check-report/_models/report-type';
import {Customer} from '../../modules/zentility/_shared/_zen-legacy-common/_models/customer';

export interface CustomerState extends EntityState<Customer, number> {
}

export interface CurrentReportState {
  data: any;
  reportTypeId: ReportType;
  switched: boolean;
}

@Injectable({ providedIn: 'root' })
export class OrganizationStore {
  customers = new EntityStore<CustomerState>({}, { name: 'customers', idKey: 'customerId' });
  currentReport = new Store<CurrentReportState>({}, { name: 'currentReport' });
}
