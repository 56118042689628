import {ZenHierarchicalGrouping} from '../_enums/zen-hierarchical-grouping.enum';
import {
  DataFlowChildrenDataTableModified,
  DataFlowHierarchyCategoryDetailsTableModifiedData
} from '../_model/data-flow-hierarchy-v4.model';
import {ZenExpansionTableData} from '../_components/zen-mat-table-with-expansion/zen-mat-table-with-expansion.component';

/**
 * Used to reset values without affecting checkbox or bill upload.
 * @param rowData
 * @param groupBy
 * @param existingData
 * @param updatedModifiedData
 */
export function updateModifiedDataWithExistingData(
  rowData: DataFlowChildrenDataTableModified,
  groupBy: ZenHierarchicalGrouping,
  existingData: ZenExpansionTableData<DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData>[],
  updatedModifiedData: ZenExpansionTableData<DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData>[]
): {rowData, existingData, updatedModifiedData} {

  const _dataIndex = existingData
    .findIndex(gtd => gtd?.data?.some(cd => cd?.meterId === rowData?.meterId));

  if (_dataIndex !== -1) {
    // If hasSubCategories -> true
    // We need to update data at subCategories level.
    if (groupBy === ZenHierarchicalGrouping.CONTRACT_STATUS) {
      const _scRowIndex = existingData[_dataIndex]
        ?.subCategories?.findIndex(d => d?.data?.some(cd => cd?.meterId === rowData?.meterId));

      if (_scRowIndex !== -1) {
        existingData[_dataIndex].subCategories[_scRowIndex].categoryDetails
          = updatedModifiedData?.[0]?.categoryDetails;
        existingData[_dataIndex].subCategories[_scRowIndex].data
          =  updatedModifiedData?.[0]?.data;
      }
    }

    existingData[_dataIndex].categoryDetails = {
      ...updatedModifiedData?.[0]?.categoryDetails,
      selectedRows: getExistingSelection(existingData[_dataIndex]?.categoryDetails, updatedModifiedData)
    };
    existingData[_dataIndex].data = updatedModifiedData?.[0]?.data;

    rowData = updatedModifiedData?.[0]?.data?.[0];

    return {rowData, existingData, updatedModifiedData};
  }
}

function getExistingSelection(
  tdCat: DataFlowHierarchyCategoryDetailsTableModifiedData,
  updatedModifiedData: ZenExpansionTableData<DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData>[],
) {
  const _existingSelection = tdCat?.selectedRows;

  // Replace existing selection with updated meter values
  if (_existingSelection?.hasValue()) {
    tdCat?.selectedRows?.selected.forEach(currentSelected => {
      const _updated = updatedModifiedData?.[0].data?.find(m => m.meterId === currentSelected.meterId);
      if (_updated) {
        tdCat?.selectedRows?.deselect(currentSelected);
        tdCat?.selectedRows?.select(_updated);
      }
    });
  }

  return tdCat?.selectedRows;
}
