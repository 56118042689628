import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../../environments';
import {ContractSignature} from '../../_models/contract';
import {AuthenticationService} from './authentication.service';
import {HttpClient} from '@angular/common/http';
import {catchError, take} from 'rxjs/operators';
import {ErrorService} from './error.service';
import {ContractTimeline} from '../../../_model/custom/contract-timeline.component';

@Injectable()
export class ContractService {

  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private errorService: ErrorService) {
  }

  public getContractFileUrlCustomerId(customerId: number, energyPlanId: number): string {
    const token = this.authService.getCurrentAuthToken();
    return `${this.baseUrl}/v1/app/customers/${customerId}/contracts/${energyPlanId}/view_file?x-auth-token=${token}`;
  }
  /**
   * @deprecated use signContractDocument()
   * @param energyPlanId
   * @param contractSignature
   */
  public signContract(energyPlanId: number, contractSignature: ContractSignature): Observable<any> {
    const customerId = this.authService.getCurrentCustomerId();
    const url = `${this.baseUrl}/v1/app/customers/${customerId}/contracts/${energyPlanId}/sign`;
    return this.http
      .post(url, contractSignature)
      .pipe(catchError(this.errorService.handleObsError));
  }

  /**
   *
   * @param energyPlanId
   * @param documentId
   * @param contractSignature
   */
  public signContractDocumentCustomerId(customerId: number, energyPlanId: number, documentId: number,  contractSignature: ContractSignature): Promise<any> {
    const url = `${this.baseUrl}/v1/app/customers/${customerId}/contracts/${energyPlanId}/documents/${documentId}/sign`;
    return this.http
      .post(url, contractSignature)
      .pipe(catchError(this.errorService.handleObsError))
      .toPromise();
  }

  public getSupplierImagePath(supplierId?: string, supplierName?: string) {
    const path = '//storage.googleapis.com/images-zentility-com/web_images/supplier-logos/';
    const supplierImgDetails = [
    {id: '7c9c050c-e696-482e-8cba-ea589e216a58', supplierName: 'APGE', imgPath: path + 'apge.png'},
    {id: 'afad0f99-e739-46ec-a18b-4e456ac5efcf', supplierName: 'SFE', imgPath: path + 'sfe-logo.png'},
    {id: 'dd28d70e-e6b8-4979-84e3-94fe280f4340', supplierName: 'CIRRO', imgPath: path + 'cirro.png'},
    {id: 'aa398b9b-fcf8-463c-b2f6-e12706b46362', supplierName: 'CONSTELLATION', imgPath: path + 'constellation.png'},
    {id: '2fb4c52d-133e-44d8-9504-d816f11eb082', supplierName: 'DIRECT_ENERGY', imgPath: path + 'direct-energy.png'},
    {id: '53aa93b9-fbd0-430e-8dbf-22680f2f7a36', supplierName: 'DYNEGY', imgPath: path + 'dynegy-energy.png'},
    {id: '03449ecc-fcbd-41e1-87a0-c46f397c186c', supplierName: 'HOMEFIELD_ENERGY', imgPath: path + 'homefield-energy.png'},
    {id: '53890292-10f0-45f9-8a81-b1505fca65c3', supplierName: 'TXU', imgPath: path + 'txu-energy.png'},
    {id: '6b3cd5df-2ad0-4ce8-8f32-1fbab3371dc4', supplierName: 'ENGIE', imgPath: path + 'engie.png'},
    {id: '8face8ae-f860-4f70-aae6-4fb67513ae13', supplierName: 'FIRST_POINT_POWER', imgPath: path + 'first-point-power.png'},
    {id: '54e616dc-a04b-4c0b-b892-1fa312ff99bb', supplierName: 'FREE_POINT', imgPath: path + 'free-point.png'},
    {id: '71c5b7a7-d813-11e5-86b8-f23c9133d457', supplierName: 'GREEN_MOUNTAIN', imgPath: path + 'green-mountain.png'},
    {id: '71ce2681-d813-11e5-86b8-f23c9133d457', supplierName: 'HUDSON', imgPath: path + 'hudson-energy.png'},
    {id: '5fe9fef6-5944-4714-9f1c-67dc10d5e996', supplierName: 'LIBERTY_POWER', imgPath: path + 'liberty-power.png'},
    {id: '40855263-34c0-4814-9bcd-998667939d60', supplierName: 'NATIONAL_G_AND_E', imgPath: path + 'national-g-and-e.png'},
    {id: 'df3bfadd-6d5e-45e6-8760-1d6636b6ce75', supplierName: 'NRG', imgPath: path + 'nrg-final.png'},
    {id: '55dbc834-4329-4fe0-b03d-6630e857140b', supplierName: 'ENERGO', imgPath: path + 'energo.png'},
    {id: '7256a3c6-d813-11e5-86b8-f23c9133d457', supplierName: 'STARION', imgPath: path + 'starion.png'},
    {id: '9a998138-6154-42ba-a178-80715caf4a35', supplierName: 'NEW_WAVE_ENERGY', imgPath: path + 'new-wave-energy.png'},
    {id: '426017b2-067a-4e67-97e7-ca46afbddedc', supplierName: 'VISTRA', imgPath: path + 'vistra.png'},
    {id: '5d6cf6c5-ce25-443a-99db-267368ccf0c1', supplierName: 'PUBLIC_POWER', imgPath: path + 'public-power-logo.png'},
    {id: 'a8bcc7cf-eb14-4728-8d21-28b87abe478e', supplierName: 'TRI_EAGLE_ENERGY', imgPath: path + 'trieagle-energy-logo.png'},
    {id: '13ec1d20-ce4e-4e05-9c27-6fe0517ee7fd', supplierName: 'CONSOLIDATED_COMM_ENERGY_REWARDS', imgPath: path + 'consolidated-communications-logo.png'},
    {id: '0bc96c7c-2d82-4e55-a2d6-0e4f9f509ea3', supplierName: 'US_GAS_AND_ELECTRIC', imgPath: path + 'usge-logo.png'}
    ];
    const supplier = supplierImgDetails.find(s => s.id === supplierId || s.supplierName === supplierName);
    return supplier && supplier.imgPath ? supplier.imgPath : null;
  }

  public getCustomerContractTimelineWithCustomerId(customerId: number, contractId: number): Observable<ContractTimeline[]> {
    const url = `${this.baseUrl}/v1/customers/${customerId}/contracts/${contractId}/contract-timeline`;
    return this.http.get<ContractTimeline[]>(url).pipe(take(1));
  }

}
