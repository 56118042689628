import {SelectionModel} from '@angular/cdk/collections';
import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {ZenLoaderTypes} from '../zen-skeleton-loader/zen-skeleton-loader.component';
import {EnergyPlanTimeline} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import {ZenBaseComponent} from '../zen-base/zen-base.component';
import {PortfolioTabTopActionConfig} from '../zen-tab-top-action/zen-tab-top-action.component';
import {NgxPopperjsContentComponent, NgxPopperjsPlacements} from 'ngx-popperjs';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, switchMap, takeUntil} from 'rxjs/operators';
import {Observable, Subject, Subscription} from 'rxjs';
import {ZenMatTableHelperService} from '../../_services/helpers/zen-mat-table-helper.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {EntityState, EntityStore, PaginationResponse, PaginatorPlugin} from '@datorama/akita';
import {PageableModel} from '../../../_modules/portfolio/_model/pageable.model';
import {DownloadService} from '../../_zen-legacy-common/zen-common-services/_services/download.service';
import {ActivatedRoute} from '@angular/router';
import {formatExtractFileName} from '../../_utils/zen-filename.util';
import {ZenTableViewTypeEnum} from '../../_enums/zen-table-view-type.enum';
import {ZenRateCheckAction} from '../../_model/rate-check-v4.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {MatCheckboxChange} from '@angular/material/checkbox';

/**
 * @title Table with selection
 */

export interface ZenTableColsModel {
  field: string;
  fieldPopperArrObj?: string;
  type: 'standard' | 'text-array' | 'menu' | 'procurement-snapshot' | 'checkbox' | 'notes' | 'progress-status' | 'col-xs' | 'col-sm' | 'col-md' | 'col-lg' | 'button' | 'standard-padding';
  hideMenu?: (col, rowData) => boolean;
  hidden?: boolean;
  /* Stocky configs */
  sticky?: boolean;
  stickyEnd?: boolean;
  whiteSpace?: boolean; // To show only icons w/t dash( - )
  /* Tooltip configs */
  enableTooltip?: boolean;
  enableUnderLinedTooltip?: boolean;
  popperContent?: NgxPopperjsContentComponent;
  subTextPopperContent?: NgxPopperjsContentComponent;
  handlePopperHover?: (col, element) => void;
  popperApplyClass?: string; // 'xs' | 'sm' | 'md' | 'min-xs' | 'min-sm' | 'min-md' | 'bg-transparent'
  popperTrigger?: 'click' | 'hover';
  popperPositionFixed?: boolean;
  popperPlacement?: NgxPopperjsPlacements;
  popperHideOnMouseLeave?: boolean;
  popperOnShown?: Function;
  headerTooltipText?: string;

  /* Hyperlink */
  hyperlink?: boolean;
  handleHyperLinkClick?: (col, rowData) => void; // to handle main-text click event
  handleHyperLinkRightClick?: (col, rowData) => void; // to handle main-text right click event
  editable?: boolean;
  lockedCol?: string; // Used to add lock icon after the main-val, this will disable the col.editable|hide the edit icon.
  subTextCol?: string;
  subTextStyleClass?: string;
  subTextLinkEnabledCol?: string;
  handleSubTextClick?: (col, rowData) => void; // to handle sub-text click event
  handleSubTextRightClick?: (col: ZenTableColsModel, rowData, rightClick?: boolean) => void; // to handle sub-text right click event
  subTextArrayCol?: string; // -> the value should be string[]
  subTextWhiteSpace?: boolean; // To remove "-" from the sub text, if sub text value is null.
  subTextViewAllOption?: boolean; // Used to show only +more, to show popper with string[]

  enableSubTextTooltip?: boolean;

  // Add new icon
  allowAddNew?: boolean; // this show add icon if there is no value
  handleAddNewClick?: (col, rowData) => void; // this show add icon if there is no value
  // Show link icon for existing data
  allowLinkIcon?: boolean; // show link icon if entity already exists
  allowLinkField?: string; // this will be meterId, lenId, etc, and if its not null or empty, then we'll show the link after we activate with the boolean above
  textClassCol?: string; // specific to the row value
  textMaxWidth?: string;
  colStyleCls?: 'text-column' | 'long'; // common for all column rows.

  header?: string;
  headerColIcon?: string; // header icon

  align?: 'center' | 'right' | '';

  imageCol?: string;
  statusCol?: string;
  progressColorCol?: string; // ZenColors

  /* To add icons with row values */
  iconCol?: string;
  iconClsCol?: string;
  iconPosition?: 'order-first' | 'order-last';

  /* Suffix icon (used in the RC table product config col to show renewable green leaf) */
  iconSuffixHtmlCol?: string;

  handleIconClick?: (col, rowData) => void; // to handle icon click event -> Contract download
  /* Progress bar configs =>  type === 'progress-bar' */
  procurement?: ZenTableProcurement;

  /* td border left color used in stack ranking page  */
  borderLeftColor?: string; // uses -> ZenRcStatusClass;
  borderLeftWidth?: string; // uses -> ZenRcStatusClass;

  /* To format values */
  formatter?: (col, rowData) => string;

  /* Column header action */
  colHeaderAction?: {
    icon: string;
    command: (col) => void;
  }

  /* Sorting */
  hideSort?: boolean; // If true, column will not be sortable
  sortColumnName?: string; // When clicking on this sortable column, this is the field name sent back to API

  /* Download */
  /**
   * Formats the data in a row/column for excel export. If null, will use `formatter` function if exists.
   */
  downloadFormatter?: (col, rowData) => string;

  // To support ngx-tour-ui anchor id
  tourAnchorId?: string;
}

export interface ZenTableProcurement {
  timelines: EnergyPlanTimeline[];
}

export interface ZenTableMenuOption {
  children?: ZenTableMenuOption[];
  divider?: boolean;
  label: string;
  beta?: boolean;
  type?: 'button' | 'toggle';
  value?: string;
  icon?: string;
  iconPosition?: 'right' | 'left';
  command?: Function;
  toggleDefaultVal?: boolean;
  field?: string;

  // Used in the portfolio details page
  hideForDesktop?: boolean;
  hide?: boolean;
  disabled?: boolean;

  // Used in the RCR
  rateCheckAction?: ZenRateCheckAction;
}

export interface ZenTableConfig {
  primaryKey?: string; // Ids -> energyPlanId, customerId, meterId
  cols?: ZenTableColsModel[];
  styles?: Object;
  styleType?: 'v1' | 'v2'; // default v1
  searchPlaceholder?: string;
  // To lock a table row w.r.t, conditions.
  rowLockingCondition?: (rowData) => boolean;
  onRowClick?: (rowData) => void;
  onRowSelected?: (rowData) => boolean;
  onRowError?: (rowData) => boolean;
  /* totalLength - is used to disable to next paginator button after loading total records.
  * Also, it is used to support server side rendering. */
  totalLength?: number;
  pageSize?: number;
  rowMenuOptions?: ZenTableMenuOption[];
  tableMenuOptions?: ZenTableMenuOption[];
  defaultState?: {
    title?: string;
    message: string;
    hideJenZen?: boolean;
    actions?: PortfolioTabTopActionConfig;
  };
  // tr row tooltip
  rowTooltip?: {
    enable: boolean;
    popperApplyClass?: 'xs' | 'sm' | 'md';
    popperPlacement?: NgxPopperjsPlacements;
  },
  // Different hide configs
  hideMultiselect?: boolean; // Hide multi-select column
  selectSearchMainAnchorTourId?: string; // Unique id, used to support ngx-tour-if
  hideSearch?: boolean; // Hide table search
  hideTopSelectedActions?: boolean; // Hide table selected actions
  hidePagination?: boolean; // Hide pagination section
  hideBorder?: boolean; // To remove border from zen-mat-table-wrapper
  hideMinHeight?: boolean; // To remove set-min-ht cls from the table tag
  // Footer row - where the object name should be col.field
  footerRow?: Object; // example  footerRow: {propAccounts: '5/5', annualUsage: 213131}
  // Download
  download?: {
    enable: boolean;
    getFileNameFn: () => string;
    externalDownload?: () => void;
  };
  // Double Click
  onDoubleClick?: (rowData) => void;
  // Search query from params
  allowSearchQueryParams?: boolean;
  // allow rows to grag and drop
  isDraggable?: boolean;
  isDraggableTableId?: string;

  // When drop made from sender table this event will be triggered from receiver table to know what data is got received.
  onDropReceived?: Function;
  onDragDropped?: Function;

  // Handle subText array click this is related to the subTextArrayCol
  handleSubTextArrayClick?: (data: ZenMatTableSubTextArrayDetails) => void;
}

export interface ZenTableServerSideConfig<T> {
  paginator: PaginatorPlugin<EntityState<T>>;
  store: EntityStore<EntityState<T>>;
  getDataFn: (page, size, sortBy, sortDir) => Observable<PageableModel<T>>;
  onDataLoaded?: (data: T[], paginationResponse: PaginationResponse<any>) => void;
}

export interface ZenTooltipHoverModel {
  col: ZenTableColsModel;
  element: any;
}

export enum ZenTableSelectOptionDataType {
  NUMBER = 'number', STRING = 'string', DATE = 'date'
}

export interface ZenMatTableSelectOption {
  objectName: string;
  objectLabel: string;
  dataType?: ZenTableSelectOptionDataType;
}

export interface ZenMatTableSelectSearchConfig {
  selectOptions: ZenMatTableSelectOption[];
  searchTypeCtrl: UntypedFormControl;
  inputCtrl: UntypedFormControl;
  onChange?: () => void;
}

export interface ZenMatTableSubTextArrayDetails {
  selected: string;
  subTextArrayDetails: SubTextDet;
}

interface SubTextDet {
  subTextArr: string[];
  col: ZenTableColsModel;
  rowData;
  rightClick: boolean;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-zen-mat-table',
  templateUrl: './zen-mat-table.component.html',
  styleUrls: ['./zen-mat-table.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  /** The animation with *ngIf [@inOutAnimation] changes have been made to fix DEV-11323 */
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.2s ease-out',
              style({ height: 200, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 200, opacity: 1 }),
            animate('0.2s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ZenMatTableComponent extends ZenBaseComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() data;
  /**
   * Include if you want to use server side rendering.
   */
  @Input() serverSideConfig?: ZenTableServerSideConfig<Object>;
  @Input() tableConfig: ZenTableConfig;
  @Input() loading: boolean;
  @Input() selection = new SelectionModel<Object>(true, []);

  @Input() tooltipContent;
  @Input() subTextTooltipContent;
  previousPageSize;
  // TODO: Currently we are emitting changes only for forward page changes. Need to fix this in future.
  @Input() emitOnlyForwardPageChange = true;

  // Multi-select
  // If multiselectType === 'radio' -> Setting primaryKey in the tableConfig is required.
  @Input() multiselectType: 'checkbox' | 'radio' | 'swap' = 'checkbox';

  // The below view type is used to show/hide sub text
  @Input() viewType: ZenTableViewTypeEnum;
  @Input() styleCls: string;

  @Input() searchType: 'input-search' | 'select-search' = 'input-search';
  @Input() selectSearchConfig: ZenMatTableSelectSearchConfig;
  @Input() pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  @Output() onTooltipHover: EventEmitter<ZenTooltipHoverModel> = new EventEmitter();
  @Output() onSubTextTooltipHover: EventEmitter<ZenTooltipHoverModel> = new EventEmitter();

  @Output() handleUpdatedPagination: EventEmitter<PageEvent> = new EventEmitter();
  @Output() onMenuClick: EventEmitter<ZenTableMenuOption> = new EventEmitter(); // send row-element
  // tr row tooltip
  @Output() onRowTooltipHover: EventEmitter<ZenTooltipHoverModel> = new EventEmitter();

  @Output() handleCheckboxUpdate: EventEmitter<{ element: Object; col: ZenTableColsModel }> = new EventEmitter();
  @Output() onFilter: EventEmitter<Object[]> = new EventEmitter();

  ZenTableSelectOptionDataType = ZenTableSelectOptionDataType;
  TableViewTypes = ZenTableViewTypeEnum;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<Object>(); // Setting type any here. Because, as a shared component it has to deal with different data's.


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  THUMBNAIL = '/assets/img/img-thumbnail.svg';
  currentPageIndex = 0;
  ZenLoaderTypes = ZenLoaderTypes;

  searchValue: string;
  subTextArrayDetails: SubTextDet; // Notes col

  // @Deprecated - Radio btn: TODO: Remove this feature from the table if not required.
  @Input() selectedRadioVal;
  @Output() onRadioChange: EventEmitter<Object> = new EventEmitter(); // send row-element

  @Output() onSwapBtnClick: EventEmitter<Object> = new EventEmitter(); // send row-element

  // Header tooltip text
  headerTooltipText?: string;

  searchTextChanged = new Subject<string>();

  paginator$: Subscription;

  // Expansion
  expandedElement;
  @Input() isExpandable: boolean;
  @Input() hideCols: string[] = [];

  PopperPlacement = NgxPopperjsPlacements;
  @Output() onSortChange: EventEmitter<Sort> = new EventEmitter(); // send row-element
  hoveredCol: ZenTableColsModel;
  hoveredElement;

  // Changed the type to array, to detect array changes. Because, we using distinctUntilChanged()
  changesDetected = new Subject<Object[]>();
  private readonly destroy$ = new Subject<void>();

  @Input() set externalSearchQuery(query: string) {
    if (typeof query === 'string') {
      this.applyFilter(query);
    }
  }

  constructor(public zenMatTableHelperSvc: ZenMatTableHelperService,
              private downloadService: DownloadService,
              private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.previousPageSize = this.tableConfig?.pageSize ?? 10;
    this.initDisplayCols();

    // Introducing debounceTime to avoid making multiple api call on search.
    this.searchTextChanged.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => this.handleSearchInputChange());

    if (this.serverSideConfig) {
      // Page change handler
      this.paginator$ = this.serverSideConfig.paginator.pageChanges.pipe(
        distinctUntilChanged(), debounceTime(100), takeUntil(this.destroy$),
        switchMap((page) => {
          const pageIdx = page - 1; // page is 1-indexed
          const reqFn = this.serverSideConfig.getDataFn(pageIdx, this.previousPageSize,
            this.dataSource.sort?.active,
            this.dataSource.sort?.direction
          ).pipe(map(pageableModel => this.toPaginationResponse(pageableModel)));
          return this.serverSideConfig.paginator.getPage(() => reqFn);
        })
      ).pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((paginationResponse) => {
        this.dataSource.data = paginationResponse.data;
        // Change paginator to reflect
        this.tableConfig.totalLength = paginationResponse.total;
        if (this.serverSideConfig.onDataLoaded) {
          this.serverSideConfig.onDataLoaded(paginationResponse.data, paginationResponse);
        }
      });

      // Loading handler
      this.serverSideConfig.paginator.isLoading$.subscribe((isLoading) => {
        this.loading = isLoading;
      });
      // Sort handler - disable client side sorting
      this.dataSource.sortingDataAccessor = (item, property) => {
        return null;
      };
    }

    // Checking for search query exit in the URL
    this.route.queryParams.subscribe(query => {
      if (this.tableConfig.allowSearchQueryParams) {
        if (query.searchQueryText) {
          this.searchValue = query?.searchQueryText;
          setTimeout(() => {
            this.applyFilter();
          });
        }
      } else {
        this.searchValue = null;
      }
    });

    // This Subject is used to detect actual data changes & to fix - DEV-10930.
    this.changesDetected.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        this.dataSource.data = this.data;
        this.loading = false;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initDisplayCols();
    if (!this.serverSideConfig) {
      if (changes?.data?.currentValue) {
        if (changes?.data?.currentValue?.length) {
          this.data = changes.data.currentValue;
        }
        setTimeout(() => this.changesDetected.next(this.data), 10);
      }
    }
  }

  ngAfterViewInit() {
    // Wrapping in a set timeOut, to fix change detection errors
    // You aren't supposed to modify @ViewChild objects in the ngAfterViewInit() hook.
    setTimeout(() => {
      // Remove Previous Page and Next Page floating labels
      if (this.paginator && this.paginator._intl) {
        this.paginator._intl.previousPageLabel = '';
        this.paginator._intl.nextPageLabel = '';
      }

      if (!this.serverSideConfig) {
        this.dataSource.data = this.data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.paginator.length = this.tableConfig.totalLength;
        this.paginator.pageIndex = this.currentPageIndex;
        /* Goto first page on sort changes. */
        this.dataSource.sort?.sortChange.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((val) => {
          this.onSortChange.emit(val);
          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        });
      } else {
        this.dataSource.sort = this.sort;
        // Sort handler
        this.dataSource.sort?.sortChange.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((val) => {
          this.onSortChange.emit(val);
          this.resetToFirstPage();
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.serverSideConfig) {
      this.paginator$.unsubscribe();
      // TODO: we should use store key hierarchyEnum + customerId
      this.clearStore(true);
    }

    this.destroy$.next(); // Trigger the observable
    this.destroy$.complete(); // Complete the observable. So, further fires triggers wont happen.
  }

  initDisplayCols() {
    this.displayedColumns = this.tableConfig?.cols?.filter(c => !c.hidden && !this.hideCols.includes(c.field)).map(c => c.field);

    if (!this.tableConfig?.hideMultiselect) {
      this.displayedColumns.unshift('select'); // To enable select
    }

    if (this.isExpandable) {
      this.displayedColumns.unshift('expansion');
    }
  }

  clearStore(resetPage: boolean) {
    let obj = {
      clearCache: true,
      ...(resetPage ? {currentPage: 1} : {})
    };
    this.serverSideConfig.paginator.destroy(obj)
    this.serverSideConfig.store.reset();
  }

  resetToFirstPage() {
    // Flush the cache
    this.clearStore(false);
    // Reset the mat-paginator so that it can show (X-Y of Z) accurately. Will trigger handlePage again, which will
    // call the below Akita paginator setPage, which will trigger pageChanges.
    this.paginator.firstPage();

    if (this.serverSideConfig) {
      // This will trigger serverSideConfig.paginator.pageChanges event which is used
      // to trigger this.serverSideConfig.getDataFn()
      this.serverSideConfig.paginator.setPage(0);
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection?.selected?.length || 0;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(check: MatCheckboxChange) {
    if (check?.checked) {
      this.selection.select(...this.dataSource.filteredData);
    } else {
      this.selection.clear();
    }
  }

  applyFilter(externalQuery?: string) {
    this.dataSource.filter = this.searchValue?.trim().toLowerCase() || externalQuery?.trim().toLowerCase();
    this.onFilter.emit(this.dataSource.filteredData);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  handlePage(evt: PageEvent) {
    const {length, pageIndex, pageSize, previousPageIndex} = evt;
    this.currentPageIndex = pageIndex;
    // Server side
    if (this.serverSideConfig) {
      /* Forcing to move to firstPage on page size change. */
      if (pageSize !== this.previousPageSize) {
        // Set previous page size
        this.previousPageSize = pageSize;
        this.resetToFirstPage();
      } else {
        this.serverSideConfig.paginator.setPage(pageIndex + 1); // page is 1 indexed
      }
    } else { // Client side
      // Client-side mat-paginator will handle pagination assuming all the data is in the datasource.
      // If page size changes, bring it to first page
      if (pageSize !== this.previousPageSize) {
        this.previousPageSize = pageSize;
        this.paginator.firstPage();
      }
    }
  }

  hoveredTooltipText: string;

  handleMouseOver(col: ZenTableColsModel, element) {
    if (col.enableUnderLinedTooltip || col.popperContent) {
      this.onTooltipHover.emit({col, element});
      if (col.handlePopperHover) {
        col.handlePopperHover(col, element);
      }
    }
  }

  // text-overflow ellipsis detection - if ellipsis present show the text with tooltip.
  onHoverCheckEllipsis(mainValElement: HTMLSpanElement, hoveredTextTooltipContent: NgxPopperjsContentComponent) {
    this.hoveredTooltipText = null;
    hoveredTextTooltipContent?.hide();
    if (mainValElement && this.isEllipsisActive(mainValElement)) {
      this.hoveredTooltipText = mainValElement?.innerText || null;
      hoveredTextTooltipContent?.show();
      setTimeout(() => hoveredTextTooltipContent?.update(), 50);
    }
  }

  isEllipsisActive = (e) => (e?.offsetWidth < e?.scrollWidth);

  handleHyperLinkClick(col, rowData) {
    if (col.handleHyperLinkClick) {
      col.handleHyperLinkClick(col, rowData);
    }
  }

  handleHyperLinkRightClick(col, rowData) {
    if (col.hyperlink && col.handleHyperLinkRightClick) {
      col.handleHyperLinkRightClick(col, rowData);
    }
  }

  get handleNoSearchData() {
    return Boolean(this.dataSource.data?.length && this.dataSource.filteredData?.length === 0);
  }

  get handleNoSelectSearchData() {
    return Boolean(this.dataSource.data?.length === 0 && this.selectSearchConfig?.inputCtrl?.value);
  }

  /** Checking filter chips added ot not by checking child chips of app-filter-chips
   * Reason for using this method instead of checking filters queryParam
   * 1. After adding and removing filters still keeps this queryParams in the url ?filters=%257B%257D**
   * 2. To check query params I need to subscribe the ActivatedRoute everytime. */
  get handleNoDateForAddedFilterChips() {
    const appfilterChips = document.getElementsByTagName('app-filter-chips');
    return Boolean(this.dataSource.data?.length === 0 && appfilterChips[0] && appfilterChips[0].getElementsByTagName('mat-chip') && appfilterChips[0].getElementsByTagName('mat-chip')?.length > 0);
  }

  showSubTextArray(rowData, col: ZenTableColsModel, arr: string[], subTextArrayPopper: NgxPopperjsContentComponent, rightClick = false) {
    subTextArrayPopper.hide();
    this.subTextArrayDetails = null;
    if (arr?.length) {
      this.subTextArrayDetails = {subTextArr: arr, rowData, col, rightClick};
      subTextArrayPopper.show();
      setTimeout(() => subTextArrayPopper.clean());
    }
  }

  onSubTextOptionClick(rowData, col: ZenTableColsModel, subTextArrObj: string, rightClick = false) {
    setTimeout(() => {
      if (this.tableConfig?.handleSubTextArrayClick) {
        const subTextArrayDetails = {subTextArr: rowData[col?.subTextArrayCol], rowData, col, rightClick};
        this.tableConfig.handleSubTextArrayClick({selected: subTextArrObj, subTextArrayDetails});
      }
    }, 100);
  }

  onRadioBtnSelected(evt, rowData) {
    evt.stopPropagation();
    this.selectedRadioVal = rowData;
    this.onRadioChange.emit(rowData);
  }

  /** Select search starts here */
  onSearchTypeChange() {
    // If any search input values exists - while changing the search type - clearing the input field and reloading table data.
    if (this.selectSearchConfig.inputCtrl.value) {
      this.clearInput();
      this.searchKeyup();
    }
  }

  /**
   * When key is pressed, fire the searchTextChanged event emitter
   */
  searchKeyup() {
    if (this.selectSearchConfig.searchTypeCtrl.value) {
      this.searchTextChanged.next(this.selectSearchConfig.inputCtrl.value);
    }
  }

  clearInput() {
    this.selectSearchConfig.inputCtrl.setValue(null);
  }

  /**
   * When the search text changes (with debounce), handle it
   */
  handleSearchInputChange() {
    if (this.selectSearchConfig) {
      if (this.serverSideConfig) {
        // Server side search. Clear store, reset to first page, will recall API
        this.resetToFirstPage();
      } else {
        if (this.selectSearchConfig.onChange) {
          this.selectSearchConfig.onChange();
        }
        this.dataSource.paginator.firstPage();
      }
    }
  }

  handleMonthChange(evt, searchDateField) {
    this.zenMatTableHelperSvc.setMonthAndYear(evt, searchDateField, this.selectSearchConfig.inputCtrl);
    this.searchKeyup();
  }

  /** Select search ends here */

  private toPaginationResponse<T>(pageableModel: PageableModel<T>): PaginationResponse<T> {
    return {
      data: [...pageableModel.content],
      total: pageableModel.totalElements,
      currentPage: pageableModel.page + 1, // Akita is 1 indexed
      lastPage: pageableModel.totalPages,
      perPage: pageableModel.size
    };
  }

  /* Download */
  async downloadPage() {
    if (this.tableConfig.download?.externalDownload) {
      this.tableConfig.download?.externalDownload();
    } else {
      let _columns: ZenTableColsModel[] = this.tableConfig.cols;
      let _data: Object[] = this.dataSource.data; // pull from current page
      if (_data?.length === 0) {
        return;
      }
      // build the data to be exported for each row in the table
      const exportRows = _data.map(row => {
        const exportRow = {};
        _columns.forEach(col => {
          if (col.downloadFormatter) {
            exportRow[col.header] = col.downloadFormatter(col, row);
          } else {
            exportRow[col.header] = col.formatter ? col.formatter(col, row) : row[col.field];
          }
        });
        return exportRow;
      });
      const fileName = formatExtractFileName(this.tableConfig.download.getFileNameFn());
      this.downloadService.downloadExcelFile(exportRows, fileName);
    }
  }

  onRowDoubleClick(row: Object) {
    if (this.tableConfig.onDoubleClick) {
      this.tableConfig.onDoubleClick(row);
    }
  }

  handleRowClick(row) {
    if (this.tableConfig?.rowTooltip?.enable) {
      this.onRowTooltipHover.emit({col: null, element: row});
    }
    if (this.tableConfig.onRowClick) {
      this.tableConfig.onRowClick(row);
    }
  }

  handleRowExpansion(row) {
    if (this.isExpandable) {
      setTimeout(() => this.expandedElement = this.expandedElement === row ? null : row);
    }
  }

  onDropReceived(evt) {
    if (this.tableConfig.isDraggable && this.tableConfig.onDropReceived) {
      this.tableConfig.onDropReceived(evt);
    }
  }

  dragDroppedEvent(evt) {
    if (this.tableConfig.isDraggable && this.tableConfig.onDragDropped) {
      this.tableConfig.onDragDropped(evt);
    }
  }

  handleTdMouseHover(col: ZenTableColsModel, element) {
    if (col.hyperlink || col.editable) {
      this.hoveredCol = col;
      this.hoveredElement = element;
    }
  }

  handleTdMouseLeave() {
    this.hoveredCol = null;
    this.hoveredElement = null;
    this.hoveredTooltipText = null;
  }

  handleArrowTooltipClick(col: ZenTableColsModel, element, tooltipContent: NgxPopperjsContentComponent) {
    tooltipContent.hide();

    if (col.enableTooltip) {
      setTimeout(() => {
        tooltipContent.clean();
        tooltipContent.show();
      }, 100);
      this.onTooltipHover.emit({col, element});
    }
  }

  handleArrowSubTextTooltipClick(col: ZenTableColsModel, element, tooltipContent: NgxPopperjsContentComponent) {
    tooltipContent.hide();

    if (col.enableSubTextTooltip) {
      setTimeout(() => {
        tooltipContent.clean();
        tooltipContent.show();
      }, 100);
      this.onSubTextTooltipHover.emit({col, element});
    }
  }
}
