<!--Ref: https://blog.angulartraining.com/how-to-use-a-skeleton-loader-with-angular-4f03ae8fa5c6-->
<ng-container [ngSwitch]="loaderType">
  <ng-container *ngSwitchCase="ZenLoaderTypes.CHART">
    <div class="col-6 p-0 mb-4">
      <ngx-skeleton-loader [theme]="theme"></ngx-skeleton-loader>
    </div>
    <div class="d-flex">
      <div class="col-6 ps-0">
        <ngx-skeleton-loader appearance="circle" [theme]="chartCircle"></ngx-skeleton-loader>
      </div>
      <div class="col-6 pe-2 text-end">
        <ngx-skeleton-loader [theme]="chartSmallBar"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="chartSmallBar"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="chartSmallBar"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="chartSmallBar"></ngx-skeleton-loader>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="ZenLoaderTypes.SNAP_SHOT">
    <div class="snapshot-main-wrapper">
      <div class="col-6 p-0 mb-4 snapshot-header">
        <ngx-skeleton-loader [theme]="theme"></ngx-skeleton-loader>
      </div>
      <div class="row">
        <ng-template [ngTemplateOutlet]="snapShotSingle" [ngTemplateOutletContext] ="{ styleClass: '' }"></ng-template>
        <ng-template [ngTemplateOutlet]="snapShotSingle"></ng-template>
        <ng-template [ngTemplateOutlet]="snapShotSingle"></ng-template>
        <ng-template [ngTemplateOutlet]="snapShotSingle"></ng-template>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="ZenLoaderTypes.USAGE">
    <div class="snapshot-main-wrapper">
        <div class="row mb-4">
          <div class="col-3">
            <ngx-skeleton-loader [theme]="usageBar"></ngx-skeleton-loader>
          </div>
          <div class="col-9">
            <ngx-skeleton-loader [theme]="usageBar"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <ngx-skeleton-loader [theme]="usageBar"></ngx-skeleton-loader>
          </div>
          <div class="col-9">
            <ngx-skeleton-loader [theme]="usageBar"></ngx-skeleton-loader>
          </div>
        </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="ZenLoaderTypes.TABS">
    <div class="row align-items-center">
      <div class="col-12 col-sm-6">
        <div class="row align-items-center">
          <div class="col-3">
            <ngx-skeleton-loader [theme]="theme"></ngx-skeleton-loader>
          </div>
          <div class="col-3">
            <ngx-skeleton-loader [theme]="theme"></ngx-skeleton-loader>
          </div>
          <div class="col-3">
            <ngx-skeleton-loader [theme]="theme"></ngx-skeleton-loader>
          </div>
          <div class="col-3">
            <ngx-skeleton-loader [theme]="theme"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="text-end">
          <div class="">
            <ngx-skeleton-loader [theme]="inputBar"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
    <ngx-skeleton-loader [theme]="tableContentSec"></ngx-skeleton-loader>

    <div class="d-flex justify-content-end mt-2 mb-4">
      <div class="col-4 p-0">
        <ngx-skeleton-loader [theme]="theme"></ngx-skeleton-loader>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="ZenLoaderTypes.TABLE">
    <ngx-skeleton-loader [theme]="tableContentSec"></ngx-skeleton-loader>
  </ng-container>

  <ng-container *ngSwitchCase="ZenLoaderTypes.TABLE_BG">
    <div class="table-bg-wrapper">
      <div class="col-12 table-bg-row">
        <ngx-skeleton-loader [theme]="tableRow" [animation]="false" count="6"></ngx-skeleton-loader>
      </div>
    </div>
  </ng-container>

</ng-container>

<ng-template #snapShotSingle let-styleClass="styleClass">
  <div class="col-6" [ngClass]="styleClass">
    <div class="row snapshot-single-loader">
      <div class="col-5 circle-sec">
        <ngx-skeleton-loader [theme]="snapShotCircle" appearance="circle"></ngx-skeleton-loader>
      </div>
      <div class="col-7">
        <ngx-skeleton-loader [theme]="snapShotBar"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="theme"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>
