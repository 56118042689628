<!-- Adding this app-layout component to support base font-size(62.5% -> 1px = 0.1rem) -->
<app-layout></app-layout>
<div class="p-4">

  <div class="mb-5">
    <h3 class="mb-3">Confirm Dialog</h3>
    <button mat-raised-button color="primary" (click)="confirmDialog()">Open Confirm Dialog</button>
  </div>

  <div class="mb-5">
    <h3 class="mb-2">Guidance Dialog</h3>

    <p class="mb-2 mt-4">Success Guidance</p>
    <button mat-raised-button color="primary" (click)="openSuccessGuidance()">Open Success Guidance</button>

    <p class="mb-2 mt-4">Specific Error Guidance</p>
    <button mat-raised-button color="primary" (click)="openErrorGuidance(true)">Open Error Guidance</button>

    <p class="mb-2 mt-4">Generic Error Guidance</p>
    <button mat-raised-button color="primary" (click)="openErrorGuidance(false)">Open Error Guidance</button>
  </div>

  <div class="mb-5">
    <h3 class="mb-2">Send Email Pop-ups</h3>

    <p class="mb-2 mt-4">Send MLOA/LOE</p>
    <button mat-raised-button color="primary" (click)="openSendEmailDialog()">Open Email Dialog</button>
  </div>

  <div class="mb-5">
    <h3 class="mb-2">Toast Messages</h3>

    <p class="mb-2 mt-4">Success Toast</p>
    <button mat-raised-button color="primary" (click)="dialogMsgSvc.openToast(true)">Open Success Toast</button>

    <p class="mb-2 mt-4">Error Toast</p>
    <button mat-raised-button color="primary" (click)="dialogMsgSvc.openToast(false)">Open Error Toast</button>
  </div>

</div>
