export enum MATRIX_DV_SORT_TYPES {
  PRICE_LOW_TO_HIGH = 'PRICE_LOW_TO_HIGH',
  PRICE_HIGH_TO_LOW = 'PRICE_HIGH_TO_LOW',
  SAVINGS_LOW_TO_HIGH = 'SAVINGS_LOW_TO_HIGH',
  SAVINGS_HIGH_TO_LOW = 'SAVINGS_HIGH_TO_LOW',
  TERM_SHORT_TO_LONG = 'TERM_SHORT_TO_LONG',
  TERM_LONG_TO_SHORT = 'TERM_LONG_TO_SHORT'
}

export enum MATRIX_PARENT_COMPONENT {
  MARKET_PULSE = 'MARKET_PULSE',
  CUSTOMER_RC = 'CUSTOMER_RC'
}

export enum PCT_SAVING_ANALYSIS_TYPES {
  MEDIAN = 'MEDIAN',
  SUPPLIER = 'SUPPLIER',
  UTILITY = 'UTILITY'
}
