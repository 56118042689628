export enum TagType {
  CUSTOMER = 'CUSTOMER',
  LEN = 'LEN',
  SERVICE_ADDRESS = 'SERVICE_ADDRESS',
  METER = 'METER',
}

export type TagOption = {
  tagName?: string;
  tagGroupName?: string;
  tagGroupId?: string;
}

export type LabelledTagOption = TagOption & {
  label: string;
  isNew?: boolean;
  disabled?: boolean;
}

export type Tag = {
  id: string;
  name: string;
  objectType: TagType;
  customerId: number;
  lenId?: string;
  meterId?: number;
  serviceAddressId?: number;
  tagGroup?: any;
}

export type TagCreationRequest = {
  name: string;
  objectType: TagType;
  organizationId: string;
  customerId: number;
  groupName?: string;
  lenId?: string;
  serviceAddressId?: number;
  meterId?: number;
}
