<app-zen-dialog-wrapper [data]="data" [loading]="loading" (onSubmit)="submit($event)">

  <div *ngIf="form" class="d-flex flex-wrap" [formGroup]="form">
    <div class="col-12 col-md-12 px-4">
      <div class="dialog-form-sec with-border mb-0">
        <div class="d-flex justify-content-center">
          <div class="w-100">

            <div class="row mb-3">
              <div class="col-12 text-start">
                <p class="form-title mb-0">Contract Details</p>
              </div>
            </div>

            <div class="col-12 col-md-12 form-group px-0">
              <div class="mb-2">
                <app-zen-unit-conversion-input label="Supply Rate*" [isRateField]="true"
                                               [commodity]="selectedCommodity"
                                               [formCtrl]="form.controls.supplierRate"
                                               [selectedConversionUnit]="unit"></app-zen-unit-conversion-input>
              </div>
              <mat-error *ngIf="hasError('supplierRate', 'required')">Required Field*</mat-error>
              <mat-error *ngIf="(hasError('supplierRate', 'max')
                      || hasError('supplierRate', 'min'))">
                {{rateValidationMsg}}
              </mat-error>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</app-zen-dialog-wrapper>
