import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgxPopperjsPlacements} from 'ngx-popperjs';
import {MeterUsageProfileV4GetModel} from '../../_model/meter-usage-profile-v4.model';
import {MeterUsageProfileV4Service} from '../../_services/v4/meter-usage-profile-v4.service';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {PortfolioMetersHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-meters-helper.service';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {MatrixPricingDataService} from '../../../_modules/zen-market-pulse/_services/matrix-pricing-data.service';
import {UtilityRateSchedule} from '../../_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {ReplaySubject} from 'rxjs';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';
import {ZenUnitsHelperService} from '../../_services/helpers/zen-units-helper.service';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';

export enum ZenUsageAlertType {
  ZEN_IQ = 'zen-iq', ESTIMATE = 'estimate'
}

export interface MeterUsageProfileDialogData {
  usage: MeterUsageProfileV4GetModel;
  customerId: number;
  serviceAddressId: number;
  meterId: number;
  enableZenIq?: boolean;
  alert?: boolean;
}

@Component({
  selector: 'app-zen-usage-profile-dialog',
  templateUrl: './zen-usage-profile-dialog.component.html',
  styleUrls: ['./zen-usage-profile-dialog.component.scss']
})
export class ZenUsageProfileDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  appearance: MatFormFieldAppearance = 'outline';
  form: UntypedFormGroup;
  disableButton: boolean;
  errorMsg: string;
  CommodityType = CommodityType;
  rateClassDisabled = false;
  rateSchedules: UtilityRateSchedule[] = [];
  filteredRateClassOptions: ReplaySubject<UtilityRateSchedule[]> = new ReplaySubject();

  // Usage alert
  usageType = new UntypedFormControl(ZenUsageAlertType.ZEN_IQ);
  ZenUsageAlertType = ZenUsageAlertType;

  ZenUnitsEnum = ZenUnitsEnum;

  placement = NgxPopperjsPlacements.BOTTOM;
  dialogData: MeterUsageProfileDialogData;

  isStateTx: boolean;

  unit = new UntypedFormControl(ZenUnitsEnum.kWh, [Validators.required]);
  baseUnit: ZenUnitsEnum;

  constructor(public dialogRef: MatDialogRef<ZenUsageProfileDialogComponent>,
              private zenDialogSvc: ZenDialogMsgService,
              private formBuilder: UntypedFormBuilder, private mtrUsgProfSvc: MeterUsageProfileV4Service,
              public pfMeterHelpSvc: PortfolioMetersHelperService,
              public zenUnitsSvc: ZenUnitsHelperService,
              public translateSvc: TranslateService,
              private matrixPricingDataSvc: MatrixPricingDataService,
              @Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel) {
    super(translateSvc);
    this.dialogData = this.data.data as MeterUsageProfileDialogData;
  }

  ngOnInit(): void {
    this.loadForm();
  }

  get controls() {
    return this.form.controls;
  }

  loadForm(): void {
    this.form = this.formBuilder.group({
      estimatedAnnualUsage: new UntypedFormControl(null, [Validators.required]),
      usingZenIq: new UntypedFormControl(),
      elecPeakDemandKw: new UntypedFormControl(null),
      utilityRateScheduleId: new UntypedFormControl(null)
    });

    this.isStateTx = this.pfMeterHelpSvc.meterDet?.serviceAddressState === 'TX';
    if (!this.isStateTx) {
      this.getRateSchedules(this.pfMeterHelpSvc.meterDet.utilityId);
    }

    this.setUnitValue();

    if (this.dialogData.usage) {
      this.controls.estimatedAnnualUsage.setValue(this.dialogData.usage.estimatedAnnualUsage);
      let usingZenIq = this.dialogData.usage.usingZenIq;
      this.controls.usingZenIq.setValue((usingZenIq === null) ? false : usingZenIq);
      this.handleEstimatedAnnualUsageInput(usingZenIq);
      this.controls.elecPeakDemandKw.setValue(this.pfMeterHelpSvc.meterDet.elecPeakDemandKw);
    }

    this.controls.usingZenIq.valueChanges.subscribe(checked => {
      this.handleEstimatedAnnualUsageInput(checked);
    });

    // listen to changes in State, and filter options accordingly
    this.controls.utilityRateScheduleId.valueChanges.subscribe(search => {
      this.filteredRateClassOptions.next(this.filterRateClasses());
    });
  }

  handleEstimatedAnnualUsageInput(checked) {
    if (!checked) {
      this.controls.estimatedAnnualUsage.enable();
    } else {
      this.controls.estimatedAnnualUsage.setValue(JSON.stringify(this.dialogData.usage.estimatedAnnualUsage));
      this.controls.estimatedAnnualUsage.disable();
    }
    this.setUnitValue();
  }

  setUnitValue() {
    if (this.pfMeterHelpSvc.meterDet?.commodityType === CommodityType.Electricity) {
      this.unit.setValue(ZenUnitsEnum.kWh);
      this.baseUnit = ZenUnitsEnum.kWh;
    } else {
      this.unit.setValue(ZenUnitsEnum.Dth);
      this.baseUnit = ZenUnitsEnum.Dth;
    }
  }

  filterRateClasses() {
    let filteredStates = [...this.rateSchedules];
    let search = this.controls.utilityRateScheduleId.value;
    if (!search) {
      return filteredStates;
    } else {
      search = typeof search === 'string' ? search.toLowerCase() : '';
      return this.rateSchedules.filter(s => s.code.toLowerCase().includes(search));
    }
  }

  getRateSchedules(utilityId: number) {
    this.matrixPricingDataSvc.getRateScheduleListWithDescriptions(utilityId).subscribe(res => {
      this.rateSchedules = res.rateSchedules;
      this.filteredRateClassOptions.next(res.rateSchedules);
      this.controls.utilityRateScheduleId.setValue(res.rateSchedules.find(u => u.id === this.pfMeterHelpSvc.meterDet.utilityRateScheduleId));

      if (this.rateSchedules.length === 0) {
        this.controls.utilityRateScheduleId.disable();
        this.rateClassDisabled = true;
      } else {
        this.controls.utilityRateScheduleId.enable();
        this.rateClassDisabled = false;
      }

    }, e => {
      console.log('Error: Get utility rate schedules ', e);
      this.zenDialogSvc.openToast(false);
    });
  }

  disableDialogActions(disable: boolean) {
    this.disableButton = disable;
  }

  submit(btn: ZenDialogActionButton) {
    this.errorMsg = null;
    if (btn.label === 'Cancel') {
      this.dialogRef.close();
    } else {
      this.form.markAllAsTouched();
      if (this.form.valid) {
        this.disableDialogActions(true);
        let updateData = {...this.form.value,
          utilityRateScheduleId: this.controls.utilityRateScheduleId.value?.id,
          estimatedAnnualUsage: this.zenUnitsSvc.getSelectedUnitCalculatedUsageValue(this.unit.value, parseFloat(this.form.getRawValue().estimatedAnnualUsage))
        };

        // handle usage profile submit
        this.form.value.usingZenIq = (this.form.value.usingZenIq == null) ? false : this.form.value.usingZenIq;
        this.mtrUsgProfSvc.updateUsageProfile(this.dialogData.customerId, this.dialogData.serviceAddressId,
          this.dialogData.meterId, updateData).subscribe(updated => {
          this.pfMeterHelpSvc.meterUsage = updated;
          this.zenDialogSvc.openToast(true);
          this.dialogRef.close(this.pfMeterHelpSvc.meterDet);
        }, e => {
          this.disableDialogActions(false);
          console.log('Error: Update usage profile ', e);
          if (e.error && e.error.status === 400 && e.error.message) {
            this.errorMsg = e.error.message
          } else {
            this.errorMsg = ZenErrorMsgEnum.ERR_MSG_1_TEXT;
          }
          this.zenDialogSvc.openToast(false);
        });
      }
    }
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }

  displayFn(u: UtilityRateSchedule): string {
    return u && u.code ? u.code + ' - ' + u.description : '';
  }
}
