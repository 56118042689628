import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CssVariablesService {

  cssVariables = {};

  constructor() { }

  setCssVariable(varName: string, varValue: string) {
    document.documentElement.style.setProperty(varName, varValue);
    this.cssVariables[varName] = varValue;
  }

  getCssVariable(varName: string): string {
    return document.documentElement.style.getPropertyValue(varName);
  }

  getCssVariables() {
    return this.cssVariables;
  }
}
