<ng-container *ngIf="formGroup && formGroup.controls && formGroup.controls[formCtrlName]">
  <div [popper]="passwordStrengthMeter" [popperHideOnScroll]="true"
       popperTrigger="click" popperApplyClass="sm" [popperPlacement]="'top-start'"
       [popperDisabled]="!(feedback && passwordConfig)">
    <mat-form-field appearance="outline">
      <mat-label>{{label}}</mat-label>
      <input matInput [placeholder]="placeHolder" [type]="hide ? 'password' : 'text'"
             [formControl]="formGroup.controls[formCtrlName]"/>
      <i class="material-symbols-rounded pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</i>
      <mat-error *ngIf="hasError('required')">{{label}} is required.</mat-error>
      <mat-error *ngIf="hasError('mustMatch')">{{label}} does not match.</mat-error>
    </mat-form-field>
    <mat-error *ngIf="passwordConfig?.description && hasError('pattern')">{{passwordConfig?.description}}</mat-error>
  </div>
</ng-container>
<!-- TODO: This is just an temporary password strength tooltip. Need to implement strength checker in future. -->
<popper-content #passwordStrengthMeter>
  <app-zen-popper [popperContent]="passwordStrengthMeter" [closeable]="true">
    <h2>Pick a password</h2>
    <hr/>
    <div class="my-3" *ngIf="feedback">
      <app-zen-password-strength-checker [passwordToCheck]="formGroup.controls[formCtrlName].value"
      [passwordConfig]="passwordConfig"></app-zen-password-strength-checker>
    </div>

    <p class="font-weight-500 mb-3">Suggestions</p>
    <div>
      <p class="text-md">{{passwordConfig?.description}}</p>
    </div>
  </app-zen-popper>
</popper-content>
