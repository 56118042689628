import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {DataFlowChildrenDataTableModified} from '../../_model/data-flow-hierarchy-v4.model';
import {ItemListV4Model} from '../../_model/item-list-v4.model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ButtonActionTypes, ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {MatrixPricingDataService} from '../../../_modules/zen-market-pulse/_services/matrix-pricing-data.service';
import {ZenRcReqFlowHelperService} from '../../../_modules/zen-rate-check-request-v2/_services/zen-rc-req-flow-helper.service';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {MetersV4Service} from '../../_services/v4/meters-v4.service';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {ZenBaseComponent} from '../../_components/zen-base/zen-base.component';
import {MeterZoneChangeRequest} from '../../_model/meter-zone.model';
import {ZenHierarchicalGrouping} from '../../_enums/zen-hierarchical-grouping.enum';
import {RcReqMeterBulkUpdateHelperService} from '../../_services/helpers/rc-req-meter-bulk-update-helper-service';
import {BulkUpdateType} from '../../_enums/zen-bulk-update-type.enum';
import {ZenCustomerBulkImportHelperService} from '../../../_modules/zen-bulk-import/_services/zen-customer-bulk-import-helper.service';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';

@Component({
  selector: 'app-rc-req-meter-zone-update-dialog',
  templateUrl: './rc-req-meter-zone-update-dialog.component.html',
  styleUrls: ['./rc-req-meter-zone-update-dialog.component.scss']
})
export class RcReqMeterZoneUpdateDialogComponent extends ZenBaseComponent implements OnInit {
  zoneControl = new UntypedFormControl();
  meterRow: DataFlowChildrenDataTableModified;
  loading: boolean;
  bulkUpdateType: BulkUpdateType;
  currentGrouping: ZenHierarchicalGrouping;
  commodityType: CommodityType;

  zoneList: ItemListV4Model[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<RcReqMeterZoneUpdateDialogComponent>,
              private matrixPricingDataSvc: MatrixPricingDataService,
              public rcReqHelpSvc: ZenRcReqFlowHelperService,
              public zenDialogSvc: ZenDialogMsgService,
              public dialog: MatDialog,
              private meterV4Svc: MetersV4Service,
              public rcReqMeterBulkUpdateHelpSvc: RcReqMeterBulkUpdateHelperService,
              public bulkImportHelpSvc: ZenCustomerBulkImportHelperService ) {
    super();
    this.meterRow = this.data.data.meterRow;
    this.zoneControl.setValue(this.meterRow.zoneId);
    this.bulkUpdateType = this.data.data.bulkUpdateType;
    this.currentGrouping = this.data.data.dataGrouping;
    this.commodityType = this.data.data.commodityType;

    this.loadZones();
  }

  ngOnInit(): void {
  }

  loadZones() {
    this.loading = true;
    this.matrixPricingDataSvc.getZipCodeZoneListByMeterId(this.meterRow.meterId)
      .subscribe({
        next: zones => {
          this.zoneList = zones.map(zone => ({key: zone.id, value: zone.name}));
          this.loading = false;
        },
        error: err => {
          this.loading = false;
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }

  submit(btn: ZenDialogActionButton) {
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      this.dialogRef.close(null);
    } else {
      this.zoneControl.markAllAsTouched(); // to trigger mat-error
      if (this.zoneControl.valid) {
        this.handleMeterZoneUpdate();
      }
    }
  }

  /**
   * This method used to handle both single and bulk meter's zone update w.r.t the applyToAllMatchingZipCodes flag.
   */
  handleMeterZoneUpdate() {
    const meterIds = this.rcReqMeterBulkUpdateHelpSvc.getMeterIdsByCurrentGrouping(this.currentGrouping, this.meterRow, this.bulkUpdateType)

    const request: MeterZoneChangeRequest = {
      meterId: this.meterRow.meterId,
      meterIds: meterIds,
      zoneId: this.zoneControl.value,
      applyToAllMatchingZipCodes: this.data.checkBox.formCtrl.value // Flag used to decide bulk update or not
    };

    this.loading = true;
    this.meterV4Svc.bulkChangeZoneMeters(this.rcReqHelpSvc.customerIdParam, request).subscribe({
        next: updatedMeters => {
          this.dialogRef.close(updatedMeters);
          if (this.bulkUpdateType === BulkUpdateType.BULK_IMPORT_RECENTLY_ADDED_METERS) {
            this.bulkImportHelpSvc.getRecentlyImportedData(this.currentGrouping, this.commodityType);
          } else {
            this.rcReqHelpSvc.getRateCheckMetersGroupedDataById(this.currentGrouping);
          }
          this.zenDialogSvc.openToast(true);
          this.loading = false;
        },
        error: err => {
          this.loading = false;
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }
}
