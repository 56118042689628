import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonActionTypes, ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {
  PortfolioProcurementSnapshotHelperService
} from '../../../_modules/portfolio/_services/_helpers/portfolio-procurement-snapshot-helper.service';
import {EnergyPlanTimeline, MeterDetailsV4Model, TimelineStatus} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import {PortfolioMetersHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-meters-helper.service';
import * as moment from 'moment';
import {FormTypesEnum} from '../../_zen-legacy-common/_enums/form-types.enum';
import {Subscription} from 'rxjs';
import {ZenDialogSizeEnum} from '../../_enums/zen-dialogs.enum';
import {ZenProcurementSnapshotTblRowModel} from '../../_model/contract-v4.model';
import {ZenRcStatusClass} from '../../../_modules/zen-rate-checks/_enum/zen-rc-v4.enum';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-zen-add-procurement-snapshot-dialog',
  templateUrl: './zen-add-procurement-snapshot-dialog.component.html',
  styleUrls: ['./zen-add-procurement-snapshot-dialog.component.scss']
})
export class ZenAddProcurementSnapshotDialogComponent implements OnInit, OnDestroy {
  errorMsg: string;
  snapShotDates = {minDate: null, maxDate: null};
  statusBeforeOnUtilityCheck: TimelineStatus = TimelineStatus.UNDEFINED;
  localTimelineData: EnergyPlanTimeline[] = [];
  FormTypes = FormTypesEnum;
  subs: Subscription[] = [];
  showOnUtilityCheckbox: boolean;
  hoveredTimeline: EnergyPlanTimeline;
  ZenIcons = ZenIconsEnum;

  constructor(public dialogRef: MatDialogRef<ZenAddProcurementSnapshotDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public pfMeterHelpSvc: PortfolioMetersHelperService,
              public pfProcurementHelpSvc: PortfolioProcurementSnapshotHelperService) {
  }

  ngOnInit(): void {
    let {commodityType} = this.data.data as MeterDetailsV4Model;
    this.pfProcurementHelpSvc.initTable(commodityType, this.data.data, this.dialogRef);
    this.pfProcurementHelpSvc.loadData(true);
    // Subscribe to contract loaded
    const contractLoaded = this.pfProcurementHelpSvc.contractDataLoaded.subscribe(() => this.handleContractRefresh());
    this.subs.push(contractLoaded);

    this.pfProcurementHelpSvc.assignToUtility.setValue(false);
    const timelineData = this.pfMeterHelpSvc.timelineDataLoaded.subscribe(() => this.handleTimeLineData());
    this.subs.push(timelineData);

    const widthChanges = this.pfProcurementHelpSvc.handleDialogWidthChanges.subscribe((width: ZenDialogSizeEnum) => {
      this.dialogRef.updateSize(width);
    });
    this.subs.push(widthChanges);

    const _excludeFromOpportunities = this.pfProcurementHelpSvc.excludeFromOpportunities.valueChanges.subscribe(toggled => {
      if (toggled) {
        this.pfProcurementHelpSvc.excludeUntilDate.setValidators([Validators.required]);
      } else {
        this.pfProcurementHelpSvc.excludeUntilDate.setValidators(null);
        this.pfProcurementHelpSvc.excludeUntilDate.reset();
      }
      this.pfProcurementHelpSvc.excludeUntilDate.updateValueAndValidity();
    });
    this.subs.push(_excludeFromOpportunities);

    const _assignToUtility = this.pfProcurementHelpSvc.assignToUtility.valueChanges.subscribe(toggled => {
      this.pfProcurementHelpSvc.excludeFromOpportunities.reset();
      this.pfProcurementHelpSvc.excludeUntilDate.reset();
    });
    this.subs.push(_assignToUtility);
  }

  ngOnDestroy() {
    this.pfProcurementHelpSvc.closeContractForm();
    this.subs.forEach(s => s.unsubscribe());
  }

  handleTimeLineData() {

    // Handle draft contract scenario
    if (this.statusBeforeOnUtilityCheck === 'Draft') {
      this.showOnUtilityCheckbox = false;
      this.localTimelineData = JSON.parse(JSON.stringify(this.pfProcurementHelpSvc.simulatedTimelineData));
      return;
    }

    // this is the real timeline data
    if (this.pfMeterHelpSvc.timelineData) {
      // if the simulated timeline exists, use that
      if (this.pfProcurementHelpSvc.simulatedTimelineData.length > 0) {
        // Form the timeline based on the simulated data passed in
        this.localTimelineData = JSON.parse(JSON.stringify(this.pfProcurementHelpSvc.simulatedTimelineData));
      } else {
        this.localTimelineData = JSON.parse(JSON.stringify(this.pfMeterHelpSvc.timelineData));
      }
      // To set min and max date in the procurement snapshot timeline
      const startDates = this.localTimelineData.filter(d => d.startDate != null).map(d => moment(d.startDate)),
        minStartDt = moment.min(startDates).format('MMM YYYY');
      const endDates = this.localTimelineData.filter(d => d.endDate != null).map(d => moment(d.endDate));
      let maxStartDt;
      let firstContract = this.pfMeterHelpSvc?.timelineData?.[0];
      if (this.localTimelineData.length === 1) {
        if (firstContract.timelineStatus === TimelineStatus.EXPIRED) {
          // If this single contract is expired and the end date is in the past, make the end date now + 36 months.
          // Otherwise, use the end date.
          if (moment(firstContract.endDate).isBefore(moment())) {
            maxStartDt = moment().add(36, 'months').format('MMM YYYY');
          } else {
            maxStartDt = moment(firstContract.endDate).format('MMM YYYY');
          }
        } else {
          maxStartDt = moment.min(startDates).add(36, 'months').format('MMM YYYY'); // 36 month timeline
        }
      } else {
        // If the last contract endDate is null, calculate the end date as now + 36 months
        if (this.localTimelineData[this.localTimelineData.length - 1].endDate === null) {
          if (moment(this.localTimelineData[this.localTimelineData.length - 1].startDate).isBefore(moment().add(36, 'months'))) {
            maxStartDt = moment().add(36, 'months').format('MMM YYYY');
          } else {
            // Handling case where timeline has a really future contract that's processing.
            // Going to show 3 months after start date of this contract
            // We don't know when contract will end, so show 3 months from this contract start date (a little bit)
            maxStartDt = moment(this.localTimelineData[this.localTimelineData.length - 1].startDate).add(3, 'months');
          }
        } else {
          maxStartDt = moment.max(endDates).format('MMM YYYY');
        }
      }
      this.snapShotDates = {
        // changed from minStartDt to now because "The start date on the procurement snapshot timeline should be the current month."
        minDate: moment().format('MMM YYYY'),
        maxDate: maxStartDt
      };
      // If first timeline status === ON_UTILITY -> set assignToUtility as true.
      if (firstContract?.timelineStatus === TimelineStatus.ON_UTILITY) {
        this.pfProcurementHelpSvc.assignToUtility.setValue(true);
        this.pfProcurementHelpSvc.excludeFromOpportunities.setValue(firstContract?.excludedFromOpportunities);
        this.pfProcurementHelpSvc.excludeUntilDate.setValue(firstContract?.excludedUntil);
      }
      // Only show this checkbox show if 1st timeline status -> If undefined or expired
      this.showOnUtilityCheckbox = firstContract?.timelineStatus === TimelineStatus.EXPIRED
        || firstContract?.timelineStatus === TimelineStatus.UNDEFINED
        || firstContract?.timelineStatus === TimelineStatus.ON_UTILITY;
    }
  }

  // Change 1st timeline status into On Utility.
  handleOnUtilityChange() {
    this.localTimelineData = this.localTimelineData.map((t, index) => {
      if (index === 0) {
        // If checking the checkbox, set the status to ON_UTILITY and set the statusBeforeOnUtilityCheck
        if (this.pfProcurementHelpSvc.assignToUtility.value) {
          this.statusBeforeOnUtilityCheck = t.timelineStatus;
          t.timelineStatus = TimelineStatus.ON_UTILITY;
        } else {
          // If unchecking the checkbox, set the status to the statusBeforeOnUtilityCheck
          t.timelineStatus = this.statusBeforeOnUtilityCheck;
        }
      }
      return t;
    });
  }

  bubbleUpError(event) {
    this.errorMsg = event;
  }

  submit(btn: ZenDialogActionButton) {
    this.errorMsg = null;
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      btn.command(false);
    } else {
      if (this.pfProcurementHelpSvc.excludeFromOpportunities.value && !this.pfProcurementHelpSvc.excludeUntilDate.value) {
        this.pfProcurementHelpSvc.excludeUntilDate.markAsTouched();
      } else {
        this.pfProcurementHelpSvc.updateProcurementSnapshot(this.dialogRef);
      }
    }
  }

  handleContractRefresh(): void {
    if (this.pfProcurementHelpSvc.linkedContracts.find(c => c.statusCls === ZenRcStatusClass.EXPIRED)) {
      this.statusBeforeOnUtilityCheck = TimelineStatus.EXPIRED;
    } else if (this.pfProcurementHelpSvc.linkedContracts.find(c => c.contractStatus === 'Draft')) {
      this.statusBeforeOnUtilityCheck = TimelineStatus.DRAFT;
    } else {
      this.statusBeforeOnUtilityCheck = TimelineStatus.UNDEFINED;
    }

    this.handleTimeLineData();
  }

  handleInfoCardClick(c: ZenProcurementSnapshotTblRowModel) {
    if (!c.lockedContract) {
      // Also uncheck utility box, just reset everything
      this.pfProcurementHelpSvc.assignToUtility.setValue(false);
      this.pfProcurementHelpSvc.cancelLinking(c);
    }
  }

  handleEligibleContractClick(c: ZenProcurementSnapshotTblRowModel) {
    this.pfProcurementHelpSvc.assignToUtility.setValue(false);
    this.pfProcurementHelpSvc.handleLinkingEligibleContracts(c)
  }

}
