import { Pipe, PipeTransform } from '@angular/core';
import { CommodityType } from '../_models/commodity';
import { CommodityUnit } from '../_enums/commodity-unit.enum';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'annualUsage'
})
export class AnnualUsagePipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {}
  
  transform(value: number, commodityType: CommodityType): string {
    return this.decimalPipe.transform(value, '1.0-0') + ' ' + (commodityType === CommodityType.Gas ? CommodityUnit.GAS : CommodityUnit.ELECTRICITY);
  }

}
