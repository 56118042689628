import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {LenRcDetails, RateCheckV4Model} from '../../_model/rate-check-v4.model';
import {PageableModel} from '../../../_modules/portfolio/_model/pageable.model';
import {RateCheckStatusEnum} from '../../_zen-legacy-common/_models/rate-checks/rate-check-status';
import {RateCheckV3Model, RefreshPricingModel} from '../../../_modules/zen-rate-checks/_model/rate-check-v3.model';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import {getParams} from '../../_zen-legacy-common/_utils/set-param-util';
import {ZenRateCheckSearchTypeEnum} from '../../_enums/zen-rate-check-search-type.enum';
import {TxSwitchData} from '../../../_modules/zen-reports/_modules/zen-rate-check-report/_models/matrix-rate-check.interface';
import {
  RateCheckRequestV4FlowModel,
  StaggeredStartProjection
} from '../../_model/rate-check-request-v4.model';
import {DataFlowChildrenDataV4Model, DataFlowHierarchyGroupedV4Model} from '../../_model/data-flow-hierarchy-v4.model';
import {ZenHierarchicalGrouping} from '../../_enums/zen-hierarchical-grouping.enum';
import {BillUploadedDocument} from '../../_model/bills-v4.model';
import {BrokerFeesCalculated} from '../../_zen-legacy-common/zen-common-services/tili-services/models/broker-fees';
import {ScenarioMetersV4Model} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import {formatAddressType2} from '../../_zen-legacy-common/_utils/format-utils';
import {UtilityV4Model} from '../../_model/utility-v4.model';
import {RateCheckRefreshData} from '../../_model/eligible-supplier-response.model';

@Injectable({
  providedIn: 'root'
})
export class RateCheckV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService, private authSvc: AuthenticationService) { }

  getRateChecks(filters: string, rateCheckSearchType: ZenRateCheckSearchTypeEnum,  page: number, size: number, sortBy?: string, sortDir?: string): Observable<PageableModel<RateCheckV4Model>> {
    let httpParams = getParams({
      page,
      size,
      ...((sortBy && sortDir) ? { sort: `${sortBy},${sortDir}` } : {})
    });
    httpParams = httpParams.set('rateCheckSearchType', rateCheckSearchType.toString());

    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/rate-checks?filters=${encodeURIComponent(filters)}`;
    return this.http.get<PageableModel<RateCheckV4Model>>(url, {params: httpParams}).pipe(take(1));
  }

  setRateCheckStatus(rateCheckId: string, newStatus: RateCheckStatusEnum, comments?: string): Observable<RefreshPricingModel> {
    let request = {newStatus, comments};
    const url = `${this.baseUrl}/v2/organizations/${this.orgMgtSvc.getOrganizationId()}/rate-checks/${rateCheckId}/status`;
    return this.http.put<RefreshPricingModel>(url, request).pipe(take(1));
  }

  deleteRateCheck(customerId: number, rateCheckId: string): Observable<RateCheckV3Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rateCheckId}`;
    return this.http.delete<RateCheckV3Model>(url).pipe(take(1));
  }

  /**
   * To clone a RC using its id with RC status 100.
   * @param customerId
   * @param rateCheckId
   */
  cloneRateCheck(customerId: number, rateCheckId: string): Observable<RateCheckRequestV4FlowModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rateCheckId}/clone`;
    return this.http.post<RateCheckRequestV4FlowModel>(url, null).pipe(take(1));
  }

  /**  RC REQUEST START **/
  createRcRequest(customerId: number, rateCheckFlowDto: RateCheckRequestV4FlowModel): Observable<RateCheckRequestV4FlowModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks`;
    return this.http.post<RateCheckRequestV4FlowModel>(url, rateCheckFlowDto).pipe(take(1));
  }

  updateRcRequest(customerId: number, rcId: string, rateCheckFlowDto: Partial<RateCheckRequestV4FlowModel>, recalculate = false): Observable<RateCheckRequestV4FlowModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}?recalculate=${recalculate}`;
    return this.http.put<RateCheckRequestV4FlowModel>(url, rateCheckFlowDto).pipe(take(1));
  }

  /**
   * This API fetch details of a RC which is created/updated using createRcRequest()/updateRcRequest() service calls.
   * Which included statusCode, commodityTypes, state etc.,
   * @param customerId
   * @param rcId
   */
  getRcRequestFlowByRcId(customerId: number, rcId: string): Observable<RateCheckRequestV4FlowModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}`;
    return this.http.get<RateCheckRequestV4FlowModel>(url).pipe(take(1));
  }

  getRcRequestStartDateByRcId(customerId: number, rcId: string): Observable<StaggeredStartProjection[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}/start-dates`;
    return this.http.get<StaggeredStartProjection[]>(url).pipe(take(1));
  }

  getRcRequestDataFlowGroupedByRcId(customerId: number, rcId: string, groupBy: ZenHierarchicalGrouping): Observable<DataFlowHierarchyGroupedV4Model[]> {
    const params = getParams({groupBy});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}/data-flow-grouped`;
    return this.http.get<DataFlowHierarchyGroupedV4Model[]>(url, {params}).pipe(take(1));
  }

  getRcRequestDataFlowFlatByRcId(customerId: number, rcId: string): Observable<DataFlowHierarchyGroupedV4Model[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}/data-flow-flat`;
    return this.http.get<DataFlowHierarchyGroupedV4Model[]>(url).pipe(take(1));
  }

  /**
   * This API used in the meter selection flyout. Which load eligible meters w.r.t the RC.
   * @param customerId
   * @param rcId
   */
  getRcEligibleMetersGroupedByRcId(customerId: number, rcId: string, groupBy: ZenHierarchicalGrouping, eligibilityWindowMonths = 12): Observable<DataFlowHierarchyGroupedV4Model[]> {
    const params = getParams({groupBy, eligibilityWindowMonths});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}/eligible-grouped`;
    return this.http.get<DataFlowHierarchyGroupedV4Model[]>(url, {params}).pipe(take(1));
  }

  updateRateCheckTxSwitchData(organizationId: string,  customerId: number, rateCheckId: string, txSwitchData: TxSwitchData): Observable<RateCheckV3Model> {
    const url = `${this.baseUrl}/v4/organizations/${organizationId}/customers/${customerId}/rate-checks/${rateCheckId}/tx-switch-data`;
    return this.http.put<RateCheckV3Model>(url, txSwitchData).pipe(take(1));
  }

  /**
   * The fee calculation is handled w.r.t the totalFee or brokerFee. We should pass only one value at a time.
   * @param customerId
   * @param rcId
   * @param totalFee
   * @param brokerFee
   */
  calculateRateCheckFees(customerId: number, rcId: string, totalFee?: number, brokerFee?: number): Observable<BrokerFeesCalculated> {
    const params = getParams({totalFee, brokerFee});
    const url = `${this.baseUrl}/v4/organizations/${this.authSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}/fee`;
    return this.http.get<BrokerFeesCalculated>(url, {params}).pipe(take(1));
  }

  /**
   * This API used to populate the list of suppliers for a Rate Check Refresh
   * @param customerId
   * @param rcId
   */
  getRateCheckRefreshData(customerId: number, rcId: string): Observable<RateCheckRefreshData> {
    const url = `${this.baseUrl}/v4/organizations/${this.authSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}/rate-check-refresh-data`;
    return this.http.get<RateCheckRefreshData>(url).pipe(take(1));
  }

  uploadMeterBill(customerId: number, rcId: string, meterId: number, billFile: File): Observable<BillUploadedDocument> {
    const formData = new FormData();
    formData.append('billFile', billFile);
    const url = `${this.baseUrl}/v4/organizations/${this.authSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}/meters/${meterId}/bill-upload`;
    return this.http.post<BillUploadedDocument>(url, formData).pipe(take(1));
  }

  /**
   * Returns a flat data flow list of all the meters in the given rate check. Used in the RCR meters dialog.
   * @param customerId
   * @param rcId
   */
  getRcMetersDataFlowDetails(customerId: number, rcId: string, includeProposedStart = false): Observable<DataFlowChildrenDataV4Model[]> {
    const params = getParams({includeProposedStart});
    const url = `${this.baseUrl}/v4/organizations/${this.authSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}/meters-details`;
    return this.http.get<DataFlowChildrenDataV4Model[]>(url, {params}).pipe(take(1));
  }

  /**
   * To fetch contract start & end date for meters from PricingScenarioOptionMeter.
   * @param customerId
   * @param rcId
   * @param termMonths
   */
  getRcScenarioMeters(customerId: number, rcId: string, termMonths?: number): Observable<ScenarioMetersV4Model[]> {
    let httpParams = getParams({termMonths});
    const url = `${this.baseUrl}/v4/organizations/${this.authSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}/scenario-meters`;
    return this.http.get<ScenarioMetersV4Model[]>(url, {params: httpParams}).pipe(take(1));
  }

  /**
   * Get list of LEN details by RC id.
   * @param customerId
   * @param rcId
   */
  getListOfLenDetailsByRcId(customerId: number, rcId: string): Observable<LenRcDetails[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.authSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}/len-details`;
    return this.http.get<LenRcDetails[]>(url).pipe(take(1), map(ld => ld.map(l => {
      l.billingAddressText = formatAddressType2(l);
      return l;
    })));
  }

  /**
   * If there are ineligible meters w.r.t, the utilityId show the following warning (Only on Import on RateCheck Data Preview Flow).
   * -- Electric
   * utility.state = 'MD' and utility.procurement_status_electric != 'AVAILABLE';
   *
   * -- Gas
   * utility.state = 'MD' and utility.procurement_status_gas != 'AVAILABLE';
   * @param customerId
   * @param rcId
   */
  getListOfIneligibleUtilityIds(customerId: number, rcId: string): Observable<UtilityV4Model[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rcId}/ineligible-utilities`;
    return this.http.get<UtilityV4Model[]>(url).pipe(take(1));
  }

}
