<app-zen-dialog-wrapper [data]="dialogData" (onSubmit)="submit($event)" [actionStyleCls]="'center'">
  <app-zen-loader *ngIf="loading"></app-zen-loader>
  <div class="my-3 mx-4 mx-sm-3 mx-md-0" *ngIf="!loading && tableTopConfigBtnAction && tableTopConfigBtnAction">
    <div class="zen-top-action-wrapper-inside-mat-tab zen-meters-tree-table-dialog">
      <app-zen-tab-top-action [config]="tableTopConfigBtnAction"></app-zen-tab-top-action>
    </div>

    <app-zen-mat-table [data]="meterListModified" [tableConfig]="tableConfig"
      [viewType]="tableTopConfigBtnAction.btnToggleGroupAction.formCtrl.value"></app-zen-mat-table>
  </div>
</app-zen-dialog-wrapper>
