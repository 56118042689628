<div class="zen-dialog">
  <div *ngIf="this.data.type===ZenDialogDataType.MATRIX_DOWNLOADER" class="loader-main">
    <div class="loader-section">
      <i class="material-symbols-outlined">cloud_download</i>
      <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="content-section">
      <h5>Almost ready to download</h5>
      <p>Generating your contract. This may take up to 60 seconds. Please do not close this browser.</p>
    </div>
  </div>

  <div *ngIf="this.data.type !== ZenDialogDataType.MATRIX_DOWNLOADER">
    <i class="material-icons close-icon" (click)="data.onClose()" *ngIf="!data.hideCloseX">close</i>
    <div class="header-icon-main" >
      <i *ngIf="data?.header?.icon" class="material-symbols-rounded" [ngClass]="[data?.header?.iconClass || '', icon?.color || '']">{{data?.header?.icon || icon?.name}}</i>
    </div>

    <div *ngIf="data?.deleteConfirmation?.deleteClicked else normalFlow">
      <h1 mat-dialog-title class="dialog-title" *ngIf="data?.deleteConfirmation?.deleteHeader">{{data?.deleteConfirmation?.deleteHeader}}</h1>
      <p *ngIf="data?.deleteConfirmation?.deleteBody" class="body-text">{{data?.deleteConfirmation?.deleteBody}}</p>
      <div mat-dialog-actions class="center" *ngIf="data.actionButtons && data.actionButtons.length">
        <button (click)="deleteToggled()" mat-button class="mr-4">Cancel</button>
        <button (click)="showLoadingSpinner = true; data?.deleteConfirmation.deleteCommand()" mat-raised-button
                [color]="'primary'" [disabled]="showLoadingSpinner">
          Confirm
          <mat-spinner *ngIf="showLoadingSpinner" class="button-click-spinner" color="accent" diameter="20"></mat-spinner>
        </button>
      </div>
    </div>
    <ng-template #normalFlow>
      <h1 mat-dialog-title class="dialog-title" *ngIf="data?.header">{{data?.header?.title}}</h1>
      <div mat-dialog-content>
        <div *ngIf="data?.bodyHtml" class="body-text" [innerHTML]="data?.bodyHtml" ></div>
        <p *ngIf="data?.bodyText" class="body-text">{{data?.bodyText}}</p>
        <div class="col-12 form-group">
          <div *ngIf="data.numberInput">
            <mat-form-field appearance="outline" *ngIf="data?.numberInput?.mask">
              <mat-label>
                {{data.numberInput.label}}
              </mat-label>
              <input matInput [imask]="data.numberInput.mask"
                     [unmask]="true"
                     [formControl]="data.numberInput.formCtrl"
                     [min]="data.numberInput.min"
                     [max]="data.numberInput.max">
              <mat-error *ngIf="data.numberInput.formCtrl.hasError('min') || data.numberInput.formCtrl.hasError('max')">
                Value must be between {{'currency.symbol' | translate}}{{data.numberInput.min}} and {{'currency.symbol' | translate}}{{data.numberInput.max}}
              </mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="data.textInput" class="mt-3">
            <mat-form-field appearance="outline" >
              <mat-label>
                {{data.textInput.label}}
              </mat-label>
              <input matInput [maxlength]="data?.textInput?.charLimit ? data?.textInput?.charLimit : 80"   [formControl]="data.textInput.formCtrl">
            </mat-form-field>
          </div>
          <div *ngIf="data?.deleteConfirmation" (click)="deleteToggled()" class="float-start align-items cursor-pointer">
            <i class="material-icons danger-1-color">delete</i>
            <span class="align-middle ps-3 sub-text">Delete</span>
          </div>
        </div>
        <div *ngIf="data?.checkBox?.formCtrl" class="my-3 float-start ms-5 ps-3">
          <mat-checkbox [formControl]="data?.checkBox?.formCtrl">{{data?.checkBox?.label}}</mat-checkbox>
        </div>
      </div>


      <div mat-dialog-actions class="center mt-3" *ngIf="data.actionButtons && data.actionButtons.length">
        <ng-container *ngFor="let btn of data.actionButtons">
          <button mat-button [ngClass]="btn?.btnType || 'mat-button'" [class]="btn?.styleClass"
                  [class.me-4]="data.actionButtons?.length > 1 && btn?.actionType === ButtonActionTypes.CANCEL"
                  [disabled] = "showLoadingSpinner && clickedButtonLabel === btn.label"
                  (click)="executeCommand(btn)" [color]="btn.color" >
            {{btn.label}}
            <mat-spinner *ngIf="showLoadingSpinner && clickedButtonLabel === btn.label" class="button-click-spinner" color="accent" diameter="20"></mat-spinner>
          </button>
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>
