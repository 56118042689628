import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {Observable} from 'rxjs';
import {getParams} from '../../_zen-legacy-common/_utils/set-param-util';
import {take} from 'rxjs/operators';
import {CustomerBillingAddress} from '../../_model/customer-address-v4.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerBillingAddressV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  getCustomerBillingAddressById(customerId: number, includeLens = false): Observable<CustomerBillingAddress[]> {
    let httpParams = getParams({includeLens});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/billing-addresses`;
    return this.http.get<CustomerBillingAddress[]>(url, {params: httpParams}).pipe(take(1));
  }

  updateCustomerBillingAddress(customerId: number, addressId: string, address: Partial<CustomerBillingAddress>): Observable<CustomerBillingAddress> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/billing-addresses/${addressId}`;
    return this.http.put<CustomerBillingAddress>(url, address).pipe(take(1));
  }

  addCustomerBillingAddress(customerId: number, request: Partial<CustomerBillingAddress>): Observable<CustomerBillingAddress> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/billing-addresses`;
    return this.http.post<CustomerBillingAddress>(url, request).pipe(take(1));
  }

  deleteAddressById(customerId: number, addressId: string): Observable<void> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/billing-addresses/${addressId}`;
    return this.http.delete<void>(url).pipe(take(1));
  }

  bulkDeleteAddressByIds(customerId: number, ids: string[]): Observable<void> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/billing-addresses/bulk-delete`;
    return this.http.post<void>(url, {ids}).pipe(take(1));
  }
}
