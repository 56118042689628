export class ContractSignature {
  constructor(
    public signatureSvgBase64Image: string,
    public signatureText: string,
    public initialsSvgBase64Image: string,
    public initialsText: string
  ) { }
}


