<div class="d-flex justify-content-between align-items-center mb-4 px-0 px-sm-2 px-md-3">
  <div class="d-inline-flex">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center">
        <h1 class="large">{{title}}
          <ng-content></ng-content>
        </h1>
      </div>
      <hr class="under-line"/>
    </div>
  </div>
  <button *ngIf="!hideFilter"
          [ngClass]="{'filter-icon-button filter-header-button':true,'active': filtersApplied}"
          (click)="openFilters()">
    <i class="material-symbols-rounded large">filter_alt</i>
  </button>
</div>



