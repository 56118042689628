import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {ContractSignature} from '../../_zen-legacy-common/_models/contract';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {DocumentAcknoledgement} from '../../_model/custom/generic-document.model';


declare var $: any;

@Component({
  selector: 'app-zen-generic-sign-document',
  templateUrl: './zen-generic-sign-document.component.html',
  styleUrls: ['./zen-generic-sign-document.component.scss']
})
export class ZenGenericSignDocumentComponent implements OnInit, AfterViewInit {
  @ViewChild('drawSignatureBox')
  drawSignatureBox: ElementRef<HTMLDivElement>;

  @ViewChild('drawInitialsBox')
  drawInitialsBox: ElementRef<HTMLDivElement>;

  @Input() documentUrl: string;
  @Input() acknowledgements: DocumentAcknoledgement[] = [];
  @Input() viewOnly = false;
  @Input() completeBtnLabel = 'Submit';

  @Output() onSignDocument: EventEmitter<ContractSignature> = new EventEmitter();
  @Output() onCloseSigningWindow: EventEmitter<Boolean> = new EventEmitter();

  safeDocumentUrl: SafeResourceUrl;
  signatureOverlayVisible = false; // TODO: switch back to false by default
  drawSignature = true;
  drawInitials = true;

  typedSignature = '';
  typedInitials = '';

  drawSignatureValid = false;
  drawSignatureInvalid = false;
  typedSignatureValid = false;
  typedSignatureInvalid = false;

  drawInitialsValid = false;
  drawInitialsInvalid = false;
  typedInitialsValid = false;
  typedInitialsInvalid = false;

  signatureErrorText: string;
  initialsErrorText: string;
  @Input() signContractError = false;

  acceptSignature = false;

  @Input() signingDocument = false;
  formValid: boolean;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.documentUrl = this.documentUrl + '#toolbar=1'; // To close the toolbar menu onload.
    this.safeDocumentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.documentUrl);
  }

  ngAfterViewInit(): void {
    if (this.drawSignatureBox && this.drawInitialsBox) {
      $(this.drawSignatureBox.nativeElement).jSignature({
        height: '100px',
        width: '100%'
      });

      $(this.drawSignatureBox.nativeElement).bind('change', () => {
        if (this.isDrawDataPresent($(this.drawSignatureBox.nativeElement))) {
          this.drawSignatureValid = true;
          this.drawSignatureInvalid = false;
        }
      });

      $(this.drawInitialsBox.nativeElement).jSignature({
        height: '100px',
        width: '100%'
      });

      $(this.drawInitialsBox.nativeElement).bind('change', () => {
        if (this.isDrawDataPresent($(this.drawInitialsBox.nativeElement))) {
          this.drawInitialsValid = true;
          this.drawInitialsInvalid = false;
        }
      });
    }
  }

  showSignatureOverlay() {
    this.signatureOverlayVisible = true;
    /** Doing this to trigger signature div, to initiate jsSignature canvas. */
    setTimeout(() => {
      this.onResize()
    }, 100);
  }

  hideSignatureOverlay() {
    this.signatureOverlayVisible = false;
    this.resetInitials();
    this.resetSignature();
    this.resetAcks();
  }

  closeSigningWindow() {
    this.onCloseSigningWindow.emit(true);
  }
  typedSignatureChanged(newValue) {
    this.typedSignature = newValue;
    if (this.typedSignature === '' || this.typedSignature === null || this.typedSignature === undefined) {
      this.typedSignatureValid = false;
      this.typedSignatureInvalid = true;
    } else {
      this.typedSignatureInvalid = false;
      this.typedSignatureValid = true;
    }
  }

  typedInitialsChanged(newValue) {
    this.typedInitials = newValue;
    if (this.typedInitials === '' || this.typedInitials === null || this.typedInitials === undefined) {
      this.typedInitialsValid = false;
      this.typedInitialsInvalid = true;
    } else {
      this.typedInitialsInvalid = false;
      this.typedInitialsValid = true;
    }
  }

  resetSignature() {
    if (this.drawSignature) {
      $(this.drawSignatureBox.nativeElement).jSignature('reset');
      this.drawSignatureValid = false;
      this.drawSignatureInvalid = false;
    } else {
      this.typedSignature = '';
      this.typedSignatureValid = false;
      this.typedSignatureInvalid = false;
    }
  }

  resetInitials() {
    if (this.drawInitials) {
      $(this.drawInitialsBox.nativeElement).jSignature('reset');
      this.drawInitialsValid = false;
      this.drawInitialsInvalid = false;
    } else {
      this.typedInitials = '';
      this.typedInitialsValid = false;
      this.typedInitialsInvalid = false;
    }
  }

  resetAcks() {
    this.acknowledgements = this.acknowledgements.map(a => {
      a.selected = false; // to uncheck
      return a
    });
  }


  updateCheckbox(arg: MatCheckboxChange, obj: DocumentAcknoledgement) {
    obj.selected = arg.checked;
  }
  signDocument() {
    this.signContractError = false;
    const canSign = this.checkSignatureAndInitials();
    if (canSign) {
      const contractSignature = new ContractSignature(
        this.drawSignature ? $(this.drawSignatureBox.nativeElement).jSignature('getData', 'svgbase64')[1] : null,
        !this.drawSignature ? this.typedSignature : null,
        this.drawInitials ? $(this.drawInitialsBox.nativeElement).jSignature('getData', 'svgbase64')[1] : null,
        !this.drawInitials ? this.typedInitials : null
      );

      this.signingDocument = true;
      this.onSignDocument.emit(contractSignature);
    }
  }

  private checkSignatureAndInitials(): boolean {
    this.signatureErrorText = undefined;
    this.initialsErrorText = undefined;

    if (this.drawSignature && !this.drawSignatureValid) {
      this.drawSignatureInvalid = true;
    }
    if (this.drawInitials && !this.drawInitialsValid) {
      this.drawInitialsInvalid = true;
    }
    if (!this.drawSignature && !this.typedSignatureValid) {
      this.typedSignatureInvalid = true;
    }
    if (!this.drawInitials && !this.typedInitialsValid) {
      this.typedInitialsInvalid = true;
    }

    const providedSignature = (
      (this.drawSignature && this.drawSignatureValid) ||
      (!this.drawSignature && this.typedSignatureValid)
    );
    const providedInitials = (
      (this.drawInitials && this.drawInitialsValid) ||
      (!this.drawInitials && this.typedInitialsValid)
    );

    if (!providedSignature) {
      this.signatureErrorText = 'Your signature is required';
    }
    if (!providedInitials) {
      this.initialsErrorText = 'Your initials are required';
    }

    return providedSignature && providedInitials;
  }

  private isDrawDataPresent(signatureBox: any): boolean {
    const svgData = signatureBox.jSignature('getData', 'svg')[1];
    const xmlData = $.parseXML(svgData);
    const xml = $(xmlData);
    const size = xml.find('path').toArray()
      .map(path => {
        const d = $(path).attr('d');
        return d ? d.length : 0;
      })
      .reduce((a, b) => a + b, 0);
    return size > 0;
  }

  scrollBottom() {
    window.scroll({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  @HostListener('window:resize', ['$event']) onResize() {
    // Get data (if already drawn)
    const signatureBoxData = $(this.drawSignatureBox.nativeElement).jSignature("getData", "base30");
    const initialsBoxData = $(this.drawInitialsBox.nativeElement).jSignature("getData", "base30");

    // Remove boxes
    this.drawSignatureBox.nativeElement.removeChild(this.drawSignatureBox.nativeElement.getElementsByTagName('canvas')[0]);
    this.drawInitialsBox.nativeElement.removeChild(this.drawInitialsBox.nativeElement.getElementsByTagName('canvas')[0]);

    // Add new empty boxes
    $(this.drawSignatureBox.nativeElement).jSignature({
      height: '100px',
      width: '100%'
    });

    $(this.drawInitialsBox.nativeElement).jSignature({
      height: '100px',
      width: '100%'
    })

    // Bind events
    $(this.drawSignatureBox.nativeElement).bind('change', () => {
      if (this.isDrawDataPresent($(this.drawSignatureBox.nativeElement))) {
        this.drawSignatureValid = true;
        this.drawSignatureInvalid = false;
      }
    });

    $(this.drawInitialsBox.nativeElement).bind('change', () => {
      if (this.isDrawDataPresent($(this.drawInitialsBox.nativeElement))) {
        this.drawInitialsValid = true;
        this.drawInitialsInvalid = false;
      }
    });

    // Import extracted data
    $(this.drawSignatureBox.nativeElement).jSignature("setData", "data:" + signatureBoxData.join(","));
    $(this.drawInitialsBox.nativeElement).jSignature("setData", "data:" + initialsBoxData.join(","));
  }

  get ifAcksNotChecked() {
    return this.acknowledgements && this.acknowledgements.length && this.acknowledgements.some(ack => !ack.selected);
  }
}
