import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-zen-magic-scroll',
  templateUrl: './zen-magic-scroll.component.html',
  styleUrls: ['./zen-magic-scroll.component.scss']
})
export class ZenMagicScrollComponent implements AfterViewInit {
  @Input() position: 'top' | 'center';
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  showLeftIcon: boolean;

  @ViewChild('parentContainer') parentContainer: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    // Show and hide left icon w.r.t, the scroll bar position.
    if (this.parentContainer && this.parentContainer.nativeElement) {
      this.parentContainer.nativeElement.addEventListener('scroll', e => {
        this.showLeftIcon = e.target.scrollLeft > 1;
      });
    }
  }

  startDragging(e, flag, el) {
    this.mouseDown = true;
    this.startX = e.pageX - el.offsetLeft;
    this.scrollLeft = el.scrollLeft;
  }
  stopDragging(e, flag) {
    this.mouseDown = false;
  }
  moveEvent(e, el) {
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - el.offsetLeft;
    const scroll = x - this.startX;
    el.scrollLeft = this.scrollLeft - scroll;
  }


  clickLeft() {
    this.parentContainer.nativeElement.scrollTo({left: this.parentContainer.nativeElement.scrollLeft - 200, top: 0, behavior: 'smooth'});
  }

  clickRight() {
    this.parentContainer.nativeElement.scrollTo({left: this.parentContainer.nativeElement.scrollLeft + 200, top: 0, behavior: 'smooth'});
  }

}
