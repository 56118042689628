import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {UntypedFormControl} from '@angular/forms';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-zen-mat-datepicker',
  templateUrl: './zen-mat-datepicker.component.html',
  styleUrls: ['./zen-mat-datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class ZenMatDatepickerComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Output() monthSelected = new EventEmitter<{evt, dp}>(); // {evt: $event, dp: datePicker}

  constructor() { }

  ngOnInit(): void {
  }

}
