<ng-container *ngIf="data?.feeError && data?.feeDetails">
  <mat-error class="danger-1-color mt-2" *ngIf="data?.feeError?.valueHigh">
    Your fee exceeds the {{data?.feeDetails.maxFeeDollarsLimit | number: (numFormat)}} limit we set for this rate check.
  </mat-error>

  <mat-error class="danger-1-color mt-2" *ngIf="data?.feeError.valueLow">
    The total fee is under the {{data?.feeDetails.minFeeDollarsLimit | number: (numFormat)}} minimum Platform Fee set
    for this rate check.
  </mat-error>

  <mat-error class="danger-1-color mt-2" *ngIf="data?.feeError.belowZero">The total fee cannot be below 0.</mat-error>
</ng-container>
