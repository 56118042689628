import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../zen-common-services/_services/authentication.service';
import {OrganizationManagementService} from '../zen-common-services/_services/organization-management.service';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private zenDialogSvc: ZenDialogMsgService,
    private organizationService: OrganizationManagementService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.authService.redirectUrl = state.url;
    if (this.authService.isCurrentlyLoggedIn() || this.skipAuth(state)) {
      if (this.authService.isAdvisor() && this.organizationService.organization?.id) {
        // need to get additional organization information
        this.organizationService.getOrganizationPromise(true); // wait for org info before continuing
      }
      return true;
    }
    const token = route.queryParams['token'];
    if (token !== undefined && token !== '') {
      return this.authService.loginWithToken(token).pipe(
        map(res => {
          if (res.success) {
            if (this.authService.isAdvisor()) {
              // need to get additional organization information
              this.organizationService.getOrganizationPromise(true); // wait for org info before continuing
            }
            return true;
          }
          console.log('failed to log in with token');
          this.router.navigate(['/']);
          this.zenDialogSvc.openToast(false, 'Sorry! The link you followed has expired.');
          return false;
        }));
    }

    this.router.navigate(['/']);
    return false;
  }

  private skipAuth(state: RouterStateSnapshot) {
    return state.url.indexOf('skipAuth=true') !== -1;
  }
}
