/* The below method is copied from api-core RateCheckStatusUtils->getPortfolioManagementAdvisorFriendlyStatus */
import {ZenRcStatusClass} from '../_enum/zen-rc-v4.enum';
import {RateCheckStatusEnum} from '../../../_shared/_zen-legacy-common/_models/rate-checks/rate-check-status';

export function getRcFriendlyStatusCls(statusCode: RateCheckStatusEnum): ZenRcStatusClass {
  switch (statusCode) {
    case 50:
    case 60:
    case 65:
    case 70:
    case 80:
    case 90:
      return ZenRcStatusClass.DRAFT;
    case 100:
    case 110:
    case 120:
    case 200:
    case 250:
    case 300:
    case 310:
    case 320:
    case 330:
      return ZenRcStatusClass.PROCESSING; // 'Bidding'
    case 340:
      return ZenRcStatusClass.PROCESSING; // 'Advisor Review'
    case 400:
      return ZenRcStatusClass.PROCESSING; // 'Customer Review'
    case 410:
      // 'Contract Generation' -> Matrix & 'Contract Requested' -> Custom
      return ZenRcStatusClass.PROCESSING;
      break;
    case 420:
    case 430:
    case 440:
      return ZenRcStatusClass.PROCESSING; // 'Contract Requested'
    case 500:
      return ZenRcStatusClass.PROCESSING; // 'Contract Signing'
    case 505:
    case 510:
    case 520:
      return ZenRcStatusClass.SUPPLIER_REVIEW; // 'Supplier Review'
    case 1000:
      return ZenRcStatusClass.BOOKED;
    case 0:
      return ZenRcStatusClass.REJECTED; // 'Rejected'
    case 1:
      return ZenRcStatusClass.ABORTED; // 'Aborted'
    case 2:
      return ZenRcStatusClass.TIMED_OUT; // 'Timed Out'
    case 3:
      return ZenRcStatusClass.DECLINED; // 'Declined'
    default:
      return ZenRcStatusClass.UNKNOWN; // 'Unknown'
  }
}
