<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" [actionStyleCls]="'right'"
  [errorMsg]="errorMsg" [disableButton]="disableButton">
  <div class="my-5 mx-4 py-4">

    <!-- Add/Edit usage -->
    <div class="row" [formGroup]="form">
      <div *ngIf="pfMeterHelpSvc.meterDet?.commodityType == CommodityType.Gas" class="col-md-3"></div> <!-- Center Only Field, for gas -->
      <div class="col-md-6">
        <app-zen-unit-conversion-input label="Estimated Annual Usage*" [isRateField]="true"
                                       [commodity]="pfMeterHelpSvc.meterDet?.commodityType"
                                       [formCtrl]="form.controls.estimatedAnnualUsage"
                                       [isUsageField]="true" [hideUnitSelection]="controls.usingZenIq.value"
                                       [isRateField]="false"
                                       [selectedConversionUnit]="unit"></app-zen-unit-conversion-input>
        <mat-error *ngIf="hasError('estimatedAnnualUsage', 'required')">Required Field*</mat-error>

        <mat-hint class="info w-100 d-flex" *ngIf="unit.value !== baseUnit">
          <div class="d-flex align-items-center justify-content-end w-100">
            <i class="material-symbols-rounded me-1">warning</i>
            Value to be converted to platform default ({{baseUnit}})
          </div>
        </mat-hint>

        <div class="d-flex align-items-center mt-4 w-100">
          <mat-checkbox class="ms-0 float-start d-flex" [disabled]="!dialogData?.enableZenIq"
                        formControlName="usingZenIq"></mat-checkbox>
          <p class="text-sm m-0">Enable ZenIQ</p>
          <i class="material-icons-outlined help-outline ms-3 mt-1"
             [popper]="zenIqInfo" [popperHideOnScroll]="true" [popperHideOnClickOutside]="true"
             popperTrigger="click" popperApplyClass="sm" popperPlacement="bottom-end">info</i>
        </div>
      </div>
      <div class="col-md-6" *ngIf="isStateTx && pfMeterHelpSvc.meterDet && pfMeterHelpSvc.meterDet.commodityType === CommodityType.Electricity">
        <div class="d-flex align-items-center">
          <mat-form-field [appearance]="appearance" class="left-field">
            <mat-label>Peak Demand (kW)</mat-label>
            <input matInput formControlName="elecPeakDemandKw" class="pb-2"/>
          </mat-form-field>
          <i class="material-icons-outlined help-outline ms-3"
             [popper]="peakDemandInfo" [popperHideOnScroll]="true" [popperHideOnClickOutside]="true"
             popperTrigger="click" popperApplyClass="sm" popperPlacement="bottom-start">info</i>
        </div>
      </div>
      <div class="col-md-6" *ngIf="!isStateTx && pfMeterHelpSvc.meterDet?.commodityType !== CommodityType.Gas && _translations?.features?.featureRateSchedules">
        <div class="d-flex align-items-center">
          <mat-form-field [appearance]="appearance" class="left-field">
            <mat-label>{{ rateClassDisabled ? 'No Rate Classes Available' : 'Rate Class' }}</mat-label>
            <input matInput formControlName="utilityRateScheduleId" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [autoActiveFirstOption]="true" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredRateClassOptions | async" [value]="option">
                {{option.code + ' - ' + option.description}}
              </mat-option>
            </mat-autocomplete>
            <i class="material-symbols-rounded" matSuffix>expand_more</i>
          </mat-form-field>
          <i class="material-icons-outlined help-outline ms-3"
             [popper]="rateClsInfo" [popperHideOnScroll]="true" [popperHideOnClickOutside]="true"
             popperTrigger="click" popperApplyClass="sm" popperPlacement="left">info</i>
        </div>
      </div>

    </div>
  </div>
</app-zen-dialog-wrapper>

<popper-content #zenIqInfo>
  <app-zen-popper title="Zen IQ" [closeable]="true" [popperContent]="zenIqInfo">
    <div class="my-3">
      A predictive data algorithm that uses actual bill data to provide backward and forward usage projections. Understanding Estimated annual usage is required for procurement and can be set manually or by adding a bill and enabling this feature. ZenIQ is not available when bills are not loaded.
    </div>
  </app-zen-popper>
</popper-content>

<popper-content #rateClsInfo>
  <app-zen-popper title="Rate Classes" [closeable]="true" [popperContent]="rateClsInfo">
    <div class="my-3">
      Rate Classes are defined by the local {{'nomenclature.utilityShort' | translate}} and used to categorize a meter based on what it is (e.g. streetlight) and/or how it uses power (e.g general service, time of use, etc.). Rate Classes are designated on the bill.
    </div>
    <div class="my-3">
      Matrix {{'nomenclature.suppliers' | translate}} price based on rate class, making this a requirement for matrix pricing and contracting.
    </div>
  </app-zen-popper>
</popper-content>

<popper-content #peakDemandInfo>
  <app-zen-popper title="Peak Demand" [closeable]="true" [popperContent]="peakDemandInfo">
    <div class="my-3">
      The highest electrical power demand that has occurred in a given month. It represents the customer’s obligation and is required to calculate load factor which is a requirement for matrix pricing and contracting within Texas.
    </div>
  </app-zen-popper>
</popper-content>
