import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient, HttpParams} from '@angular/common/http';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {
    MeterActiveCheck,
    MeterAddUpdateModel,
    MeterDetailsV4Model,
    MeterV4Model, UnprocurableMeterInfo, UnprocurableMeterSummary, UnprocurableStatus
} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import {ActivationCheck, DeleteCheck} from '../../_model/customer-v4.model';
import {MeterZoneChangeRequest} from '../../_model/meter-zone.model';
import {MeterRateClassChangeRequest} from '../../_model/meter-rate-class.model';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {SelectionLevel} from '../../_dialogs/zen-procurable-status-dialog/zen-procurable-status-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MetersV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  getMeterDetailsById(customerId: number, serviceAddressId: number, meterId: number): Observable<MeterDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/details`;
    return this.http.get<MeterDetailsV4Model>(url).pipe(take(1));
  }

  getUnprocurableStatus(
    customerId: number,
    states: string[],
    commodityTypes: CommodityType[] | null,
    meterId?: number // used for single meters
  ): Observable<UnprocurableStatus[]> {
    // Construct the URL with query parameters
    let params = new HttpParams();

    if (states && states.length > 0) {
      // Append each state to the params
      states.filter(state => state).forEach((state) => {
        params = params.append('states', state);
      });
    }

    if (commodityTypes && commodityTypes.length > 0) {
      // Append each commodityType to the params
      commodityTypes.forEach((commodityType) => {
        params = params.append('commodityTypes', commodityType.toString());
      });
    }

    if (meterId) {
      params = params.append('meterId', meterId);
    }

    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/unprocurable-status`;
    return this.http.get<UnprocurableStatus[]>(url, { params }).pipe(take(1));
  }

  getUnprocurableStatusInfo(customerId: number, ids: (string | number)[], entityType: SelectionLevel) {
    let url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/bulk/unprocurable-status`;
    let params: HttpParams = new HttpParams().set('ids', ids.join(',')).set('entityType', entityType);

    return this.http.get<UnprocurableMeterSummary>(url, { params }).pipe(take(1));
  }


  getMeterById(customerId: number, serviceAddressId: number, meterId: number): Observable<MeterV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}`;
    return this.http.get<MeterV4Model>(url).pipe(take(1));
  }

  getAllMetersInServiceAddress(customerId: number, serviceAddressId: number): Observable<MeterV4Model[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters`;
    return this.http.get<MeterV4Model[]>(url).pipe(take(1));
  }

  addMeter(customerId: number, serviceAddressId: number, data: MeterAddUpdateModel): Observable<MeterDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters`;
    return this.http.post<MeterDetailsV4Model>(url, data).pipe(take(1));
  }

  updateMeter(customerId: number, serviceAddressId: number, meterId: number, data: MeterAddUpdateModel): Observable<MeterDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}`;
    return this.http.put<MeterDetailsV4Model>(url, data).pipe(take(1));
  }

  deleteMeterById(customerId: number, serviceAddressId: number, meterId: number): Observable<void> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}`;
    return this.http.delete<void>(url).pipe(take(1));
  }

  bulkDeleteCheckMeter(customerId: number, serviceAddressId: number, ids: number[]): Observable<DeleteCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/bulk/delete-check`;
    return this.http.post<DeleteCheck>(url, { ids }).pipe(take(1));
  }

  bulkDeleteMeters(customerId: number, serviceAddressId: number, ids: number[]): Observable<DeleteCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/bulk/delete`;
    return this.http.post<DeleteCheck>(url, { ids }).pipe(take(1));
  }

  /**
   * Bulk Activation Check Meters - Check to see if provided Meters can be Reactivated
   * Payload -> {customerId: number, meterId: number, ids: string[]} -> where applicable (Meters)
   * @param customerId
   * @param serviceAddressId
   * @param ids
   */
  bulkActivationCheckMeters(customerId: number, serviceAddressId: number, ids: number[]): Observable<ActivationCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/service-addresses/${serviceAddressId}/meters/bulk/activation-check`;
    return this.http.post<ActivationCheck>(url, { ids }).pipe(take(1));
  }

  bulkChangeUnprocurableStatus(
      customerId: number,
      ids: (number | string)[], // Accepts either number or string depending on the entity type
      unprocurableStatusId: number,
      notes: string,
      excludedUntil,
      entityType: SelectionLevel, // Use the enum for entity types: meter, serviceAddress, len
      targetUnprocurableStatusIds: number[],
      targetProcurable: boolean,
      commodityType: CommodityType
  ) {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/bulk/unprocurable-status`;

    // Build the request body with the dynamic ids, unprocurableStatusId, notes, and excludedUntil
    const body = {
      ids,
      unprocurableStatusId,
      notes,
      excludedUntil,
      entityType, // Pass the enum as a string (SelectionLevel.meter, SelectionLevel.serviceAddress, etc.)
      targetUnprocurableStatusIds,
      targetProcurable,
      commodityType
    };

    return this.http.put(url, body).pipe(take(1));
  }

  /**
   * Bulk change status meters - Deactivate or reactivate meters using the table or row action toggles
   * Payload -> {customerId: number, meterId: number, ids: number[]} -> where applicable (service addresses)
   * @param customerId
   * @param serviceAddressId
   * @param meterIds
   * @param active
   */
  bulkChangeStatusMeters(customerId: number, serviceAddressId: number, meterIds: number[], active: boolean) {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/service-addresses/${serviceAddressId}/meters/bulk/activation`;
    return this.http.put(url, {ids: meterIds, active: active}).pipe(take(1));
  }

  checkActiveMeterExistsByCustomerId(customerId: number): Observable<MeterActiveCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/active-meters-check`;
    return this.http.get<MeterActiveCheck>(url).pipe(take(1));
  }

  checkActiveMeterExistsAtOrgLevel(): Observable<MeterActiveCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/active-meters-check`;
    return this.http.get<MeterActiveCheck>(url).pipe(take(1));
  }

  /**
   * This service used to make bulk updates to the meter's rate class according to grouping and utility
   * @param customerId
   * @param updateRequest
   */
  bulkChangeRateClassMeters(customerId: number, updateRequest: MeterRateClassChangeRequest): Observable<MeterDetailsV4Model[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/meters/bulk/rate-schedule`;
    return this.http.put<MeterDetailsV4Model[]>(url, updateRequest).pipe(take(1));
  }

  /**
   * This service used to make both single and bulk updates to the meter's zone according to grouping and serviceAddress zip code
   * The applyToAllMatchingZipCodes flag is used to determing whether it is a single or bulk update
   * @param customerId
   * @param updateRequest
   */
  bulkChangeZoneMeters(customerId: number, updateRequest: MeterZoneChangeRequest): Observable<MeterDetailsV4Model[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/meters/bulk/zone`;
    return this.http.put<MeterDetailsV4Model[]>(url, updateRequest).pipe(take(1));
  }
}
