<div class="zen-tab-top-action-wrapper {{designPosition}}" *ngIf="config"
     [class.adjust-top]="config.dropdownAction || config.btnToggleGroupAction">

  <div class="d-flex dropdown-and-group-action-responsive" *ngIf="config.dropdownAction || config.btnToggleGroupAction">
    <ng-container *ngIf="config.dropdownAction && config.dropdownAction.formCtrl">
      <div class="d-flex flex-wrap align-items-center">
        <div class="" *ngIf="matSelectTrigger?.value">
          <mat-form-field class="zen-tab-top-action-select-field" appearance="outline" [style.width]="config.dropdownAction?.width || 'auto'">
            <mat-label>{{config.dropdownAction.label}}</mat-label>
            <mat-select [formControl]="config.dropdownAction.formCtrl"
                        panelClass="panel-min-wd-25"
                        (selectionChange)="config.dropdownAction.onChange()">
              <mat-select-trigger *ngIf="matSelectTrigger?.value">
                <div class="d-flex align-items-center">
                  <i class="material-symbols-rounded large text-color-light me-3" *ngIf="matSelectTrigger.icon">{{matSelectTrigger.icon}}</i>
                  <span *ngIf="!config.dropdownAction?.iconOnly">{{ matSelectTrigger?.label }}</span>
                </div>
              </mat-select-trigger>

              <mat-option *ngFor="let op of config.dropdownAction.options" [value]="op.value">
                <div class="d-flex align-items-center">
                  <i class="material-symbols-rounded large text-color-light me-3" *ngIf="op.icon">{{op.icon}}</i>
                  <span>{{op.label}}</span>
                </div>
              </mat-option>
            </mat-select>
            <i class="material-symbols-rounded" matSuffix>expand_more</i>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="config.btnToggleGroupAction && config.btnToggleGroupAction.formCtrl">
      <div class="d-flex flex-wrap align-items-center">
        <div class="m-3">
          <ng-container *ngFor="let op of config.btnToggleGroupAction.options">
            <mat-button-toggle-group *ngIf="config?.btnToggleGroupAction?.formCtrl?.value === op.value">
              <mat-button-toggle (click)="handleToggleBtnClick(op.value)">
                <i class="material-symbols-rounded">{{op.icon}}</i>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </ng-container>
        </div>
      </div>
    </ng-container>

  </div>

  <!-- 1st action btn -->
  <ng-container *ngIf="config.actionBtn">
   <ng-template [ngTemplateOutlet]="btnConfigTpl"
                [ngTemplateOutletContext]="{actionConfig: config?.actionBtn, menuItems: config?.menuItems}"></ng-template>
  </ng-container>


  <!-- 2nd action btn -->
  <ng-container *ngIf="config.actionBtn2">
    <ng-template [ngTemplateOutlet]="btnConfigTpl"
                 [ngTemplateOutletContext]="{actionConfig: config?.actionBtn2, menuItems: config?.menuItems2, class: 'm-3'}"></ng-template>
  </ng-container>

  <ng-container *ngIf="config.legendPopperContent">
    <div class="static-legend-container" [popper]="config.legendPopperContent"
         [popperPlacement]="'left'" popperApplyClass="md"
         [popperHideOnScroll]="true" [popperHideOnClickOutside]="true"
         (click)="openLegendPopper()">
      <ng-container *ngFor="let color of staticLegendColors">
        <i class="material-icons " [style.color]="color">fiber_manual_record</i>
      </ng-container>
    </div>
  </ng-container>

</div>


<!-- Keeping 2 different code for menu & non-menu buttons. Because, button with menuTrigger always
     open the cdk-overlay-wrapper. -->
<ng-template #btnConfigTpl let-menuItems="menuItems" let-actionConfig="actionConfig" let-class="class">
  <!-- Button with mat menu trigger -->
  <ng-container *ngIf="menuItems?.length else defaultBtnTpl">
    <ng-container [ngSwitch]="actionConfig?.btnType">
      <button *ngSwitchCase="ButtonTypes.MAT_BUTTON" mat-button [ngClass]="class"
              (click)="actionConfig.command ? actionConfig.command() : null"
              [matMenuTriggerFor]="tableTopMenu" [class]="actionConfig.styleCls">
        <span class="label">{{actionConfig.label}}</span>
        <i class="material-symbols-rounded large me-3">{{actionConfig.icon}}</i>
      </button>

      <button *ngSwitchDefault mat-raised-button color="accent" [class]="class"
              [class.mobile-icon-only-fixed]="!config.isDialog && !config.isDefaultState"
              [class.mobile-icon-only]="actionConfig.mobileIconOnly"
              (click)="actionConfig.command ? actionConfig.command() : null"
              [matMenuTriggerFor]="tableTopMenu">
        <i class="material-symbols-rounded large me-3" *ngIf="actionConfig.icon">{{actionConfig.icon}}</i>
        <span class="label">{{actionConfig.label}}</span>
      </button>
    </ng-container>

    <mat-menu #tableTopMenu="matMenu">
      <ng-container *ngFor="let m of menuItems">
        <button mat-menu-item (click)="m.command()">
          <div class="d-flex align-items-center">
            {{m.label}}

            <span class="beta-v2 small ms-3" *ngIf="m.beta">Beta</span>
            <span class="legacy-v1 small ms-3" *ngIf="m.legacy">Legacy</span>
          </div>
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>

  <!-- Button w/o menu items -->
  <ng-template #defaultBtnTpl>
    <ng-container [ngSwitch]="actionConfig?.btnType">
      <button *ngSwitchCase="ButtonTypes.MAT_BUTTON" mat-button [ngClass]="class"
              (click)="actionConfig.command ? actionConfig.command() : null" [class]="actionConfig.styleCls">
        <i *ngIf="actionConfig.icon"
           class="material-symbols-rounded large me-3 bg-text-color-light-3 p-1 border-radius-4">
          {{actionConfig.icon}}</i>
        <span class="label">{{actionConfig.label}}</span>
      </button>

      <button *ngSwitchDefault mat-raised-button color="accent" [class]="class"
              [class.mobile-icon-only-fixed]="!config.isDialog && !config.isDefaultState"
              [class.mobile-icon-only]="actionConfig.mobileIconOnly"
              (click)="actionConfig.command ? actionConfig.command() : null">
        <i class="material-symbols-rounded large me-3" *ngIf="actionConfig.icon">{{actionConfig.icon}}</i>
        <span class="label">{{actionConfig.label}}</span>
      </button>
    </ng-container>
  </ng-template>

</ng-template>
