<div class="zen-message-with-img" *ngIf="show">
  <div class="zen-message-with-img-wrapper">
    <div class="img-container">
      <app-jen-zen-img [imgType]="JenZenImgType.HAPPY"></app-jen-zen-img>
    </div>
    <div class="message-container">
      <i class="material-icons close-icon" *ngIf="closeable" (click)="dismiss()">close</i>
      <div [innerHTML]="message"></div>

      <div *ngIf="validations?.invalid" class="pt-2 mt-4 card-container-wrapper d-block">
        <p class="text-md text-start mb-1 pointer d-flex align-items-center" (click)="toggleValidation()">
          <i class="icon yellow material-symbols-outlined me-3 warning-1-color large">warning</i>
          {{validations?.title}}
          <i class="ms-3 material-symbols-rounded">{{!validations?.showValidation ? 'chevron_right' : 'expand_more'}}</i>
        </p>
        <p *ngIf="validations?.showValidation" class="text-md text-start ms-5 p-4 bg-text-color-light-3 card-container" [innerHTML]="validations?.message"></p>
      </div>
    </div>
  </div>
</div>
