import {Component, Input, OnInit} from '@angular/core';
import {Organization} from '../../../../_shared/_zen-legacy-common/_models/organization';
import {OrganizationsV4Service} from '../../../../_shared/_services/organizations-v4.service';
import {OrganizationManagementService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/organization-management.service';
export const DEFAULT_LOGO_PATH = '/assets/img/tili/tili-logo-green.png';

@Component({
  selector: 'app-portfolio-platform-logo',
  templateUrl: './portfolio-platform-logo.component.html',
  styleUrls: ['./portfolio-platform-logo.component.scss']
})
export class PortfolioPlatformLogoComponent implements OnInit {
  @Input() organization: Organization;
  /* Small Logo */
  smallLogo = {loading: false, imagePath: ''};
  /* Full Logo */
  fullLogo = {loading: false, imagePath: ''};

  constructor(private orgV4Svc: OrganizationsV4Service,
              private orgMgtSvc: OrganizationManagementService) {
  }

  ngOnInit(): void {
    if (this.organization) {
      this.smallLogo.imagePath = this.organization.imageSmallPath || DEFAULT_LOGO_PATH;
      this.fullLogo.imagePath = this.organization.imagePath || DEFAULT_LOGO_PATH;
    }
  }

  uploadSmallLogo(logoFile: File) {
    this.smallLogo.loading = true;
    this.orgV4Svc.uploadOrgSmallLogo(this.organization.id, logoFile).subscribe((org) => {
      this.orgMgtSvc.setOrganization(org);
      this.smallLogo.loading = false;
      this.smallLogo.imagePath = org.imageSmallPath;
    }, () => {
      this.smallLogo.loading = false;
    });
  }

  uploadFullLogo(logoFile: File) {
    this.fullLogo.loading = true;
    this.orgV4Svc.uploadOrgFullLogo(this.organization.id, logoFile).subscribe((org) => {
      this.orgMgtSvc.setOrganization(org);
      this.fullLogo.loading = false;
      this.fullLogo.imagePath = org.imagePath;
    }, () => {
      this.fullLogo.loading = false;
    });
  }

}
