import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';

@Component({
  selector: 'app-zen-rc-request-data-action-dialog',
  templateUrl: './zen-mat-table-dialog.component.html',
  styleUrls: ['./zen-mat-table-dialog.component.scss']
})
export class ZenMatTableDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenMatTableDialogComponent>) {
  }

  submit(btn: ZenDialogActionButton) {
    btn.command(btn);
  }

}
