<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" [errorMsgClass]="'error-margin'"
  actionStyleCls="center" [loading]="loading">


  <form [formGroup]="form" *ngIf="form && meterInfo?.commodityType" class="zen-bulk-assign-meter-energy-plan">

    <div class="form-group">
      <app-zen-unit-conversion-input label="Supply Rate (Optional)" [isRateField]="true"
                                     [commodity]="meterInfo?.commodityType"
                                     [formCtrl]="form.controls.supplierRate"
                                     [selectedConversionUnit]="unit"></app-zen-unit-conversion-input>

      <mat-error *ngIf="(hasError('supplierRate', 'max')
                    || hasError('supplierRate', 'min'))">
        {{rateValidationMsg}}
      </mat-error>
    </div>

  </form>

</app-zen-dialog-wrapper>
