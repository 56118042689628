import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ButtonTypes, ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {ZenMessageType} from '../../_components/zen-message/zen-message.component';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-zen-dialog-wrapper',
  templateUrl: './zen-dialog-wrapper.component.html',
  styleUrls: ['./zen-dialog-wrapper.component.scss']
})
export class ZenDialogWrapperComponent implements OnInit, OnDestroy {
  @Input() data: ZenDialogDataModel;
  @Input() loading: boolean;
  @Input() disableButton: boolean;
  @Input() hideButton: boolean;
  @Input() actionStyleCls: 'center' | 'right' = 'right';
  @Output() onSubmit: EventEmitter<ZenDialogActionButton> = new EventEmitter();

  @Input() errorMsgClass: string;

  // Error msg
  @Input() errorMsg: string;
  ZenMessageType = ZenMessageType;
  ButtonTypes = ButtonTypes;

  overlayWrapper = document.getElementsByClassName('cdk-global-overlay-wrapper');
  // Centering
  private onResizeSubject: Subject<void> = new Subject<void>();
  private onResizeSub: Subscription;

  constructor() {
    this.onResizeSub = this.onResizeSubject.pipe(debounceTime(250)).subscribe(() => {
      this.readjustDialogs();
    });
  }

  ngOnInit(): void {
    let index = 0; // default
    if (this.overlayWrapper.length > 0) {
      // sometime when there is more than one overlay component (e.g - snackbar and dialog)
      // we need to append the class to the last component.
      index = this.overlayWrapper.length - 1;
    }
    this.overlayWrapper[index].className += ' zen-dialog-wrapper ';

    // Trigger resize listener for perfect centering
    this.readjustDialogs();

    // When dialog size changes, trigger resize listener
    const observer = new ResizeObserver(entries => {
      this.readjustDialogs();
    });
    observer.observe(document.querySelector('.zen-dialog'));
  }

  ngOnDestroy() {
    this.onResizeSub.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onResizeSubject.next();
  }

  /**
   * If any dialogs are open, adjust their height to be centered yet scrollable
   */
  private readjustDialogs() {
    let openDialogContainers = document.getElementsByClassName('zen-dialog-wrapper');
    for (let i = 0; i < openDialogContainers.length; i++) {
      // Get the dialog itself
      let dialog = openDialogContainers[i].firstChild;
      // Get the dialog height
      // @ts-ignore
      let dialogHeight = dialog.scrollHeight;
      // If the height is greater than the window height, set top to 0 and scroll. Otherwise, null
      if (dialogHeight > window.innerHeight) {
        // @ts-ignore
        dialog.style.top = '0';
      } else {
        // @ts-ignore
        dialog.style.top = null;
      }
    }
  }
}
