import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UtilitiesV3Model} from '../../_models/service-address-settings';
import {UtilityAccountNumber} from '../../_models/utility-account-number';
import {catchError, map, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {ErrorService} from './error.service';
import {environment} from '../../../../../../../environments';
import {getParams} from '../../_utils/set-param-util';
import { CommodityType } from '../../_models/commodity';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private errorService: ErrorService) {
  }

  /** To get State Utilities
   * NOTE: If state param is null it will bring all Utilities.
   * @param isGasProvider - Set true to get Gas Providing Utilities
   * @param state - To get State based Utilities.
   * @param isElecProvider - To get Electricity Utilities.
   * @param isActive - To get Active Utilities.*/
  public getStateUtilitiesV3(state: string, isGasProvider: boolean,
                             isElecProvider: boolean, isActive: boolean): Observable<UtilitiesV3Model[]> {
    let httpParams = getParams({ state, isGasProvider, isElecProvider, isActive });
    const url = `${this.baseUrl}/v3/utilities`;
    return this.http.get(url, { params: httpParams })
      .pipe(
        take(1),
        map(response => response as UtilitiesV3Model[]),
        catchError(this.errorService.handleObsError)
      );
  }

  public getUtilityAccountNumbers(utilityId: number, commodity: CommodityType): Observable<UtilityAccountNumber[]> {
    let httpParams = getParams({ utilityId, commodity });
    const url = `${this.baseUrl}/v3/account-numbers`;
    return this.http.get(url, { params: httpParams })
      .pipe(
        take(1),
        map(response => response as UtilityAccountNumber[]),
        catchError(this.errorService.handleObsError)
      );
  }

}
