import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {CustomerContact} from '../../_zen-legacy-common/_models/customer';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, take} from 'rxjs/operators';
import {isDeepEqual} from '../../_utils/zen-object.util';
import {MatSelect} from '@angular/material/select';

/**
 * This component can show a flat array of options, or a nested map of options.
 *
 * For flat array, you need to pass in a FormControl to bind.
 * The flat array is an array of objects that look like:
 * {
 *  id: any,
 *  value: string,
 *  label: string,
 *  [whatever other fields]
 * }
 * Selecting an option will automatically change the form control's value, which is the entire object.
 *
 * For nested map, you need to listen to change events through the onChange event emitter.
 * The nested map has to be Map<string, string[]>, which looks like:
 * {
 *   'Real Estate': ['Commercial', 'Residential'],
 *   'Education': ['K-12', 'Higher Ed'],
 *   ...
 * }
 * Selecting an option will two-way bind to the selectedOption variable, which is a string, specifically
 * with the format of '[key]||[value item]', for example, 'Real Estate||Commercial'. It is up to the listener
 * to parse this string.
 *
 * Searching is done by toggling isSearchable to true and passing in a filterSearchFn. The filterSearchFn
 * will provide the list/map of options and the search string, and it is up to the function to return a filtered
 * list/map of options.
 *
 */
@Component({
  selector: 'app-zen-select-search-dropdown',
  templateUrl: './zen-select-search-dropdown.component.html',
  styleUrls: ['./zen-select-search-dropdown.component.scss']
})
export class ZenSelectSearchDropdownComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  // General configuration
  @Input() disabled: boolean;
  @Input() isEditable: boolean;
  @Input() isDeletable: boolean;
  @Input() isSearchable: boolean;

  // Display options
  /**
   * The select label to display
   */
  @Input() label: string;
  /**
   * The text that shows when there's an option with value of null; is a "new value" option
   */
  @Input() newOptLabel: string;
  /**
   * What to show when there's no option selected
   */
  @Input() placeholder: string;
  /**
   * Show error message or not
   */
  @Input() showError = true;
  /**
   * Preselected option if any
   */
  @Input() preselectedOption: string;

  // Select options
  @Input() initialOptions: (any[] | Map<any, any[]>) = [];
  protected filteredOptions: (any[] | Map<any, any[]>); // if search is on, this is the filtered list/map to display
  @Input() disabledOptions: CustomerContact[] = []; // TODO: Abstract this away from CustomerContact
  disabledContactIds: string[] = [];
  @Input() hasSuggested = false;
  @Input() showGroupingHeaders: ShowGroupingHeadersEnum = ShowGroupingHeadersEnum.ALL;
  @Input() showSelectedGroupings: string[] = [];
  @Input() showGroupingTotals = false;

  // Binding
  @Input() formCtrl: UntypedFormControl; // for array
  protected selectedOption: string; // for map

  // Searching
  // Returns an Observable to support async server side searching in the future
  @Input() filterFn: (list: (any[] | Map<any, any[]>), searchValue: string) => Observable<(any[] | Map<any, any[]>)>;
  protected searchCtrl: UntypedFormControl = new UntypedFormControl();

  // Event emitters
  @Output() onOptionChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onOptionClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAddNew: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onEditClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDeleteClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() afterViewInit: EventEmitter<MatSelect> = new EventEmitter<MatSelect>();
  @ViewChild('matSelect') matSelect: MatSelect;

  // Subs
  private subs: Subscription[] = [];
  matTrigger: {id: (number | string), value: (string | number), label: string, icon?: string};

  constructor() {
  }

  get isOptionsArray(): boolean {
    return Array.isArray(this.initialOptions);
  }

  ngOnInit(): void {
    this.resetOptionList();
    if (this.preselectedOption) {
      this.selectedOption = this.preselectedOption;
    }

    // If searchable, listen to search input changes
    if (this.isSearchable) {
      this.subs.push(
        this.searchCtrl.valueChanges.pipe(debounceTime(250)).subscribe((value) => {
          if (value) {
            this.filterFn(this.initialOptions, value).pipe(take(1)).subscribe(filtered => {
              this.filteredOptions = filtered;
            });
          } else {
            this.resetOptionList();
          }
          this.scrollSelectToTop();
        })
      );
    }

    if (this.formCtrl) {
      this.formCtrl.valueChanges.subscribe(() => this.setMatTriggerVal());
      this.setMatTriggerVal();
      setTimeout(() => this.setMatTriggerVal(), 250);
    }
  }

  ngAfterViewInit() {
    this.afterViewInit.emit(this.matSelect);
  }

  setMatTriggerVal() {
    if (this.formCtrl.value && this.isOptionsArray) {
      // @ts-ignore
      this.matTrigger = (this.initialOptions as []).find(op => op.value === this.formCtrl.value);
    }

    if (this.formCtrl.value && !this.isOptionsArray) {
      let _options = [];
      (this.initialOptions as Map<string, any[]>)?.forEach(a => _options.push(...a));
      this.matTrigger = _options?.find(op => op.value === this.formCtrl.value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // If initialOptions is the one that was changed, compare each object in initialOptions and if not equal, reset
    if (changes.initialOptions) {
      // If first time change or length changed, reset
      if (!this.isSearchable && (changes.initialOptions.isFirstChange() ||
          changes.initialOptions.previousValue.length !== changes.initialOptions.currentValue.length)) {
        this.resetOptionList();
      } else if (this.isSearchable) {
        // Do a deep object comparison for each object in initialOptions
        for (let i = 0; i < changes?.initialOptions?.previousValue?.length; i++) {
          // Compare "equivalent" objects; if not equal inside, reset
          if (!isDeepEqual(changes.initialOptions.previousValue[i], changes.initialOptions.currentValue[i])) {
            this.resetOptionList();
          }
        }
      }
    }

    if (this.preselectedOption) {
      this.selectedOption = this.preselectedOption;
    }
    this.disabledContactIds = [...this.disabledOptions.map(op => op.id)];
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  onSelectOptionChange(selected) {
    this.onOptionChange.emit(selected);
    this.disabledContactIds = [...this.disabledOptions.map(op => op.id)];
    this.resetSearch();
    this.resetOptionList();
  }

  resetSearch() {
    if (this.searchCtrl) {
      this.searchCtrl.setValue(null);
    }
  }

  scrollSelectToTop() {
    if (document.querySelector('div[role=listbox]')) {
      document.querySelector('div[role=listbox]').scrollTop = 0;
    }
  }

  scrollToActiveElement() {
    // If there is an active element,
    if (document.querySelector('mat-option.mdc-list-item--selected') && document.querySelector('div[role=listbox]')) {
      // Scroll to it
      // @ts-ignore
      document.querySelector('div[role=listbox]').scrollTop = document.querySelector('mat-option.mdc-list-item--selected').offsetTop
        - (document.querySelector('div[role=listbox]').clientHeight / 2) // Subtract half the option list height
        + (document.querySelector('mat-option.mdc-list-item--selected').clientHeight / 2) // Add half the option's height itself
    } else {
      // Otherwise scroll to top
      this.scrollSelectToTop();
    }
  }

  // Resets the filtered option list back to the initial list
  resetOptionList() {
    this.filteredOptions = this.initialOptions;
  }

  get isEditableCheck(): boolean {
    return this.isEditable && this.formCtrl.value && this.formCtrl.value.trim().toLowerCase() !== 'none';
  }

  determineGroupHeader(group: string): string {
    if (this.showGroupingHeaders === ShowGroupingHeadersEnum.ALL) {
      if (this.showGroupingTotals && this.initialOptions instanceof Map) {
        return `${group} (${this.initialOptions.get(group)?.length})`;
      } else {
        return group;
      }
    } else if (this.showGroupingHeaders === ShowGroupingHeadersEnum.SELECTED) {
      if (this.showSelectedGroupings.includes(group)) {
        if (this.showGroupingTotals && this.initialOptions instanceof Map) {
          return `${group} (${this.initialOptions.get(group)?.length})`;
        } else {
          return group;
        }
      }
    }
    return null;
  }

  // Used to keep the current order (not sorted) of the keys when traversing the initalOptions map
  keepCurrentOrder(a, b) {
    return 1;
  }
}

export enum ShowGroupingHeadersEnum {
  ALL = 'ALL',
  SELECTED = 'SELECTED', // If chosen the showSelectedGroupings array input field should be filled
  NONE = 'NONE'
}
