import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogDataModel} from '../../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {ZenDialogMsgService} from '../../../../_shared/_services/zen-dialog-msg.service';
import {ZenErrorMsgEnum} from '../../../../_shared/_enums/zen-error-msg.enum';
import {ZenTableConfig} from '../../../../_shared/_components/zen-mat-table/zen-mat-table.component';
import {SelectionModel} from '@angular/cdk/collections';
import {ZenDialogPanelClassEnum, ZenDialogSizeEnum} from '../../../../_shared/_enums/zen-dialogs.enum';
import {ZenAddressEditFormDialogComponent} from '../zen-address-edit-form-dialog/zen-address-edit-form-dialog.component';
import {PortfolioTabTopActionConfig} from '../../../../_shared/_components/zen-tab-top-action/zen-tab-top-action.component';
import {CustomerBillingAddressV4Service} from '../../../../_shared/_services/v4/customer-billing-address-v4.service';
import {CustomerBillingAddress, LenNameAndId} from '../../../../_shared/_model/customer-address-v4.model';
import {NgxPopperjsContentComponent, NgxPopperjsTriggers} from 'ngx-popperjs';
import {formatAddress} from '../../../../_shared/_zen-legacy-common/_utils/format-utils';
import {Router} from '@angular/router';
import {NavigationService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {ZenBaseWithTranslateComponent} from '../../../../_shared/_components/zen-base-with-translate/zen-base-with-translate.component';

@Component({
  selector: 'app-zen-address-management-dialog',
  templateUrl: './zen-address-management-dialog.component.html',
  styleUrls: ['./zen-address-management-dialog.component.scss']
})
export class ZenAddressManagementDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  loading: boolean;
  customerAddressData: CustomerBillingAddress[] = [];
  tableConfig: ZenTableConfig;
  selectedRows = new SelectionModel<CustomerBillingAddress>(true, []);
  tableTopConfig: PortfolioTabTopActionConfig = {};
  lenList: LenNameAndId[];
  customerId: number;

  @ViewChild('lenListPopper') lenListPopper: NgxPopperjsContentComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenAddressManagementDialogComponent>,
              private zenDialogSvc: ZenDialogMsgService,
              private dialog: MatDialog,
              private router: Router,
              private navSvc: NavigationService,
              public translateSvc: TranslateService,
              private billingAddressV4Svc: CustomerBillingAddressV4Service
              ) {
    super(translateSvc);
    this.customerId = this.data.data.customerId;
    this.loadData();
  }

  ngOnInit(): void {
    setTimeout(() => this.buildTable());
  }

  buildTable() {
    this.tableConfig = {
      cols: [
        {field: 'address1', header: 'Address 1', type: 'standard'},
        {field: 'address2', header: 'Address 2', type: 'standard'},
        {field: 'city', header: 'City', type: 'standard'},
        {field: 'state', header: this._translations?.nomenclature?.state, type: 'standard'},
        {field: 'zip', header: 'Zip', type: 'standard'},
        {
          field: 'lenCount', header: 'Associations', type: 'standard', align: 'center', hyperlink: true,
          popperContent: this.lenListPopper, popperTrigger: NgxPopperjsTriggers.click, popperApplyClass: 'xs',
          formatter: (col, rowData: CustomerBillingAddress) => rowData.lens?.length ? `${rowData.lens?.length}` : '-',
          handleHyperLinkClick: (col, rowData: CustomerBillingAddress) => {
            this.lenList = rowData.lens;
            setTimeout(() => this.lenList?.length ? this.lenListPopper.show() : this.lenListPopper.hide(), 10);
          }
        },
        {field: 'menu', type: 'menu'}
      ],
      rowMenuOptions: [
        {label: 'Edit', type: 'button', command: (rowData: CustomerBillingAddress) => this.handleAddEditAddress(rowData)},
        {label: 'Remove', type: 'button', command: (rowData: CustomerBillingAddress) => this.confirmSingleDelete(rowData)}
      ],
      tableMenuOptions: [
        {
          label: 'Remove', type: 'button', iconPosition: 'left', icon: 'link_off',
          command: () => this.confirmBulkDelete()
        }
      ]
    };

    this.tableTopConfig = {
      actionBtn: {
        label: 'Billing Address', icon: 'add', command: () => this.handleAddEditAddress()
      }
    };
  }

  loadData() {
    this.loading = true;
    this.billingAddressV4Svc.getCustomerBillingAddressById(this.data.data?.customerId, true).subscribe({
      next: res => {
        this.customerAddressData = res.map(a => {
          a.lenCount = a?.lens?.length || null;
          return a;
        });
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });
  }

  handleAddEditAddress(rowData?: CustomerBillingAddress) {
    this.dialogRef.addPanelClass(ZenDialogPanelClassEnum.HIDE_DIALOG);
    const isEditMode = rowData?.id;

    let dialogData: ZenDialogDataModel = {
      data: {customerId: this.customerId, id: rowData?.id, rowData},
      header: {title: `${isEditMode ? 'Edit' : 'Add'} Billing Address`},
      bodyHtml: `${isEditMode ? 'Edit' : 'Add'} the details below to update the billing address and to<br/>associate it to legal entities that do not yet have a billing address.`,
      onClose: () => dialogRef.close(),
      actionButtons: []
    };

    const dialogRef = this.dialog.open(ZenAddressEditFormDialogComponent, {
      width: ZenDialogSizeEnum.MEDIUM,
      panelClass: ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT,
      data: dialogData, autoFocus: false
    });

    dialogRef.afterClosed().subscribe((refresh) => {
      this.dialogRef.removePanelClass(ZenDialogPanelClassEnum.HIDE_DIALOG);
      if (refresh) {
        this.loadData();
      }
    });
  }

  confirmSingleDelete(rowData: CustomerBillingAddress) {
    let bodyHtml = ``; // W/o association

    const billingAddress = formatAddress(rowData);
    const lenString = rowData?.lens?.map(l => l.name)?.join(', ');

    if (rowData.lens?.length) { // With association
      bodyHtml = `
        You are about to remove the following Billing Address <br/> ${billingAddress} <br/> which is currently assigned to below Legal ${rowData.lens?.length === 1 ? 'Entity' : 'Entities'}
        <br/> ${lenString}.
        <br/> <br/>
        This will remove the billing address from the Legal Entity.
     `;
    } else { // W/o association
      bodyHtml = `You are about to remove the following Billing Address. <br/> ${billingAddress}`;
    }

    bodyHtml += `<br/><br/>Are your sure you want to proceed?`;

    this.zenDialogSvc.showConfirmationDialog('Bill Address Management', bodyHtml,
      () => {}, () => this.handleSingleDelete(rowData)
    );
  }

  handleSingleDelete(rowData: CustomerBillingAddress) {
    this.billingAddressV4Svc.deleteAddressById(rowData.customerId, rowData.id).subscribe({
      next: () => {
        this.zenDialogSvc.openToast(true);
        this.loadData();
      },
      error: (err) => {
        this.loading = false;
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });
  }

  confirmBulkDelete() {
    const isLenAssociated = this.selectedRows.selected.some(address => address?.lens?.length);

    let bodyHtml = ``; // W/o association
    if (isLenAssociated) { // With association
      bodyHtml = `
        You are about to remove 1 or more Billing Addresses which are currently assigned to a Legal Entity.
        This will remove the billing address from the Legal Entity.
     `;
    } else { // W/o association
      bodyHtml = `You are about to remove ${this.selectedRows.selected?.length} Billing Address.`;
    }

    bodyHtml += `<br/><br/>Are your sure you want to proceed?`;

    this.zenDialogSvc.showConfirmationDialog('Bill Address Management', bodyHtml,
      () => {}, () => this.handleBulkDelete()
    );
  }

  handleBulkDelete() {
    const addressIds = this.selectedRows.selected.map(address => address?.id);
    this.billingAddressV4Svc.bulkDeleteAddressByIds(this.customerId, addressIds).subscribe({
      next: () => {
        this.zenDialogSvc.openToast(true);
        this.selectedRows.clear();
        this.loadData();
      },
      error: (err) => {
        this.loading = false;
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });
  }

  gotoLenDetails(len: LenNameAndId) {
    this.dialogRef.close(false);
    this.router.navigate(this.navSvc.getPortfolioLenDetailsPageRoute(this.customerId, len.id))
  }
}
