import {Component, OnInit} from '@angular/core';
import {
  ButtonTypes,
  ZenDialogComponent,
  ZenDialogDataModel,
  ZenDialogDataType
} from '../../../../modules/zentility/_shared/_dialogs/zen-dialog/zen-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ZenErrorMsgEnum} from '../../../../modules/zentility/_shared/_enums/zen-error-msg.enum';
import {
  ZenSendEmailDialogComponent
} from '../../../../modules/zentility/_shared/_dialogs/zen-send-email-dialog/zen-send-email-dialog.component';
import {ZenDialogSizeEnum} from '../../../../modules/zentility/_shared/_enums/zen-dialogs.enum';
import {ZenDialogMsgService} from '../../../../modules/zentility/_shared/_services/zen-dialog-msg.service';

@Component({
  selector: 'app-zen-mat-dialogs',
  templateUrl: './zen-mat-dialogs.component.html',
  styleUrls: ['./zen-mat-dialogs.component.scss']
})
export class ZenMatDialogsComponent implements OnInit {

  constructor(public dialog: MatDialog, public dialogMsgSvc: ZenDialogMsgService) {}

  ngOnInit(): void {
  }

  confirmDialog() {
    let dialogData: ZenDialogDataModel = {
      type: ZenDialogDataType.CONFIRM,
      onClose: () => dialogRef.close(),
      header: {title: 'Portfolio Management'},
      actionButtons: [
        {label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary', command: () => dialogRef.close()}
      ],
      bodyText: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
    };
    const dialogRef = this.dialog.open(ZenDialogComponent, {
      width: ZenDialogSizeEnum.SMALL,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  openSuccessGuidance() {
    let dialogData: ZenDialogDataModel = {
      type: ZenDialogDataType.GUIDANCE_SUCCESS,
      header: {title: 'Success'},
      onClose: () => dialogRef.close(),
      actionButtons: [{label: `Don't show again`, command: () => dialogRef.close()}],
      bodyHtml: `You Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,`
    };
    const dialogRef = this.dialog.open(ZenDialogComponent, {
      width: ZenDialogSizeEnum.SMALL,
      data: dialogData, autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }

  openErrorGuidance(specific: boolean) {
    let header;
    let bodyText;
    if (specific) {
      header = {title: ZenErrorMsgEnum.ERR_MSG_2_TITLE};
      bodyText = `You cannot delete records across different hierarchies (e.g. Meters that span two different service addresses). To proceed, limit your selections to objects within the same hierarchy.`;
    } else {
      header = {title: ZenErrorMsgEnum.ERR_MSG_1_TITLE};
      bodyText = ZenErrorMsgEnum.ERR_MSG_1_TEXT;
    }
    let dialogData: ZenDialogDataModel = {
      type: ZenDialogDataType.GUIDANCE_ERROR,
      header, bodyText,
      onClose: () => dialogRef.close()
    };
    const dialogRef = this.dialog.open(ZenDialogComponent, {
      width: ZenDialogSizeEnum.SMALL,
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

  openSendEmailDialog() {
    const dialogData: ZenDialogDataModel = {
      header: {title: 'Send LOE/MLOA', icon: 'send'},
      onClose: () => dialogRef.close(),
      actionButtons: [
        {
          label: 'Call To Action', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
          command: () => {
            dialogRef.close();
          }
        }
      ],
      bodyText: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.`
    };
    const dialogRef = this.dialog.open(ZenSendEmailDialogComponent, {
      width: ZenDialogSizeEnum.MEDIUM,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
