import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../../../../../environments";
import { BizCalendar } from "../../../_models/biz-calendar";
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BizCalendarService {
    private baseUrl = environment.apiBaseUrl;

    constructor(private http: HttpClient) { }

    getNowWithinOfficeHours(): Observable<BizCalendar> {
        return this.http.get<BizCalendar>(this.baseUrl + '/v2/biz_calendar/now').pipe(take(1));
    }

}
