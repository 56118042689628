import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {MeterStatusModel, PortfolioContractCountdownModel, SummarySnapShotModel} from '../_model/portfolio-summary.model';
import {OrganizationManagementService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/organization-management.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioSummaryService {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  getSummarySnapshot(filters?: string, riskWindowDays?: number): Observable<SummarySnapShotModel> {
    let url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/summary-snapshot?filters=${encodeURIComponent(filters)}`;
    if (riskWindowDays) {
      url += `&riskWindowDays=${riskWindowDays}`;
    }
    return this.http.get<SummarySnapShotModel>(url).pipe(take(1));
  }

  getMeterStatus(filters?: string, riskWindowDays?: number): Observable<MeterStatusModel> {
    let url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/contract-snapshot?filters=${encodeURIComponent(filters)}`;
    if (riskWindowDays) {
      url += `&riskWindowDays=${riskWindowDays}`;
    }
    return this.http.get<MeterStatusModel>(url).pipe(take(1));
  }

  getContractCountdown(outsideRiskWindowDays?: number, filters?: string): Observable<PortfolioContractCountdownModel> {
  const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/contract-countdown?outsideRiskWindowDays=${outsideRiskWindowDays}&filters=${encodeURIComponent(filters)}`;
    return this.http.get<PortfolioContractCountdownModel>(url).pipe(take(1));
  }
}
