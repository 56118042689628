<div class="zen-toast-help">
  <div class="zen-toast-main">
    <div class="message-container">
      <i class="material-icons close-icon" (click)="snackBarRef.dismiss()">close</i>
      <div [innerHTML]="data.message"></div>
    </div>
    <div class="img-container">
      <app-jen-zen-img [imgType]="JenZenImgType.LARGE"></app-jen-zen-img>
    </div>
  </div>
</div>
