import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';

// NOTE: Removed AutoUnsubscribe because its causing ObjectUnsubscribedError
// checkProperties -> true if you want to unsubscribe from subscriptions serviceAddresses automatically
@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-zen-base',
  templateUrl: './zen-base.component.html',
  styleUrls: ['./zen-base.component.scss']
})
export class ZenBaseComponent implements OnInit, OnDestroy {

  constructor() {
  }

  ngOnInit(): void {
  }

  // This method must be present, even if empty.
  ngOnDestroy() {
    // We'll throw an error if it doesn't
  }

}
