import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZenTacDialogComponent } from './zen-tac-dialog/zen-tac-dialog.component';
import { ZenTacTextsComponent } from './zen-tac-texts/zen-tac-texts.component';
import {SharedModule} from '../../_shared/shared.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [
    ZenTacDialogComponent,
    ZenTacTextsComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        MatDialogModule,
        MatButtonModule,
        TranslateModule
    ],
  exports: [
    ZenTacDialogComponent
  ]
})
export class ZenTacModule { }
