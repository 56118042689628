import {EventEmitter, Injectable} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {
  DataFlowChildrenDataTableModified,
  DataFlowHierarchyCategoryDetailsTableModifiedData,
  DataFlowHierarchyGroupedV4Model
} from '../../../_shared/_model/data-flow-hierarchy-v4.model';
import {BulkImportV4Model, ZenBulkImportsListV4Model} from '../../../_shared/_model/bulk-import-v4.model';
import {ZenExpansionTableData} from '../../../_shared/_components/zen-mat-table-with-expansion/zen-mat-table-with-expansion.component';
import {ZenErrorMsgEnum} from '../../../_shared/_enums/zen-error-msg.enum';
import {CustomerServiceV4} from '../../../_shared/_services/v4/customer-v4.service';
import {ZenDialogMsgService} from '../../../_shared/_services/zen-dialog-msg.service';
import {ZenHierarchicalGrouping} from '../../../_shared/_enums/zen-hierarchical-grouping.enum';
import {ZenBulkImportTableHelperService} from '../../../_shared/_services/helpers/zen-bulk-import-table-helper.service';
import {PaginationResponse} from '@datorama/akita';
import {ZenDialogDataModel} from '../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {ZenIconsEnum} from '../../../_shared/_enums/zen-icons.enum';
import {ZenBulkImportErrorDialogComponent} from '../_dialogs/zen-bulk-import-error-dialog/zen-bulk-import-error-dialog.component';
import {ZenDialogSizeEnum} from '../../../_shared/_enums/zen-dialogs.enum';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {CommodityType} from '../../../_shared/_zen-legacy-common/_models/commodity';
import {RateCheckRequestV4FlowModel,} from '../../../_shared/_model/rate-check-request-v4.model';


@Injectable({
  providedIn: 'root'
})
export class ZenCustomerBulkImportHelperService {
  loading: boolean;
  step = 0;

  // Step 1
  selectedCommodity = new UntypedFormControl();
  customerId: number;
  documentCtrl = new UntypedFormControl(null, [Validators.required]);
  allowDraftContracts = new UntypedFormControl();
  documentName: string;
  storedFile: File;
  errors: string[] = [];

  // Step 2
  bulkImportId: string;
  groupedRawData: DataFlowHierarchyGroupedV4Model[] = [];
  groupedTableData: ZenExpansionTableData<DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData>[] = [];
  stateList: string[] = [];

  dataLoaded = new EventEmitter();

  zoneColName = 'zoneName';
  rateClsColName = 'rateScheduleCode';

  // Imports list table
  importListData: PaginationResponse<ZenBulkImportsListV4Model>;
  selectedTabIndex = 0;
  recentlyAddedIndex = 0;

  reloadImportList = new EventEmitter();

  importSummary: BulkImportV4Model;

  // The below variable is used to collect the RC informations. Helps to integrate customers-bulk-imports component.
  rateCheckFlowDetails: RateCheckRequestV4FlowModel;
  runRcWithMetersCallback: Function;

  constructor(private customerV4Svc: CustomerServiceV4, private zenDialogSvc: ZenDialogMsgService,
              private zenBulkImportTableHelperSvc: ZenBulkImportTableHelperService,
              private router: Router, private route: ActivatedRoute,
              private dialog: MatDialog) {
    // Setting zone & rate class field col name. Used to hide cols w.r.t the service address states.
    this.zenBulkImportTableHelperSvc.zoneColName = this.zoneColName;
    this.zenBulkImportTableHelperSvc.rateClsColName = this.rateClsColName;
  }

  getBulkImportGroupedDataById(bulkImportId: string, groupBy = ZenHierarchicalGrouping.CONTRACT, callback?: Function) {
    this.loading = true;
    this.bulkImportId = bulkImportId;
    this.customerV4Svc.getImportGroupedDataById(this.customerId, bulkImportId, groupBy)
      .subscribe({
        next: (res: DataFlowHierarchyGroupedV4Model[]) => {
          this.modifyAndSetBulkImportData(res);
          this.loading = false;
          this.updateUrlState();
          if (callback) {
            callback(res);
          }
        },
        error: () => {
          this.loading = false;
          this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }

  getRecentlyImportedData(groupBy = ZenHierarchicalGrouping.CONTRACT, commodityType: CommodityType, callback?: Function) {
    this.loading = true;
    this.customerV4Svc.getRecentlyImportedData(this.customerId, groupBy, commodityType)
      .subscribe({
        next: (res: DataFlowHierarchyGroupedV4Model[]) => {
          this.groupedTableData = [];
          this.modifyAndSetBulkImportData(res);
          this.loading = false;
          if (callback) {
            callback();
          }
        },
        error: () => {
          this.loading = false;
          this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }

  modifyAndSetBulkImportData(data: DataFlowHierarchyGroupedV4Model[], callback?: Function) {
    this.groupedRawData = data;
    this.groupedTableData = this.zenBulkImportTableHelperSvc.getModifiedTableData(data, this.selectedCommodity.value);
    this.stateList = this.zenBulkImportTableHelperSvc.getStateList();
    this.dataLoaded.emit();
    if (callback) {
      callback();
    }
  }

  persistBulkImportDataById(allowDrafts: boolean) {
    this.loading = true;
    this.errors = [];
    this.customerV4Svc.persistImportDataById(this.customerId, this.bulkImportId, allowDrafts)
      .subscribe({
        next: (importSummary: BulkImportV4Model) => {
          this.importSummary = importSummary;

          if (importSummary?.errors?.length > 0) {
            this.errors = importSummary.errors;
            this.openImportErrorDialog();
          } else {
            // If bulk import done inside the RCF,
            // 1) Skip the Success screen and close the slide out screen.
            // 2) You should automatically add all the eligible meters to the rate check
            // 3) Import the ineligible meters but DO NOT ADD them to the Rate Check.
            if (this.runRcWithMetersCallback) {
              this.runRcWithMetersCallback(importSummary);
            } else {
              // Customer bulk import flow
              this.step = 2;
            }

            this.reloadImportList.emit(true);
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }

  openImportErrorDialog() {
    let dialogData: ZenDialogDataModel = {
      header: {title: 'Error Preview', icon: ZenIconsEnum.SETTINGS},
      onClose: () => dialogRef.close(),
      data: {errors: this.errors}
    };

    const dialogRef = this.dialog.open(ZenBulkImportErrorDialogComponent, {
      width: ZenDialogSizeEnum.EXTRA_MEDIUM,
      data: dialogData, autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  reset(selectedTabIndex = 0) {
    this.step = 0;
    this.allowDraftContracts.setValue(false);
    // Step 1
    if (!this.runRcWithMetersCallback) { // Don't reset commodity if its in the RC flow.
      this.selectedCommodity.reset();
    }
    this.documentCtrl.reset();
    this.documentName = null;
    this.storedFile = null;
    this.errors = [];

    // Step 2
    this.bulkImportId = null;
    this.updateUrlState(); // To clear bulkImportId from the URL

    this.groupedRawData = [];
    this.groupedTableData = [];

    this.importSummary = null;

    // this.rateCheckFlowDetails = null; // This variable is cleared from through runRcWithMetersCallback()


    // Resume bulk import table
    setTimeout(() => {
        this.selectedTabIndex = selectedTabIndex;
      });
  }

  updateUrlState() {
    let queryParams = {bulkImportId: this.bulkImportId}
    this.router.navigate([], {relativeTo: this.route, queryParams, queryParamsHandling: 'merge'});
  }


  /** The below variable is used to collect the RC informations. Helps to integrate customers-bulk-imports component. */
  initRateCheckInfo(rateCheck: RateCheckRequestV4FlowModel, callback: Function) {
    this.rateCheckFlowDetails = {...rateCheck};
    this.selectedCommodity.setValue(rateCheck?.commodityType, {emitEvent: false});
    this.runRcWithMetersCallback = callback;
  }

}
