<form class="pf-platform-logo">
  <div class="logo-wrapper mb-3">
    <div class="small-logo-sec">
      <div class="form-group">
        <div class="d-flex align-items-center">
          <label>Small Logo</label>
          <i class="material-icons-outlined help-outline ms-2"
             [popper]="smallLogoDfn" [popperHideOnScroll]="true" [popperHideOnClickOutside]="true"
             popperTrigger="click" popperApplyClass="sm" popperPlacement="bottom">info</i>
        </div>
        <app-portfolio-image-uploader type="sm" (onUpload)="uploadSmallLogo($event)"
                                      [loading]="smallLogo.loading"
                                      [imageUrl]="smallLogo.imagePath"></app-portfolio-image-uploader>
      </div>
    </div>
    <div class="full-logo-sec w-100">
      <div class="form-group">
        <div class="d-flex align-items-center">
          <label>Full Logo</label>
          <i class="material-icons-outlined help-outline ms-2"
             [popper]="fullLogoDfn" [popperHideOnScroll]="true" [popperHideOnClickOutside]="true"
             popperTrigger="click" popperApplyClass="sm" popperPlacement="bottom">info</i>
        </div>
        <app-portfolio-image-uploader type="full" (onUpload)="uploadFullLogo($event)"
                                      [loading]="fullLogo.loading"
                                      [imageUrl]="fullLogo.imagePath"></app-portfolio-image-uploader>
      </div>
    </div>
  </div>
</form>

<popper-content #fullLogoDfn>
  <app-zen-popper [closeable]="true" [popperContent]="fullLogoDfn">
    <p class="card-title">Full Logo</p>
    <p class="mb-0">Requires a minimum of 150px height. We recommend using a high quality png image with a transparent
      background.</p>
  </app-zen-popper>
</popper-content>
<popper-content #smallLogoDfn>
  <app-zen-popper [closeable]="true" [popperContent]="smallLogoDfn">
    <p class="card-title">Small Logo</p>
    <p class="mb-0">Requires an image with a 1:1 aspect ratio and 25% padding around the image. We recommend a high
      quality png image with a transparent background.</p>
  </app-zen-popper>
</popper-content>
