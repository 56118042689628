<div class="zen-mat-table-actions">
  <div class="selected action">
    <span class="label no-wrap">({{selection.selected.length}}) Selected</span>
  </div>

  <div *ngFor="let menu of actions" class="action">
    <div (click)="menu.command()" mat-menu-item class="">
      <mat-slide-toggle *ngIf="menu.type === 'toggle' && menu.field" class="mt-1"
                        [(ngModel)]="menu.toggleDefaultVal"></mat-slide-toggle>

      <span class="material-symbols-rounded action-icon" *ngIf="menu?.iconPosition === 'left'">{{menu?.icon}}</span>
      <span class="label">{{menu?.label}}</span>
      <span class="material-symbols-rounded action-icon" *ngIf="menu?.iconPosition !== 'left'">{{menu?.icon}}</span>
    </div>
  </div>
</div>
