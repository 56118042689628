import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-zen-loader',
  templateUrl: './zen-loader.component.html',
  styleUrls: ['./zen-loader.component.scss']
})
export class ZenLoaderComponent {

  /**
   * Loader displays as position absolute by default, which expands to container height
   * Set fixed to true to display as position fixed, which is fixed to screen height
   */
  @Input() fixed = false;
  constructor() { }

}
