export enum PortfolioTabsEnum {
  CUSTOMERS = 'customers', LENS = 'lens', SERVICE_ADDRESSES = 'serviceAddresses', METERS = 'meters'
}

export enum DeleteObjectType {
  CUSTOMER = 'customer', LEN = 'legal entity name', SERVICE_ADDRESS = 'service address', METER = 'meter', BILL = 'bill'
}

export enum ChangeStatusObjectType {
  CUSTOMER = 'customer', LEN = 'legal entity name', SERVICE_ADDRESS = 'service address', METER = 'meter'
}

export enum ActivationObjectType {
  CUSTOMER = 'customer', LEN = 'legal entity name', SERVICE_ADDRESS = 'service address'
}
/**
 * For the sections on details page e.g. Portfolio, Rate Checks, Contracts
 * Maps enum to index
 */
export enum PortfolioSectionsEnum {
  PORTFOLIO = 'Portfolio', RATE_CHECKS = 'Rate Checks', CONTRACTS = 'Contracts', DASHBOARD = 'Dashboard',
  CONTACTS = 'Users', STACKRANKING = 'Stack Ranking', BULK_IMPORT = 'Bulk Import', OPPORTUNITIES = 'Opportunities'
}
