import {Component, Input} from '@angular/core';

export interface ZenNoDataConfig {
  title: string;
  bodyText: string;
  action: {
    icon?: string;
    btnLabel: string;
    command: () => void;
  };
  footerAction?: {
    linkLabel: string;
    command: () => void;
  };
}

@Component({
  selector: 'app-zen-no-data-section',
  templateUrl: './zen-no-data-section.component.html',
  styleUrls: ['./zen-no-data-section.component.scss']
})
export class ZenNoDataSectionComponent {
  @Input() config: ZenNoDataConfig;

  constructor() { }

}
