<div class="activation-form-dialog" [formGroup]="form" *ngIf="form">
  <div class="form-group">
    <mat-form-field appearance="outline">
      <mat-label>Reason</mat-label>
      <mat-select formControlName="reasonId">
        <mat-option *ngFor="let op of reasonOptions" [value]="op.key">{{op.value}}</mat-option>
      </mat-select>
      <i class="material-symbols-rounded" matSuffix>expand_more</i>
      <mat-error *ngIf="hasError('reasonId', 'required')">Required Field*</mat-error>
    </mat-form-field>
  </div>

  <ng-container *ngIf="form.controls.reasonId.value === ActivationReasonIdEnum.SOLD">

    <div class="row align-items-center">
      <div class="col-12 col-md-6">
        <div class="form-group text-start">
          <mat-checkbox formControlName="assumption">Contract assumed</mat-checkbox>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="form-group" *ngIf="form.controls.assumption.value">
          <mat-form-field appearance="outline">
            <mat-label>Assumption Date*</mat-label>
            <input matInput [matDatepicker]="dp" readonly (click)="dp.open()" formControlName="assumptionDt">
            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="year"></mat-datepicker>
            <mat-error *ngIf="hasError('assumptionDt', 'matDatepickerMin')">Invalid Date</mat-error>
            <mat-error *ngIf="hasError('assumptionDt', 'required')">Required Field*</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="form-group mb-4">
    <mat-form-field appearance="outline" class="zen-mat-textarea">
      <mat-label>Additional Notes</mat-label>
      <textarea matInput formControlName="reasonCustom"></textarea>
    </mat-form-field>
    <mat-error *ngIf="hasError('reasonCustom', 'maxlength') else matHint">
      Maximum allowed characters exceeded.
    </mat-error>
    <ng-template #matHint>
      <mat-hint>Max 100 Characters</mat-hint>
    </ng-template>
  </div>

  <div *ngIf="disabledByParent" class="parent-disabled-msg">The deactivation was initiated at the {{parentName}} level. To make changes, navigate to the parent {{parentName}} where it was originally deactivated.</div>

</div>
