<app-zen-side-flyout *ngIf="showFlyout" (onShow)="onShow()" (onHide)="onShow()"
                     [header]="title" [infoPopper]="infoPopper">

  <div class="filter-flyout">

    <div class="mat-tree-wrapper">
      <div class="ff-spacer">
        <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl">
          <!-- This is the tree node template for leaf nodes -->
          <!-- There is inline padding applied to this node using styles.
            This padding value depends on the mat-icon-button width. -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <ng-container *ngIf="!node.parent || node.children?.length">
              <!-- we need some indeterminate in here when nested comes around. TODO-->
              <mat-checkbox [disabled]="node.disabled" [checked]="isSelected(node)"
                            (change)="handleCheckboxClicked(node)">{{node.label}}</mat-checkbox>
            </ng-container>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" class="mb-3">
            <div class="mat-tree-node" *ngIf="node.children">
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'Toggle ' + node.label">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              {{node.label}}
            </div>
            <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
            <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                 role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
    </div>

    <div class="filter-flyout-footer">
      <div class="d-flex justify-content-between">
        <button mat-button (click)="clearSelections()">Clear Selections</button>
        <button mat-raised-button color="accent" class="float-md-right" (click)="submitSelections()">
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</app-zen-side-flyout>

<popper-content class="zen-dialog-popper" #infoPopper>
  <app-zen-popper [title]="title" [closeable]="true" [popperContent]="infoPopper">
    <p>{{popperText}}</p>
  </app-zen-popper>
</popper-content>
