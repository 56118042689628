import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileData } from '../../../_models/file-data';
import { environment } from '../../../../../../../../environments';
import { DownloadService } from '../download.service';
import {Document} from '../../../_models/app-rate-check-v2';
import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
/**
 *
 */
export interface TemporaryFile {
  contentType: string;
  createdAt: string;
  fileName: string;
  id: string;
  internalStoragePath: string;
  ownerId: string;
  updatedAt: string;
}

export interface DocumentCreation {
  name: string;
  description: string;
  documentType: 'loe'|'autosign'|'loa';
  customerId: number;
}

@Injectable({
  providedIn: 'root'
})
export class TemporaryStorageService {

  constructor(private http: HttpClient,
    private downloadService: DownloadService) { }

  /**
   * upload a temporary file that we will later
   * attach to some form
   * @param file the file to upload
   */
   uploadTemporaryFile(file: FileData): Observable<TemporaryFile> {
    let formData = new FormData();
    formData.append('file', file.fileRef);

    const url = `${environment.apiBaseUrl}/v2/temporary-files`;
    return this.http.post<TemporaryFile>(url, formData);
  }

  async uploadTempFile(file: File): Promise<TemporaryFile> {
    let formData = new FormData();
    formData.append('file', file);

    const url = `${environment.apiBaseUrl}/v2/temporary-files`;
    return this.http.post<TemporaryFile>(url, formData).toPromise();
  }

  async getFileMetadata(fileId: string): Promise<TemporaryFile> {
    const url = `${environment.apiBaseUrl}/v2/temporary-files/${fileId}`;
    return this.http.get<TemporaryFile>(url).toPromise();

  }

  async download(fileId: string): Promise<void> {
    const f = await this.getFileMetadata(fileId);
    const url = `${environment.apiBaseUrl}/v2/temporary-files/${fileId}/file`;
    return this.downloadService.downloadFile(url, f.fileName, f.contentType);
  }

  downloadDocument(customerId: number, documentId: number, fileName: string) {
    const url = `${environment.apiBaseUrl}/v1/customers/${customerId}/documents/${documentId}/file`;
    return this.http.get(url, {responseType: 'blob'}).pipe(take(1), map(res => {
      let data = res;
      const pdfUrl = URL.createObjectURL(data);
      const anchor: any = document.createElement('a');
      anchor.download = fileName;
      anchor.href = pdfUrl;
      anchor.click();
    }));
  }

  async delete(fileId: string): Promise<void> {
    const url = `${environment.apiBaseUrl}/v2/temporary-files/${fileId}`;
    return this.http.delete<void>(url).toPromise();
  }

  async createDocument(fileId: string, create: DocumentCreation): Promise<Document> {
    const url = `${environment.apiBaseUrl}/v2/temporary-files/${fileId}/create-document`;
    return this.http.post<Document>(url, create).toPromise();
  }
}
