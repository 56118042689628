<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" [errorMsg]="errorMsg" [disableButton]="loading"
  [loading]="loading">
  <div class="px-4">
  <div class="dialog-form-sec with-border">
    <div class="zen-mat-form zen-customer-contact-form">
      <div class="d-flex justify-content-center">
        <div class="justify-content-center">
          <div class="d-flex justify-content-start">
            <p class="form-title mb-3">Profile Details</p>
          </div>

          <!-- Contact form -->
          <div class="row mt-1 text-start" [formGroup]="form">
            <div class="col-12 col-sm-6 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>First Name*</mat-label>
                <input matInput formControlName="firstName">
                <mat-error *ngIf="hasError('firstName','required')">Required Field*</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Last Name*</mat-label>
                <input matInput formControlName="lastName">
                <mat-error *ngIf="hasError('lastName','required')">Required Field*</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Title*</mat-label>
                <input matInput formControlName="title">
                <mat-error *ngIf="hasError('title','required')">Required Field*</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Email*</mat-label>
                <input matInput formControlName="email" [imask]="NoSpacesMask" [unmask]="unmask">
                <mat-error *ngIf="hasError('email','required')">Required Field*</mat-error>
                <mat-error *ngIf="hasError('email','pattern')">Invalid Format*</mat-error>
                <mat-error *ngIf="hasError('email', 'existentAccount')">Email already in use*</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Phone*</mat-label>
                <input matInput formControlName="phone" [imask]="i18nMask.phone" [unmask]="i18nMask.phoneUnmask">
                <mat-error *ngIf="hasError('phone', 'required')">Required Field*</mat-error>
                <mat-error *ngIf="hasError('phone', 'minlength')
                  || hasError('phone', 'maxlength')">Invalid Format*</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 form-group password-field">
              <app-zen-password-field [formGroup]="form" formCtrlName="password" placeHolder="Password"
                                      label="Password" [passwordConfig]="passwordConfig"></app-zen-password-field>
            </div>
            <div class="col-12 col-sm-6 form-group">
              <app-zen-password-field [formGroup]="form" formCtrlName="confirmPassword" [feedback]="false"
                                      label="Confirm Password" placeHolder="Confirm Password"></app-zen-password-field>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</app-zen-dialog-wrapper>
