export class Theme {
    name: string;
    companyName: string;
    baseDomain: string;
    getThemeName() {
      switch (this.name) {
        case 'zen-default-theme':
          return ThemeName.ZENTILITY;
        case 'zen-cbre-theme':
          return ThemeName.CBRE;
        case 'zen-patriot-theme':
          return ThemeName.PATRIOT;
        case 'zen-tili-theme':
          return ThemeName.TILI;
        default:
          return null;
      }
    };
}

export enum ThemeName {
  ZENTILITY = <any> 'zen-default-theme',
  CBRE = <any> 'zen-cbre-theme',
  PATRIOT = <any> 'zen-patriot-theme',
  TILI = <any> 'zen-tili-theme'
}

export enum Variant {
  DARK = <any> 'dark',
  DEFAULT = <any> 'default',
  LIGHT = <any> 'light'
}
