import {FeeError} from '../../_modules/zen-market-pulse/_services/matrix-pricing-helper.service';
import {RateCheckFeeDetails} from '../_model/rate-check-v4.model';

/**
 * Used to validate fees. Don't forger to include minFeeDollarsLimit & maxFeeDollarsLimit in the feeDetails.
 * @param feeDetails
 */
export function feeErrorCheck(feeDetails: RateCheckFeeDetails): FeeError {
  let feeError: FeeError = {hasError: false};
  const _totalFee = feeDetails?.totalFee;
  const _maxFeeMilsLimit = (feeDetails?.maxFeeDollarsLimit);
  const _minFeeMilsLimit = (feeDetails?.minFeeDollarsLimit);
  if (_totalFee > _maxFeeMilsLimit) {
    feeError.valueHigh = true;
    feeError.hasError = true;
  } else if (_totalFee < 0) {
    feeError.belowZero = true;
    feeError.hasError = true;
  } else if (_totalFee < _minFeeMilsLimit) {
    feeError.valueLow = true;
    feeError.hasError = true;
  }
  return feeError;
}
