<ng-container *ngIf="zenBcSvc.config">
  <div class="zen-breadcrumb-main desktop-page-title" (click)="zenBcSvc.handleTitleClick()">
    <div class="zen-breadcrumb-item" [matMenuTriggerFor]="breadcrumbMenu"
         (mouseover)="hovered=zenBcSvc.config?.breadcrumbs?.length>0" (mouseout)="hovered=false">
      <i class="material-icons">{{hovered ? 'keyboard_arrow_down' : 'keyboard_arrow_left'}}</i>
      <p>{{zenBcSvc.config?.pageName || '-'}}</p>
    </div>
  </div>

  <mat-menu #breadcrumbMenu="matMenu" class="zen-breadcrumb-main">
    <ng-container *ngFor="let bread of zenBcSvc.config.breadcrumbs">
      <div mat-menu-item class="zen-breadcrumb-item" [ngClass]="{current: bread.currentPage}"
           (click)="zenBcSvc.handleBcClick(bread)">
        <i class="material-icons current-icon">{{bread.currentPage ? 'done' : ''}}</i>
        <i class="material-icons">{{bread.icon}}</i>
        <p class="m-0">{{bread.title || '-'}}</p>
      </div>
    </ng-container>
  </mat-menu>
</ng-container>
