<div class="zen-unit-conversion-input-main" *ngIf="mask">
  <mat-form-field appearance="outline" [ngClass]="{'left-input': !isBaseUnitSelected}">
    <mat-label>{{label}}</mat-label>
    <input matInput [imask]="mask" [unmask]="true" [formControl]="formCtrl">
  </mat-form-field>
  <i class="material-symbols-rounded swap-icon" [hidden]="isBaseUnitSelected">swap_horiz</i>
  <mat-form-field appearance="outline" class="right-input" [hidden]="isBaseUnitSelected">
    <input matInput [imask]="mask" [unmask]="true" [formControl]="convertedValueCtrl" readonly>
  </mat-form-field>
</div>

<div *ngIf="!hideUnitSelection" class="mb-1 ms-2 d-flex align-items-center justify-content-between flex-wrap-reverse">
  <mat-chip-listbox (change)="$event.value ? handleUnitChange($event.value) : null">
    <mat-chip-option class="select-chip" *ngFor="let op of unitOptions"
              [class.selected]="selectedConversionUnit.value === op.value">
      {{op.label}}
    </mat-chip-option>
  </mat-chip-listbox>

  <div class="d-flex justify-content-end" [class.w-100]="_translations?.id === CountryEnum.CA && commodity === CommodityType.Gas">
    <mat-chip-listbox class="pointer-events-none">
      <mat-chip-option class="select-chip">Platform default {{baseUnit}}</mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>
