<div class="zen-procurement-snapshot" [ngClass]="mode">
  <small class="label">{{firstContractLabel}}</small>
  <span *ngIf="isDraft && showLinkLabels" class="ms-3 blue-link" (click)="handleFinalizeDraftContract()">(Finalize Contract)</span>
  <span *ngIf="isUndefined && showLinkLabels" class="ms-3 blue-link" (click)="handleAddContract()">(Add Contract)</span>
  <div class="d-flex align-items-baseline position-relative">
    <ng-container *ngFor="let data of modifiedTimelineData; let index=index;">

      <!-- DEV-9100 - Procurement Snapshot Hover -->
      <!-- Figma reference: https://www.figma.com/file/fkBFaMD4P9PIfpMReWL8eO?node-id=7745:42900&mode=design#303071022 -->
      <!-- 1) Timeline Duration Rule: -->
      <!--   - Timeline to show current month + 36 more months (total of 36) -->
      <!-- 2) Default view rule -->
      <!--   - Widget’s default mode when procurement status = defined -->
      <!--   default state when entering page - show only the details associated with the current status. all segments - same size -->
      <!--   - Shows all statuses up through the 36-mon window. this is different from what will be seen when managing the snapshot where you might have visibility beyond min time horizon of 36 months. -->
      <!-- 3) Hover Rule: -->
      <!--   - On Hover - show details of segment being hovered upon and show segment larger -->
      <!--   - Off hover - when users moves off the segment, defaults back to current status. Segment gets smaller -->
      <!-- 4) Click Rule -->
      <!--   - On click - clicked segment shows larger and data changes to reflect that state -->
      <!--   - On double click - segment gets smaller and data reverts back to default mode which is showing today’s procurement status. nuance - if double clicked but still hovering over the data will reflect the hover -->
      <!-- 5) Click through Rule: -->
      <!--   - Remove “view” everywhere and, instead, present carrot to push users to contract details page (see example below) -->
      <!--     when hovered over, the entire row becomes hyperlinked -->
      <!-- 6) Utility Rule -->
      <!--   - when on utility present name of utility -->

      <div [style.width]="data.percent" class="timeline" [ngClass]="data.statusCls"
           [class.clicked]="clicked"
           [class.selected-segment-by-click]="clicked && index === selectedIndex"
           (click)="clicked = true; handleMouseOver(index)"
           (dblclick)="clicked = false"
           (mouseover)="clicked || handleMouseOver(index)" (mouseout)="onHover.emit(null)"
           [popper]="dateInfo" [popperHideOnMouseLeave]="false" [popperHideOnClickOutside]="true"
           [popperDisabled]="!enableTooltip" popperTrigger="hover" popperPlacement="top"></div>
      <popper-content #dateInfo>
        <p class="m-0 px-4 py-3 text-md" style="min-width: 20rem;" *ngIf="timelines[selectedIndex]">
          {{tooltipDates?.startDate}} - {{tooltipDates?.endDate}}
        </p>
      </popper-content>

    </ng-container>
  </div>
</div>
