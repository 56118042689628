import {Store, StoreConfig} from '@datorama/akita';
import {RouterQuery} from '@datorama/akita-ng-router-store';
import {Injectable} from '@angular/core';
import {delay} from 'rxjs/operators';

export interface CustomerIdSessionState {
  customerId: number;
}

export function createInitialState(): CustomerIdSessionState {
  return {
    customerId: null
  };
}

@Injectable()
@StoreConfig({name: 'customerId', resettable: true})
export class CustomerIdSessionStore extends Store<CustomerIdSessionState> {
  constructor(private routerQuery: RouterQuery) {
    super(createInitialState());
    // Setting customerId from the params for advisorUser
    // delay(0) -> is important to make subscription before all subs.
    routerQuery.selectParams('customerId').pipe(delay(0))
      .subscribe(_paramId => {
        if (_paramId) {
          this.update({customerId: _paramId});
        }
      });
  }

}
