import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './modules/zentility/_shared/_zen-legacy-common/_guards/auth.guard';
import {ZenRoutesEnum} from './modules/zentility/_shared/_enums/zen-routes.enum';


const routes: Routes = [
  {path: '', loadChildren: () => import('./modules/zentility/_modules/zen-login/zen-login.module').then(mod => mod.ZenLoginModule)},
  {path: '404', loadChildren: () => import('./modules/404/org-not-found/org-not-found.module').then(mod => mod.OrgNotFoundModule)},
  {path: ZenRoutesEnum.ADVISOR + '/signup', loadChildren: () => import('./modules/404/page-not-found/page-not-found.module').then(mod => mod.PageNotFoundModule)},
  {
    path: '',
    loadChildren: () => import('./modules/zentility/_modules/zen-forgot-password/zen-forgot-password.module').then(mod => mod.ZenForgotPasswordModule)
  },
  {path: 'signup', redirectTo: '/' + ZenRoutesEnum.CUSTOMER + '/signup'},
  {
    path: 'external-widgets',
    loadChildren: () => import('./modules/external-widgets/external-widgets.module').then(mod => mod.ExternalWidgetsModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/zentility/zentility.module').then(mod => mod.ZentilityModule)
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})] // canceledNavigationResolution: 'replace', 
})
export class AppRoutingModule {
}
