import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {AddressAutocompleteService} from '../../../../_shared/_services/address-autocomplete.services';
import {zenHasError} from '../../../../_shared/_utils/zen-has-error.util';
import {ItemListV4Model} from '../../../../_shared/_model/item-list-v4.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  ButtonActionTypes,
  ButtonTypes,
  ZenDialogActionButton,
  ZenDialogDataModel
} from '../../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {ZenDialogMsgService} from '../../../../_shared/_services/zen-dialog-msg.service';
import {ZenErrorMsgEnum} from '../../../../_shared/_enums/zen-error-msg.enum';
import {Observable} from 'rxjs';
import {CustomerBillingAddress} from '../../../../_shared/_model/customer-address-v4.model';
import {CustomerBillingAddressV4Service} from '../../../../_shared/_services/v4/customer-billing-address-v4.service';
import {CustomerService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/customer.service';
import {ItemListV4Service} from '../../../../_shared/_services/v4/item-list-v4.service';
import {LenAddressFilter} from '../../../../_shared/_enums/zen-len-address-filter.enum';
import {StateV4Service} from '../../../../_shared/_services/v4/state-v4.service';
import {TranslateService} from '@ngx-translate/core';
import {ZenBaseWithTranslateComponent} from '../../../../_shared/_components/zen-base-with-translate/zen-base-with-translate.component';

@Component({
  selector: 'app-zen-address-edit-form-dialog',
  templateUrl: './zen-address-edit-form-dialog.component.html',
  styleUrls: ['./zen-address-edit-form-dialog.component.scss']
})
export class ZenAddressEditFormDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  form: UntypedFormGroup;
  appearance: MatFormFieldAppearance = 'outline';
  stateOptions: ItemListV4Model[] = [];
  customerId: number;
  isEditMode: boolean;
  loading: boolean;
  rowData: CustomerBillingAddress;

  showLenList = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenAddressEditFormDialogComponent>,
              private zenDialogSvc: ZenDialogMsgService,
              private formBuilder: UntypedFormBuilder,
              private customerSvc: CustomerService,
              private itemListV4Svc: ItemListV4Service,
              private stateV4Svc: StateV4Service,
              private billingAddressV4Svc: CustomerBillingAddressV4Service,
              public addressAutocompleteService: AddressAutocompleteService,
              public translateSvc: TranslateService,
  ) {
    super(translateSvc);
    this.isEditMode = this.data.data?.id;
    this.rowData = this.data.data?.rowData;
    this.customerId = this.data.data?.customerId;

    this.loadForm();
  }

  ngOnInit(): void {
  }

  loadForm(): void {
    this.form = this.formBuilder.group({
      customerId: new UntypedFormControl(this.customerId, [Validators.required]),
      address1: new UntypedFormControl('', [Validators.required]),
      address2: new UntypedFormControl(''),
      city: new UntypedFormControl('', [Validators.required]),
      state: new UntypedFormControl('', [Validators.required]),
      zip: new UntypedFormControl('', [Validators.required]),
      lens: new UntypedFormControl(''),
      withoutAddress: new UntypedFormControl(true)
    });

    this.stateV4Svc.getAllCountryStates().subscribe({
      next: states => {
        this.stateOptions = states;
      },
      error: err => {
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });

    if (this.isEditMode) {
      this.form.patchValue(this.rowData);
      const itemList: ItemListV4Model[] = this.rowData.lens?.map(l => ({key: l.id, value: l.name}));
      this.form.controls.lens.setValue(itemList);
    }

    this.data.actionButtons = [
      {label: 'Cancel', btnType: ButtonTypes.MAT_BUTTON, actionType: ButtonActionTypes.CANCEL},
      {label: 'Save', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'accent'}
    ];

    this.form.controls.withoutAddress.valueChanges.subscribe(() => {
      this.refreshLenList();
    });
  }

  refreshLenList() {
    setTimeout(() => this.showLenList = false);
    setTimeout(() => this.showLenList = true);
  }

  customerLenItemListFilterObs(search: string): Observable<ItemListV4Model[]> {
    if (this.customerId) {
      const lenAddressFilter = this.form.controls.withoutAddress.value ? LenAddressFilter.WITHOUT_ADDRESS : LenAddressFilter.ALL;
      const existingLenIds = this.rowData?.lens?.length ? this.rowData?.lens?.map(l => l?.id) : null;
      return this.itemListV4Svc.getCustomerLenItemList(this.customerId, existingLenIds,
        search, true, 200, false, lenAddressFilter, true);
    }
  }

  addressHasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }

  submit(btn: ZenDialogActionButton) {
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      this.dialogRef.close(false);
    } else {
      this.form.markAllAsTouched();

      const _formVal = {
        ...this.form.value,
        lens: this.form.value.lens?.length ? this.form.value.lens?.map(l => ({id: l.key, name: l.value})) : []
      } as CustomerBillingAddress;

      if (this.form.valid) {
        if (this.isEditMode) {
          this.handleSubmitObs(this.billingAddressV4Svc.updateCustomerBillingAddress(this.customerId, this.rowData?.id, _formVal));
        } else {
          this.handleSubmitObs(this.billingAddressV4Svc.addCustomerBillingAddress(this.customerId, _formVal));
        }
      }
    }
  }

  handleSubmitObs(obs: Observable<CustomerBillingAddress>) {
    this.loading = true;
    obs.subscribe({
      next: () => {
        this.zenDialogSvc.openToast(true);
        this.dialogRef.close(true);
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
