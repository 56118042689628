<div class="zen-popper-main">
  <!-- If popperContent is available is used for custom tooltip design. -->
  <div *ngIf="popperContent" class="zen-tooltip-content-wrapper" [ngClass]="{'small': small}">
    <i *ngIf="closeable" class="material-icons close-icon" (click)="popperContent.clean()">close</i>
    <p class="font-weight-600 mb-2" *ngIf="title">{{title}}</p>
    <ng-content></ng-content>
  </div>

  <!-- If popperData array value is available it will be defined list style w.r.t ZenPopperData config -->
  <div *ngIf="popperData && popperData.length > 0" class="zen-popper-data-wrapper">
    <ng-container *ngFor="let d of popperData">
      <div *ngIf="!d.hideIfBlank || d.value" class="zen-popper-data-main" [ngClass]="d.align">
        <p class="value">
          {{d.value}}
          <i class="material-icons status-indicator ms-1" *ngIf="d.status" [ngClass]="d.status">fiber_manual_record</i>
        </p>
        <p class="title">
          <i *ngIf="d.titleIcon" class="material-symbols-rounded">{{d.titleIcon}}</i>
          {{d.title}}
        </p>
        <i *ngIf="d.enableCopy" class="material-symbols-rounded copy-icon" (click)="copyToClipboard(d.value)">content_copy</i>
      </div>
    </ng-container>
  </div>

</div>
