import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../../environments';
import {AuthenticationService} from '../authentication.service';
import { HttpClient } from '@angular/common/http';
import {take} from 'rxjs/operators';
import {RateCheckReportOverride} from '../../../_models/rate-check-report-override';
import {OrganizationManagementService} from '../organization-management.service';
import {RateCheckStatusEnum} from '../../../_models/rate-checks/rate-check-status';
import {RefreshPricingModel} from '../../../../../_modules/zen-rate-checks/_model/rate-check-v3.model';
import {ZenRequestRefreshDialogModel} from '../../../../_model/zen-request-refresh-dialog.model';

@Injectable({
  providedIn: 'root',
})
export class RateCheckReportV4Service {

  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private orgMgtSvc: OrganizationManagementService) {
  }

  createOrUpdateRateCheckReportOverride(customerId: number, rateCheckReportOverride: RateCheckReportOverride): Observable<RateCheckReportOverride> {
    if (rateCheckReportOverride.id) {
      return this.updateOverrideRateCheckReport(customerId, rateCheckReportOverride);
    } else {
      return this.createOverrideRateCheckReport(customerId, rateCheckReportOverride);
    }
  }

  createOverrideRateCheckReport(customerId: number, rateCheckReportOverride: RateCheckReportOverride): Observable<RateCheckReportOverride> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rateCheckReportOverride.rateCheckId}/rate_check_report_override`;
    return this.http
      .post<RateCheckReportOverride>(url, rateCheckReportOverride)
      .pipe(take(1));
  }

  updateOverrideRateCheckReport(customerId: number, rateCheckReportOverride: RateCheckReportOverride): Observable<RateCheckReportOverride> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rateCheckReportOverride.rateCheckId}/rate_check_report_override/${rateCheckReportOverride.id}`;
    return this.http
      .put<RateCheckReportOverride>(url, rateCheckReportOverride)
      .pipe(take(1));
  }

  /**
   * Refresh Pricing Request.
   * @param rateCheckId
   * @param {RateCheckStatusEnum} newStatus - new status for the rate check
   * @param request
   * */
  refreshPricing(rateCheckId: string, newStatus: RateCheckStatusEnum, request: ZenRequestRefreshDialogModel): Observable<RefreshPricingModel> {
    return this.rateCheckStatusSync(rateCheckId, newStatus, request);
  }

  /**
   * To Sync the RC status between NatGas and CORE databases.
   * @param rateCheckId
   * @param {RateCheckStatusEnum} newStatus - new status for the rate check
   * @param request
   * */
  rateCheckStatusSync(rateCheckId: string, newStatus: RateCheckStatusEnum, request: ZenRequestRefreshDialogModel): Observable<RefreshPricingModel> {
    let requestObj = {newStatus, requestDueDate: request?.dueDate, ...request};
    const url = `${environment.apiBaseUrl}/v2/organizations/${this.authService.getOrganizationId()}/rate-checks/${rateCheckId}/status`;
    return this.http.put<RefreshPricingModel>(url, requestObj).pipe(take(1));
  }
}
