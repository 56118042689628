import {Injectable} from '@angular/core';
import {ZenHierarchicalGrouping} from '../../_enums/zen-hierarchical-grouping.enum';
import {DataFlowChildrenDataTableModified, DataFlowHierarchyGroupedV4Model} from '../../_model/data-flow-hierarchy-v4.model';
import {ZenRcReqFlowHelperService} from '../../../_modules/zen-rate-check-request-v2/_services/zen-rc-req-flow-helper.service';
import {ZenCustomerBulkImportHelperService} from '../../../_modules/zen-bulk-import/_services/zen-customer-bulk-import-helper.service';
import {BulkUpdateType} from '../../_enums/zen-bulk-update-type.enum';
import {ContractStatus} from '../../_enums/zen-contract.enum';

@Injectable({
    providedIn: 'root'
})
export class RcReqMeterBulkUpdateHelperService {

    constructor(public rcReqHelpSvc: ZenRcReqFlowHelperService,
                public bulkImportHelpSvc: ZenCustomerBulkImportHelperService) {

    }

  /**
   * Retrieves all the MeterIds according to the currentGrouping and the selected meterRow
   * @param currentGrouping - required - current Grouping chosen for the grouped meter data in the table
   * @param meterRow - required - currently selected meterRow in the the table
   * @param bulkUpdateType - required - denotes where the bulk update is being initiated, used to denote which data set to use for the groupedMeterData
   */
    getMeterIdsByCurrentGrouping(currentGrouping: ZenHierarchicalGrouping, meterRow: DataFlowChildrenDataTableModified, bulkUpdateType: BulkUpdateType) {
      const groupedMeterData =
        bulkUpdateType === BulkUpdateType.BULK_IMPORT_RECENTLY_ADDED_METERS ? this.rcReqHelpSvc.groupedRawData :
        bulkUpdateType === BulkUpdateType.RATE_CHECK_ELIGIBLE_METERS_FLYOUT ? this.rcReqHelpSvc.eligibleMetersRawData :
        this.rcReqHelpSvc.groupedRawData;
      if (currentGrouping === ZenHierarchicalGrouping.LEN) {
        return this.getAllMeterIds(groupedMeterData, false, meterRow.meterUtilityId, null, meterRow.lenId);
      } else if (currentGrouping === ZenHierarchicalGrouping.CONTRACT) {
        return this.getAllMeterIds(groupedMeterData, meterRow.contractStatus === ContractStatus.on_utility, meterRow.meterUtilityId, meterRow.energyPlanId, null);
      } else {
        return this.getAllMeterIdsByEnergyPlanStatus(groupedMeterData, meterRow.contractStatus === ContractStatus.on_utility, meterRow.meterUtilityId, meterRow.contractStatus, meterRow.energyPlanId);
      }
    }

  /**
   * Retrieves all the MeterIds to be updated when grouping is by Energy Plan or LEN
   * @param groupedMeterData - required - full grouped meter data model that has not yet been filtered
   * @param onlyOnUtility - required - flag to filter out meters according to onUtility setting of the currently selected meter (only if not grouped by LEN)
   * @param utilityId - required - filters out meters according to the Utility of the currently selected meter
   * @param energyPlanId - optional - filters out meters according to the Energy Plan of the currently selected meter (only if not onUtility)
   * @param lenId - optional - filters out meters according to the LEN of the currently selected meter
   */
    getAllMeterIds(groupedMeterData: DataFlowHierarchyGroupedV4Model[], onlyOnUtility: boolean, utilityId: number, energyPlanId?: number, lenId?: string): number[] {
      const meterIds: number[] = [];
      // Filter out all meters where the Energy Plan does not match the current meter's Energy Plan (if not onUtility)
      let filteredData = this.filterOnParent(groupedMeterData, onlyOnUtility, energyPlanId, lenId)
      filteredData = this.filterOnUtility(filteredData, onlyOnUtility, utilityId);
      filteredData.forEach(dataElement => {
        meterIds.push(...this.collectMeterIds(dataElement));
      });
      return meterIds;
    }

  /**
   * Retrieves all the MeterIds to be updated when grouping is by Energy Plan Status
   * @param groupedMeterData - required - full grouped meter data model that has not yet been filtered
   * @param onlyOnUtility - required - flag to filter out meters according to onUtility setting of the currently selected meter (only if not grouped by LEN)
   * @param utilityId - required - filters out meters according to the Utility of the currently selected meter
   * @param energyPlanId - required - filters out meters according to the Energy Plan of the currently selected meter (only if not onUtility)
   * @param contractStatus - required - filters out meters according to the LEN of the currently selected meter
   */
    getAllMeterIdsByEnergyPlanStatus(groupedMeterData: DataFlowHierarchyGroupedV4Model[], onlyOnUtility: boolean, utilityId: number, contractStatus: ContractStatus, energyPlanId: number): number[] {
      const meterIds: number[] = [];
      // Filter out all meters where the onUtility does not match the current meter's onUtility at category details (top) level
      groupedMeterData.filter(gmd => gmd.categoryDetails?.contractStatus === contractStatus)
      .forEach(gmd => {
        // Filter out all meters where the Energy Plan does not match the current meter's Energy Plan (if not onUtility)
        let filteredData = this.filterOnParent(gmd.subCategories, onlyOnUtility, energyPlanId, null)
        // Loop through all subcategories and add any matching meters to be updated
        filteredData = this.filterOnUtility(filteredData, onlyOnUtility, utilityId);
        // store and collect meter IDs
        // store all meter IDs
        filteredData.forEach(dataElement => {
          meterIds.push(...this.collectMeterIds(dataElement));
        })
      })
      return meterIds;
    }

  /**
   * Filters the groupedMeterData according to the grouping and the parent object (Energy Plan, LEN, Energy Plan Status) of the currently select meter
   * @param groupedMeterData - required - full grouped meter data model that has not yet been filtered
   * @param onlyOnUtility - required - flag to filter out meters according to onUtility setting of the currently selected meter (only if not grouped by LEN)
   * @param energyPlanId - required - filters out meters according to the Energy Plan of the currently selected meter (only if not onUtility)
   * @param contractStatus - required - filters out meters according to the LEN of the currently selected meter
   */
    filterOnParent(groupedMeterData: DataFlowHierarchyGroupedV4Model[], onlyOnUtility: boolean, energyPlanId: number, lenId: string) {
      if (lenId !== null) {
        // Filter out all meters with an LEN that does not match the current meter's LEN
        groupedMeterData = groupedMeterData.filter(data => data.categoryDetails.len.lenId === lenId)
      } else if (energyPlanId !== null && !onlyOnUtility) {
        // Filter out all meters with an energy plan that does not match the current meter's energy plan (if not onUtility)
        groupedMeterData = groupedMeterData.filter(data => data.categoryDetails.contract.energyPlanId === energyPlanId)
      }
      return groupedMeterData;
    }

  /**
   * Filters the groupedMeterData according to the onlyOnUtility (if true) and the Utility of the currently select meter
   * @param filteredData - required - grouped meter data model already filtered by parent that is meant to be further filtered
   * @param onlyOnUtility - required - flag to filter out meters according to onUtility setting of the currently selected meter (only if not grouped by LEN)
   * @param utilityId - required - filters out meters according to the Utility of the currently selected meter
   */
    filterOnUtility(filteredData: DataFlowHierarchyGroupedV4Model[], onlyOnUtility: boolean, utilityId: number): DataFlowHierarchyGroupedV4Model[] {
      // Filter out all meters with a LEN or energy plan that does not match the current meter's LEN or energy plan
    filteredData.forEach(dataElement => {
        if (onlyOnUtility) {
          // filter meters only on the utility
          dataElement.data = dataElement.data.filter(dd => dd.contract.onUtility === onlyOnUtility);
        }
        // filter only on the same utility
        dataElement.data = dataElement.data.filter(dd => dd.meter.utilityId === utilityId);
      })
      return filteredData;
    }

  /**
   * Collects all the Meter Ids to be passed back as the result
   * @param groupedData - fully filtered meter data
   */
  collectMeterIds(groupedData: DataFlowHierarchyGroupedV4Model) {
    // get all meter IDs
    return groupedData.data.map(cd => cd.meter.meterId)
  }
}
