<app-zen-dialog-wrapper [data]="data" [loading]="loading" (onSubmit)="submit($event)">
  <div class="dialog-form-sec with-border mb-5" *ngIf="form">
    <div class="d-flex justify-content-center" [formGroup]="form">
      <div class="justify-content-center">

        <div class="row mb-3">
          <div class="col-12 text-start">
            <p class="form-title mb-0">Billing Address Details</p>
          </div>
        </div>

        <!-- LEN details form -->
        <div class="row text-start">
          <!-- Billing Address form starts -->
          <div class="col-12" [formGroup]="form">

            <div class="row">
              <div class="col-12 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Address 1*</mat-label>
                  <input matInput formControlName="address1" #address
                         (input)="addressAutocompleteService.handleAddressChange(form, address)">
                  <mat-error *ngIf="addressHasError('address1', 'required')">Required
                    Field*
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-6 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Address 2</mat-label>
                  <input matInput formControlName="address2">
                </mat-form-field>
              </div>
              <div class="col-6 col-md-6 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>City*</mat-label>
                  <input matInput formControlName="city">
                  <mat-error *ngIf="addressHasError('city', 'required')">Required Field*</mat-error>
                </mat-form-field>
              </div>
              <div class="col-6 col-md-6 form-group">
                <app-zen-autocomplete *ngIf="stateOptions?.length"
                                      [listData]="stateOptions" label="{{'nomenclature.state' | translate}}*"
                                      [formCtrl]="form.controls.state"></app-zen-autocomplete>
              </div>
              <div class="col-6 col-md-6 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Zip*</mat-label>
                  <input matInput formControlName="zip" [mask]="i18nMask.zip">
                  <mat-error *ngIf="addressHasError('zip', 'required')">Required
                    Field*
                  </mat-error>
                  <mat-error *ngIf="addressHasError('zip', 'pattern')">Invalid
                    Format*
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 form-group">
                <!-- We are not setting [itemListData] here. Because externalFilterObsFn will initiated on load. -->
                <app-zen-input-chip-list-selection *ngIf="showLenList"
                                                   label="Legal Entity Names"
                                                   [formCtrl]="form.controls.lens"
                                                   [loading]="loading"
                                                   [externalFilterObsFn]="customerLenItemListFilterObs.bind(this)"
                ></app-zen-input-chip-list-selection>


                <div class="mt-4">
                  <mat-checkbox class="ms-0" formControlName="withoutAddress">Only Legal Entity Names without addresses</mat-checkbox>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</app-zen-dialog-wrapper>
