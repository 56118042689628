import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ZenMatTableSelectSearchConfig, ZenTableColsModel, ZenTableConfig} from '../zen-mat-table/zen-mat-table.component';
import {SelectionModel} from '@angular/cdk/collections';
import {ZenTableViewTypeEnum} from '../../_enums/zen-table-view-type.enum';
import {ZenColors} from '../../_enums/zen-colors.enum';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {ZenBaseComponent} from '../zen-base/zen-base.component';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {orderBy} from 'lodash';
import {DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData} from '../../_model/data-flow-hierarchy-v4.model';
import {MatCheckboxChange} from '@angular/material/checkbox';

export interface ZenExpansionTableData<T, P> {
  categoryDetails: P;
  data: T[];
  // To store data after doing the filter. Used in the select all options.
  filteredData?: T[];
  hasSubCategories: boolean;
  subCategories?: ZenExpansionTableData<T, P>[];
  hideCols?: string[];
}

@Component({
  selector: 'app-zen-mat-table-with-expansion',
  templateUrl: './zen-mat-table-with-expansion.component.html',
  styleUrls: ['./zen-mat-table-with-expansion.component.scss',
    '../zen-mat-table/zen-mat-table.component.scss']
})
export class ZenMatTableWithExpansionComponent extends ZenBaseComponent implements OnInit, OnChanges {
  searchValue = new UntypedFormControl();
  @Input() parentTableConfig: ZenTableConfig;
  @Input() groupedData: ZenExpansionTableData<DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData>[] = [];
  @Input() hidePagination: boolean;
  @Input() hideMultiselect: boolean;
  groupedDataCopy: ZenExpansionTableData<DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData>[] = [];

  @Input() childTableConfig: ZenTableConfig;
  @Input() childOfChildTableConfig: ZenTableConfig;

  @Input() emptyTableConfig: ZenTableConfig;
  @Input() readOnly: boolean;
  emptyTblSelectSearchConfig: Partial<ZenMatTableSelectSearchConfig>;

  @Input() viewType: ZenTableViewTypeEnum;
  @Input() legends: {label: string; color: ZenColors}[] = [];
  TableViewTypes = ZenTableViewTypeEnum;

  allSelectedRowsFlat = new SelectionModel(true, []);

  @Output() onSelectionChange = new EventEmitter<SelectionModel<DataFlowChildrenDataTableModified>>();

  // Pagination
  currentPage = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50, 100];

  @Input() loading: boolean;

  // Sorting
  sortCache = [];
  toggleDirection: 'asc' | 'desc' | '' = '';
  activeSortField: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.init();

    this.searchValue.valueChanges.pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(() => this.applyFilter());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.viewType) {
      this.init();
    }
  }

  init() {
    this.initGroupedSelectionModel();
    this.disableColActionsForReadOnly();

    this.parentTableConfig.hideTopSelectedActions = true;
  }

  applyFilter() {
    let searchKey = this.searchValue.value.trim().toLowerCase();

    this.groupedData = [...this.groupedDataCopy]
      .filter(gd => {
        return (Object.values(gd.categoryDetails) as string[]).some(d => this.filterCondition(d, searchKey)
          || (
            gd.hasSubCategories
              ? gd.subCategories.some(sc => sc.data?.some(cd => (Object.values(cd) as string[]).some(cdObjVal => this.filterCondition(cdObjVal, searchKey))))
                : gd.data?.some(cd => (Object.values(cd) as string[]).some(cdObjVal => this.filterCondition(cdObjVal, searchKey)))
          )
        );
      });

    // Setting No Results text on search if not data exist.
    if (this.emptyTableConfig && searchKey && this.groupedData?.length === 0) {
      this.emptyTblSelectSearchConfig = {
        inputCtrl: new FormControl(searchKey)
      };
    }
  }

  filterCondition(d: string, searchKey) {
    if (typeof d === 'number') {
      return d && parseFloat(d).toString().trim().toLowerCase().includes(searchKey);
    } else if (typeof d === 'string') {
      return d && d.toString().trim().toLowerCase().includes(searchKey);
    } else if (Array.isArray(d)) { // array. e.g. tags array
      // @ts-ignore
      return d && d?.map(a => a?.name)?.join(', ')?.toString().trim().toLowerCase().includes(searchKey);
    }
  }

  /* Adding SelectionModel[] with each parent object to tract selectedRows. */
  initGroupedSelectionModel() {
    const _selectionMissing = this.groupedData?.some(gd => gd.hasSubCategories
      ? gd?.subCategories.some(sc => !sc.categoryDetails?.selectedRows)
      : !gd?.categoryDetails?.selectedRows
    );

    // Update selectedRows only if the selectedRows are not set
    if (_selectionMissing) {
      this.groupedData = this.groupedData.map((g, index) => {
        if (!g.categoryDetails?.selectedRows) {
          g.categoryDetails = {
            ...g.categoryDetails,
            selectedRows: new SelectionModel<DataFlowChildrenDataTableModified>(true, []),
          };
        }
        g.filteredData = [...g.data];
        g.categoryDetails.selectedRows.changed.subscribe(() => this.handleEveryRowSelectionChange());

        // Setting SelectionModel for sub categories.
        g.subCategories = g.subCategories.map(sc => {
          if (!sc.categoryDetails?.selectedRows) {
            sc.categoryDetails = {
              ...sc.categoryDetails,
              selectedRows: new SelectionModel<DataFlowChildrenDataTableModified>(true, [])
            }
          }
          sc.filteredData = [...sc.data];
          sc.categoryDetails.selectedRows.changed.subscribe(() => this.handleEveryRowSelectionChange());
          return sc;
        });
        return g;
      });
      this.groupedDataCopy = [...this.groupedData];
    }
  }

  clearGroupedSelectionModel() {
    this.groupedData = [...this.groupedDataCopy].map((g, index) => {
      g.categoryDetails?.selectedRows?.clear();

      // Setting SelectionModel for sub categories.
      g.subCategories = g.subCategories.map(sc => {
        sc.categoryDetails?.selectedRows?.clear();
        return sc;
      });
      return g;
    });
    this.groupedDataCopy = [...this.groupedData];

    if (this.searchValue.value?.trim()) {
      this.applyFilter();
    }
  }


  // The below method listen selection changes all rows.
  handleEveryRowSelectionChange() {
    let _allSelectedValues = [];

    this.groupedData.forEach(gd => {
      if (gd.hasSubCategories) {
        gd.subCategories.forEach(sc => _allSelectedValues.push(...sc.categoryDetails.selectedRows?.selected));
      } else {
        _allSelectedValues.push(...gd.categoryDetails.selectedRows?.selected);
      }
    });

    setTimeout(() => {
      this.allSelectedRowsFlat = new SelectionModel(true, _allSelectedValues);
      this.onSelectionChange.emit(this.allSelectedRowsFlat);
    });
  }

  isAllSelected() {
    const numSelected = this.allSelectedRowsFlat.selected.length;
    const numAllRows = this.allChildData(false, true)?.length;
    const numFilteredRows = this.allChildData(true)?.length;
    return (numSelected === numFilteredRows) && (numSelected === numAllRows);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(evt: MatCheckboxChange) {
    if (!evt.checked || this.isAllSelected()) {
      this.allSelectedRowsFlat.clear();
      this.clearGroupedSelectionModel();
      return;
    } else {
      this.allSelectedRowsFlat.select(this.allChildData(true));
      this.selectAllGroupedSelectionModel();
    }
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedCatDetails(pData: ZenExpansionTableData<DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData>) {
    const numSelected = pData?.categoryDetails.selectedRows?.selected?.length || 0;
    const numRows = pData?.data?.length;
    return numSelected === numRows;
  }

  /** Selects all rows in the category details level, if they are not all selected; otherwise clear selection. */
  masterToggleCatDetails(check: MatCheckboxChange,
                         pData: ZenExpansionTableData<DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData>) {
    if (pData.hasSubCategories) { // TODO: Future enhancement
    } else {
      if (check?.checked) {
        pData.categoryDetails.selectedRows.select(...pData.filteredData);
      } else {
        pData.categoryDetails.selectedRows.clear();
      }
    }
  }

  selectAllGroupedSelectionModel() {
    this.groupedData.forEach((gd: ZenExpansionTableData<DataFlowChildrenDataTableModified,
      DataFlowHierarchyCategoryDetailsTableModifiedData>) => {
      if (gd.filteredData?.length) {
        gd.filteredData?.forEach(d => gd.categoryDetails.selectedRows.select(d));
      }
      if (gd.hasSubCategories && gd.subCategories?.length) {
        gd.subCategories?.forEach(subCat => {
          subCat.filteredData?.forEach(d => subCat.categoryDetails.selectedRows.select(d));
        });
      }
    });
  }

  allChildData(filteredData = false, dataWithoutFilter = false) {
    if (dataWithoutFilter) {
      return this.groupedDataCopy.map(gd =>
        gd.hasSubCategories
          ? gd.subCategories.flatMap(d => (filteredData ? d.filteredData : d.data)?.flatMap(sd => sd))
          : (filteredData ? gd.filteredData : gd.data)).flatMap(d => d);
    } else {
      return this.groupedData.map(gd =>
        gd.hasSubCategories
          ? gd.subCategories.flatMap(d => (filteredData ? d.filteredData : d.data)?.flatMap(sd => sd))
          : (filteredData ? gd.filteredData : gd.data)).flatMap(d => d);
    }
  }

  // To support the design Im using mat-paginator. To support the pagination in the *ngFor Im using ngx-pagination.
  pageChange(page: PageEvent) {
    let {pageIndex, pageSize} = page;
    this.currentPage = pageIndex + 1;
    this.pageSize = pageSize;
  }

  // The groupedData is looped individual parent tables, so the regular sort won't work here.
  // Using sortCache arr to handle the state of sort -> asc, desc, ''
  handleSortChange({active}: Sort) {
    // Reset when the toggle field changed
    if (!this.activeSortField || this.activeSortField !== active) {
      this.sortCache = [];
    }

    // After doing both [asc, desc] - reset to -> ['']
    if (this.sortCache?.length < 2) {
      this.activeSortField = active;
      this.toggleDirection = this.toggleDirection === 'desc' ? 'asc' : 'desc';
      this.sortCache.push(this.toggleDirection);
      this.groupedData = orderBy(this.groupedData, 'categoryDetails.' + active, this.toggleDirection);
    } else {
      this.activeSortField = '';
      this.toggleDirection = '';
      this.sortCache = [];
      this.groupedData = this.groupedDataCopy;
    }
  }

  disableColActionsForReadOnly() {
    // Disabling checkbox, hyperlink and editable serviceAddresses for the readOnly config
    if (this.readOnly) {
      if (this.parentTableConfig.cols?.length) {
        this.parentTableConfig.cols = this.parentTableConfig.cols.map(c => this.disableColActions(c));
      }
      if (this.childTableConfig?.cols?.length) {
        this.childTableConfig.cols = this.childTableConfig.cols.map(c => this.disableColActions(c));
        this.childTableConfig.hideMultiselect = true;
      }
      if (this.childOfChildTableConfig?.cols?.length) {
        this.childOfChildTableConfig.cols = this.childOfChildTableConfig.cols.map(c => this.disableColActions(c));
        this.childOfChildTableConfig.hideMultiselect = true;
      }
    }
  }

  disableColActions(c: ZenTableColsModel): ZenTableColsModel {
    c.hyperlink = false;
    c.editable = false;
    return c;
  }

}
