import { CommodityType } from '../../../_models/commodity';
import { DateValidations } from '../../../../../_modules/zen-rate-checks/_model/rate-check-v3.model';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthenticationService} from '../../_services/authentication.service';
import {BrokerFeesCalculated} from '../models/broker-fees';
import { MatrixMeterStartDate } from '../../../../../_modules/zen-market-pulse/_models/MatrixMeterStartDate';

@Injectable({
  providedIn: 'root'
})
export class RateCheckRequestService {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private authService: AuthenticationService) {
  }


  getRateCheckEffectiveRate(customerId: number, rateCheckId: string): Observable<number> {
    const url = `${this.baseUrl}/v4/organizations/${this.authService.getOrganizationId()}/customers/${customerId}/rate-checks/${rateCheckId}/effective-rate`;
    return this.http.get<number>(url).pipe(take(1));
  }


  calculateOrganizationFees(mwhUsage: number, totalFeeInDollars: number): Observable<BrokerFeesCalculated> {
    const url = `${this.baseUrl}/v2/organizations/${this.authService.getOrganizationId()}/compute_zen_fee`;
    const mwhUsageString = mwhUsage ? mwhUsage.toString() : '0';
    let options = { params: { mwhUsage: mwhUsageString } };
    if (totalFeeInDollars !== null) { options.params['totalFee'] = totalFeeInDollars.toFixed(5); }
    return this.http.get<BrokerFeesCalculated>(url, options).pipe(take(1));
  }

  getDueDates(commodityType: CommodityType, isRefresh: boolean, contractStartDate?: string): Observable<DateValidations> {
    const url = `${this.baseUrl}/v2/biz_calendar/rate_check_due_dates`;
    let params = { commodityType, isRefresh };
    if (contractStartDate) params['contractStartDate'] = contractStartDate;
    let options = { params };
    return this.http.get<DateValidations>(url, options).pipe(take(1));
  }
  getMeterStartDates(customerId: number, meterIds: number[]) {
    const meterIdsParam = [...new Set(meterIds)];
    return this.http.post<MatrixMeterStartDate[]>(this.baseUrl + `/v4/organizations/${this.authService.getOrganizationId()}/customers/${customerId || this.authService.getCurrentCustomerId()}/eligible-meter-start-dates`, meterIdsParam).pipe(take(1));
  }


}
