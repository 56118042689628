import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonActionTypes, ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';

@Component({
  selector: 'app-zen-payment-feedback-dialog',
  templateUrl: './zen-payment-feedback-dialog.component.html',
  styleUrls: ['./zen-payment-feedback-dialog.component.scss']
})
export class ZenPaymentFeedbackDialogComponent implements OnInit {
  CHAR_LIMIT = 500;
  feedback = new UntypedFormControl('', [Validators.maxLength(this.CHAR_LIMIT), Validators.required]);

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenPaymentFeedbackDialogComponent>) {
  }

  ngOnInit(): void {
  }

  submit(btn: ZenDialogActionButton) {
    this.feedback.markAllAsTouched();
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      this.dialogRef.close();
    } else if (this.feedback.valid) {
      btn.command(this.feedback.value);
    }
  }
}
