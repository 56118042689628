import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {NgxPopperjsContentComponent} from 'ngx-popperjs';


@Component({
  selector: 'app-zen-icon-button',
  templateUrl: './zen-icon-button.component.html',
  styleUrls: ['./zen-icon-button.component.scss']
})
export class ZenIconButtonComponent implements OnInit, OnChanges {
  @Input() icon: ZenIconsEnum;
  @Input() iconNoBg = false;
  @Input() btnStyleCls; // small, w-100
  @Input() title: string;
  @Input() isSymbol: boolean;
  @Input() selected: boolean;
  @Input() noBorder = false;
  @Input() unselected: boolean;
  @Input() disableRipple: boolean;
  @Input() disabled: boolean;
  @Input() popperComponent: NgxPopperjsContentComponent;

  iconColor: 'electric' | 'nat-gas' | 'green' | 'red' | 'no-bg';

  constructor() {
  }

  ngOnInit(): void {
    this.setIconColor();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setIconColor();
  }

  setIconColor() {
    if (!this.iconNoBg) {
      switch (this.icon) {
        case ZenIconsEnum.ELECTRIC:
          this.iconColor = 'electric';
          break;
        case ZenIconsEnum.NAT_GAS:
          this.iconColor = 'nat-gas';
          break;
        case ZenIconsEnum.DATABASE:
        case ZenIconsEnum.THUMB_UP:
        case ZenIconsEnum.GREEN:
          this.iconColor = 'green';
          break;
        case ZenIconsEnum.THUMB_DOWN:
          this.iconColor = 'red';
          break;
      }
    } else {
      this.iconColor = 'no-bg';
    }
  }

}
