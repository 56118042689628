/// <reference types="@angular/localize" />

import 'zone.js';  // Included with Angular CLI.

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments';
import { AppModule } from './app/';
import {akitaConfig, enableAkitaProdMode} from '@datorama/akita';
import { persistState } from '@datorama/akita';


/** Whithout below import chart.js v2 zoom & pan options wont works.
 * REF: https://github.com/chartjs/chartjs-plugin-zoom/issues/222 */
import 'chartjs-plugin-zoom/dist/chartjs-plugin-zoom';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

akitaConfig({
  resettable: true
});

const storage = persistState({
  key: 'zenStore',
  include: ['customerId', 'portfolioFilterSession']
});

const providers = [{ provide: 'persistStorage', useValue: storage }];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
