import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {environment} from '../../../../../environments';
import {Observable} from 'rxjs';
import {Organization} from '../_zen-legacy-common/_models/organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  /** Uploads an LARGE image to the organizations public image folder in the images bucket. */
  uploadOrgFullLogo(orgId: string, file_data: File): Observable<Organization> {
    let formData = new FormData();
    formData.append('file', file_data);
    const url = `${this.baseUrl}/v4/organizations/${orgId}/image`;
    return this.http.post<Organization>(url, formData).pipe(take(1));
  }

  /** Uploads an SMALL image to the organizations public image folder in the images bucket. */
  uploadOrgSmallLogo(orgId: string, file_data: File): Observable<Organization> {
    let formData = new FormData();
    formData.append('file', file_data);
    const url = `${this.baseUrl}/v4/organizations/${orgId}/image-small`;
    return this.http.post<Organization>(url, formData).pipe(take(1));
  }
}
