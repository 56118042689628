import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {EnergyPlanV2, ServiceAddressEnergyPlansV2} from '../_models/energyplan-v2';
export interface HasUnsavedData {
  energyPlans: EnergyPlanV2[],
  loading: boolean
}

export class HasUnsavedDataGuard implements CanDeactivate<HasUnsavedData> {
  canDeactivate(component: HasUnsavedData,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
    let pendingServiceAddressList = [];
    if (component.energyPlans && component.energyPlans.length > 0) {
      component.energyPlans.map((energyPlan: EnergyPlanV2) => {
        if (energyPlan.serviceAddressEnergyPlans && energyPlan.serviceAddressEnergyPlans.length > 0) {
          energyPlan.serviceAddressEnergyPlans.map((propEngPlan: ServiceAddressEnergyPlansV2) => {
            if (propEngPlan.serviceAddress && propEngPlan.serviceAddress.status === 'Pending') {
              pendingServiceAddressList.push({energyPlanId: energyPlan.energyPlanId, ...propEngPlan.serviceAddress});
            }
          });
        }
      });
    }
    if (pendingServiceAddressList && pendingServiceAddressList.length > 0) {
      // tslint:disable-next-line:max-line-length
      let unsaved = confirm(`Are you sure are you want to navigate away?\n\nYou have added bill data to 1 or more Energy Plans which require a refresh to update all data metrics for this customer.`);
      component.loading = false;
      return unsaved;
    }
    return true;
  }
}
