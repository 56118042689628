import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {CustomerContactService} from '../../_zen-legacy-common/zen-common-services/_services/customer-contact.service';
import {CustomerIdSessionService} from '../../_services/customer-id/customer-id-session.service';
import {CustomerContact} from '../../_zen-legacy-common/_models/customer';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {UntypedFormControl} from '@angular/forms';
import {LenService} from '../../_zen-legacy-common/zen-common-services/tili-services/services/len.service';
import {LenBulkPostModel} from '../../_zen-legacy-common/zen-common-services/tili-services/models/len';

@Component({
  selector: 'app-zen-customer-member-delete-dialog',
  templateUrl: './zen-customer-member-delete-dialog.component.html',
  styleUrls: ['./zen-customer-member-delete-dialog.component.scss']
})
export class ZenCustomerMemberDeleteDialogComponent implements OnInit {
  errorMsg: string
  loading: boolean;
  customerId: number;
  contactList: CustomerContact[] = [];
  deletingContact: CustomerContact;
  contractSignatory = new UntypedFormControl();
  billingContact = new UntypedFormControl();

  constructor(public dialogRef: MatDialogRef<ZenCustomerMemberDeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: ZenDialogDataModel,
              private customerContactSvc: CustomerContactService,
              private customerIdSvc: CustomerIdSessionService,
              private lenSvc: LenService,
              private zenDialogSvc: ZenDialogMsgService) {
    this.customerId = this.customerIdSvc.getCustomerId();
    this.deletingContact = this.dialogData.data?.contact;
  }

  ngOnInit(): void {
    this.loadContacts();
  }

  loadContacts() {
    this.loading = true;
    this.customerContactSvc.getCustomerContacts(this.customerId).subscribe(contacts => {
      this.contactList = contacts.filter(c => c.id !== this.deletingContact.id).map((c, i) => {
        c.label = `${c.firstName} ${c.lastName} ${(i === 0) ? '(Default)' : ''}`;
        c.value = c.id;
        return c;
      });
      if (this.deletingContact.billingContactLens?.length) {
        this.billingContact.setValue(this.contactList[0].id);
      }
      if (this.deletingContact.contractSignatoryLens?.length) {
        this.contractSignatory.setValue(this.contactList[0].id);
      }
      this.loading = false;
    }, e => {
      console.log('Error: Load contacts ', e);
      this.zenDialogSvc.openToast(false);
      this.loading = false;
    });
  }

  submit() {
    let _lenIds = new Set();
    let _bulkUpdateSignatoryData: LenBulkPostModel[] = [];
    if (this.deletingContact.contractSignatoryLens?.length) {
      (this.deletingContact.contractSignatoryLens || []).forEach(len => {
          _lenIds.add(len.id);
          _bulkUpdateSignatoryData.push({
            id: len.id,
            billingAddressId: len?.billingAddress?.id,
            signatorContactId: this.contractSignatory.value
          });
        }
      );
    }

    let _bulkUpdateBillingContactData: LenBulkPostModel[] = [];
    if (this.deletingContact.billingContactLens?.length) {
      (this.deletingContact.billingContactLens || []).forEach(len => {
          _lenIds.add(len.id);
          _bulkUpdateBillingContactData.push({
            id: len.id,
            billingAddressId: len?.billingAddress?.id,
            billingContactId: this.billingContact.value // Setting contact id as null
          });
        }
      );
    }

    let _finalUpdateData: LenBulkPostModel[] = [];

    _lenIds.forEach(lenId => {
      _finalUpdateData.push({
        id: lenId.toString(),
        ...Object.assign({}, _bulkUpdateSignatoryData.find(l => l.id === lenId), _bulkUpdateBillingContactData.find(l => l.id === lenId)),
      });
    });

    this.customerContactSvc.deleteCustomerContactById(this.deletingContact.id, this.customerId).subscribe(() => {
      if (_finalUpdateData?.length) {
        this.lenSvc.bulkUpdateLen(this.customerId, _finalUpdateData).subscribe(() => {
          this.zenDialogSvc.openToast(true);
          this.dialogRef.close(true);
        }, e => {
          console.log('Error: Update multiple LEN ', e);
          this.zenDialogSvc.openToast(false);
        });
      } else {
        this.zenDialogSvc.openToast(true);
        this.dialogRef.close(true);
      }
    }, e => {
      console.log('Error: Delete contact ', e);
      this.zenDialogSvc.openToast(false);
    });
  }
}
