export enum RateCheckStatusEnum {
  REJECT = 0,
  ABORTED = 1,
  TIMED_OUT = 2,
  DECLINED = 3,
  /** The Rate Check has been automatically generated. This state is currently pass-through and is used for automation purposes only **/
  CREATED = 100,
  DATA_COLLECTION = 110,
  PRE_BIDDING_QA = 120,
  /** 200: customer verification **/
  CUSTOMER_VERIFICATION = 200,
  /** 300: supplier bidding and internal processing **/
  BIDDING_OPEN = 300,
  BIDDING_CLOSED = 310,
  OPTIMIZING = 320,
  POST_BIDDING_QA = 330,
  RECOMMENDATION_QA = 340,
  /** 400: customer decision **/
  CUSTOMER_DECIDING = 400,
  REQUESTING_CONTRACTS = 410,
  PRICING_SOLUTION_QA = 420,
  CUSTOMER_APPROVING_SOLUTION = 430,
  PRICING_SOLUTION_CUSTOMER_SUPPORT = 440,
  /** 500: transacting/booking **/
  /** Zentility is waiting for the Customer to sign all required Contracts.  Contract state transitions are tracked individually. **/
  SIGNING_CONTRACTS = 500,
  /** Zentility is in the process of packaging up the contract to sen dto supplier for review **/
  PACKAGE_CONTRACTS = 505,
  /** Zentility is in the process of booking all energy plans required (e.g. sending signed contracts to suppliers
   * and requesting utility switches). **/
  BOOKING_ENERGY_PLANS = 510,
  /** Counter Signed Contract: has confirmed booking, and Zentility is now waiting for the Supplier to send the
   * Counter Signed Contract **/
  AWAITING_COUNTERSIGNED_CONTRACT = 520,
  /** The rate check has finished executing **/
  COMPLETE = 1000,

  /** NATURAL GAS STATUSES **/
  STEP_1 = 50, // RC acquires this status once the customer's finished step 1
  STEP_2_PRE = 60, // RC acquires this status once the customer's finished uploading on-board serviceAddress account sheet - Pre Upload
  STEP_2_EDIT = 61,
  STEP_2_POST = 65, // RC acquires this status once the customer's finished uploading on-board serviceAddress account sheet - Post Upload
  STEP_3 = 70, // RC acquires this status once the customers' finished step 3
  STEP_4 = 80, // RC acquires this status once the customers' finished step 4
  STEP_5 = 90, // RC acquires this status once the customers' finished step 5
  REFRESH_REQUEST = 250,
}

export enum RateCheckStatusLabels {
  REQUEST_PENDING = 'Request Pending',
  REQUEST_SUBMITTED = 'Request Submitted',
  BIDDING = 'Bidding',
  CONTRACT_GENERATION = 'Contract Generation',
  ADVISOR_REVIEW = 'Advisor Review',
  CUSTOMER_REVIEW = 'Customer Review',
  RATE_CHECK_REVIEW = 'Rate Check Review', // Backend status -> Customer Review
  CONTRACT_REVIEW = 'Contract Review', // Backend status -> Customer Signing
  CUSTOMER_SIGNING = 'Customer Signing',
  SUPPLIER_REVIEW = 'Supplier Review',
  AWAITING_COUNTERSIGNED_CONTRACT = 'Awaiting Countersigned Contract',
  CURRENT = 'Current',
  FUTURE = 'Future',
  EXPIRED = 'Expired',
  DEFAULT_SERVICE = 'Default Service',
  BOOKED = 'Booked',

  ABORTED = 'Aborted',
  REJECTED = 'Rejected',
  TIMED_OUT = 'Timed Out',
}

// used for sorting by rate check progression order
const rateCheckLabelToOrdinal = {
  [RateCheckStatusLabels.REQUEST_PENDING]: 0,
  [RateCheckStatusLabels.REQUEST_SUBMITTED]: 1,
  [RateCheckStatusLabels.BIDDING]: 2,
  [RateCheckStatusLabels.ADVISOR_REVIEW]: 3,
  [RateCheckStatusLabels.CUSTOMER_REVIEW]: 4,
  [RateCheckStatusLabels.RATE_CHECK_REVIEW]: 4.5,
  [RateCheckStatusLabels.CONTRACT_GENERATION]: 5,
  [RateCheckStatusLabels.CONTRACT_REVIEW]: 6,
  [RateCheckStatusLabels.SUPPLIER_REVIEW]: 7,
  [RateCheckStatusLabels.AWAITING_COUNTERSIGNED_CONTRACT]: 8,
  [RateCheckStatusLabels.BOOKED]: 9,
  [RateCheckStatusLabels.ABORTED]: 10,
  [RateCheckStatusLabels.TIMED_OUT]: 11,
  [RateCheckStatusLabels.REJECTED]: 12,
}

export function sortByRateCheckStatus(a: RateCheckStatusLabels, b: RateCheckStatusLabels) {
  return rateCheckLabelToOrdinal[a] < rateCheckLabelToOrdinal[b] ? -1 : 1;
}

