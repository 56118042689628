<ng-container *ngIf="formCtrl">
  <mat-form-field (click)="fileInput.click()" appearance="outline" class="input-upload-files"
                  [class.ng-invalid]="formCtrl?.touched && formCtrl?.hasError('required')">

    <mat-label [hidden]="uploading">{{uploading ? '' : label}}</mat-label>

    <input matInput class="text-color"
           [value]="documentName && !uploading ? documentName : null"
           [disabled]="isDisabled">
    <i matSuffix class="material-symbols-rounded">file_upload</i>
    <mat-spinner *ngIf="uploading" diameter="20" matPrefix [strokeWidth]="2" color="accent"
                 class="loading"></mat-spinner>
  </mat-form-field>
  <mat-error *ngIf="formCtrl?.touched && formCtrl?.hasError('required')">Required Field*</mat-error>

  <button *ngIf="downloadable" class="filter-button p-0" (click)="downloadDocument.emit()" [disabled]="isDisabled">
    <span class="small">Download</span>
  </button>

  <input #fileInput type="file" (change)="onFileInput.emit($event.target.files)"
         style="display:none;" [accept]="'application/pdf'" [disabled]="isDisabled"/>
</ng-container>
