<div [ngClass]="{'zen-input-group': isEditable}">
  <!-- For flat arrays -->
  <ng-container *ngIf="isOptionsArray">
    <mat-form-field [appearance]="'outline'" [class.left-field]="isEditableCheck" id="zen-select-form-field-basic">
      <mat-label> <span class="ms-3">{{label}}</span> </mat-label>
      <mat-select #matSelect [formControl]="formCtrl" [placeholder]="placeholder"
                  (closed)="resetSearch()" (opened)="scrollSelectToTop()"
                  (selectionChange)="onSelectOptionChange(formCtrl.value)">

        <mat-select-trigger *ngIf="matTrigger?.value">
          <ng-template [ngTemplateOutlet]="selectItemTpl" [ngTemplateOutletContext]="{option: matTrigger, isMatTrigger: true}"></ng-template>
        </mat-select-trigger>

        <ng-container *ngIf="isSearchable">
          <div class="select-search-input-wrapper">
            <div class="select-search-input-container">
              <mat-form-field [appearance]="'outline'" class="thin" floatLabel="never">
                <mat-icon class="text-color" matPrefix>search</mat-icon>
                <input (keydown)="$event.stopPropagation()" [formControl]="searchCtrl" matInput placeholder="Search..." type="text">
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let option of filteredOptions">
          <mat-option (click)="onAddNew.emit()" *ngIf="option.value === null && option.label !== 'None'; else selectItem">
            <div class="d-flex align-items-center">
              <i class="material-symbols-rounded color-icon bg-blue-5-color blue-1-color p-2 me-2 large">add</i>{{newOptLabel}}
            </div>
          </mat-option>
          <ng-template #selectItem>
            <mat-option [disabled]="disabledContactIds.includes(option.id)" [value]="option.value"
                        (click)="onOptionClick.emit(formCtrl.value)">
              <ng-template [ngTemplateOutlet]="selectItemTpl" [ngTemplateOutletContext]="{option}"></ng-template>
            </mat-option>
          </ng-template>
        </ng-container>
      </mat-select>
<!--      <i class="material-symbols-rounded" matSuffix>expand_more</i>-->
      <mat-error *ngIf="formCtrl.touched && formCtrl.hasError('required')">Required Field*</mat-error>
    </mat-form-field>
  </ng-container>

  <!-- For maps -->
  <ng-container *ngIf="!isOptionsArray && !hasSuggested">
    <mat-form-field [appearance]="'outline'" [class.left-field]="isEditableCheck"
                    [ngClass]="{'ng-invalid ng-touched': showError}" id="zen-select-form-field-for-maps">
      <mat-label> <span class="ms-3">{{label}}</span> </mat-label>
      <mat-select (closed)="resetSearch()" (opened)="scrollToActiveElement()"
                  (selectionChange)="onSelectOptionChange($event.value)" [(ngModel)]="selectedOption">
        <div class="select-search-input-wrapper">
          <div class="select-search-input-container">
            <mat-form-field appearance="outline" class="thin" floatLabel="always">
              <mat-icon class="text-color" matPrefix>search</mat-icon>
              <input [formControl]="searchCtrl" matInput placeholder="Search..." type="text">
            </mat-form-field>
          </div>
        </div>
        <mat-optgroup *ngFor="let group of filteredOptions | keyvalue" [label]="group.key">
          <mat-option *ngFor="let item of group.value" [value]="group.key + '||' + item">
            {{item}}
          </mat-option>
        </mat-optgroup>
      </mat-select>
      <i class="material-symbols-rounded" matSuffix>expand_more</i>
    </mat-form-field>
    <mat-error *ngIf="formCtrl?.touched && formCtrl?.hasError('required')">Required Field*</mat-error>
  </ng-container>

  <ng-container *ngIf="!isOptionsArray && hasSuggested">
    <mat-form-field [appearance]="'outline'" [class.left-field]="isEditableCheck" id="zen-select-form-field"
                    [class.locked]="disabled">
      <mat-label> <span class="ms-3">{{label}}</span> </mat-label>
      <mat-select (closed)="resetSearch()" (opened)="scrollSelectToTop()" (selectionChange)="onSelectOptionChange($event.value)"
                  [formControl]="formCtrl" [placeholder]="placeholder">

        <mat-select-trigger *ngIf="matTrigger?.value">
          <ng-template [ngTemplateOutlet]="selectItemTpl" [ngTemplateOutletContext]="{option: matTrigger, isMatTrigger: true, disabled}"></ng-template>
        </mat-select-trigger>

        <ng-container *ngIf="isSearchable">
          <div class="select-search-input-wrapper">
            <div class="select-search-input-container">
              <mat-form-field [appearance]="'outline'" class="thin" floatLabel="never">
                <mat-icon class="text-color" matPrefix>search</mat-icon>
                <input (keydown)="$event.stopPropagation()" [formControl]="searchCtrl" matInput placeholder="Search..." type="text">
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <mat-option *ngIf="newOptLabel != null" (click)="onAddNew.emit()">
          <div class="d-flex align-items-center">
            <i class="material-icons font-weight-bolder me-2 large">add</i>{{newOptLabel}}
          </div>
        </mat-option>
        <ng-container *ngIf="!hasSuggested">
          <mat-optgroup *ngFor="let group of filteredOptions | keyvalue" [label]="group.key">
            <mat-option *ngFor="let item of group.value" [value]="group.key + '||' + item">
              {{item}}
            </mat-option>
          </mat-optgroup>
        </ng-container>
        <ng-container *ngIf="hasSuggested">
          <mat-optgroup *ngFor="let group of filteredOptions | keyvalue: keepCurrentOrder" [label]="determineGroupHeader(group.key)">
            <mat-option *ngFor="let item of group.value" [disabled]="disabledContactIds.includes(item.id)"
                        [ngClass]="group.key == 'Suggested Contacts' ? 'suggested' : 'not-suggested'" [value]="item.value">
              <div class="d-flex align-items-center">
                <i *ngIf="group.key == 'Suggested Contacts'" [ngClass]="disabledContactIds.includes(item.id) ? 'star-icon-disabled' : 'star-icon-enabled'"
                   class="material-icons me-2" matPrefix>star</i>
                <div class="">
                  {{item.label}}
                  <p *ngIf="item.subText" [ngClass]="disabledContactIds.includes(item.id) ? 'sub-text-disabled' : 'sub-text-enabled'">{{item.subText}}</p>
                </div>
              </div>
            </mat-option>
          </mat-optgroup>
        </ng-container>
      </mat-select>
      <i class="material-symbols-rounded" matSuffix>expand_more</i>
    </mat-form-field>
    <span *ngIf="showError" class="mat-error required-error">Required Field*</span>
  </ng-container>
  <div *ngIf="isEditableCheck" class="right-field btn-action" (click)="onEditClick.emit(formCtrl.value)">
    <i class="material-icons blue-1-color">edit</i>
  </div>
  <div *ngIf="isDeletable && formCtrl.value" class="right-field btn-action" (click)="onDeleteClick.emit()">
    <i class="material-icons danger-1-color">delete</i>
  </div>
</div>

<!--This tpl used in both mat-option & in mat-trigger-->
<ng-template #selectItemTpl let-option="option" let-isMatTrigger="isMatTrigger" let-disabled="disabled">
  <div class="d-flex align-items-center">
    <i class="material-symbols-rounded dropdown-option-icon"
       [class.option-position]="!isMatTrigger"
       *ngIf="option?.dropdownOptionIcon">{{option?.dropdownOptionIcon}}</i>
    <div [class.ms-1]="option?.dropdownOptionIcon">
      <p class="font-weight-400 m-0 text-sm-2" [class.text-color]="disabled">{{option.label}}</p>
      <p class="small text-color m-0" *ngIf="option.subText && !isMatTrigger">{{option.subText}}</p>
    </div>
  </div>
</ng-template>
