<!--https://github.com/valor-software/ng2-charts/issues/1109#issuecomment-713595246-->
<app-zen-skeleton-loader *ngIf="loading" [loaderType]="ZenLoaderTypes.CHART"></app-zen-skeleton-loader>

<ng-container *ngIf="!loading">
  <app-zen-error *ngIf="error else chartTpl"></app-zen-error>

  <ng-template #chartTpl>
    <div class="chart {{styleType}}" *ngIf="!error">
      <div class="d-flex justify-content-between">
        <div class="chart-sec" (mouseout)="legendHoverIndex=null;">

          <!-- External tooltip tpl -->
          <div id="zenChartJsTooltip"></div>

          <canvas #myChart="base-chart" id="zen-chart-{{styleType}}"
                  baseChart
                  [data]="filteredData"
                  [type]="chartType"
                  [options]="chartOptions"
                  (chartHover)="handleChartSegmentHover($event)"
                  (chartClick)="onChartSegmentClick($event)"
                  [legend]="showLegend"></canvas>
          <div class="custom-plugin" *ngIf="pluginData" [class.default-no-data]="!pluginData.value && !pluginData.title">
            <h2 class="large">{{pluginData.value ? pluginData.value : 'N/A'}}</h2>
            <p class="sub-heading-2 no-wrap">{{pluginData.title}}</p>
          </div>
        </div>

        <div class="legend-sec">
          <p class="legend-title" *ngIf="title">{{title}}</p>
          <div #chartLegendContainer class="chart__legend" *ngIf="legends.length"
               [class.mt-3]="!title && styleType !== 'v3'">

            <ng-container *ngFor="let item of legends;">
              <div class="chart__legend-item" [ngClass]="{'legend-selected': isItemFilterActive(item) || legendHoverIndex === item.dataIndex}"
                   [class.disabled]="item.disabled" (mouseout)="onOut(myChart)" (click)="!item.disabled ? onLegendItemClick($event, item) : null">
                <div [class.no-wrap]="styleType === 'v1'">
                    <span class="chart__item-color"
                          [ngStyle]="{background: item.color, border: '1px solid','border-color': item.color}"></span>
                  <p class="chart__item-text" [class.disabled]="item.disabled"
                     [id]="chartDataCopy?.legendUniqueScrollId + '-' + item.dataIndex"
                     (mouseover)="onLegendHover(item, item.dataIndex, myChart)" [innerHTML]="item.label"></p>

                </div>
              </div>
            </ng-container>

          </div>

          <div class="scroll-btns" *ngIf="expansionSize && legends?.length > (expansionSize - 1)">
            <button mat-icon-button (click)="scroll(true)">
              <i class="material-symbols-rounded me-3 pointer"
                 [ngClass]="expandMore ? 'text-color-dark' : 'text-color'">expand_more</i>
            </button>
            <button mat-icon-button (click)="scroll(false)">
              <i class="material-symbols-rounded pointer"
                 [ngClass]="expandMore === false ? 'text-color-dark' : 'text-color'">expand_less</i>
            </button>
          </div>

        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
