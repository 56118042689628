export enum ZenIconsEnum {
  // Commodity
  ELECTRIC = 'lightbulb',
  NAT_GAS = 'local_fire_department',

  // Pages
  SERVICE_ADDRESS = 'home_work',
  CUSTOMER = 'language',
  LEN = 'account_balance',
  METER = 'speed',
  BILL = 'receipt_long',
  REPORTS = 'assignment',
  MLOA_SIGNED = 'edit_square',

  // NavBar
  DASHBOARD = 'home_filled',
  PORTFOLIO = 'layers',
  OPPORTUNITIES = 'manage_search',
  RATE_CHECK = 'library_add_check',
  MARK_UNPROCURABLE = 'do_not_disturb_on',
  RATE_CLS = 'electric_meter',
  STAGGERED_START = 'calendar_clock',
  CONTRACTS = 'insert_drive_file',
  STATUS = 'task_alt',
  CONTRACT_STATUS = 'donut_large',
  RC_STATUS = 'donut_large',
  CONTRACTS_V2 = 'description',
  DETAILS = 'description',
  STACK_RANKING = 'trending_up',
  MARKET_PULSE = 'query_stats',
  MARKET_SCAN = 'frame_inspect',
  PAYMENTS = 'payments',

  // Action icons
  INFO = 'info_outline',
  SETTINGS = 'settings',
  BACK = 'chevron_left',
  PROCUREMENT_SNAPSHOT = 'photo_camera',

  // Contracts icons
  GREEN = 'eco',
  STANDARD_MIX = 'timelapse',

  // RULE: Add badge when contract is booked through our system
  VERIFIED = 'verified',
  STAR = 'star',

  PERSON = 'person',

  UPLOAD = 'upload',
  DOWNLOAD = 'download',
  MENU_ICON = 'more_horiz',
  DATABASE = 'database',
  STATE = 'place',
  USAGE = 'bar_chart',
  THUMB_UP = 'thumb_up',
  THUMB_DOWN = 'thumb_down',
  SWING = 'show_chart',
  SEND = 'send',
  CAPACITY = 'monitoring',
  TRANSMISSION = 'cable',

  DONE = 'check_circle',
  ERROR = 'error_outline',

  EDIT_DOC = 'edit_document', // Upload LOE

  DEACTIVATE = 'hide_source',

  STAGGERED = 'align_horizontal_left',
  CALENDAR = 'today',
  FUTURE_CALENDAR = 'event_upcoming',
  PRICING_STRATEGY = 'contract_edit', // Custom, Matrix
  DROPDOWN_ICON = 'expand_more',
  CSA = 'apartment',
  MATRIX = 'checklist',

  DETAILED = 'unfold_less',
  DELETE = 'delete',
  COLLAPSED = 'expand_content',

  SEARCH = 'search',
  UNLINK = 'link_off',

  // REPORT
  ELECTRIC_REPORT = 'electric_bolt', // electric_bolt
  NAT_GAS_REPORT = 'local_fire_department', // whatshot
  COMPARISON_REPORT = 'assignment', // Summary Report

  ON_UTILITY = 'cell_tower',
  FEE_BREAKDOWN_CALC = 'calculate',
  FEE_BREAKDOWN = 'monetization_on',

  CLONE = 'content_copy',
  REFRESH = 'refresh',
  REFRESH_OPPS = 'cached',

  ADD = 'add'
}
