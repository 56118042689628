import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {OrganizationManagementService} from './organization-management.service';
import {combineLatest, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {CustomerAndServiceAddressType, ServiceAddressSubType} from '../../_enums/entity-types.enum';

@Injectable({
  providedIn: 'root'
})
export class EntityTypeService {
  public static CUSTOMER_AND_SERVICE_ADDRESS_TYPES: CustomerAndServiceAddressType[];
  public static SERVICE_ADDRESS_TYPE_TO_SUBTYPE_MAP: Map<CustomerAndServiceAddressType, ServiceAddressSubType[]>;
  private baseUrl = environment.apiBaseUrl;

  private THUMBNAIL_PATH = '/assets/img/img-thumbnail.svg';

  constructor(private http: HttpClient,
              private orgMgtSvc: OrganizationManagementService) {
  }

  populateEntityTypes() {
    combineLatest([this.getCustomerAndServiceAddressTypes(), this.getServiceAddressTypeToSubtypeMap()])
      .subscribe(([customerAndSATypes, serviceAddressTypeToSubtypeMap]) => {
        // Populate customer/sa types sorted
        EntityTypeService.CUSTOMER_AND_SERVICE_ADDRESS_TYPES = customerAndSATypes.sort((a, b) => a.localeCompare(b));
        // Populate Service Address type to subtype map, sort by key and sort subtype lists by value
        EntityTypeService.SERVICE_ADDRESS_TYPE_TO_SUBTYPE_MAP = new Map<CustomerAndServiceAddressType, ServiceAddressSubType[]>();
        Object.keys(serviceAddressTypeToSubtypeMap).sort().forEach(key => {
          EntityTypeService.SERVICE_ADDRESS_TYPE_TO_SUBTYPE_MAP.set(key as CustomerAndServiceAddressType, serviceAddressTypeToSubtypeMap[key].sort((a, b) => a.localeCompare(b)));
        });
      });
  }

  private getCustomerAndServiceAddressTypes(): Observable<CustomerAndServiceAddressType[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customer-types`;
    return this.http.get<CustomerAndServiceAddressType[]>(url).pipe(take(1));
  }

  private getServiceAddressTypeToSubtypeMap(): Observable<Map<CustomerAndServiceAddressType, ServiceAddressSubType[]>> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/service-address-subtypes`;
    return this.http.get<Map<CustomerAndServiceAddressType, ServiceAddressSubType[]>>(url).pipe(take(1));
  }

  public getDefaultTypeIconPath(type: CustomerAndServiceAddressType): string {
    if (type == null) {
      return this.THUMBNAIL_PATH;
    }
    let iconPath = 'assets/img/types/';
    // Based on enum name, get the icon path
    switch (type) {
      case CustomerAndServiceAddressType.GOVERNMENT:
        iconPath += 'government';
        break;
      case CustomerAndServiceAddressType.EDUCATION:
        iconPath += 'education';
        break;
      case CustomerAndServiceAddressType.NON_PROFIT:
        iconPath += 'non-profit';
        break;
      case CustomerAndServiceAddressType.HEALTHCARE:
        iconPath += 'healthcare';
        break;
      case CustomerAndServiceAddressType.REAL_ESTATE:
        iconPath += 'real-estate';
        break;
      case CustomerAndServiceAddressType.HOSPITALITY:
        iconPath += 'hospitality';
        break;
      case CustomerAndServiceAddressType.FINANCIAL:
        iconPath += 'financial';
        break;
      case CustomerAndServiceAddressType.RETAIL:
        iconPath += 'retail';
        break;
      case CustomerAndServiceAddressType.MANUFACTURING:
        iconPath += 'manufacturing';
        break;
      case CustomerAndServiceAddressType.TRANSPORTATION:
        iconPath += 'transportation';
        break;
      case CustomerAndServiceAddressType.MEDIA_ENTERTAINMENT:
        iconPath += 'media-entertainment';
        break;
      case CustomerAndServiceAddressType.TECHNOLOGY:
        iconPath += 'technology';
        break;
      case CustomerAndServiceAddressType.OTHER:
        iconPath += 'other';
        break;
    }
    iconPath += '.svg';
    return iconPath;
  }
  public getDefaultLENIconPath(): string {
    return 'assets/img/types/len.svg';
  }
  public getDefaultMeterIconPath(): string {
    return 'assets/img/types/meter.svg';
  }
}
