import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {FormGroup, Validators} from '@angular/forms';
import {ItemListV4Model} from '../../_model/item-list-v4.model';
import {ActivationReasonIdEnum} from '../../../_modules/portfolio/_enums/activation-reason.enum';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ActivationAuditModel, ParentHierarchyEnum} from '../../_model/activation-audit-model';
import {PortfolioHierarchyLevelEnum} from '../../../_modules/portfolio/_enums/portfolio-hierarchy-level.enum';

@Component({
  selector: 'app-zen-activation-form',
  templateUrl: './zen-activation-form.component.html',
  styleUrls: ['./zen-activation-form.component.scss']
})
export class ZenActivationFormComponent implements OnInit, OnDestroy {
  @Input() activationAudit: ActivationAuditModel;
  @Input() hierarchyLevel: PortfolioHierarchyLevelEnum;
  @Input() form: FormGroup;
  reasonOptions: ItemListV4Model[] = [
    {key: ActivationReasonIdEnum.SOLD, value: 'Sold'},
    {key: ActivationReasonIdEnum.CLOSED, value: 'Closed'},
    {key: ActivationReasonIdEnum.LOST, value: 'Lost'},
    {key: ActivationReasonIdEnum.LEASED, value: 'Leased'}
  ];
  ActivationReasonIdEnum = ActivationReasonIdEnum;
  disabledByParent = false;

  private readonly destroy$ = new Subject<void>();
  parentName = ''; // default to this

  constructor() { }

  ngOnInit(): void {
    if (this.form) {

      // set initial values if we have them
      if (this.activationAudit?.reasonId > 0) {
        this.form.controls.reasonId.setValue(this.activationAudit.reasonId);
        this.form.controls.reasonCustom.setValue(this.activationAudit.reasonCustom);
        this.form.controls.assumption.setValue(this.activationAudit.assumption);
        this.form.controls.assumptionDt.setValue(this.activationAudit.assumptionDt);
        this.form.controls.assumption.updateValueAndValidity({emitEvent: false});
        this.setAssumptionValidations(this.activationAudit.assumption);

        // disable if not at same level
        if (
          (this.hierarchyLevel === PortfolioHierarchyLevelEnum.LENS && this.activationAudit.parent !== 'len') ||
          (this.hierarchyLevel === PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES && this.activationAudit.parent !== 'serviceAddress')
        ) {
          this.form.controls.reasonId.disable();
          this.form.controls.reasonCustom.disable();
          this.form.controls.assumption.disable();
          this.form.controls.assumptionDt.disable();
          this.disabledByParent = true;
          this.parentName = this.activationAudit.parent;
          if (this.parentName === ParentHierarchyEnum.len) {
            this.parentName = 'LEN';
          }
        }
      }

      // on value changes, process assumptions
      this.form.controls.reasonId.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
        next: (reasonId: ActivationReasonIdEnum) => {
          this.processAssumptions(reasonId);
        }
      });

      this.form.controls.assumption.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
        next: (_assumption: boolean) => {
          this.setAssumptionValidations(_assumption);
        }
      });
    }
  }

  processAssumptions(reasonId: number) {
    if (reasonId === ActivationReasonIdEnum.SOLD) {
      this.form.controls.assumption.setValue(true);
    } else {
      this.form.controls.assumption.setValue(false);
    }
    this.form.controls.assumption.updateValueAndValidity({emitEvent: false});
  }

  setAssumptionValidations(assumption: boolean) {
    if (assumption) {
      this.form.controls.assumptionDt.setValidators([Validators.required]);
    } else {
      this.form.controls.assumptionDt.setValidators(null);
    }
    this.form.controls.assumptionDt.updateValueAndValidity({emitEvent: false});
  }

  ngOnDestroy() {
    this.destroy$.next(); // Trigger the observable
    this.destroy$.complete(); // Complete the observable. So, further fires triggers wont happen.
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }
}
