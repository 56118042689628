<div class="zen-input-group field-input-container ng-pricing time-and-date-field w-100">
  <mat-form-field appearance="outline" class="left-field">
      <mat-label>{{label}}<span *ngIf="required" class="star">*</span></mat-label>
      <input matInput [matDatepicker]="datePicker" [formControl]="dateCtrl"
             (dateChange)="calendarCheck($event)" readonly (click)="datePicker.open()"
             [matDatepickerFilter]="dateFilter">
      <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline" class="right-field">
      <mat-label>Time<span *ngIf="required" class="star">*</span></mat-label>
      <mat-select [formControl]="timeCtrl" panelWidth="12rem">
          <mat-option *ngFor="let t of timeList" [value]="t.value" [disabled]="t.disabled">{{t.label}}</mat-option>
      </mat-select>
      <i class="material-symbols-rounded expand-icon" matSuffix>expand_more</i>
  </mat-form-field>
</div>
