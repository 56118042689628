<app-zen-dialog-wrapper [loading]="!hasLoaded" [data]="data" (onSubmit)="submit($event)" actionStyleCls="center" [errorMsg]="errorMsg"
                        [hideButton]="(formType || errorMsg) ? true : false">
  <div class="dialog-form-sec mb-3 {{errorMsg?'mb-0':''}}"
       [ngClass]="formType?'with-border px-2':''">
    <div class="zen-mat-form zen-customer-contact-form">
      <div class="row justify-content-center">
        <div class="col-10 justify-content-center">

          <div *ngIf="!formType">
            <div class="d-flex align-items-center justify-content-between">
              <div class="w-100 form-group" [ngClass]="{'zen-input-group': isEditable}">
                <mat-form-field class="left-field" [appearance]="'outline'">
                  <mat-label>Contact*</mat-label>
                  <input type="text"
                         matInput
                         [formControl]="contactCtrl"
                         [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <!-- Mat select Action Button -->
                    <mat-option class="action-button" (click)="handleAddContact()"><i
                      class="material-icons-round">add</i>New Contact
                    </mat-option>
                    <mat-option *ngFor="let option of filteredContacts | async" [value]="option">
                      {{option.label}}
                    </mat-option>
                  </mat-autocomplete>
                  <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  <mat-error *ngIf="contactCtrl.touched && contactCtrl.hasError('required')">Required Field*</mat-error>
                </mat-form-field>

                <div *ngIf="isEditable" class="right-field btn-action" (click)="handleEditContact()">
                  <i class="material-icons blue-1-color">edit</i>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="formType" class="text-start">
            <p class="form-title p-0 mb-4">Contact Details</p>
            <div *ngIf="formType === ZenFormTypesEnum.EDIT" class="mb-5">
              <app-zen-message [type]="ZenMessageType.INFO"
                               message="You are about to make a global change."></app-zen-message>
            </div>

            <!-- Contact form STARTS -->
            <app-zen-contact-form-fields [form]="form" [formType]="formType"
                                         (cancelEdit)="cancelEdit()"
                                         (onContactFormSubmit)="onContactFormSubmit()"></app-zen-contact-form-fields>
            <!-- Contact form ENDS -->
          </div>

        </div>
      </div>
    </div>
  </div>
</app-zen-dialog-wrapper>
