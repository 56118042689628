import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Input() showFilter: boolean;
  @Input() hideFilter: boolean;
  @Input() filtersApplied: boolean;
  @Output() showFilterChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  openFilters() {
      this.showFilterChange.emit(this.showFilter);
  }





}
