import {UntypedFormGroup} from '@angular/forms';

export function zenHasError(form: UntypedFormGroup, formControlName: string, formErrorName?: string): boolean {
  const hasAnyError = !form.controls[formControlName].valid &&
    form.controls[formControlName].touched &&
    form.controls[formControlName].errors;
  if (formErrorName) {
    return hasAnyError && form.controls[formControlName].errors[formErrorName];
  } else {
    return !!hasAnyError;
  }
}
