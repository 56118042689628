<app-zen-loader *ngIf="loading" [fixed]="true"></app-zen-loader>
<app-zen-dialog-wrapper [data]="data" [hideButton]="true">
  <div class="position-relative">
    <app-zen-tab-top-action *ngIf="tableTopConfig" [config]="tableTopConfig"></app-zen-tab-top-action>
  </div>

  <mat-tab-group class="v1" *ngIf="tableConfig">
    <mat-tab label="Billing Addresses ({{customerAddressData?.length || 0}})">
      <app-zen-mat-table [data]="customerAddressData" [tableConfig]="tableConfig"
                         [selection]="selectedRows"></app-zen-mat-table>
    </mat-tab>
  </mat-tab-group>
</app-zen-dialog-wrapper>

<popper-content #lenListPopper>
  <app-zen-popper [popperContent]="lenListPopper">
    <p class="font-weight-600">Associated Legal Entity Names</p>
    <ng-container *ngFor="let len of lenList">
      <p class="text-md mt-3 mb-0 hover-blue-1-color pointer" (click)="gotoLenDetails(len)">{{len.name}}</p>
    </ng-container>
  </app-zen-popper>
</popper-content>
