<div class="tili-tos p-0" *ngIf="documentUrl">
  <div class="brand-logo"></div>
  <div class="row" *ngIf="safeDocumentUrl" class="">
    <div class="col-12 sign-doc-container">
      <iframe width="100%" height="100%" [src]="safeDocumentUrl" type="application/pdf"></iframe>
    </div>
  </div>
  <div class="signature-overlay" *ngIf="!viewOnly">
    <div class="d-flex align-items-center justify-content-center mt-4" *ngIf="!signatureOverlayVisible">
      <button mat-button class="mx-3 my-2" (click)="closeSigningWindow()"
              [disabled]="signingDocument">
        <span class="label">Cancel Signing</span>
      </button>
      <button mat-raised-button color="accent" class="mx-3 my-2" (click)="showSignatureOverlay()">
        <span class="label">Sign this Document</span>
      </button>
    </div>
    <div class="signature-overlay-box mat-depth-1 pt-2" [hidden]="!signatureOverlayVisible">
      <div class="row" *ngIf="!viewOnly">
        <div class="col-8 col-sm-8 col-md-9 text-center">
          <div class="sig-contnr">
            <p>Full Signature:</p>
            <div #drawSignatureBox id="drawSignatureBox" class="signature" [class.hidden]="!drawSignature"
                 [class.valid]="drawSignatureValid" [class.invalid]="drawSignatureInvalid"></div>
            <p *ngIf="signatureErrorText" class="error-text">
              {{ signatureErrorText }}
            </p>
          </div>
        </div>
        <div class="col-4 col-sm-4  col-md-3 text-center">
          <div class="sig-contnr">
            <p>Initials:</p>
            <div #drawInitialsBox id="drawInitialsBox" class="signature" [class.hidden]="!drawInitials"
                 [class.valid]="drawInitialsValid" [class.invalid]="drawInitialsInvalid"></div>
            <p *ngIf="initialsErrorText" class="error-text">
              {{ initialsErrorText }}
            </p>
          </div>
        </div>
      </div>
      <div class="row my-4" *ngIf="acknowledgements && acknowledgements.length">
        <div class="col-12" *ngFor="let ack of acknowledgements; let i = index;">
          <div class="checkbox">
            <mat-checkbox [id]="'acceptSignatureCheckbox'+i" [name]="'acceptSignatureCheckbox'+i" (change)="updateCheckbox($event, ack)"
                          [checked]="ack.selected" binary="true"></mat-checkbox>
            <mat-label>{{ack.text}} </mat-label>
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-2" *ngIf="!viewOnly">
        <div class="col-12 text-center submit-container">
          <button mat-button class="mx-3 my-2" (click)="hideSignatureOverlay()"
                  [disabled]="signingDocument">
            <span class="label">Close</span>
          </button>
          <button mat-raised-button color="accent" class="mx-3 my-2" (click)="signDocument()"
                  [disabled]="!(drawSignatureValid && drawInitialsValid) || signingDocument || !!ifAcksNotChecked">
            <!-- ifAcksNotChecked returned a 1 or 0 but disabled treated 0 as true because it wasn't an explicit boolean. !! converts 0/1 to bool -->
            <span class="label">Submit</span>
          </button>
        </div>
      </div>
      <div *ngIf="signContractError" class="row">
        <div class="col-12 text-center">
          <p class="error-text">
            There was a problem signing your contract. Please try again or
            email the <a href="mailto:support@zentility.com">Onboarding Team</a> for assistance.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
