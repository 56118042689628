<app-zen-dialog-wrapper [data]="dialogData" (onSubmit)="submit($event)" [actionStyleCls]="'center'">

  <div class="my-2" *ngIf="dialogData.data?.message">
    <app-zen-message [message]="dialogData.data?.message" type="info"></app-zen-message>
  </div>

  <div class="my-3">
    <app-zen-mat-table *ngIf="dialogData.data?.tableConfig"
                       [tableConfig]="dialogData.data?.tableConfig"
                       [data]="dialogData.data?.data"></app-zen-mat-table>
  </div>
</app-zen-dialog-wrapper>
