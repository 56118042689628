<!-- Adding this app-layout component to support base font-size(62.5% -> 1px = 0.1rem) -->
<app-layout></app-layout>

<div class="story-zen-mat-form-fields">
  <div class="p-5">
    <h3 class="sub-header-bg">FORM FIELD - FREE INPUT TEXT </h3>
    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Default</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance">
                <mat-label>Label</mat-label>
                <input matInput>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Engaged</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance">
                <mat-label>Label</mat-label>
                <input matInput value="Text">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Disabled</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance">
                <mat-label>Label</mat-label>
                <input matInput disabled>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Engaged Disabled</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance">
                <mat-label>Label</mat-label>
                <input matInput value="Text" disabled>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Locked</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="locked">
                <mat-label>Label</mat-label>
                <input matInput value="Text" disabled>
                <i class="material-symbols-rounded" matSuffix>lock</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Error</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="submitted ng-invalid">
                <mat-label>Label</mat-label>
                <input matInput [formControl]="errorEmail" required>
                <mat-error *ngIf="errorEmail.invalid">Error Message*</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Engaged Error</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="submitted ng-invalid">
                <mat-label>Label</mat-label>
                <input matInput value="Text" [formControl]="errorEmailEngaged">
                <mat-error *ngIf="errorEmailEngaged.invalid">Error Message*</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Default Info</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance">
                <mat-label>
                  Label
                </mat-label>
                <input matInput>
                <i class="material-symbols-rounded info-icon" matSuffix>info</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-5">
    <h3 class="sub-header-bg">DROPDOWN SELECT</h3>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Disabled</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance">
                <mat-label>Label</mat-label>
                <mat-select disabled>
                  <mat-option *ngFor="let website of filteredContacts | async" [value]="website">
                    {{website.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Locked</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="locked">
                <mat-label>Label</mat-label>
                <mat-select disabled>
                  <mat-option *ngFor="let website of filteredContacts | async" [value]="website">
                    {{website.label}}
                  </mat-option>
                </mat-select>
                <i class="material-symbols-rounded" matSuffix>lock</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Edit Option</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5 d-flex align-items-center">
              <mat-form-field [appearance]="appearance">
                <mat-label>Label</mat-label>
                <mat-select>
                  <mat-option *ngFor="let website of filteredContacts | async" [value]="website">
                    {{website.label}}
                  </mat-option>
                </mat-select>
                <i class="material-symbols-rounded" matSuffix>expand_more</i>
              </mat-form-field>
              <i class="material-symbols-rounded edit-icon">edit</i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Standard Expanded</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <div class="d-flex align-items-center">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Label</mat-label>
                  <mat-select>
                    <mat-option *ngFor="let website of filteredContacts | async" [value]="website">
                      {{website.label}}
                    </mat-option>
                  </mat-select>
                  <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  <i class="material-symbols-rounded info-icon ms-3" matSuffix>info</i>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Expanded with search</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance">
                <mat-label>Label</mat-label>
                <input type="text" matInput [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <!-- Mat select Action Button -->
                  <mat-option class="action-button"><i
                    class="material-icons-round">add</i>New Contact
                  </mat-option>
                  <mat-option *ngFor="let option of filteredContacts | async" [value]="option">
                    {{option.label}}
                  </mat-option>
                </mat-autocomplete>
                <i class="material-symbols-rounded" matSuffix>expand_more</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Expanded with search and checkboxes</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" color="accent">
                <mat-label>Label</mat-label>
                <input type="text" matInput [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" multiple>
                  <!-- Mat select Action Button -->
                  <mat-option class="action-button"><i
                    class="material-icons-round">add</i>New Contact
                  </mat-option>
                  <mat-option *ngFor="let option of filteredContacts | async" [value]="option">
                    <mat-checkbox [checked]="option.selected" (click)="$event.stopPropagation()">
                      {{option.label}}
                    </mat-checkbox>
                  </mat-option>
                </mat-autocomplete>
                <i class="material-symbols-rounded" matSuffix>expand_more</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Expanded with search and checkboxes and add</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" color="accent">
                <mat-label>Label</mat-label>
                <input type="text" matInput [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <!-- Mat select Action Button -->
                  <mat-option class="action-button"><i
                    class="material-icons-round">add</i>New Contact
                  </mat-option>
                  <mat-option *ngFor="let option of filteredContacts | async" [value]="option">
                    <mat-checkbox [checked]="option.selected" (click)="$event.stopPropagation()">
                      {{option.label}}
                    </mat-checkbox>
                  </mat-option>
                </mat-autocomplete>
                <i class="material-symbols-rounded" matSuffix>expand_more</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Expanded without search</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" color="accent">
                <mat-label>Label</mat-label>
                <mat-select multiple>
                  <mat-option class="action-button">
                    <i class="material-symbols-rounded">add</i>Add New
                  </mat-option>

                  <mat-option *ngFor="let website of filteredContacts | async" [value]="website">
                    {{website.label}}
                  </mat-option>
                </mat-select>
                <i class="material-symbols-rounded" matSuffix>expand_more</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

  <div class="p-5">
    <h3 class="sub-header-bg">UPLOAD IMAGE BUTTONS</h3>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Default & Hover</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
<!--              <app-zen-upload-img-btn></app-zen-upload-img-btn>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Disabled</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
<!--              <app-zen-upload-img-btn [disabled]="true"></app-zen-upload-img-btn>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Locked</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
<!--              <app-zen-upload-img-btn [locked]="true"></app-zen-upload-img-btn>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Error</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
<!--              <app-zen-upload-img-btn [invalid]="true"></app-zen-upload-img-btn>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Loading</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
<!--              <app-zen-upload-img-btn [loading]="true"></app-zen-upload-img-btn>-->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="p-5">
    <h3 class="sub-header-bg">UPLOAD FILES BUTTONS </h3>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Default</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="input-upload-files">
                <mat-label>Label</mat-label>
                <input matInput>
                <i class="material-symbols-rounded" matSuffix>file_upload</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Disabled</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="input-upload-files">
                <mat-label>Label</mat-label>
                <input matInput disabled>
                <i class="material-symbols-rounded" matSuffix>file_upload</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Engaged Disabled</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="input-upload-files">
                <mat-label>Label</mat-label>
                <input matInput value="Text" disabled>
                <i class="material-symbols-rounded" matSuffix>file_upload</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Locked</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="input-upload-files">
                <mat-label>Label</mat-label>
                <input matInput value="Text" disabled>
                <i class="material-symbols-rounded" matSuffix>lock</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Error</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="submitted ng-invalid input-upload-files">
                <mat-label>Label</mat-label>
                <input matInput [formControl]="errorEmail" required>
                <mat-error *ngIf="errorEmail.invalid">Error Message*</mat-error>
                <i class="material-symbols-rounded" matSuffix>file_upload</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Engaged Error</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="submitted ng-invalid input-upload-files">
                <mat-label>Label</mat-label>
                <input matInput value="File Name" [formControl]="errorFileEngaged">
                <mat-error *ngIf="errorFileEngaged.invalid">Error Message*</mat-error>
                <i class="material-symbols-rounded" matSuffix>file_upload</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Loading</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" input-upload-files>
                <input matInput>
                <mat-spinner diameter="20" matPrefix [strokeWidth]="2" color="accent" class="loading"></mat-spinner>
                <i class="material-symbols-rounded" matSuffix>file_upload</i>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-5">
    <h3 class="sub-header-bg">Radio Buttons</h3>

    <p>Rule:Use these radio buttons across the platform </p>
    <div class="w-100 mb-4 ms-4">
      <div class="row w-100">
        <mat-radio-button checked [name]="'normal-radio'">Label</mat-radio-button>
      </div>
      <div class="row w-100">
        <mat-radio-button [name]="'normal-radio'">Label</mat-radio-button>
      </div>
      <div class="row w-100">
        <mat-radio-button checked [disableRipple]="true" disabled>Disabled Label</mat-radio-button>
      </div>
    </div>
  </div>

  <div class="p-5">
    <h3 class="sub-header-bg">CHECKBOXES</h3>

    <div class="w-100 mb-4 ms-4">
      <div class="row w-100">
        <mat-checkbox checked [name]="'normal-radio'" class="ms-0">Label</mat-checkbox>
      </div>
      <div class="row w-100">
        <mat-checkbox [name]="'normal-radio'" class="ms-0">Label</mat-checkbox>
      </div>
      <div class="row w-100">
        <mat-checkbox checked [disableRipple]="true" disabled class="ms-0">Disabled Label</mat-checkbox>
      </div>
      <div class="row w-100">
        <mat-checkbox checked [disableRipple]="true" [formControl]="errorEmail" class="ms-0">Invalid Label
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="p-5">
    <h3 class="sub-header-bg">DATE PICKER </h3>
    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Default</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance">
                <mat-label>MM/DD/YY-MM/DD/YY</mat-label>
                <input matInput [matDatepicker]="pickerDefault" placeholder="MM/DD/YY-MM/DD/YY">
                <mat-datepicker-toggle matSuffix [for]="pickerDefault"></mat-datepicker-toggle>
                <mat-datepicker #pickerDefault></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Disabled</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance">
                <mat-label>MM/DD/YY-MM/DD/YY</mat-label>
                <input matInput [matDatepicker]="pickerDisabled" placeholder="MM/DD/YY-MM/DD/YY" disabled>
                <mat-datepicker-toggle matSuffix [for]="pickerDisabled"></mat-datepicker-toggle>
                <mat-datepicker #pickerDisabled></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Error</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" [ngClass]="'submitted'">
                <mat-label>MM/DD/YY-MM/DD/YY</mat-label>
                <input matInput [matDatepicker]="pickerError" placeholder="MM/DD/YY-MM/DD/YY"
                       [formControl]="errorEmail">
                <mat-datepicker-toggle matSuffix [for]="pickerError"></mat-datepicker-toggle>
                <mat-datepicker #pickerError></mat-datepicker>
                <mat-error>Error Message*</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Error</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance">
                <mat-label>First campaign</mat-label>
                <mat-date-range-input
                  [formGroup]="campaignOne"
                  [rangePicker]="campaignOnePicker"
                  [comparisonStart]="campaignTwo.value.start"
                  [comparisonEnd]="campaignTwo.value.end">
                  <input matStartDate placeholder="Start date" formControlName="start">
                  <input matEndDate placeholder="End date" formControlName="end">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-5">
    <div class="w-100 pb-4">
      <h3 class="sub-header-bg">TEXT AREA </h3>
      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Default</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <mat-form-field [appearance]="appearance" class="zen-mat-textarea">
                  <mat-label>Label</mat-label>
                  <textarea matInput rows="4"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Disabled</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="zen-mat-textarea">
                <mat-label>Label</mat-label>
                <textarea matInput rows="4" disabled></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 mb-4">
      <div class="row">
        <div class="col-3 col-sm-3 col-md-3 text-end">
          <p class="ff-title">Error</p>
        </div>

        <div class="col-md-9">
          <div class="row w-100">
            <div class="col-12 col-sm-6 col-md-5">
              <mat-form-field [appearance]="appearance" class="zen-mat-textarea" [ngClass]="'submitted'">
                <mat-label>Label</mat-label>
                <textarea rows="4" matInput [formControl]="errorEmail"></textarea>
                <mat-error>Error Message*</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-5">
    <div class="w-100 pb-4">
      <h3 class="sub-header-bg">CHANGING UNIT BUTTONS</h3>

      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Default</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <div class="zen-input-group">
                  <mat-form-field [appearance]="appearance" class="left-field">
                    <mat-label>Estimated Annual Usage</mat-label>
                    <input matInput/>
                  </mat-form-field>
                  <mat-form-field [appearance]="appearance" class="right-field">
                    <mat-label></mat-label>
                    <mat-select value="kWh">
                      <mat-option value="kWh">kWh</mat-option>
                      <mat-option value="Dth">Dth</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Hint</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <div class="zen-input-group">
                  <mat-form-field [appearance]="appearance" class="left-field">
                    <mat-label>Estimated Annual Usage</mat-label>
                    <input matInput/>
                  </mat-form-field>
                  <mat-form-field [appearance]="appearance" class="right-field">
                    <mat-select value="kWh">
                      <mat-option value="kWh">kWh</mat-option>
                      <mat-option value="Dth">Dth</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  </mat-form-field>
                </div>
                <mat-hint class="info">
                  <div class="d-flex align-items-center">
                    <i class="material-symbols-rounded me-1">warning</i>
                    Value to be converted to platform default ([unit])
                  </div>
                </mat-hint>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Disabled</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <div class="zen-input-group">
                  <mat-form-field [appearance]="appearance" class="left-field">
                    <mat-label>Estimated Annual Usage</mat-label>
                    <input matInput disabled/>
                  </mat-form-field>
                  <mat-form-field [appearance]="appearance" class="right-field">
                    <mat-select disabled value="kWh">
                      <mat-option value="kWh">kWh</mat-option>
                      <mat-option value="Dth">Dth</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Engaged Disabled</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <div class="zen-input-group" [formGroup]="unitBtnsEngDisabled">
                  <mat-form-field [appearance]="appearance" class="left-field">
                    <mat-label>Estimated Annual Usage</mat-label>
                    <input matInput formControlName="usage"/>
                  </mat-form-field>
                  <mat-form-field [appearance]="appearance" class="right-field">
                    <mat-select formControlName="unit">
                      <mat-option [value]="'kWh'">kWh</mat-option>
                      <mat-option [value]="'Dth'">Dth</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Locked</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <div class="zen-input-group" [formGroup]="unitBtnsEngDisabled">
                  <mat-form-field [appearance]="appearance" class="left-field">
                    <mat-label>Estimated Annual Usage</mat-label>
                    <input matInput formControlName="usage"/>
                  </mat-form-field>
                  <mat-form-field [appearance]="appearance" class="right-field locked">
                    <mat-select formControlName="unit">
                      <mat-option [value]="'kWh'">kWh</mat-option>
                      <mat-option [value]="'Dth'">Dth</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>lock</i>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Error</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <div class="zen-input-group" [formGroup]="unitBtnsError">
                  <mat-form-field [appearance]="appearance" class="left-field submitted">
                    <mat-label>Estimated Annual Usage</mat-label>
                    <input matInput formControlName="usage"/>
                  </mat-form-field>
                  <mat-form-field [appearance]="appearance" class="right-field submitted">
                    <mat-select formControlName="unit">
                      <mat-option [value]="'kWh'">kWh</mat-option>
                      <mat-option [value]="'Dth'">Dth</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  </mat-form-field>
                </div>
                <mat-error>(!) Value to be converted to platform default ([unit])</mat-error>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Engaged Error</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <div class="zen-input-group" [formGroup]="unitBtnsErrorEngaged">
                  <mat-form-field [appearance]="appearance" class="left-field submitted">
                    <mat-label>Estimated Annual Usage</mat-label>
                    <input matInput formControlName="usage"/>
                  </mat-form-field>
                  <mat-form-field [appearance]="appearance" class="right-field submitted">
                    <mat-select formControlName="unit">
                      <mat-option [value]="'kWh'">kWh</mat-option>
                      <mat-option [value]="'Dth'">Dth</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  </mat-form-field>
                </div>
                <mat-error>(!) Value to be converted to platform default ([unit])</mat-error>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Loading</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <div class="zen-input-group">
                  <mat-form-field [appearance]="appearance" class="left-field">
                    <input matInput/>
                    <mat-spinner diameter="20" matPrefix [strokeWidth]="2" color="accent" class="loading"></mat-spinner>
                  </mat-form-field>
                  <mat-form-field [appearance]="appearance" class="right-field">
                    <mat-select value="kWh">
                      <mat-option value="kWh">kWh</mat-option>
                      <mat-option value="Dth">Dth</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">With Edit Option</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <div class="zen-input-group">
                  <mat-form-field class="left-field" [appearance]="'outline'">
                    <mat-label>Label</mat-label>
                    <mat-select>
                      <mat-option>1</mat-option>
                      <mat-option>2</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  </mat-form-field>
                  <div class="right-field btn-action">
                    <i class="material-icons blue-1-color">edit</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="p-5">
    <div class="w-100 pb-4">
      <h3 class="sub-header-bg">INPUT FORMATS</h3>
      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Phone</p>
            <small>Free text input</small>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Phone</mat-label>
                  <input [imask]="phoneMask" [unmask]="unmask" matInput/>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Zip</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Zip</mat-label>
                  <input matInput mask="00000||00000-0000"/>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-5">
    <div class="w-100 pb-4">
      <h3 class="sub-header-bg">TAGS</h3>
      <div class="w-100 mb-4">
        <div class="row">
          <div class="col-3 col-sm-3 col-md-3 text-end">
            <p class="ff-title">Customer Tags</p>
          </div>

          <div class="col-md-9">
            <div class="row w-100">
              <div class="col-12 col-sm-6 col-md-5">
                <app-zen-tags [type]="TagType.CUSTOMER" [selectedTags]="selectedTags"></app-zen-tags>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

