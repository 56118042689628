import * as moment from 'moment-timezone';

export const TimeSettings = {
  Zone: 'America/New_York',
  Format: 'YYYY-MM-DD'
}

export function convertEtcZoneDate(date) {
  return date ? moment(date).tz(TimeSettings.Zone).format(TimeSettings.Format) : null;
}

export function mapEtcZoneDate(date) {
  return date ? new Date(date).toUTCString() : null;
}

export function patchDateValue(date) {
  // @ts-ignore
  return date ? new Date(moment(date).add('1', 'day')) : null;
}
