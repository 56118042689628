import {NgModule} from '@angular/core';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { ZenThemeManagerModule } from './modules/zen-theme-manager';
import { ZenCommonServicesModule } from './modules/zentility/_shared/_zen-legacy-common/zen-common-services/zen-common-services.module';
import { ErrorInterceptorService } from './modules/zentility/_shared/_zen-legacy-common/zen-common-services/_services/error-interceptor.service';
import { TokenInterceptorService } from './modules/zentility/_shared/_zen-legacy-common/zen-common-services/_services/token-interceptor.service';
import { TiliServicesModule } from './modules/zentility/_shared/_zen-legacy-common/zen-common-services/tili-services/tili-services.module';
import { OrgInterceptorService } from './modules/zentility/_shared/_zen-legacy-common/zen-common-services/_services/org-interceptor.service';
import { environment } from '../environments';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { DeviceDetectorService } from 'ngx-device-detector';
import {CustomerIdSessionStore} from './modules/zentility/_shared/_stores/customer-id/customer-id-session.store';
import {ZenTacModule} from './modules/zentility/_modules/zen-tac/zen-tac.module';
import {PortfolioFilterSessionStore} from './modules/zentility/_shared/_stores/filter/portfolio-filter-session.store';
import {CommonModule} from '@angular/common';
import {ZenLegacyCommonModule} from './modules/zentility/_shared/_zen-legacy-common/zen-legacy-common.module';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ScrollToModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot(),
        ZenThemeManagerModule.forRoot(),
        TiliServicesModule.forRoot(),
        ZenCommonServicesModule.forRoot(),
        AkitaNgRouterStoreModule,
        ZenTacModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        ZenLegacyCommonModule,
        GuidedTourModule
    ],
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    DeviceDetectorService,
    CustomerIdSessionStore,
    PortfolioFilterSessionStore,
    GuidedTourService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrgInterceptorService,
      multi: true
    }
  ]
})
export class AppModule { }
