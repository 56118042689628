export enum ZenRcStatus {
  PROCESSING = 'Processing',
  DRAFT = 'Draft',
  TIMED_OUT = 'Timed Out',
  BIDDING = 'Bidding',
  SUPPLIER_REVIEW = 'Supplier Review',
  CURRENT = 'Current',
  EXPIRED = 'Expired',
  FUTURE = 'Future',
}

export enum ZenRcStatusClass {
  PROCESSING = 'processing',
  DRAFT = 'draft',
  TIMED_OUT = 'timed-out',
  BIDDING = 'bidding',
  SUPPLIER_REVIEW = 'supplier-review',
  CURRENT = 'current',
  EXPIRED = 'expired',
  REJECTED = 'rejected',
  ABORTED = 'aborted',
  FUTURE = 'future',
  BOOKED = 'booked',
  DECLINED = 'declined',
  UNKNOWN = 'unknown',
  ON_UTILITY = 'onutility'
}

