<app-zen-dialog-wrapper [data]="data" (onSubmit)="handleSubmit($event)">
  <div class="zen-image-cropper-dialog">

    <div class="d-flex flex-wrap my-5">
      <i (click)="rotateLeft()" class="material-symbols-rounded color-icon pointer p-3 m-3">rotate_left</i>
      <i (click)="rotateRight()" class="material-symbols-rounded color-icon pointer p-3 m-3">rotate_right</i>

      <i (click)="zoomOut()" class="material-symbols-rounded color-icon pointer p-3 m-3">zoom_out</i>
      <i (click)="zoomIn()" class="material-symbols-rounded color-icon pointer p-3 m-3">zoom_in</i>

      <button (click)="flipHorizontal()" class="m-3" color="accent" mat-raised-button>Flip horizontal</button>
      <button (click)="flipVertical()" class="m-3" color="accent" mat-raised-button>Flip vertical</button>

      <button class="m-3" color="accent" mat-raised-button
              (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio ? 'Fill Aspect Ratio' : 'Contain Within Aspect Ratio'}}</button>
      <button (click)="resetImage()" class="m-3" color="accent" mat-raised-button>Reset image</button>
    </div>

    <div class="d-flex justify-content-center mb-4" *ngIf="imageChangedEvent">
      <image-cropper [imageChangedEvent]="imageChangedEvent"
                     [maintainAspectRatio]="true"
                     [containWithinAspectRatio]="containWithinAspectRatio"
                     [aspectRatio]="aspectRatio"
                     [resizeToWidth]="512"
                     [cropperMinWidth]="128"
                     [onlyScaleDown]="true"
                     [roundCropper]="false"
                     [canvasRotation]="canvasRotation"
                     [transform]="transform"
                     [alignImage]="'center'"
                     class="checkerboard-bg"
                     [style.display]="showCropper ? null : 'none'"
                     format="png"
                     (imageCropped)="imageCropped($event)"
                     (imageLoaded)="imageLoaded()"
                     (cropperReady)="cropperReady($event)"
                     (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
  </div>
</app-zen-dialog-wrapper>
