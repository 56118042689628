import {Theme, ThemeName} from '../../../_models/theme.model';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../../environments';

const CURRENT_THEME_KEY = 'current-theme'; // used for testing and manual theme override
const DEFAULT_THEME_NAME: ThemeName = ThemeName.ZENTILITY;
let activeTheme: Theme;

@Injectable()
export class ThemeService {

  constructor() {
    // check if a localstorage override is set for the theme
    // if not, grab the hostname
    const website = (localStorage.getItem(CURRENT_THEME_KEY) !== null) ?
      localStorage.getItem(CURRENT_THEME_KEY) : window.location.hostname;

    let themeName = DEFAULT_THEME_NAME;
    // if cbre is in the host name, switch to the cbre theme
    if (website.toLowerCase().indexOf('cbre') !== -1) {
      themeName = ThemeName.CBRE;
    }

    // if patriot is in the host name, switch to the patriot theme
    if (website.toLowerCase().indexOf('patriot') !== -1) {
      themeName = ThemeName.PATRIOT;
    }

    if (website.toLowerCase().indexOf('tili.ai') !== -1) {
      themeName = ThemeName.TILI;
    }

    // we check if the environment has the theme override flag in true to get the theme name from the environment
    if (environment.theme.override) {
      themeName = environment.theme.name;
    }
    this.setTheme(themeName);
  }

  setTheme(themeName: ThemeName) {
    let theme: Theme = Object.assign(new Theme(), {
      baseDomain: 'zentility.com',
      name: DEFAULT_THEME_NAME.toString(),
      companyName: 'Zentility'
    });
    switch (themeName) {
      case ThemeName.CBRE:
        theme.baseDomain = 'cbreenergy.com';
        theme.name = themeName.toString();
        theme.companyName = 'CBRE Energy';
        break;
      case ThemeName.PATRIOT:
        theme.baseDomain = 'patriotenergy.app';
        theme.name = themeName.toString();
        theme.companyName = 'Patriot';
        break;
      case ThemeName.TILI:
        theme.baseDomain = 'tili.ai';
        theme.name = themeName.toString();
        theme.companyName = 'tiLi';
        break;
    }
    activeTheme = theme;
  }

}
