import { Component, Input, ViewChild } from '@angular/core';
import {ZenTableMenuOption} from '../zen-mat-table/zen-mat-table.component';

@Component({
  selector: 'app-zen-menu-item',
  templateUrl: './zen-mat-menu-item.component.html',
  styleUrl: './zen-mat-menu-item.component.scss'
})
export class ZenMatMenuItemComponent {
  @Input() menuItems: ZenTableMenuOption[];
  @Input() element: Object;

  @ViewChild('childMenu') public childMenu;

}
