<section class="transaction-history">
  <ul id='contract-timeline' class="progressbar" *ngIf="zenTimeline && zenTimeline.length > 0"
      [class.white-bg]="whiteBg">
    <li *ngFor="let details of zenTimeline" [ngClass]="[zenTimeline.length === 5 ? 'length-5' : 'length-4', details?.className ? details.className : '']"
      [class.current-step]="details.title === currentStep"
      (click)="details.className === 'completed' && goBack.emit(details)">
      <h4>{{details?.title | titlecase}}</h4>
    </li>
  </ul>
</section>
