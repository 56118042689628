import {Injectable} from '@angular/core';
import moment from 'moment';
import {Observable} from 'rxjs';

@Injectable()
export class CacheService {

  constructor() {
  }

  /**
   * Adds a value to the cache
   * @param url
   * @param requestObj
   * @param value
   * @param prefix
   * @param ttl
   */
  public addValue(url: string, requestObj: any, value: any, prefix = '', ttl = moment().add(5, 'minutes').toDate()): void {
    localStorage.setItem(
      this.createCacheKey(url, requestObj, prefix),
      JSON.stringify({
          expires: ttl.getTime(),
          value: btoa(unescape(encodeURIComponent(JSON.stringify(value))))
      })
    );
  }

  /**
   * Converts an observable to a promise and then caches the result
   * @param url
   * @param requestObj
   * @param observableValue
   * @param prefix
   * @param ttl
   */
  public addObservableValue(url: string, requestObj: any, observableValue: Observable<any>, prefix = '', ttl = moment().add(5, 'minutes').toDate()): void {
    observableValue.toPromise().then(res => this.addValue(url, requestObj, res, prefix));
  }

  /**
   * Gets the cache value
   * @param url
   * @param requestObj
   * @param prefix
   */
  public getValue(url: string, requestObj: any, prefix = ''): any {
    let cacheResult = null;
    let item = localStorage.getItem(this.createCacheKey(url, requestObj, prefix));
    if (item !== null && JSON.parse(item).expires > new Date().getTime()) {
      cacheResult = JSON.parse(decodeURIComponent(escape(atob(JSON.parse(item).value))));
    }
    return cacheResult;
  }

  /**
   * Deletes a value from the cache
   * @param url
   * @param requestObj
   * @param value
   * @param prefix
   * @param ttl
   */
  public deleteValue(url: string, requestObj: any, value: any, prefix = '', ttl = moment().add(5, 'minutes').toDate()): void {
    localStorage.removeItem(this.createCacheKey(url, requestObj, prefix));
  }

  /**
   * Clears all cache values
   */
  public clearAllCache(): void {
    const items = {...localStorage};
    Object.keys(items).filter(k => k.startsWith('cache_')).forEach(k => {
      localStorage.removeItem(k);
    });
  }

  /**
   * Clears all cache by the prefix name
   * @param prefix
   */
  public clearCacheByName(prefix: string) {
    const items = {...localStorage};
    Object.keys(items).filter(k => k.startsWith('cache_' + prefix + '_')).forEach(k => {
      localStorage.removeItem(k);
    });
  }

  /**
   * Creates the cache key
   * @param url
   * @param object
   * @param prefix
   */
  public createCacheKey(url: string, object: any, prefix = ''): string {
    if (prefix !== '') {
      prefix = prefix + '_';
    }
    let objFlat = this.flattenObject(object);
    return 'cache_' + prefix + this.hashCode(JSON.stringify(objFlat, Object.keys(objFlat).sort()) + url).toString(10);
  }

  flattenObject(ob): object {
    let toReturn = {};

    for (let i in ob) {
      if (!ob.hasOwnProperty(i)) { continue; }

      if ((typeof ob[i]) === 'object') {
        let flatObject = this.flattenObject(ob[i]);
        for (let x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) { continue; }
          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  };

  hashCode(str: string) {
    // tslint:disable-next-line:no-bitwise
    return str.split('').reduce(function(a, b) {a = ((a << 5) - a) + b.charCodeAt(0); return a & a}, 0);
  }

}
