import {Component, Inject, OnDestroy} from '@angular/core';
import {ZenBaseComponent} from '../zen-base/zen-base.component';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {ZenToastDataModel} from '../zen-toast/zen-toast.component';
import {JenZenImgType} from '../jen-zen-img/jen-zen-img.component';

@Component({
  selector: 'app-zen-toast-snackbar',
  templateUrl: './zen-toast-snackbar.component.html',
  styleUrls: ['./zen-toast-snackbar.component.scss']
})
export class ZenToastSnackbarComponent extends ZenBaseComponent implements OnDestroy {
  overlayContainerTag: Element;
  JenZenImgType = JenZenImgType;

  constructor(public snackBarRef: MatSnackBarRef<ZenToastSnackbarComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: ZenToastDataModel) {
    super();
    const _element = document.getElementsByClassName('cdk-overlay-container')
    if (_element?.length) {
      this.overlayContainerTag = _element[_element?.length - 1];
      this.appendCustomClsWithOverlayContainer();
    }
  }

  ngOnDestroy() {
    this.removeCustomClsFromOverlayContainer();
  }

  appendCustomClsWithOverlayContainer() {
    // Adding custom class to avoid conflicts with other designs.
    this.overlayContainerTag.className = this.overlayContainerTag.className + ' zen-toast-help-overlay ';
  }

  removeCustomClsFromOverlayContainer() {
    this.overlayContainerTag.className = this.overlayContainerTag.className.replace(/zen-toast-help-overlay/g, '');
  }
}
