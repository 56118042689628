<div class="position-relative">
  <div class="zen-error">
    <div class="d-flex flex-column justify-content-center text-center">
      <i class="material-symbols-rounded error-icon">error_outline</i>
      <p class="error-text">
        It appears something went wrong. The issue has been reported and we are working to resolve it.
      </p>
    </div>
  </div>
</div>
