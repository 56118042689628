<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" [actionStyleCls]="'center'">
  <form class="zen-base-fontsize">
    <div class="mt-3 mb-5">
      <mat-form-field appearance="outline" class="zen-mat-textarea">
        <textarea matInput [formControl]="feedback" rows="5"></textarea>
      </mat-form-field>
      <mat-error *ngIf="feedback.touched && feedback.hasError('maxlength') else hint">Maximum allowed characters exceeded.</mat-error>
      <ng-template #hint>
        <mat-hint>{{CHAR_LIMIT}} Character Limit</mat-hint>
      </ng-template>
    </div>
  </form>
</app-zen-dialog-wrapper>
