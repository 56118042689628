import {Component, Input} from '@angular/core';
import {FeeDetailsError, RateCheckFeeDetails} from '../../_model/rate-check-v4.model';
import {ZenNumberFormat} from '../../_enums/zen-number-format.enum';

@Component({
  selector: 'app-zen-total-fee-error',
  templateUrl: './zen-total-fee-error.component.html',
  styleUrl: './zen-total-fee-error.component.scss'
})
export class ZenTotalFeeErrorComponent {
  @Input() data: {feeError: FeeDetailsError; feeDetails: RateCheckFeeDetails; isElectric: boolean; };
  ZenNumberFormat = ZenNumberFormat;
  numFormat: ZenNumberFormat;

  constructor() {
    this.numFormat = this.data?.isElectric ? ZenNumberFormat.ELECTRIC_FEE_IN_DOLLARS : ZenNumberFormat.GAS_FEE;
  }
}
