<div class="zen-dialog" *ngIf="!this.loading; else loadingTemplate">
      <i class="material-icons close-icon" (click)="data.onClose()" >close</i>
      <h1 *ngIf="electricMeterTotalCount !== 0 || gasMeterTotalCount !== 0" mat-dialog-title class="dialog-title">Portfolio Management</h1>
      <div *ngIf="this.selectedCommodity?.value != null else commoditySection"  mat-dialog-content>
        <div *ngIf="this.bodyHtml" class="body-text" [innerHTML]="this.bodyHtml" ></div>

        <!-- Dynamic Body Content -->
        <div *ngIf="unprocurableStatusBodyText" class="body-text">
          {{ unprocurableStatusBodyText }}
        </div>

        <!-- Dynamic Table for Unprocurable Status -->
        <div class="d-flex justify-content-center my-2" *ngIf="unprocurableStatusData && unprocurableStatusData.length">
          <table class="zen-confirm-tbl clean-table pb-3">
            <thead>
            <tr>
              <th class="ps-3">Statuses</th>
              <th class="pe-3">Meters</th>
            </tr>
            </thead>
            <tbody>
            <!-- Procurable Row -->
            <tr *ngIf="procurableMeterCount > 0">
              <td class="reason-cell">
                <mat-checkbox *ngIf="!isSingleCheckboxScenario" [checked]="procurableChecked" (change)="toggleProcurable($event)">

                </mat-checkbox>
                Procurable
              </td>
              <td class="meter-count-cell">{{ procurableMeterCount }}</td>
            </tr>

            <!-- Non-Procurable Parent Row -->
            <tr *ngIf="unprocurableMeterCount > 0 && !isSingleCheckboxScenario">
              <td class="reason-cell">
                <mat-checkbox *ngIf="!isSingleCheckboxScenario" [checked]="unprocurableChecked" [indeterminate]="unprocurableIndeterminate" (change)="toggleUnprocurable($event)">

                </mat-checkbox>
                Non-Procurable
                <button class="toggle-button" (click)="toggleShowUnprocurableReasons()">
                  <mat-icon class="align-content-end">{{ showUnprocurableReasons ? 'expand_more' : 'chevron_right' }}</mat-icon>
                </button>
              </td>
              <td class="meter-count-cell">{{ this.unprocurableMeterCount }}</td>
            </tr>

            <!-- Non-Procurable Child Rows -->
            <ng-container *ngIf="showUnprocurableReasons">
              <tr *ngFor="let status of unprocurableStatusData; let i = index" [hidden]="status.id === null">
                <td class="reason-cell">
                  <mat-checkbox *ngIf="!isSingleCheckboxScenario" class="ms-4" [checked]="status.checked" (change)="toggleReason($event, i)">
                  </mat-checkbox>
                  {{ status.name }}
                </td>
                <td class="meter-count-cell">{{ meterCountMap[status.id] || 0 }}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>




        <div class="slider-section my-3 d-flex flex-column align-items-start">
          <div>
          <mat-slide-toggle [formControl]="slider?.formCtrl">{{slider?.label}}
          </mat-slide-toggle>
            <i class="material-icons-outlined info-icon"
               [popper]="unprocurableStatusInfo"
               [popperHideOnScroll]="true"
               [popperHideOnClickOutside]="true"
               popperTrigger="click"
               popperApplyClass="sm"
               popperPlacement="bottom">
              info
            </i>
          </div>
        </div>

        <div class="mt-5"  *ngIf="slider?.formCtrl?.value !== true">
          <div class="d-flex align-items-start flex-wrap" style="margin-left: 4rem;">
            <!-- Reason Field -->
            <mat-form-field [appearance]="appearance" class="reason-half-width me-4">
              <mat-label>{{unprocurableDropdown?.label}}</mat-label>
              <mat-select [formControl]="unprocurableDropdown?.formCtrl">
                <mat-option *ngFor="let option of unprocurableDropdown?.options" [value]="option.value">
                  {{ option.label }}
                </mat-option>
              </mat-select>
              <i class="material-symbols-rounded" matSuffix>expand_more</i>
              <mat-error *ngIf="unprocurableDropdown?.formCtrl.hasError('required')">
                Required Field*
              </mat-error>
            </mat-form-field>

            <!-- Set Until Field -->
            <mat-form-field class="reason-half-width ms-1" [appearance]="appearance">
              <mat-label>Set Until</mat-label>
              <input matInput name="contract-end" [matDatepicker]="dp" readonly (click)="dp.open()"
                     [formControl]="this.unprocurableSetUntil" placeholder="Set Until" [min]="tomorrow">
              <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
              <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>
          </div>

          <!-- Unprocurable Notes Field -->
          <div class="d-flex-column form-group">
            <mat-form-field [appearance]="appearance" class="medium-field zen-mat-textarea">
              <mat-label>Add a note</mat-label>
              <textarea matInput [formControl]="this.unprocurableNotes" rows="3" maxlength="200"></textarea>
              <mat-hint align="end">{{this.unprocurableNotes?.value?.length || 0}} / 200</mat-hint>
            </mat-form-field>
            <div *ngIf="this.existingNotesHtml"  class="clickable-text" (click)="updateExistingNotes()"
                 [popper]="existingNotesHtml"
                 [popperHideOnScroll]="true"
                 [popperHideOnClickOutside]="true"
                 popperTrigger="click"
                 popperApplyClass="sm"
                 popperPlacement="bottom">
              View Existing Notes
            </div>
          </div>

        </div>

          <div mat-dialog-actions class="center mt-3">
              <button mat-button [ngClass]="'mdc-button--raised mat-mdc-raised-button'"
                      [disabled] = "showLoadingSpinner"
                      (click)="submit()" [color]="'primary'" >
                Confirm
                <mat-spinner *ngIf="showLoadingSpinner" class="button-click-spinner" color="accent" diameter="20"></mat-spinner>
              </button>
          </div>
  </div>
  <ng-template class="d-flex flex-wrap justify-content-center align-items-center" #commoditySection>
    <!-- If there are no meters, show the "Oops" message -->
    <div *ngIf="electricMeterTotalCount === 0 && gasMeterTotalCount === 0">
      <h1 mat-dialog-title class="dialog-title">Oops....</h1>
      <div class="body-text">
        Your chosen entities don't contain any meters, please update your selection to include meters.
      </div>
    </div>

    <!-- Else, show the commodity selection options -->
    <div *ngIf="electricMeterTotalCount > 0 || gasMeterTotalCount > 0">
      <div class="body-text mb-3">
        {{ commoditySelectionBodyText }}
      </div>

      <div class="d-flex justify-content-center">
        <app-zen-icon-button [title]="'Electricity (' + electricMeterTotalCount + ')'" [icon]="ZenIcons.ELECTRIC"
                             [selected]="this.selectedCommodity.value === Commodity.Electricity"
                             [unselected]="this.selectedCommodity.value === Commodity.Gas"
                             class="me-5"
                             (click)="updateCommodity(Commodity.Electricity)"></app-zen-icon-button>

        <app-zen-icon-button [title]="'Natural Gas (' + gasMeterTotalCount + ')'" [icon]="ZenIcons.NAT_GAS"
                             [selected]="this.selectedCommodity.value === Commodity.Gas"
                             [unselected]="this.selectedCommodity.value === Commodity.Electricity"
                             (click)="updateCommodity(Commodity.Gas)"></app-zen-icon-button>
      </div>
    </div>
  </ng-template>

</div>
<!-- Loading State Template -->
<ng-template #loadingTemplate>
  <div class="zen-dialog loading-placeholder">
    <mat-spinner diameter="50" color="primary"></mat-spinner>
    <div class="loading-text">Loading, please wait...</div>
  </div>
</ng-template>

<popper-content #unprocurableStatusInfo>
  <app-zen-popper title="Procurable Status" [closeable]="true"  [popperContent]="unprocurableStatusInfo">
    <div class="mt-3 mb-1">
      When the Procurable switch is toggled off, this meter will not be available for energy procurement in our system.
      You can provide a reason, set a ‘Procurable Until’ date (to automatically re-enabled future procurement), and add any notes for reminders.
    </div>
  </app-zen-popper>
</popper-content>


<popper-content #existingNotes>
  <app-zen-popper title="Existing Notes" [closeable]="true"  [popperContent]="existingNotes">
    <div class="mt-3 mb-1 note-entry" [innerHTML]="this.existingNotesHtml ? this.existingNotesHtml : ''">
    </div>
  </app-zen-popper>
</popper-content>
