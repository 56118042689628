import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {OrganizationSettings} from '../../_shared/_zen-legacy-common/_models/organization';
import {OrganizationManagementService} from '../../_shared/_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {NavigationEnd, Router} from '@angular/router';

export enum ZenFooterTypes {
  ADVISOR = 'ADVISOR',
  CUSTOMER = 'CUSTOMER'
}

@Component({
  selector: 'app-zen-footer',
  templateUrl: './zen-footer.component.html',
  styleUrls: ['./zen-footer.component.scss']
})
export class ZenFooterComponent implements OnInit {
  @Input() footerType: ZenFooterTypes;
  @Input() alignment: 'left-align' | 'right-align' = 'right-align';
  ZenFooterTypes = ZenFooterTypes;
  @Input() backgroundColor?: string;
  innerBackgroundColor: string;
  settings: OrganizationSettings;

  constructor(public authSvc: AuthenticationService,
              private orgSvc: OrganizationManagementService,
              private router: Router) {
  }

  ngOnInit() {
    this.orgSvc.observeOrganization().subscribe(o => {
      this.settings = o.settings;
    });
    this.innerBackgroundColor = this.backgroundColor ? this.backgroundColor : '#ffffff';

    // If footerType is not mentioned. Then the footerType is decided w.r.t authSvc.isAdvisor()
    // For Login, forgot password & reset password pages footerType -> Customer
    if (!this.footerType) {
      this.handleFooterTypeChange();
      this.router.events.subscribe(val => {
        if (val instanceof NavigationEnd) {
          this.handleFooterTypeChange();
        }
      });
    }
  }

  handleFooterTypeChange() {
    // Adding this delay to handle session store updates
    setTimeout(() => {
      if (this.authSvc.isAdvisor()) {
        this.footerType = ZenFooterTypes.ADVISOR;
      } else {
        this.footerType = ZenFooterTypes.CUSTOMER;
      }
    }, 100);
  }

}
