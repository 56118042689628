import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {ZenDialogActionButton} from '../../_dialogs/zen-dialog/zen-dialog.component';

@Component({
  selector: 'app-zen-file-upload',
  templateUrl: './zen-file-upload.component.html',
  styleUrl: './zen-file-upload.component.scss'
})
export class ZenFileUploadComponent {
  @Input() formCtrl: UntypedFormControl;
  @Input() label: string;
  @Input() uploading: boolean;
  @Input() documentName: string;
  @Input() downloadable: boolean;
  @Input() isDisabled: boolean;

  @Output() downloadDocument: EventEmitter<void> = new EventEmitter();
  @Output() onFileInput: EventEmitter<FileList> = new EventEmitter();

}
