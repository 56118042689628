import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {OrganizationManagementService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {PortfolioServiceAddressesModel} from '../_model/portfolio-service-addresses.model';
import {getParams} from '../../../_shared/_zen-legacy-common/_utils/set-param-util';

@Injectable({
  providedIn: 'root'
})
export class PortfolioServiceAddressService {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  getOrgServiceAddresses(active?: boolean, page?: number, size?: number, sortBy?: string, sortDir?: string, filters?: string, riskWindowDays?: number): Observable<PortfolioServiceAddressesModel> {
    let params = getParams({
      page: page,
      size: size,
      riskWindowDays: riskWindowDays,
      sort: (sortBy && sortDir) ? `${sortBy},${sortDir}` : undefined
    })
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/service-addresses?filters=${encodeURIComponent(filters)}`;
    return this.http.get<PortfolioServiceAddressesModel>(url, {params: params}).pipe(take(1));
  }

}
