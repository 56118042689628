import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ButtonActionTypes, ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ValidPatterns} from '../../_zen-legacy-common/_models/common/email-pattern';
import {ProfileService} from '../../_zen-legacy-common/zen-common-services/_services/profile.service';
import {MustMatch} from '../../_zen-legacy-common/_utils/mustmatch-utils';
import {ZenUserUpdateModel, ZenUserV1Model} from '../../_zen-legacy-common/_models/zen-user';
import {SignupService} from '../../_zen-legacy-common/zen-common-services/tili-services/services/signup.service';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {NoSpacesMask, PhoneMask} from '../../_enums/zen-masks.enum';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {PasswordConfig} from '../../_zen-legacy-common/_models/organization';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-zen-user-profile-dialog',
  templateUrl: './zen-user-profile-dialog.component.html',
  styleUrls: ['./zen-user-profile-dialog.component.scss']
})
export class ZenUserProfileDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  form: UntypedFormGroup;
  // The PASSWORD_PLACEHOLDER was changed to mixed case with trailing numbers and a
  // special character to pass all levels of validation on entry to the edit screen
  PASSWORD_PLACEHOLDER = 'Password_Placeholder123$';
  loading: boolean;
  user: ZenUserV1Model;
  appearance: MatFormFieldAppearance = 'outline';

  // Phone Number Mask
  // Ref: https://imask.js.org/guide.html
  // lazy: false,  // make placeholder always visible
  unmask = true; // to remove ext text and special chars from the input.

  NoSpacesMask = NoSpacesMask;
  passwordConfig: PasswordConfig;
  errorMsg: string;

  constructor(public dialogRef: MatDialogRef<ZenUserProfileDialogComponent>,
              private zenDialogSvc: ZenDialogMsgService, public orgMgtSvc: OrganizationManagementService,
              private profileSvc: ProfileService, private signupSvc: SignupService,
              @Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              private formBuilder: UntypedFormBuilder,
              public translateSvc: TranslateService
              ) {
    super(translateSvc);
  }

  ngOnInit(): void {
    this.orgMgtSvc.organizationSubject.subscribe(org => {
      this.passwordConfig = org.settings.passwordConfig;
      this.loadForm();
    });
    this.loadUserData();
  }

  get controls() {
    return this.form.controls;
  }

  loadForm(): void {
    this.form = this.formBuilder.group({
      id: new UntypedFormControl(),
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      title: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(16)]),
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(ValidPatterns.EMAIL_PATTERN)]),
      password: new UntypedFormControl('', [Validators.pattern(this.passwordConfig.pattern)]),
      confirmPassword: new UntypedFormControl('')
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    // Email change validation
    this.controls.email.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(email => {
          this.signupSvc.validateExistentAccount(this.form.controls.email, this.user?.email, true);
        }
      );
  }

  loadUserData() {
    this.profileSvc.getUser().subscribe(user => {
      this.user = user;
      this.form.patchValue(user, {emitEvent: false});
    });
  }

  submit(btn: ZenDialogActionButton) {
    this.errorMsg = null;
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      btn.command(false);
    } else {
      this.form.markAllAsTouched();
      if (this.form.valid) {
        this.loading = true;
        this.profileSvc.updateUser(this.buildSubmitData()).subscribe(user => {
          this.loading = false;
          btn.command(user);
        }, e => {
          console.log('ERR: updateUser ', e);
          this.loading = false;
          this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
          this.zenDialogSvc.openToast(false);
        });
      } else {
        this.zenDialogSvc.openToast(false, ZenErrorMsgEnum.ERR_FORM_FIELD);
        if (this.controls.password.errors && this.controls.password.errors.pattern) {
          this.errorMsg = this.passwordConfig.description;
        }
        if (this.controls.confirmPassword.errors && this.controls.confirmPassword.errors.mustMatch) {
          this.errorMsg = ZenErrorMsgEnum.PASSWORD_MATCH;
        }
        if (this.controls.email.errors) {
          this.errorMsg = ZenErrorMsgEnum.EMAIL_EXISTS;
        }
      }
    }
  }

  buildSubmitData(): ZenUserUpdateModel {
    let {firstName, lastName, email, title, phone, password} = this.form.value;
    let _data: ZenUserUpdateModel = {firstName, lastName, email, title, phone};
    if (this.controls.password.dirty) {
      _data.password = password ? btoa(password).replace(/\=/g, '.') : null;
    }
    return _data;
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }
}
