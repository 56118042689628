// Phone Number Mask
// Ref: https://imask.js.org/guide.html
// lazy: false,  // make placeholder always visible
export const PhoneMask = {mask: '(000) 000-0000', lazy: true};
export const CaPhoneMask = {mask: '+1 (000) 000-0000', lazy: true};
export const GbPhoneMask = {mask: '+44 0000 000000', lazy: true};

// Zip mask
export const DefaultZipMask = '00000||00000-0000';
export const CaAndGbZipMask = 'AAA AAA';

export const NumberMask = {
  mask: Number,
  thousandsSeparator: ',', lazy: true,
  scale: 4, // decimal places
  radix: '.',  // fractional delimiter
  overwrite: true
};

export const TermMask = {
  mask: Number,
  thousandsSeparator: ',', lazy: true,
  scale: 0, // decimal places
  radix: '.'  // fractional delimiter
};

export const FeeMask = {
  mask: Number,  // enable number mask
  // other options are optional with defaults below
  scale: 2,  // digits after point, 0 for integers
  padFractionalZeros: true,  // if true, then pads zeros at end to the length of scale
  normalizeZeros: true,  // appends or removes zeros at ends
  radix: '.',  // fractional delimiter
  thousandsSeparator: ',',
  overwrite: true
};

export const NoSpacesMask = {
  mask: function (value) {
    return value.replace(/ /g, '');
  },
  // for preprocessing input
  prepare: function (str) {
    return str.replace(/ /g, '');
  }
}
