import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {MatrixMessageService} from '../../../_modules/zen-market-pulse/_services/matrix-message.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {ZenRatesEnum} from '../../_enums/zen-rates.enum';

@Component({
  selector: 'app-zen-matrix-set-pct-dialog',
  templateUrl: './zen-matrix-set-pct-dialog.component.html',
  styleUrls: ['./zen-matrix-set-pct-dialog.component.scss']
})
export class ZenMatrixSetPctDialogComponent implements OnInit {
  pctValue: UntypedFormControl = new UntypedFormControl(null, [
    Validators.min(ZenRatesEnum.ELECTRICITY_MIN_RATE * 100),
    Validators.max(ZenRatesEnum.ELECTRICITY_MAX_RATE * 100)]);
  ZenRatesEnum = ZenRatesEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenMatrixSetPctDialogComponent>,
              public matMsgService: MatrixMessageService) {
  }

  ngOnInit(): void {
  }

  submit(btn: ZenDialogActionButton) {
    if (btn.label === 'Cancel') {
      this.dialogRef.close();
    } else {
      this.pctValue.markAllAsTouched();
      if (this.pctValue.valid) {
        btn.command({pctValue: this.pctValue.value});
      }
    }
  }

}
