import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-zen-upload-file',
  templateUrl: './zen-upload-file.component.html',
  styleUrls: ['./zen-upload-file.component.scss']
})
export class ZenUploadFileComponent implements OnInit {
  @Input() accept = ['application/pdf'];
  @Input() disabled: boolean;
  @Input() locked: boolean;
  @Input() label: string;
  @Input() loading: boolean;
  @Input() invalid: boolean;
  @Input() downloadable: boolean;
  @Output() onUpload: EventEmitter<File> = new EventEmitter();
  @Output() onDownload: EventEmitter<File> = new EventEmitter();
  @Output() onDelete: EventEmitter<void> = new EventEmitter();
  file: File;

  @Input() documentName: string;

  constructor() { }

  ngOnInit(): void {
  }

  onFileSelected(files: FileList) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (!this.accept.includes(mimeType)) {
      return;
    } else {
      this.onUpload.emit(files[0]);
      this.documentName = files[0]?.name;
    }
  }


}
