<div class="zen-datepicker" *ngIf="formCtrl">
  <p class="zdp-p-wrap text-md" (click)="dp.open()"
     [class.invalid]="formCtrl.touched && formCtrl.invalid && !dp.opened">
    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>

    <span class="zdp-label">Set Until {{formCtrl.value ? (formCtrl.value | date: 'MM/dd/yy') : ''}}</span>
    <i class="material-symbols-rounded zdp-dropdown-icon">{{ZenIcons.DROPDOWN_ICON}}</i>

    <input class="date-picker-input" [matDatepicker]="dp" readonly (click)="dp.open()" [formControl]="formCtrl"
           (dateChange)="dateChanged.emit(formCtrl.value)">
    <mat-datepicker #dp></mat-datepicker>
  </p>

  <mat-error class="zdp-mat-error" *ngIf="!dp.opened && formCtrl.touched && formCtrl.hasError('required')">Required Field*</mat-error>
</div>
