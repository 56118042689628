import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../../../../../../environments';
import { PaymentDetail } from '../tili-services/models/payment';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private http: HttpClient, private authService: AuthenticationService) { }

  getOrganizationPayments(): Observable<PaymentDetail[]> {
    const url = `${environment.apiBaseUrl}/v2/organizations/${this.authService.getOrganizationId()}/zen-payments`;
    return this.http.get<PaymentDetail[]>(url).pipe(take(1));
  }

  sendPaymentFeedback(paymentId: number, feedback: string): Observable<boolean> {
    const url = `${environment.apiBaseUrl}/v2/organizations/${this.authService.getOrganizationId()}/zen-payments/${paymentId}/feedback`;
    return this.http.post<boolean>(url, { feedback }).pipe(take(1));
  }

  downloadPaymentDocument(id: number, fileName: string) {
    const url = `${environment.apiBaseUrl}/v2/organizations/${this.authService.getOrganizationId()}/zen-payments/${id}/file`;
    return this.http.get(url, { responseType: 'blob' }).pipe(map(data => {
      let fileType = this.getFileType(data.type);
      const pdfUrl = URL.createObjectURL(data);
      const anchor: any = document.createElement('a');
      anchor.download = `${fileName}.${fileType}`;
      anchor.href = pdfUrl;
      anchor.click();
    })).toPromise();
  }

  getFileType(type) {
    if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      || type === 'application/vnd.ms-excel'
      || type === 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet') {
      return 'xlsx';
    } else {
      return  type.split('/').pop();  // type -> application/pdf
    }
  }
}
