import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';
import {RouterQuery} from '@datorama/akita-ng-router-store';
import {delay} from 'rxjs/operators';

export interface PortfolioFilterSessionState {
  portfolioFilterString: string;
  rateCheckFilterString: string;
  contractFilterString: string;
  dashboardFilterString: string;
  stackRankingFilterString: string;
}

export function createInitialState(): PortfolioFilterSessionState {
  return {
    portfolioFilterString: null,
    rateCheckFilterString: null,
    contractFilterString: null,
    dashboardFilterString: null,
    stackRankingFilterString: null
  };
}

@Injectable()
@StoreConfig({name: 'portfolioFilterSession', resettable: true})
export class PortfolioFilterSessionStore extends Store<PortfolioFilterSessionState> {
  constructor() {
    super(createInitialState());
  }
}
