import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomerTiliService} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/services/customer-tili.service';
import {AuthenticationService, SkipTacInfo} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogDataModel} from '../../../_shared/_dialogs/zen-dialog/zen-dialog.component';

@Component({
  selector: 'app-zen-tac-dialog',
  templateUrl: './zen-tac-dialog.component.html',
  styleUrls: ['./zen-tac-dialog.component.scss']
})
export class ZenTacDialogComponent implements OnInit {
  existingUser: boolean;
  loading: boolean;
  tacId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenTacDialogComponent>,
              private router: Router,
              private route: ActivatedRoute,
              private customerTiliService: CustomerTiliService,
              private auth: AuthenticationService) {
    this.existingUser = this.data?.data?.existingUser;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params: SkipTacInfo) => {
      this.auth.skipTac(params);
    });
  }

  onSubmit() {
    if (this.existingUser) {
      this.loading = true;
      this.customerTiliService.acceptTacFn(this.auth.getCurrentCustomerId(), this.tacId).then(tacApproved => {
        console.log('TAC %s for Existing User ', tacApproved ? 'Approved' : 'Not Approved');
        this.dialogRef.close();
        if (this.route.snapshot.queryParams && this.route.snapshot.routeConfig.path.toLowerCase().includes('sign')) {
          this.router.navigate(['..']);
        }
      }).finally(() => {
        this.loading = false;
      });
    } else {
      this.dialogRef.close(true);
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  onTacId(tacId: string) {
    this.tacId = tacId;
  }

}
