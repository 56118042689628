import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, Validators} from '@angular/forms';
import {ZenTableMenuOption} from '../../_components/zen-mat-table/zen-mat-table.component';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {UnprocurableStatus} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import {hasError} from '../../_zen-legacy-common/_utils/zen-utils';

export enum ZenDialogDataType {
  CONFIRM = 'confirm',
  ADD_DIALOG = 'ADD_DIALOG',
  EDIT_DIALOG = 'EDIT_DIALOG',
  GUIDANCE_SUCCESS = 'guidance_success',
  GUIDANCE_ERROR = 'guidance_error',
  MATRIX_DOWNLOADER = 'matrix',
  UNPROCURABLE_STATUS = 'UNPROCURABLE_STATUS'
}

export enum ButtonTypes {
  MAT_BUTTON = 'mat-button',
  MAT_RAISED_BUTTON = 'mdc-button--raised mat-mdc-raised-button',
  MAT_FLAT_BUTTON = 'mdc-button--flat mat-mdc-flat-button',
}

export enum ButtonActionTypes {
  SUBMIT = 'SUBMIT', CANCEL = 'CANCEL'
}

export interface ZenDialogActionButton {
  label: string;
  btnType?: ButtonTypes;
  color?: 'primary' | 'accent' | 'warn';
  command?: ({}) => void;
  actionType?: ButtonActionTypes;
  styleClass?: string;
  prefixIcon?: string;
  suffixIcon?: string;
  menuItems?: ZenTableMenuOption[];
  showLoader?: boolean;
}

export interface ZenDialogDataModel {
  data?: any;
  type?: ZenDialogDataType;
  header?: {
    title: string; icon?: string; iconClass?: string;
    popperDet?: {title?: string; message: string};
  };
  onClose?: () => void;
  hideCloseX?: boolean;
  bodyText?: string;
  bodyHtml?: string;
  textInput?: {label: string; formCtrl: UntypedFormControl, charLimit?: number};
  numberInput?: {label: string; formCtrl: UntypedFormControl, mask: Object, min?: number; max?: number};
  deleteConfirmation?: {deleteHeader: string; deleteBody: string, deleteClicked?: boolean, deleteCommand?: () => void};
  checkBox?: {label: string; formCtrl: UntypedFormControl};
  actionButtons?: ZenDialogActionButton[];
}

export enum ZenIconColors {
  SUCCESS = 'success_1', DANGER = 'danger_1', WARN = 'warn_1'
}

@Component({
  selector: 'app-zen-dialog',
  templateUrl: './zen-dialog.component.html',
  styleUrls: ['./zen-dialog.component.scss']
})
export class ZenDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  ZenDialogDataType = ZenDialogDataType;
  ButtonActionTypes = ButtonActionTypes;
  icon: { name: string, color: ZenIconColors; };
  showLoadingSpinner = false; // used to control button action loader
  clickedButtonLabel: string; // used to detect button which button was clicked if there are several buttons
  appearance: MatFormFieldAppearance = 'outline';
  isDatePickerVisible: boolean;
  constructor(
    public dialogRef: MatDialogRef<ZenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
    public translateSvc: TranslateService
  ) {
    super(translateSvc);
    if (this.data && this.data.type) {
      this.handleIcon();
    }
    if (data.numberInput && !data.numberInput.formCtrl) {
      data.numberInput.formCtrl = new UntypedFormControl('');
    }

  }

  ngOnInit(): void {
  }

  handleIcon() {
    switch (this.data.type) {
      case ZenDialogDataType.CONFIRM:
        this.icon = {name: 'warning', color: ZenIconColors.WARN};
        break;
      case ZenDialogDataType.GUIDANCE_ERROR:
        this.icon = {name: 'error_outline', color: ZenIconColors.DANGER};
        break;
      case ZenDialogDataType.GUIDANCE_SUCCESS:
        this.icon = {name: 'check_circle', color: ZenIconColors.SUCCESS};
        break;
    }
  }

  executeCommand(btn: ZenDialogActionButton): void {
    if ((this.isFormValid() || btn.label === 'Cancel') && btn.command) {
      this.clickedButtonLabel = btn.label; // set label of clicked button to make it easy to detect which button was clicked
      if (btn.showLoader) {
        this.showLoadingSpinner = true; // show button loader
      }
      btn.command(this.data); // execute command
    }
  }

  isFormValid(): boolean {
    let isValid = true;
    if (this.data.numberInput?.formCtrl && !this.data.numberInput.formCtrl.valid) {
      isValid = false;
    }
    if (this.data.textInput?.formCtrl && !this.data.textInput.formCtrl.valid) {
      isValid = false;
    }
    if (this.data.checkBox?.formCtrl && !this.data.checkBox.formCtrl.valid) {
      isValid = false;
    }

    return isValid;
  }

  deleteToggled() {
    this.data.deleteConfirmation.deleteClicked = !this.data.deleteConfirmation.deleteClicked;
  }

}
