export interface ZenLocaleModel {
  id: CountryEnum; // US, GB, CA
  name: string; // Long name of country
  currency: {
    abbreviation: string; // USD, CAD, GBP
    symbol: string; // $,
  },
  formatting: {
    dateFormat: string; // MM/dd/yyyy || DD/mm/yyyy
    phoneFormat: string; // xxx-xxx-xxxx
  },
  nomenclature: {
    supplier: string;
    suppliers: string;
    utilityShort: string;
    utilityLong: string;
    rate: string;
    rates: string;
    state: string;
  },
  features: {
    featureMarketCheck: boolean;
    featureMarketPulse: boolean;
    featureRateSchedules:  boolean;
  }
}

export enum CountryEnum {
  US = 'US', GB = 'GB', CA = 'CA'
}
