import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {Supplier} from '../../../_models/service-address-settings';
import {SupplierAlert} from '../../../_models/supplier-alert';
import {CountryEnum} from '../../../../_model/zen-locale.model';

export interface MatrixSuppliersModel {
  suppliers: MatrixSupplier[]
}

export interface MatrixSupplier {
  bfaLastUpdatedOn: string;
  brokerFeeAgreementId: string;
  id: string;
  name: string;
  supportedStates: Array<string>;
  supportedUtilities: Array<number>;
  supportsAutoSign: boolean
  urlCreateAccount: string;
  urlLogin: string
}


@Injectable({
    providedIn: 'root'
})
export class SupplierService {
    baseUrl = environment.apiBaseUrl;

    constructor(private http: HttpClient) { }

    getSuppliers(country: CountryEnum = CountryEnum.US): Observable<Supplier[]> {
        return this.http.get<Supplier[]>(this.baseUrl + `/v1/mp/suppliers?country=${country}`).pipe(take(1));
    }

    getMatrixSuppliers(): Observable<MatrixSuppliersModel> {
        return this.http.get<MatrixSuppliersModel>(this.baseUrl + `/v1/mp/suppliers?matrixOnly=${true}`).pipe(take(1));
    }

    getSupplierAlerts(): Observable<SupplierAlert[]> {
        return this.http.get<SupplierAlert[]>(this.baseUrl + `/supplier-alerts`).pipe(take(1));
    }
}
