import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {DecimalPipe} from '@angular/common';
import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../../../../environments';
import {
  FilterClass,
  FilterClassBoolean,
  FilterClassNumber,
  FilterClassWithId,
  PriceEntity,
  PriceResponseDvFilter,
  PriceResponseDvFilterCriteria, ProductEnum
} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {MatrixPricingDataService} from './matrix-pricing-data.service';
import * as moment from 'moment-timezone';
import {MatrixSearchCriteria} from '../_models/matrix-search-criteria.model';
import {MatrixSavingsVars} from '../_models/matrix-search-savings-vars.model';
import {convertEtcZoneDate, TimeSettings} from '../../../_shared/_zen-legacy-common/_utils/etc-timezone-utils';
import {RateCheckRequestService} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/services/rate-check-request.service';
import * as currencyUtils from '../../../_shared/_zen-legacy-common/_utils/currency-utils';
import {distinctUntilChanged, take} from 'rxjs/operators';
import {Moment} from 'moment';
import {ContractService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/contract.service';
import {MatrixMessageService} from './matrix-message.service';
import {stateSupportsRateClass} from '../../../_shared/_zen-legacy-common/_utils/state-utils';
import {LoadFactorLabelsMatrixRC} from '../../zen-reports/_modules/zen-rate-check-report/_models/matrix-rate-check.interface';
import {BrokerFeesCalculated} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/models/broker-fees';
import {ExternalQueryModal} from '../_models/MarketPulseQueryParams';
import { SupplierService } from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/services/supplier.service';
import { groupBy } from '../../../_shared/_zen-legacy-common/_utils/array-utils';
import * as _ from 'lodash';
import {RateCheckV4Service} from '../../../_shared/_services/v4/rate-check-v4.service';
import {ServiceAddressRow} from '../../zen-rate-checks/_model/zen-electric-rc-request.model';
import {MATRIX_DV_SORT_TYPES} from '../_models/matrix-data-view.model';
import {feeErrorCheck} from '../../../_shared/_utils/zen-fee.util';
import {ContractSourceFriendlyText} from '../../../_shared/_enums/zen-contract.enum';
import {RateCheckFeeDetails} from '../../../_shared/_model/rate-check-v4.model';

export interface FeeError {
  valueHigh?: boolean;
  valueLow?: boolean;
  belowZero?: boolean;
  hasError?: boolean;
}

export enum TX_SWITCH_OPTIONS {
  MOVE_IN = 'Move-In',
  SWITCH = 'Switch'
}

export interface ZenMatrixFilterTreeModel {
  label: string;
  fieldName: string;
  parent: boolean;
  children: FilterClassBoolean[];
}

@Injectable({
  providedIn: 'root'
})
export class MatrixPricingHelperService {
  baseUrl = environment.apiBaseUrl;
  clearSearch: EventEmitter<boolean> = new EventEmitter<boolean>();
  onPriceSelectionChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading = false;
  dataLoading: boolean;
  effectivePriceDateDisplay: string;
  feesIncluded = false; // whether or not fees are to be included from server-side pricing
  searchCriteria: MatrixSearchCriteria;

  // API response
  priceEntities: PriceEntity[] = [];

  // flags for hiding and showing dropdowns
  enabledDropdowns = {
    utility: false,
    rateClass: false,
    zone: false,
    loadFactor: false,
    peakDemandKw: false
  }

  // metadata
  errorMessage = '';
  showErrorPopup = false;
  showNoRecords = false;

  // drop downs
  utilityList: FilterClassWithId[];
  productDetailList: Observable<FilterClass[]>;
  loadFactorList: FilterClass[];
  termList: Observable<FilterClassNumber[]>;
  rateClassList: FilterClass[];
  zoneList: FilterClass[];


  selectedPricingIds: string[] = [];
  selectedPriceEntities: PriceEntity[] = [];

  savingsVars: MatrixSavingsVars;
  savingsUpdated = new BehaviorSubject<boolean>(false);

  // total fees
  tiliFeeMils: number;
  zenFeeMils: number;
  totalFeeMils: number;
  hasFee = false;

  // price response -> filter values
  matrixPriceFilterRes: PriceResponseDvFilter;
  clonedPriceEntities: PriceEntity[] = [];
  dataViewFilterCriteria: PriceResponseDvFilterCriteria;
  priceResUpdated = new BehaviorSubject<boolean>(false);
  priceResRequested = new BehaviorSubject<boolean>(false);
  savingAnalysis = {
    medianRateCents: null,
    utilityRateCents: null,
    supplierRateCents: null,
    /** The value stored here just a reference to compare the changed Vs initial values. While calling generateRcReq. */
    supplierRateCentsOriginal: null,
    utilityRateCentsOriginal: null
  };
  /** Customer RC matrix pricing tool **/
  selectedServiceAddresses: ServiceAddressRow[] = [];

  isStaggeredStart: boolean;

  // Label includes rounding, value does not
  totalAnnualMwhUsageLabel = '0';
  totalAnnualMwhUsageValue = 0;

  curBrokerFeesCalc: RateCheckFeeDetails;

  // handle fees
  feeError: FeeError;
  maxFeeMilsLimit = 12; // Keeping 12 here to support the service methods.
  minFeeMilsLimit = 0;

  /** Externalize MarketPulse - UI iFrame/Component setup.
   * Eg., - ?state=TX&utilityId=34&annualUsageKwh=5000&enableSupplierStatuses=true&selectionMode=multi */
  externalQueryParams: ExternalQueryModal = {};

  // Filter sorting
  sortKey: MATRIX_DV_SORT_TYPES = MATRIX_DV_SORT_TYPES.PRICE_LOW_TO_HIGH;
  sortOptions: FilterClass[] = [
    {label: 'Price Low to High', value: MATRIX_DV_SORT_TYPES.PRICE_LOW_TO_HIGH},
    {label: 'Price High to Low', value: MATRIX_DV_SORT_TYPES.PRICE_HIGH_TO_LOW},
    {label: 'Savings Low to High', value: MATRIX_DV_SORT_TYPES.SAVINGS_LOW_TO_HIGH},
    {label: 'Savings High to Low', value: MATRIX_DV_SORT_TYPES.SAVINGS_HIGH_TO_LOW},
    {label: 'Term Short to Long', value: MATRIX_DV_SORT_TYPES.TERM_SHORT_TO_LONG},
    {label: 'Term Long to Short', value: MATRIX_DV_SORT_TYPES.TERM_LONG_TO_SHORT},
  ];

  private static calcSavingsPct(originalRate: number, newRate: number) {
    return ((originalRate - newRate) / originalRate) * 100;
  }

  public static calcSavingsAmount(originalRate: number, newRate: number, totalUsage: number) {
    return ((originalRate * totalUsage) - (newRate * totalUsage)) / 100;
  }

  constructor(private matrixPricingService: MatrixPricingDataService, private rateCheckRequestService: RateCheckRequestService,
              private contractService: ContractService, private matMsgService: MatrixMessageService,
              private decimalPipe: DecimalPipe,
              private rcV4Svc: RateCheckV4Service,
              private scrollToService: ScrollToService,
              private supplierService: SupplierService) {
    this.init(false);
  }

  init(feesIncluded: boolean) {
    this.dataLoading = false;
    let today = this.normalizeToTimeZone(moment());
    this.effectivePriceDateDisplay = today.format('MM/DD/YYYY');
    let startDate = moment().month(moment().month() + 1).date(1).toDate();
    this.searchCriteria = {
      state: undefined, utilityId: undefined,
      rateClass: undefined, zone: undefined,
      loadFactor: LoadFactorLabelsMatrixRC.NONE, utilityName: undefined, peakDemandKw: undefined,
      terms: [6, 12, 18, 24, 30, 36, 48, 60], annualMwhUsage: null,
      passThroughCapAndTrans: false, renewable: undefined,
      startDate: startDate, effectivePriceDate: today.toDate()
    };
    this.savingsVars = {
      currentSupplierRateCents: undefined,
      currentUtilityRateCents: undefined,
      medianRateCents: null
    };
    this.feesIncluded = feesIncluded;
    this.clearData();
  }

  disableAndResetAllDropDowns() {
    Object.keys(this.enabledDropdowns).forEach(k => {
      this.enabledDropdowns[k] = false;
    });

    this.searchCriteria.rateClass = undefined;
    this.searchCriteria.zone = undefined;
    this.searchCriteria.loadFactor = LoadFactorLabelsMatrixRC.NONE;

    this.rateClassList = [];
    this.loadFactorList = [];
    this.zoneList = [];
  }

  getAndLoadUtilities() {
    this.loading = true;
    this.disableAndResetAllDropDowns();
    this.searchCriteria.annualMwhUsage = undefined;
    this.matMsgService.getSupplierProgressMessage(this.searchCriteria.state, null, null, this.isExternalised);
    this.matMsgService.clearData()
    const effectivePriceString = this.getEffectiveDateString();
    // tslint:disable-next-line:max-line-length
    // this.utilityList = this.matrixPricingService.getUtilityList(effectivePriceString, this.selectedState);
    this.matrixPricingService.getUtilityList(effectivePriceString, this.searchCriteria.state).subscribe(e => {
        // change value to be the ID
        this.utilityList = e.map(util => {
          util.value = util.id;
          return util;
        });

        this.loading = false;
        this.enabledDropdowns.utility = true;

        /** To handle externalized MarketPulse query params. */
        if (this.externalQueryParams.utilityId) {
          this.searchCriteria.utilityId = this.externalQueryParams.utilityId;
          this.utilitySelectedFunction(true);
          this.recalculateFees();
          if (!stateSupportsRateClass(this.searchCriteria.state)) {
            this.handleFullSearch(false);
          }
        }
      }, () => {
        this.loading = false;
        this.showError('Error getting Utilities.  If the problem persists please contact support.');
      }
    );
    this.clearResults();
  }

  get isExternalised() {
    return Object.values(this.externalQueryParams)?.length > 0;
  }

  loadData() {
    this.disableAndResetAllDropDowns();
    this.matMsgService.clearData();
    const effectivePriceString = this.getEffectiveDateString();
    // tslint:disable-next-line:max-line-length
    const readyForFastLookup = Boolean(this.externalQueryParams.utilityId && (this.externalQueryParams.rateClass || this.externalQueryParams.state && this.externalQueryParams.state === 'TX') && this.externalQueryParams.state);
    if (readyForFastLookup) {
      this.searchCriteria.utilityId = this.externalQueryParams.utilityId;
      this.searchCriteria.rateClass = this.externalQueryParams.rateClass;
      this.searchCriteria.state = this.externalQueryParams.state;
      this.handleFullSearch(false);
      this.matrixPricingService.getUtilityList(effectivePriceString, this.searchCriteria.state).subscribe(e => {
          // change value to be the ID
          this.utilityList = e.map(util => {
            util.value = util.id;
            return util;
          });
          this.loading = false;
          this.enabledDropdowns.utility = true;
          /** To handle externalized MarketPulse query params. */
          if (this.externalQueryParams.utilityId) {
            this.searchCriteria.utilityId = this.externalQueryParams.utilityId;
            this.utilitySelectedFunction(true);
            this.recalculateFees();
          }
        }, () => {
          this.loading = false;
          this.showError('Error getting Utilities.  If the problem persists please contact support.');
        }
      );
    } else {
      this.getAndLoadUtilities();
    }
  }

  utilitySelectedFunction(suppressLoadingWidget: boolean) {
    this.selectedPricingIds = [];
    if (this.utilityList && this.utilityList.length) {
      this.searchCriteria.utilityName = this.utilityList.filter(u => u.id === this.searchCriteria.utilityId)[0]?.label;
    }

    this.searchCriteria.annualMwhUsage = undefined;
    //  If someone changes utilities, we need to wipe all data
    const tempUtil = this.searchCriteria.utilityId;
    const utilityName = this.searchCriteria.utilityName;
    this.clearData();
    // Reenable utility drop down and restore value
    this.enabledDropdowns.utility = true;
    this.searchCriteria.utilityId = tempUtil;

    //  If we have a valid utility load additional drop down data
    if (tempUtil) {
      this.matMsgService.getSupplierProgressMessage(this.searchCriteria.state, tempUtil, utilityName, this.isExternalised);
      this.getAndLoadZones();
      if (stateSupportsRateClass(this.searchCriteria.state)) {
        this.getAndLoadRateClasses();
      }
      this.getAndLoadFactors(suppressLoadingWidget);
    }
  }

  getAndLoadRateClasses() {
    this.enabledDropdowns.peakDemandKw = false;
    this.enabledDropdowns.zone = false;
    const effectivePriceString = this.getEffectiveDateString();
    // tslint:disable-next-line:max-line-length
    this.matrixPricingService.getRateScheduleList(effectivePriceString, this.searchCriteria.state, this.searchCriteria.utilityId, true)
      .subscribe(e => {
        this.rateClassList = e;
        if (e.length > 0) {
          this.rateClassList = e;
          this.enabledDropdowns.rateClass = true;
          this.searchCriteria.rateClass = e[0].value; // TODO: change to default value in the future
        }
        this.loading = false;
      }, error => {
        this.loading = false;
        this.showError('Error getting Rate Classes.  If the problem persists please contact support.');
        console.log(error);
      });
  }

  getAndLoadZones() {
    this.enabledDropdowns.peakDemandKw = false;
    this.enabledDropdowns.zone = false;
    this.enabledDropdowns.rateClass = false;
    const effectivePriceString = this.getEffectiveDateString();
    // tslint:disable-next-line:max-line-length
    this.matrixPricingService.getZoneList(effectivePriceString, this.searchCriteria.state, this.searchCriteria.utilityId)
      .subscribe(zones => {
        if (zones.length > 0) {
          this.zoneList = zones.filter(z => z.value);
          this.enabledDropdowns.zone = true;
          this.searchCriteria.zone = this.zoneList[0].value; // setting default zone value
        }
        this.loading = false;
      }, error => {
        this.loading = false;
        this.showError('Error getting Zones.  If the problem persists please contact support.');
        console.log(error);
      });
  }

  getAndLoadFactors(suppressLoadingWidget: boolean) {
    this.loading = !suppressLoadingWidget;
    const effectivePriceString = this.getEffectiveDateString();
    this.matMsgService.clearData();
    // tslint:disable-next-line: max-line-length
    this.matrixPricingService.getLoadFactorList(effectivePriceString, this.searchCriteria.state, this.searchCriteria.utilityId, this.searchCriteria.zone)
      .subscribe(e => {
        if (e.length > 0) {
          this.loadFactorList = e.map(lf => {
            // The api return All Load Factors obj value as empty string.
            // Material select wont work with empty string as default. So, replacing '' with NONE.
            lf.value = lf.value === '' ? LoadFactorLabelsMatrixRC.NONE : lf.value;
            return lf;
          });
          this.enabledDropdowns.peakDemandKw = true;
          this.enabledDropdowns.loadFactor = true;
          this.searchCriteria.loadFactor = LoadFactorLabelsMatrixRC.NONE;
        }
        this.loading = false;
      }, error => {
        this.loading = false;
        this.showError('Error getting Load Factors.  If the problem persists please contact support.');
        console.log(error);
      });
    this.clearResults();
  }

  getEffectiveDateString() {
    return moment(this.searchCriteria.effectivePriceDate).format(TimeSettings.Format);
  }

  getStartDateString(startDate) {
    return moment(startDate).format(TimeSettings.Format);
  }

  normalizeToTimeZone(dt: Moment): Moment {
    return moment(dt).tz(TimeSettings.Zone);
  }

  handleFullSearch(suppressLoadingWidget: boolean) {
    this.selectedPriceEntities = [];
    this.clearFilterCriteria();

    const effectivePriceString = this.getEffectiveDateString();
    let startDate = convertEtcZoneDate(this.searchCriteria.startDate);
    let paramsList: any = {
      effectivePriceDate: effectivePriceString,
      state: this.searchCriteria.state,
      kwhUsage: this.searchCriteria.annualMwhUsage ? this.searchCriteria.annualMwhUsage * 1000 : 0,
      startDate: startDate,
      utilityId: this.searchCriteria.utilityId
    };
    if (!this.feesIncluded) {
      // fees should not be included
      paramsList = {...paramsList, tfo: 0};
      paramsList = {...paramsList, includeTaxes: false};
    } else {
      paramsList = {...paramsList, includeTaxes: true};
    }
    if (this.searchCriteria.terms.length > 0) {
      paramsList = {...paramsList, terms: this.searchCriteria.terms.join(',')};
    }
    if (this.searchCriteria.rateClass != null && this.searchCriteria.rateClass !== '') {
      paramsList = {...paramsList, rateSchedule: this.searchCriteria.rateClass};
    }
    if (this.searchCriteria.zone != null && this.searchCriteria.zone !== '') {
      paramsList = {...paramsList, zone: this.searchCriteria.zone};
    }

    if (this.searchCriteria.loadFactor != null && this.searchCriteria.loadFactor !== LoadFactorLabelsMatrixRC.NONE) {
      paramsList = {...paramsList, loadFactor: this.searchCriteria.loadFactor};
    }
    if (this.searchCriteria.renewable != null) {
      paramsList = {...paramsList, isRenewable: this.searchCriteria.renewable};
    }

    this.getPricingApiCall(paramsList, suppressLoadingWidget);
  }

  getPricingApiCall(paramsList, suppressLoadingWidget: boolean, scrollToElement?) {
    this.dataLoading = !suppressLoadingWidget;
    this.priceResRequested.next(true);
    combineLatest([
      this.matrixPricingService.getPricingV3(paramsList),
      this.supplierService.getSupplierAlerts()
    ]).subscribe(([res, supplierAlerts]) => {
        this.priceEntities = res.sort((a, b) => a.kwhRate < b.kwhRate ? -1 : 1);

        this.searchCriteria.utilityId = paramsList.utilityId;

        // add supplier alerts to price entities
        const alertsBySupplierId: any = groupBy(supplierAlerts, ['supplierId']);

        // backup original fees, add on supplier alerts
        this.priceEntities = this.priceEntities.map(priceEntity => {
          priceEntity.kwhRateCentsCopy = priceEntity.kwhRate;
          priceEntity.supplierLogoImgPath = this.contractService.getSupplierImagePath(priceEntity.supplierID);

          if (alertsBySupplierId && alertsBySupplierId[priceEntity.supplierID]) {
            priceEntity.supplierAlertShort = alertsBySupplierId[priceEntity.supplierID][0].shortMessage;
          }

          return priceEntity;
        });

        // price response -> used on dv filters
        this.clonedPriceEntities = [... this.priceEntities];

        this.showNoRecords = this.clonedPriceEntities.length === 0;
        this.setPriceResponse(res);

        this.calculateFinalPricesAndSavings();

        if (this.priceEntities.length && scrollToElement) {
          setTimeout(() => {
            this.scrollToService.scrollTo({ target: scrollToElement, offset: -100 });
          }, 250);
        }

        this.dataLoading = false;

        this.priceResUpdated.next(true);
      },
      () => {
        this.showError('Error getting pricing.  If the problem persists please contact support.');
        this.dataLoading = false;
      }
    );
  }

  showError(errorMessage) {
    this.errorMessage = errorMessage;
    this.showErrorPopup = true;
  }

  /** This is used to set dataview filter details **/
  setPriceResponse(priceEntities: PriceEntity[]) {
    priceEntities.forEach(price => {
      if (this.matrixPriceFilterRes?.distinctProductDetails?.find(p => p.value === price.product)) {
        this.matrixPriceFilterRes.distinctProductDetails.find(p => p.value === price.product).disabled = false;
      }
      if (!this.matrixPriceFilterRes?.distinctTerm?.find(p => p.value === price.term)) {
        this.matrixPriceFilterRes.distinctTerm.push({label: JSON.stringify(price.term), value: price.term});
      }
      if (this.matrixPriceFilterRes?.distinctEnergySourceDetails?.find(p => p.value === price.source)) {
        this.matrixPriceFilterRes.distinctEnergySourceDetails.find(p => p.value === price.source).disabled = false;
      }
      if (!this.matrixPriceFilterRes?.distinctSuppliers?.find(p => p.value === price.supplierID)) {
        this.matrixPriceFilterRes.distinctSuppliers.push({label: price.supplierName, value: price.supplierID});
      }
      if (this.matrixPriceFilterRes?.distinctBillingOptions?.find(p => p.value === price.billingOption)) {
        this.matrixPriceFilterRes.distinctBillingOptions.find(p => p.value === price.billingOption).disabled = false;
      }
      // if this price has margin cap info, enable "Yes" check box filter
      if (this.hasMarginCapInfo(price.marginCapInfo)) {
        this.matrixPriceFilterRes.distinctMarginCap.find(p => p.value).disabled = false;
      } else {
        // if this price has no margin cap info, enable "No" check box filter
        this.matrixPriceFilterRes.distinctMarginCap.find(p => p.value === false).disabled = false;
      }
    });
    this.matrixPriceFilterRes?.distinctSuppliers.sort((s1, s2) => (s1.label > s2.label) ? 1 : -1);
    this.matrixPriceFilterRes?.distinctTerm.sort(
      (t1, t2) => t1.value > t2.value ? 1 : -1
    );

    this.defaultFilter();
  }

  defaultFilter() {
    this.clearFilterCriteria();
    this.dataViewFilterCriteria.distinctProductDetails = ['Fixed'];
    setTimeout(() => {
      this.onFilterCriteria(this.dataViewFilterCriteria);
      this.calculateFinalPricesAndSavings();
    }, 100);
  }

  /**
   * Set savings on a price entity
   * @param price
   * @param totalFeeMils
   */
  addFees(price: PriceEntity, totalFeeMils: number): PriceEntity {
    // External Widget API Response always has correct values, no need to calculate anything.
    if (this.feesIncluded) {
      return price;
    }
    let rate = price.kwhRateCentsCopy;

    // deduct out tax if included
    if (price.taxesIncluded && price.taxRate) {
      rate = rate / price.taxRate;
    }

    // add fee
    rate = rate + currencyUtils.milsToCents(totalFeeMils);

    // add tax if present and not already included
    if (price.taxRate) {
      rate = rate * price.taxRate;
    }

    price.finalizedPrice = parseFloat(rate.toFixed(3));

    return price;
  }

  addSavings(price: PriceEntity): PriceEntity {
    let allSavingsOptions = [
      {name: 'supplierRate', rate: this.savingsVars.currentSupplierRateCents},
      {name: 'utilityRate', rate: this.savingsVars.currentUtilityRateCents},
      {name: 'medianRate', rate: this.savingsVars.medianRateCents}
    ];

    // filter out nulls and sort from highest to lowest rate
    const populatedSavingsOptions = allSavingsOptions.filter(s => s.rate !== undefined && s.rate !== null);
    populatedSavingsOptions.sort((a, b) => b.rate - a.rate);

    const bestSavingsMethod = populatedSavingsOptions[0];
    // console.log('Best Savings: ', bestSavingsMethod.name, ' | ', bestSavingsMethod.rate);

    price.savingsCurrentRate =
      // tslint:disable-next-line:max-line-length
      MatrixPricingHelperService.calcSavingsAmount(this.savingAnalysis.supplierRateCents, price.finalizedPrice, this.searchCriteria.annualMwhUsage * 1000);

    price.savingsUtilityRate =
      // tslint:disable-next-line:max-line-length
      MatrixPricingHelperService.calcSavingsAmount(this.savingAnalysis.utilityRateCents, price.finalizedPrice, this.searchCriteria.annualMwhUsage * 1000);

    price.savingsCurrentRatePct =
      MatrixPricingHelperService.calcSavingsPct(this.savingAnalysis.supplierRateCents, price.finalizedPrice);
    price.savingsUtilityRatePct =
      MatrixPricingHelperService.calcSavingsPct(this.savingAnalysis.utilityRateCents, price.finalizedPrice);


    price.savings = MatrixPricingHelperService.calcSavingsAmount(bestSavingsMethod.rate, price.finalizedPrice,
      this.searchCriteria.annualMwhUsage * 1000);
    price.savingsPct = MatrixPricingHelperService.calcSavingsPct(bestSavingsMethod.rate, price.finalizedPrice);

    return price;
  }

  onFilterCriteria(criteria: PriceResponseDvFilterCriteria) {
    let criteriaValues = Object.values(criteria);
    if (criteriaValues.filter(c => c && c.length > 0).length > 0) {
      const { distinctProductDetails, distinctTerm, distinctMarginCap,
        distinctSuppliers, distinctEnergySourceDetails, distinctBillingOptions } = criteria;
      this.priceEntities = this.clonedPriceEntities
        .filter(({ product, term, marginCapInfo, supplierID, source, billingOption }) => (
        (distinctProductDetails.includes(product) || distinctProductDetails.length === 0)
        && (distinctTerm.includes(term) || distinctTerm.length === 0)
        && (distinctSuppliers.includes(supplierID) || distinctSuppliers.length === 0)
        && (distinctEnergySourceDetails.includes(source) || distinctEnergySourceDetails.length === 0)
        && (distinctBillingOptions.includes(billingOption) || distinctBillingOptions.length === 0)
        && (distinctMarginCap.includes(this.hasMarginCapInfo(marginCapInfo)) ||  distinctMarginCap.length === 0)
      ));
    } else {
      this.priceEntities = this.clonedPriceEntities;
    }
    this.onSortChange(this.sortKey);
  }

  hasMarginCapInfo(marginCapInfo: string) {
    return marginCapInfo !== 'Not Applicable';
  }

  /**
   * Set all savings at once
   * @param totalFeeMils
   */
  addFeesAndSavingsToAll(totalFeeMils: number) {
    this.priceEntities = this.priceEntities.map((price: PriceEntity) => {
      price = this.addFees(price, totalFeeMils);
      return this.addSavings(price);
    });
  }

  clearData(forceClear = false) {
    this.clearResults(forceClear);
    this.disableAndResetAllDropDowns();
    this.searchCriteria.utilityId = undefined; // To avoid data mismatch issue.
  }

  clearResults(forceClear = false) {
    if (this.priceEntities?.length > 0 || forceClear) {
      this.matMsgService.clearData(true);
    }
      this.clearPriceRes();
      this.clearFilterCriteria();
      this.priceEntities = [];
      this.selectedPricingIds = [];
      this.selectedPriceEntities = [];
      this.clonedPriceEntities = [];
      this.showNoRecords = false;
  }

  clearSelectedServiceAddresses() {
    this.selectedServiceAddresses = [];
    this.setTotalAnnualMwhUsage();
  }

  clearPriceRes() {
    this.matrixPriceFilterRes = {
      distinctProductDetails: this.products(),
      distinctTerm: [],
      distinctEnergySourceDetails: this.energySources(),
      distinctSuppliers: [],
      distinctBillingOptions: this.billingMethods(),
      distinctMarginCap: this.marginCaps()
    } as PriceResponseDvFilter;
  }

  products(): FilterClass[] {
    return [
      {label: ProductEnum.Fixed, value: ProductEnum.Fixed, disabled: true}, // capAndTransPassthrough -> false
      {label: ProductEnum.FixedPassthrough, value: ProductEnum.FixedPassthrough, disabled: true} // capAndTransPassthrough -> true
    ]
  }

  energySources(): FilterClass[] {
    return [
      {label: ContractSourceFriendlyText.RENEWABLE_E_CERTIFIED, value: ContractSourceFriendlyText.RENEWABLE_E_CERTIFIED, disabled: true},
      {label: ContractSourceFriendlyText.RENEWABLE_ELECTRIC, value: ContractSourceFriendlyText.RENEWABLE_ELECTRIC, disabled: true},
      {label: ContractSourceFriendlyText.STANDARD_MIX, value: ContractSourceFriendlyText.STANDARD_MIX, disabled: true}
    ];
  }

  billingMethods(): FilterClass[] {
    return  [
      {label: 'Consolidated', value: 'Consolidated', disabled: true},
      {label: 'Dual', value: 'Dual' , disabled: true}];
  }

  marginCaps(): FilterClassBoolean[] {
    return [
      {label: 'Yes', value: true, disabled: true},
      {label: 'No', value: false, disabled: true}
    ]
  }

  clearFilterCriteria() {
    this.dataViewFilterCriteria = {
      distinctProductDetails: [],
      distinctTerm: [],
      distinctEnergySourceDetails: [],
      distinctSuppliers: [],
      distinctBillingOptions: [],
      distinctMarginCap: []
    };
  }

  private setMedianRate() {
    // get distinct products
    // TODO: maybe find a faster way to get this
    let uniqueProducts = [...new Set(this.priceEntities.map(p => p.product))];

    // get all cents by filtered products
    let allRatesCents = this.clonedPriceEntities
      .filter(price => uniqueProducts.includes(price.product))
      .map((price: PriceEntity) => price.finalizedPrice);

    const mid = Math.floor(allRatesCents.length / 2), nums = [...allRatesCents].sort((a, b) => a - b);
    // tslint:disable-next-line:max-line-length
    this.savingsVars.medianRateCents = (allRatesCents.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2);
  }

  calculateFinalPricesAndSavings() {
    if (this.priceEntities && this.priceEntities.length > 0) {
      this.dataLoading = true;

      // add fees
      this.priceEntities = this.priceEntities.map(price => this.addFees(price, this.totalFeeMils));

      // calculate median rate
      this.setMedianRate();

      // add savings
      this.priceEntities = this.priceEntities.map(price => this.addSavings(price));

      this.dataLoading = false;
      this.savingsUpdated.next(true);
    }
  }

  recalculateFeesWithNewUsage() {
    this.totalFeeMils = null;
    this.recalculateFees();
    if (this.priceEntities.length > 0) {
      this.clearResults();
    }
  }

  // @Deprecated
  recalculateFees() {
    if (this.feesIncluded) {
      // fees are already included, don't get the fees.  Just set to 0 and calc savings
      this.totalFeeMils = 0;
      this.calculateFinalPricesAndSavings();
    } else {
      // fees are not included, lets figure out what they are.
      // not including fees allows the UI is easily change the fees included
      const feeInDollars = (this.totalFeeMils) ? currencyUtils.milsToDollars(this.totalFeeMils) : null;

      this.rateCheckRequestService.calculateOrganizationFees(this.searchCriteria.annualMwhUsage ? this.searchCriteria.annualMwhUsage : this.totalAnnualMwhUsageValue, feeInDollars)
        .pipe(take(1), distinctUntilChanged())
        .subscribe({
          next: brokerFee => this.feeLoadedCallback(brokerFee)
        });
    }
  }

  // Used in the new RCF
  recalculateFeesV2(customerId: number, rcId: string, callback?: Function) {
    if (this.feesIncluded) {
      // fees are already included, don't get the fees.  Just set to 0 and calc savings
      this.totalFeeMils = 0;
      this.calculateFinalPricesAndSavings();
    } else {
      // fees are not included, lets figure out what they are.
      // not including fees allows the UI is easily change the fees included
      const feeInDollars = (this.totalFeeMils) ? currencyUtils.milsToDollars(this.totalFeeMils) : null;

      this.rcV4Svc.calculateRateCheckFees(customerId, rcId, feeInDollars)
        .pipe(take(1), distinctUntilChanged())
        .subscribe({
          next: brokerFee => {
            this.feeLoadedCallback(brokerFee);
            if (callback) {
              callback();
            }
          }
        });
    }
  }

  recalculateFeesWithKnownBrokerFee(brokerFee: BrokerFeesCalculated) {
    if (this.feesIncluded) {
      // fees are already included, don't get the fees.  Just set to 0 and calc savings
      this.totalFeeMils = 0;
      this.calculateFinalPricesAndSavings();
    } else {
      // fees are not included, lets figure out what they are.
      // not including fees allows the UI is easily change the fees included
      this.feeLoadedCallback(brokerFee);
    }
  }

  feeLoadedCallback(brokerFee: BrokerFeesCalculated) {
    this.curBrokerFeesCalc = brokerFee;
    this.totalFeeMils = currencyUtils.dollarsToMils(brokerFee.totalFee);
    this.tiliFeeMils = currencyUtils.dollarsToMils(brokerFee.brokerFee);
    this.zenFeeMils = currencyUtils.dollarsToMils(brokerFee.platformFee);
    this.hasFee = true;
    this.minFeeMilsLimit = (brokerFee.feeSource.platformFee) ? currencyUtils.dollarsToMils(brokerFee.feeSource.platformFee) : 0;
    // Min and Max fee limits are added here to support feeErrorCheck
    this.curBrokerFeesCalc.minFeeDollarsLimit = this.minFeeMilsLimit / 1000;
    this.curBrokerFeesCalc.maxFeeDollarsLimit = this.maxFeeMilsLimit / 1000;
    this.feeErrorCheck();
    this.clearResults();
    this.calculateFinalPricesAndSavings();
  }

  selectRowFn(rowData: PriceEntity) {
    if (this.selectedPricingIds.includes(rowData.id)) {
      let idIndex = this.selectedPricingIds.findIndex(obj => obj === rowData.id);
      let rowIndex = this.selectedPriceEntities.findIndex((obj: PriceEntity) => obj.id === rowData.id);
      this.selectedPriceEntities.splice(rowIndex, 1);
      this.selectedPricingIds.splice(idIndex, 1);
    } else {
      this.selectedPricingIds.push(rowData.id);
      this.selectedPriceEntities.push(rowData);
    }
    this.onPriceSelectionChange.emit();
  }

  calculateLoadFactor() {
    this.searchCriteria.loadFactor = this.getLoadFactor();
  }

  calculateInitialEstimatedDemand(kwhValue) {
    if (this.searchCriteria.state === 'TX') {
      const kwhFormulaValue = kwhValue * 100;
      const HOURS_IN_YEAR = 8760;
      const MEDIUM_FLOOR_KW = 40 * HOURS_IN_YEAR;
      // We are setting the LOW FLOOR KW value, not Medium,  we need to calculate Medium first though.
      return Math.floor(kwhFormulaValue / MEDIUM_FLOOR_KW) + 1;   // Set Low Ceiling Value
    }
  }

  getPeakDemandRange(): string {
    const peakDemand = this.getPeakDemandKwh();
    return this.calculatePeakDemandRange(peakDemand);
  }

  calculatePeakDemandRange(kwhValue) {
    const kwhFormulaValue =   kwhValue * 100;
    const HOURS_IN_YEAR = 8760;
    const MEDIUM_FLOOR_KW = 40 * HOURS_IN_YEAR;  //  40 represents the .40 Load Factor floor cutoff for MEDIUM
    const HIGH_FLOOR_KW = 60 * HOURS_IN_YEAR;    //  60 represents the .60 Load Factor floor cutoff for HIGH
    const MediumFloorKw =  Math.floor(kwhFormulaValue / MEDIUM_FLOOR_KW);
    const LowCeilingKw =  MediumFloorKw + 1;
    const HighFloorKw =  Math.floor(kwhFormulaValue / HIGH_FLOOR_KW);
    const MediumCeilingKw =  HighFloorKw + 1;

    switch (this.searchCriteria.loadFactor) {
      case 'LOW':
        return ' >= ' + LowCeilingKw + ' KW';
      case 'MEDIUM':
        if (MediumCeilingKw === MediumFloorKw) {  //  With smaller KWH numbers these Could be the same value.
          return MediumFloorKw + ' KW';
        } else {
          return MediumCeilingKw + '-' + MediumFloorKw + ' KW';
        }
      case 'HIGH':
        return ' <= ' + HighFloorKw + ' KW';
    }
  }

  /** Returns an array of distinct rate schedules that are present in the list of currently selected serviceAddresses */
  getSelectedRateClassIds(): number[] {
    return [...new Set(this.selectedServiceAddresses.filter(prop => prop.utilityRateSchedule).map(prop => prop.utilityRateSchedule.id))];
  }

  getPeakDemandKwh(): number {
    return Math.max(...this.selectedServiceAddresses.map(prop => prop.peakDemandKw))
  }

  getLoadFactor(): LoadFactorLabelsMatrixRC {

    // Map all load factors to set for fast lookup times
    let loadFactors = new Set(this.selectedServiceAddresses.map(prop => {
      return this.searchCriteria.state === 'TX' ?
        this.matrixPricingService.calculatedLoadFactor('TX', prop.peakDemandKw, prop.yearlyUsage / 1000) : '';
    }));

    //  If there is a single low LOAD FACTOR, the overall load factor is LOW
    if (loadFactors.has(LoadFactorLabelsMatrixRC.LOW)) {
      return LoadFactorLabelsMatrixRC.LOW;
    }

    //  If there are 0 LOW Load Factors, but any number of Medium, load factor is MEDIUM
    if (loadFactors.has(LoadFactorLabelsMatrixRC.MEDIUM)) {
      return LoadFactorLabelsMatrixRC.MEDIUM;
    }

    //  If all load factors are HIGH, return HIGH
    if (loadFactors.has(LoadFactorLabelsMatrixRC.HIGH)) {
      return LoadFactorLabelsMatrixRC.HIGH;
    }

    return LoadFactorLabelsMatrixRC.NONE;
  }

  /** total of rounded yearly MWh usages for selected service addresses */
  setTotalAnnualMwhUsage(): void {
    this.totalAnnualMwhUsageValue = this.selectedServiceAddresses.reduce((acc, val) => acc + val.yearlyUsage, 0) / 1000;
    this.totalAnnualMwhUsageLabel = this.decimalPipe.transform(this.totalAnnualMwhUsageValue, '1.0-2');
  }

  getTotalAnnualKwhUsage(): number {
    return this.selectedServiceAddresses.reduce((acc, val) => acc + val.yearlyUsage, 0);
  }

  feeErrorCheck() {
    this.feeError = feeErrorCheck(this.curBrokerFeesCalc);
  }

  // Sort starts
  onSortChange(val: MATRIX_DV_SORT_TYPES) {
    let sortField: any = this.sortField(val);
    // don't remove, there is a weird race condition here due to mutation of this variable in matrix-pricing-helper-service
    setTimeout(() => {
      this.priceEntities = _.orderBy(this.priceEntities, sortField.obj, sortField.type);
    }, 100);
  }

  sortField(val: MATRIX_DV_SORT_TYPES) {
    switch (val) {
      case MATRIX_DV_SORT_TYPES.PRICE_HIGH_TO_LOW:
        return {obj: 'finalizedPrice', type: 'desc'};
      case MATRIX_DV_SORT_TYPES.PRICE_LOW_TO_HIGH:
        return {obj: 'finalizedPrice', type: 'asc'};
      case MATRIX_DV_SORT_TYPES.SAVINGS_HIGH_TO_LOW:
        return {obj: 'savings', type: 'desc'};
      case MATRIX_DV_SORT_TYPES.SAVINGS_LOW_TO_HIGH:
        return {obj: 'savings', type: 'asc'};
      case MATRIX_DV_SORT_TYPES.TERM_SHORT_TO_LONG:
        return {obj: 'term', type: 'asc'};
      case MATRIX_DV_SORT_TYPES.TERM_LONG_TO_SHORT:
        return {obj: 'term', type: 'desc'};
    }
  }
}
