import {Component, ElementRef, EventEmitter, OnInit, Output, SecurityContext, ViewChild} from '@angular/core';
import {OrganizationManagementService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthenticationService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {
  ZenDownloadableDirective
} from '../../zen-reports/_modules/zen-rate-check-report/_components/zen-report-downloader/zen-downloadable.directive';
import {ZenErrorMsgEnum} from '../../../_shared/_enums/zen-error-msg.enum';
import {ZenDialogMsgService} from '../../../_shared/_services/zen-dialog-msg.service';

@Component({
  selector: 'app-zen-tac-texts',
  templateUrl: './zen-tac-texts.component.html',
  styleUrls: ['./zen-tac-texts.component.scss']
})
export class ZenTacTextsComponent extends ZenDownloadableDirective implements OnInit {
  showTermsAndCond: boolean;
  companyName: string;
  onPrint: boolean;
  forceOnePage = false;
  footer = null;
  organization = null;
  @ViewChild('termsAndCondsId')  report: ElementRef;
  myContent: any = '<strong>Loading...</strong>';
  loading: boolean;
  @Output() tacId = new EventEmitter<string>();
  pdfPages = [];
  constructor(private orgs: OrganizationManagementService,
              private zenDialogSvc: ZenDialogMsgService,
              private sanitizer: DomSanitizer, private authService: AuthenticationService) {
    super();
  }
  ngOnInit() {
    this.orgs.observeOrganization().subscribe(o => {
      this.companyName = o.companyName ? o.companyName : 'Company Name';
      this.loading = true;
      this.orgs.getTermsAndCondsText(o.id).subscribe((poData: any) => {
        this.tacId.emit(poData.id);
        this.myContent = this.sanitizer.bypassSecurityTrustHtml(poData.text.replace(/(\{{)companyName(\}})/g, o.companyName));
        const textPages = this.sanitizer.sanitize(SecurityContext.HTML, this.myContent);
        textPages.trim().replace(/<div class="tnc-text-sec">/g, '').slice(0, -6).split('<div class="page-break"></div>').forEach(page => {
          const div = document.createElement('div');
          div.innerHTML = page;
          this.pdfPages.push(new ElementRef(div));
          document.getElementById('pdfTC').appendChild(div);
        });
      }, () => {
        this.zenDialogSvc.openToast(false, ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }, () => {
        this.loading = false;
      });
    });

    if (this.authService.getCurrentCustomerId()) {
      this.authService.getCustomer(this.authService.getCurrentCustomerId()).subscribe(currentCustomer => {
        if (currentCustomer.tacApproved) {
          this.authService.hideTacDialog();
        }
      });
    }
  }
  toggleTermsAndCond() {
    window.scrollTo(0, -300);
    this.showTermsAndCond = !this.showTermsAndCond;
  }
  downloadPdf() {
    window.scrollTo(0, -300);
    this.onPrint = true;
    const timeout = setTimeout(() => {
      this.makePDF();
    }, 200)
  }
  initLoad() {
  }
  getFileName(): string {
    return 'T&C - ' + this.companyName;
  }
  getPages(): ElementRef[] {
    return this.pdfPages;
  }
  onPdfGenerated() {
    this.onPrint = false;
  }

}
