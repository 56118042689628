export function milsToCents(amount: number) {
  return amount / 10;
}

export function dollarsToMils(amount: number): number {
  return parseFloat((amount * 1000).toFixed(3));
}

export function milsToDollars(amount: number): number {
  return amount / 1000;
}
