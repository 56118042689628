import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ZenDialogDataModel} from '../../_dialogs/zen-dialog/zen-dialog.component';
import {NguCarouselConfig} from '@ngu/carousel';

@Component({
  selector: 'app-zen-loader-with-facts',
  templateUrl: './zen-loader-with-facts.component.html',
  styleUrls: ['./zen-loader-with-facts.component.scss']
})
export class ZenLoaderWithFactsComponent implements OnInit {
  @Input() title: string;
  @Input() bodyText: string;
  @Output()
  onDialogClosed: EventEmitter<any> = new EventEmitter<any>();
  slides = [];
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    load: 3,
    interval: {timing: 4000, initialDelay: 1000},
    loop: true,
    touch: true,
    velocity: 0.2
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel) { }

  ngOnInit() {
    this.title = this.data.data.title;
    this.bodyText = this.data.data.bodyText
    this.slides = [
      {text: `Solar energy is, by far, the most abundant energy resource on Earth.
      A whopping 173,000 terawatts of solar energy strikes the Earth continuously.
      That's more than 10,000 times the world's total energy use.`},
      {text: `A single Google search consumes enough energy to turn on a 60-watt light bulb for 17 seconds.
      In a single day, Google search accounts for 12.5 million watts of energy.
      In total, all Google data centers consume an aggregate of 260 million watts per day!`},
      {text: `If everyone boiled only the water they needed every time they used the kettle,
      we could save enough electricity in a year to power the UK’s street lights for nearly 7 months.`},
      {text: `Laptops use up to 85% less electricity than desktop PCs.`},
      {text: `The largest Concentrated Solar Power (CSP) plant is in the Mojave Desert (California) and covers 1000 acres.`},
      {text: `K-12 schools in the United States spend more than $6 billion on energy.
      This is more than they spend on textbooks and computers combined.`},
      {text: `Only about 10% of the energy used in a single lightbulb is used to create light, the rest is used to create heat.
      Fluorescent light bulbs use about 80% less energy in total than standard bulbs.`},
      {text: `It costs just $0.47 per year to charge an iPhone 13.
      It takes 49 times as much energy to charge a desktop computer and 72 times as much energy to power a flat screen television.`},
      {text: `The amount of energy Americans use doubles every 20 years`}
    ];
    this.slides = this.slides.sort(() => Math.random() - 0.5);
  }

}
