import {Injectable} from '@angular/core';
import {HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {catchError, map} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {ZenDialogMsgService} from '../../../_services/zen-dialog-msg.service';

export const IGNORED_STATUSES = new HttpContextToken<number[]>(() => []);

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  authCheckInProgress = false;

  constructor(private router: Router,
              private auth: AuthenticationService,
              private zenDialogSvc: ZenDialogMsgService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        map((event: HttpEvent<any>) => {
          return event;
        }),
        catchError((error: HttpEvent<any>) => {
          if (error instanceof HttpErrorResponse && !error.url.endsWith('/authenticate')) {
            const ignoredStatuses = req.context.get(IGNORED_STATUSES);

            // if ignored statuses are set and returned status matched
            if (ignoredStatuses?.includes(error.status)) {
              // rethrow error to be catched locally
              throw error;
            } else {
              if (error.status === 401 && !this.authCheckInProgress) { // invalid credentials, token expired, etc.
                this.authCheckInProgress = true; // block others from doing this
                this.auth.validateAuthToken().subscribe(
                  (res) => {
                    if (res.valueOf()) {
                      console.log('User is logged in, but tried to hit an unauthorized resource');
                    } else {
                      // Logout only if we get a 401 outside login page
                      if (this.router.url !== '/') {
                        this.auth.logout();
                        this.router.navigate(['/']);
                      }
                    }
                  },
                  (errorMsg) => console.error('Auth check error: ', errorMsg),
                  () => {
                    this.authCheckInProgress = false;
                  }
                );
              } else if (error.status === 403) { // access denied, does not have required role
                this.zenDialogSvc.openToast(false, 'User does not have the required permissions to access this resource.');
              }
            }
            throw error;
          } else {
            return of(error)
          }
        }));
  }

}
