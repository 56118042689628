<form class="row text-start" *ngIf="formType" [formGroup]="form" [ngClass]="formType? '' : 'mt-4'">
  <div class="col-12 form-group">
    <mat-form-field [appearance]="appearance">
      <mat-label>Email*</mat-label>
      <input matInput placeholder="Email@address.com" formControlName="email" [imask]="NoSpacesMask" [unmask]="true">
      <mat-error *ngIf="hasError('email', 'required')">Required Field*</mat-error>
      <mat-error *ngIf="hasError('email', 'pattern')">Invalid Format*</mat-error>
      <mat-error *ngIf="hasError('email', 'existentAccount')">Email already exists*</mat-error>
      <mat-error *ngIf="hasError('email', 'customerExistentAccount')">Please reach out to your energy advisor to add this user*</mat-error>
    </mat-form-field>
  </div>
  <div class="col-6 form-group">
    <mat-form-field [appearance]="appearance">
      <mat-label>First Name*</mat-label>
      <input matInput placeholder="First Name" formControlName="firstName">
      <mat-error *ngIf="hasError('firstName', 'required')">Required Field*</mat-error>
    </mat-form-field>
  </div>
  <div class="col-6 form-group">
    <mat-form-field [appearance]="appearance">
      <mat-label>Last Name*</mat-label>
      <input matInput placeholder="Last Name" formControlName="lastName">
      <mat-error *ngIf="hasError('lastName', 'required')">Required Field*</mat-error>
    </mat-form-field>
  </div>
  <div class="col-6 form-group">
    <mat-form-field [appearance]="appearance">
      <mat-label>Title*</mat-label>
      <input matInput placeholder="Title" formControlName="title">
      <mat-error *ngIf="hasError('title', 'required')">Required Field*</mat-error>
    </mat-form-field>
  </div>
  <div class="col-6 form-group">
    <mat-form-field [appearance]="appearance">
      <mat-label>Phone*</mat-label>
      <input matInput formControlName="phone" [imask]="i18nMask.phone" [unmask]="i18nMask.phoneUnmask">
      <mat-error *ngIf="hasError('phone', 'required')">Required Field*</mat-error>
      <mat-error *ngIf="(hasError('phone', 'minlength')
                  || hasError('phone', 'maxlength'))">Invalid Format*
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Showing this section on - add/edit billing address -->
  <div *ngIf="formType && !hideActionBtns" class="col-12 d-flex align-items-center justify-content-end my-3">
    <button mat-button (click)="cancelEdit.emit()" [disabled]="loading">Cancel</button>
    <button mat-raised-button color="accent" class="ms-4" [disabled]="loading" (click)="onContactFormSubmit.emit()">Save</button>
  </div>
</form>
