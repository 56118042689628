import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import { AuthenticationService } from '../_zen-legacy-common/zen-common-services/_services/authentication.service';
import { getParams } from '../_zen-legacy-common/_utils/set-param-util';
import { environment } from '../../../../../environments';
import { Tag, TagCreationRequest, TagOption, TagType } from '../../_modules/portfolio/_model/tag.model';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private authService: AuthenticationService) {
  }

  getFriendlyName(type: TagType): string {
    switch (type) {
      case TagType.CUSTOMER:
        return this.authService.isAdvisor() ? 'Customer' : 'Company';
      case TagType.LEN:
        return 'Legal Entity Name';
      case TagType.SERVICE_ADDRESS:
        return 'Service Address';
      case TagType.METER:
        return 'Meter';
    }
  }

  getTagExample(id: any, type: TagType): Partial<Tag> {
    switch (type) {
      case TagType.CUSTOMER:
        return { customerId: id };
      case TagType.LEN:
        return { lenId: id };
      case TagType.CUSTOMER:
        return { serviceAddressId: id };
      case TagType.METER:
        return { meterId: id };
    }
  }

  // e.g., taggroupname:tagname or taggroupname: or tagname
  getTagOptionLabel(tagOption: TagOption): string {
    const { tagGroupName, tagName } = tagOption;
    let label = '';
    if (tagGroupName) {
      label += tagGroupName + ':'
    }
    if (tagName) {
      label += tagName;
    }
    return label;
  }

  public getTagOptions(tagType, customerId, query?: string): Observable<TagOption[]> {
    switch (tagType) {
      case TagType.CUSTOMER:
        return this.http.get<TagOption[]>(
          `${this.baseUrl}/v4/organizations/${this.authService.getOrganizationId()}/customer-tags/search`,
          { params: getParams({ query: query ||= '' })}
        ).pipe(take(1));
      default:
        return this.http.get<TagOption[]>(
          `${this.baseUrl}/v4/organizations/${this.authService.getOrganizationId()}/customers/${customerId}/tags/search`,
          { params: getParams({ query: query ||= '', tagType })}
        ).pipe(take(1));
    }
  }

  public getTags(tagType, customerId, match: Partial<Tag>): Observable<Tag[]> {
    const httpParams = getParams({
      tagType,
      ...match,
    })
    const url = `${this.baseUrl}/v4/organizations/${this.authService.getOrganizationId()}/customers/${customerId}/tags`;
    return this.http.get<Tag[]>(url, { params: httpParams }).pipe(take(1));
  }

  public createTag(tagType, customerId, tagName, tagGroupName, entityIdObj) {
    const organizationId = this.authService.getOrganizationId();
    let body: TagCreationRequest = {
      objectType: tagType,
      organizationId,
      customerId,
      name: tagName,
      groupName: tagGroupName,
      ...entityIdObj
    }
    switch (tagType) {
      case TagType.CUSTOMER:
        return this.http.post<any>(
          `${this.baseUrl}/v4/organizations/${organizationId}/customer-tags`,
          body
        ).pipe(take(1));
      default:
        return this.http.post<any>(
          `${this.baseUrl}/v4/organizations/${organizationId}/customers/${customerId}/tags`,
          body
        ).pipe(take(1));
    }
  }

  // This API wont delete tag from DB. Its used to remove the attachment of tag from the main object - Customer, LEN, ServiceAddress, Meters
  public deleteTag(customerId: number, tagId: string, request: {tagType: TagType; name: string}) {
    const organizationId = this.authService.getOrganizationId();
    const url = `${this.baseUrl}/v4/organizations/${organizationId}/customers/${customerId}/tags/${tagId}`;
    return this.http.delete<any>(url).pipe(take(1));
  }

}

