export enum ZenDialogSizeEnum {
  SMALL = '47.5rem',
  EXTRA_SMALL = '55rem',
  MEDIUM = '61rem',
  MEDIUM_2 = '75rem',
  EXTRA_MEDIUM = '98rem',
  LARGE = '112rem',
  EXTRA_LARGE = '140rem',
}

export enum ZenDialogPanelClassEnum {
  MOBILE_FULL_HEIGHT = 'mobile-full-height', // below 576px to take full screen height.
  OPEN_FROM_BOTTOM = 'open-from-bottom', // to open dialog from bottom. eg., market pulse mobile rates selectons
  DOWNLOAD_REPORT = 'download-rc-report',
  HIDE_DIALOG = 'hide-dialog'
}

