import {Injectable} from '@angular/core';
import {ZenAbstractFilterService} from '../../../zen-contracts/_services/zen-abstract-filter-service';
import {PortfolioSectionsEnum} from '../../_enums/portfolio-tabs.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {ZenMatTableHelperService} from '../../../../_shared/_services/helpers/zen-mat-table-helper.service';
import {AuthenticationService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {PortfolioFilterSessionStore} from '../../../../_shared/_stores/filter/portfolio-filter-session.store';

@Injectable({
  providedIn: 'root'
})
export class PortfolioFilterService extends ZenAbstractFilterService {

  serviceSection = PortfolioSectionsEnum.PORTFOLIO;
  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected zenMatTableHelperSvc: ZenMatTableHelperService,
              protected authSvc: AuthenticationService, protected portfolioFilterStore: PortfolioFilterSessionStore) {
    super(router, route, authSvc, zenMatTableHelperSvc, portfolioFilterStore);
  }
}
