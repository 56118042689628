import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {DomSanitizer} from '@angular/platform-browser';

export enum ZenMessageType {
  INFO = 'info', ERROR = 'error', SUCCESS = 'success'
}

@Component({
  selector: 'app-zen-message',
  templateUrl: './zen-message.component.html',
  styleUrls: ['./zen-message.component.scss']
})
export class ZenMessageComponent implements OnInit {
  @Input() type: ZenMessageType;
  @Input() message;
  @Output() onClick = new EventEmitter();

  @Input() icon: ZenIconsEnum = ZenIconsEnum.INFO;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    if (this.message) {
      this.message = this.sanitizer.bypassSecurityTrustHtml(this.message);
    }
  }

}
