import { Injectable } from '@angular/core';
import {CustomerIdSessionStore} from '../../_stores/customer-id/customer-id-session.store';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerIdSessionService {

  constructor(private customerIdStore: CustomerIdSessionStore,
              private authSvc: AuthenticationService) {}

  /** NOTE: RouterQuery customerId param detection is not fast enough to support browser controls (back/forward).
      Thats why we are using setCustomerIdFromParams() here. */
  setCustomerIdFromParams(paramCustomerId) {
    // customerId store updated from CustomerIdSessionStore using RouterQuery
    this.customerIdStore.update({customerId: paramCustomerId});
  }

  getCustomerId(): number {
    // Setting customerId for customer users. This will be accessed globally.
    if (this.authSvc.isCustomer()) {
      return this.authSvc.getCurrentCustomerId()
    } else {
      // We are getting & setting the customerId from the Router Params
      return this.customerIdStore.getValue().customerId;
    }
  }

  /* This reset should made from all parent component. For Advisor users. */
  reset() {
    this.customerIdStore.reset();
  }
}
