export interface RateCheckAuthorizationV4 {
  customerId: number;
  rateCheckId: string;
  contactEmail: string;
  mloaSigned: string;
  mloaFilePath: string;
  loeFilePath: string;
  mloaSignatureImageId: number;
  mloaInitialsImageId: number;
  loaObligations: LOAObligationV4[];
}

export interface LOAObligationV4 {
  loaTemplateFilePath: string;
  loaType: LOAType;
  utilityNames: string[];
  utilityIds: number[];
  existingLoaFile: ExistingLoaFile;
  state: string;
}

export enum LOAType {
  GENERIC = 'GENERIC', UTILITY_SPECIFIC = 'UTILITY_SPECIFIC'
}

export interface ExistingLoaFile {
  loaFilePath: string;
  loaFileName: string;
  documentId: number;
}
