<app-zen-dialog-wrapper [data]="dialogData" [errorMsg]="errorMsg" [disableButton]="loading" [hideButton]="true">
  <form class="dialog-form-sec with-border" [formGroup]="form">
    <div class="zen-mat-form zen-customer-contact-form">
      <div class="d-flex justify-content-center">
        <div class="justify-content-center">
          <div class="d-flex justify-content-start">
            <p class="form-title">User Details</p>
          </div>

          <!-- Contact form -->
          <div class="row mt-1 text-start">
            <div class="col-12 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Email*</mat-label>
                <input matInput formControlName="email" [imask]="NoSpacesMask" [unmask]="unmask">
                <mat-error *ngIf="hasError('email','required')">Required Field*</mat-error>
                <mat-error *ngIf="hasError('email','pattern')">Invalid Format*</mat-error>
                <mat-error *ngIf="hasError('email', 'existentAccount')">Email already in use*</mat-error>
                <mat-error *ngIf="hasError('email', 'customerExistentAccount')">Email already in use*</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>First Name*</mat-label>
                <input matInput formControlName="firstName">
                <mat-error *ngIf="hasError('firstName','required')">Required Field*</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Last Name*</mat-label>
                <input matInput formControlName="lastName">
                <mat-error *ngIf="hasError('lastName','required')">Required Field*</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Title*</mat-label>
                <input matInput formControlName="title">
                <mat-error *ngIf="hasError('title','required')">Required Field*</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Phone*</mat-label>
                <input matInput formControlName="phone" [imask]="i18nMask.phone" [unmask]="i18nMask.phoneUnmask">
                <mat-error *ngIf="hasError('phone', 'required')">Required Field*</mat-error>
                <mat-error *ngIf="hasError('phone', 'minlength')
                  || hasError('phone', 'maxlength')">Invalid Format*
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6 form-group position-relative">
              <mat-checkbox formControlName="allowLogin" class="ms-0">
                Allow User Login
              </mat-checkbox>
              <i class="material-icons info-icon position-absolute" style="right: 0; top: 0;" [popper]="allowUserLoginInfo"
                 popperTrigger="click" popperApplyClass="xs">info_outline</i>
            </div>
            <div *ngIf="form.controls.allowLogin.value" class="col-6 form-group position-relative">
              <mat-checkbox formControlName="primary" class="ms-0">
                Primary User
              </mat-checkbox>
              <i class="material-icons info-icon position-absolute primary-user" style="right: 0; top: 0;" [popper]="primaryUserInfo"
                 popperTrigger="click" popperApplyClass="xs">info_outline</i>
            </div>

            <ng-container *ngIf="controls.allowLogin.value">
              <div class="col-12 col-sm-6 form-group password-field">
                <app-zen-password-field [formGroup]="form" formCtrlName="password" placeHolder="Password"
                                        label="Password" [passwordConfig]="passwordConfig"></app-zen-password-field>
              </div>
              <div class="col-12 col-sm-6 form-group">
                <app-zen-password-field [formGroup]="form" formCtrlName="confirmPassword" [feedback]="false"
                                        label="Confirm Password"
                                        placeHolder="Confirm Password"></app-zen-password-field>
              </div>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </form>
  <div mat-dialog-actions class="me-0">
    <div class="d-flex justify-content-between w-100">
      <div>
        <i *ngIf="!isPrimaryContact && isEditMode" class="material-icons color-icon red p-4 pointer" (click)="dialogData.data?.deleteCommand()">delete</i>
      </div>
      <div>
        <button mat-button type="button" (click)="dialogRef.close()">Cancel</button>
        <button mat-raised-button color="accent" type="button" [disabled]="loading" (click)="submit()">Save</button>
      </div>
    </div>
  </div>
</app-zen-dialog-wrapper>
<popper-content #allowUserLoginInfo>
  <app-zen-popper [popperContent]="allowUserLoginInfo">
    <p class="font-weight-600 mb-3">User Login</p>
    <p class="text-md m-0">To give a user access to the platform, create a password for them. If you do not want the
      user
      to have access, simply leave this option unchecked.</p>
  </app-zen-popper>
</popper-content>

<popper-content #primaryUserInfo>
  <app-zen-popper [popperContent]="primaryUserInfo">
    <p class="font-weight-600 mb-3">Primary User</p>
    <p class="text-md m-0">This is the primary user for this customer. Emails sent with tokenized links
    will login as this user.  For your security, if the primary user is an advisor, tokenized links will not be sent.</p>
  </app-zen-popper>
</popper-content>
