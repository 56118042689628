import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogActionButton, ZenDialogDataModel} from '../../../_shared/_dialogs/zen-dialog/zen-dialog.component';

@Component({
  selector: 'app-zen-success-msg-dialog',
  templateUrl: './zen-success-msg-dialog.component.html',
  styleUrl: './zen-success-msg-dialog.component.scss'
})
export class ZenSuccessMsgDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenSuccessMsgDialogComponent>
  ) {
  }

  submit(btn: ZenDialogActionButton) {
    btn.command(btn);
  }

}
