import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import * as moment from 'moment';
import {DueTimeEnum} from '../../_enums/due-time.enum';
import {FilterClass} from '../../_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {TimeSettings} from '../../_zen-legacy-common/_utils/etc-timezone-utils';
import {RateCheckRequestService} from '../../_zen-legacy-common/zen-common-services/tili-services/services/rate-check-request.service';
import {ZenDateModeEnum} from '../../_enums/zen-date-mode.enum';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import {DateValidations} from '../../../_modules/zen-rate-checks/_model/rate-check-v3.model';
import {pairwise} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Component({
  selector: 'app-zen-date-time-picker',
  templateUrl: './zen-date-time-picker.component.html',
  styleUrls: ['./zen-date-time-picker.component.scss']
})
export class ZenDateTimePickerComponent implements OnInit {

  @Input() label: string;
  @Input() dateCtrl: UntypedFormControl;
  @Input() timeCtrl: UntypedFormControl;
  @Input() required: boolean;
  @Input() dateMode: ZenDateModeEnum; // expected 'general' | 'no weekends' | 'due date'
  @Input() timeInterval: number; // expected 15 | 30 | 60 in minutes
  @Input() commodityType: CommodityType;
  @Input() setDefaultValue = true;
  @Input() isRefresh = false; // default is not a refresh
  @Input() minDate: Date = new Date();
  @Input() maxDate: Date = new Date();
  @Input() contractStartString: string;
  invalidDates: Date []
  startDate: Date = new Date();
  timeList: FilterClass[] = [];

  constructor(private rateCheckRequestService: RateCheckRequestService, private authSvc: AuthenticationService) {
  }

  ngOnInit(): void {
    this.setDates();
    // To disable time from the past
    this.dateCtrl.valueChanges
      .pipe(
        startWith(this.dateCtrl.value), // Emit the current value as the initial previous value
        pairwise() // Emit the previous and current values as a pair
      )
      .subscribe(([prevValue, currValue]) => {
        const prevDate = moment(prevValue);
        const currDate = moment(currValue);

        // Check if the previous or current date is today
        const wasToday = prevDate.isSame(moment(), 'day');
        const isToday = currDate.isSame(moment(), 'day');

        // Determine if the "same day scenario" has changed,  because we show 1PM in some cases, and 2PM in others
        const sameDayScenarioChanged = (wasToday && !isToday) || (!wasToday && isToday);

        // Pass the boolean to buildTimeList
        this.buildTimeList(sameDayScenarioChanged);
      });
  }

  // Converts date object to date string, and th
   isInvalidDate(date: Date, invalidDates: any[]) {
    const dateString = date.toISOString().slice(0, 10); // Convert to 'YYYY-MM-DD' format
    return invalidDates.includes(dateString);
  }

  // This works with a list of invalid dates supplied by the API so we can advance according to the back end approved dates
   addBusinessDays(startDate: string | number | Date, numDays: number, invalidDates: Date[]) {
    let currentDate = new Date(startDate);
    let validDaysAdded = 0;

    while (validDaysAdded < numDays) {
      currentDate.setDate(currentDate.getDate() + 1); // Add one day
      if (!this.isInvalidDate(currentDate, invalidDates)) {
        validDaysAdded++;
      }
    }

    return currentDate;
  }

  // Admins by default will have a 4 day minimum for due date, they however, can override this minimum
  getAdminDefaultNonRefreshDate(dateValidations: DateValidations) {
    return this.addBusinessDays(this.minDate, 4, dateValidations.invalidDates);
  }


  setDates() {
    if (this.dateMode === ZenDateModeEnum.DUE_DATE) {
      // Start date is NEXT DAY for Due Date type of DateTimePicker
      this.startDate = moment().add(1, 'day').hour(14).minute(0).toDate();
      this.rateCheckRequestService.getDueDates(this.commodityType, this.isRefresh, this.contractStartString).subscribe(dueDates => {
        const isSameDayAdvisorRefresh = (!this.authSvc.isAdmin() && moment(dueDates.minDate).isSameOrBefore(moment()));
        // Min and Max dates are NOT SET in input for Due Date type of DateTimePicker
        // If the minDate is same and today
        // 1) For non-admin users the current time past 11AM (1PM - 2 hour) then change the default date to the next day.
        // 2) For admin users the current time past 3PM then change the default date to the next day.
        if ((isSameDayAdvisorRefresh && moment().hour() >= 11) || (this.authSvc.isAdmin() && moment(dueDates.minDate).isSameOrBefore(moment()) && moment().hour() >= 15)
        ) {
          this.minDate = moment(dueDates.minDate).tz(TimeSettings.Zone).add(1, 'd').hours(14).minutes(0).toDate();
        } else {
          this.minDate = moment(dueDates.minDate).tz(TimeSettings.Zone).hours(14).minutes(0).toDate();
        }

        this.maxDate = moment(dueDates.maxDate).tz(TimeSettings.Zone).hours(14).minutes(0).toDate();
        this.invalidDates = dueDates.invalidDates;
        if (this.setDefaultValue) { // always true
          this.dateCtrl.setValue(this.findDefaultDueDate(dueDates));
        } else {
          this.dateCtrl.setValue(this.dateCtrl.value);
        }
      });
    } else {
      // Start Date is TODAY for all other types of DateTimePicker
      this.startDate = moment().hour(14).minute(0).toDate();
      // Min and Max dates are SET in input for all other types of DateTimePicker
      this.dateCtrl.setValue(this.minDate);
    }
  }

  findDefaultDueDate(dueDates: DateValidations) {
    // Check to see if our default date needs to be something besides the minimum, which is the case for admins not refreshing
    const enforceFourDayMinimum =  (!this.isRefresh && this.authSvc.isAdmin());
    // Allow holidays when user is Admin, otherwise filter out holidays
    if (this.authSvc.isAdmin()) {
      return !enforceFourDayMinimum ? this.minDate : this.getAdminDefaultNonRefreshDate(dueDates);
    }
    // Check if minDate is invalid, meaning it is a holiday, if so increase minDate by 1 day until hitting a valid date
    return this.invalidDates.some(invalidDate => moment(this.minDate).isSame(invalidDate, 'day')) ?
        this.addBusinessDays(this.minDate, 1, dueDates.invalidDates) : this.minDate;
  }

  calendarCheck(event: any) {
    if (this.dateCtrl.value < this.minDate) {
      this.dateCtrl.setValue(this.minDate);
    }
    if (this.dateCtrl.value > this.maxDate) {
      this.dateCtrl.setValue(this.maxDate);
    }
  }

  dateFilter = (d: Date): boolean => {
    if (this.dateMode === ZenDateModeEnum.DUE_DATE) {
      return this.dueDateFilter(d);
    } else if (this.dateMode === ZenDateModeEnum.NO_WEEKENDS) {
      return this.noWeekendsFilter(d);
    } else {
      return this.generalDateFilter(d);
    }
  }

  generalDateFilter = (d: Date): boolean => {
    if (d && this.minDate && this.maxDate) {
      const min = moment(d).diff(this.minDate, 'd', true);
      const max = moment(d).diff(this.maxDate, 'd', true);
      return min >= -1 && max <= 0;
    } else {
      return true;
    }
  }

  noWeekendsFilter = (d: Date): boolean => {
    if (d) {
      const day = d.getDay();
      return day !== 0 && day !== 6;
    } else {
      return true;
    }
  }

  dueDateFilter = (d: Date): boolean => {
    if (d && this.minDate && this.maxDate) {
      const day = d.getDay();
      const min = moment(d).isSameOrAfter(moment(this.minDate), 'day');
      const max = moment(d).isSameOrBefore(moment(this.maxDate), 'day');
      const validDate = !this.invalidDates.some(invalidDate => moment(d).isSame(invalidDate, 'day'));
      // Admins - Filters out Saturday & Sunday, and staying within minDate and maxDate (Holidays included)
      // Non-Admins - Filters out Saturday & Sunday & Holidays, and staying within minDate and maxDate
      if (this.authSvc.isAdmin()) {
        return day !== 0 && day !== 6 && min && max;
      } else {
        return day !== 0 && day !== 6 && min && max && validDate;
      }
    } else {
      return true;
    }
  }

  buildTimeList(forceRecalculateTimeList: boolean) {
    if (this.dateMode === ZenDateModeEnum.DUE_DATE) {
      this.setTimeList(11, 15, 0.5, forceRecalculateTimeList);
    } else {
      this.setTimeList(0, 23, this.setIntervalForTimeList(), false);
    }
  }

  setIntervalForTimeList(): number {
    if (this.timeInterval === 15) {
      return 0.25;
    } else if (this.timeInterval === 30) {
      return 0.5;
    } else if (this.timeInterval === 60) {
      return 1;
    } else {
      return 1; // defaults to hour increments
    }
  }

  /**
   * For same day refreshes
   * 1) Min request time due: 11AM
   * 2) Max request time due: 3PM
   * 3) Max time to make refresh request: 1PM
   * 4) Min allowed hours ahead of current time: 2
   *    * Examples (all selected time windows MUST be more than 2 hours in the future)
   *      -- Assuming 30 minute time windows, if its 9:59AM, the next available time is 12pm.
   *      -- Assuming 30 minute time windows, if its 10:01AM, the next available time is 12:30pm.
   * 5) Time can never be in the past.
   * 6) Admins can do anything, that’s not in the past.
   * 7) ForceRecalclateTimeList allows us to set the default value, if we change from a sameDayRefreshScenario
   */
  setTimeList(startHour: number, endHour: number, interval: number, forceRecalculateTimeList: boolean) {
    this.timeList = [];
    let firstNonDisabledValue = null;
    const _isToday = moment(this.dateCtrl.value).format(TimeSettings.Format) === moment().format(TimeSettings.Format);
    const _isAdmin = this.authSvc.isAdmin();
    const _maxHour =  (this.dateMode !== ZenDateModeEnum.DUE_DATE) ? endHour : // For non due dates, use endHour
      !_isAdmin && _isToday ? 13 : 15; // Today's max time for due date scenarios: For admin 3:00PM for advisor 1:00PM

    for (let hour = startHour; hour <= _maxHour; hour += interval) {
      const h = moment.duration(hour, 'hour');
      const _time = `${h.get('h')}:${h.get('minute')}`; // Format -> hh:ss
      const _dateWithTime = this.getMergedDateTime(this.startDate, _time);
      // Disable past time options if selected dateCtrl.value === today
      // Max time to make refresh request: 1PM if selected date is today
      // Min allowed hours ahead of current time: 2 -> (current time + 2 hours)
      const minAllowedDate = moment(this.startDate)
        // Admins can do anything, that’s not in the past. For others Min allowed hours ahead of current time: 2
        .hour(moment().hour() + (_isAdmin ? 0 : 2))
        .minute(moment().minutes());

      const _optionDisabled = _isToday
        && moment(_dateWithTime).isSameOrBefore(minAllowedDate);

      this.timeList.push(
        {
          label: moment(_dateWithTime).format(DueTimeEnum.DROPDOWN_LABEL_FORMAT),
          value: moment(_dateWithTime).format(DueTimeEnum.DROPDOWN_VALUE_FORMAT),
          disabled: _optionDisabled
        });
      // Lets store this so later we automatically choose the first valid value
      if (firstNonDisabledValue == null && !_optionDisabled) {
        firstNonDisabledValue = moment(_dateWithTime).format(DueTimeEnum.DROPDOWN_VALUE_FORMAT);
      }
    }

    // Persisting default time value if date time is already updated.
    if (!this.timeCtrl.value || forceRecalculateTimeList) {
      const currentDateTime = moment(this.startDate).format(DueTimeEnum.DROPDOWN_LABEL_FORMAT);
      const i = this.timeList.findIndex(t => t.label === currentDateTime);
      if (i !== -1) {
        this.timeCtrl.setValue(_isToday && this.timeList[i].disabled ? firstNonDisabledValue : this.timeList[i].value);
      } else if (this.dateMode === ZenDateModeEnum.DUE_DATE) {
        if (_isToday && !_isAdmin) {
          this.timeCtrl.setValue('13:00'); // Same day refresh scenarios default to 1pm for advisors
        } else {
          this.timeCtrl.setValue('12:00');
        }
      } else {
        this.timeCtrl.setValue(this.timeList.find(t => !t.disabled)?.value); // default time setting is first available when default not set
      }
    }
  }

  /** Date & Time merged -> time format -> 00:00:00 */
  getMergedDateTime(date: Date, time: string) {
    return new Date(moment(date).format('YYYY-MM-DD') + ' ' + time);
  }
}
