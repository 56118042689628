import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../_services/authentication.service';
import { Observable } from 'rxjs';
import {LenBulkPostModel, LenEinUpdate, LenModel} from '../models/len';
import {map, take} from 'rxjs/operators';
import { environment } from '../../../../../../../../environments';
import { OrganizationQuery } from '../../../../../../../stores/organization/organization.query';

@Injectable({
  providedIn: 'root'
})
export class LenService {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient,
    private authService: AuthenticationService,
    private organizationQuery: OrganizationQuery) {
  }

  getAllCustomerLens(customerId?: number): Observable<LenModel[]> {
    let url = `${this.baseUrl}/v1/customers/${customerId || this.getCurrentCustomerId()}/lens`;
    return this.http.get<LenModel[]>(url);
  }


  private getCurrentCustomerId() {
    return this.authService.getCurrentCustomerId() || this.organizationQuery.customers.getActiveId();
  }

  bulkUpdateLen(customerId: number, data: LenBulkPostModel[]) {
    let url = `${this.baseUrl}/v1/customers/${customerId}/lens/bulk`;
    return this.http.put<LenModel>(url, data).pipe(take(1));
  }

  getRateCheckLens(customerId: number, rateCheckId: string) {
    let url = `${this.baseUrl}/v1/customers/${customerId}/lens/query?rateCheckId=${rateCheckId}`;
    return this.http.get<LenModel[]>(url).pipe(take(1));
  }

  updateLenEins(customerId: number, lenEinUpdate: LenEinUpdate[]) {
    const organizationId = this.authService.getOrganizationId();
    let url = `${this.baseUrl}/v4/organizations/${organizationId}/customers/${customerId}/lens/eins`;
    return this.http.put<LenEinUpdate[]>(url, lenEinUpdate).pipe(take(1));
  }

}
