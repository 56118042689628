import { Injectable } from '@angular/core';

@Injectable()
export class LogService {

  level: string;

  constructor() { }

  log(logLevel: string, msg: string) {
    this.setCurrentLogLevel();
    if (this.level === 'info') {
      console.log(msg);
      return;
    } else if (logLevel === 'debug' && this.level === 'debug') {
      console.log(msg);
      return;
    } else if (logLevel === 'error' && (this.level === 'error' || this.level === 'debug')) {
      console.log(msg);
      return;
    }
  }

  debug(msg: string) {
    this.log('debug', msg);
  }

  error(msg: string) {
    this.log('error', msg);
  }

  info(msg: string) {
    this.log('info', msg);
  }

  setCurrentLogLevel() {
    if (typeof window['LOG_LEVEL'] === 'undefined') {
      window['LOG_LEVEL'] = 'ERROR';
    }
    this.level = window['LOG_LEVEL'];
  }


}
