<app-zen-loader *ngIf="loading"></app-zen-loader>
<div class="zen-total-fee-breakdown" [style.padding]="popperElement ? '2rem' : ''">
  <div *ngIf="popperElement" class="d-flex align-items-center justify-content-between mb-4">
    <p class="">Total Fee Breakdown</p>
    <i class="material-icons pointer large" (click)="closeAndReset()">close</i>
  </div>

  <table class="bill-calculator-table" *ngIf="feeDetails && form" [formGroup]="form">
    <thead>
    <tr>
      <th class="no-wrap">Your Fee ({{rateCheckData?.commodity === CommodityType.Electricity ? 'Mils' : _translations?.currency?.symbol}})</th>
      <th class="no-wrap">Platform Fee ({{rateCheckData?.commodity === CommodityType.Electricity ? 'Mils' : _translations?.currency?.symbol}})</th>
      <th class="no-wrap">Total Fee</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="td-input">
        <input formControlName="brokerFee" [imask]="FeeMask" [unmask]="true" class="edit-field"
               (change)="recalculateFees(form.controls.brokerFee.value)">
      </td>

      <td class="bg-white"> <span class="text-color font-weight-600">{{feeDetails?.platformFee | number: ZenNumberFormat.COST}}</span> </td>

      <td class="td-input">
        <input formControlName="totalFee" [imask]="FeeMask" [unmask]="true" class="edit-field"
               (change)="recalculateFees(null, form.controls.totalFee.value)">
      </td>
    </tr>
    </tbody>
  </table>

  <div *ngIf="feeError.hasError">
    <app-zen-total-fee-error [data]="{feeError, feeDetails, isElectric}"></app-zen-total-fee-error>
  </div>

  <div class="mt-5 mb-3">
    <div class="">
      <mat-slider class="default-slider w-100" showTickMarks discrete [displayWith]="formatLabel"
                  [min]="feeDetails.minFeeDollarsLimit" [max]="feeDetails.maxFeeDollarsLimit"
                  step="0.1" color="accent">
        <input matSliderThumb (dragEnd)="onDragEnd($event)" [value]="feeDetails.totalFee">
      </mat-slider>
    </div>

    <div class="d-flex align-items-center {{rateCheckData?.platformFeeIncluded ? 'justify-content-between' : 'justify-content-end'}}">
      <p class="text-sm mt-2" *ngIf="rateCheckData?.platformFeeIncluded">Inclusive of Platform Fee</p>
      <p class="text-md font-weight-600">ARR: {{feeDetails?.arr | currency: _translations?.currency?.abbreviation : 'symbol-narrow' : ZenNumberFormat.COST}}</p>
    </div>
  </div>

  <div *ngIf="popperElement" class="d-flex align-items-center justify-content-end mt-3">
    <button mat-button (click)="closeAndReset()" class="me-5">Cancel</button>
    <button mat-raised-button color="accent" (click)="submit()" [disabled]="loading">Save</button>
  </div>

</div>
