<app-zen-dialog-wrapper [data]="data" actionStyleCls="center pb-0" (onSubmit)="submit($event)">
  <!-- Error log STARTS -->
  <div *ngIf="errors?.length" class="zen-message import-info mt-4 pe-3 error text-start">
    <p class="pt-2 mb-3 d-flex text-md"><i
      class="material-icons danger-1-color me-2 mt-1 ">info_outline</i>{{errorMsg}}</p>
    <div class="error-log">
      <p class="text-md font-weight-600 pointer d-flex align-items-center" (click)="showErrorLog = !showErrorLog">
        <span class="material-icons pe-1">{{showErrorLog ? 'expand_more' : 'chevron_right'}}</span>
        Error Log
      </p>
      <ng-container *ngIf="showErrorLog">
        <hr class="border-text-color-dark me-3"/>
        <div class="card-container-wrapper">
          <div class="card-container ms-0 error-preview-dialog-tbl"
               [class.pb-0]="errors?.length > 4" *ngIf="errors?.length">
            <app-zen-mat-table [data]="errors" [tableConfig]="tableConfig"></app-zen-mat-table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- Error log ENDS -->
</app-zen-dialog-wrapper>
