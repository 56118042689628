<ng-container *ngIf="zenMatTableHelperSvc">
  <!-- Adding this app-layout component to support base font-size(62.5% -> 1px = 0.1rem) -->
  <app-layout></app-layout>

  <div class="zen-layout-v2">
    <h3 class="sub-header-bg">PORTFOLIO TABLE</h3>
    <div class="card-container-wrapper">
      <div id="card-zen-mat-table" class="card-container w-100">
        <app-zen-mat-table [data]="data" [tableConfig]="tableConfig"></app-zen-mat-table>
      </div>
    </div>

    <h3 class="sub-header-bg">RATE CHECKS TABLE</h3>
    <div class="card-container-wrapper four-cards-responsive">
      <div id="card-portfolio-tabs-1" class="card-container pf-tabs-main-wrapper">
        <div class="position-relative">
          <app-zen-tab-top-action *ngIf="rcTableTopConfig" [config]="rcTableTopConfig"></app-zen-tab-top-action>
        </div>

        <mat-tab-group disableRipple class="v1" (selectedTabChange)="handleTabChange($event)">
          <mat-tab [label]="ZenRcTableEnum.OPEN" *ngIf="openTableConfig">
            <ng-template mat-tab-label>{{'Open (' + (50 || 0) + ')'}}</ng-template>
            <app-zen-mat-table [tableConfig]="openTableConfig" [data]="openRcs"
                               [tooltipContent]="contractTooltipContent"
                               (onMenuClick)="handleRowMenuModification($event)"></app-zen-mat-table>
          </mat-tab>
          <mat-tab [label]="ZenRcTableEnum.CLOSED" *ngIf="closedTableConfig">
            <ng-template mat-tab-label>{{'Closed (' + (15 || 0) + ')'}}</ng-template>
            <app-zen-mat-table [tableConfig]="closedTableConfig" [data]="closedRcs"
                               [tooltipContent]="contractTooltipContent"
                               (onMenuClick)="handleRowMenuModification($event)"></app-zen-mat-table>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <h3 class="sub-header-bg">STACK RANKING TABLE</h3>
    <div class="card-container-wrapper four-cards-responsive">
      <div id="card-portfolio-tabs-2" class="card-container pf-tabs-main-wrapper">
        <mat-tab-group disableRipple class="v1" [(selectedIndex)]="srSelectedIndex"
                       (selectedTabChange)="srHandleTabChange($event)">
          <mat-tab [label]="srTableEnum.Electricity">
            <ng-template mat-tab-label>{{'Electricity'}}</ng-template>
            <app-zen-mat-table [tableConfig]="srElectricityTableConfig"
                               [data]="srStackRankingElectricityData"></app-zen-mat-table>
          </mat-tab>
          <mat-tab [label]="srTableEnum.Gas">
            <ng-template mat-tab-label>{{'Natural Gas'}}</ng-template>
            <app-zen-mat-table [tableConfig]="srNatGasTableConfig"
                               [data]="srStackRankingNatGasData"></app-zen-mat-table>
          </mat-tab>
        </mat-tab-group>
      </div>

    </div>

  </div>
  <popper-content #contractTooltipContent>
    <app-zen-popper [closeable]="false" [popperContent]="contractTooltipContent">
      <p class="mb-0">Your fee ($0.00) x Proj. Annual Usage (0 kWh) = Proj. ARR ($0)</p>
    </app-zen-popper>
  </popper-content>
</ng-container>
