import {Injectable} from '@angular/core';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';
import {ZenConversionRatesEnum, ZenRatesEnum} from '../../_enums/zen-rates.enum';
import {CommodityFeeFormatPipe} from '../../_pipes/commodity-fee-format.pipe';
import {ZenCurrencyTypeEnum} from '../../_enums/zen-currency-type.enum';
import {TranslateService} from '@ngx-translate/core';
import {CountryEnum, ZenLocaleModel} from '../../_model/zen-locale.model';

@Injectable({
  providedIn: 'root'
})
export class ZenUnitsHelperService {
  translations: ZenLocaleModel;

  constructor(
    private feeFormat: CommodityFeeFormatPipe,
    private translateSvc: TranslateService
  ) {
    this.translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;
  }

  /** This method used to get base values while submitting the form
   * For 1. Electricity base unit is kWh, 2. Gas base unit is Dth
   * Electricity - > 1. MWh, 2. kWh (default) →
   *   -- if unit MWh → val * 1000
   *
   * Gas → 1.Dth (default)  2.Th, 3.Ccf ,  4.Mcf  5.MMBtu (same as Dth), 6.Btu
   *   -– $ per Ccf divided by 1.037 equals $ per therm
   *   -- $ per Mcf divided by 10.37 equals $ per therm
   *
   * Dth = Th / 10
   * Dth = MMBtu
   * Dth = Btu / 1 mn
   * Dth = (Ccf / 1.037) / 10
   * Dth = (Mcf / 10.37) / 10
   * */
  /** Ref: https://www.abraxasenergy.com/energy-resources/toolbox/conversion-calculators/energy/ */
  convertRateToBaseUnitValue(unit: ZenUnitsEnum, value: number) {
    switch (unit) {
      // Electricity Units
      case ZenUnitsEnum.MWh:
        return value / 1000;
      case ZenUnitsEnum.kWh:
        return value;

      // Gas Units
      case ZenUnitsEnum.Dth:
      case ZenUnitsEnum.MMBtu:
        return value;
      case ZenUnitsEnum.Th:
        // Dth -> Th * 10
        return (value * 10);
      case ZenUnitsEnum.Ccf:
        // 1 Dth = 9.804 Ccf
        return (value * ZenConversionRatesEnum.Dth_to_Ccf);
      case ZenUnitsEnum.Mcf:
        // 1 Dth = 0.98 Mcf
        return (value * ZenConversionRatesEnum.Dth_to_Mcf);
      case ZenUnitsEnum.GJ:
        // 1 Dth = 1.0550559000000006 GJ
        return (value * ZenConversionRatesEnum.Dth_to_GJ);
      case ZenUnitsEnum.CubicMeters:
        // 1 Dth = 28.33 Cubic Meters
        return (value * ZenConversionRatesEnum.Dth_to_CubicMeters);
    }
  }

  /** This method used to set min and max validations for supplierRate form control in the ZenContractForm */
  /** Ref: https://www.abraxasenergy.com/energy-resources/toolbox/conversion-calculators/energy/ */
  getMinAndMaxValidationValue(commodity: CommodityType, unit: ZenUnitsEnum): { min: number; max: number; } {
    if (commodity === CommodityType.Electricity) {
      const _min = ZenRatesEnum.ELECTRICITY_MIN_RATE;
      const _max = ZenRatesEnum.ELECTRICITY_MAX_RATE;
      switch (unit) {
        case ZenUnitsEnum.kWh:
          return {min: _min, max: _max};
        case ZenUnitsEnum.MWh:
          return {min: _min * 1000, max: _max * 1000};
      }
    }

    if (commodity === CommodityType.Gas) {
      const _min = ZenRatesEnum.NAT_GAS_MIN_RATE; // 1 Dth
      const _max = ZenRatesEnum.NAT_GAS_MAX_RATE; // 50 Dth
      switch (unit) {
        case ZenUnitsEnum.Dth:
        case ZenUnitsEnum.MMBtu:
          return {min: _min, max: _max};
        case ZenUnitsEnum.Th:
          // Th = Dth / 10;
          return {min: _min / 10, max: _max / 10};
        case ZenUnitsEnum.Ccf:
          // 1 Dth = 9.804 Ccf
          return {min: _min / ZenConversionRatesEnum.Dth_to_Ccf, max: _max / ZenConversionRatesEnum.Dth_to_Ccf};
        case ZenUnitsEnum.Mcf:
          // 1 Dth = 0.98 Mcf;
          return {min: _min / ZenConversionRatesEnum.Dth_to_Mcf, max: _max / ZenConversionRatesEnum.Dth_to_Mcf};
        case ZenUnitsEnum.GJ:
          // 1 Dth = 1.0550559000000006 GJ;
          return {min: _min / ZenConversionRatesEnum.Dth_to_GJ, max: _max / ZenConversionRatesEnum.Dth_to_GJ};
        case ZenUnitsEnum.CubicMeters:
          // 1 Dth = 28.33 Cubic Meters
          return {min: _min / ZenConversionRatesEnum.Dth_to_CubicMeters, max: _max / ZenConversionRatesEnum.Dth_to_CubicMeters};
      }
    }
  }

  getAllUnitOptions(commodity: CommodityType, isRate = true): {label: string; value: ZenUnitsEnum;}[] {
    const _symbolPrefix = `${this.translations?.currency?.symbol}/`;
    switch (commodity) {
      case CommodityType.Electricity:
        return [
          {label: (isRate ? _symbolPrefix : '') + ZenUnitsEnum.kWh, value: ZenUnitsEnum.kWh},
          {label: (isRate ? _symbolPrefix : '') + ZenUnitsEnum.MWh, value: ZenUnitsEnum.MWh}
        ];
      case CommodityType.Gas:
        let _units = [
          {label: (isRate ? _symbolPrefix : '') + ZenUnitsEnum.Dth, value: ZenUnitsEnum.Dth},
          {label: (isRate ? _symbolPrefix : '') + ZenUnitsEnum.Th, value: ZenUnitsEnum.Th},
          {label: (isRate ? _symbolPrefix : '') + ZenUnitsEnum.Ccf, value: ZenUnitsEnum.Ccf},
          {label: (isRate ? _symbolPrefix : '') + ZenUnitsEnum.Mcf, value: ZenUnitsEnum.Mcf},
          {label: (isRate ? _symbolPrefix : '') + ZenUnitsEnum.MMBtu, value: ZenUnitsEnum.MMBtu}
        ];

        if (this.translations.id === CountryEnum.CA) {
          _units.push({label: (isRate ? _symbolPrefix : '') + ZenUnitsEnum.GJ, value: ZenUnitsEnum.GJ});
          _units.push({label: (isRate ? _symbolPrefix : '') + ZenUnitsEnum.CubicMeters, value: ZenUnitsEnum.CubicMeters});
        }
        return _units;
    }
  }

  getRateFieldValidationMsg(commodity: CommodityType, unit: ZenUnitsEnum): string {
    const {min, max} = this.getMinAndMaxValidationValue(commodity, unit);
    const currencyType = ZenCurrencyTypeEnum.CURRENCY;
    return `Value must be between ${this.feeFormat.transform(min, {commodity, currencyType})} and ${this.feeFormat.transform(max, {commodity, currencyType})}`;
  }


  /** This method used to calculate rate while changing the supply cost input in the Add Bill -> Calculate Cost step. */
  getSelectedUnitCalculatedUsageValue(unit: ZenUnitsEnum, rateValue: number): number {
    switch (unit) {
      case ZenUnitsEnum.kWh:
        return rateValue;
      case ZenUnitsEnum.MWh:
        return rateValue * 1000;
      // Gas
      case ZenUnitsEnum.Dth:
      case ZenUnitsEnum.MMBtu:
        return rateValue;
      case ZenUnitsEnum.Th:
        // Dth -> Th / 10;
        return rateValue / 10;
      case ZenUnitsEnum.Ccf:
        // Dth -> (Ccf / 1.037);
        return (rateValue / ZenConversionRatesEnum.Dth_to_Ccf);
      case ZenUnitsEnum.Mcf:
        // Dth -> (Mcf / 10.37);
        return (rateValue / ZenConversionRatesEnum.Dth_to_Mcf);
      case ZenUnitsEnum.GJ:
        // Dth -> (GJ / 10.37);
        return (rateValue / ZenConversionRatesEnum.Dth_to_GJ);
      case ZenUnitsEnum.CubicMeters:
        // Dth -> (CubicMeters / 28.33);
        return (rateValue / ZenConversionRatesEnum.Dth_to_CubicMeters);
    }
  }
}
