<!-- The options in the below field get disable on selecting 6 options. -->
<div class="position-relative">
<div class="d-flex" *ngIf="formCtrl && searchCtrl">
  <div class="w-100">

    <!-- Max selection limit is 6 -->
    <mat-form-field appearance="outline" class="contract-terms mat-form-field-type-mat-chip-list"
                    [class.form-field-without-label]="!label"
                    [class.ng-invalid]="!formCtrl.valid"
                    [class.ng-touched]="formCtrl.touched || searchCtrl.touched"
                    [class.value-exists]="(isMobile && formCtrl.value?.length > 3) || (formCtrl.value?.length > 3 && !compactSize)"
                    [floatLabel]="formCtrl?.value?.length ? 'always' : 'auto'"
                    (click)="filteredTermsOptions ? filteredTermsOptions?.next(itemListData) : null">
      <mat-label>{{label}}</mat-label>

      <mat-chip-grid #chipList [disabled]="loading">
        <mat-chip-row class="rc-req filter-chip" *ngFor="let item of formCtrl?.value"
                  (removed)="removeTerm(item)">
          <div class="d-flex align-items-center">
            <span>{{item.value}}</span>
            <span class="material-icons" matChipRemove>close</span>
          </div>
        </mat-chip-row>

        <input matInput #termInputSelection
              [formControl]="searchCtrl"
              [matAutocomplete]="termAuto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="checkInputValueAndUpdate()">

      </mat-chip-grid>

      <mat-autocomplete #termAuto="matAutocomplete" (optionSelected)="handleOptionSelect($event)">
        <!-- .multi-chip-select-option - is used to to fix multi select option closing issue after selection.
             This cls inherit mat-checkbox width & height for mat-option. It will helps to call $event.stopPropagation(); -->
        <mat-option *ngFor="let t of filteredTermsOptions | async" [value]="t"
                    [id]="'chip-list-option-'+t.key"
                    class="multi-chip-select-option"
                    [disabled]="t.disabled || (isMaxTermsSelected && !selectedKeyIds?.includes(t.key))">

          <mat-checkbox [checked]="selectedKeyIds?.includes(t.key)"
                        (click)="$event.stopPropagation(); addTerm(t)"
                        [disabled]="t.disabled || (isMaxTermsSelected && !selectedKeyIds?.includes(t.key))">
            <p class="text-md font-weight-500 m-0">{{t.value}}</p>
            <p class="text-sm text-color small m-0" *ngIf="t.subOptionText">{{t.subOptionText}}</p>
          </mat-checkbox>

        </mat-option>
      </mat-autocomplete>
      <i class="material-symbols-rounded pointer" (click)="expandDropdownClick()" matSuffix>expand_more</i>
    </mat-form-field>
  </div>
  <i *ngIf="infoPopper" class="material-icons-outlined mat-form-field-info-icon" [popper]="infoPopper"
     popperTrigger="hover" popperPlacement="top" popperApplyClass="sm">info</i>
</div>
<mat-error class="ms-4 outside-mat-form-field" *ngIf="!formCtrl.valid && (searchCtrl.touched || formCtrl.touched)">Required Field*</mat-error>
</div>
