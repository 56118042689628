<svg width="89" height="88" viewBox="0 0 89 88" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M44.5 2.30694C68.3746 2.30694 87.6677 21.1427 87.6677 44.3036C87.6677 67.4644 68.3746 86.3002 44.5 86.3002C20.6254 86.3002 1.33234 67.4644 1.33234 44.3036C1.33234 21.1427 20.6254 2.30694 44.5 2.30694Z" fill="white" stroke="#FF0CCA" stroke-width="2.66467"/>
  <mask id="mask0_13436_26714" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="89" height="88">
    <path d="M44.5 2.30694C68.3746 2.30694 87.6677 21.1427 87.6677 44.3036C87.6677 67.4644 68.3746 86.3002 44.5 86.3002C20.6254 86.3002 1.33234 67.4644 1.33234 44.3036C1.33234 21.1427 20.6254 2.30694 44.5 2.30694Z" fill="white" stroke="#54E2C0" stroke-width="2.66467"/>
  </mask>
  <g mask="url(#mask0_13436_26714)">
    <path d="M27.7218 58.0158C31.0577 62.9367 41.0503 66.4004 42.3824 66.0125L47.98 67.0787L55.7102 60.9479C69.6636 47.4024 71.8407 8.83208 46.4448 8.83203C25.5948 8.83199 24.0093 20.5745 22.1884 27.2244C17.4994 44.3484 23.5519 51.8647 27.7218 58.0158Z" fill="#E5E8ED"/>
    <path d="M50.9757 54.0205L50.583 76.8373C50.5141 80.8379 48.5781 84.3767 45.7705 85.4873C43.6063 86.3428 41.4577 86.2873 39.3249 85.3209C36.5572 84.0663 34.7454 80.4307 34.8143 76.4301L35.207 53.6133L50.9757 54.0205Z" fill="#F2F5F9"/>
    <path opacity="0.2" d="M50.9748 63.4547L50.9748 60.8063L34.9814 60.0117L34.9814 62.925C35.9308 63.8962 38.7936 66.474 42.6495 66.8978C46.5054 67.3215 49.8063 64.6024 50.9748 63.4547Z" fill="#A8B8C2"/>
    <path d="M32.92 21.6289L32.9244 21.6647C33.087 22.9901 34.3463 29.6107 43.2754 29.4843C53.1967 29.3439 64.6991 39.2969 62.9736 45.4721C61.2482 51.6473 61.9671 53.7525 53.9152 60.7697C46.9904 66.8045 43.575 66.5076 40.2851 65.5028L40.0558 65.4317C40.0175 65.4196 39.9793 65.4075 39.9411 65.3953L39.7119 65.3215C39.5973 65.2843 39.4825 65.2465 39.3675 65.2086L39.1373 65.1325C39.0988 65.1197 39.0603 65.107 39.0218 65.0943L38.7901 65.0181C38.7514 65.0054 38.7126 64.9927 38.6738 64.9801C34.7916 63.7169 23.0011 54.7349 23.4326 45.0511C23.4326 45.0511 20.33 35.1304 27.0879 31.9025C33.1701 28.9973 33.0136 22.8432 32.9215 21.6415L32.92 21.6289Z" fill="#F2F5F9"/>
    <path d="M38.4415 1.87978C26.978 4.60686 31.7542 11.1332 35.5752 14.0555C44.0053 13.3272 50.1055 16.3458 52.1019 17.9461C53.0184 17.329 55.1916 15.1039 56.5518 11.1406C58.252 6.18648 52.771 -1.52909 38.4415 1.87978Z" fill="#E5E8ED"/>
    <path d="M49.5164 12.3786L55.0398 13.6745C55.0398 13.6745 55.1515 29.6114 35.6707 33.7007C35.6707 33.7007 48.797 24.8738 49.5164 12.3786Z" fill="#E5E8ED"/>
    <mask id="mask1_13436_26714" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="26" y="40" width="12" height="4">
      <path d="M37.6611 42.1437C37.6611 42.1437 33.696 40.8539 30.9509 41.6724C28.2057 42.4911 27.5703 43.8805 27.5703 43.8805L26.6553 41.5236L27.7481 42.4415L27.1382 40.8041L28.3074 41.8213C28.3074 41.8213 32.2218 38.472 37.6611 42.1437Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_13436_26714)">
      <path d="M26.6553 40.4238H37.6611V43.8805H26.6553V40.4238Z" fill="#A8B8C2"/>
    </g>
    <mask id="mask2_13436_26714" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="48" y="40" width="12" height="4">
      <path d="M48.0557 42.1437C53.4951 38.472 57.4095 41.8213 57.4095 41.8213L58.5786 40.8041L57.9687 42.4415L59.0617 41.5236L58.1466 43.8805C58.1466 43.8805 57.5111 42.4911 54.766 41.6724C52.0208 40.8539 48.0557 42.1437 48.0557 42.1437Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_13436_26714)">
      <path d="M48.0557 40.4238H59.0617V43.8805H48.0557V40.4238Z" fill="#A8B8C2"/>
    </g>
    <mask id="mask3_13436_26714" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="50" y="51" width="7" height="3">
      <path d="M53.5748 51.6172C55.1941 51.6172 56.5068 51.9752 56.5068 52.4169C56.5068 52.8585 55.1941 53.2165 53.5748 53.2165C52.7971 53.2165 52.0513 53.1323 51.5014 52.9823C50.9515 52.8323 50.6426 52.6289 50.6426 52.4169C50.6426 52.2048 50.9515 52.0014 51.5014 51.8514C52.0513 51.7014 52.7971 51.6172 53.5748 51.6172Z" fill="white"/>
    </mask>
    <g mask="url(#mask3_13436_26714)">
      <path d="M50.6426 51.6172H56.5068V53.2165H50.6426V51.6172Z" fill="#D9DFE3" fill-opacity="0.6"/>
    </g>
    <mask id="mask4_13436_26714" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="30" y="51" width="7" height="3">
      <path d="M33.3238 51.6172C34.9431 51.6172 36.2558 51.9752 36.2558 52.4169C36.2558 52.8585 34.9431 53.2165 33.3238 53.2165C32.5461 53.2165 31.8003 53.1323 31.2504 52.9823C30.7005 52.8323 30.3916 52.6289 30.3916 52.4169C30.3916 52.2048 30.7005 52.0014 31.2504 51.8514C31.8003 51.7014 32.5461 51.6172 33.3238 51.6172Z" fill="white"/>
    </mask>
    <g mask="url(#mask4_13436_26714)">
      <path d="M30.3916 51.6172H36.2558V53.2165H30.3916V51.6172Z" fill="#D9DFE3" fill-opacity="0.6"/>
    </g>
    <mask id="mask5_13436_26714" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="50" y="42" width="5" height="5">
      <path d="M52.402 42.043C53.4772 42.043 54.3488 43.0129 54.3488 44.2094C54.3488 45.4059 53.4772 46.3759 52.402 46.3759C51.3267 46.3759 50.4551 45.4059 50.4551 44.2094C50.4551 43.0129 51.3267 42.043 52.402 42.043Z" fill="white"/>
    </mask>
    <g mask="url(#mask5_13436_26714)">
      <path d="M50.4551 42.043H54.3488V46.3759H50.4551V42.043Z" fill="#53E2C0"/>
    </g>
    <path d="M52.402 46.3759C53.4772 46.3759 54.3488 45.4059 54.3488 44.2094C54.3488 43.0129 53.4772 42.043 52.402 42.043C51.3267 42.043 50.4551 43.0129 50.4551 44.2094C50.4551 45.4059 51.3267 46.3759 52.402 46.3759Z" stroke="#A8B8C2" stroke-width="0.359456" stroke-linecap="round" stroke-linejoin="round"/>
    <mask id="mask6_13436_26714" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="30" y="42" width="5" height="5">
      <path d="M32.3766 42.043C33.4518 42.043 34.3234 43.0129 34.3234 44.2094C34.3234 45.4059 33.4518 46.3759 32.3766 46.3759C31.3013 46.3759 30.4297 45.4059 30.4297 44.2094C30.4297 43.0129 31.3013 42.043 32.3766 42.043Z" fill="white"/>
    </mask>
    <g mask="url(#mask6_13436_26714)">
      <path d="M30.4297 42.043H34.3234V46.3759H30.4297V42.043Z" fill="#53E2C0"/>
    </g>
    <path d="M32.3785 46.3778C33.4537 46.3778 34.3254 45.4079 34.3254 44.2114C34.3254 43.0149 33.4537 42.0449 32.3785 42.0449C31.3033 42.0449 30.4316 43.0149 30.4316 44.2114C30.4316 45.4079 31.3033 46.3778 32.3785 46.3778Z" stroke="#A8B8C2" stroke-width="0.359456" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M34.7178 69.4915C28.2458 70.4839 22.5751 73.3534 19.4853 76.9589L28.0502 90.6363L24.5559 96.2359L36.3032 116.517C36.3032 116.517 33.8504 87.1654 34.7178 69.4915Z" fill="#D7E0E4"/>
    <path d="M53.1689 69.4926C59.4111 71.3139 64.7437 74.6353 67.1424 77.897L58.1908 90.004L61.2638 96.9432L51.8258 118.306C51.8258 118.306 51.0727 97.348 53.1689 69.4926Z" fill="#D7E0E4"/>
    <path d="M35.227 82.3025L26.7504 87.3981C25.6689 76.7438 28.3607 68.7184 34.0661 63.8809C34.0661 63.8809 35.033 76.5234 39.028 80.018L40.314 98.9587C38.4847 85.3783 35.7859 86.0528 35.7859 86.0528L35.227 82.3025Z" fill="white"/>
    <path d="M50.08 82.3017L58.5566 87.3972C59.6382 76.743 57.2868 69.4938 51.5814 64.6562C51.5814 64.6562 50.2741 76.5225 46.279 80.0172L44.7628 98.9578C46.5921 85.3774 49.5211 86.052 49.5211 86.052L50.08 82.3017Z" fill="white"/>
    <path d="M44.5 1.73278C68.6771 1.73278 88.2418 20.8113 88.2418 44.3036C88.2418 67.7958 68.6771 86.8743 44.5 86.8743C20.3229 86.8743 0.758169 67.7958 0.758169 44.3036C0.758169 20.8113 20.3229 1.73278 44.5 1.73278Z" stroke="#54E2C0" stroke-width="1.51634"/>
  </g>
  <g id="jen-zen-border">
    <path d="M44.5 2.30694C68.3746 2.30694 87.6677 21.1427 87.6677 44.3036C87.6677 67.4644 68.3746 86.3002 44.5 86.3002C20.6254 86.3002 1.33234 67.4644 1.33234 44.3036C1.33234 21.1427 20.6254 2.30694 44.5 2.30694Z" stroke="#D7E0E4" stroke-width="2.66467"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M53.1079 19.4053C53.1079 19.4053 43.0063 39.9272 21.6543 40.2867L25.2485 24.6071L37.7766 14.6973L47.3411 15.3896L53.1079 19.4053Z" fill="#E5E8ED"/>
  <path d="M44.1545 26.6091C44.1545 26.6091 57.1313 42.3417 64.0191 38.0626C64.0191 38.0626 59.4151 24.1707 53.4717 21.7809L44.1545 26.6091Z" fill="#E5E8ED"/>
  <path d="M53.9721 37.7913C57.9388 37.8051 61.8263 38.3972 62.7736 38.8286C62.7736 38.8286 62.9893 39.7015 63.0399 41.0035L64.486 39.703V40.6684L63.0243 42.3382C62.9377 43.7906 62.5958 45.5043 61.6622 47.1079C59.4394 50.9251 56.4078 51.6615 52.3661 51.2015C48.3245 50.7416 46.759 47.6598 46.1021 46.1879C45.942 45.8292 45.8012 45.4345 45.6569 45.0372L45.5697 44.7987C45.1006 43.528 44.5494 42.2979 43.1717 42.1988C41.2773 42.3355 40.4988 44.2859 40.1405 45.9581C39.7364 47.8439 38.019 50.7415 33.9773 51.2015C29.9357 51.6615 26.9041 50.9254 24.6813 47.1079C23.7476 45.5043 23.406 43.7906 23.3192 42.3382L21.8574 40.6684V39.703L23.3036 41.0035C23.3538 39.7015 23.5699 38.8286 23.5699 38.8286C24.5172 38.3974 28.4047 37.8004 32.3714 37.7913C34.7515 37.7831 37.16 37.9841 38.9787 38.5533C40.1403 38.9168 40.9964 39.1574 41.6234 39.3157L41.824 39.3655C42.6573 39.5686 43.0301 39.6095 43.1719 39.6112C43.4225 39.6071 44.3967 39.4824 47.365 38.5533C49.1838 37.984 51.5922 37.7822 53.9721 37.7913ZM31.7798 39.5749C30.5294 39.5707 29.1271 39.6805 27.6115 39.979C22.4079 40.991 26.2395 49.6749 31.2991 49.6841C36.3575 49.7021 39.0294 46.5563 37.918 40.6229C37.918 40.6229 35.5308 39.584 31.7798 39.5749ZM54.3619 39.5749C53.1116 39.5707 51.71 39.6805 50.1944 39.979C44.9908 40.991 48.8224 49.6749 53.882 49.6841C58.9403 49.7021 61.6115 46.5563 60.5001 40.6229C60.5001 40.6229 58.1129 39.584 54.3619 39.5749Z" fill="#54E2C0" class="fill-primary-color"/>
  <path d="M43.166 58.9196C40.1263 59.0786 37.4665 57.3962 36.5166 56.5352C37.8623 56.8663 41.1712 57.5287 43.6409 57.5287C46.1107 57.5287 48.6279 56.9988 49.5779 56.7339C48.7071 57.3962 46.2057 58.7607 43.166 58.9196Z" fill="white"/>
  <path d="M42.9285 59.7144C39.6988 59.7144 37.3082 57.5949 36.5166 56.5352C37.704 57.1975 39.6038 58.4891 42.9285 58.7209C45.7782 58.9196 48.3113 57.4624 49.5779 56.7339C48.7071 57.7274 46.1582 59.7144 42.9285 59.7144Z" fill="#A8B8C2"/>
  <path d="M43.166 61.3041C45.6357 61.4631 48.5488 58.2573 49.5779 56.7339C48.4696 57.4624 46.0157 59.5157 43.166 59.5157C39.8413 59.5157 37.7831 57.5949 36.5166 56.5352C37.9415 58.3235 40.0788 61.1054 43.166 61.3041Z" fill="#D5DBE4"/>
  <path d="M42.9285 57.7288C45.9682 57.8878 48.5488 57.199 49.5779 56.7353C48.9446 56.9491 47.5355 57.0895 46.0157 56.7353C44.1159 56.2925 42.9285 55.9405 40.5537 56.5366C38.6539 57.0135 37.1499 56.7353 36.5166 56.5366C37.4665 56.8678 39.8888 57.5698 42.9285 57.7288Z" fill="#D5DBE4"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M25.142 25.1492C25.142 25.1492 20.1161 58.6956 30.1995 71.345C30.1995 71.345 20.3564 61.7255 21.1782 31.5838" fill="#E5E8ED"/>
  <path d="M30.6536 26.5644C38.5425 17.2126 49.4251 18.8565 53.1089 20.0555C52.4288 19.9095 49.1304 20.4246 41.3775 23.6524C33.6247 26.8802 25.7924 39.8642 22.8454 45.9527L21.6553 47.7511C21.882 45.1134 22.7647 35.9161 30.6536 26.5644Z" fill="#F6F7F9"/>
  <path d="M25.9201 20.5617C31.8784 13.0697 41.4247 14.9643 46.1786 16.7305C45.4392 16.7305 42.8826 16.8538 38.5723 17.3467C33.1845 17.9629 25.5781 23.5079 24.4688 30.4084C23.5814 35.9288 22.5144 48.5222 22.5672 53.0815C20.7185 45.4416 19.9618 28.0537 25.9201 20.5617Z" fill="#F6F7F9"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M61.6139 26.5608C61.6139 26.5608 65.0794 60.2699 54.4181 72.5219C54.4181 72.5219 64.6145 64.4707 65.1915 34.3244" fill="#E5E8ED"/>
  <path d="M64.8195 34.0495C63.6569 25.3126 57.8443 21.2735 55.0834 20.3461C55.5631 20.56 57.4021 22.2901 60.9209 27.4993C64.4397 32.7084 64.1197 43.4779 63.5198 48.2114L63.5408 49.749C64.4514 48.1562 65.9821 42.7864 64.8195 34.0495Z" fill="#F6F7F9"/>
  <rect x="52.2451" y="43.0898" width="1.59934" height="1.59934" rx="0.799668" fill="white"/>
  <rect x="31.9863" y="43.0898" width="1.59934" height="1.59934" rx="0.799668" fill="white"/>
  <path d="M54.9106 10.8375C44.2484 8.70505 36.9625 9.94897 34.6523 10.8375C35.1855 9.94896 37.851 8.17188 44.2484 8.17188C50.6457 8.17188 54.0221 9.94896 54.9106 10.8375Z" fill="white"/>
</svg>
