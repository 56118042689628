<app-zen-dialog-wrapper [data]="data" [hideButton]="true">
  <div class="status-report-modal" *ngIf="matMsgService.dataProgressList?.length">

    <app-zen-mat-table [data]="matMsgService.dataProgressList" [tableConfig]="tableConfig"></app-zen-mat-table>

    <div class="status-report-footer">
      <div class="row">
        <div class="col-4 col-sm-3">
          <span class="zen-progress-status" [style.backgroundColor]="ZenColors.SUCCESS_2">Approved</span>
        </div>
        <div class="col-8 col-sm-9">
          <p class="text-md">Pricing has been received and is available.</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4 col-sm-3">
          <span class="zen-progress-status" [style.backgroundColor]="ZenColors.WARN_1">Ineligible</span>
        </div>
        <div class="col-8 col-sm-9">
          <p class="text-md">Pricing unavailable due to {{'nomenclature.supplier' | translate}} matrix specifications.</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4 col-sm-3">
          <span class="zen-progress-status" [style.backgroundColor]="ZenColors.INFO_1">Processing</span>
        </div>
        <div class="col-8 col-sm-9">
          <p class="text-md">Pricing has been received and is being processed.</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4 col-sm-3">
          <span class="zen-progress-status" [style.backgroundColor]="ZenColors.DANGER_1">Not Received</span>
        </div>
        <div class="col-8 col-sm-9">
          <p class="text-md">Pricing has not yet been received from the {{'nomenclature.supplier' | translate}}.</p>
        </div>
      </div>
    </div>
  </div>
</app-zen-dialog-wrapper>
