<svg width="167" height="163" viewBox="0 0 167 163" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M83.5 1C129.089 1 166 36.9777 166 81.3026C166 125.628 129.089 161.605 83.5 161.605C37.9112 161.605 1 125.628 1 81.3026C1 36.9777 37.9112 1 83.5 1Z" fill="white" stroke="#D7E0E4" stroke-width="2"/>
  <mask id="mask0_13403_136706" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="167" height="163">
    <path d="M83.5 2.5C128.298 2.5 164.5 37.8436 164.5 81.3026C164.5 124.762 128.298 160.105 83.5 160.105C38.7017 160.105 2.5 124.762 2.5 81.3026C2.5 37.8436 38.7017 2.5 83.5 2.5Z" fill="white" stroke="#54E2C0" stroke-width="5"/>
  </mask>
  <g mask="url(#mask0_13403_136706)">
    <path d="M52.0182 107.036C58.2777 116.269 77.0279 122.769 79.5274 122.041L90.0309 124.041L104.536 112.538C130.718 87.1206 134.803 14.7472 87.1501 14.7471C48.027 14.747 45.0521 36.7807 41.6353 49.2586C32.8368 81.39 44.1939 95.4937 52.0182 107.036Z" fill="#D7E0E4"/>
    <path d="M95.6524 99.5385L94.9155 142.352C94.7862 149.859 91.1535 156.499 85.8853 158.583C81.8243 160.188 77.7928 160.084 73.7907 158.271C68.5973 155.917 65.1978 149.095 65.327 141.588L66.064 98.7744L95.6524 99.5385Z" fill="#EFF5F8"/>
    <path opacity="0.2" d="M95.6516 117.24L95.6516 112.27L65.6416 110.779L65.6416 116.246C67.423 118.068 72.7947 122.905 80.03 123.7C87.2653 124.495 93.4591 119.393 95.6516 117.24Z" fill="#A8B8C2"/>
    <path d="M61.7728 38.7559L61.7812 38.823C62.0863 41.31 64.4493 53.7328 81.2039 53.4958C99.8201 53.2324 121.403 71.9082 118.166 83.4954C114.928 95.0824 116.277 99.0326 101.168 112.2C88.1746 123.524 81.766 122.967 75.5928 121.081L75.1625 120.948C75.0908 120.925 75.0191 120.902 74.9474 120.879L74.5173 120.741C74.3021 120.671 74.0868 120.6 73.8711 120.529L73.439 120.386C73.3668 120.362 73.2946 120.338 73.2223 120.315L72.7876 120.172C72.715 120.148 72.6422 120.124 72.5694 120.1C65.2848 117.73 43.1611 100.876 43.9707 82.7053C43.9707 82.7053 38.1489 64.0901 50.8296 58.0332C62.2421 52.582 61.9486 41.0344 61.7758 38.7794L61.7728 38.7559Z" fill="#EFF5F8"/>
    <path d="M72.1336 1.69953C50.6233 6.81665 59.5854 19.0627 66.7552 24.5462C82.5735 23.1795 94.02 28.8436 97.7659 31.8465C99.4858 30.6885 103.564 26.5133 106.116 19.0766C109.306 9.78065 99.0215 -4.69687 72.1336 1.69953Z" fill="#D7E0E4"/>
    <path d="M92.9142 21.3996L103.278 23.8312C103.278 23.8312 103.488 53.7353 66.9342 61.4085C66.9342 61.4085 91.5644 44.8455 92.9142 21.3996Z" fill="#D7E0E4"/>
    <mask id="mask1_13403_136706" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="50" y="74" width="21" height="7">
      <path d="M70.6656 77.2506C70.6656 77.2506 63.2254 74.8303 58.0745 76.3663C52.9234 77.9026 51.7311 80.5095 51.7311 80.5095L50.0142 76.087L52.0648 77.8094L50.9203 74.7369L53.1141 76.6456C53.1141 76.6456 60.4591 70.361 70.6656 77.2506Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_13403_136706)">
      <path d="M50.0142 74.0234H70.6656V80.5095H50.0142V74.0234Z" fill="#A8B8C2"/>
    </g>
    <mask id="mask2_13403_136706" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="90" y="74" width="21" height="7">
      <path d="M90.1724 77.2506C100.379 70.361 107.724 76.6456 107.724 76.6456L109.918 74.7369L108.773 77.8094L110.824 76.087L109.107 80.5095C109.107 80.5095 107.915 77.9026 102.764 76.3663C97.6126 74.8303 90.1724 77.2506 90.1724 77.2506Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_13403_136706)">
      <path d="M90.1724 74.0234H110.824V80.5095H90.1724V74.0234Z" fill="#A8B8C2"/>
    </g>
    <mask id="mask3_13403_136706" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="95" y="95" width="12" height="4">
      <path d="M100.532 95.0303C103.571 95.0303 106.034 95.7021 106.034 96.5308C106.034 97.3595 103.571 98.0313 100.532 98.0313C99.073 98.0313 97.6736 97.8732 96.6418 97.5918C95.6099 97.3104 95.0303 96.9287 95.0303 96.5308C95.0303 96.1328 95.61 95.7511 96.6418 95.4698C97.6736 95.1884 99.073 95.0303 100.532 95.0303Z" fill="white"/>
    </mask>
    <g mask="url(#mask3_13403_136706)">
      <path opacity="0.3" d="M95.0303 95.0303H106.034V98.0313H95.0303V95.0303Z" fill="#D7E0E4"/>
    </g>
    <mask id="mask4_13403_136706" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="57" y="95" width="12" height="4">
      <path d="M62.5239 95.0303C65.5625 95.0303 68.0256 95.7021 68.0256 96.5308C68.0256 97.3595 65.5625 98.0313 62.5239 98.0313C61.0647 98.0313 59.6653 97.8732 58.6335 97.5918C57.6016 97.3104 57.022 96.9287 57.022 96.5308C57.022 96.1328 57.6017 95.7511 58.6335 95.4698C59.6653 95.1884 61.0647 95.0303 62.5239 95.0303Z" fill="white"/>
    </mask>
    <g mask="url(#mask4_13403_136706)">
      <path opacity="0.3" d="M57.022 95.0303H68.0256V98.0313H57.022V95.0303Z" fill="#D7E0E4"/>
    </g>
    <mask id="mask5_13403_136706" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="94" y="77" width="8" height="9">
      <path d="M98.3318 77.0645C100.349 77.0645 101.985 78.8845 101.985 81.1296C101.985 83.3747 100.349 85.1947 98.3318 85.1947C96.3143 85.1947 94.6787 83.3747 94.6787 81.1296C94.6787 78.8845 96.3143 77.0645 98.3318 77.0645Z" fill="white"/>
    </mask>
    <g mask="url(#mask5_13403_136706)">
      <path d="M94.6787 77.0645H101.985V85.1947H94.6787V77.0645Z" fill="#A8B8C2"/>
    </g>
    <path d="M98.3309 85.1957C100.348 85.1957 101.984 83.3757 101.984 81.1306C101.984 78.8855 100.348 77.0654 98.3309 77.0654C96.3133 77.0654 94.6777 78.8855 94.6777 81.1306C94.6777 83.3757 96.3133 85.1957 98.3309 85.1957Z" stroke="#A8B8C2" stroke-width="0.674485" stroke-linecap="round" stroke-linejoin="round"/>
    <mask id="mask6_13403_136706" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="57" y="77" width="8" height="9">
      <path d="M60.7552 77.0645C62.7727 77.0645 64.4083 78.8845 64.4083 81.1296C64.4083 83.3747 62.7727 85.1947 60.7552 85.1947C58.7376 85.1947 57.1021 83.3747 57.1021 81.1296C57.1021 78.8845 58.7376 77.0645 60.7552 77.0645Z" fill="white"/>
    </mask>
    <g mask="url(#mask6_13403_136706)">
      <path d="M57.1021 77.0645H64.4083V85.1947H57.1021V77.0645Z" fill="#A8B8C2"/>
    </g>
    <path d="M60.7566 85.1967C62.7742 85.1967 64.4098 83.3766 64.4098 81.1315C64.4098 78.8864 62.7742 77.0664 60.7566 77.0664C58.7391 77.0664 57.1035 78.8864 57.1035 81.1315C57.1035 83.3766 58.7391 85.1967 60.7566 85.1967Z" stroke="#A8B8C2" stroke-width="0.674485" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M65.1454 128.567C53.0014 130.429 38.7978 139.377 33.0001 146.142L52.6344 168.243L46.0776 178.75L68.1203 216.806C68.1203 216.806 63.5179 161.731 65.1454 128.567Z" fill="#D7E0E4"/>
    <path d="M99.767 128.567C111.48 131.985 124.649 141.38 129.15 147.5L109.19 167.055L114.956 180.076L97.2467 220.162C97.2467 220.162 95.8336 180.835 99.767 128.567Z" fill="#D7E0E4"/>
    <path d="M66.1015 152.606L50.196 162.167C48.1665 142.175 53.2174 127.116 63.9231 118.039C63.9231 118.039 65.7373 141.762 73.2337 148.319L75.6467 183.859C72.2141 158.377 67.1502 159.643 67.1502 159.643L66.1015 152.606Z" fill="white"/>
    <path d="M93.9708 152.605L109.876 162.166C111.906 142.175 107.494 128.572 96.788 119.495C96.788 119.495 94.3349 141.761 86.8385 148.318L83.9935 183.859C87.4261 158.376 92.9221 159.642 92.9221 159.642L93.9708 152.605Z" fill="white"/>
    <g id="jen-zen-border">
      <path d="M83.5 1.42263C128.866 1.42263 165.577 37.2216 165.577 81.3026C165.577 125.384 128.866 161.183 83.5 161.183C38.1339 161.183 1.42263 125.384 1.42263 81.3026C1.42263 37.2216 38.1339 1.42263 83.5 1.42263Z" stroke="#D7E0E4" stroke-width="2.84526"/>
    </g>
  </g>
  <g id="jen-zen-border">
    <path d="M83.5 0.5C129.352 0.5 166.5 36.6891 166.5 81.3026C166.5 125.916 129.352 162.105 83.5 162.105C37.6477 162.105 0.5 125.916 0.5 81.3026C0.5 36.6891 37.6477 0.5 83.5 0.5Z" stroke="#D7E0E4"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M99.652 34.5852C99.652 34.5852 80.6973 73.0926 40.6323 73.767L47.3766 44.3458L70.8842 25.751L88.8311 27.0501L99.652 34.5852Z" fill="#D7E0E4"/>
  <path d="M82.8525 48.1026C82.8525 48.1026 107.202 77.6233 120.127 69.594C120.127 69.594 111.488 43.5271 100.335 39.043L82.8525 48.1026Z" fill="#D7E0E4"/>
  <path d="M101.274 69.0855C108.717 69.1114 116.011 70.2223 117.789 71.0318C117.789 71.0318 118.194 72.6698 118.289 75.1129L121.002 72.6726V74.4841L118.259 77.6174C118.097 80.3426 117.455 83.5582 115.703 86.5671C111.533 93.7299 105.844 95.1115 98.2604 94.2485C90.6766 93.3855 87.7391 87.6027 86.5065 84.841C86.2061 84.1679 85.9419 83.4273 85.6711 82.6817L85.5075 82.2342C84.6273 79.8498 83.593 77.5417 81.0079 77.3557C77.4532 77.6122 75.9925 81.272 75.3201 84.4097C74.5619 87.9483 71.3393 93.3853 63.7555 94.2485C56.1718 95.1116 50.4832 93.7304 46.3124 86.5671C44.5604 83.5582 43.9195 80.3426 43.7565 77.6174L41.0137 74.4841V72.6726L43.7273 75.1129C43.8214 72.6698 44.2269 71.0318 44.2269 71.0318C46.0044 70.2227 53.2989 69.1025 60.7421 69.0855C65.2082 69.07 69.7274 69.4472 73.1401 70.5153C75.3197 71.1974 76.9262 71.6488 78.1026 71.946L78.479 72.0394C80.0427 72.4204 80.7421 72.4973 81.0082 72.5003C81.4784 72.4927 83.3065 72.2586 88.8763 70.5153C92.2889 69.447 96.8081 69.0685 101.274 69.0855ZM59.6321 72.4322C57.2859 72.4243 54.6546 72.6304 51.8107 73.1906C42.0466 75.0893 49.2363 91.3839 58.7301 91.4011C68.2217 91.4349 73.2352 85.5323 71.1498 74.3986C71.1498 74.3986 66.6705 72.4493 59.6321 72.4322ZM102.005 72.4322C99.6591 72.4243 97.0293 72.6304 94.1854 73.1906C84.4213 75.0893 91.611 91.3839 101.105 91.4011C110.596 91.4349 115.608 85.5323 113.523 74.3986C113.523 74.3986 109.044 72.4493 102.005 72.4322Z" fill="#54E2C0"/>
  <path d="M80.9989 108.73C75.2951 109.028 70.3044 105.872 68.522 104.256C71.0471 104.877 77.2558 106.12 81.8901 106.12C86.5243 106.12 91.2477 105.126 93.0302 104.629C91.3963 105.872 86.7026 108.432 80.9989 108.73Z" fill="white"/>
  <path d="M80.5533 110.221C74.4931 110.221 70.0073 106.244 68.522 104.256C70.75 105.499 74.3148 107.922 80.5533 108.357C85.9005 108.73 90.6536 105.996 93.0302 104.629C91.3963 106.493 86.6135 110.221 80.5533 110.221Z" fill="#A8B8C2"/>
  <path d="M80.9989 113.204C85.6331 113.503 91.0992 107.487 93.0302 104.629C90.9507 105.996 86.3461 109.849 80.9989 109.849C74.7604 109.849 70.8985 106.244 68.522 104.256C71.1956 107.612 75.206 112.831 80.9989 113.204Z" fill="#D7E0E4"/>
  <path d="M80.5533 106.494C86.257 106.792 91.0992 105.5 93.0302 104.63C91.8419 105.031 89.198 105.294 86.3461 104.63C82.7813 103.799 80.5533 103.138 76.0972 104.257C72.5324 105.152 69.7102 104.63 68.522 104.257C70.3044 104.878 74.8495 106.196 80.5533 106.494Z" fill="#D7E0E4"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M47.1759 45.3617C47.1759 45.3617 37.7452 108.308 56.6659 132.044C56.6659 132.044 38.1962 113.993 39.7383 57.4356" fill="#D7E0E4"/>
  <path d="M57.5193 48.0154C72.3221 30.4677 92.7423 33.5524 99.6545 35.8021C98.3784 35.5282 92.1893 36.4947 77.6417 42.5514C63.0942 48.6081 48.3977 72.9713 42.8679 84.3958L40.6348 87.7704C41.0601 82.821 42.7165 65.5631 57.5193 48.0154Z" fill="#EFF5F8"/>
  <path d="M48.6372 36.7534C59.8174 22.6954 77.73 26.2505 86.6504 29.5646C85.2628 29.5646 80.4657 29.7959 72.3779 30.7208C62.2681 31.877 47.9955 42.2816 45.914 55.2298C44.2488 65.5883 42.2466 89.2186 42.3458 97.7737C38.8768 83.4382 37.457 50.8114 48.6372 36.7534Z" fill="#EFF5F8"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M115.612 48.0114C115.612 48.0114 122.115 111.263 102.11 134.253C102.11 134.253 121.242 119.146 122.325 62.579" fill="#D7E0E4"/>
  <path d="M121.628 62.0615C119.447 45.6675 108.54 38.0886 103.36 36.3484C104.26 36.7498 107.71 39.9962 114.313 49.7707C120.916 59.5451 120.315 79.7529 119.19 88.635L119.229 91.5202C120.938 88.5314 123.81 78.4555 121.628 62.0615Z" fill="#EFF5F8"/>
  <rect x="98.0327" y="79.0264" width="3.001" height="3.001" rx="1.5005" fill="white"/>
  <rect x="60.02" y="79.0264" width="3.001" height="3.001" rx="1.5005" fill="white"/>
  <path d="M103.034 18.5067C83.0275 14.5053 69.3563 16.8394 65.0215 18.5067C66.0218 16.8394 71.0235 13.5049 83.0275 13.5049C95.0315 13.5049 101.367 16.8394 103.034 18.5067Z" fill="white"/>
</svg>
