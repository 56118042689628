import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient, HttpParams} from '@angular/common/http';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {Observable, of} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {getParams} from '../../_zen-legacy-common/_utils/set-param-util';
import {
  EnergyPlanMeterWithoutUsage,
  NearestEnergyPlanModel,
  ZenContractDetailsEligibleMeter,
  ZenContractDetailsListMeter,
  ZenContractV4ListModel,
  ZenProductV4GetModel
} from '../../_model/contract-v4.model';
import {RateType} from '../../_zen-legacy-common/_models/energyplan-v2';
import {Document} from '../../_zen-legacy-common/_models/app-rate-check-v2';
import {PageableModel} from '../../../_modules/portfolio/_model/pageable.model';
import {
  ContractDetailMeterFilters,
  EnergyPlanEligibleMeterFilters
} from '../../../_modules/portfolio/_model/portfolio-initial-filter.model';
import {UndefinedMetersToUtilityDto} from '../../_model/meter-energy-plan.model';
import {MeterV4Model} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ContractsV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  /**
   * This service used for both Organization level and hierarchy level(LEN, ServiceAddress, Meter) contract list fetching.
   * @param filters -> Used for hierarchy level contract fetching and table select filter.
   * Which is an string response of PortfolioFilterRequest.
   */
  getContracts(filters: string, page: number, size: number, sortBy?: string, sortDir?: string, draftOnly?: boolean): Observable<PageableModel<ZenContractV4ListModel>> {
    let httpParams = getParams({
      page,
      size,
      draftOnly,
      ...((sortBy && sortDir) ? { sort: `${sortBy},${sortDir}` } : {})
    });
    const url =
      `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/energy-plans?filters=${encodeURIComponent(filters)}`;
    return this.http.get<PageableModel<ZenContractV4ListModel>>(url, {params: httpParams}).pipe(take(1));
  }

  /**
   * This API is used in the "Add procurement snapshot" UI dialog, from the meter details page.
   */
  getCustomerMeterContracts(customerId: number, serviceAddressId: number, meterId: number, addEnergyPlanIds: number[],
                            removeEnergyPlanIds: number[], page?: number, size?: number): Observable<PageableModel<ZenContractV4ListModel>> {
    let httpParams = getParams({addEnergyPlanIds, removeEnergyPlanIds, page, size, sort: ['updatedAt,desc']});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/eligible-energy-plans`;
    return this.http.get<PageableModel<ZenContractV4ListModel>>(url, {params: httpParams}).pipe(take(1));
  }

  getContractById(customerId: number, energyPlanId: number): Observable<ZenContractV4ListModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/energy-plans/${energyPlanId}`;
    return this.http.get<ZenContractV4ListModel>(url).pipe(take(1));
  }

  getContractWithDetailsById(customerId: number, energyPlanId: number): Observable<ZenContractV4ListModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/energy-plan-details/${energyPlanId}`;
    return this.http.get<ZenContractV4ListModel>(url).pipe(take(1));
  }


  deleteContractById(customerId: number, energyPlanId: number): Observable<void> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/energy-plans/${energyPlanId}`;
    return this.http.delete<void>(url).pipe(take(1));
  }

  getProductModels(commodityType: CommodityType, rateType: RateType, state: string): Observable<ZenProductV4GetModel[]> {
    let httpParams = getParams({commodityType, rateType, state});
    const url = `${this.baseUrl}/v4/product-models`;
    return this.http.get<ZenProductV4GetModel[]>(url, {params: httpParams}).pipe(take(1));
  }

  createContracts(customerId: number, _data, meterId?: number, serviceAddressId?: number): Observable<ZenContractV4ListModel> {
    // If we pass in meterId and meterId, call the API with meter validations
    if (meterId && serviceAddressId) {
      return this.createContractsForMeter(customerId, _data, meterId, serviceAddressId);
    }

    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/energy-plans`;
    return this.http.post<ZenContractV4ListModel>(url, _data).pipe(take(1));
  }


  createContractsForMeter(customerId: number, _data, meterId: number, serviceAddressId: number): Observable<ZenContractV4ListModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/energy-plans`;
    return this.http.post<ZenContractV4ListModel>(url, _data).pipe(take(1));
  }

  createContract(customerId: number, _data): Observable<PageableModel<ZenContractV4ListModel>> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/energy-plans`;
    return this.http.post<PageableModel<ZenContractV4ListModel>>(url, _data).pipe(take(1));
  }

  updateContract(customerId: number, energyPlanId: number,  _data): Observable<ZenContractV4ListModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/energy-plans/${energyPlanId}`;
    return this.http.put<ZenContractV4ListModel>(url, _data).pipe(take(1));
  }

  /**
   * Can add/remove energy plans from meters, or can add/remove meters from energy plans.
   */
  updateProcurementSnapshotContract(customerId: number, serviceAddressId: number, meterId: number, addEnergyPlanIds: number[],
                                    removeEnergyPlanIds: number[], assignToUtility = false): Observable<void> {
    let httpParams = getParams({addEnergyPlanIds, removeEnergyPlanIds, assignToUtility});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/energy-plans`;
    return this.http.put<void>(url, null, {params: httpParams}).pipe(take(1));
  }

  /**
   * To bulk change undefined meters to On Utility.
   */
  addUndefinedMetersToUtility(customerId: number, request: UndefinedMetersToUtilityDto): Observable<MeterV4Model[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/undefined-meters-to-utility`;
    return this.http.post<MeterV4Model[]>(url, request).pipe(take(1));
  }

  /**
   * Returns the (active or inactive) meters tied the given contract
   */
  getMetersInContract(customerId: number, contractId: number, active: boolean, page: number,
                       size: number, sortBy?: string, sortDir?: string, contractDetailMeterFilters?: string): Observable<PageableModel<ZenContractDetailsListMeter>> {
    let httpParams = getParams({
      active,
      page,
      size,
      meterFilters: contractDetailMeterFilters,
      ...((sortBy && sortDir) ? { sort: `${sortBy},${sortDir}` } : {})
    });
    // Delete empty filters obj
    if (contractDetailMeterFilters == null || contractDetailMeterFilters == '{}') {
      httpParams = httpParams.delete('meterFilters');
    }

    return this.http.get<PageableModel<ZenContractDetailsListMeter>>(
        `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/energy-plans/${contractId}/meters`, {params: httpParams}).pipe(take(1));
  }

  /**
   * Returns the list of eligible meters that could be added to this contract
   */
  getEligibleMetersForContract(customerId: number, contractId: number, contractState: string, contractCommodity: CommodityType,
                               contractStart: Date, contractEnd: Date,
                               page: number, size: number, sortBy?: string, sortDir?: string, textFilter?: EnergyPlanEligibleMeterFilters): Observable<PageableModel<ZenContractDetailsEligibleMeter>> {
    let httpParams = getParams({contractState, contractCommodity, contractStart, contractEnd, page, size, textFilter: JSON.stringify(textFilter), sort: (sortBy && sortDir) ? `${sortBy},${sortDir}` : undefined});

    if (textFilter == null) {
     httpParams = httpParams.delete('textFilter');
    }
    return this.http.get<PageableModel<ZenContractDetailsEligibleMeter>>(`${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/energy-plans/${contractId}/eligible-meters`, {params: httpParams}).pipe(take(1));
  }

  linkMetersToContract(customerId: number, contractId: number, metersSelected: number[]): Observable<number> {
    return this.http.put<number>(`${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/energy-plans/${contractId}/meters`, {meterIds: metersSelected}).pipe(take(1));
  }


  /** Contract plan finder API used in Add bill flow. Using start dt and end dt it able to find
   *  nearest energy plans for a meter. */
  getContractPlansForMeter(customerId: number, serviceAddressId: number, meterId: number, billStartDate: string, billEndDate: string): Observable<NearestEnergyPlanModel> {
    let httpParams = getParams({billStartDate, billEndDate});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/service-addresses/${serviceAddressId}/meters/${meterId}/plan-finder`;
    return this.http.get<NearestEnergyPlanModel>(url, {params: httpParams}).pipe(take(1));
  }

  getEnergyPlanMetersWithoutUsage(customerId: number, energyPlanId: number) {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/energy-plans/${energyPlanId}/meters-without-usage`;
    return this.http.get<EnergyPlanMeterWithoutUsage[]>(url).pipe(take(1));
  }
}
