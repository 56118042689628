<div class="pf-image-uploader" [ngClass]="type">
  <div class="image-container">
    <img class="selected-img" *ngIf="imageUrl && !loading" [src]="imageUrl"/>
    <mat-spinner class="selected-img" *ngIf="loading" [diameter]="50" color="primary"></mat-spinner>
  </div>
  <input hidden type="file" [formControl]="inputCtrl" onclick="this.value=null;" (change)="onFileChange($event)" #uploadFileInput [accept]="'image/png'"/>
  <button type="button" mat-stroked-button class="py-1 select-btn" (click)="uploadFileInput.click()">
    Upload File
    <span class="material-icons-outlined ms-3">file_upload</span>
  </button>
</div>
