import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  ButtonActionTypes,
  ButtonTypes,
  ZenDialogActionButton,
  ZenDialogDataModel
} from '../zen-dialog/zen-dialog.component';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {ZenUnitsHelperService} from '../../_services/helpers/zen-units-helper.service';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ContractsV4Service} from '../../_services/v4/contracts-v4.service';
import {ContractGreenType, ZenContractV4FormModel, ZenContractV4ListModel} from '../../_model/contract-v4.model';
import {ZenBaseComponent} from '../../_components/zen-base/zen-base.component';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {ContractFriendlyStatus} from '../../_zen-legacy-common/_utils/contract-utils';

@Component({
  selector: 'app-rc-req-on-utility-contract-edit-dialog',
  templateUrl: './rc-req-on-utility-contract-edit-dialog.component.html',
  styleUrls: ['./rc-req-on-utility-contract-edit-dialog.component.scss']
})
export class RcReqOnUtilityContractEditDialogComponent extends ZenBaseComponent implements OnInit {
  form: UntypedFormGroup;
  loading: boolean;

  appearance: MatFormFieldAppearance = 'outline';
  rateValidationMsg: string;

  unit = new UntypedFormControl();
  selectedCommodity: CommodityType;

  contractDet: ZenContractV4ListModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<RcReqOnUtilityContractEditDialogComponent>,
              private zenUnitsSvc: ZenUnitsHelperService,
              private zenDialogSvc: ZenDialogMsgService,
              private contractsV4Svc: ContractsV4Service,
              private fb: UntypedFormBuilder) {
    super();
    this.contractDet = this.data.data.contractDet;
    this.selectedCommodity = this.contractDet.commodityType;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      supplierRate: new UntypedFormControl(null, Validators.required)
    });

    this.form.patchValue({supplierRate: JSON.stringify(this.contractDet.supplierRate)});



    this.data.actionButtons = [
      {label: 'Cancel', btnType: ButtonTypes.MAT_BUTTON, actionType: ButtonActionTypes.CANCEL},
      {label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'accent'}
    ];

    // Update min and max for contract rate validations.
    this.unit.valueChanges.subscribe(_unit => {
      const {min, max} = this.zenUnitsSvc.getMinAndMaxValidationValue(this.selectedCommodity, _unit);
      this.form.controls.supplierRate.setValidators([Validators.required, Validators.min(min), Validators.max(max)]);
      this.form.controls.supplierRate.updateValueAndValidity();
      this.rateValidationMsg = this.zenUnitsSvc.getRateFieldValidationMsg(this.selectedCommodity, _unit);
    });

    this.setUnitOptions();
  }

  setUnitOptions() {
    if (this.selectedCommodity === CommodityType.Electricity) {
      this.unit.setValue(ZenUnitsEnum.kWh);
    } else {
      this.unit.setValue(ZenUnitsEnum.Dth);
    }
  }

  submit(btn: ZenDialogActionButton) {
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      this.dialogRef.close(false);
    } else {
      this.form.markAllAsTouched(); // to trigger mat-error
      if (this.form.valid) {

        const _isRenewable = this.contractDet.productModelConfiguration.renewable;
        const _formData = {
          customerId: this.contractDet.customerId,
          termMonths: this.contractDet.termMonths,
          greenPercent: 0,
          rateType: this.contractDet.rateType,
          state: this.contractDet.state,
          commodityType: this.contractDet.commodityType,
          planEndDate: this.contractDet.planEndDate,
          greenType: _isRenewable ? ContractGreenType.hybrid : null,
          utilityIds: this.contractDet.utilityIds || [],
          configuration: this.contractDet.productModelConfiguration,
          supplierRate: this.zenUnitsSvc.convertRateToBaseUnitValue(this.unit.value, parseFloat(this.form.value.supplierRate))
        } as Partial<ZenContractV4FormModel>;

        this.loading = true;
        this.contractsV4Svc.updateContract(this.contractDet.customerId, this.contractDet.energyPlanId, _formData)
          .subscribe({
            next: updatedContract => {

              // Map this manually in the UI for now.
              if (updatedContract.contractStatus === ContractFriendlyStatus.Default_Service) {
                updatedContract.contractStatus = ContractFriendlyStatus.On_Utility;
              }

              this.dialogRef.close(updatedContract);
            }, error: err => {
              this.loading = false;
              this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
            }
          });
      }
    }
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }
}
