<!-- Adding this app-layout component to support base font-size(62.5% -> 1px = 0.1rem) -->
<app-layout></app-layout>
<div class="p-4">

  <div class="mb-5">
    <h3 class="mb-3">1. Basic Button</h3>
    <button mat-raised-button color="accent">
      <span class="label">CTA Button</span>
    </button>
  </div>

  <div class="mb-5">
    <h3 class="mb-3">2. Button With Icon </h3>
    <button mat-raised-button color="accent">
      <i class="material-icons large me-3">add</i>
      <span class="label">CTA Button</span>
    </button>
  </div>

  <div class="mb-5">
    <h3 class="mb-3">3. Secondary Button / Cancel Button </h3>
    <button mat-button>Cancel</button>
  </div>

  <div class="mb-5">
    <h3 class="mb-3">4. Filter Button </h3>
    <button class="filter-button">
      <i class="material-icons">add</i>
      <span class="title">Filter</span>
    </button>
  </div>

  <div class="mb-5">
    <h3 class="mb-3">5. “X” button </h3>
    <i class="material-symbols-rounded close-icon text-color-dark">close</i>
  </div>

  <div class="mb-5">
    <h3 class="mb-3">6. Button Set. </h3>
    <div class="d-flex align-items-center">
      <button mat-button>Cancel</button>
      <button mat-raised-button color="accent" class="ms-3">
        <span class="label">CTA Button</span>
      </button>
    </div>
  </div>

  <div class="mb-5">
    <h3 class="mb-3">7. Disabled Button </h3>
    <button mat-raised-button color="accent" disabled>
      <span class="label">CTA Button</span>
    </button>
  </div>

  <div class="mb-5">
    <h3 class="mb-3">8. Loading state </h3>
    <button mat-raised-button color="accent" class="loading">
      <span class="label">CTA Button</span>
      <mat-spinner diameter="25" [strokeWidth]="2" color="accent"></mat-spinner>
    </button>
  </div>
</div>
