import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})

export class TextHighlightPipe implements PipeTransform {

  transform(value: string, args: string): string {
    if (args && value) {
      let re = new RegExp(args, 'gi');
      return value.replace(re, '<mark>$&</mark>');
    } else {
      return value;
    }
  }
}
