import {RateCheckStatusEnum} from '../_zen-legacy-common/_models/rate-checks/rate-check-status';
import {RateType} from '../_zen-legacy-common/_models/energyplan-v2';
import {GreenType} from '../_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {CommodityType} from '../_zen-legacy-common/_models/commodity';
import {ZenRcStatusClass} from '../../_modules/zen-rate-checks/_enum/zen-rc-v4.enum';
import {ContractFriendlyStatus} from '../_zen-legacy-common/_utils/contract-utils';
import {ContractBillingMethod, ContractConfiguration, ContractSource} from '../_enums/zen-contract.enum';
import {ZenIconsEnum} from '../_enums/zen-icons.enum';
import {EnergyPlanTimeline} from '../../_modules/portfolio/_model/portfolio-meters.model';
import {ContractDocumentDetails} from '../../_modules/zen-rate-checks/_model/contracts/nat-gas-contracts.model';
import {MeterType} from '../_zen-legacy-common/_enums/entity-types.enum';
import {ZenNatGasPaymentTermEnum} from '../../_modules/zen-rate-check-request-v2/_enums/rc-req-natural-gas.enum';
import {MloaDocumentDetailsDTO} from '../../_modules/portfolio/_model/portfolio-customers.model';

export interface ZenContractV4ListModel {
  activeMeterCount: number;
  energyPlanId: number;
  customerId: number;
  state: string;
  utilityIds: number[];
  utilityNames: string[];
  supplierRate: number;
  termMonths: number;
  supplierId: string;
  supplierName: string;
  rateType: RateType;
  planStartDate: Date;
  planEndDate: Date;
  rateCheckId: string;
  rateCheckStatusCode: RateCheckStatusEnum;
  isMatrixPricing: boolean;
  pricingSolutionId: string;
  pricingSolutionRate: number;
  quoteId: string;
  greenName: string;
  greenType: GreenType;
  greenPercent: number;
  documents: ContractDocumentDetails[];
  documentId: number;
  deletable: boolean;
  commodityType: CommodityType;
  productModelConfiguration: ProductModelConfiguration;
  deleted: boolean;
  lastUpdatedBy: string;
  notes: string;
  contractStatus: ContractFriendlyStatus;
  updatedAt: Date;
  annualUsage: number;
  arr: number;
  bookedOnOurPlatform: boolean;
  meterCount: number;
  brokerFee: number;
  totalFee: number;
  customerName: string;
  meterIsOnEP?: boolean;
  staggeredStart?: boolean;
  lens: LenEpName[];
  canBeRebooked: boolean;
  mloaDocumentDetails: MloaDocumentDetailsDTO; // Only used for Draft Contracts currently
}

export interface LenEpName {
  energyPlanId: number;
  id: string;
  name: string;
}

export interface ZenContractTblRowModel extends ZenContractV4ListModel {
  statusCls?: ZenRcStatusClass;
  contractStrategy?: string; // Fixed @ $0.0789 / kWh
  draftContractStrategy?: string; //  Supplier Name - <br> Fixed @ $0.0789 / kWh
  // Authorization Details
  authorizationStatus: string;
  authorizationCls?: string;
  authorizationSubText: string;
  contractStrategyProcurement?: string; // Fixed @$0.0655 (36M) -> Used in add procurement snapshot dialog.
  rateTypeText?: string; // Fixed
  contractConfiguration?: ContractConfiguration;
  contractStrategyIcon?: ZenIconsEnum;
  contractStrategyCls?: string;
  bookedInOurSystemIcon: ZenIconsEnum;
  bookedInOurSystemCls: string;
  lockedContract?: boolean;
  primaryLen?: string;
  lenNames?: string[]; // This is mapped from lens: LenEpName[]
}

export interface ZenContractV4FormModel {
  state: string;
  supplierId: string;
  supplierRate: number;
  planEndDate: Date;
  termMonths: number;
  commodityType: CommodityType;
  rateType: RateType;
  source: ContractSource;
  renewablePercentage: number;
  billingMethod: ContractBillingMethod;
  tempFileId?: string;
  customerId: number;
  productModelConfiguration: {
    renewable: boolean;
    configuration: ProductModelCommodityConfig;
    productModelId: number;
  };
}

export interface ProductModelConfiguration {
  id: number;
  renewable: boolean;
  configuration: ProductModelCommodityConfig;
  productModel: ZenProductV4GetModel;
}

export interface BaseProductModelV2 {
  paymentTerms: ZenNatGasPaymentTermEnum;
  billingMethod: ContractBillingMethod;
  requirements: string;
}

export interface ProductModelCommodityConfig extends BaseProductModelV2 {
  // Electric configs
  source: ContractSource;
  // Note, craig said this is the "Opposite" of how the old Enum works, keep that in mind for any future migrations.
  capacityPassthrough: boolean;
  transmissionPassthrough: boolean;

  // Gas configs
  swing: number; // 0-100
  basisFixed: boolean;
  basisPricingLocation: BasisPricingLocation;
  carbonOffsetPricing: boolean;
  commodityFixed: boolean;  // Sometimes known as the "NYMEX fixed"
}


export interface ZenProductV4GetModel {
  commodityType: CommodityType;
  config;
  id: number;
  name: string;
  rateType: RateType;
}

export enum ContractGreenType {
  solar = 'solar',
  wind = 'wind',
  hydro = 'hydro',
  geothermal = 'geothermal',
  hybrid = 'hybrid',
  other = 'other'
}

export interface ZenProcurementSnapshotTblRowModel {
  energyPlanId: number;
  rateCheckId: string;
  contractStatus: ContractFriendlyStatus;
  supplierName: string;
  statusCls: ZenRcStatusClass;
  termMonthsText: string;
  contractStrategy: string; // Fixed @ $0.0789 / kWh
  contractStrategyProcurement: string; // Fixed @$0.0655 (36M) -> Used in add procurement snapshot dialog.
  rateTypeText: string; // Fixed
  contractConfiguration: ContractConfiguration;
  contractStrategyIcon: ZenIconsEnum;
  contractStrategyCls: string;
  bookedInOurSystemIcon: ZenIconsEnum;
  bookedInOurSystemCls: string;
  planStartDate: Date;
  planEndDate: Date;
  supplierRate: number;
  // Linked contracts -> Lock condition
  lockedContract: boolean;
  startDateText: string; // To support client side search
  endDateText: string; // To support client side search
}

/**
 * Used on contract details page meter list
 */
export interface ZenContractDetailsListMeter {
  customerId: number;
  serviceAddressId: number;
  lenId: string;
  meterId: number;
  meterName: number;
  uan: string;
  lenName: string;
  serviceAddressName: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  utility: string;
  meterType: MeterType;
  usage: number;
  startDate: Date;
  endDate: Date;
  // Only to support Ui
  serviceAddressSubText?: string;
}

/**
 * Used in contract details meter list dialog (linking) dialog
 */
export interface ZenContractDetailsEligibleMeter {
  customerId: number;
  serviceAddressId: number;
  lenId: string;
  meterId: number;
  uan: string;
  lenName: string;
  serviceAddressName: string;
  serviceAddress: string;
  utility: string;
  usage: number;
  meterType: MeterType;
  energyPlanTimelines: EnergyPlanTimeline[];
}

export enum ProviderType {
  UTILITY = 'utility', SUPPLIER = 'supplier', UNKNOWN = 'unknown', DRAFT = 'draft'
}

/** Used for meter's contract plan-finder API */
export interface NearestEnergyPlanModel {
  nearestPlanId: number;
  providerType: ProviderType;
  energyPlans: NearestRankedEnergyPlanModel[];
}

export interface NearestRankedEnergyPlanModel extends ZenContractV4ListModel {
  rank: number;
}

export interface NearestRankedEnergyPlanTblRowModel extends NearestRankedEnergyPlanModel {
  energyPlanId: number;
  rateCheckId: string;
  rankColor: string;
  contractStatus: ContractFriendlyStatus;
  supplierName: string;
  planStartDate: Date;
  planEndDate: Date;
  supplierRate: number;
  statusCls: ZenRcStatusClass;
  contractConfiguration: string;
  contractStrategyIcon: ZenIconsEnum;
  contractStrategyCls: string;
  bookedInOurSystemIcon: ZenIconsEnum;
  bookedInOurSystemCls: string;
  startDateText: string; // To support client side search
  endDateText: string; // To support client side search
  updatedAtText: string;
  rateTypeText: string;
  // Linked contracts -> Lock condition
  lockedContract: boolean;
}

export interface EnergyPlanMeterWithoutUsage {
  meterId: number;
  utilityAccountNumber1: string;
  utilityAccountNumber2: string;
}

export enum BasisPricingLocation {
  BURNER_TIP = 'burner tip',
  CITY_GATE = 'city gate'
}
