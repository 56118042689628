<app-zen-loader *ngIf="loading || onPrint"></app-zen-loader>
<div class="main-content-section text-start">
  <div [ngClass]="showTermsAndCond ? 'pt-3' : ''" class="header-sec mb-3">
    <h1>Terms and Conditions</h1>
    <div class="icon-sec">
      <p (click)="downloadPdf()" *ngIf="showTermsAndCond" class="text-sm font-weight-500 load"><span class="material-icons">vertical_align_bottom</span>&nbsp;Download
      </p>
      <p (click)="showTermsAndCond=false" *ngIf="showTermsAndCond" class="text-sm font-weight-500 back"><span
        class="material-icons">arrow_back</span>&nbsp;Back</p>
    </div>
  </div>
  <ng-container *ngIf="!showTermsAndCond">
    <p class="text-md">By accepting these Terms & Conditions I understand and agree to the following:</p>
    <ul>
      <li> {{companyName}} has my permission to manage my energy and service address account information through their Energy
        Procurement and Data Management Platform.
      </li>
      <li> This agreement does not authorize {{companyName}} to lock me into a supplier contract without my expressed
        consent.
      </li>
      <li> {{companyName}} is not a {{'nomenclature.supplier' | translate}} of electricity or natural gas.</li>
      <li> The rates secured by {{companyName}} represent the best available option given market timing and {{'nomenclature.supplier' | translate}}
        participation.
      </li>
      <li> I will adhere to the rules of engagement that govern the way I am to use this platform and the services
        provided.
      </li>
      <li>I have read and agree to the full <span (click)="toggleTermsAndCond()" class="blue-1-color pointer">Terms & Conditions</span>.
      </li>
    </ul>
  </ng-container>

  <div *ngIf="showTermsAndCond" class="terms-and-cond">
    <div *ngIf="myContent && myContent.changingThisBreaksApplicationSecurity"
         [innerHTML]="myContent.changingThisBreaksApplicationSecurity">
    </div>
  </div>

  <div [hidden]="!showTermsAndCond" class="terms-and-cond-onprint text-start" id="pdfTC">
  </div>
</div>
