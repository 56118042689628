import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ZenContractTblRowModel, ZenContractV4ListModel} from '../../_model/contract-v4.model';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ZenNumberFormat} from '../../_enums/zen-number-format.enum';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {RateCheckStatusEnum} from '../../_zen-legacy-common/_models/rate-checks/rate-check-status';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';
import {ZenCurrencyTypeEnum} from '../../_enums/zen-currency-type.enum';

@Component({
  selector: 'app-zen-contract-info-card',
  templateUrl: './zen-contract-info-card.component.html',
  styleUrls: ['./zen-contract-info-card.component.scss']
})
export class ZenContractInfoCardComponent implements OnInit {
  @Input() contract: ZenContractTblRowModel; // ZenContractV4ListModel modified into ZenContractV4ListModel
  @Input() selectedEpId: number; // used to highlight the card.
  @Output() onClick: EventEmitter<ZenContractTblRowModel> = new EventEmitter();

  ZenNumberFormat = ZenNumberFormat;
  CommodityType = CommodityType;
  ZenIcons = ZenIconsEnum;
  RateCheckStatusEnum = RateCheckStatusEnum;
  ZenUnitsEnum = ZenUnitsEnum;
  currencyType = ZenCurrencyTypeEnum.CURRENCY;

  constructor() { }

  ngOnInit(): void {
  }

}
