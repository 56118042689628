import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  ButtonActionTypes,
  ButtonTypes,
  ZenDialogActionButton,
  ZenDialogDataModel
} from '../../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {ZenTableConfig} from '../../../../_shared/_components/zen-mat-table/zen-mat-table.component';

@Component({
  selector: 'app-zen-bulk-import-error-dialog',
  templateUrl: './zen-bulk-import-error-dialog.component.html',
  styleUrls: ['./zen-bulk-import-error-dialog.component.scss']
})
export class ZenBulkImportErrorDialogComponent implements OnInit {
  errors: {message: string}[] = [];
  errorMsg = 'The meter upload failed. Please review the Error log below to determine how to improve your file prior to re-importing the data.';
  showErrorLog = true;
  tableConfig: ZenTableConfig;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenBulkImportErrorDialogComponent>) {
    this.errors = this.data?.data?.errors?.length ? this.data?.data?.errors?.map(e => {
      return {message: e};
    }) : [];

    this.tableConfig = {
      hideSearch: true, hideMultiselect: true, hidePagination: this.errors?.length <= 4,
      pageSize: 4,
      cols: [
        {field: 'message', header: 'Errors', type: 'standard', colStyleCls: 'text-column'}
      ]
    };

    this.data.actionButtons = [
      {label: 'Close', btnType: ButtonTypes.MAT_BUTTON, actionType: ButtonActionTypes.CANCEL, command: () => dialogRef.close()}
    ];
  }

  ngOnInit(): void {
  }

  submit(btn: ZenDialogActionButton) {
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      this.dialogRef.close(false);
    }
  }

}
