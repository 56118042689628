<svg width="130" height="126" viewBox="0 0 130 126" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M64.7027 0.5C100.174 0.5 128.905 28.4947 128.905 63C128.905 97.5053 100.174 125.5 64.7027 125.5C29.2319 125.5 0.5 97.5053 0.5 63C0.5 28.4947 29.2319 0.5 64.7027 0.5Z" fill="white" stroke="#54E2C0"/>
  <mask id="mask0_13413_137900" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="130" height="126">
    <path d="M64.7027 0.5C100.174 0.5 128.905 28.4947 128.905 63C128.905 97.5053 100.174 125.5 64.7027 125.5C29.2319 125.5 0.5 97.5053 0.5 63C0.5 28.4947 29.2319 0.5 64.7027 0.5Z" fill="white" stroke="#A8B8C2"/>
  </mask>
  <g mask="url(#mask0_13413_137900)">
    <path d="M39.6132 80.2062C44.6638 87.6564 59.7927 92.9005 61.8094 92.3132L70.2843 93.9275L81.9878 84.6454C103.113 64.1374 106.409 5.74177 67.9599 5.7417C36.3929 5.74164 33.9925 23.5199 31.2356 33.5878C24.1364 59.5136 33.3 70.8933 39.6132 80.2062Z" fill="#D7E0E4"/>
    <path d="M74.8197 74.1575L74.2251 108.702C74.1208 114.759 71.1897 120.117 66.9389 121.798C63.6623 123.094 60.4094 123.01 57.1802 121.546C52.9899 119.647 50.2469 114.143 50.3512 108.086L50.9458 73.541L74.8197 74.1575Z" fill="#EFF5F8"/>
    <path opacity="0.2" d="M74.82 88.4394L74.82 84.4296L50.606 83.2267L50.606 87.6375C52.0433 89.1077 56.3775 93.0106 62.2154 93.6522C68.0533 94.2938 73.0509 90.177 74.82 88.4394Z" fill="#A8B8C2"/>
    <path d="M47.4837 25.1128L47.4905 25.167C47.7366 27.1736 49.6433 37.1972 63.162 37.0059C78.1827 36.7934 95.5974 51.8622 92.985 61.2115C90.3728 70.5607 91.4613 73.748 79.2706 84.3722C68.7864 93.5089 63.6155 93.0594 58.6346 91.5382L58.2873 91.4304C58.2295 91.4122 58.1717 91.3938 58.1138 91.3754L57.7668 91.2636C57.5932 91.2072 57.4195 91.1501 57.2454 91.0926L56.8967 90.9774C56.8385 90.9581 56.7803 90.9389 56.7219 90.9196L56.3711 90.8042C56.3125 90.785 56.2538 90.7658 56.1951 90.7467C50.3174 88.8343 32.4665 75.2354 33.1198 60.574C33.1198 60.574 28.4224 45.5541 38.654 40.667C47.8623 36.2686 47.6255 26.9513 47.4861 25.1318L47.4837 25.1128Z" fill="#EFF5F8"/>
    <path d="M55.8438 -4.78594C38.4879 -0.657116 45.7191 9.22382 51.5041 13.6482C64.2674 12.5455 73.5031 17.1157 76.5256 19.5386C77.9133 18.6043 81.2035 15.2355 83.2628 9.235C85.8369 1.73443 77.5387 -9.94697 55.8438 -4.78594Z" fill="#D7E0E4"/>
    <path d="M72.6112 11.1098L80.9738 13.0718C80.9738 13.0718 81.1429 37.2004 51.6489 43.3916C51.6489 43.3916 71.5221 30.0275 72.6112 11.1098Z" fill="#D7E0E4"/>
    <mask id="mask1_13413_137900" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="37" y="53" width="18" height="6">
      <path d="M54.6625 56.1746C54.6625 56.1746 48.6592 54.2217 44.5031 55.4611C40.3469 56.7006 39.3849 58.8041 39.3849 58.8041L37.9995 55.2357L39.6541 56.6255L38.7306 54.1463L40.5008 55.6864C40.5008 55.6864 46.4272 50.6156 54.6625 56.1746Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_13413_137900)">
      <path d="M37.9995 53.5707H54.6625V58.8041H37.9995V53.5707Z" fill="#A8B8C2"/>
    </g>
    <mask id="mask2_13413_137900" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="70" y="53" width="18" height="6">
      <path d="M70.396 56.1746C78.6313 50.6156 84.5577 55.6864 84.5577 55.6864L86.3278 54.1463L85.4043 56.6255L87.0591 55.2357L85.6738 58.8041C85.6738 58.8041 84.7116 56.7006 80.5555 55.4611C76.3992 54.2217 70.396 56.1746 70.396 56.1746Z" fill="white"/>
    </mask>
    <g mask="url(#mask2_13413_137900)">
      <path d="M70.396 53.5707H87.0591V58.8041H70.396V53.5707Z" fill="#A8B8C2"/>
    </g>
    <mask id="mask3_13413_137900" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="74" y="70" width="10" height="3">
      <path d="M78.7597 70.5205C81.2113 70.5205 83.1988 71.0626 83.1988 71.7312C83.1988 72.3998 81.2114 72.9419 78.7597 72.9419C77.5823 72.9419 76.4531 72.8144 75.6206 72.5873C74.788 72.3603 74.3203 72.0523 74.3203 71.7312C74.3203 71.4101 74.788 71.1022 75.6206 70.8751C76.4531 70.6481 77.5823 70.5205 78.7597 70.5205Z" fill="white"/>
    </mask>
    <g mask="url(#mask3_13413_137900)">
      <path opacity="0.2" d="M74.3203 70.5205H83.1988V72.9419H74.3203V70.5205Z" fill="#D7E0E4"/>
    </g>
    <mask id="mask4_13413_137900" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="43" y="70" width="10" height="3">
      <path d="M48.0892 70.5205C50.5409 70.5205 52.5284 71.0626 52.5284 71.7312C52.5284 72.3998 50.5409 72.9419 48.0892 72.9419C46.9119 72.9419 45.7827 72.8144 44.9501 72.5873C44.1176 72.3603 43.6499 72.0523 43.6499 71.7312C43.6499 71.4101 44.1176 71.1022 44.9502 70.8751C45.7827 70.6481 46.9119 70.5205 48.0892 70.5205Z" fill="white"/>
    </mask>
    <g mask="url(#mask4_13413_137900)">
      <path opacity="0.2" d="M43.6499 70.5205H52.5284V72.9419H43.6499V70.5205Z" fill="#D7E0E4"/>
    </g>
    <mask id="mask5_13413_137900" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="74" y="56" width="6" height="7">
      <path d="M76.9803 56.0258C78.6082 56.0258 79.9279 57.4943 79.9279 59.3058C79.9279 61.1173 78.6082 62.5858 76.9803 62.5858C75.3524 62.5858 74.0327 61.1173 74.0327 59.3058C74.0327 57.4943 75.3524 56.0258 76.9803 56.0258Z" fill="white"/>
    </mask>
    <g mask="url(#mask5_13413_137900)">
      <path d="M74.0327 56.0258H79.9279V62.5858H74.0327V56.0258Z" fill="#54E2C0"/>
    </g>
    <path d="M76.9803 62.5858C78.6082 62.5858 79.9279 61.1173 79.9279 59.3058C79.9279 57.4943 78.6082 56.0258 76.9803 56.0258C75.3524 56.0258 74.0327 57.4943 74.0327 59.3058C74.0327 61.1173 75.3524 62.5858 76.9803 62.5858Z" stroke="#A8B8C2" stroke-width="1.18528" stroke-linecap="round" stroke-linejoin="round"/>
    <mask id="mask6_13413_137900" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="43" y="56" width="7" height="7">
      <path d="M46.6649 56.0255C48.2928 56.0255 49.6124 57.494 49.6124 59.3055C49.6124 61.117 48.2928 62.5855 46.6649 62.5855C45.037 62.5855 43.7173 61.117 43.7173 59.3055C43.7173 57.494 45.037 56.0255 46.6649 56.0255Z" fill="white"/>
    </mask>
    <g mask="url(#mask6_13413_137900)">
      <path d="M43.7173 56.0255H49.6124V62.5855H43.7173V56.0255Z" fill="#54E2C0"/>
    </g>
    <path d="M46.6649 62.5855C48.2928 62.5855 49.6124 61.117 49.6124 59.3055C49.6124 57.494 48.2928 56.0255 46.6649 56.0255C45.037 56.0255 43.7173 57.494 43.7173 59.3055C43.7173 61.117 45.037 62.5855 46.6649 62.5855Z" stroke="#A8B8C2" stroke-width="1.18528" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M50.2053 97.5786C40.4068 99.0812 21.8212 113.425 17.1433 118.884L40.1107 129.592L34.8202 138.07L52.6056 168.776C52.6056 168.776 48.8921 124.337 50.2053 97.5786Z" fill="#D7E0E4"/>
    <path d="M78.1405 97.5793C87.5912 100.337 101.665 113.365 105.296 118.303L85.7436 128.634L90.3962 139.14L76.107 171.484C76.107 171.484 74.9668 139.752 78.1405 97.5793Z" fill="#D7E0E4"/>
    <path d="M50.9772 116.975L38.1436 124.69C36.5061 108.559 40.5815 96.4086 49.2195 89.0845C49.2195 89.0845 50.6834 108.225 56.732 113.516L58.6789 142.192C55.9093 121.632 51.8234 122.653 51.8234 122.653L50.9772 116.975Z" fill="white"/>
    <path d="M73.463 116.975L86.2967 124.69C87.9342 108.559 84.3742 97.5837 75.7362 90.2596C75.7362 90.2596 73.7569 108.225 67.7083 113.516L65.4127 142.192C68.1824 121.632 72.6169 122.653 72.6169 122.653L73.463 116.975Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M78.0482 21.7483C78.0482 21.7483 62.7543 52.8185 30.4272 53.3627L35.8689 29.6237L54.8364 14.6202L69.3172 15.6685L78.0482 21.7483Z" fill="#D7E0E4"/>
    <path d="M64.493 32.6549C64.493 32.6549 84.14 56.4742 94.5682 49.9956C94.5682 49.9956 87.5976 28.9631 78.5993 25.3451L64.493 32.6549Z" fill="#D7E0E4"/>
    <path d="M79.3572 49.5849C85.3628 49.6058 91.2485 50.5022 92.6826 51.1553C92.6826 51.1553 93.0092 52.477 93.0858 54.4482L95.2753 52.4792V53.9408L93.0622 56.469C92.9311 58.6679 92.4135 61.2624 91 63.6902C87.6346 69.4696 83.0448 70.5844 76.9257 69.8881C70.8067 69.1917 68.4364 64.5258 67.4419 62.2975C67.1996 61.7544 66.9863 61.1568 66.7679 60.5552L66.6359 60.1942C65.9256 58.2703 65.0911 56.4079 63.0053 56.2578C60.1372 56.4648 58.9585 59.4178 58.416 61.9495C57.8042 64.8046 55.204 69.1916 49.085 69.8881C42.9659 70.5845 38.376 69.47 35.0107 63.6902C33.5971 61.2624 33.0799 58.6679 32.9484 56.469L30.7354 53.9408V52.4792L32.9249 54.4482C33.0008 52.477 33.328 51.1553 33.328 51.1553C34.7622 50.5025 40.6479 49.5987 46.6535 49.5849C50.2571 49.5724 53.9035 49.8767 56.657 50.7385C58.4157 51.289 59.7119 51.6532 60.6611 51.8929L60.9648 51.9683C62.2265 52.2757 62.7909 52.3377 63.0055 52.3402C63.3849 52.334 64.8599 52.1452 69.354 50.7385C72.1076 49.8766 75.7539 49.5712 79.3572 49.5849ZM45.7579 52.2853C43.8648 52.2789 41.7417 52.4452 39.4471 52.8972C31.5688 54.4292 37.3699 67.5767 45.0301 67.5906C52.6885 67.6178 56.7338 62.8552 55.0511 53.8719C55.0511 53.8719 51.4369 52.299 45.7579 52.2853ZM79.9474 52.2853C78.0543 52.2789 75.9324 52.4452 73.6377 52.8972C65.7595 54.4292 71.5605 67.5767 79.2207 67.5906C86.879 67.6178 90.9232 62.8552 89.2406 53.8719C89.2406 53.8719 85.6264 52.299 79.9474 52.2853Z" fill="#54E2C0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.7068 30.4435C35.7068 30.4435 28.0976 81.2329 43.364 100.384C43.364 100.384 28.4614 85.8201 29.7057 40.1855" fill="#D7E0E4"/>
    <path d="M44.0523 32.5853C55.9961 18.4267 72.4724 20.9156 78.0497 22.7308C77.02 22.5098 72.0262 23.2896 60.2883 28.1766C48.5504 33.0635 36.6924 52.7213 32.2306 61.9394L30.4287 64.6622C30.7719 60.6687 32.1084 46.7439 44.0523 32.5853Z" fill="#EFF5F8"/>
    <path d="M36.8863 23.4987C45.9072 12.1558 60.3602 15.0243 67.5578 17.6984C66.4382 17.6984 62.5676 17.8849 56.0418 18.6312C47.8846 19.5641 36.3685 27.9593 34.689 38.4067C33.3454 46.7646 31.7299 65.8311 31.81 72.7338C29.0109 61.167 27.8654 34.8416 36.8863 23.4987Z" fill="#EFF5F8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M90.9254 32.5817C90.9254 32.5817 96.1721 83.6174 80.0309 102.167C80.0309 102.167 95.4683 89.9774 96.3419 44.3357" fill="#D7E0E4"/>
    <path d="M95.7799 43.9181C94.0197 30.6904 85.2195 24.5752 81.0394 23.1711C81.7656 23.495 84.5499 26.1144 89.8774 34.001C95.2049 41.8877 94.7204 58.1927 93.8122 65.3593L93.8439 67.6873C95.2226 65.2758 97.5401 57.1458 95.7799 43.9181Z" fill="#EFF5F8"/>
    <rect x="76.7412" y="57.6066" width="2.4214" height="2.4214" rx="1.2107" fill="white"/>
    <rect x="46.0698" y="57.6066" width="2.4214" height="2.4214" rx="1.2107" fill="white"/>
    <path d="M63.1262 80.5398C58.7256 80.77 54.8752 78.3344 53.5 77.0879C55.4481 77.5673 60.2383 78.5262 63.8137 78.5262C67.3892 78.5262 71.0333 77.7591 72.4085 77.3756C71.1479 78.3344 67.5267 80.3097 63.1262 80.5398Z" fill="white"/>
    <path d="M62.7824 81.6905C58.1068 81.6905 54.646 78.6221 53.5 77.0879C55.219 78.0468 57.9693 79.9166 62.7824 80.2522C66.9079 80.5398 70.575 78.4303 72.4085 77.3756C71.1479 78.8139 67.4579 81.6905 62.7824 81.6905Z" fill="#A8B8C2"/>
    <path d="M63.1262 83.9918C66.7016 84.2219 70.9188 79.581 72.4085 77.3756C70.8042 78.4303 67.2516 81.4028 63.1262 81.4028C58.3131 81.4028 55.3336 78.6221 53.5 77.0879C55.5627 79.6768 58.6569 83.7041 63.1262 83.9918Z" fill="#D7E0E4"/>
    <path d="M62.7824 78.8136C67.1829 79.0438 70.9188 78.0466 72.4085 77.3754C71.4917 77.6849 69.4519 77.8881 67.2516 77.3753C64.5013 76.7344 62.7824 76.2247 59.3444 77.0877C56.5941 77.778 54.4168 77.3754 53.5 77.0877C54.8752 77.5671 58.3818 78.5835 62.7824 78.8136Z" fill="#D7E0E4"/>
    <path d="M79.9704 8.37121C63.8277 5.14267 52.7969 7.02599 49.2993 8.37121C50.1065 7.02596 54.1421 4.33545 63.8277 4.33545C73.5134 4.33545 78.6252 7.02596 79.9704 8.37121Z" fill="white"/>
    <g id="jen-zen-border">
      <path d="M64.7027 0.5C100.174 0.5 128.905 28.4947 128.905 63C128.905 97.5053 100.174 125.5 64.7027 125.5C29.2319 125.5 0.5 97.5053 0.5 63C0.5 28.4947 29.2319 0.5 64.7027 0.5Z" stroke="#8597A2" stroke-width="4.75"/>
    </g>
  </g>
</svg>
