export enum ZenColors {
  FONT_COLOR = '#2B3134',
  /* Green colors */
  SUCCESS = '#2BDC83',
  SUCCESS_1 = '#1DAC79',
  SUCCESS_2 = '#54E2C0',
  SUCCESS_3 = '#A9F1E0',
  SUCCESS_4 = '#D3FFF5',
  SUCCESS_5 = '#D1ECDE', // Used in the RCR
  SUCCESS_6 = '#D9FFEC',

  /* Yellow colors */
  WARN_1 = '#FF993A',
  WARN_2 = '#FFC061',
  WARN_3 = '#FFE352',
  WARN_4 = '#FFEF9B',
  WARN_5 = '#FFFAE2',

  /* Blue colors */
  BLUE = '#336AF8',
  BLUE_2 = '#85A6FB',
  BLUE_3 = '#ADC3FC',
  BLUE_4 = '#D6E1FE',
  BLUE_5 = '#DEE6F8',

  INFO_1 = '#68C5FA',
  INFO_3 = '#A5E8FF',
  INFO_4 = '#e0f4fe',

  /* Red colors */
  DANGER_1 = '#FF7D7D',
  DANGER_2 = '#F5A9AB',
  DANGER_3 = '#F4C8CA',

  /* Grey colors */
  textColorDark = '#40464D',
  textColor = '#878F97',
  textColorLight = '#A8B8C2',
  textColorLight_2 = '#D7E0E4',
  textColorLight_3 = '#EFF5F8',
  textColorLight_4 = '#F6FAFB'
}
