import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../../environments';
import {AuthenticationService} from './authentication.service';
import {HttpClient} from '@angular/common/http';
import {ErrorService} from './error.service';
import {ZenUser, ZenUserUpdateModel, ZenUserV1Model} from '../../_models/zen-user';
import {catchError, map, take} from 'rxjs/operators';

@Injectable()
export class ProfileService {
  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private error: ErrorService) {
  }

  getUser(): Observable<ZenUserV1Model> {
    const url = `${this.baseUrl}/v1/users/${this.authService.getCurrentUserId()}`;
    return this.http.get(url)
      .pipe(
        map(response => response as ZenUser),
        take(1),
        catchError(this.error.handleObsError)
      );
  }

  updateUser(user: ZenUserUpdateModel): Observable<ZenUserV1Model> {
    const url = `${this.baseUrl}/v1/users/${this.authService.getCurrentUserId()}`;
    return this.http.put(url, user)
      .pipe(
        map(response => response as ZenUserV1Model),
        catchError(this.error.handleObsError)
      );
  }

}
