<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" actionStyleCls="center" [loading]="loading">

  <div class="d-flex justify-content-center m-4">
    <div class="col-12 col-10">
      <app-zen-autocomplete *ngIf="rateSchedules?.length"
                            label="Rate Class"
                            [listData]="rateSchedules" [formCtrl]="rateClassControl"></app-zen-autocomplete>
    </div>
  </div>

</app-zen-dialog-wrapper>
