// Used to fix - For UK the google-maps API is not providing abbreviated short_name for the state/county
// The below response is copied from state-item-list API with includeFullName=true
const _allUkStates = [
  {
    'key': 'BD',
    'value': 'Bedfordshire'
  },
  {
    'key': 'BR',
    'value': 'Berkshire'
  },
  {
    'key': 'BU',
    'value': 'Buckinghamshire'
  },
  {
    'key': 'CA',
    'value': 'Cambridgeshire'
  },
  {
    'key': 'CH',
    'value': 'Cheshire'
  },
  {
    'key': 'CO',
    'value': 'Cornwall'
  },
  {
    'key': 'CU',
    'value': 'Cumbria'
  },
  {
    'key': 'DE',
    'value': 'Derbyshire'
  },
  {
    'key': 'DR',
    'value': 'Dorset'
  },
  {
    'key': 'DU',
    'value': 'Durham'
  },
  {
    'key': 'DV',
    'value': 'Devon'
  },
  {
    'key': 'ES',
    'value': 'Essex'
  },
  {
    'key': 'EY',
    'value': 'East Riding of Yorkshire'
  },
  {
    'key': 'GL',
    'value': 'Gloucestershire'
  },
  {
    'key': 'HF',
    'value': 'Herefordshire'
  },
  {
    'key': 'HP',
    'value': 'Hampshire'
  },
  {
    'key': 'HT',
    'value': 'Hertfordshire'
  },
  {
    'key': 'HU',
    'value': 'Huntingdonshire'
  },
  {
    'key': 'KT',
    'value': 'Kent'
  },
  {
    'key': 'LC',
    'value': 'Lancashire'
  },
  {
    'key': 'LI',
    'value': 'Lincolnshire'
  },
  {
    'key': 'LN',
    'value': 'Greater London'
  },
  {
    'key': 'LO',
    'value': 'City of London'
  },
  {
    'key': 'LS',
    'value': 'Leicestershire'
  },
  {
    'key': 'MN',
    'value': 'Greater Manchester'
  },
  {
    'key': 'MY',
    'value': 'Merseyside'
  },
  {
    'key': 'NF',
    'value': 'Norfolk'
  },
  {
    'key': 'NH',
    'value': 'Northamptonshire'
  },
  {
    'key': 'NO',
    'value': 'Nottinghamshire'
  },
  {
    'key': 'NU',
    'value': 'Northumberland'
  },
  {
    'key': 'NY',
    'value': 'North Yorkshire'
  },
  {
    'key': 'OX',
    'value': 'Oxfordshire'
  },
  {
    'key': 'RU',
    'value': 'Rutland'
  },
  {
    'key': 'SF',
    'value': 'Staffordshire'
  },
  {
    'key': 'SH',
    'value': 'Shropshire'
  },
  {
    'key': 'SO',
    'value': 'Somerset'
  },
  {
    'key': 'SU',
    'value': 'Suffolk'
  },
  {
    'key': 'SY',
    'value': 'South Yorkshire'
  },
  {
    'key': 'TB',
    'value': 'Tyne and Wear'
  },
  {
    'key': 'WA',
    'value': 'Warwickshire'
  }
];

export function findUkStateShortName(stateFullName: string) {
  return _allUkStates.find(s => clean(s?.value) === clean(stateFullName))?.key || '';

  function clean(s) {
    return s?.trim()?.toLowerCase()?.replace(/ /g, '');
  }
}
