<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)">
  <div class="dialog-form-sec with-border text-start pb-5 mx-4">
    <p class="mb-4">{{'nomenclature.rate' | translate}} (¢/kWh)</p>
    <mat-form-field appearance="outline">
      <mat-label>{{'nomenclature.rate' | translate}} (¢/kWh)</mat-label>
      <input [formControl]="pctValue" matInput placeholder="Example: 4.90" step="0.1" type="number"/>
      <mat-error *ngIf="pctValue.errors">Rate must be between {{ZenRatesEnum.ELECTRICITY_MIN_RATE * 100}}
        and {{ZenRatesEnum.ELECTRICITY_MAX_RATE * 100}} cents.
      </mat-error>
    </mat-form-field>
  </div>
</app-zen-dialog-wrapper>
