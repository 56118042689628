import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class WixAnswersService {
    private wixAttempts = 0;

    /*
        The loading flag will be enabled every time we want to show the Wix Answers logo.
        If the logo was loaded, we will set the flag to false.
        Since we show the logo in the first load by default, if we don't want to show it, we can set
        the flag to false in order to prevent the first load to override the "hide" call.
    */
    private loading = false;

    showWixAnswers() {
        this.loading = true;
        this.wixAttempts = 0;
        /** Disabling the wix answers temporally. */
        // this.answersLoaded();
    }

    hideWixAnswers() {
        this.loading = false;

        if (document.getElementById('answers-widget-launcher-container') &&
            document.getElementById('answers-widget-launcher-container').classList) {
            document.getElementById('answers-widget-launcher-container').classList.remove('visible');
        }
    }

    private answersLoaded() {
        if (!document.getElementById('answers-widget-launcher-container') && this.loading && this.wixAttempts < 10) {
            setTimeout(() => {
                this.answersLoaded();
                this.wixAttempts++;
            }, 1000);
        } else if(document.getElementById('answers-widget-launcher-container') && this.loading) {
            this.loading = false;
            document.getElementById('answers-widget-launcher-container').classList.add('visible');
        }
    }
}
