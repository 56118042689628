<ng-container [ngSwitch]="footerType">

  <!-- Advisor footer starts -->
  <ng-container *ngSwitchCase="ZenFooterTypes.ADVISOR">
    <ng-container *ngIf="settings?.footerAdvisorPagesIncluded">
      <div class="zen-footer {{alignment}}"
           [style.background-color]="innerBackgroundColor ? innerBackgroundColor : '#ffffff'">
        <div id="advisor-footer" class="advisor-footer" [innerHTML]="settings.footerAdvisorPages"></div>

        <div class="advisor-footer" *ngIf="settings && !settings.footerAdvisorPagesIncluded">
          <ul class="list-unstyled">
            <li><a href="https://www.tili.ai/privacy-policy.html">Privacy Policy</a></li>
            <li><a href="https://www.tili.ai/terms-of-service.html">Terms of Service</a></li>
          </ul>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <!-- Advisor footer ends -->

  <!-- Customer footer starts -->
  <ng-container *ngSwitchCase="ZenFooterTypes.CUSTOMER">
    <ng-container *ngIf="settings?.footerCustomerPagesIncluded">
      <div class="zen-footer {{alignment}}"
           [style.background-color]="innerBackgroundColor ? innerBackgroundColor : '#ffffff'">
        <div id="customer-footer" class="customer-footer" [innerHTML]="settings.footerCustomerPages"></div>
      </div>
    </ng-container>
  </ng-container>
  <!-- Customer footer ends -->

</ng-container>
