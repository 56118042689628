import {Component, Input, OnInit} from '@angular/core';
import {ZenTableMenuOption} from '../zen-mat-table.component';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-zen-mat-table-actions',
  templateUrl: './zen-mat-table-actions.component.html',
  styleUrls: ['./zen-mat-table-actions.component.scss']
})
export class ZenMatTableActionsComponent implements OnInit {
  @Input() actions: ZenTableMenuOption[] = [];
  @Input() selection = new SelectionModel<any>(true, []);

  constructor() { }

  ngOnInit(): void {
  }

}
