<div class="widget-container">
  <div class="header">
    <div class="title">
      {{ title }}<i *ngIf="helpText" class="material-symbols-rounded help-outline px-2"
                    [popper]="helpText" [popperTrigger]="'hover'" popperApplyClass="xs">info</i>
    </div>
    <div class="metric">
      <span *ngIf="prefix" class="prefix">{{ prefix }}</span>{{ formattedMetric || '0' }}<span *ngIf="suffix" class="prefix">{{ suffix }}</span>
    </div>
  </div>
  <canvas *ngIf="showLineChart" baseChart
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [legend]="false"
          [type]="'bar'">
  </canvas>
</div>

<popper-content #helpText>
  <app-zen-popper [popperContent]="helpTextPopper" [title]="title">
    <div>
      <p>Represents the total {{title}}.</p><br />
      <ng-container *ngIf="showLineChart">
        <p>The line chart is a monthly timeline representation of when meters are available for procurement.</p><br />
        <p>Here’s how it works:</p>
        <ul>
          <li>
            <b>Immediate Opportunities:</b> When an opportunity contains meters on a utility plan or on an expired contract,
            the opportunity is available immediately.  These will show up in the current month.
          </li>
          <li>
            <b>Future Risks/Opportunities:</b> For meters either not on a utility plan or still under contract,
            these show in the month the contract is expiring.
          </li>
        </ul>
      </ng-container>
    </div>
  </app-zen-popper>
</popper-content>
