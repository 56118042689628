import { OrganizationStore } from './organization.store';
import { Injectable } from '@angular/core';
import { QueryEntity, Query } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class OrganizationQuery {

  customers = new QueryEntity(this.store.customers);
  currentReport = new Query(this.store.currentReport);

  constructor(protected store: OrganizationStore) {
  }
}