<div [ngClass]="{'ms-3': true, 'mb-2': !displayInline }">
  <mat-chip-listbox #chipList aria-label="Filter selection">
    <mat-chip-option class="flyout-chip" *ngFor="let node of dataSource" (removed)="remove(node)">
      {{node.label}}
      <button class="flyout-chip-remove" matChipRemove>
        <span class="chips-material-symbols-rounded sm pi-chip-remove-icon">close</span>
      </button>
    </mat-chip-option>
  </mat-chip-listbox>
</div>
