import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeManagerService } from './services/theme-manager.service';
import { ThemeDirective } from './directives/theme.directive';
import { ColorService } from './services/color.service';
import { CssVariablesService } from './services/css-variables.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ThemeDirective],
  exports: [
    ThemeDirective
  ],
  providers: [ThemeManagerService, ColorService, CssVariablesService]
})
export class ZenThemeManagerModule {
  static forRoot(): ModuleWithProviders<ZenThemeManagerModule> {
    return {
      ngModule: ZenThemeManagerModule,
      providers: [
        ColorService,
        CssVariablesService,
        ThemeManagerService
      ]
    }
  }
 }
