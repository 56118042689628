import {Injectable} from '@angular/core';
import {ThemeStyles} from '..';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../../../environments';
import {ColorService} from './color.service';
import {CssVariablesService} from './css-variables.service';
import {range} from 'lodash';
import {debounceTime} from 'rxjs/operators';

const THEME_STYLES_KEY = 'theme-styles';
@Injectable()
export class ThemeManagerService {

  currentTheme: Subject<ThemeStyles> = new Subject();

  constructor(private http: HttpClient,
    private colors: ColorService,
    private cssVars: CssVariablesService) {

    this.observeCurrentTheme().pipe(debounceTime(200))
      .subscribe(t => this.applyTheme(t));
    // Note: Im removing this method to fix DEV-9425 (theme local cache issue). Want to make sure its not breaking anything.
    // this.restoreThemeFromStorage();
  }

  observeCurrentTheme(): Observable<ThemeStyles> {
    return this.currentTheme.asObservable();
  }

  setCurrentTheme(t: ThemeStyles) {
    this.currentTheme.next(t);
  }

  getTheme(themeId: string): Observable<ThemeStyles> {
    const url = `${environment.apiBaseUrl}/v2/theme-styles/${themeId}`;
    return this.http.get<ThemeStyles>(url)
  }

  getThemes(): Observable<ThemeStyles[]> {
    const url = `${environment.apiBaseUrl}/v2/theme-styles`;
    return this.http.get<ThemeStyles[]>(url);
  }

  setupLightColorRange(srcVarName: string) {
    const percents = range(5, 100, 5);
    const srcColor = this.cssVars.getCssVariable(srcVarName);
    percents.forEach((percent) => {
      const target = this.colors.tint(srcColor, percent);
      const targetVarName = `${srcVarName}Light${percent}`;
      this.cssVars.setCssVariable(targetVarName, target);
      // console.log(`generated ${targetVarName} = ${target}`);
    })
  }

  setupDarkColorRange(srcVarName: string) {
    const percents = range(5, 100, 5);
    const srcColor = this.cssVars.getCssVariable(srcVarName);
    percents.forEach((percent) => {
      const target = this.colors.shade(srcColor, percent);
      const targetVarName = `${srcVarName}Dark${percent}`;
      this.cssVars.setCssVariable(targetVarName, target);
      // console.log(`generated ${targetVarName} = ${target}`);
    })
  }

  scaffoldPalette() {
    const srcColorCssVars = ['--primaryColor', '--secondaryColor', '--tertiaryColor', '--textColor'];
    srcColorCssVars.forEach((srcVarName) => {
      this.setupDarkColorRange(srcVarName);
      this.setupLightColorRange(srcVarName);
    });
  }

  public applyTheme(theme: ThemeStyles) {
    let themeObj = theme;
    Object.keys(themeObj.rules).forEach(k => {
      this.cssVars.setCssVariable(`--${k}`, themeObj.rules[k]);
    });
    this.scaffoldPalette();
    console.log(`finished applying theme`);
    // this.storeTheme(theme);
  }

}

