<div class="zen-toast-help" >
  <div class="zen-toast-main" id="zenToastConfirmation" *ngIf="popperContent">
    <div class="img-container">
      <span class="count">1</span>
      <div class="pointer"
           [popper]="popperContent"
           [popperTrigger]="'click'"
           [popperShowOnStart]="false"
           [popperHideOnClickOutside]="false"
           popperApplyClass="zen-confirmation-popper"
           [popperPlacement]="'top-end'"
           [popperPositionFixed]="true">
          <app-jen-zen-img [imgType]="JenZenImgType.LARGE"></app-jen-zen-img>
      </div>
    </div>
  </div>
</div>
