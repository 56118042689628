export interface ActivationAuditModel {
  id: number;
  value: boolean;
  parent: ParentHierarchyEnum;
  parentId: string;
  reasonCustom: string;
  reasonId: number;
  reasonName: string;
  userId: string;
  userFirstName: string;
  userLastName: string;
  assumption: boolean;
  assumptionDt: Date;
  createDt: Date;
  updateDt: Date;
}

export enum ParentHierarchyEnum {
  len = 'len',
  serviceAddress = 'serviceAddress',
  customer = 'customer',
  organization = 'organization',
  meter = 'meter',
}
