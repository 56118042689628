import {Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {NgxPopperjsContentComponent} from 'ngx-popperjs';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {Observable, ReplaySubject} from 'rxjs';
import {orderBy} from 'lodash';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import {IMaskPipe} from 'angular-imask';
import {TermMask} from '../../_enums/zen-masks.enum';
import {ItemListV4Model} from '../../_model/item-list-v4.model';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-zen-input-chip-list-selection',
  templateUrl: './zen-input-chip-list-selection.component.html',
  styleUrls: ['./zen-input-chip-list-selection.component.scss']
})
export class ZenInputChipListSelectionComponent implements OnInit {
  @Input() label: string;
  @Input() maxSelection: number;
  @Input() onlyNumbers: boolean;
  @Input() compactSize = false;
  @Input() formCtrl: UntypedFormControl;
  @Input() loading: boolean;
  @Input() infoPopper: NgxPopperjsContentComponent;
  @Input() itemListData: ItemListV4Model[] = [];

  // For external search - We are not setting [itemListData]. Because externalFilterObsFn will initiated on load. -->
  @Input() externalFilterObsFn: (search?: string) => Observable<ItemListV4Model[]>;

  isMaxTermsSelected: boolean;
  selectedKeyIds = [];
  isMobile: boolean;

  searchCtrl = new UntypedFormControl();
  filteredTermsOptions: ReplaySubject<ItemListV4Model[]> = new ReplaySubject();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('termInputSelection') termInput: ElementRef<HTMLInputElement>;


  constructor(private scrollToSvc: ScrollToService,
              private zenDialogSvc: ZenDialogMsgService,
              private iMaskPipe: IMaskPipe) { }

  ngOnInit(): void {
    if (this.onlyNumbers) {
      this.separatorKeysCodes = [ENTER, COMMA, SPACE];
    }

    // listen to changes in Terms, and filter options accordingly
    this.searchCtrl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(this.externalFilterObsFn ? 250 : 0))
      .subscribe(val => {

        // On mat option select the val will be a Object {key, value}. On search its string.
        if (typeof val === 'string') {
          if (this.onlyNumbers) {
            this.keepOnlyNumbersOnSearch(val);
          }
          setTimeout(() => this.filterItemList(), 25);
        }
    });

    this.sortSelectedItems();

    if (this.externalFilterObsFn) {
      this.filterItemList(false);
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = window.innerWidth <= 550;
  }

  keepOnlyNumbersOnSearch(val: string) {
    const _numOnly = this.iMaskPipe.transform(JSON.stringify(val), TermMask);
    this.searchCtrl.setValue(_numOnly, {emitEvent: false, onlySelf: true});
    this.termInput.nativeElement.value = _numOnly;
  }

  filterItemList(filterItem = true) {
    let _itemListData = [...this.itemListData];
    let search = this.searchCtrl.value || '';

    // External API search
    if (this.externalFilterObsFn) {
      this.loading = true;
      this.externalFilterObsFn(search).pipe(distinctUntilChanged(), debounceTime(250)).subscribe({
        next: res => {
          this.itemListData = res.map(item => {
            // When the showAddress is true -> The value will be like -> "Henrys 3rd St LLC - 456 Washington St, New York, NY 10013"
            // So, by splitting them by "||" we will able to get [lenName, billingAddress]
            let values = item.value.split('||');
            item.value = values[0]; // lenName or item list value
            item.subOptionText = values[1]; // lenName or item list value
            return item;
          });
          if (filterItem) {
            this.filteredTermsOptions?.next(res);
            this.termInput.nativeElement?.focus();
          }
          this.loading = false;
        },
        error: err => {
          this.loading = false;
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
    } else {
      // Regular array filter
      if (!search) {
        this.filteredTermsOptions?.next(_itemListData);
      } else {
        this.filteredTermsOptions?.next(this.itemListData.filter(i => i.value?.startsWith(search)));
      }
      this.termInput.nativeElement?.focus();
    }
  }

  handleOptionSelect(event: MatAutocompleteSelectedEvent): void {
    const _item = this.itemListData.find(i => i.key === (event.option?.value as ItemListV4Model)?.key);
    if (_item) {
      this.addTerm(_item);
    }
  }

  addTerm(item: ItemListV4Model) {
    let selectedItems: ItemListV4Model[] = this.formCtrl?.value || [];
    const index = selectedItems.findIndex(i => i.key === item.key);

    if (!this.isMaxTermsSelected || (index !== -1)) {
      if (index === -1) { // add term
        selectedItems.push(item);
      } else if (index !== -1) { // remove term
        selectedItems.splice(index, 1);
      }

      // clear input value
      this.termInput.nativeElement.value = '';
      this.searchCtrl.setValue(null, this.externalFilterObsFn ? {emitEvent: false, onlySelf: true} : {emitEvent: true});

      this.formCtrl.setValue(selectedItems);

      this.sortSelectedItems();
    }
  }


  addTerms(items: ItemListV4Model[]) {
      this.termInput.nativeElement.value = '';
      this.formCtrl.setValue(items);
      this.sortSelectedItems();
    }

  removeTerm(item: ItemListV4Model): void {
    let selectedItems: ItemListV4Model[] = this.formCtrl?.value || [];
    const index = selectedItems.findIndex(i => i.key === item.key);
    if (index !== -1) {
      selectedItems.splice(index, 1);
      this.formCtrl.setValue([...selectedItems]);
    }
    this.filteredTermsOptions?.next(this.itemListData);
    this.sortSelectedItems();
  }

  /** Sort selected contract terms */
  sortSelectedItems() {
    let selectedItems: ItemListV4Model[] = this.formCtrl?.value || [];
    /** To order selected terms */
    this.formCtrl.setValue(orderBy(selectedItems, 'key'), {emitEvent: false});

    /** To disable check options in the multiselect dropdown on selecting 6 options. */
    this.isMaxTermsSelected = (selectedItems && selectedItems.length >= this.maxSelection);

    this.selectedKeyIds = selectedItems.map(s => s.key);
  }

  /**
   * This method used while hitting enter/comma/space when the input field got any values.
   */
  checkInputValueAndUpdate() {
    if (this.searchCtrl.value) {
      const _value = this.searchCtrl.value;
      const _exitingItem: ItemListV4Model = this.itemListData?.find(v => v?.value === _value);
      if (_exitingItem?.key) {
        let selectedItems: ItemListV4Model[] = this.formCtrl?.value || [];
        const index = selectedItems.findIndex(i => i.value === _value);
        if (index === -1) {
          this.addTerm(_exitingItem);
        }
      }
    }
  }

  expandDropdownClick() {
    this.termInput.nativeElement?.blur();
    setTimeout(() => this.termInput.nativeElement?.focus(), 100);
  }

}
