<div class="zen-dialog" id="zenDialog">
  <div mat-dialog-content>

    <i class="material-icons close-icon" (click)="data.onClose()" *ngIf="!data.hideCloseX">close</i>

    <div class="header-icon-main">
      <i *ngIf="data?.header?.icon" class="material-symbols-rounded {{data?.header?.iconClass}}">{{data?.header?.icon}}</i>
    </div>

    <h1 mat-dialog-title *ngIf="data?.header" class="dialog-title {{data?.header?.popperDet?.message ? 'd-flex justify-content-center align-items-center' : ''}}">
      {{data?.header?.title}}

      <i *ngIf="data?.header?.popperDet?.message" class="material-symbols-rounded blue-1-color small pointer ms-3"
         [popper]="headerPopper" popperTrigger="click" popperApplyClass="sm"
         [popperAppendTo]="'zenDialog'" [popperPositionFixed]="true">info</i>
    </h1>
    <p *ngIf="data?.bodyText" class="body-text">{{data?.bodyText}}</p>
    <div *ngIf="data?.bodyHtml" [innerHTML]="data?.bodyHtml" class="body-text"></div>

    <!-- Error msg section-->
    <div class="d-flex justify-content-center mb-4 mx-5" *ngIf="errorMsg">
      <app-zen-message [ngClass]="this.errorMsgClass" [type]="ZenMessageType.ERROR" [message]="errorMsg"></app-zen-message>
    </div>

    <ng-content></ng-content>

    <!-- Checkbox section-->
    <div *ngIf="data?.checkBox?.formCtrl" class="my-4">
      <mat-checkbox [formControl]="data?.checkBox?.formCtrl">{{data?.checkBox?.label}}</mat-checkbox>
    </div>
  </div>

  <div [ngClass]="actionStyleCls" [hidden]="hideButton" mat-dialog-actions>
    <ng-container *ngFor="let btn of data.actionButtons; let index=index">
      <!-- Menu button -->
      <ng-container *ngIf="btn.menuItems && btn.menuItems.length else buttonTemplate">
        <ng-template [ngTemplateOutlet]="menuButtonTemplate" [ngTemplateOutletContext]="{config: btn}"></ng-template>
      </ng-container>
      <!-- Regular action buttons -->
      <ng-template #buttonTemplate>
        <button mat-button
                class="{{btn?.btnType || 'mat-button'}} {{btn?.styleClass}} {{data.actionButtons?.length === 1 ? '' : 'ms-4'}}"
                (click)="onSubmit.emit(btn)" [color]="btn.color" [disabled]="disableButton || loading"
                [class.loading]="loading">

          <div class="d-flex align-items-center">
            <i class="material-symbols-rounded prefix-icon me-3" *ngIf="btn.prefixIcon">{{btn.prefixIcon}}</i>
            <span class="label">{{btn.label}}</span>
            <i class="material-symbols-rounded suffix-icon ms-3" *ngIf="btn.suffixIcon">{{btn.suffixIcon}}</i>
          </div>

          <mat-spinner diameter="25" class="ms-3" *ngIf="loading && btn?.btnType === ButtonTypes.MAT_RAISED_BUTTON"
                       [strokeWidth]="2"></mat-spinner>
        </button>
      </ng-template>
    </ng-container>
  </div>
</div>

<ng-template #menuButtonTemplate let-config="config">
  <div *ngIf="config">
    <button mat-raised-button color="accent" class="ms-4"
            (click)="config.command ? config.command({}) : null"
            [matMenuTriggerFor]="tableTopMenu" [class.loading]="loading" [disabled]="disableButton">
      <div class="d-flex align-items-center">
        <i class="material-symbols-rounded prefix-icon" *ngIf="config.prefixIcon">{{config.prefixIcon}}</i>
        <span class="label">{{config.label}}</span>
        <i class="material-symbols-rounded suffix-icon ms-3" *ngIf="config.suffixIcon">{{config.suffixIcon}}</i>
      </div>
      <mat-spinner diameter="25" *ngIf="loading"
                   [strokeWidth]="2" color="primary"></mat-spinner>
    </button>

    <mat-menu #tableTopMenu="matMenu">
      <ng-container *ngFor="let m of config.menuItems">
        <button mat-menu-item (click)="onSubmit.emit(m)">{{m.label}}</button>
      </ng-container>
    </mat-menu>
  </div>
</ng-template>

<popper-content #headerPopper>
  <app-zen-popper [title]="data?.header?.popperDet?.title" [popperContent]="headerPopper" [closeable]="false">
    <p class="text-md font-weight-300 mb-0" [innerHTML]="data?.header?.popperDet?.message"></p>
  </app-zen-popper>
</popper-content>
