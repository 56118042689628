<div class="ms-3" [ngClass]="{'mb-2': !displayInline}">
<mat-chip-listbox #chipList aria-label="Filter selection">
  <mat-chip-option class="filter-chip" *ngFor="let filter of zenFilterService.filterChipNodes" (removed)="remove(filter)">
    <span *ngIf="filter?.showStackedIcon" class="chips-material-symbols-rounded"  >{{filter.icon ? filter.icon : ''}}<span class="chips-material-symbols-rounded-mini-filled">sell</span>  </span>
    <span *ngIf="!filter?.showStackedIcon"class="chips-material-symbols-rounded">{{filter.icon ? filter.icon : ''}}</span>
    {{filter.item}}
    <span *ngIf="filter?.chipChildren?.length > 1" #child [popper]="chipPopper" (popperOnShown)="updatePopperList(filter)" popperTrigger="click" popperPlacement="bottom" [popperPositionFixed]="true" class="link-span"> + {{filter.chipChildren.length - 1}} more...</span>
    <button *ngIf="!(filter?.parentAttribute == 'activationStatuses' && filter?.value === 'Inactive' && isActive === false && hierarchyLevel <= portfolioHierarchyLevelEnum.CUSTOMERS)" class="filter-chip-remove" matChipRemove>
      <span class="chips-material-symbols-rounded sm pi-chip-remove-icon">close</span>
    </button>

  </mat-chip-option>
  <popper-content class="chip-popper" #chipPopper>
    <app-zen-popper [popperContent]="chipPopper" *ngIf="popperChildren?.length > 1">
      <mat-chip-option class="filter-chip-list-item" *ngFor="let filter of popperChildren" (removed)="remove(filter)">
        {{filter.item}}
        <button class="pe-4 float-end" matChipRemove>
          <span class="chips-material-symbols-rounded sm pi-chip-remove-icon ">close</span>
        </button>
      </mat-chip-option>
    </app-zen-popper>
  </popper-content>
</mat-chip-listbox>
</div>
