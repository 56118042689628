/**
 * Node for to-do item
 */
import {Component, EventEmitter, HostListener, Injectable, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {InitialFiltersService} from '../../../_modules/portfolio/_services/_helpers/initial-filters.service';
import {
  InitialPortfolioFilters,
  PortfolioFilterRequest,
  SelectOption
} from '../../../_modules/portfolio/_model/portfolio-initial-filter.model';
import {orderBy} from 'lodash';
import {PortfolioFilterScope, PortfolioHierarchyLevelEnum} from '../../../_modules/portfolio/_enums/portfolio-hierarchy-level.enum';
import {CustomerIdSessionService} from '../../_services/customer-id/customer-id-session.service';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import {ZenAbstractFilterService} from '../../../_modules/zen-contracts/_services/zen-abstract-filter-service';
import {debounceTime, take} from 'rxjs/operators';
import {PortfolioHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-helper.service';


/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TreeItemNode[]>([]);

  get data(): TreeItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {
  }
}

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'app-portfolio-filter-flyout',
  templateUrl: './portfolio-filter-flyout.component.html',
  styleUrls: ['./portfolio-filter-flyout.component.scss'],
  providers: [ChecklistDatabase]
})
export class PortfolioFilterFlyoutComponent implements OnInit, OnDestroy {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TreeItemFlatNode, TreeItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeItemNode, TreeItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TreeItemFlatNode | null = null;
  displayPosition: boolean;
  position: string;

  @Input() customerId: number;
  @Input() lenId: string;
  @Input() serviceAddressId: number;
  @Input() isActive: boolean;
  @Input() filterScope: PortfolioFilterScope;

  @Input() hierarchyLevel: PortfolioHierarchyLevelEnum;
  @Input() showFilter: boolean;
  @Output() showFilterChange = new EventEmitter<boolean>();
  @Input() urlParamFilters: PortfolioFilterRequest;

  // TODO: Needs expanding on for hierarchy
  @Input() excludeFields: string[];

  @Input() updateURLState?: boolean = true;


  @Output() filtersInitialized = new EventEmitter<boolean>();

  @Output() onFilterChangeCallbackFn = new EventEmitter<void>();
  @Input() zenFilterService: ZenAbstractFilterService;
  parentAttribute = FilterParentAttribute;
  sortField = 'item';
  treeControl: FlatTreeControl<TreeItemFlatNode>;
  meterStatuses: SelectOption[];
  futureMeterStatuses: SelectOption[];
  treeFlattener: MatTreeFlattener<TreeItemNode, TreeItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TreeItemNode, TreeItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TreeItemFlatNode>(true /* multiple */);

  subs: Subscription[] = [];

  constructor(private _database: ChecklistDatabase, private portfolioFilterService: InitialFiltersService,
              private pfHelperService: PortfolioHelperService,
              private authSvc: AuthenticationService) {
    // For hierarchyLevels except PORTFOLIO the customerIdStore is updated from pfHelpSvc view methods.
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TreeItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
      this.showPositionDialog('right');
      this.initializeFilter();

      // allows us to refresh the filters from the PortfolioFiltersService
      this.subs.push(this.portfolioFilterService.refreshFilters.pipe(debounceTime(300)).subscribe(refresh => {
        if (refresh) {
          this.initializeFilter();
        }
      }));
  }


  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  initializeFilter(): void {
    this.portfolioFilterService.getPortfolioInitialFilters(this.customerId, this.lenId, this.serviceAddressId, this.filterScope).subscribe(res => {
      //  Build the filter object, and then set edge cases for Time Horizon
      this.portfolioFilterService.emitFilters.next(res);
      this._database.dataChange.next(this.buildFilter(res));
      this.meterStatuses = res.meterStatuses;
      this.futureMeterStatuses = res.futureMeterStatuses;
      this.setFutureSelectedByDefault();
      this.setActiveSelectedByDefault();
      this.setRenewableStatusByDefault();
      //  Make filter chips load on initial load
      this.applyUrlFilterToFlyout()
      this.zenFilterService.setFilterNodes(this.checklistSelection.selected);
      this.filtersInitialized.next(true);
    });
  }


  setFutureSelectedByDefault() {
    const todayRadioButtonSelected = this.checklistSelection.selected.filter(x => x.value === 'Today');
    if (todayRadioButtonSelected.length === 0) {
      this.checklistSelection.select(...this.treeControl.dataNodes.filter(x => x.value === 'Future' && x.parentAttribute === FilterParentAttribute.timeHorizons));
    } else {
      this.handleTimeHorizonRadioButtonLogic(todayRadioButtonSelected.shift());
    }
  }

  // Selects the active radio button by default
  setActiveSelectedByDefault() {
    const activationStatusNodes = this.checklistSelection.selected.filter(x => x.parentAttribute === FilterParentAttribute.activationStatuses);
    if (activationStatusNodes.length === 0) {
      this.checklistSelection.select(...this.treeControl.dataNodes.filter(x => x.parentAttribute === FilterParentAttribute.activationStatuses && x.value === true));
    }

    if (this.isActive === false) {
      this.checklistSelection.deselect(...this.treeControl.dataNodes.filter(x => x.parentAttribute === FilterParentAttribute.activationStatuses && x.value === true));
      this.checklistSelection.select(...this.treeControl.dataNodes.filter(x => x.parentAttribute === FilterParentAttribute.activationStatuses && x.value === false));
    }

  }

  // Selects the active radio button by default
  setRenewableStatusByDefault() {
    const renewableStatusNodes = this.checklistSelection.selected.filter(x => x.parentAttribute === FilterParentAttribute.renewableStatuses);
    if (renewableStatusNodes.length === 0) {
      this.checklistSelection.select(...this.treeControl.dataNodes.filter(x => x.parentAttribute === FilterParentAttribute.renewableStatuses && x.value === RenewableStatuses.ALL));
    }
  }

  //  This handles an issue where users could press back/forward on their browser and keep the filters open and get into a weird state
  @HostListener('window:popstate', ['$event'])
  clearAndCloseFilter() {
    this.showFilter = false;
    this.toggleFilterFlyout();
    this.clearFilters(false);
  }

  getLevel = (node: TreeItemFlatNode) => node.level;

  isExpandable = (node: TreeItemFlatNode) => node.expandable;

  isDisabled = (node: TreeItemFlatNode) => node.disabled;

  getChildren = (node: TreeItemNode): TreeItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TreeItemFlatNode) => {
    // The second condition here is so that empty orgs can still see the "State / Utility" top level node.
    return _nodeData.expandable || (_nodeData.parentAttribute === FilterParentAttribute.utilityIds && _nodeData.level === 0);
  }

  hasNoContent = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    let flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TreeItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.disabled = node.disabled;
    flatNode.value = node.value;
    flatNode.parentAttribute = node.parentAttribute
    flatNode.isCheckableParent = node.isCheckableParent;
    flatNode.expandable = !!node.children?.length;
    flatNode.isRadioButton = node.isRadioButton;
    flatNode = this.addIconToChip(flatNode)
    flatNode.utilityState = node.utilityState;
    flatNode.hierarchyLvlRequired = node.hierarchyLvlRequired;
    //  If the tag nodes have no children, lets mark them as expandable so they still look like expandable nodes, instead of an akward looking checkbox
    if (flatNode.expandable === false && flatNode.level === 1 && (flatNode.parentAttribute === FilterParentAttribute.customerTags ||
      flatNode.parentAttribute === FilterParentAttribute.lenTags ||
      flatNode.parentAttribute === FilterParentAttribute.meterTags ||
      flatNode.parentAttribute === FilterParentAttribute.serviceAddressTags)) {
      flatNode.expandable = true;
    }
    flatNode.chipChildren = null;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TreeItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node).filter(n => !n.disabled && !n.isRadioButton);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node).filter(n => !n.disabled && !n.isRadioButton);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeItemFlatNode): void {
    if (!node.disabled && !(node.expandable && !node.isCheckableParent)) {
      this.checklistSelection.toggle(node);
      const descendants = this.treeControl.getDescendants(node).filter(n => !n.disabled && !n.isRadioButton);
      this.checklistSelection.isSelected(node)
        ? this.checklistSelection.select(...descendants)
        : this.checklistSelection.deselect(...descendants);

      // Force update for the parent
      descendants.forEach(child => this.checklistSelection.isSelected(child));
      this.checkAllParentsSelection(node);
    }
  }

  clearFilters(applyFutureDefault: boolean) {
    this.checklistSelection.clear()
    if (applyFutureDefault) {
      this.setFutureSelectedByDefault();
      this.setActiveSelectedByDefault();
    }
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TreeItemFlatNode): void {
    // regular toggle
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);

    this.checkMeterServiceAddressCustomerTypes(node);
  }


  checkMeterServiceAddressCustomerTypes(node: TreeItemFlatNode): void {
    // If we are selecting specific meter types, check specific service address/customer type checkboxes
    if (this.checklistSelection.selected.includes(node) && node.parentAttribute === FilterParentAttribute.meterTypes && (node.value === 'Common Area' || node.value === 'Unit')) {
      this.checklistSelection.select(...this.treeControl.dataNodes.filter(n => n.parentAttribute === FilterParentAttribute.serviceAddressTypes && n.value === 'Multi-Family'));
      this.checklistSelection.select(...this.treeControl.dataNodes.filter(n => n.parentAttribute === FilterParentAttribute.customerTypes && n.value === 'Real Estate'));
    }

    // If we are selecting a specific service address type, check specific customer 'Real Estate' type checkboxes
    if (this.checklistSelection.selected.includes(node) && node.parentAttribute === FilterParentAttribute.serviceAddressTypes && node.value === 'Multi-Family') {
      // then select Real Estate on customer
      this.checklistSelection.select(...this.treeControl.dataNodes.filter(n => n.parentAttribute === FilterParentAttribute.customerTypes && n.value === 'Real Estate'));
    }
  }

  // When we toggle between future and today, disable the necessary checkboxes based on the data for that time horizon
  updateMeterStatuses(meterStatuses: SelectOption[]) {
    this.treeControl.dataNodes.filter(n => n.parentAttribute === FilterParentAttribute.meterStatuses && !n.expandable).forEach(n => {
      const matchingNode = meterStatuses.filter(x => x.value === n.value).shift();
      // != for shorthand undefined/null check
      n.disabled = matchingNode != undefined ? matchingNode.disabled : true;
    })
  }

  handleTimeHorizonRadioButtonLogic(node: TreeItemFlatNode) {
    // Based on current time horizon value, scan through all the checkboxes, and set disabled/enabled, and uncheck any disabled checkboxes
    if (node.parentAttribute === FilterParentAttribute.timeHorizons && node.expandable === false) {
      if (this.checklistSelection.selected.filter(n => n.parentAttribute === FilterParentAttribute.timeHorizons && n.value === 'Future').length > 0) {
        this.updateMeterStatuses(this.futureMeterStatuses);
      }

      if (this.checklistSelection.selected.filter(n => n.parentAttribute === FilterParentAttribute.timeHorizons && n.value === 'Today').length > 0) {
        this.updateMeterStatuses(this.meterStatuses);
      }

      // If we somehow went back and forth and invalidated a selection, uncheck it, so we don't have a checked disabled checkbox
      this.checklistSelection.deselect(...this.checklistSelection.selected.filter(x => x.disabled));
    }
  }

  // This will untoggle other radio buttons under the same parent Attribute in the selection object to match what is shown in the UI
  todoLeafItemRadioButtonToggle(node: TreeItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checklistSelection.selected.filter(n => !n.expandable && n.isRadioButton && n.parentAttribute === node.parentAttribute && n.value !== node.value)
      .forEach(fe => {
        this.checklistSelection.toggle(fe)
      });
    this.handleTimeHorizonRadioButtonLogic(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TreeItemFlatNode): void {
    let parent: TreeItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TreeItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TreeItemFlatNode): TreeItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }


  buildFilter(pf: InitialPortfolioFilters): TreeItemNode[] {
    const _customerLabel = this.authSvc.isAdvisor() ? 'Customer' : 'Company';

    // Create objects for Type Filters
    const customerType = this.selectOptionMapperChild(_customerLabel, pf.customerTypes, FilterParentAttribute.customerTypes, PortfolioHierarchyLevelEnum.PORTFOLIO);
    const serviceAddressType = this.selectOptionMapperChild('Service Address', pf.serviceAddressTypes, FilterParentAttribute.serviceAddressTypes, PortfolioHierarchyLevelEnum.LENS);
    const meterType = this.selectOptionMapperChild('Meter', pf.meterTypes, FilterParentAttribute.meterTypes, PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES);

    // Create objects for Tag Filters
    const customerTags = this.selectOptionMapperChild(_customerLabel, pf.portfolioFilterTags.customerTags, FilterParentAttribute.customerTags, PortfolioHierarchyLevelEnum.PORTFOLIO);
    const lenTags = this.selectOptionMapperChild('Legal Entity Name', pf.portfolioFilterTags.lenTags, FilterParentAttribute.lenTags, PortfolioHierarchyLevelEnum.CUSTOMERS);
    const serviceAddressTags = this.selectOptionMapperChild('Service Address', pf.portfolioFilterTags.serviceAddressTags, FilterParentAttribute.serviceAddressTags, PortfolioHierarchyLevelEnum.LENS);
    const meterTags = this.selectOptionMapperChild('Meter', pf.portfolioFilterTags.meterTags, FilterParentAttribute.meterTags, PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES);
    // If there are no utilities, because no meters, return an empty object that will be omitted from filter.
    const hasMeters = pf.utilities && Object.entries(pf.utilities).length > 0;

    const contractStates = this.selectOptionMapper('Contract State', pf.contractStates, FilterParentAttribute.contractStates, false, false, null, PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES);
    const contractStatuses = this.selectOptionMapperKeepDefaultOrder('Contract Status', pf.contractStatuses, FilterParentAttribute.contractStatuses, false, false, null, PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES);
    const renewableStatuses = this.selectOptionMapper('Renewable Status', pf.renewableStatuses, FilterParentAttribute.renewableStatuses, true, false, null, PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES);

    const rateCheckStates = this.selectOptionMapper('State', pf.rateCheckStates, FilterParentAttribute.rateCheckStates, false, false, null, PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES);
    const rateCheckStatuses = this.selectOptionMapperKeepDefaultOrder('Rate Check Status', pf.rateCheckStatuses, FilterParentAttribute.rateCheckStatuses, false, false, null, PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES);


    if (!pf.activationStatuses) {
      if (pf.contractStates) {
        pf.activationStatuses = [
          {disabled: false, label: 'All', selected: false, value: null},
          {disabled: false, label: 'Active', selected: true, value: true},
          {disabled: false, label: 'Inactive', selected: false, value: false}
        ];
      } else {
        pf.activationStatuses = [
          {disabled: false, label: 'Active', selected: true, value: true},
          {disabled: false, label: 'Inactive', selected: false, value: false}
        ];
      }

    }

    let activationStatuses = this.selectOptionMapper('Activation Status', pf.activationStatuses, FilterParentAttribute.activationStatuses, true, false, null, this.hierarchyLevel);
    activationStatuses.children = activationStatuses.children.map(x => {
      x.disabled = this.isActive === false;
      return x;
    });

    // Manually replace the order of the activation statuses
    if (pf.contractStates) {
      [activationStatuses.children[0], activationStatuses.children[1]] = [activationStatuses.children[1], activationStatuses.children[0]];
    }

    activationStatuses.disabled = this.isActive === false;

    const stateUtils = {
      item: 'Service State / Utility',
      children: pf.utilities && Object.entries(pf.utilities).sort(([key, so], [key2, so2]) => key.localeCompare(key2)).map(([key, so]) => {
          return ({
            item: key,
            children: so.sort((a, b) => a.label.localeCompare(b.label))
              // tslint:disable-next-line:max-line-length
              .map(s => this.selectOptionToNode(s, FilterParentAttribute.utilityIds, false, key, PortfolioHierarchyLevelEnum.METERS)),
            value: key,
            disabled: false,
            parentAttribute: FilterParentAttribute.utilityIds,
            isRadioButton: false,
            isCheckableParent: true,
            utilityState: null,
            hierarchyLvlRequired: PortfolioHierarchyLevelEnum.METERS
          })
        }
      ),
      disabled: !hasMeters,
      value: null,
      parentAttribute: FilterParentAttribute.utilityIds,
      isRadioButton: false,
      isCheckableParent: false,
      utilityState: null,
      hierarchyLvlRequired: PortfolioHierarchyLevelEnum.METERS
    }


// Function to map procurement availability options
    let procurementAvailability = {
      item: 'Procurement Availability',
      children: [
        // "Available" Node
        {
          item: 'Available',
          children: pf?.procurementStatus?.filter((s) => s.label === 'Available') // Filter out the 'Available' node values from children
            .sort((a, b) => a.label.localeCompare(b.label)) // Sort remaining nodes alphabetically by label
            .map((s) => {
              s.label = 'Procurable';
              return  this.selectOptionToNode(
                  s,
                  FilterParentAttribute.procurementStatuses,
                  false,
                  null,
                  PortfolioHierarchyLevelEnum.METERS
                )
              }
            ),
          disabled: false,
          value: null,
          parentAttribute: FilterParentAttribute.procurementStatuses,
          isRadioButton: false,
          isCheckableParent: true,
          utilityState: null,
          hierarchyLvlRequired: PortfolioHierarchyLevelEnum.METERS
        },
        // "Unavailable" Node
        {
          item: 'Unavailable',
          children: pf?.procurementStatus?.filter((s) => s.label !== 'Available' && s.label !== 'Procurable') // Filter out the 'Available' node values from children
            .sort((a, b) => a.label.localeCompare(b.label)) // Sort remaining nodes alphabetically by label
            .map((s) => {
              if (s.label === 'Unavailable') {
                 s.label = 'Utility Not Procurable';
               }
              return this.selectOptionToNode(
                s,
                FilterParentAttribute.procurementStatuses,
                false,
                null,
                PortfolioHierarchyLevelEnum.METERS
              )
            }
            ),
          disabled: false,
          value: null,
          parentAttribute: FilterParentAttribute.procurementStatuses,
          isRadioButton: false,
          isCheckableParent: true,
          utilityState: null,
          hierarchyLvlRequired: PortfolioHierarchyLevelEnum.METERS
        }
      ],
      disabled: !hasMeters,
      value: null,
      parentAttribute: FilterParentAttribute.procurementStatuses,
      isRadioButton: false,
      isCheckableParent: false,
      utilityState: null,
      hierarchyLvlRequired: PortfolioHierarchyLevelEnum.METERS
    };

// If there are no meters, disable all nodes
    if (hasMeters === false) {
      procurementAvailability.disabled = true;
      procurementAvailability.children.forEach((node) => {
        node.disabled = true;
        node.children?.forEach((childNode) => (childNode.disabled = true));
      });
    }



    let results = [
      activationStatuses,
      stateUtils,
      procurementAvailability,
      this.selectOptionMapper('Commodity', pf.commodityTypes, FilterParentAttribute.commodityTypes, false, false, null, PortfolioHierarchyLevelEnum.METERS),
      this.selectOptionMapperMeter('Meter Status', pf.futureMeterStatuses, pf.timeHorizons, FilterParentAttribute.meterStatuses, hasMeters),
      this.selectOptionMapper('At Risk', pf.riskStatuses, FilterParentAttribute.riskStatuses, false, false, null, PortfolioHierarchyLevelEnum.METERS),
      this.selectOptionMapper('LOE/MLOA Status', pf.mloaStatus, FilterParentAttribute.mloaSignedStatus, false, false, null, PortfolioHierarchyLevelEnum.PORTFOLIO),
      {
        item: 'Type',
        children: ([customerType, serviceAddressType, meterType]),
        disabled: customerType.disabled && serviceAddressType.disabled && meterType.disabled,
        value: null,
        parentAttribute: null,
        isRadioButton: false,
        isCheckableParent: false,
        utilityState: null,
        hierarchyLvlRequired: PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES
      },
      {
        item: 'Tags',
        children: ([customerTags, lenTags, serviceAddressTags, meterTags]),
        disabled: customerTags.disabled && lenTags.disabled && serviceAddressTags.disabled && meterTags.disabled,
        value: null,
        parentAttribute: null,
        isRadioButton: false,
        isCheckableParent: false,
        utilityState: null,
        hierarchyLvlRequired: PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES
      }
    ] as TreeItemNode[];

    // For contracts we need to insert contract states, and remove risk statuses
    if (pf.contractStates) {
      results.splice(0, 0, renewableStatuses)
      results.splice(0, 0, contractStates);
      results.splice(0, 0, contractStatuses);

      // remove risk statuses for now for contracts, and sort
      results = results.filter(x => x.parentAttribute !== FilterParentAttribute.riskStatuses
        && x.parentAttribute !== FilterParentAttribute.rateCheckStates
        && x.parentAttribute !== FilterParentAttribute.rateCheckStatuses)
        .sort((a, b) => a.item.localeCompare(b.item));
    }

    // For RateChecks we need to insert contract states, and remove risk statuses
    if (pf.rateCheckStates || pf.rateCheckStatuses) {
      if (rateCheckStates.children.length > 0) {
        results.splice(0, 0, rateCheckStates);
      }
      results.splice(0, 0, rateCheckStatuses);
      // remove risk statuses for now for contracts
      results = results.filter(x => x.parentAttribute !== FilterParentAttribute.riskStatuses &&
        x.parentAttribute !== FilterParentAttribute.contractStates
        && x.parentAttribute !== FilterParentAttribute.contractStatuses);
    }

    // This filters out nodes that shouldn't be available based on the current hierarchy level.
    results = (results.filter(n => this.hierarchyLevel <= n.hierarchyLvlRequired).map(n => {
      n.children = n.children && n.children.filter(x => this.hierarchyLevel <= x.hierarchyLvlRequired);
      return n;
    }));

    // Excludes from the excludeFields list
    // Uses != null because excludeFields could be undefined and undefined == null but undefined !== null :(
    if (this.excludeFields?.length > 0) {
      results = (results.filter(n => !this.excludeFields.includes(n.item)).map(n => {
        n.children = n.children && n.children.filter(x => !this.excludeFields.includes(x.item));
        return n;
      }));
    }
    return results;
  }

  submitFilters() {
    this.zenFilterService.applyFiltersAndReload(this.checklistSelection.selected.filter(x => !x.expandable && x.hierarchyLvlRequired >= this.hierarchyLevel), this.hierarchyLevel, this.customerId, this.lenId, this.serviceAddressId, this.onFilterChangeCallbackFn, this.updateURLState);
    this.showFilter = !this.showFilter;
    this.portfolioFilterService.onFilterChange.next(this.showFilter);
    this.toggleFilterFlyout();
  }

  selectOptionMapper(nodeName: string, so: SelectOption[], parentAttribute: FilterParentAttribute, isRadioButton = false, parentIsRadioButton = false, utilityState = null, hierarchyLvlRequired: PortfolioHierarchyLevelEnum): TreeItemNode {
    let children = orderBy(so?.map(s => this.selectOptionToNode(s, parentAttribute, isRadioButton, null, hierarchyLvlRequired)), this.sortField, 'asc') as TreeItemNode[];
    return ({
      item: nodeName,
      children: children,
      disabled: children.filter(c => c.disabled).length === children.length,
      value: null,
      parentAttribute: parentAttribute,
      isRadioButton: parentIsRadioButton,
      isCheckableParent: false,
      utilityState,
      hierarchyLvlRequired: hierarchyLvlRequired
    })
  }

  selectOptionMapperKeepDefaultOrder(nodeName: string, so: SelectOption[], parentAttribute: FilterParentAttribute, isRadioButton = false, parentIsRadioButton = false, utilityState = null, hierarchyLvlRequired: PortfolioHierarchyLevelEnum): TreeItemNode {
    let children = so?.map(s => this.selectOptionToNode(s, parentAttribute, isRadioButton, null, hierarchyLvlRequired)) as TreeItemNode[];
    return ({
      item: nodeName,
      children: children,
      disabled: children && children.filter(c => c.disabled).length === children.length,
      value: null,
      parentAttribute: parentAttribute,
      isRadioButton: parentIsRadioButton,
      isCheckableParent: false,
      utilityState,
      hierarchyLvlRequired: hierarchyLvlRequired
    })
  }

  selectOptionMapperMeter(nodeName: string, so: SelectOption[], th: SelectOption[], parentAttribute: FilterParentAttribute, hasMeters: boolean): TreeItemNode {
    let results = ([{
      item: 'Status by Meter',
      children: so && orderBy(so.map(s => this.selectOptionToNode(s, parentAttribute, false, null, PortfolioHierarchyLevelEnum.METERS)), this.sortField, 'asc'),
      disabled: !hasMeters,
      value: null,
      parentAttribute: parentAttribute,
      isRadioButton: false,
      isCheckableParent: false,
      utilityState: null,
      hierarchyLvlRequired: PortfolioHierarchyLevelEnum.METERS
    }])
    // Time Horizons appears under the same node as Status by Meter even though they are not bottom-level objects like in other cases
    let timeHorizons = this.selectOptionMapper('Time Horizons (select one)', th, FilterParentAttribute.timeHorizons, true, true, null, PortfolioHierarchyLevelEnum.METERS);

    // Toggle disabled for child nodes
    timeHorizons.disabled = !hasMeters;
    timeHorizons.children.map(n => n.disabled = !hasMeters);
    results.unshift(timeHorizons);
    return ({
      item: nodeName,
      children: results,
      disabled: !hasMeters,
      value: null,
      parentAttribute: parentAttribute,
      isRadioButton: false,
      isCheckableParent: false,
      utilityState: null,
      hierarchyLvlRequired: PortfolioHierarchyLevelEnum.METERS
    })
  }

  selectOptionMapperChild(nodeName: string, so: SelectOption[], parentNode: FilterParentAttribute, hierarchyLvlRequired: PortfolioHierarchyLevelEnum, isRadioButton = false): TreeItemNode {
    const nodes = orderBy(so.map(s => this.selectOptionToNode(s, parentNode, false, null, hierarchyLvlRequired)), this.sortField, 'asc');
    return ({
      item: nodeName,
      children: nodes,
      disabled: !nodes.filter(x => !x.disabled).length,
      value: null,
      parentAttribute: parentNode,
      isRadioButton: isRadioButton,
      isCheckableParent: false,
      utilityState: null,
      hierarchyLvlRequired: hierarchyLvlRequired
    })
  }

  selectOptionToNode(s: SelectOption, parentAttribute: FilterParentAttribute, isRadioButton = false, utilityState = null, hierarchyLvlRequired: PortfolioHierarchyLevelEnum): TreeItemNode {
    return ({
      item: s.label,
      value: s.value,
      children: null,
      disabled: s.disabled,
      parentAttribute: parentAttribute,
      isRadioButton: isRadioButton,
      isCheckableParent: false,
      utilityState: utilityState,
      hierarchyLvlRequired: hierarchyLvlRequired
    })
  }


  toggleFilterFlyout(event?: any) {
    // If the event is undefined, then the user clicked the submit button. Otherwise, the user clicked the close button.
    if (event === undefined) {
    } else {
      this.showFilterChange.emit(this.showFilter);
    }
  }

  onHide() {
    setTimeout(() => this.showFilter = false, 200);
  }

  applyUrlFilterToFlyout() {
    // Scan through request object for populated fields and find matching tree nodes to mark as selected
    if (this.urlParamFilters) {
      this.clearFilters(false);  // Clear out filters, because we are setting them below.
      Object.getOwnPropertyNames(this.urlParamFilters).forEach(propName => {
        const arrayValues = Array.from(this.urlParamFilters[propName]);
        const results = this.treeControl.dataNodes.filter(n => n.parentAttribute === propName && !n.expandable  && arrayValues.includes(n.value));
        if (results.length > 0) {
          this.checklistSelection.select(...results);
        }

        this.zenFilterService.setFilterNodes(this.checklistSelection.selected);
        // Mark parent nodes as selected where appropriate.
        this.checklistSelection.select(...this.treeControl.dataNodes.filter(x => x.expandable && x.isCheckableParent && this.descendantsAllSelected(x)));

      });
      this.setFutureSelectedByDefault();
      this.setActiveSelectedByDefault();
      this.setRenewableStatusByDefault();
    }
  }

  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
  }


  nodeToggle(node: TreeItemFlatNode) {
    this.treeControl.toggle(node);
    //  When we expand the meter status node, we want to auto expand its 2 direct decendents so its a good experience
    if (node.level === 0 && node.parentAttribute === FilterParentAttribute.meterStatuses) {
      this.treeControl.dataNodes.filter(n => (n.parentAttribute === FilterParentAttribute.meterStatuses || n.parentAttribute === FilterParentAttribute.timeHorizons) && n.level === 1).forEach(tn => {
        this.treeControl.toggle(tn);
      })
    }
  }

  addIconToChips(nodes: TreeItemFlatNode[]): TreeItemFlatNode[] {
    return nodes.map(n => this.addIconToChip(n));
  }

  addIconToChip(node: TreeItemFlatNode): TreeItemFlatNode {
    //  By default we won't have a stacked icon
    node.showStackedIcon = false;
    switch (node.parentAttribute) {
      case FilterParentAttribute.customerIds:
        break;
      case FilterParentAttribute.utilityIds:
        node.icon = 'location_on';
        break;
      case FilterParentAttribute.rateCheckStates:
      case FilterParentAttribute.contractStates:
        node.icon = 'location_on';
        break;
      case FilterParentAttribute.states:
        break;
      case FilterParentAttribute.commodityTypes:
        if (node.item === 'Natural Gas') {
          node.icon = ZenIconsEnum.NAT_GAS;
        } else if (node.item === 'Electricity') {
          node.icon = ZenIconsEnum.ELECTRIC;
        }
        break;
      case FilterParentAttribute.mloaSignedStatus:
        node.icon = ZenIconsEnum.MLOA_SIGNED;
        break;
      case FilterParentAttribute.meterTypes:
        node.icon = ZenIconsEnum.METER;
        break;
      case FilterParentAttribute.customerTypes:
        node.icon = ZenIconsEnum.CUSTOMER;
        break;
      case FilterParentAttribute.serviceAddressTypes:
        node.icon = ZenIconsEnum.SERVICE_ADDRESS;
        break;
      case FilterParentAttribute.procurementStatuses:
        node.icon = ZenIconsEnum.VERIFIED;
        break;
      case FilterParentAttribute.meterStatuses:
        break;
      case FilterParentAttribute.timeHorizons:
        if (node.item === 'Today') {
          node.icon = ZenIconsEnum.CALENDAR;
        } else if (node.item === 'Future') {
          node.icon = ZenIconsEnum.FUTURE_CALENDAR;
        }
        break;
      case FilterParentAttribute.customerTags:
        node.icon = ZenIconsEnum.CUSTOMER;
        node.showStackedIcon = true;
        break;
      case FilterParentAttribute.lenTags:
        node.icon = ZenIconsEnum.LEN;
        node.showStackedIcon = true;
        break;
      case FilterParentAttribute.serviceAddressTags:
        node.icon = ZenIconsEnum.SERVICE_ADDRESS;
        node.showStackedIcon = true;
        break;
      case FilterParentAttribute.meterTags:
        node.icon = ZenIconsEnum.METER;
        node.showStackedIcon = true;
        break;
      case FilterParentAttribute.riskStatuses:
        node.icon = 'warning';
        break;
      case FilterParentAttribute.rateCheckStatuses:
      case FilterParentAttribute.contractStatuses:
        node.icon = ZenIconsEnum.CONTRACT_STATUS;
        break;
      case FilterParentAttribute.renewableStatuses:
        node.icon = ZenIconsEnum.GREEN;
    }
    return node;
  }
}

export class TreeItemNode {
  children: TreeItemNode[];
  item: string;
  value: any;
  disabled: boolean;
  parentAttribute: FilterParentAttribute;
  hierarchyLvlRequired: PortfolioHierarchyLevelEnum;
  isRadioButton: boolean;
  isCheckableParent: boolean;
  utilityState: string;
}

/** Flat to-do item node with expandable and level information */
export class TreeItemFlatNode {
  item: string;
  value: any;
  level: number;
  expandable: boolean;
  disabled: boolean;
  parentAttribute: FilterParentAttribute;
  hierarchyLvlRequired: PortfolioHierarchyLevelEnum;
  isRadioButton: boolean;
  isCheckableParent: boolean;
  icon: string;
  showStackedIcon: boolean;
  utilityState: string;
  chipChildren: TreeItemFlatNode[];
}

export enum FilterParentAttribute {
  activationStatuses = 'activationStatuses',
  customerIds = 'customerIds',
  utilityIds = 'utilityIds',
  states = 'states',
  commodityTypes = 'commodityTypes',
  mloaSignedStatus = 'mloaSignedStatus',
  meterTypes = 'meterTypes',
  customerTypes = 'customerTypes',
  serviceAddressTypes = 'serviceAddressTypes',
  procurementStatuses = 'procurementStatuses',
  meterStatuses = 'meterStatuses',
  timeHorizons = 'timeHorizons',
  customerTags = 'customerTags',
  lenTags = 'lenTags',
  serviceAddressTags = 'serviceAddressTags',
  meterTags = 'meterTags',
  riskStatuses = 'riskStatuses',
  contractStates = 'contractStates',
  contractStatuses = 'contractStatuses',
  renewableStatuses = 'renewableStatuses',
  rateCheckStates = 'rateCheckStates',
  rateCheckStatuses = 'rateCheckStatuses'
}
export enum RenewableStatuses {
  ALL = 'ALL',
  RENEWABLE = 'RENEWABLE',
  NON_RENEWABLE = 'NON_RENEWABLE'
}
