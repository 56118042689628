
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../authentication.service';
import {ObjectTag, ObjectType} from '../../../_models/object-tag';
import {Observable, from} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GenericResponseList} from '../../../_models/generic-response';
import {environment} from '../../../../../../../../environments';

export interface GetRequest {
  tagNames?: string[],
  objectTypes?: ObjectType[],
  objectIds?: string[],
  tagIds?: string[]
}

@Injectable()
export class ObjectTagService {

  private baseUrl = environment.apiBaseUrl;

  constructor(private authService: AuthenticationService,
              private http: HttpClient) {}

  public get(request?: GetRequest): Observable<ObjectTag[]> {
    let headers = this.authService.getAjaxHttpHeaders();
    if (request) {
        return from(this.http.post(this.baseUrl + '/v2/tags/get', request, {headers: headers}).pipe(
          map(response => (response as GenericResponseList<ObjectTag>).responseList)));
    } else {
      return from(this.http.post(this.baseUrl + '/v2/tags/get', null, {headers: headers}).pipe(
        map(response => (response as GenericResponseList<ObjectTag>).responseList)));
    }
  }

  public filter(tag: ObjectTag): Observable<ObjectTag[]> {
    let headers = this.authService.getAjaxHttpHeaders();
    return this.http.post(this.baseUrl + '/v2/tags/filter', tag, {headers: headers}).pipe(
      map(response => (response as GenericResponseList<ObjectTag>).responseList));
  }

  public post(tag: ObjectTag): Observable<ObjectTag> {
    tag.customerId = this.authService.getCurrentCustomerId();
    let headers = this.authService.getAjaxHttpHeaders();
    return this.http.post(this.baseUrl + '/v2/tags', tag, {headers: headers}).pipe(
      map(response => (response as ObjectTag)));
  }

  public put(tag: ObjectTag): Observable<ObjectTag> {
    let headers = this.authService.getAjaxHttpHeaders();
    return this.http.put(this.baseUrl + '/v2/tags', tag, {headers: headers}).pipe(
      map(response => (response as ObjectTag)));
  }

  public delete(tagId: string): Observable<boolean> {
    let headers = this.authService.getAjaxHttpHeaders();
    return this.http.delete(this.baseUrl + `/v2/tags?tagId=${tagId}`, {headers: headers}).pipe(
      map(() => {
        return true
      }));
  }

}
