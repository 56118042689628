import {EventEmitter, Injectable} from '@angular/core';
import {PortfolioSummaryService} from '../portfolio-summary.service';
import {PortfolioPageDataModel, PortfolioPageLoader} from '../../_model/portfolio-helper.model';
import {PortfolioCustomersService} from '../portfolio-customers.service';
import {UntypedFormControl} from '@angular/forms';
import {PfChartInputModel} from '../../../../_shared/_components/zen-chart/zen-chart.component';
import {PortfolioLensService} from '../portfolio-lens.service';
import {PortfolioMapperService} from '../_mappers/portfolio-mapper.service';
import {PortfolioMetersService} from '../portfolio-meters.service';
import {
  CustomerFilters, EnergyPlanEligibleMeterFilters, InitialPortfolioFilters,
  LegalEntityNameFilters,
  MeterFilters,
  PortfolioTableFilter,
  ServiceAddressFilters
} from '../../_model/portfolio-initial-filter.model';

import {SummarySnapShotModel} from '../../_model/portfolio-summary.model';
import {
  ActivationObjectType,
  ChangeStatusObjectType,
  DeleteObjectType,
  PortfolioSectionsEnum,
  PortfolioTabsEnum
} from '../../_enums/portfolio-tabs.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {PortfolioHierarchyService} from '../portfolio-hierarchy-service';
import {PfDetailsAfterDelete, PortfolioHierarchyLevelEnum} from '../../_enums/portfolio-hierarchy-level.enum';
import {PfMetersTableRowModel, UnprocurableMeterInfo} from '../../_model/portfolio-meters.model';
import {PfServiceAddressTableRowModel} from '../../_model/portfolio-service-addresses.model';
import {PfLensTableRowModel} from '../../_model/portfolio-lens.model';
import {ZenDialogMsgService} from '../../../../_shared/_services/zen-dialog-msg.service';
import {ImpactedObjects} from '../../../../_shared/_model/customer-v4.model';
import {ZenIconsEnum} from '../../../../_shared/_enums/zen-icons.enum';
import {PfCustomersTableRowModel} from '../../_model/portfolio-customers.model';
import {ZenErrorMsgEnum} from '../../../../_shared/_enums/zen-error-msg.enum';
import {InitialFiltersService} from './initial-filters.service';
import {ZenMatTableSelectSearchConfig} from '../../../../_shared/_components/zen-mat-table/zen-mat-table.component';
import {ZenMatTableHelperService} from '../../../../_shared/_services/helpers/zen-mat-table-helper.service';
import {orderBy} from '../../../../_shared/_zen-legacy-common/_utils/orderby.utils';
import {MatTableDefaultSearchField} from '../../../../_shared/_enums/zen-mat-table.enum';
import {AuthenticationService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {NavigationService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/navigation.service';
import moment from 'moment/moment';
import {CacheService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/cache.service';
import {ZenDialogComponent, ZenDialogDataModel, ZenDialogDataType} from '../../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {ZenDialogSizeEnum} from '../../../../_shared/_enums/zen-dialogs.enum';
import {MatDialog} from '@angular/material/dialog';
import {map, takeUntil} from 'rxjs/operators';
import {PageableModel} from '../../_model/pageable.model';
import {RateCheckFilterService} from './rate-check-filter.service';
import {DashboardFilterService} from './dashboard-filter.service';
import {PortfolioFilterService} from './portfolio-filter.service';
import {ContractFilterService} from './contract-filter.service';
import {StackRankingFilterService} from './stack-ranking-filter-service';
import {FilterParentAttribute} from '../../../../_shared/_components/portfolio-filter-flyout/portfolio-filter-flyout.component';
import {capitalizeEachFirstLetter} from '../../../../_shared/_zen-legacy-common/_utils/format-utils';
import {Subject, Subscription} from 'rxjs';
import {PortfolioServiceAddressService} from '../portfolio-service-address.service';


const DATETIME_FORMAT = 'MM/DD/YYYY hh:mm A';

export const FILTER_ICON_HTML = `<i class="material-symbols-rounded color-icon p-2 large mx-2 vertical-align-middle">filter_alt</i>`;

@Injectable({
  providedIn: 'root'
})
export class PortfolioHelperService {
  static outsideRiskWindowDays = new UntypedFormControl(180); // making static to access from other components with circular dependencies

  onSnapshotLoad: EventEmitter<SummarySnapShotModel> = new EventEmitter<SummarySnapShotModel>();
  pfPageData: PortfolioPageDataModel = {};
  /* Filter param for Contract Countdown */
  /* To flow independent loader w.r.t the components. */
  loading: boolean;
  loader: PortfolioPageLoader = {};
  errors: PortfolioPageLoader = {};
  initialLoading: boolean;

  // Portfolio table select filter config
  pfTableFilters: PortfolioTableFilter;

  show = {
    customers: true,
    lens: true,
    serviceAddresses: true,
    meters: true
  }
  filterSubscription: Subscription;
  private readonly destroy$ = new Subject<void>();

  constructor(private pfSummarySvc: PortfolioSummaryService, private pfLensSvc: PortfolioLensService,
              private pfCustomerSvc: PortfolioCustomersService, private pfMapperSvc: PortfolioMapperService,
              private pfServiceAddressService: PortfolioServiceAddressService, private pfMetersSvc: PortfolioMetersService,
              protected router: Router,  protected route: ActivatedRoute, private pfHierarchySvc: PortfolioHierarchyService,
              private zenDialogSvc: ZenDialogMsgService, private pfFiltersSvc: InitialFiltersService,
              protected zenMatTableHelperSvc: ZenMatTableHelperService, protected authSvc: AuthenticationService,
              private navSvc: NavigationService, private cacheSvc: CacheService, private dialog: MatDialog,
              private rcFilterService: RateCheckFilterService,
              private dbFilterService: DashboardFilterService,
              private contractFilterService: ContractFilterService,
              private portfolioFilterService: PortfolioFilterService,
              private stackRankingFilterService: StackRankingFilterService
              ) {
  }

  init() {
    this.resetHierarchyIds();
    this.subscribeToFilterChanges();
  }
  /**
   * Refresh the charts when filters are changed
   */
  subscribeToFilterChanges() {
    // Unsubscribe from the previous subscription if it exists
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }

    // Create a new subscription
    this.filterSubscription = this.pfFiltersSvc.emitFilters.subscribe((filters: InitialPortfolioFilters) => {
      if (this.pfPageData?.meterStatusChartData?.today) {
        this.pfPageData.meterStatusChartData.today = {
          ...this.pfPageData.meterStatusChartData.today,
          legends: this.pfPageData.meterStatusChartData.today.legends.map(x => ({
            ...x,
            disabled: filters?.meterStatuses?.find(y => y.label === x.label)?.disabled
          }))
        };
      }

      if (this.pfPageData?.meterStatusChartData?.future) {
        this.pfPageData.meterStatusChartData.future = {
          ...this.pfPageData.meterStatusChartData.future,
          legends: this.pfPageData.meterStatusChartData.future.legends.map(x => ({
            ...x,
            disabled: filters?.futureMeterStatuses?.find(y => y.label === x.label)?.disabled
          }))
        };
      }

      if (this.pfPageData?.contractCountdownChartData) {
        this.pfPageData.contractCountdownChartData = {
          ...this.pfPageData.contractCountdownChartData,
          legends: this.pfPageData.contractCountdownChartData.legends.map(x => ({
            ...x,
            disabled: filters?.riskStatuses?.find(y => y.label === x.label)?.disabled
          }))
        };
      }
    });
  }


  reset() {
    this.resetHierarchyIds();
    this.resetChartData();
    this.resetSearchConfigs();
    this.portfolioFilterService.hierarchyLevel = null;
    this.errors = {};
    this.loading = false;

    this.destroy$.next();
    this.destroy$.complete();
  }

  resetHierarchyIds() {
    this.portfolioFilterService.hierarchyIds = {customerId: null, lenId: null, serviceAddressId: null, meterId: null};
  }

  /**
   * Revert snapshot cards (Usage, Contract Countdown, Today's Status, Future Status) to empty
   */
  resetChartData() {
    this.pfPageData = {
      snapshot: null,
      meterStatus: null,
      meterStatusChartData: null,
      contractCountdown: null,
      contractCountdownChartData: null,
      usage: null
    }
  }

  resetSearchConfigs() {
    this.portfolioFilterService.pfCustomerSelectSearchConfig = {selectOptions: [], searchTypeCtrl: new UntypedFormControl(null), inputCtrl: new UntypedFormControl(null)};
    this.portfolioFilterService.pfLenSelectSearchConfig = {selectOptions: [], searchTypeCtrl: new UntypedFormControl(null), inputCtrl: new UntypedFormControl(null)};
    this.portfolioFilterService.pfServiceAddressSelectSearchConfig = {selectOptions: [], searchTypeCtrl: new UntypedFormControl(null), inputCtrl: new UntypedFormControl(null)};
    this.portfolioFilterService.pfMeterSelectSearchConfig = {selectOptions: [], searchTypeCtrl: new UntypedFormControl(null), inputCtrl: new UntypedFormControl(null)};
  }

  loadAllData(customerId?: number, lenId?: string, serviceAddressId?: number, hierarchyLevel?: PortfolioHierarchyLevelEnum, isActive?: boolean, refreshTables?: boolean) {
    this.portfolioFilterService.hierarchyLevel = hierarchyLevel;
    customerId = this.portfolioFilterService.hierarchyIds.customerId;
    lenId = this.portfolioFilterService.hierarchyIds.lenId;
    serviceAddressId = this.portfolioFilterService.hierarchyIds.serviceAddressId;

    if (this.portfolioFilterService.currentFilters) {
      this.portfolioFilterService.currentFilters.riskWindowDays = PortfolioHelperService.outsideRiskWindowDays.value; // set value in service to the selected value
      this.pfFiltersSvc.refreshFilters.next(true); // refresh the filters
    }

    // Get table filters
    this.getTableFilters();
    // Update selectedTab if appropriate
    this.updateSelectedTab(hierarchyLevel);
    this.loadChartData(customerId, lenId, serviceAddressId);
    if (refreshTables) {
      this.refreshAllTables();
    }
  }

  /**
   * Whatever screen you're on, refresh the appropriate charts + tables
   */
  refreshCurrentPortfolioPage(customerId: number, lenId: string, serviceAddressId: number) {
    switch (this.portfolioFilterService.hierarchyLevel) {
      case PortfolioHierarchyLevelEnum.PORTFOLIO:
        this.loadAllData(null, null, null, this.portfolioFilterService.hierarchyLevel, true, true);
        break;
      case PortfolioHierarchyLevelEnum.CUSTOMERS:
        this.loadAllData(customerId, null, null, this.portfolioFilterService.hierarchyLevel, true, true);
        break;
      case PortfolioHierarchyLevelEnum.LENS:
        this.loadAllData(customerId, lenId, null, this.portfolioFilterService.hierarchyLevel, true, true);
        break;
      case PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES:
        // ServiceAddress details page
        this.loadAllData(customerId, lenId, serviceAddressId, this.portfolioFilterService.hierarchyLevel, true, true);
        break;
      default: // For meters, there's nothing to refresh except expansion details which will be handled by PortfolioMetersHelperSvc
        break;
    }
  }

  //  If we somehow have a selected tab that exceeds hierarchy, load to top tab for that hierarchy instead
  updateSelectedTab(hierarchyLevel: PortfolioHierarchyLevelEnum) {
    // If no hierarchy, assume portfolio level and return customer tab
    if (hierarchyLevel == null) {
      return PortfolioTabsEnum.CUSTOMERS;
    }

    // If selectedTab is already set, leave it
    if (this.portfolioFilterService.selectedTab == null) {
      switch (hierarchyLevel) {
        case PortfolioHierarchyLevelEnum.PORTFOLIO:
          break;
        case PortfolioHierarchyLevelEnum.CUSTOMERS:
          this.portfolioFilterService.selectedTab = PortfolioTabsEnum.LENS;
          break;
        case PortfolioHierarchyLevelEnum.LENS:
          this.portfolioFilterService.selectedTab = PortfolioTabsEnum.SERVICE_ADDRESSES;
          break;
        case PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES:
          this.portfolioFilterService.selectedTab = PortfolioTabsEnum.METERS;
          break;
        case PortfolioHierarchyLevelEnum.METERS:
          break;
      }
    }
  }

  reloadChartData(customerId?: number, lenId?: string, serviceAddressId?: number) {
    switch (this.portfolioFilterService.hierarchyLevel) {
      case PortfolioHierarchyLevelEnum.PORTFOLIO:
        this.loadChartData(); // init
        break;
      case PortfolioHierarchyLevelEnum.CUSTOMERS:
        this.loadChartData(customerId);
        break;
      case PortfolioHierarchyLevelEnum.LENS:
        this.loadChartData(customerId, lenId);
        break;
      case PortfolioHierarchyLevelEnum.SERVICE_ADDRESSES:
        this.loadChartData(customerId, null, serviceAddressId);
        break;
    }
  }

  loadChartData(customerId?: number, lenId?: string, serviceAddressId?: number) {
    this.initSummarySnapshot(customerId, lenId, serviceAddressId);
    this.initMeterStatus(customerId, lenId, serviceAddressId);
    this.initContractCountdown(customerId, lenId, serviceAddressId);
    this.initUsage(customerId, lenId, serviceAddressId, null);
  }

  initCustomersObservable(page?: number, size?: number, sortBy?: string, sortDir?: string) {
    return this.pfCustomerSvc.getCustomerPortfolio(page, size, sortBy, sortDir, this.portfolioFilterService.getFilterString(), PortfolioHelperService.outsideRiskWindowDays.value)
      .pipe(
        map(res => {
          return {
            content: this.pfMapperSvc.customerRowMappers(res.content).filter(
              (v, i, a) => a.findIndex(v2 => (v2.customerId === v.customerId)) === i),
            page: res.page,
            size: res.size,
            sort: res.sort,
            totalElements: res.totalElements,
            totalPages: res.totalPages
          } as PageableModel<PfCustomersTableRowModel>;
        })
      );
  }

  initSummarySnapshot(customerId?: number, lenId?: string, serviceAddressId?: number) {
    this.loader.snapshot = true;
    this.pfSummarySvc.getSummarySnapshot(this.portfolioFilterService.getFilterString(customerId, lenId, serviceAddressId, null), PortfolioHelperService.outsideRiskWindowDays.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: res => {
          this.pfPageData.snapshot = res;
          this.onSnapshotLoad.emit(res);
          this.loader.snapshot = false;
        }, error: () => this.loader.snapshot = false
      });
  }

  initUsage(customerId?: number, lenId?: string, serviceAddressId?: number, meterId?: number) {
    this.loader.usage = true;
    this.pfHierarchySvc.getHierarchyUsage(this.portfolioFilterService.getFilterString(customerId, lenId, serviceAddressId, meterId), PortfolioHelperService.outsideRiskWindowDays.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: usage => {
          this.pfPageData.usage = usage;
          this.loader.usage = false;
        }, error: () => this.loader.usage = false
      });
  }

  initMeterStatus(customerId?: number, lenId?: string, serviceAddressId?: number) {
    this.loader.meterStatus = true;
    this.pfSummarySvc.getMeterStatus(this.portfolioFilterService.getFilterString(customerId, lenId, serviceAddressId, null), PortfolioHelperService.outsideRiskWindowDays.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: res => {
          this.pfPageData.meterStatus = res;
          const todayChart: PfChartInputModel = this.pfMapperSvc.mapChartInputModel(res.today);
          const futureChart: PfChartInputModel = this.pfMapperSvc.mapChartInputModel(res.future);
          this.pfPageData.meterStatusChartData = {future: futureChart, today: todayChart};
          this.loader.meterStatus = false;
        }, error: e => {
          console.log('Error: getMeterStatus ', e);
          this.errors.meterStatus = true;
          this.loader.meterStatus = false;
        }
      });
  }




  initContractCountdown(customerId?: number, lenId?: string, serviceAddressId?: number) {
    this.loader.contractCountdown = true;
    this.pfSummarySvc.getContractCountdown(PortfolioHelperService.outsideRiskWindowDays.value, this.portfolioFilterService.getFilterString(customerId, lenId, serviceAddressId, null))
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: res => {
          this.pfPageData.contractCountdown = res;
          this.pfPageData.contractCountdownChartData = this.pfMapperSvc.contractCountdownChartMapper(res);
          this.loader.contractCountdown = false;
        }, error: e => {
          console.log('Error: getContractCountdown ', e);
          this.errors.contractCountdown = true;
          this.loader.contractCountdown = false;
        }
      });
  }

  initLensObservable(page?: number, size?: number, sortBy?: string, sortDir?: string, customerId?: number) {
    return this.pfLensSvc.getOrgLens(true, page, size, sortBy, sortDir, this.portfolioFilterService.getFilterString(customerId), PortfolioHelperService.outsideRiskWindowDays.value)
      .pipe(
        map(res => {
          return {
            content: this.pfMapperSvc.lenRowMappers(res.content)
              .filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
            page: res.page,
            size: res.size,
            sort: res.sort,
            totalElements: res.totalElements,
            totalPages: res.totalPages
          } as PageableModel<PfLensTableRowModel>;
        })
      )
  }

  initServiceAddressesObservable(page?: number, size?: number, sortBy?: string, sortDir?: string, customerId?: number, lenId?: string) {
    return this.pfServiceAddressService.getOrgServiceAddresses(true, page, size, sortBy, sortDir,
      this.portfolioFilterService.getFilterString(customerId, lenId), PortfolioHelperService.outsideRiskWindowDays.value)
      .pipe(
        map(res  => {
          return {
            content: this.pfMapperSvc.serviceAddressRowMappers(res.content).filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
            page: res.page,
            size: res.size,
            sort: res.sort,
            totalElements: res.totalElements,
            totalPages: res.totalPages
          } as PageableModel<PfServiceAddressTableRowModel>;
        })
      )
  }

  initMetersObservable(page?: number, size?: number, sortBy?: string, sortDir?: string, customerId?: number, lenId?: string,
                       serviceAddressId?: number) {
    return this.pfMetersSvc.getOrgMeters(true, page, size, sortBy, sortDir,
      this.portfolioFilterService.getFilterString(customerId, lenId, serviceAddressId), PortfolioHelperService.outsideRiskWindowDays.value)
      .pipe(
        map(res => {
          return {
            content: this.pfMapperSvc.meterRowMappers(res.content).filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
            page: res.page,
            size: res.size,
            sort: res.sort,
            totalElements: res.totalElements,
            totalPages: res.totalPages
          } as PageableModel<PfMetersTableRowModel>;
        })
      );
  }

  // Initial loading will never be false; only time it's false is if client-side search is enabled, which it's not
  refreshAllTables() {
    this.refreshTable(PortfolioTabsEnum.CUSTOMERS);
    this.refreshTable(PortfolioTabsEnum.LENS);
    this.refreshTable(PortfolioTabsEnum.SERVICE_ADDRESSES);
    this.refreshTable(PortfolioTabsEnum.METERS);
  }




  // All tabs row/multiple delete action - after submit handler
  handleAfterDelete(ids: any[], deleteTab: PortfolioTabsEnum, goBack = false,
                    customerId?: number, lenId?: string, serviceAddressId?: number) {
    switch (deleteTab) {
      case PortfolioTabsEnum.CUSTOMERS:
        this.refreshTable(PortfolioTabsEnum.CUSTOMERS);
        break;

      case PortfolioTabsEnum.LENS:
        this.refreshTable(PortfolioTabsEnum.LENS);
        this.refreshTable(PortfolioTabsEnum.CUSTOMERS);
        break;

      case PortfolioTabsEnum.SERVICE_ADDRESSES:
        this.refreshTable(PortfolioTabsEnum.SERVICE_ADDRESSES);
        this.refreshTable(PortfolioTabsEnum.LENS);
        this.refreshTable(PortfolioTabsEnum.CUSTOMERS);
        break;

      case PortfolioTabsEnum.METERS:
        this.refreshTable(PortfolioTabsEnum.METERS);
        this.refreshTable(PortfolioTabsEnum.SERVICE_ADDRESSES);
        this.refreshTable(PortfolioTabsEnum.CUSTOMERS);
        break;
    }
    this.reloadChartData(customerId, lenId, serviceAddressId);
    this.zenDialogSvc.openToast(true);
    // After performing delete from the details page, we need go back to previous hierarchy page.
    // (e.g. I delete a meter from the meter’s details page and I’m sent to that meter’s serviceAddress details page)
    if (goBack) {
      switch (deleteTab) {
        case PortfolioTabsEnum.CUSTOMERS:
          let queryParams = {selectedTab: deleteTab, tabIndex: PfDetailsAfterDelete[deleteTab.toUpperCase()]};
          this.router.navigate(this.navSvc.getPortfolioPageRoute(), {queryParams});
          break;
        case PortfolioTabsEnum.LENS:
          this.handleCustomerView({customerId} as PfCustomersTableRowModel);
          break;
        case PortfolioTabsEnum.SERVICE_ADDRESSES:
          this.handleViewLen({customerId, id: lenId} as PfLensTableRowModel);
          break;
        case PortfolioTabsEnum.METERS:
          this.handleViewServiceAddress({customerId, id: serviceAddressId} as PfServiceAddressTableRowModel);
          break;
      }
    }
  }

  getDeleteBodyText(rowData = null, selectedRows = [], objectType: DeleteObjectType,
                    objectName: string, impactedObjects: ImpactedObjects): string {
    impactedObjects = this.handleImpactedCount(impactedObjects, objectType);
    let bodyHtml;
    let {lenCount, serviceAddressCount, meterCount, billCount} = impactedObjects;
    let labels = [DeleteObjectType.LEN, DeleteObjectType.SERVICE_ADDRESS, DeleteObjectType.METER, DeleteObjectType.BILL];
    const counts = [lenCount, serviceAddressCount, meterCount, billCount];
    const icons = [ZenIconsEnum.LEN, ZenIconsEnum.SERVICE_ADDRESS, ZenIconsEnum.METER, ZenIconsEnum.BILL];
    let affiliatedText = counts.map((count: any, index) =>
      `<tr class="d-flex align-items-center">
          <td class="td-icon"><i class="material-symbols-rounded">${icons[index]}</i></td>
          <td class="td-title">
            ${this.handleDeleteObjectPlural(labels[index], count)}
          </td>
          <td class="td-count">${count || 0}</td>
        </tr>
`).filter((c, i) => counts[i]);

    const _tableContent = ` <div class="d-flex justify-content-center my-2">
                      <table class="zen-confirm-tbl">
                        ${affiliatedText.join('')}
                      </table>
                </div>`;

    if (rowData) {
      // Doc - Item 28 - with child records -> At-least one LEN or ServiceAddress or Meter.
      if (lenCount || serviceAddressCount || meterCount || billCount) {
        bodyHtml = `<div>
                <div>You are about to permanently delete the ${objectType} <span class="font-weight-600">(${objectName})</span> from your platform as well as the affiliated data below:</div>
                ${_tableContent}
                <div>Are you sure you want to proceed?</div>
           </div>`;
      } else {
        // Doc - Item 27 - w/o child records
        bodyHtml = `<div>You are about to permanently delete the ${objectType} <span class="font-weight-600">(${objectName})</span> and all associated data from your platform.</div> <br> <div>Are you sure you want to proceed?</div>`;
      }
    }
    if (selectedRows && selectedRows.length) {
      const _length = selectedRows.length;
      const _hasChildRecords = selectedRows.some(r => r.serviceAddressCount || r.lenCount || r.meterCount || r.billCount);

      if (_hasChildRecords) {
        bodyHtml = `<div>
                <div>You are about to permanently delete <span class="font-weight-600">${_length} ${this.handleDeleteObjectPlural(objectType, _length)}</span> from your platform as well as the affiliated data below:</div>
                ${_tableContent}
                <div>Are you sure you want to proceed?</div>
           </div>`;
      } else {
        bodyHtml = `<div>   You are about to permanently delete <span class="font-weight-600">${_length} ${this.handleDeleteObjectPlural(objectType, _length)}</span>, and all associated data, from your platform.</div><br/><div>Are you sure you want to proceed?</div>`;
      }
    }
    return bodyHtml;
  }

  // Setting parent count as 0, to skip that value from the confirmation(affiliatedText) text.
  handleImpactedCount(impactedObjects: ImpactedObjects, type?: DeleteObjectType): ImpactedObjects {
    switch (type) {
      case DeleteObjectType.CUSTOMER:
        impactedObjects.customerCount = 0;
        break;
      case DeleteObjectType.LEN:
        impactedObjects.lenCount = 0;
        break;
      case DeleteObjectType.SERVICE_ADDRESS:
        impactedObjects.serviceAddressCount = 0;
        break;
      case DeleteObjectType.METER:
        impactedObjects.meterCount = 0;
        break;
      case DeleteObjectType.BILL:
        impactedObjects.billCount = 0;
        break;
    }
    return impactedObjects;
  }

  handleDeleteObjectPlural(type: DeleteObjectType, count: number): string {
    switch (type) {
      case DeleteObjectType.SERVICE_ADDRESS:
        return count === 1 ? DeleteObjectType.SERVICE_ADDRESS : 'service addresses';
        break;
      case DeleteObjectType.CUSTOMER:
      case DeleteObjectType.LEN:
      case DeleteObjectType.METER:
      case DeleteObjectType.BILL:
        return count === 1 ? type : type + 's';
        break;
    }
  }

  checkSelectedRowsHierarchy(tab: PortfolioTabsEnum, selectedRows): boolean {
    switch (tab) {
      case PortfolioTabsEnum.LENS:
        selectedRows = selectedRows as PfLensTableRowModel[];
        const customerIds = new Set(selectedRows.map((len: PfLensTableRowModel) => len.customerId));
        return customerIds.size === 1;
      case PortfolioTabsEnum.SERVICE_ADDRESSES:
        selectedRows = selectedRows as PfServiceAddressTableRowModel[];
        const lenIds = new Set(selectedRows.map((sa: PfServiceAddressTableRowModel) => sa.lenId));
        return lenIds.size === 1;
      case PortfolioTabsEnum.METERS:
        selectedRows = selectedRows as PfMetersTableRowModel[];
        const serviceAddressIds = new Set(selectedRows.map((meter: PfMetersTableRowModel) => meter.serviceAddressId));
        return serviceAddressIds.size === 1;
    }
  }

  getDeactivateBodyText(rowAction: boolean, selectedRows = [], objectType: ChangeStatusObjectType, proceedText = true): string {
    let bodyHtml;
    if (rowAction) {
      // Doc - Item 5 - Deactivate Row Action Customers, LENs, and Service Addresses
      bodyHtml = `<div>
                <div>You are about to <b>deactivate</b> this ${objectType}. Any underlying records associated with your selections will also be <b>deactivated</b>.</div>
                ${proceedText ? `<br><br> <div>Are you sure you want to proceed?</div>` : ''}
           </div>`;
    }
    if (selectedRows && selectedRows.length) {
      const _length = selectedRows.length;
      // Doc - Item 1 - Deactivate Table Action Customers, LENs, and Service Addresses
      bodyHtml = `<div>
                <div>You are about to <b>deactivate</b> ${_length} ${this.handleChangeStatusObjectPlural(objectType, _length)}. Any underlying records associated with your selections will also be <b>deactivated</b>.</div>
                ${proceedText ? `<br><br> <div>Are you sure you want to proceed?</div>` : ''}
           </div>`;
    }
    return bodyHtml;
  }

  getDeactivateSuccessBodyText(selectedRows = [], objectType: ChangeStatusObjectType): string {
    let bodyHtml;
    if (selectedRows && selectedRows.length) {
      const _length = selectedRows.length;
      // Doc - Item 2 - Deactivate Table Action Success (Will also be used on Row Actions)
      bodyHtml = `<div>
                <div>Your ${this.handleChangeStatusObjectPlural(objectType, _length)} ${this.pluralVerb(_length)} now inactive and hidden from view. To view and manage inactive records, click ${FILTER_ICON_HTML} at the top of the page and change Activation Status to “inactive”. </div>
           </div>`;
    }
    return bodyHtml;
  }

  getReactivateBodyText(rowAction: boolean, selectedRows = [], objectType: ChangeStatusObjectType): string {
    let bodyHtml;
    if (rowAction) {
      // Doc - Item 6 - Reactivate Row Action Customers, LENs, and Service Addresses
      bodyHtml = `<div>
                <div>You are about to <b>reactivate</b> this ${objectType}. ${this.getRestOfReactivationText(selectedRows)}</div>
                <br><br>
                <div>Are you sure you want to proceed?</div>
           </div>`;
    }
    if (selectedRows && selectedRows.length) {
      const _length = selectedRows.length;
      // Doc - Item 3 - Reactivate Table Action Customers, LENs, and Service Addresses
      bodyHtml = `<div>
                <div>You are about to <b>reactivate</b> ${_length} ${this.handleChangeStatusObjectPlural(objectType, _length)}. ${this.getRestOfReactivationText(selectedRows)}</div>
                <br><br>
                <div>Are you sure you want to proceed?</div>
           </div>`;
    }
    return bodyHtml;
  }

  getReactivateSuccessBodyText(selectedRows = [], objectType: ChangeStatusObjectType): string {
    let bodyHtml;
    if (selectedRows && selectedRows.length) {
      const _length = selectedRows.length;
      // Doc - Item 4 - Reactivate Table Action Success (Will also be used on Row Actions)
      bodyHtml = `<div>
                <div>Your ${this.handleChangeStatusObjectPlural(objectType, _length)} ${this.pluralVerb(_length)} now active but still hidden from view. To view and manage active records, click ${FILTER_ICON_HTML} at the top of the page and change the Activation Status to “active”.</div>
           </div>`;
    }
    return bodyHtml;
  }

  handleChangeStatusObjectPlural(type: ChangeStatusObjectType, count: number): string {
    switch (type) {
      case ChangeStatusObjectType.SERVICE_ADDRESS:
        return count === 1 ? ChangeStatusObjectType.SERVICE_ADDRESS : 'service addresses';
        break;
      case ChangeStatusObjectType.CUSTOMER:
      case ChangeStatusObjectType.LEN:
      case ChangeStatusObjectType.METER:
        return count === 1 ? type : type + 's';
        break;
    }
  }

  pluralVerb(count: number): string {
    return count === 1 ? 'is' : 'are';
  }

  getRestOfReactivationText(selectedRows = []): string {
    return selectedRows.length === 1 && selectedRows[0].activationAuditDt ?
      'The platform will also <b>reactivate</b> the underlying records previously deactivated on ' + moment(selectedRows[0].activationAuditDt).format(DATETIME_FORMAT) + '.' :
      'The platform will also <b>reactivate</b> the underlying records.';
  }

  getMeterDeactivateBodyText(rowAction: boolean, selectedRows = []): string {
    let bodyHtml;
    if (rowAction) {
      // Doc - Item 8 - Deactivate Row Action Meters
      bodyHtml = `<div>
                <div>You are about to <b>deactivate</b> this meter which will make it ineligible for procurement.</div>
                <br>
                <div>Are you sure you want to proceed?</div>
           </div>`;
    }
    if (selectedRows && selectedRows.length) {
      const _length = selectedRows.length;
      // Doc - Item 7 - Deactivate Table Action Meters
      bodyHtml = `<div>
                <div>You are about to <b>deactivate</b> ${_length} ${this.handleMeterPlural(_length)} which will make them ineligible for procurement.</div>
                <br>
                <div>Are you sure you want to proceed?</div>
           </div>`;
    }
    return bodyHtml;
  }

  getMeterReactivateBodyText(rowAction: boolean, selectedRows = []): string {
    let bodyHtml;
    if (rowAction) {
      // Doc - Item 34 - Reactivate Row Action Meters
      bodyHtml = `<div>
                <div>You are about to <b>reactivate</b> this meter.</div>
                <br>
                <div>Are you sure you want to proceed?</div>
           </div>`;
    }
    if (selectedRows && selectedRows.length) {
      const _length = selectedRows.length;
      // Doc - Item 33 - Reactivate Table Action Meters
      bodyHtml = `<div>
                <div>You are about to <b>reactivate</b> ${_length} ${this.handleMeterPlural(_length)}.</div>
                <br>
                <div>Are you sure you want to proceed?</div>
           </div>`;
    }
    return bodyHtml;
  }

  handleMeterPlural(count: number): string {
    return count > 1 ? 'meters' : 'meter';
  }

  handleAfterChangeStatus(ids?: any[], changeStatusTab?: PortfolioTabsEnum,
                          customerId?: number, lenId?: string, serviceAddressId?: number) {
    this.refreshCurrentPortfolioPage(customerId, lenId, serviceAddressId);
    this.zenDialogSvc.openToast(true);
  }

  openSuccessGuidance(bodyHtml: string) {
    const dontShow = this.cacheSvc.getValue(ZenDialogDataType.GUIDANCE_SUCCESS, null, ZenDialogDataType.GUIDANCE_SUCCESS);
    if (dontShow) {
      this.zenDialogSvc.openToast(true);
    } else {
      let dialogData: ZenDialogDataModel = {
        type: ZenDialogDataType.GUIDANCE_SUCCESS,
        bodyHtml: bodyHtml,
        header: {title: 'Success'},
        onClose: () => dialogRef.close(),
        actionButtons: [
          {
            label: `Don't show again`, command: () => {
              dialogRef.close();
              /* When a user click don't show again - for the future actions zen-toast will appear instead of this popup. */
              this.cacheSvc.addValue(ZenDialogDataType.GUIDANCE_SUCCESS, null, true,
                ZenDialogDataType.GUIDANCE_SUCCESS, moment().add(1, 'day').toDate());
            }
          }
        ]
      };
      const dialogRef = this.dialog.open(ZenDialogComponent, {
        width: ZenDialogSizeEnum.SMALL,
        data: dialogData, autoFocus: false
      });
    }
  }

  getReactivateErrorBodyText(rowAction: boolean, childObjectType: ChangeStatusObjectType, parentObjectType: ActivationObjectType): string {
    let bodyText;
    if (rowAction) {
      // Doc - Item 11 - Reactivate Row Action Error
      bodyText = `This ${childObjectType} is tied to an inactive ${parentObjectType} and can therefore not be activated at this time. To proceed, reactivate the ${parentObjectType}.`;
    } else {
      // Doc - Item 10 - Reactivate Table Action Error
      bodyText = `At least one ${childObjectType} is tied to an inactive ${parentObjectType} and can therefore not be activated at this time. To proceed, check the status of each affiliated ${parentObjectType} and reactivate as needed.`;
    }
    return bodyText;
  }

  handleCustomerView(rowData: PfCustomersTableRowModel, selectedSection?: PortfolioSectionsEnum, selectedTab?: PortfolioTabsEnum, tabIndex?: number) {
    this.resetAllFilters();
    selectedSection = selectedSection || (this.authSvc.isAdvisor() ? PortfolioSectionsEnum.PORTFOLIO : PortfolioSectionsEnum.DASHBOARD);
    this.loader.customers = true;
    this.router.navigate(this.navSvc.getPortfolioCustomerDetailsPageRoute(rowData.customerId), {queryParams: {selectedSection}}).then(() => {
      // If provided, set the selected tab and tab index. Otherwise, use the default values.
      this.portfolioFilterService.selectedTab = selectedTab ?? PortfolioTabsEnum.LENS;
      this.portfolioFilterService.selectedTabIndex = tabIndex ?? 0;
      this.portfolioFilterService.selectedSection = selectedSection ?? PortfolioSectionsEnum.DASHBOARD;
      this.portfolioFilterService.updateUrlState(selectedSection ?? PortfolioSectionsEnum.DASHBOARD, this.portfolioFilterService.selectedTab, this.portfolioFilterService.selectedTabIndex);
      setTimeout(() => this.loader.customers = false);
    });
  }

  handleCustomerViewInNewTab(rowData: PfCustomersTableRowModel, selectedSection?: PortfolioSectionsEnum) {
    selectedSection = selectedSection || (this.authSvc.isAdvisor() ? PortfolioSectionsEnum.PORTFOLIO : PortfolioSectionsEnum.DASHBOARD);
    window.open(`${this.navSvc.toRouterLink(this.navSvc.getPortfolioCustomerDetailsPageRoute(rowData.customerId))}?selectedSection=${selectedSection}`);
  }

  handlePortfolioView() {
    // Clear out nodes only if we are not at the top level component.
    if (this.navSvc.isCustomerLevelForAdmin()) {
      this.resetAllFilters();
    }
    this.router.navigate(this.navSvc.getPortfolioPageRoute()).then(() => {
      this.portfolioFilterService.selectedTab = PortfolioTabsEnum.CUSTOMERS;
      this.portfolioFilterService.selectedTabIndex = 0;
      this.portfolioFilterService.updateUrlState(PortfolioSectionsEnum.PORTFOLIO, this.portfolioFilterService.selectedTab, this.portfolioFilterService.selectedTabIndex);
    });
  }


  handleViewLen(rowData: PfLensTableRowModel, selectedSection?: PortfolioSectionsEnum, selectedTab?: PortfolioTabsEnum, tabIndex?: number) {
    this.resetAllFilters();
    this.loader.lens = true;
    this.router.navigate(this.navSvc.getPortfolioLenDetailsPageRoute(rowData.customerId, rowData.id)).then(() => {
      // If provided, set the selected tab and tab index. Otherwise, use the default values.
      this.portfolioFilterService.selectedTab = selectedTab ?? PortfolioTabsEnum.SERVICE_ADDRESSES;
      this.portfolioFilterService.selectedTabIndex = tabIndex ?? 0;
      this.portfolioFilterService.updateUrlState(selectedSection ?? PortfolioSectionsEnum.PORTFOLIO, this.portfolioFilterService.selectedTab, this.portfolioFilterService.selectedTabIndex);
      setTimeout(() => this.loader.lens = false);
    });
  }

  handleViewLenInNewTab(rowData: PfLensTableRowModel, selectedSection = PortfolioSectionsEnum.PORTFOLIO) {
    window.open(`${this.navSvc.toRouterLink(this.navSvc.getPortfolioLenDetailsPageRoute(rowData.customerId, rowData.id))}?selectedSection=${selectedSection}`);
  }

  handleViewServiceAddress(rowData: PfServiceAddressTableRowModel, selectedSection?: PortfolioSectionsEnum, selectedTab?: PortfolioTabsEnum, tabIndex?: number) {
    this.resetAllFilters();
    this.loader.serviceAddresses = true;
    this.router.navigate(this.navSvc.getPortfolioServiceAddressDetailsPageRoute(rowData.customerId, rowData.id)).then(() => {
      // If provided, set the selected tab and tab index. Otherwise, use the default values.
      this.portfolioFilterService.selectedTab = selectedTab ?? PortfolioTabsEnum.METERS;
      this.portfolioFilterService.selectedTabIndex = tabIndex ?? 0;
      this.portfolioFilterService.updateUrlState(selectedSection ?? PortfolioSectionsEnum.PORTFOLIO, this.portfolioFilterService.selectedTab, this.portfolioFilterService.selectedTabIndex);
      setTimeout(() => this.loader.serviceAddresses = false);
    });
  }

  handleViewMeter(rowData: PfMetersTableRowModel, selectedSection?: PortfolioSectionsEnum, selectedTab?: PortfolioTabsEnum, tabIndex?: number) {
    this.resetAllFilters();
    this.loader.meters = true;
    this.router.navigate(this.navSvc.getPortfolioMeterDetailsPageRoute(rowData.customerId, rowData.serviceAddressId, rowData.id)).then(() => {
      // If provided, set the selected tab and tab index. Otherwise, use the default values.
      this.portfolioFilterService.selectedTab = selectedTab ?? PortfolioTabsEnum.METERS;
      this.portfolioFilterService.selectedTabIndex = tabIndex ?? 0;
      this.portfolioFilterService.updateUrlState(selectedSection ?? PortfolioSectionsEnum.PORTFOLIO, this.portfolioFilterService.selectedTab, this.portfolioFilterService.selectedTabIndex);
      setTimeout(() => this.loader.meters = false);
    });
  }

  /** zen-mat-table select-search config starts here */
  /** Select search filters STARTS */
  getTableFilters() {
    this.pfFiltersSvc.getPortfolioTableFilters().subscribe({
      next: (filters) => {
        this.pfTableFilters = filters;
        // Set up search type config for all tab
        this.setSelectSearchConfig();
      }, error: e => {
        console.log('Error: Get table filters ', e);
        this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        this.zenDialogSvc.openToast(false);
      }
    });
  }

  /**
   * Sets up the search type selections for all four tables
   */
  setSelectSearchConfig() {
    this.setSearchConfig(this.pfTableFilters.customerFilters, this.portfolioFilterService.pfCustomerSelectSearchConfig, MatTableDefaultSearchField.CUSTOMER);
    this.setSearchConfig(this.pfTableFilters.legalEntityNameFilters, this.portfolioFilterService.pfLenSelectSearchConfig, MatTableDefaultSearchField.LEN);
    this.setSearchConfig(this.pfTableFilters.serviceAddressFilters, this.portfolioFilterService.pfServiceAddressSelectSearchConfig, MatTableDefaultSearchField.SERVICE_ADDRESS);
    this.setSearchConfig(this.pfTableFilters.meterFilters, this.portfolioFilterService.pfMeterSelectSearchConfig, MatTableDefaultSearchField.METER);
  }

  /**
   * Sets up the search type selections for a table
   * @param selectedFilters - The list of search type options for this table
   * @param searchConfig - The search config, depends on the table (customers, lens, serviceAddresses, meters)
   * @param defaultSearchField - Sets default search type
   */
  setSearchConfig(selectedFilters: CustomerFilters | LegalEntityNameFilters | ServiceAddressFilters | MeterFilters | EnergyPlanEligibleMeterFilters,
                  searchConfig: ZenMatTableSelectSearchConfig,
                  defaultSearchField: MatTableDefaultSearchField) {
    // Populate once, if the select options are already populated, don't do it again
    if (searchConfig.selectOptions?.length) {
      return;
    }
    const keys = Object.keys(selectedFilters);
    if (keys?.length) {
      searchConfig.selectOptions = [];
      keys.forEach(k => searchConfig.selectOptions.push({objectName: k, objectLabel: selectedFilters[k]}));
      searchConfig.selectOptions = orderBy(searchConfig.selectOptions, 'objectLabel');
      // Setting default select option value
      searchConfig.searchTypeCtrl.setValue(searchConfig.selectOptions.find(op => op.objectName === defaultSearchField));
    }
  }

  /**
   * Use this setTimeout toggles to force a component refresh of the mat-table, which will trigger Akita's paginator
   * to pull from API again
   */
  refreshTable(level: PortfolioTabsEnum) {
    switch (level) {
      case PortfolioTabsEnum.CUSTOMERS:
        setTimeout(() => this.show.customers = false);
        setTimeout(() => this.show.customers = true);
        break;
      case PortfolioTabsEnum.LENS:
        setTimeout(() => this.show.lens = false);
        setTimeout(() => this.show.lens = true);
        break;
      case PortfolioTabsEnum.SERVICE_ADDRESSES:
        setTimeout(() => this.show.serviceAddresses = false);
        setTimeout(() => this.show.serviceAddresses = true);
        break;
      case PortfolioTabsEnum.METERS:
        setTimeout(() => this.show.meters = false);
        setTimeout(() => this.show.meters = true);
        break;
    }
  }

  /**
   * Gets the download name of portfolio tables
   */
  getTableDownloadFileName(baseName: string): string {
    let fileName = baseName;
    let hierarchyBaseLevel = PortfolioHierarchyLevelEnum.PORTFOLIO;
    if (this.authSvc.isCustomer()) {
      hierarchyBaseLevel = PortfolioHierarchyLevelEnum.CUSTOMERS;
    }
    if (this.portfolioFilterService.hierarchyLevel > hierarchyBaseLevel) {
      // Gets the enum name in lower case.
      fileName = `${PortfolioHierarchyLevelEnum[this.portfolioFilterService.hierarchyLevel].toLowerCase()}_` + fileName;
    }
    return fileName;
  }

  resetAllFilters() {
    this.portfolioFilterService.resetFiltersAndNodes();
    this.rcFilterService.resetFiltersAndNodes();
    this.dbFilterService.resetFiltersAndNodes();
    this.contractFilterService.resetFiltersAndNodes();
    this.stackRankingFilterService.resetFiltersAndNodes();
  }

  get noFilters(): Boolean {
    let _currentFilters = {...this.portfolioFilterService.currentFilters};
    delete _currentFilters?.riskWindowDays; // Removing default filter
    return Boolean(!_currentFilters || !Object.keys(_currentFilters).length);
  }

  handleDetailsEntityStatusUpdate(activeStatus: boolean) {
    if (this.portfolioFilterService?.currentFilters?.activationStatuses) {
      // Setting activationStatuses to support portfolio hierarchy table API calls
      this.portfolioFilterService.currentFilters.activationStatuses = [activeStatus] // update filters

      if (!activeStatus) { // when Inactive
        this.pfFiltersSvc.refreshFilters.next(true); // add inactive filter chip
        this.portfolioFilterService.emitChangeStatusEvent();
      } else {
        const i = this.portfolioFilterService.filterChipNodes.findIndex(cn => cn.parentAttribute === FilterParentAttribute.activationStatuses);
        if (i !== -1) {
          this.portfolioFilterService.filterChipNodes.splice(i, 1); // remove inactive filter chip
        }
        this.portfolioFilterService.emitChangeStatusEvent();
      }
    }
  }

  defaultStateTextTpl(addBtnAvailable: boolean, addEntity: ChangeStatusObjectType, parentEntity?: ChangeStatusObjectType) {
    const entityPlural = addEntity === ChangeStatusObjectType.SERVICE_ADDRESS ? 'service addresses' : `${addEntity}s`;
    if (addBtnAvailable) {
      return `<p class="text-md">Add, organize and manage your ${entityPlural} here.</p><p class="text-md">Add your first ${addEntity} to get started.</p>`;
    } else {
      return `<p class="text-md">Add your first ${addEntity} by selecting an existing or new ${parentEntity}. Click the "+ ${capitalizeEachFirstLetter(addEntity)}" button under the ${parentEntity} to create a new ${addEntity}. You can view all of your ${entityPlural} in this tab.</p>`;
    }
  }

}
