import {Component, Input, OnInit} from '@angular/core';
import {getCssVarValue} from '../../_zen-legacy-common/_utils/zen-utils';

export enum JenZenImgType {
  SMALL = 'small', LARGE = 'large', HAPPIEST = 'happiest', HAPPY = 'happy', OK = 'ok', SAD = 'sad'
}


@Component({
  selector: 'app-jen-zen-img',
  templateUrl: './jen-zen-img.component.html',
  styleUrls: ['./jen-zen-img.component.scss']
})
export class JenZenImgComponent implements OnInit {
  primaryColor = getCssVarValue('--primaryColor');
  @Input() imgType: JenZenImgType = JenZenImgType.SMALL;
  JenZenImgType = JenZenImgType;

  constructor() { }

  ngOnInit(): void {
  }

}
