import { NgModule, SkipSelf, Optional, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common';
import { SignupService } from './services/signup.service';
import { RateCheckRequestService } from './services/rate-check-request.service';
import { CustomerTiliService } from './services/customer-tili.service';
import { LenService } from './services/len.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

export class TiliServicesModule {

  static forRoot(): ModuleWithProviders<TiliServicesModule> {
    return {
      ngModule: TiliServicesModule,
      providers: [
        SignupService,
        DatePipe,
        DecimalPipe,
        CurrencyPipe,
        RateCheckRequestService,
        CustomerTiliService,
        LenService
      ]
    };
  }
  constructor(@Optional() @SkipSelf() parentModule?: TiliServicesModule) {
    if (parentModule) {
      throw new Error(
        'TiliServicesModule is already loaded. Import it in the AppModule only');
    }
  }

 }
