export enum ZenErrorMsgEnum {
  ERR_MSG_1_TITLE = 'Oops! That’s embarrassing...',
  ERR_MSG_1_TEXT = 'It appears something went wrong. The issue has been reported and we are working to resolve it.',
  ERR_MSG_2_TITLE = 'Oops...',
  ERR_FORM_FIELD = 'Please check form fields',
  COPY = 'Copied!',
  EMAIL_EXISTS = 'Email address is already in use. Please update to proceed.',
  PASSWORD_MATCH = 'Passwords do not match. Please update to proceed.',
  HIERARCHY_DELETE_ERROR = `You cannot delete records that span different hierarchies (e.g. meters that span two different service addresses).
           To proceed, limit your selections to objects within the same hierarchy.`,
// Doc - Item 9 - Hierarchy Status Change Error
  HIERARCHY_STATUS_CHANGE_ERROR = `You cannot change the status of records that span different hierarchies (e.g. meters that span two different service addresses).
           To proceed, limit your selections to records within the same hierarchy.`,
  CUSTOMER_HIERARCHY_STATUS_CHANGE_ERROR = `You cannot change the procurement status of meters that span different customers.
           To proceed, limit your selections to records within the same customer.`,

  BULK_IMPORT = 'Error uploading file! Please make sure you are uploading the correct file.',
  HIERARCHY_ON_UTILITY_STATUS_CHANGE_ERROR = `Unable to change status for meters spanning multiple hierarchies (different customers, service addresses, states, or commodities) or if the meter status is not Undefined.`,
  HIERARCHY_SERVICE_ADDRESS_CREATE_RATE_CHECK_ERROR = `Unable to create Rate Check from the selected service addresses spanning multiple hierarchies (different customers, states, or commodities) or if the service address status is inactive.`,
  HIERARCHY_METER_CREATE_RATE_CHECK_ERROR = `Unable to create Rate Check from the selected meters spanning multiple hierarchies (different customers, states, or commodities) or if the meter status is inactive.`
}
