import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {ThemeManagerService} from '../services/theme-manager.service';
import { ThemeStyles } from '../models/theme-styles';

@Directive({
  selector: '[zenTheme]',
  providers: [
    ThemeManagerService
  ]
})
export class ThemeDirective implements OnInit {

  @Input('zenTheme') zenTheme: ThemeStyles;

  constructor(private el: ElementRef, private manager: ThemeManagerService) {
  }
  ngOnInit(): void {

    this.manager.applyTheme(this.zenTheme);
  }

}
