<app-zen-dialog-wrapper [data]="dialogData" [errorMsg]="errorMsg" [disableButton]="loading" [hideButton]="true">
  <app-zen-loader *ngIf="loading"></app-zen-loader>
  <form>
    <div class="row">
      <div class="col-12" *ngIf="deletingContact.contractSignatoryLens?.length">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Contract Signatory</mat-label>
            <mat-select [formControl]="contractSignatory">
              <mat-option *ngFor="let contact of contactList" [value]="contact.id">
                {{contact.label}}
              </mat-option>
            </mat-select>
            <i class="material-symbols-rounded" matSuffix>expand_more</i>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12" *ngIf="deletingContact.billingContactLens?.length">
        <div class="form-group">
          <mat-form-field appearance="outline">
            <mat-label>Billing Contact</mat-label>
            <mat-select [formControl]="billingContact">
              <mat-option *ngFor="let contact of contactList" [value]="contact.id">
                {{contact.label}}
              </mat-option>
            </mat-select>
            <i class="material-symbols-rounded" matSuffix>expand_more</i>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="center">
      <button mat-button class="mat-button" type="button" (click)="dialogRef.close()">Cancel</button>
      <button mat-raised-button class="mat-button mat-primary" type="button"
              (click)="submit()" [disabled]="loading" color="primary">Confirm
      </button>
    </div>
  </form>
</app-zen-dialog-wrapper>
