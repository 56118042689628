<div class="loader-v3">
  <div class="loader-main">
    <div class="loader-section">
      <i class="material-symbols-outlined">cloud_download</i>
      <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="content-section">
      <h5>{{title}}</h5>
      <p>{{bodyText}}</p>
    </div>
  </div>
  <div class="slider-section">
    <div class="slider-left-img">
      <img src="/assets/img/did-you-know.png">
    </div>
    <div class="carousel-content">
      <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="slides">
        <div *nguCarouselDef="let slide" class="item">
          <div class="tile">
            <div class="slide">
              <p class="m-0 text-md">{{slide.text}}</p>
            </div>
          </div>
        </div>
      </ngu-carousel>
      <ul *ngIf="myCarousel?.pointNumbers" class="myPoint" NguCarouselPoint>
        <li *ngFor="let i of myCarousel.pointNumbers; let index = index" [class.active]="i==myCarousel.activePoint" (click)="myCarousel.moveTo(i)"
            [style.background]="'url(' + slides[i] + ')'"></li>
      </ul>
    </div>
  </div>

</div>
