import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private auth: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // return undefined;
    let request: HttpRequest<any> = req;
    if (!request.url.endsWith('/v1/authenticate')) {
      if (this.auth.isCurrentlyLoggedIn()) {
        request = req.clone({
          setHeaders: {
            'X-Auth-Token': this.auth.getCurrentAuthToken(),
            // Reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Permissions_Policy
            'Permissions-Policy': `geolocation=*,sync-xhr=*,encrypted-media=*`,
            'X-Frame-Options': 'SAMEORIGIN',
            'X-Content-Type-Options': 'nosniff',
            'X-Xss-Protection': '1; mode=block',
            // Will not allow any information to be sent when a scheme downgrade happens (when user is navigating from HTTPS to HTTP).
            'Referrer-Policy': 'strict-origin-when-cross-origin',
            'Content-Security-Policy': `script-src 'self'`
          }
        });
      }
    }
    return next.handle(request);

  }

}
