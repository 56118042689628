import {Component, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import {PasswordConfig} from '../../../_zen-legacy-common/_models/organization';
import {ZenColors} from '../../../_enums/zen-colors.enum';

/** Reference:
 * https://stackblitz.com/edit/angular-ivy-password-strength-meter?file=src%2Fapp%2Fapp.component.ts
 */

@Component({
  selector: 'app-zen-password-strength-checker',
  templateUrl: './zen-password-strength-checker.component.html',
  styleUrls: ['./zen-password-strength-checker.component.scss']
})
export class ZenPasswordStrengthCheckerComponent implements OnInit, OnChanges {
  @Input() public passwordToCheck: string;
  @Input() passwordConfig: PasswordConfig;

  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  private colors = [ZenColors.DANGER_1, ZenColors.DANGER_2, ZenColors.WARN_1, ZenColors.SUCCESS_2];

  regexPattern: RegExp;

  constructor() { }

  ngOnInit(): void {
    if (this.passwordConfig && this.passwordConfig.pattern) {
      this.regexPattern = new RegExp(this.passwordConfig.pattern);
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const password = changes.passwordToCheck.currentValue;
    this.setBarColors(4, '#DDD');
    if (password) {
      const c = this.getColor(this.checkStrength(password));
      this.setBarColors(c.index, c.color);
    }
  }


  checkStrength(p) {
    // 1
    let force = 0;

    // 2
    const valueEntered = Boolean(p.length);
    const lowerLetters = /[0-9]+/.test(p);
    const minFiveChars = /^.{5,}$/.test(p);
    const passConfPatternMatch = Boolean(this.regexPattern.exec(p));

    // 3 -> When the final passConfPatternMatch is true we are passing all conditions true.
    let flags = [];
    if (!passConfPatternMatch) {
      flags = [valueEntered, lowerLetters, minFiveChars, passConfPatternMatch];
    } else {
      flags = [true, true, true, true];
    }

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = (passedMatches === 1) ? Math.min(force, 10) : force;
    force = (passedMatches === 2) ? Math.min(force, 20) : force;
    force = (passedMatches === 3) ? Math.min(force, 30) : force;
    force = (passedMatches === 4) ? Math.min(force, 40) : force;

    return force;
  }

  private getColor(s) {
    let index = 0;
    if (s === 10) {
      index = 0;
    } else if (s === 20) {
      index = 1;
    } else if (s === 30) {
      index = 2;
    } else if (s === 40) {
      index = 3;
    } else {
      index = 4;
    }
    return {
      index: index + 1,
      color: this.colors[index]
    };
  }

  private setBarColors(count, col) {
    for (let n = 0; n < count; n++) {
      this['bar' + n] = col;
    }
  }

}
