import {Component, Inject, OnInit} from '@angular/core';
import {ZenBaseComponent} from '../../_components/zen-base/zen-base.component';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgxPopperjsPlacements} from 'ngx-popperjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {MeterUsageProfileV4Service} from '../../_services/v4/meter-usage-profile-v4.service';
import {ZenUnitsHelperService} from '../../_services/helpers/zen-units-helper.service';
import {
  ButtonActionTypes,
  ZenDialogActionButton,
  ZenDialogDataModel
} from '../zen-dialog/zen-dialog.component';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {DataFlowChildrenDataTableModified} from '../../_model/data-flow-hierarchy-v4.model';
import {ZenRcReqFlowHelperService} from '../../../_modules/zen-rate-check-request-v2/_services/zen-rc-req-flow-helper.service';
import {MeterUsageProfileV4GetModel, MeterUsageUpdate} from '../../_model/meter-usage-profile-v4.model';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';

@Component({
  selector: 'app-rc-req-meter-annual-usage-update-dialog',
  templateUrl: './rc-req-meter-annual-usage-update-dialog.component.html',
  styleUrls: ['./rc-req-meter-annual-usage-update-dialog.component.scss']
})
export class RcReqMeterAnnualUsageUpdateDialogComponent extends ZenBaseComponent implements OnInit {
  appearance: MatFormFieldAppearance = 'outline';
  form: UntypedFormGroup;
  loading: boolean;
  errorMsg: string;

  placement = NgxPopperjsPlacements.BOTTOM;
  meterUsageProfileData: MeterUsageProfileV4GetModel;
  meterRow: DataFlowChildrenDataTableModified;

  unit = new UntypedFormControl(null, [Validators.required]);
  ZenUnitsEnum = ZenUnitsEnum;

  constructor(public dialogRef: MatDialogRef<RcReqMeterAnnualUsageUpdateDialogComponent>,
              private zenDialogSvc: ZenDialogMsgService,
              private formBuilder: UntypedFormBuilder, private mtrUsgProfSvc: MeterUsageProfileV4Service,
              public zenUnitsSvc: ZenUnitsHelperService,
              public rcReqHelpSvc: ZenRcReqFlowHelperService,
              @Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel) {
    super();
    this.meterRow = this.data.data?.meterRow;
    this.unit.setValue(this.data.data?.baseUnit);
    this.loadForm();
    this.getMeterUsageProfile();
  }

  ngOnInit(): void {
  }

  get controls() {
    return this.form.controls;
  }

  loadForm(): void {
    this.form = this.formBuilder.group({
      estimatedAnnualUsage: new UntypedFormControl(null, [Validators.required])
    });
  }

  getMeterUsageProfile() {
    const {serviceAddressId, meterId} = this.meterRow;
    this.loading = true;
    this.mtrUsgProfSvc.getUsageProfileByMeterId(this.rcReqHelpSvc.customerIdParam, serviceAddressId, meterId)
      .subscribe({
        next: usageProfile => {
          this.loading = false;
          this.meterUsageProfileData = usageProfile;
          if (usageProfile?.estimatedAnnualUsage) {
            // Converting num to string to support imask.
            this.controls.estimatedAnnualUsage.setValue(JSON.stringify(usageProfile.estimatedAnnualUsage));
          }
        },
        error: err => {
          this.loading = false;
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }

  /** Disable Est. Annual Usage input if zenIq checked. */
  handleEstimatedAnnualUsageInput(checked) {
    if (!checked) {
      this.controls.estimatedAnnualUsage.enable();
    } else {
      this.controls.estimatedAnnualUsage.setValue(JSON.stringify(this.meterUsageProfileData.estimatedAnnualUsage));
      this.controls.estimatedAnnualUsage.disable();
    }
  }

  submit(btn: ZenDialogActionButton) {
    this.errorMsg = null;
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      this.dialogRef.close();
    } else {
      this.form.markAllAsTouched();
      if (this.form.valid) {
        this.loading = true;

        let updateData = {
          usingZenIq: false, // Adding this field here to support the updateUsageProfile() API
          estimatedAnnualUsage: this.zenUnitsSvc.getSelectedUnitCalculatedUsageValue(this.unit.value, parseFloat(this.form.getRawValue().estimatedAnnualUsage))
        } as MeterUsageUpdate;

        // handle usage profile submit
        this.mtrUsgProfSvc.updateUsageProfile(this.rcReqHelpSvc.customerIdParam, this.meterRow.serviceAddressId,
          this.meterRow.meterId, updateData).subscribe({
          next: updatedUsage => {
            this.zenDialogSvc.openToast(true);
            this.dialogRef.close(updatedUsage);
          },
          error: e => {
            this.loading = false;
            if (e.error && e.error.status === 400 && e.error.message) {
              this.errorMsg = e.error.message
            } else {
              this.errorMsg = ZenErrorMsgEnum.ERR_MSG_1_TEXT;
            }
            this.zenDialogSvc.openToast(false);
          }
        });
      }
    }
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }
}
