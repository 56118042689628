import {ThemeName, Variant} from '../app/modules/zentility/_shared/_zen-legacy-common/_models/theme.model';

export const environment = {
  production: false,
  apiBaseUrl: 'https://dev-api.zentility.com',
  signupBaseUrl: 'https://dev-signup.zentility.com',
  matrixApiBaseUrl: 'https://dev-matrix-api.zentility.com',
  signApiBaseUrl: 'https://dev-sign-api.zentility.com',
  showWelcomePopup: false,
  refreshNotifications: false,
  maxNotificationsShown: 3,
  theme: {
    override: false,
    name: ThemeName.TILI,
    variant: Variant.DEFAULT
  },
  generateMarketCheckReportUrl: 'https://us-central1-ha-zentility.cloudfunctions.net/generateReport',
  siteKey: '6LfF5l0UAAAAAAPEtagoNGSmXsMXHTZKI7bT2Jp2',
  googleMapApiKey: 'AIzaSyDI_lRTb7HUAPWik8f_FmVkS09TsxneWZU'
};
