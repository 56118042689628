import {EventEmitter, Injectable} from '@angular/core';
import {
  ZenTableColsModel,
  ZenTableConfig,
  ZenTableMenuOption,
  ZenTooltipHoverModel
} from '../../../../_shared/_components/zen-mat-table/zen-mat-table.component';
import {SelectionModel} from '@angular/cdk/collections';
import {
  ButtonTypes,
  ZenDialogComponent,
  ZenDialogDataModel,
  ZenDialogDataType
} from '../../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {CacheService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/cache.service';
import moment from 'moment';
import {MatDialog} from '@angular/material/dialog';
import {
  CustomerDetails,
  MloaDocumentDetailsDTO,
  PfCustomerDetailsModel,
  PfCustomersContentModel,
  PfCustomersTableRowModel
} from '../../_model/portfolio-customers.model';
import {PortfolioHelperService} from './portfolio-helper.service';
import {ZenPopperData} from '../../../../_shared/_components/zen-popper/zen-popper.component';
import {zenFormatAddressType} from '../../../../_shared/_zen-legacy-common/_utils/format-utils';
import {ZenSendEmailDialogComponent} from '../../../../_shared/_dialogs/zen-send-email-dialog/zen-send-email-dialog.component';
import {ZenDialogPanelClassEnum, ZenDialogSizeEnum} from '../../../../_shared/_enums/zen-dialogs.enum';
import {CustomerContact} from '../../../../_shared/_zen-legacy-common/_models/customer';
import {SignupService} from '../../../../_shared/_zen-legacy-common/zen-common-services/tili-services/services/signup.service';
import {ZenDialogMsgService} from '../../../../_shared/_services/zen-dialog-msg.service';
import {Router} from '@angular/router';
import {
  ZenCustomerProfileDialogComponent
} from '../../../../_shared/_dialogs/zen-customer-profile-dialog/zen-customer-profile-dialog.component';
import {CustomerServiceV4} from '../../../../_shared/_services/v4/customer-v4.service';
import {ZenErrorMsgEnum} from '../../../../_shared/_enums/zen-error-msg.enum';
import {MatTableSize} from '../../../../_shared/_enums/zen-mat-table.enum';
import {ChangeStatusObjectType, DeleteObjectType, PortfolioSectionsEnum, PortfolioTabsEnum} from '../../_enums/portfolio-tabs.enum';
import {ZenBreadcrumbConfig} from '../../../../_shared/_components/zen-breadcrumps/zen-breadcrumb.component';
import {ZenIconsEnum} from '../../../../_shared/_enums/zen-icons.enum';
import {ZenBreadcrumbService} from '../../../../_shared/_services/zen-breadcrumb.service';
import {ZenColumnInfoText} from '../../../../_shared/_enums/zen-column-info-text.enum';
import {NgxPopperjsPlacements} from 'ngx-popperjs';
import {AuthenticationService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {NavigationService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/navigation.service';
import {PortfolioHierarchyLevelEnum} from '../../_enums/portfolio-hierarchy-level.enum';
import {PortfolioFilterService} from './portfolio-filter.service';
import {
  ZenAddressManagementDialogComponent
} from '../../../zen-address/_dialogs/zen-address-management-dialog/zen-address-management-dialog.component';
import {getActivationStatusFilterValue} from '../../../../_shared/_utils/zen-menu-item.util';

const DATE_FORMAT = 'MMM D, yyyy';

export enum CustomersMenuActions {
  SEND_MLOA = 'SEND_MLOA',
  RESEND_MLOA = 'RESEND_MLOA',
  WELCOME_EMAIL = 'WELCOME_EMAIL',
  DELETE = 'DELETE',
  MULTI_DELETE = 'MULTI_DELETE'
}

@Injectable({
  providedIn: 'root'
})
export class PortfolioCustomersHelperService {
  selectedRows = new SelectionModel<PfCustomersTableRowModel>(true, []);

  tableMenuOptions: ZenTableMenuOption[] = [
    {
      label: 'Delete', type: 'button', icon: ZenIconsEnum.DELETE, iconPosition: 'left',
      command: () => this.handleCustomerDelete(null, this.selectedRows.selected)
    }, {
      label: 'Active',
      type: 'toggle',
      toggleDefaultVal: true,
      field: 'active',
      command: () => this.handleBulkStatusChange(getActivationStatusFilterValue(this.tableMenuOptions), false, this.selectedRows.selected)
    }
  ];

  cols: ZenTableColsModel[] = [
    {
      field: 'companyName', header: 'Customer Name', type: 'standard', enableTooltip: true, hyperlink: true,
      imageCol: 'imageSmallPath', statusCol: 'customerStatusCls', sticky: true,
      subTextCol: 'customerNameSubText', subTextLinkEnabledCol: 'customerNameSubTextHyperlinked',
      headerTooltipText: ZenColumnInfoText.CUSTOMER_NAME, popperPlacement: NgxPopperjsPlacements.AUTOSTART,
      handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleCustomerView(rowData),
      handleSubTextClick: (col, rowData) => (!rowData.mloaSigned && rowData.active) ? this.handleMenuAction(rowData.mloaDocumentDetails.mloaSentDate ? CustomersMenuActions.RESEND_MLOA : CustomersMenuActions.SEND_MLOA, rowData.mloaDocumentDetails) : null
    },
    {
      field: 'lenCount', header: 'Legal Entity Names', align: 'center', type: 'standard',
      headerTooltipText: ZenColumnInfoText.LEN, sortColumnName: 'customerMetrics.lenCount', hyperlink: true,
      handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleCustomerView(rowData, null, PortfolioTabsEnum.LENS, 0)
    },
    {
      field: 'serviceAddressCount', header: 'Service Addresses', align: 'center', type: 'standard',
      headerTooltipText: ZenColumnInfoText.SERVICE_ADDRESSES, sortColumnName: 'customerMetrics.serviceAddressCount', hyperlink: true,
      handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleCustomerView(rowData, null, PortfolioTabsEnum.SERVICE_ADDRESSES, 1)
    },
    {
      field: 'meterCount', header: 'Meters', align: 'center', type: 'standard',
      headerTooltipText: ZenColumnInfoText.METERS, sortColumnName: 'customerMetrics.meterCount', hyperlink: true,
      handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleCustomerView(rowData, null, PortfolioTabsEnum.METERS, 2)
    },
    {
      field: 'electricityUsage', header: 'Usage (kWh)', headerColIcon: ZenIconsEnum.ELECTRIC, align: 'center',
      type: 'standard', headerTooltipText: ZenColumnInfoText.ELEC_USAGE, sortColumnName: 'customerMetrics.electricityUsage'
    },
    {
      field: 'natGasUsage', header: 'Usage (Dth)', headerColIcon: ZenIconsEnum.NAT_GAS, align: 'center',
      type: 'standard', headerTooltipText: ZenColumnInfoText.NATGAS_USAGE, sortColumnName: 'customerMetrics.natGasUsage'
    },
    {
      field: 'activeRateCheckCountStr',
      header: 'Rate Checks',
      align: 'center',
      type: 'standard',
      headerTooltipText: ZenColumnInfoText.RCS,
      sortColumnName: 'customerMetrics.activeRateCheckCount',
      hyperlink: true,
      handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleCustomerView(rowData, PortfolioSectionsEnum.RATE_CHECKS, null, null)
    },
    {
      field: 'atRiskPercentage',
      textClassCol: 'atRiskTextCls',
      header: 'At Risk',
      iconCol: 'riskIcon',
      align: 'center',
      iconClsCol: 'riskIconCls',
      iconPosition: 'order-last',
      type: 'standard',
      enableTooltip: false,
      headerTooltipText: ZenColumnInfoText.AT_RISK,
      sortColumnName: 'customerMetrics.contractMetrics.atRisk.percentage',
      hideSort: false
    },
    {
      field: 'todayPercentage', header: 'Today', subTextCol: 'todaySubText', align: 'center', type: 'standard',
      headerTooltipText: ZenColumnInfoText.TODAY, sortColumnName: 'customerMetrics.contractMetrics.today.percentage', hideSort: false,
      downloadFormatter: (col, rowData: PfCustomersTableRowModel) => rowData.todayPercentage !== '-' ? `${rowData.todayPercentage} Contracted` : '-'
    },
    {
      field: 'futurePercentage', header: 'Future', subTextCol: 'futureSubText', align: 'center', type: 'standard',
      headerTooltipText: ZenColumnInfoText.FUTURE, sortColumnName: 'customerMetrics.contractMetrics.future.percentage', hideSort: false,
      downloadFormatter: (col, rowData: PfCustomersTableRowModel) => rowData.futurePercentage !== '-' ? `${rowData.futurePercentage} Contracted` : '-'
    },
    {field: 'menu', type: 'menu', stickyEnd: true}
  ];

  tableConfig: ZenTableConfig = {};
  popperData: ZenPopperData[] = []

  // Customer Details page
  loading: boolean;
  customerDetailsLoaded = new EventEmitter<boolean>();
  showTabSection = true;
  customerDet: CustomerDetails;

  constructor(private cacheSvc: CacheService,
              private dialog: MatDialog,
              private customerServiceV4: CustomerServiceV4,
              private authSvc: AuthenticationService,
              private zenBcSvc: ZenBreadcrumbService,
              private pfHelpSvc: PortfolioHelperService,
              private pfHelpFilterSvc: PortfolioFilterService,
              private router: Router,
              private signup: SignupService,
              private zenDialogSvc: ZenDialogMsgService,
              private navSvc: NavigationService) {
    this.tableConfig = {
      styles: {'min-width': MatTableSize.LARGE}, // this width is to match the overall top widget width.
      tableMenuOptions: this.tableMenuOptions,
      cols: this.cols,
      searchPlaceholder: 'Search Customers',
      download: {
        enable: true,
        getFileNameFn: () => this.pfHelpSvc.getTableDownloadFileName('portfolio_customers')
      },
      onDoubleClick: (rowData) => this.pfHelpSvc.handleCustomerView(rowData),
    };
    this.pfHelpFilterSvc.activeStatusUpdate.subscribe(val => {

      this.tableConfig.tableMenuOptions.filter(x => x.label === 'Active').forEach(x => {
        x.toggleDefaultVal = val;
      })
    })
  }

  handleTooltipData({col, element}: ZenTooltipHoverModel) {
    const _data: PfCustomersTableRowModel = element;
    if (col.field === 'companyName') {
      this.popperData = [
        {
          title: 'Customer Name',
          titleIcon: ZenIconsEnum.CUSTOMER,
          value: _data.companyName,
          hideIfBlank: false,
          status: (_data.active ? 'active' : 'inactive'),
          enableCopy: true
        },
        {
          title: 'Physical Address',
          value: zenFormatAddressType(_data),
          hideIfBlank: false,
          enableCopy: true,
          align: 'center'
        },
        {
          title: 'Date LOE/MLOA Signed',
          value: (_data.mloaSigned ? moment(_data.mloaDocumentDetails.mloaSignedDate).format(DATE_FORMAT) : null),
          hideIfBlank: true,
          enableCopy: true,
          align: 'center'
        },
        {
          title: 'Customer Type',
          value: _data.type,
          hideIfBlank: true,
          enableCopy: true,
          align: 'center'
        }
      ];
    }
  }

  handleAddEditCustomer(rowData?: PfCustomersTableRowModel, onlySave = false, callBackFn?: Function) {
    this.selectedRows = new SelectionModel<PfCustomersTableRowModel>(true, []);
    const _custLabel = (this.authSvc.isAdvisor() ? 'Customer' : 'Company');
    let dialogData: ZenDialogDataModel = {
      data: rowData,
      header: {
        title: (rowData ? 'Edit ' : 'Add ') + _custLabel,
        icon: 'settings'
      },
      bodyText: rowData ? `Edit the information below to maintain an accurate record of your ${_custLabel.toLowerCase()}.`
        : `Add the information below to maintain an accurate record of your ${_custLabel.toLowerCase()}.`,
      onClose: () => dialogRef.close(),
      actionButtons: [
        {label: 'Cancel', command: () => dialogRef.close()}
      ]
    };
    /** PM Add/Edit customer dialog save action
     * 1) Add mode -> i) Save -> Save & goto customer details page. ii) Save & Close -> stay at current page
     * 2) Edit mode -> Save */
    if (rowData || onlySave) { // Edit mode
      dialogData.actionButtons.push({
        label: 'Save',
        btnType: ButtonTypes.MAT_RAISED_BUTTON,
        color: 'primary',
        command: (data: { addLen, customer }) => dialogRef.close(data)
      });
    } else { // Add mode
      dialogData.actionButtons.push({
        label: 'Save',
        btnType: ButtonTypes.MAT_RAISED_BUTTON,
        color: 'primary',
        suffixIcon: 'expand_more',
        menuItems: [
          {label: 'Save & Close', command: (data: { addLen, customer }) => dialogRef.close(data)},
          {
            label: 'Save & Continue', command: (data: { addLen, customer }) => {
              dialogRef.close();
              // When electing to create a Company & LEN simultaneously, to be on the “ServiceAddress” Tab vs. the “LEN” Tab.
              let _selectedPfTab = data.addLen ? PortfolioTabsEnum.SERVICE_ADDRESSES : PortfolioTabsEnum.LENS;
              let _selectedPfTabIndex = data.addLen ? 1 : 0;
              this.pfHelpSvc.handleCustomerView(data.customer, PortfolioSectionsEnum.PORTFOLIO, _selectedPfTab, _selectedPfTabIndex);
            }
          }]
      });
    }

    const dialogRef = this.dialog.open(ZenCustomerProfileDialogComponent, {
      width: ZenDialogSizeEnum.LARGE,
      panelClass: ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT,
      data: dialogData, autoFocus: false
    });

    dialogRef.afterClosed().subscribe((data: { addLen, customer }) => {
      if (data && data.customer) {
        if (callBackFn) {
          callBackFn(data.customer);
        }

        this.pfHelpSvc.refreshCurrentPortfolioPage(data.customer.customerId, null, null);
        if (this.pfHelpFilterSvc.hierarchyLevel === PortfolioHierarchyLevelEnum.CUSTOMERS) {
          this.initCustomerDetails(data.customer.customerId);
        }
      }
    });
  }

  handleMenuAction(type: CustomersMenuActions, rowData: MloaDocumentDetailsDTO, goBack = false, callback?: Function) {
    if (type === CustomersMenuActions.SEND_MLOA || type === CustomersMenuActions.WELCOME_EMAIL) {
      this.handleSendEmails(type, rowData);
    }

    let dialogData: ZenDialogDataModel;
    let dialogRef;

    /* Resend LOE/MLOA requires confirmation first. Similar to delete. */
    if (type === CustomersMenuActions.RESEND_MLOA) {
      dialogData = {
        type: ZenDialogDataType.CONFIRM,
        header: {title: this.handleMenuActionDialogTitle(type)},
        bodyHtml: this.handleMenuActionDialogMessage(type, rowData, true),
        onClose: () => dialogRef.close(),
        actionButtons: [
          {
            label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
            command: () => {
              dialogRef.close();
              this.handleSendEmails(type, rowData, callback);
            }
          }]
      };
    }

    if (dialogData) {
      dialogRef = this.dialog.open(ZenDialogComponent, {
        width: ZenDialogSizeEnum.SMALL,
        data: dialogData, autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  /* 1. When Send LOE/MLOA - open below dialog directly.
     2. For Resend LOE/MLOA open confirm prompt first thenopen below dialog following with the confirmation. */
  handleSendEmails(type: CustomersMenuActions, rowData: MloaDocumentDetailsDTO, callback?: Function) {
    let dialogData: ZenDialogDataModel = {
      data: rowData,
      header: {title: this.handleMenuActionDialogTitle(type), icon: 'send'},
      bodyHtml: this.handleMenuActionDialogMessage(type, rowData, false),
      onClose: () => dialogRef.close(),
      actionButtons: [
        {
          label: 'Send', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'accent',
          command: (contact: CustomerContact) => {
            dialogRef.close();
            /* Handling send LOE/MLOA api call. */
            if (type === CustomersMenuActions.SEND_MLOA || type === CustomersMenuActions.RESEND_MLOA) {
              this.signup.sendLoeMloaToContact(contact.id, rowData.customerId)
                .subscribe(() => {
                  this.pfHelpSvc.refreshTable(PortfolioTabsEnum.CUSTOMERS);
                  dialogRef.close();
                  this.zenDialogSvc.openToast(true);

                  if (callback) {
                    callback();
                  }
                }, () => {
                  this.zenDialogSvc.openErrorDialog(false);
                });
            }
            /* Handling send welcome email api call. */
            if (type === CustomersMenuActions.WELCOME_EMAIL) {
              this.signup.sendCustomerWelcomeEmail(rowData.customerId, contact.email)
                .subscribe(() => {
                  dialogRef.close();
                  this.zenDialogSvc.openToast(true);
                }, () => {
                  this.zenDialogSvc.openErrorDialog(false);
                });
            }
          }
        }
      ]
    };

    const dialogRef = this.dialog.open(ZenSendEmailDialogComponent, {
      width: ZenDialogSizeEnum.MEDIUM,
      panelClass: ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT,
      data: dialogData, autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  handleMenuActionDialogTitle(type: CustomersMenuActions) {
    switch (type) {
      case CustomersMenuActions.RESEND_MLOA:
      case CustomersMenuActions.SEND_MLOA:
        return 'Request Data Authorization';
        break;
      case CustomersMenuActions.WELCOME_EMAIL:
        return 'Welcome New Customers';
        break;
    }
  }

  handleMenuActionDialogMessage(type: CustomersMenuActions, rowData: MloaDocumentDetailsDTO, showProceed: boolean) {
    let message: string;

    switch (type) {
      case CustomersMenuActions.RESEND_MLOA:
        if (rowData.mloaSignedDate) {
          message = `<div>An LOE/MLOA was previously signed by  ${rowData.firstName + ' ' + rowData.lastName} on ${moment(rowData.mloaSignedDate).format('MMM DD, YYYY')}.
          Sending a new request will clear out the existing document we have on file.`;
        } else {
          message = `<div>A LOE/MLOA was previously sent to ${rowData.firstName + ' ' + rowData.lastName} on ${moment(rowData.mloaSentDate).format('MMM DD, YYYY')} but has not yet been signed.
          Sending a new request will clear out the existing unsigned document we have on file.`;
        }
        if (showProceed) {
          message += `<br> <div> Are you sure you want to proceed?</div>`;
        }
        break;
      case CustomersMenuActions.SEND_MLOA:
        message = `<div>Accelerate all future Rate Checks for this Customer by securing an eSigned Letter of Exclusivity & Master Authorization. Select a contact and send your request via email.</div>`;
        break;
      case CustomersMenuActions.WELCOME_EMAIL:
        message = '<div>Get new users geared up to begin using their new procurement platform. Select a contact and send a welcome email.</div>';
        break;
    }
    return message;
  }

  handleCustomerDelete(rowData: PfCustomersTableRowModel, selectedRows: PfCustomersTableRowModel[], goBack = false) {
    let ids: number[] = rowData ? [rowData.customerId] : selectedRows?.map(r => r.customerId);

    if (ids.length) {
      this.customerServiceV4.bulkDeleteCheckCustomers(ids).subscribe(deleteCheck => {
        if (deleteCheck.deletable) {
          const dialogData: ZenDialogDataModel = {
            type: ZenDialogDataType.CONFIRM,
            bodyHtml: this.pfHelpSvc.getDeleteBodyText(rowData, selectedRows, DeleteObjectType.CUSTOMER, rowData?.companyName, deleteCheck.impactedObjects),
            header: {title: 'Portfolio Management'},
            onClose: () => dialogRef.close(),
            actionButtons: [
              {
                label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
                command: () => {
                  dialogRef.close();
                  this.customerServiceV4.bulkDeleteCustomers(ids).subscribe(res => {
                    this.selectedRows = new SelectionModel<PfCustomersTableRowModel>(true, []);
                    // Clear customer-list cache from the old ui
                    this.cacheSvc.clearCacheByName('customer');
                    this.pfHelpSvc.handleAfterDelete(ids, PortfolioTabsEnum.CUSTOMERS, goBack);
                  }, e => {
                    console.log('ERR: bulk delete customers ', e);
                    this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
                  });
                }
              }
            ]
          };
          const dialogRef = this.dialog.open(ZenDialogComponent, {
            width: ZenDialogSizeEnum.SMALL,
            data: dialogData, autoFocus: false
          });
        } else {
          this.zenDialogSvc.openErrorDialog(true, deleteCheck.errorMessage || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      }, e => {
        console.log('ERR: bulk delete customers check ', e);
        this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      });
    }
  }

  /**
   * handleBulkStatusChange - Called when the active toggle on the Customer table or row actions is toggled,
   * deactivates or reactivates the chosen customer(s)
   * @param active - boolean - current Active toggle status
   * @param rowAction - boolean - true for status change from row action toggle, false from table action toggle
   * @param selectedRows - PfCustomersTableRowModel[] selected rows from the Customer table (null if row action)
   */
  handleBulkStatusChange(active: boolean, rowAction: boolean, selectedRows: PfCustomersTableRowModel[]) {
    let ids: number[] = selectedRows.map(r => r.customerId);

    if (ids.length) {
      const dialogData: ZenDialogDataModel = {
        type: ZenDialogDataType.CONFIRM,
        bodyHtml: active ? this.pfHelpSvc.getDeactivateBodyText(rowAction, selectedRows, ChangeStatusObjectType.CUSTOMER) :
          this.pfHelpSvc.getReactivateBodyText(rowAction, selectedRows, ChangeStatusObjectType.CUSTOMER),
        header: {title: 'Portfolio Management'},
        onClose: () => {
          dialogRef.close();
          this.resetTableActionToggle(!rowAction);
        },
        actionButtons: [
          {
            label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
            command: () => {
              dialogRef.close();
              // !active is passed to the API since we need to send the desired active/inactive status change, not the current status
              this.customerServiceV4.bulkChangeStatusCustomers(ids, !active).subscribe(res => {
                this.selectedRows = new SelectionModel<PfCustomersTableRowModel>(true, []);
                this.pfHelpSvc.handleAfterChangeStatus(ids, PortfolioTabsEnum.CUSTOMERS);
                this.resetTableActionToggle(!rowAction);
                active ? this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getDeactivateSuccessBodyText(selectedRows, ChangeStatusObjectType.CUSTOMER)) :
                  this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getReactivateSuccessBodyText(selectedRows, ChangeStatusObjectType.CUSTOMER));
              }, e => {
                console.log('ERR: bulk change status Customers ', e);
                this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
                this.resetTableActionToggle(!rowAction);
              });
            }
          }
        ]
      };
      const dialogRef = this.dialog.open(ZenDialogComponent, {
        width: ZenDialogSizeEnum.SMALL,
        data: dialogData, autoFocus: false
      });
    } else {
      this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      this.resetTableActionToggle(!rowAction);
    }
  }

  resetTableActionToggle(tableAction: boolean) {
    if (tableAction) {
      this.tableConfig.tableMenuOptions.find(x => x.label === 'Active').toggleDefaultVal =
        this.tableConfig.tableMenuOptions.find(x => x.label === 'Active').toggleDefaultVal !== true;
    }
  }

  /** Customer Details page starts */
  initCustomerDetails(customerId: number, callback?: Function) {
    this.loading = true;
    this.customerServiceV4.getCustomerDetails(customerId).subscribe(custDet => {
      this.customerDet = custDet;
      this.buildBreadcrumb(custDet);

      if (callback) {
        callback(custDet);
      }
      this.loading = false;
    }, err => {
      console.log('Error: Get customer details ', err);
      this.loading = false;
      this.zenDialogSvc.openToast(false);
    });
  }

  buildBreadcrumb(custDet: CustomerDetails) {
    const _bcData: ZenBreadcrumbConfig = {
      pageName: 'Portfolio',
      // goto portfolio
      command: () => this.router.navigate(this.navSvc.getPortfolioPageRoute()),
      mobileBreadcrumbs: [
        {
          title: 'Portfolio', icon: ZenIconsEnum.BACK,
          command: () => this.router.navigate(this.navSvc.getPortfolioPageRoute())
        },
        {icon: ZenIconsEnum.CUSTOMER, title: custDet.companyName, currentPage: true}
      ]
    };
    this.zenBcSvc.setConfig(_bcData);
  }

  clearBc() {
    this.zenBcSvc.clear();
  }

  handleDelete(custDet: PfCustomersContentModel) {
    const _tblRowModel = {...custDet} as PfCustomersTableRowModel;
    this.handleCustomerDelete(_tblRowModel, null, true);
  }

  /** The below method will hide and show the tab group template, which helps to reload data w.r.t the selected tab. */
  refreshTabSection() {
    setTimeout(() => this.showTabSection = false);
    setTimeout(() => this.showTabSection = true);
  }

  /** Address Management */
  showAddressMgtDialog(customerId: number) {
    let dialogData: ZenDialogDataModel = {
      type: ZenDialogDataType.GUIDANCE_SUCCESS,
      data: {customerId},
      header: {title: 'Manage Billing Addresses'},
      bodyHtml: `View the table below to manage billing addresses and see which addresses are not in use.`,
      onClose: () => dialogRef.close(),
      actionButtons: []
    };

    const dialogRef = this.dialog.open(ZenAddressManagementDialogComponent, {
      width: ZenDialogSizeEnum.EXTRA_MEDIUM,
      panelClass: ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT,
      data: dialogData, autoFocus: false
    });

    dialogRef.afterClosed().subscribe((refresh) => {
      // Refresh the tables on close
      if (refresh) {
        this.refreshTabSection();
      }
    });
  }

  /** Customer Details page ends */
}
