import html2canvas from 'html2canvas';
import {Directive, ElementRef, OnInit} from '@angular/core';
import {jsPDF} from 'jspdf';
import {Organization} from '../../../../../../_shared/_zen-legacy-common/_models/organization';

;

@Directive()
export abstract class ZenDownloadableDirective implements OnInit {
  abstract forceOnePage: boolean;
  abstract footer?: string;
  setFooterAllPage = false;
  /** To exclude footer for particular page w.r.t the nativeElement Id. */
  excludeFooterPageIds: string[] = [];
  abstract organization?: Organization;

  protected constructor() {
  }

  ngOnInit(): void {
    this.initLoad();
  }

  abstract getPages(): ElementRef[];

  abstract getFileName(): string;

  abstract initLoad();

  abstract onPdfGenerated();

  downloadReport(): void {
    this.makePDF();
  }

  async makePDF() {
    // We add a sleep to wait for all the elements of the report to load and be displayed.
    await new Promise(resolve => setTimeout(resolve, 1000));

    let doc = new jsPDF('p', 'mm', 'letter');
    let pages = this.getPages();
    for (const [index, value] of pages.entries()) {
      const canvas = await html2canvas(value.nativeElement, {
        /** NOTE: html2canvas do no support images(src url) from different host(Eg., logoPrimaryDark).
         * So, to break that condition we are using proxy. To enable proxy we need assign values in
         * the this.organization variable. Which used to enable cross-origin proxy in the Downloadable component.
         * Reference: http://html2canvas.hertzen.com/proxy/ **/
        proxy: this.organization ? this.organization.imageSmallPath : null,
        scrollY: -window.scrollY,
        scale: 3
      });
      doc = await this.generatePage(doc, canvas, index, index + 1 === pages.length, value.nativeElement.id);
    }

    doc.save(this.getFileName());
    this.onPdfGenerated();
  }

  async generatePage(doc, canvas, index, lastPage, pageId) {
    let imgData = canvas.toDataURL('image/png');

    let imgWidth = 216;
    let pageHeight = 279;
    let imgHeight = canvas.height * imgWidth / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    if (index !== 0) {
      doc.addPage();
    }

    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, null, 'FAST');
    heightLeft -= pageHeight;

    if (!this.forceOnePage) {
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, null, 'FAST');
        heightLeft -= pageHeight;
      }
    }

    if (this.footer && !this.excludeFooterPageIds.includes(pageId)) {
      doc.setPage(doc.internal.getNumberOfPages());
      doc.setFontSize(8);
      doc.setTextColor(56, 65, 82);
      doc.setDrawColor(0);
      doc.setFillColor(246, 247, 249);
      doc.rect(0, pageHeight - 14, imgWidth, 14, 'F');
      /** To add footer only in the last pages. */
      if (lastPage && !this.setFooterAllPage) {
        doc.text(this.footer, imgWidth / 2, pageHeight - 5.5, {align: 'center'});
      }
      /** To add footer in all pages. */
      if (this.setFooterAllPage) {
        // Removing HTML tags from the text
        const footerReport = this.footer.replace(/<[^>]*>?/gm, '');
        doc.text(footerReport, imgWidth / 2, pageHeight - 5, {align: 'center'});
      }
    }

    return doc;
  }
}
