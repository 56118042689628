import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
// @ts-ignore
import {Chart, ChartComponentLike, ChartData, ChartEvent, ChartOptions, ChartTooltipOptions, ChartType} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {ZenLoaderTypes} from '../zen-skeleton-loader/zen-skeleton-loader.component';
import {ZenColors} from '../../_enums/zen-colors.enum';
import {DecimalPipe} from '@angular/common';
import {orderBy} from '../../_zen-legacy-common/_utils/orderby.utils';
import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import * as _ from 'lodash';

interface PfChartPluginModel {
  value?: number | string;
  title?: string;
  titleFormat?: (tooltipItem, subLabels: (string | number)[]) => string;
  valueFormat?: (tooltipItem, subLabels: (string | number)[]) => string;
}

export interface PfChartInputModel {
  title?: string;
  legendUniqueScrollId?: string; // To support ZenChart->scroll() method and onChartSegmentClick()
  pluginData?: PfChartPluginModel;
  legends: PfChartLegendModel[];
  labelSort?: {field: string; sort: 'asc' | 'desc'};
}

export interface PfChartLegendModel {
  order?: number;
  label: string;
  subLabel: string | number;
  color: string;
  colorPct?: number;
  value: number;
  disabled: boolean;
  dataIndex?: number;
}

export const StackedBarBorderRadiusPlugin: ChartComponentLike = {
  id: 'stacked_bar_border_radius',
  beforeDatasetsDraw: (chart, args, options) => {
    // Border radius to set to
    const borderRadius = {topLeft: 8, topRight: 8, bottomLeft: 0, bottomRight: 0};
    // @ts-ignore
    const numDatasets = chart.data.datasets.length;
    // numDatasets has to be even
    if (numDatasets % 2 !== 0) {
      return;
    }
    // Iterate over the groups of two datasetes (actual, zenIQ)
    for (let datasetIdx = 0; datasetIdx < numDatasets; datasetIdx += 2) {
      let ACTUAL_DATA_IDX = datasetIdx, ZENIQ_DATA_IDX = datasetIdx + 1;
      let actualDataBars = chart.getDatasetMeta(ACTUAL_DATA_IDX).data as Object;
      let zenIqBars = chart.getDatasetMeta(ZENIQ_DATA_IDX).data as Object;
      // Always has 12 bars
      for (let i = 0; i < 12; i++) {
        let actualBar = actualDataBars[i], actualBarHeight = actualBar?.height ?? NaN;
        let zenIqBar = zenIqBars[i], zenIqBarHeight = zenIqBar?.height ?? NaN;
        if (Number.isNaN(actualBarHeight) || Number.isNaN(zenIqBarHeight)) {
          // Hasn't calculated height yet, try again later
          continue;
        }

        try {
          if (zenIqBarHeight > 0 && actualBarHeight === 0) { // If zenIq is the only bar, round that
            // @ts-ignore
            chart.getDatasetMeta(ZENIQ_DATA_IDX).data[i].enableBorderRadius = true;
            // @ts-ignore
            chart.getDatasetMeta(ZENIQ_DATA_IDX).data[i].options.borderRadius = borderRadius;
          } else if (actualBarHeight > 0 && zenIqBarHeight === 0) { // If actual is the only bar, round that
            // @ts-ignore
            chart.getDatasetMeta(ACTUAL_DATA_IDX).data[i].enableBorderRadius = true;
            // @ts-ignore
            chart.getDatasetMeta(ACTUAL_DATA_IDX).data[i].options.borderRadius = borderRadius; // Adding top border only if there is only one bar
          } else { // If both bars are present, round the top of one with greater height
            if (actualBarHeight > zenIqBarHeight) {
              // If actual is bigger than zenIq, round zenIq (because zenIq is on the top of actual)
              // @ts-ignore
              chart.getDatasetMeta(ZENIQ_DATA_IDX).data[i].enableBorderRadius = false;
            } else {
              // @ts-ignore
              chart.getDatasetMeta(ACTUAL_DATA_IDX).data[i].enableBorderRadius = false;
            }
          }
        } catch (e) {
          // On the customer dashboard it fails to set borderRadius because options becomes read-only for some reason. Fail silently.
          // Resulting behavior is that on hover, the bar is not rounded.
          continue;
        }
      }
    }
  }
};

// This external tooltip tpl use zenChartJsTooltip from the template
export const externalHtmlChartTooltip = (context, chartData: PfChartInputModel) => {
  // Tooltip Element
  const {chart, tooltip} = context;

  if (context && tooltip) {
    let tooltipEl = chart.canvas.parentNode.querySelector('div#zenChartJsTooltip');

    if (tooltipEl) {

      // Hide if no tooltip
      const tooltipModel = tooltip;
      if (tooltipModel.opacity === 0) {
        // @ts-ignore
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      // Set Text
      if (tooltipModel.body) {
        let innerHtml = '<div class="d-flex align-items-center">';
        const _legend = chartData.legends?.[tooltipModel.dataPoints?.[0]?.dataIndex];

        innerHtml += `<p class="text-sm font-weight-500 d-flex text-overflow zen-chart-title"> ${_legend?.label} </p>`;
        innerHtml += '<span class="mx-2">|</span>'; // divider
        innerHtml += '<p class="text-sm font-weight-500"> ' + _legend?.subLabel + '</p>';

        innerHtml += '</div>';

        tooltipEl.innerHTML = innerHtml;
      }

      // Display, position, and set styles for font
      const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
      // @ts-ignore
      tooltipEl.style.opacity = 1;
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = 55 + positionX + tooltip.caretX + 'px';
      tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    }
  }
};

@Component({
  selector: 'app-zen-chart',
  templateUrl: './zen-chart.component.html',
  styleUrls: ['./zen-chart.component.scss']
})
export class ZenChartComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective) public baseChart: BaseChartDirective;
  @ViewChild('chartLegendContainer') public chartLegendContainer: ElementRef;
  // Passed into component to set active legend items
  @Input() preActiveLegend: Array<string> = [];
  // When user clicks on legend or chart, this event is emitted with (legendLabel)
  @Output() onChartClick: EventEmitter<string> = new EventEmitter();
  activeLegendItems: { string?: boolean }; // if Expired is active, activeLegendItems['Expired'] = true
  title: string;
  legendLabels: Array<string> = [];
  legendColors: Array<string> = [];
  subLabels: Array<string | number> = [];
  dataValues: Array<number> = [];
  defaultPluginData: PfChartPluginModel;
  pluginData: PfChartPluginModel;

  filteredData: ChartData;
  chartOptions: ChartOptions = {};
  chartType: ChartType = 'doughnut';
  showLegend = false;
  defaultLegendClickHandler;
  ZenLoaderTypes = ZenLoaderTypes;
  legends: PfChartLegendModel[] = [];
  legendHoverIndex: number;

  expandMore: boolean;
  chartDataCopy: PfChartInputModel;

  @Input() loading: boolean;
  @Input() error: boolean;
  @Input() expansionSize: number;
  @Input() styleType: 'v1' | 'v2' | 'v3' = 'v1';
  @Input() externalTooltip: boolean;
  @Input() set chartData(chartData: PfChartInputModel) {
    if (chartData && chartData?.legends) {
      this.chartDataCopy = {...chartData};
      this.init(chartData);
    }
  }

  constructor(
    private decimalPipe: DecimalPipe,
    private scrollToService: ScrollToService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.legendHoverIndex = null;
    this.activeLegendItems = {};
    // Initialize activeLegendItems
    this.legendLabels.forEach(l => this.activeLegendItems[l] = false);
    // Set active legend items
    this.preActiveLegend.forEach(l => this.activeLegendItems[l] = true);
  }

  init(chartData: PfChartInputModel) {
    let _legends = [...chartData.legends];

    // To maintain 2 different sort for labels and dataValues
    if (chartData.labelSort?.field) {
      this.legends = [..._.orderBy(_legends, chartData.labelSort?.field, chartData.labelSort?.sort).map(ol => {
        ol.dataIndex = chartData.legends?.findIndex(ld => ld.label === ol.label);
        return ol;
      })];
    } else {
      this.legends = (_legends.some(l => l.order) ? orderBy(_legends, 'order', 'asc') : _legends)
        .map((ol, index) => {
          ol.dataIndex = index;
          return ol;
        });
    }

    this.title = chartData.title;
    this.legendLabels = _legends.map(d => d.label);
    this.legendColors = _legends.map(d => d.color);
    this.subLabels = _legends.map(d => d.subLabel);
    this.dataValues = _legends.map(d => d.value);
    this.defaultPluginData = chartData.pluginData;
    this.pluginData = {...chartData.pluginData};
    this.buildChart();
    this.buildChartOptions();
  }

  buildChartOptions() {
    this.chartOptions = {
      responsive: true,
      animation: {
        duration: 30
      },
      interaction: {
        mode: 'point',
      },
      plugins: {
        tooltip: {enabled: false}
      }
    };

    if (this.chartOptions.plugins.tooltip && this.externalTooltip && this.legends?.length) {
      this.chartOptions.plugins.tooltip = {
        ...this.chartOptions.plugins.tooltip,
        external: (context) => externalHtmlChartTooltip(context, this.chartDataCopy)
      }
    }

    this.defaultLegendClickHandler = Chart.defaults.plugins.legend.onClick;
  }

  buildChart() {
    /* If data values present */
    if (this.dataValues.filter(v => v).length) {
      this.filteredData = {
        labels: this.legendLabels,
        datasets: [{
          data: [...this.dataValues],
          backgroundColor: this.legendColors,
          hoverBackgroundColor: this.legendColors,
          hoverBorderColor: this.legendColors, // remove this in the future. And enable above one.
          ...this.getChartSizeConfigByStyleType()
        }]
      };
    } else {
      /* If NO data values present. */
      this.filteredData = {
        labels: this.legendLabels,
        datasets: [{
          data: [100, 0, 0, 0],
          backgroundColor: ZenColors.textColorLight_3,
          hoverBackgroundColor: ZenColors.textColorLight_3,
          hoverBorderColor: ZenColors.textColorLight_3,
          ...this.getEmptyChartSizeConfigByStyleType(),
          borderColor: ZenColors.textColorLight_3
        }]
      }
    }
  }

  getEmptyChartSizeConfigByStyleType() {
    switch (this.styleType) {
      case 'v1':
        return {
          cutout: 50, // to reduce the thickness of the doughnut chart.
          borderWidth: 0
        };
      case 'v2':
        return {
          cutout: 75,
          borderWidth: 5
        };
      case 'v3':
        return {
          cutout: 65,
          borderWidth: 5
        };
    }
  }

  getChartSizeConfigByStyleType() {
    switch (this.styleType) {
      case 'v1':
        return {
          cutout: 47, // to reduce the thickness of the doughnut chart.
          borderWidth: 2,
          borderColor: 'white',
          hoverBorderWidth: 1.5
        };
      case 'v2':
        return {
          cutout: 75, // to reduce the thickness of the doughnut chart.
          borderWidth: 5,
          borderColor: ZenColors.textColorLight_4,
          hoverBorderWidth: 0.5
        };
      case 'v3':
        return {
          cutout: 40, // to reduce the thickness of the doughnut chart.
          borderWidth: 1,
          borderColor: ZenColors.textColorLight_4,
          hoverBorderWidth: 1.5
        };
    }
  }

  public onLegendItemClick(e: Event, legendItem: PfChartLegendModel): void {
    this.onChartClick.emit(legendItem.label);
  }

  public onChartSegmentClick(e: {
    event?: ChartEvent;
    active?: { index?: number }[];
  }) {
    if (e.active.length > 0) {
      const clickedSegment = e.active[0];
      // Safety check: Clicking on N/A
      if (this.pluginData.value == null) {
        return;
      }
      this.onChartClick.emit(this.legendLabels[clickedSegment.index]);

      const el = document.getElementById(this.chartDataCopy?.legendUniqueScrollId + '-' + clickedSegment.index);
      if (el) {
        setTimeout(() => this.scrollToService.scrollTo({ target: el }));
      }
    }
  }

  public isItemFilterActive(item: PfChartLegendModel): boolean {
    return !!this.activeLegendItems[item.label];
  }

  onLegendHover(item: PfChartLegendModel, dataIndex: number, myChart?: BaseChartDirective) {
    let title;
    let value = this.decimalPipe.transform(item.value, '1.1-1');
    if (this.defaultPluginData?.titleFormat) {
      title = this.defaultPluginData.titleFormat(item, this.subLabels);
    }
    if (this.defaultPluginData?.valueFormat) {
      value = this.defaultPluginData.valueFormat(item, this.subLabels);
    }
    this.pluginData = {title, value};
    if (myChart) {
      this.triggerHoverInTheChartSegment(myChart, dataIndex);
    }
  }

  handleChartSegmentHover(evt: {
    event?: ChartEvent;
    active?: { index?: number }[];
  }) {
    this.legendHoverIndex = evt.active && evt.active[0] ? evt.active[0]?.index : null;
    const _hoveredLegend = this.legends.find(l => l.dataIndex === this.legendHoverIndex);
    // If the legend is present and the value is present.
    if (_hoveredLegend?.value) {
      this.onLegendHover(_hoveredLegend, this.legendHoverIndex);
    } else {
      this.onOut();
    }
  }

  /** To trigger - The segment dimension change when the user selects text from a widget.
   * Ref: https://www.chartjs.org/docs/latest/samples/advanced/programmatic-events.html  */
  triggerHoverInTheChartSegment(myChart: BaseChartDirective, dataIndex: number) {
    const chart = myChart.chart;
    let activeData = [];
    this.legends.forEach((legend, i) => {
      if (i === dataIndex) {
        activeData.push({datasetIndex: 0, index: dataIndex});
      }
    });
    chart.setActiveElements(activeData);
    chart.update();
  }

  onOut(myChart?: BaseChartDirective) {
    this.pluginData = this.defaultPluginData;
    this.legendHoverIndex = null;
    if (myChart) {
      this.triggerHoverInTheChartSegment(myChart, null); // to clear the segment hover.
    }
  }

  scroll(expandMore: boolean) {
    this.expandMore = expandMore;

    if (expandMore) {
      this.chartLegendContainer.nativeElement.scrollTo({top: this.chartLegendContainer.nativeElement.scrollTop + 125, behavior: 'smooth'});
    } else {
      this.chartLegendContainer.nativeElement.scrollTo({top: this.chartLegendContainer.nativeElement.scrollTop - 125, behavior: 'smooth'});
    }
  }

}
