import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ThemeManagerService, ThemeStyles} from './modules/zen-theme-manager';
import {
  OrganizationManagementService
} from './modules/zentility/_shared/_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {AuthenticationService} from './modules/zentility/_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {ZenTacDialogComponent} from './modules/zentility/_modules/zen-tac/zen-tac-dialog/zen-tac-dialog.component';
import {ZenDialogSizeEnum} from './modules/zentility/_shared/_enums/zen-dialogs.enum';
import {ZenDialogDataModel} from './modules/zentility/_shared/_dialogs/zen-dialog/zen-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {InitResponse} from './modules/zentility/_shared/_zen-legacy-common/_models/organization';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  applicationTheme: ThemeStyles;
  tacApproved = true;
  subs: Subscription[] = [];

  constructor(
    private organizationService: OrganizationManagementService,
    private authenticationService: AuthenticationService,
    private themes: ThemeManagerService,
    private router: Router,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    const toggleTacApprovalPopup = this.authenticationService.toggleTacApprovalPopup.subscribe((tacApproved: boolean) => {
      this.tacApproved = tacApproved;
      if (!tacApproved) {
        let dialogData: ZenDialogDataModel = {
          hideCloseX: true,
          data: {existingUser: true}
        }
        const dialogRef = this.dialog.open(ZenTacDialogComponent, {
          width: ZenDialogSizeEnum.MEDIUM,
          data: dialogData, autoFocus: false
        });
      }
    });

    this.subs.push(toggleTacApprovalPopup);
    this.themes.observeCurrentTheme().subscribe(t => {
      this.applicationTheme = t;
    });
    this.initOrganization();
  }

  public async initOrganization() {
    try {
      const res: InitResponse = await this.organizationService.discover().toPromise();
      this.themes.setCurrentTheme(res.themeStyles);
    } catch (ex) {
      console.log('page not found ', ex);
      this.applicationTheme = {} as ThemeStyles;
      this.tacApproved = true;
      this.router.navigateByUrl('/404');
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this.htmlbodyHeightUpdate();
    $(window).resize(() => {
      this.htmlbodyHeightUpdate()
    });
    $(window).scroll(() => {
      this.htmlbodyHeightUpdate()
    });
  }

  htmlbodyHeightUpdate(): void {
    let height3: number = $(window).height();
    let height1: number = $('.nav').height() + 50;
    let height2: number = $('.main').height();
    if (height2 > height3) {
      $('html').height(Math.max(height1, height3, height2) + 10);
      $('body').height(Math.max(height1, height3, height2) + 10);
    } else {
      $('html').height(Math.max(height1, height3, height2));
      $('body').height(Math.max(height1, height3, height2));
    }
  }
}
