export enum SortType {
  ASC, DESC
}

export enum FilterType {
  AND, OR
}

export function sortByField(fieldName, sortType: SortType) {
  return (objectToSortA, objectToSortB) => {
    switch (sortType) {
      case SortType.ASC:
      default:
        return objectToSortA[fieldName] > objectToSortB[fieldName] ? 1 : objectToSortA[fieldName] > objectToSortB[fieldName] ? -1 : 0;
      case SortType.DESC:
        return objectToSortA[fieldName] < objectToSortB[fieldName] ? 1 : objectToSortA[fieldName] < objectToSortB[fieldName] ? -1 : 0;
    }
  };
}

export function sortByValue(sortType: SortType) {
  return (objectToSortA, objectToSortB) => {
    switch (sortType) {
      case SortType.ASC:
      default:
        return objectToSortA > objectToSortB ? 1 : objectToSortB > objectToSortA ? -1 : 0;
      case SortType.DESC:
        return objectToSortA < objectToSortB ? 1 : objectToSortB < objectToSortA ? -1 : 0;
    }
  }
}

export function filterByField(filterType: FilterType, fieldValue, fieldNames) {
  return (objectToFilter) => {
    if (!Array.isArray(fieldNames)) {
      fieldNames = [fieldNames];
    }
    switch (filterType) {
      case FilterType.AND:
      default:
          return fieldNames.map(fieldName => objectToFilter[fieldName] !== fieldValue).filter(result => result === true).length === 0;
      case FilterType.OR:
          return fieldNames.map(fieldName => objectToFilter[fieldName] === fieldValue).filter(result => result === true).length > 0;
    }
  };
}

export function arrayDistinct <T> (array: T []): T [] {
  let filteredArray = [];
  array.forEach(value => {
    if (filteredArray.indexOf(value) === -1) {
      filteredArray.push(value);
    }
  });
  return filteredArray;
}

export function equalsIgnoreCase(a: string | undefined | null, b: string | undefined | null) {
  if ((a && !b) || (b && !a)) {
    return false;
  } else if (!a && !b) {
    return true;
  } else {
    return a.toLowerCase() === b.toLowerCase();
  }
}