import {Component, ViewEncapsulation, Inject} from '@angular/core';
import {base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform} from 'ngx-image-cropper';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonActionTypes, ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {ZenBaseComponent} from '../../_components/zen-base/zen-base.component';

/** Reference: https://stackblitz.com/edit/image-cropper */

@Component({
  selector: 'app-zen-image-cropper-dialog',
  templateUrl: './zen-image-cropper-dialog.component.html',
  styleUrls: ['./zen-image-cropper-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZenImageCropperDialogComponent extends ZenBaseComponent {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = true;
  transform: ImageTransform = {};
  aspectRatio = 2.5; // This one is configrable through ZenDialogDataModel {data: {aspectRatio: 4}}

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenImageCropperDialogComponent>) {
    super();
    this.imageChangedEvent = this.data.data.imageInputFileEvent; // It's an input change event where type='file'
    this.aspectRatio = this.data.data.aspectRatio;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  handleSubmit(btn: ZenDialogActionButton) {
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      btn.command(null);
    } else if (btn.actionType === ButtonActionTypes.SUBMIT) {
      btn.command(this.croppedImage);
    }
  }
}
