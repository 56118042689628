<div class="zen-button-toggle-group" *ngIf="formCtrl && options?.length">
  <app-zen-icon-button [title]="title" [icon]="icon" [isSymbol]="isSymbol"
                       style="width: 50%"
    [popperComponent]="popperComponent"></app-zen-icon-button>

  <div class="right-section">
    <ng-container *ngFor="let op of options">
      <button mat-button [value]="op.value"
              [class.selected]="formCtrl.value === op.value"
              [disabled]="op.disabled || formCtrl.disabled"
              (click)="formCtrl.setValue(op.value)">{{op.label}}</button>
    </ng-container>
  </div>
</div>
