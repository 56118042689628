import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import FileSaver from 'file-saver';

@Injectable({providedIn: 'root'})
export class DownloadService {
  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) {
  }

  downloadFile(url: string, fileName?: string, responseType?: string) {
    const downloadUrl = this.baseUrl + url;
    if (!fileName) {
      fileName = 'file.pdf';
    }
    if (!responseType) {
      responseType = 'application/pdf';
    }
    this.downloadApi(downloadUrl, fileName, responseType);
  }

  downloadApi(downloadUrl, fileName, responseType) {
    return this.http.get(downloadUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': responseType
      },
      responseType: 'blob',
      observe: 'response'  // needed to observe response content type
    }).pipe(
      map((response: any) => {
        let data = response.body;
        const pdfUrl = URL.createObjectURL(data);
        const anchor: any = document.createElement('a');
        anchor.download = fileName;
        anchor.href = pdfUrl;
        anchor.click();
      })
    ).toPromise();
  }

  downloadFilePostMethod(downloadUrl: string, postReq, fileName?: string, responseType?: string) {
    if (!fileName) {
      fileName = 'report.pdf';
    }
    if (!responseType) {
      responseType = 'application/pdf';
    }
    return this.http.post(downloadUrl, postReq, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': responseType
      },
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      map(res => {
        const headerFilename = this.getFileNameFromHeaders(res.headers.get('Content-Disposition'));
        const pdfUrl = URL.createObjectURL(res.body);
        const anchor: any = document.createElement('a');
        fileName = headerFilename === '' ? fileName : headerFilename;
        anchor.download = fileName;
        anchor.href = pdfUrl;
        anchor.click();
      })
    ).toPromise();
  }


  getFileNameFromHeaders(disposition: string) {
    let filename = '';
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  }

  downloadExcelFile(dataToExtract, fileName: string, headerNames?: string[]) {
    const objKeys = Object.keys(dataToExtract[0]);
    if (!headerNames) {
      headerNames = objKeys.map(h =>
        h.replace(/[A-Z]/g, letter => `${this.capitalizeFirstLetter(letter.toLowerCase())}`));
      headerNames = headerNames.map(this.capitalizeFirstLetter).map(h => {
        h = (h.startsWith('Street')) ? h.replace('Street', 'Address') : h;
        h = (h === 'Util Account No') ? 'Utility Account No' : h;
        return h;
      });
    }
    if (headerNames && headerNames.length === objKeys.length) {
      const headerCol = {};
      for (let i = 0; i <= objKeys.length; i++) {
        headerCol[objKeys[i]] = headerNames[i];
      }
      dataToExtract.unshift(headerCol);
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(dataToExtract, {skipHeader: true});
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
        this.saveAsExcelFile(excelBuffer, fileName);
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    fileName = fileName.replace('.csv', '');
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

}
