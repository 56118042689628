import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ButtonTypes, ZenDialogActionButton, ZenDialogDataModel} from '../zen-dialog/zen-dialog.component';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable, ReplaySubject} from 'rxjs';
import {ValidPatterns} from '../../_zen-legacy-common/_models/common/email-pattern';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CustomerContactService} from '../../_zen-legacy-common/zen-common-services/_services/customer-contact.service';
import {CustomerContact} from '../../_zen-legacy-common/_models/customer';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ZenFormTypesEnum} from '../../_enums/zen-form-types.enum';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {ZenBaseComponent} from '../../_components/zen-base/zen-base.component';
import {ZenDialogSizeEnum} from '../../_enums/zen-dialogs.enum';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {ZenMessageType} from '../../_components/zen-message/zen-message.component';
import {zenHasError} from '../../_utils/zen-has-error.util';

@Component({
  selector: 'app-zen-send-email-dialog',
  templateUrl: './zen-send-email-dialog.component.html',
  styleUrls: ['./zen-send-email-dialog.component.scss']
})
export class ZenSendEmailDialogComponent extends ZenBaseComponent implements OnInit {
  contactCtrl = new UntypedFormControl(null, [Validators.required]);
  // contactFilterCtrl = new FormControl();
  public filteredContacts: ReplaySubject<CustomerContact[]> = new ReplaySubject();
  formType: ZenFormTypesEnum;
  ZenFormTypesEnum = ZenFormTypesEnum;

  /* Contact form */
  contacts: CustomerContact[] = [];
  form: UntypedFormGroup;
  appearance: MatFormFieldAppearance = 'outline';

  errorMsg: string;
  actualDialogData: ZenDialogDataModel;

  ZenMessageType = ZenMessageType;

  hasValidPrimaryUser = false;
  primaryUserIsAdvisor = false;
  hasLoaded = false;

  constructor(
    public dialogRef: MatDialogRef<ZenSendEmailDialogComponent>,
    private zenDialogSvc: ZenDialogMsgService,
    @Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
    private formBuilder: UntypedFormBuilder, private snackBar: MatSnackBar,
    private custContactSvc: CustomerContactService) {
    super();
    // Taking copy of actual data to support billing address add/edit
    this.actualDialogData = {...this.data};
  }

  ngOnInit() {
    this.loadForm();
    this.loadContacts();

    this.contactCtrl.valueChanges
      .subscribe((contact: CustomerContact) => {
        this.filterContacts();
        if (contact && contact.id) {
          this.form.patchValue(contact);
        }
      });
  }

  loadContacts() {
    if (this.data.data && this.data.data.customerId) {
      this.errorMsg = null;
      this.custContactSvc.getCustomerContacts(this.data.data.customerId).subscribe(res => {
        res.filter(c => c.primary && c.allowLogin).length > 0 ? this.hasValidPrimaryUser = true : this.hasValidPrimaryUser = false;
        res.filter(c => c.primary && c.allowLogin && c.isAdvisor).length > 0 ? this.primaryUserIsAdvisor = true : this.primaryUserIsAdvisor = false;
        this.hasLoaded = true;
        // If user is not valid, update email modal
        if (!this.hasValidPrimaryUser) {
          this.data.header = {title: 'Adjust Primary User', icon: 'send'};
          this.data.bodyHtml = `<div>This customer lacks a primary user with a customer role to receive this email. Please adjust the primary user in the customer's user tab.</div>`;
          this.data.actionButtons =  [
            {
              label: 'Close', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'accent',
              command: (contact: CustomerContact) => {
                this.data.onClose();
              }
            }
          ];
        }
        if (this.primaryUserIsAdvisor) {
          this.data.bodyHtml = this.data.bodyHtml + `<div class="my-3"><b>Note:</b> This customer's primary user is an advisor, for your security tokenized links will not be sent.</div>`;
        }


        this.contacts = res.map(c => this.custContactSvc.setContactFilterClass(c));
        this.filteredContacts.next(this.contacts.slice());
      }, e => {
        console.log('Error: Customer contacts ', e);
        if (e.error && e.error.status === 400 && e.error.message) {
          this.errorMsg = e.error.message
        } else {
          this.errorMsg = ZenErrorMsgEnum.ERR_MSG_1_TEXT;
        }
        this.zenDialogSvc.openToast(false);
      });
    }
  }

  protected filterContacts() {
    if (!this.contacts) {
      return;
    }
    let search = this.contactCtrl.value;
    if (search && typeof search === 'string') {
      search = search.toLowerCase();
    } else {
      this.filteredContacts.next(this.contacts.slice());
      return;
    }
    this.filteredContacts.next(
      this.contacts.filter(c => c.label.toLowerCase().indexOf(search) > -1)
    );
  }

  get controls() {
    return this.form.controls;
  }

  loadForm(): void {
    this.form = this.formBuilder.group({
      id: new UntypedFormControl(),
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      title: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(ValidPatterns.EMAIL_PATTERN)]),
      userId: new UntypedFormControl('', ),
      allowLogin: new UntypedFormControl(''),
      primary: new UntypedFormControl(''),
    });
  }

  displayFn(item: CustomerContact): string {
    return item && item.firstName ? (item.firstName + ' ' + item.lastName) : '';
  }

  get isEditable() {
    return this.contactCtrl.value && this.contactCtrl.value?.value && !this.formType;
  }

  handleEditContact() {
    this.formType = ZenFormTypesEnum.EDIT;
    this.modifyDialogData();
  }

  handleAddContact() {
    this.formType = ZenFormTypesEnum.ADD;
    this.form.reset();
    this.modifyDialogData();
  }

  modifyDialogData() {
    if (this.formType) {
      this.dialogRef.updateSize(ZenDialogSizeEnum.SMALL);
      this.data.header = {
        title: (this.formType === ZenFormTypesEnum.ADD ? 'Add' : 'Edit') + ' Contact',
        icon: ZenIconsEnum.SETTINGS
      };
      this.data.bodyHtml = this.formType === ZenFormTypesEnum.EDIT ? `Edit the information below to maintain an accurate record of this contact.`
        : `Create a new contact to be leveraged across this customer.`;
      // Add/Edit contact onClose action
      this.data.onClose = () => {
        this.formType = null;
        this.modifyDialogData();
      };
    } else {
      // Reverting back to actual dialog config/data
      this.dialogRef.updateSize(ZenDialogSizeEnum.MEDIUM);
      this.data.header = this.actualDialogData.header;
      this.data.bodyHtml = this.actualDialogData.bodyHtml;
      this.data.onClose = this.actualDialogData.onClose;
    }
  }

  onContactFormSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const val = this.form.getRawValue();
      if (this.formType === ZenFormTypesEnum.EDIT) {
        this.handleSubscribe(this.custContactSvc.updateContactById(val.id, val, this.data.data.customerId));
      } else if (this.formType === ZenFormTypesEnum.ADD) {
        this.handleSubscribe(this.custContactSvc.addContactByCustomerId(val, this.data.data.customerId));
      }
    }
  }

  cancelEdit() {
    this.formType = null;
    this.errorMsg = null;
    this.modifyDialogData();
  }

  submit(btn: ZenDialogActionButton) {
    // If no primary user is assigned, button will close dialog
    if (!this.hasValidPrimaryUser) {
      btn.command(false);
      return;
    }

    if (this.contactCtrl.value) {
      const val = this.contacts.find(c => c.id === this.contactCtrl.value?.value);
      btn.command(val);
    } else if (!this.contactCtrl.valid) {
      this.contactCtrl.markAsTouched();
      this.zenDialogSvc.openToast(false, ZenErrorMsgEnum.ERR_FORM_FIELD);
    }
  }

  handleSubscribe(sub: Observable<CustomerContact>) {
    this.errorMsg = null;
    sub.subscribe(res => {
      const _contact = this.custContactSvc.setContactFilterClass(res);
      if (this.formType === ZenFormTypesEnum.ADD) {
        this.contacts.unshift(_contact);
      } else if (this.formType === ZenFormTypesEnum.EDIT) {
        const i = this.contacts.findIndex(c => c.id === res.id);
        if (i !== -1) {
          this.contacts[i] = {..._contact};
        }
      }
      this.filteredContacts.next(this.contacts);
      this.zenDialogSvc.openToast(true);
      this.cancelEdit();
      this.contactCtrl.setValue(_contact);
    }, e => {
      console.log('Error: edit contact ', e);
      if (e.error && e.error.status === 400 && e.error.message) {
        this.errorMsg = e.error.message
      } else {
        this.errorMsg = ZenErrorMsgEnum.ERR_MSG_1_TEXT;
      }
      this.zenDialogSvc.openToast(false);
    });
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }
}
