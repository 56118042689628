import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TreeItemFlatNode} from '../portfolio-filter-flyout/portfolio-filter-flyout.component';
import {PortfolioHierarchyLevelEnum} from '../../../_modules/portfolio/_enums/portfolio-hierarchy-level.enum';
import {ZenAbstractFilterService} from '../../../_modules/zen-contracts/_services/zen-abstract-filter-service';

@Component({
  selector: 'app-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss']
})
export class FilterChipsComponent implements OnInit {
  popperChildren: TreeItemFlatNode[];
  portfolioHierarchyLevelEnum = PortfolioHierarchyLevelEnum;
  @Input() hierarchyLevel: PortfolioHierarchyLevelEnum;
  @Input() customerId: number;
  @Input() lenId: string;
  @Input() serviceAddressId: number;
  @Input() isActive: boolean;
  @Output() removeCallbackFn = new EventEmitter<any>();
  @Input() updateURLState: boolean = true;
  @Input() displayInline: boolean = false;
  @Input() zenFilterService: ZenAbstractFilterService;
  constructor() {

  }

  ngOnInit(): void {
  }

  // Update list when opening popper to show correct echild nodes
  updatePopperList(node: TreeItemFlatNode) {
    this.popperChildren = node.chipChildren;
  }

  remove(node: TreeItemFlatNode) {
    // Node list will be only the node passed in...or all of its children
    const nodeList = node.chipChildren ? node.chipChildren : [node];
    let currentActiveNodes = this.zenFilterService.filterNodes;
    nodeList.forEach(nd => {
        const index = currentActiveNodes.findIndex(n => {
          return nd.parentAttribute === n.parentAttribute && nd.value === n.value;
        });
        if (index > -1) {
          currentActiveNodes.splice(index, 1);
        }
      }
    )
    this.zenFilterService.applyFiltersAndReload(currentActiveNodes, this.hierarchyLevel, this.customerId, this.lenId, this.serviceAddressId, this.removeCallbackFn, this.updateURLState);
  }
}
