import {Component, Input, OnInit, ViewChild} from '@angular/core';
import Chart, {ChartDataset, ChartOptions} from 'chart.js';
import moment from 'moment/moment';
import {getCssVarValue} from '../../_zen-legacy-common/_utils/zen-utils';
import * as tinycolor from 'tinycolor2';
import {MonthlyMetrics} from '../../_model/opportunity.model';
import {NgxPopperjsContentComponent} from 'ngx-popperjs';

@Component({
  selector: 'app-zen-metric-sparkline-widget',
  templateUrl: './zen-metric-sparkline-widget.component.html',
  styleUrl: './zen-metric-sparkline-widget.component.scss'
})
export class ZenMetricSparklineWidgetComponent implements OnInit {

  @Input() public title: string;
  @Input() public prefix: string;
  @Input() public suffix: string;
  @Input() public data: MonthlyMetrics[];

  public lineChartData: ChartDataset[] = [];
  public lineChartLabels: string[] = [];
  public lineChartOptions: ChartOptions;
  public formattedMetric: string;
  public showLineChart = false;

  chartDateFormatString = 'YYYY-MM-DD';
  tooltipDateFormat = 'MMM YYYY';

  primaryColor = getCssVarValue('--primaryColor');
  secondaryColor = getCssVarValue('--secondaryColor');

  // default to 11 months to show on the chart.  Can be higher if more data is returned.
  // picked 11 months so we don't see the same month twice on the chart if we barely have any data.  e.g. Sep, Dec, Mar, Jun, Sep (won't show at 11 months)
  totalMonthsToChart = 11;

  @ViewChild('helpText') helpTextPopper: NgxPopperjsContentComponent;

  ngOnInit(): void {

    if (this.data?.length === 0) {
      console.error('No data provided for sparkline widget');
      return; // avoid further issues if no data is provided
    }

    // show chart base on data points.  Currently at 0, but is configurable.
    this.showLineChart = this.data?.length > 0;

    // Initialize the chart data and labels
    const values = this.data.map(d => d.value); // all values
    const totalValue = values.reduce((a, b) => a + b, 0); // sum of all values
    this.lineChartLabels = this.data.map(d => d.date); // array of string dates (e.g., ['2024-01-01', '2024-02-01', ...])

    // calculate the difference in months between the last date and today.
    // if greater than 12, then update the chart to span out further.
    // This is needed to support a min number of months to chart as well as a max number of months
    // to chart in case you only have 1 or 2 months but we want to show the full year.
    let monthDiff = moment(this.lineChartLabels[this.lineChartLabels.length - 1], this.chartDateFormatString).diff(moment(), 'months');
    if (monthDiff > 10) {
      this.totalMonthsToChart = monthDiff + 1; // need the extra month here for some reason for the chart to not cut the last month in half visually.
    }

    this.lineChartData = [
      {
        data: values,
        fill: true,
        barThickness: 10,
        maxBarThickness: 10,
        borderColor: this.primaryColor,
        backgroundColor: tinycolor(this.primaryColor).lighten(20).toString('rgb'), // Light blue shading
        pointRadius: 1,
        pointBackgroundColor: this.primaryColor,  // Match point color to line color
        pointBorderColor: 'transparent',  // Hide the border around points
        pointHoverRadius: 3,  // Slightly larger on hover
        pointHoverBackgroundColor: this.primaryColor,
        pointHoverBorderColor: this.primaryColor,
        tension: 0  // Make the line straight
      }
    ];

    // Example large value of 1,234,567 to 1.2M
    this.formattedMetric = this.formatMetric(totalValue);

    // Initialize chart options
    this.lineChartOptions = this.getChartOptions();
  }

  // Method to format large numbers (e.g., 1.2k, 2.1M)
  formatMetric(value: number): string {
    if (value >= 1000000) {
      return (value / 1000000).toFixed(1) + 'M';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'k';
    } else {
      return value.toString();
    }
  }

  // Method to create and manage custom tooltip
  createCustomTooltip(context: { chart: Chart.Chart; tooltip: any }) {
    // Tooltip Element
    let tooltipEl = document.getElementById('zen-metric-sparkline-widget-tooltip');

    // Create the tooltip element if it doesn't exist
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'zen-metric-sparkline-widget-tooltip';
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '4px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.padding = '4px';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.fontSize = '10px';
      tooltipEl.style.whiteSpace = 'nowrap';
      tooltipEl.style.zIndex = '9999';
      document.body.appendChild(tooltipEl);
    }

    // Hide the tooltip if no tooltip is visible
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    // Set the tooltip text
    if (tooltipModel.body) {
      // Format the date to 'MMM YYYY' using moment.js
      const formattedDate = moment(tooltipModel.title, this.chartDateFormatString).format(this.tooltipDateFormat);
      const body = tooltipModel.body.map(b => b.lines); // array of value.  In this case, only 1 should be present.
      // prefix:  Jan 2023: $1,000
      // suffix : Jan 2023: 1,000 kWh
      tooltipEl.innerHTML = formattedDate + ': ' + this.prefix + body.join(' ' + this.suffix + '<br>');
    }

    // Calculate tooltip position
    const canvas = context.chart.canvas;
    const position = canvas.getBoundingClientRect();

    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px';
  }

  // Method to return chart options with external tooltip logic
  getChartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
     // aspectRatio: 6,  // Wide aspect ratio for sparkline
      plugins: {
        tooltip: {
          enabled: false,  // Disable the default tooltip
          external: (context) => this.createCustomTooltip(context)  // Use custom tooltip
        }
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      scales: {
        x: {
          type: 'time',  // Set x-axis as time to interpret dates
          time: {
            unit: 'month',  // Display by months
            tooltipFormat: this.chartDateFormatString,  // Tooltip format
            displayFormats: {
              month: this.chartDateFormatString  // Format for the labels on the x-axis
            }
          },
          min: moment().startOf('month').toISOString(),  // Start at the beginning of the current month
          max: moment().add(this.totalMonthsToChart, 'months').toISOString(),
          grid: {
            display: false
          },
          ticks: {
            // Use a callback to format the x-axis labels as needed
            callback: function(value, index, ticks) {
              return moment(value).format('MMM');  // Example: Jan
            },
            source: 'auto',
            autoSkip: true,
            maxTicksLimit: 5  // Show at most 5 ticks
          }
        },
        y: {
          display: false,
          type: 'logarithmic',  // Switch to a logarithmic scale
          grid: {
            display: false  // Remove grid lines
          }
        }
      },
      elements: {
        bar: {
          borderRadius: 10, // Rounded corners
          borderSkipped: false
        },
        point: {
          radius: 1,
          hoverRadius: 5,
          hitRadius: 10,
          backgroundColor: this.primaryColor,
          borderColor: 'transparent',
        }
      }
    };
  }
}
