<button mat-icon-button class="with-text {{btnStyleCls}}"
        [class.cursor-default]="disableRipple"
        [disableRipple]="disableRipple || (popperComponent ? true : false)"
        [class.disabled]="disabled"
        [class.selected]="selected"
        [class.no-border]="noBorder"
        [class.unselected]="unselected">
  <i class="{{isSymbol ? 'material-symbols-rounded' : 'material-icons'}} color-icon zen-button-icon {{iconColor}}">{{icon}}</i>
  <span class="btn-label">{{title}}</span>
  <!-- Setting title="" to prevent browser default tooltip -->
  <i *ngIf="popperComponent" [popper]="popperComponent" [popperTrigger]="'hover'" popperApplyClass="sm"
     class="material-symbols-rounded blue-1-color info-icon"
      title="">info</i>
</button>
