import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {NgxPopperjsContentComponent} from 'ngx-popperjs';

export interface ZenToastDataModel {
  message?: string;
  icon?: ZenIconsEnum;
  popperContent?: NgxPopperjsContentComponent;
}

@Component({
  selector: 'app-zen-toast',
  templateUrl: './zen-toast.component.html',
  styleUrls: ['./zen-toast.component.scss']
})
export class ZenToastComponent implements OnInit {

  constructor(
    public snackBarRef: MatSnackBarRef<ZenToastComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: ZenToastDataModel
  ) {
  }

  ngOnInit(): void {
  }

}
