import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-zen-table-info-progressbar',
  templateUrl: './zen-table-info-progressbar.component.html',
  styleUrls: ['./zen-table-info-progressbar.component.scss']
})
export class ZenTableInfoProgressbarComponent implements OnInit {
  @Input() title = 'Total files uploaded';
  @Input() totalCount: number;
  @Input() completedCount: number;

  constructor() {
  }

  ngOnInit() {
  }

  getPercentage() {
    if (this.completedCount || this.totalCount) {
      return (this.completedCount / this.totalCount) * 100;
    } else if (this.completedCount === 0 && this.totalCount === 0) {
      return  100;
    }
  }

}
