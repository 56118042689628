import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {CommodityType} from '../_zen-legacy-common/_models/commodity';
import {ZenNumberFormat} from '../_enums/zen-number-format.enum';
import {ZenCurrencyTypeEnum} from '../_enums/zen-currency-type.enum';
import {TranslateService} from '@ngx-translate/core';
import {ZenLocaleModel} from '../_model/zen-locale.model';

@Pipe({
  name: 'commodityFeeFormat'
})
export class CommodityFeeFormatPipe implements PipeTransform {

  constructor(
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private translateSvc: TranslateService
  ) {}

  transform(value: number, args: {commodity: CommodityType, currencyType?: ZenCurrencyTypeEnum}): string {
    // set to 0 if null
    if (value === undefined || value === null || isNaN(value)) {
      value = 0;
    }

    let _format;
    if (args.commodity === CommodityType.Gas) {
      _format = ZenNumberFormat.GAS_FEE;
    } else {
      _format = (args?.currencyType === ZenCurrencyTypeEnum.MILLS ? ZenNumberFormat.ELECTRIC_FEE_IN_MILS : ZenNumberFormat.ELECTRIC_FEE_IN_DOLLARS);
    }

    const _translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;
    if (args?.currencyType === ZenCurrencyTypeEnum.CURRENCY) {
      return this.currencyPipe.transform(value, _translations?.currency?.abbreviation, 'symbol-narrow', _format);
    } else {
      return this.decimalPipe.transform(value, _format);
    }
  }

}
