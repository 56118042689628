<div class="zen-mat-table zen-mat-table-with-expansion"
     [ngClass]="viewType === TableViewTypes.COLLAPSED ? 'collapsed-view' : 'detailed-view'">
  <div *ngIf="parentTableConfig && childTableConfig"
       class="zen-mat-table-wrapper" style="border-radius: 1rem !important;">

    <div *ngIf="legends?.length" class="legend-main">
      <div *ngFor="let legend of legends" class="d-flex align-items-center me-3">
        <i class="material-icons me-2" [style.color]="legend.color">fiber_manual_record</i>
        <p class="text-sm">{{legend.label}}</p>
      </div>
    </div>

    <!-- Table top menu actions - toggled with filterInput -->
    <app-zen-mat-table-actions *ngIf="allSelectedRowsFlat.hasValue() else filterInput"
                               [actions]="parentTableConfig.tableMenuOptions"
                               [selection]="allSelectedRowsFlat"></app-zen-mat-table-actions>

    <!-- Filter input -->
    <ng-template #filterInput>
      <mat-form-field class="filter-input">
        <mat-label>
          <div class="label-sec" *ngIf="!searchInput.focused && !searchInput.value">
            <i class="material-icons label-icon">search</i>
          </div>
        </mat-label>
        <input matInput #searchInput="matInput" [disabled]="!groupedData?.length" [formControl]="searchValue">
      </mat-form-field>
    </ng-template>


    <ng-container *ngIf="groupedData?.length else emptyTableTpl">
      <!-- Adding row class here to set full width for the table content. -->
      <div class="expansion-scroll-x-wrapper">

        <div class="custom-all-checkbox" *ngIf="!hideMultiselect && !loading">
          <mat-checkbox (change)="masterToggle($event)" class="ms-2"
                        [checked]="allSelectedRowsFlat.hasValue() && isAllSelected()"
                        [indeterminate]="allSelectedRowsFlat.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </div>

        <ng-container
          *ngFor="let pData of groupedData | paginate: { itemsPerPage: pageSize, currentPage: currentPage}; let parentIndex = index;">

          <div *ngIf="!hideMultiselect && !loading && !pData.hasSubCategories"
            class="category-level-custom-all-checkbox-wrapper parent-index-{{parentIndex}}">
            <mat-checkbox class="category-level-custom-all-checkbox" (change)="$event ? masterToggleCatDetails($event, pData) : null"
                          [checked]="pData.categoryDetails.selectedRows?.hasValue() && isAllSelectedCatDetails(pData)"
                          [indeterminate]="pData.categoryDetails.selectedRows?.hasValue() && !isAllSelectedCatDetails(pData)">
            </mat-checkbox>
          </div>

          <app-zen-mat-table *ngIf="parentTableConfig" styleCls="parent-table index-{{parentIndex}}"
                             [class.no-subcategories]="!pData.hasSubCategories"
                             [class.hide-master-toggle-chk-box]="!parentTableConfig.hideMultiselect"
                             [data]="[pData.categoryDetails]"
                             [hideCols]="pData.categoryDetails?.hideCols"
                             [viewType]="viewType"
                             [isExpandable]="!loading"
                             [loading]="loading"
                             [tableConfig]="parentTableConfig" (onSortChange)="handleSortChange($event)">

            <!-- When subCategory: false -->
            <div class="multi-level-table-container without-subcategory"
                 [class.no-multi-select]="parentTableConfig.hideMultiselect"
                 *ngIf="(pData.data && pData.data.length) && !pData.hasSubCategories else subCategoryTpl">

              <app-zen-mat-table *ngIf="childTableConfig" styleCls="second-level-child remove-border"
                                 [data]="pData.data"
                                 (onFilter)="pData.filteredData = $event"
                                 [externalSearchQuery]="searchValue.value"
                                 [viewType]="viewType"
                                 [hideCols]="pData.hideCols"
                                 [selection]="pData.categoryDetails.selectedRows"
                                 [tableConfig]="childTableConfig"></app-zen-mat-table>
            </div>

            <!-- When subCategory: true -->
            <ng-template #subCategoryTpl>

              <ng-container *ngFor="let subCategory of pData.subCategories">

                <div class="multi-level-table-container with-subcategory">
                  <app-zen-mat-table *ngIf="childTableConfig" styleCls="second-level-child remove-border hide-header"
                                     [data]="[subCategory.categoryDetails]"
                                     [viewType]="viewType"
                                     [isExpandable]="pData.hasSubCategories"
                                     [hideCols]="subCategory.hideCols"
                                     [tableConfig]="childTableConfig">
                    <!--                    [selection]="subCategory.categoryDeta1ils.selectedRows"-->
                    <!-- 3rd level expandable content -->
                    <div class="{{childOfChildTableConfig.hideMultiselect?'ms-3':'ms-5'}}" *ngIf="pData.hasSubCategories">
                      <!--                      <pre>subCategory.categoryDetails.selectedRows - {{subCategory.categoryDetails.selectedRows?.selected | json}}</pre>-->
                      <app-zen-mat-table *ngIf="childOfChildTableConfig && subCategory.data?.length"
                                         styleCls="third-level-child remove-border"
                                         [data]="subCategory.data"
                                         (onFilter)="subCategory.filteredData = $event"
                                         [externalSearchQuery]="searchValue.value"
                                         [viewType]="viewType"
                                         [hideCols]="subCategory.hideCols"
                                         [selection]="subCategory.categoryDetails.selectedRows"
                                         [tableConfig]="childOfChildTableConfig">
                      </app-zen-mat-table>
                    </div>

                  </app-zen-mat-table>

                </div>
              </ng-container>
            </ng-template>


          </app-zen-mat-table>

        </ng-container>
      </div>
    </ng-container>

    <ng-template #emptyTableTpl>
      <app-zen-mat-table *ngIf="emptyTableConfig" [data]="[]" [loading]="loading" [tableConfig]="emptyTableConfig"
                        [selectSearchConfig]="emptyTblSelectSearchConfig"></app-zen-mat-table>
    </ng-template>
  </div>


  <div class="text-end" *ngIf="groupedData?.length && !hidePagination">
    <mat-paginator #matPaginator [length]="groupedData?.length" [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions" (page)="pageChange($event)"></mat-paginator>
  </div>
</div>
