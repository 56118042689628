import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {environment} from '../../../../../../../environments';
import {Customer} from '../../_models/customer';
import {AuthenticationService} from './authentication.service';
import {CacheService} from './cache.service';
import {ErrorService} from './error.service';

@Injectable()
export class CustomerService {
  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private cacheService: CacheService,
              private error: ErrorService
              ) {
  }

  getCustomerDetailsById(customerId: number, clearCache = false): Observable<Customer> {
    const url = `${this.baseUrl}/v1/customers/${customerId}`;
    const cachePrefix = 'customer_';
    let cache = this.cacheService.getValue(url, customerId, cachePrefix);
    if (cache && !clearCache) {
      return of(cache);
    } else {
      let obs = this.http.get(url)
        .pipe(
          map(response => response as Customer),
          catchError(this.error.handleObsError)
        );
      // save to cache
      this.cacheService.addObservableValue(url, customerId, obs, cachePrefix);
      // return observable
      return obs;
    }
  }

  updateCustomerInfo(customer: Customer, customerId?: number): Observable<Customer> {
    let _id = customerId ? customerId : this.authService.getCurrentCustomerId();
    const url = `${this.baseUrl}/v1/customers/${_id}`;
    return this.http.put(url, customer, {})
      .pipe(
        take(1),
        map(response => response as Customer)
      );
  }

}
