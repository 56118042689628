import {Component, Input, OnInit} from '@angular/core';
import {SummarySnapShotModel} from '../../../_modules/portfolio/_model/portfolio-summary.model';
import {ZenLoaderTypes} from '../zen-skeleton-loader/zen-skeleton-loader.component';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';

@Component({
  selector: 'app-zen-snapshot',
  templateUrl: './zen-snapshot.component.html',
  styleUrls: ['./zen-snapshot.component.scss']
})
export class ZenSnapshotComponent implements OnInit {
  @Input() data: SummarySnapShotModel;
  @Input() loading: boolean;
  @Input() error: boolean;
  ZenLoaderTypes = ZenLoaderTypes;
  ZenIcons = ZenIconsEnum;
  constructor() { }

  ngOnInit(): void {
  }

}
