import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafeHtmlPipe} from './_pipes/safe-html.pipe';
import {SafeResourceUrlPipe} from './_pipes/safe-resource-url.pipe';
import {SafeUrlPipe} from './_pipes/safe-url.pipe';
import {AbsolutePipe} from './_pipes/absolute.pipe';
import {MinValueDirective} from './_directives/form-validation/min-value.directive';
import {MaxValueDirective} from './_directives/form-validation/max-value.directive';
import {TextHighlightPipe} from './_pipes/text-highlight.pipe';
import {TruncatePipe} from './_pipes/truncate.pipe';
import {AnnualUsagePipe} from './_pipes/annual-usage.pipe';
import {StoriesModule} from '../../../../stories/stories.module';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    SafeUrlPipe,
    TextHighlightPipe,
    AbsolutePipe,
    TruncatePipe,
    AnnualUsagePipe,
    MinValueDirective,
    MaxValueDirective
  ],
  exports: [
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    SafeUrlPipe,
    TextHighlightPipe,
    AbsolutePipe,
    TruncatePipe,
    AnnualUsagePipe,
    // ImageCropperModule,
    MinValueDirective,
    MaxValueDirective
  ],
  imports: [
    CommonModule,
    // ImageCropperModule,
    StoriesModule
  ],
  providers: [
    AnnualUsagePipe
  ]
})
export class ZenLegacyCommonModule {
}
