import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {InitialPortfolioFilters, PortfolioTableFilter} from '../../_model/portfolio-initial-filter.model';
import {environment} from '../../../../../../../environments';
import {OrganizationManagementService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {getParams} from '../../../../_shared/_zen-legacy-common/_utils/set-param-util';
import {PortfolioFilterScope} from '../../_enums/portfolio-hierarchy-level.enum';
import {PortfolioHelperService} from './portfolio-helper.service';

@Injectable({
  providedIn: 'root'
})
export class InitialFiltersService {
  baseUrl = environment.apiBaseUrl;
  refreshFilters = new BehaviorSubject<boolean>(false);
  onFilterChange = new BehaviorSubject<boolean>(false);
  emitFilters = new BehaviorSubject<InitialPortfolioFilters>(null);

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }


  getPortfolioInitialFilters(customerId?: number, lenId?: string, serviceAddressId?: number, filterScope?: PortfolioFilterScope): Observable<InitialPortfolioFilters> {
    let riskWindowDays = PortfolioHelperService.outsideRiskWindowDays.value;

    // If there is no customerId lenId, or meterId, call the PORTFOLIO level API, else call hierarchy
    if (customerId == null && lenId == null && serviceAddressId == null) {
      let params = {filterScope: filterScope != null ? filterScope.toString() : null, riskWindowDays };
      let httpParams = getParams(params);
      const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/filters?` + httpParams.toString();
      return this.http.get<InitialPortfolioFilters>(url).pipe(take(1));
    } else {
      let params = {lenId: lenId, serviceAddressId: serviceAddressId, filterScope: filterScope != null ? filterScope.toString() : null, riskWindowDays: riskWindowDays};
      let httpParams = getParams(params);
      const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/filters?` + httpParams.toString()
      return this.http.get<InitialPortfolioFilters>(url).pipe(take(1));
    }
  }

  getPortfolioTableFilters(): Observable<PortfolioTableFilter> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/table-filters`;
    return this.http.get<PortfolioTableFilter>(url).pipe(take(1));
  }

}

